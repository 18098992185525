import { isEmpty } from 'lodash'

import { KG_NODES, ValidationCategory as State } from '@common'

import { shopRewardSchema } from './schema'

export const rules = [
  ({ re_channel_ids }) => {
    const summary = `[${shopRewardSchema.re_channel_ids.label}] 必不為以下值：一般、一般_海外、實體、實體_海外、網購、網購_海外`
    return {
      fieldname: shopRewardSchema.re_channel_ids.label,
      result: [
        KG_NODES.card.GENERAL_ID,
        KG_NODES.card.GENERAL_OVERSEAS_ID,
        KG_NODES.card.REAL_ID,
        KG_NODES.card.REAL_OVERSEAS_ID,
        KG_NODES.card.ONLINE_ID,
        KG_NODES.card.ONLINE_OVERSEAS_ID,
      ].every(id => !re_channel_ids.includes(id))
        ? []
        : [[State.Error, summary]],
    }
  },
  ({ effective_date_b, effective_date_e }) => {
    const summary = `[${shopRewardSchema.effective_date_b.label}]必小於[${shopRewardSchema.effective_date_e.label}]`
    if (isEmpty(effective_date_b) || isEmpty(effective_date_e))
      return { result: [] }
    const begin = new Date(effective_date_b)
    const end = new Date(effective_date_e)
    return {
      fieldname: shopRewardSchema.effective_date_b.label,
      result: begin < end ? [] : [[State.Error, summary]],
    }
  },
  ({ effective_date_b, effective_date_e }) => {
    const summary = `[${shopRewardSchema.effective_date_b.label}]或[${shopRewardSchema.effective_date_e.label}]不合理（超過目前日期五年）`
    const begin = new Date(effective_date_b)
    const end = new Date(effective_date_e)
    const now = new Date()
    const limit = new Date(now.getFullYear() + 5, now.getMonth(), now.getDate())
    return {
      fieldname:
        shopRewardSchema.effective_date_b.label +
        '/' +
        shopRewardSchema.effective_date_e.label,
      result: begin < limit && end < limit ? [] : [[State.Error, summary]],
    }
  },
]
