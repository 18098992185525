import { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import 'styled-components/macro'
import { useImmer } from 'use-immer'
import { Button } from 'primereact/button'
import { ListBox } from 'primereact/listbox'
import { Checkbox } from 'primereact/checkbox'
import uuid from 'react-uuid'
import {
  map,
  isEmpty,
  some,
  size,
  filter,
  includes,
  concat,
  without,
  uniq,
  intersection,
  compact,
} from 'lodash'
import {
  Box,
  Flex,
  StyledOverlay,
} from '@changingai/react-editor-common-component'

import { useInit, useError, SelectionList } from './common'
import { ColumnRoot } from './StyledComps'
import { ToastifyLabel } from './ToastifyLabel'
import { AiFillPlusCircle } from 'react-icons/ai'

function CheckBoxList({
  options,
  selections,
  listStyle,
  filter,
  disabled = false,
  onChange,
  items,
  itemRenderer = ({ id, name }, selections) => (
    <Box
      ml="2"
      flex="1 1"
      fontWeight={includes(selections, id) ? 'bold' : 'normal'}
    >
      {name}
    </Box>
  ),
}) {
  return (
    <Flex flexDirection="column" width="100%">
      <ListBox
        style={{ flex: '1 1 100%', borderRadius: 0, width: '100%' }}
        listStyle={listStyle}
        options={options}
        optionLabel="name"
        filter={filter}
        disabled={disabled}
        itemTemplate={item => (
          <Flex justifyContent="flex-start">
            <Checkbox
              inputId={item.id}
              checked={includes(selections, item.id)}
              onChange={({ checked }) => onChange({ checked, id: item.id })}
            />
            {itemRenderer(item, selections)}
          </Flex>
        )}
      />
      <SelectionList selected={selections} items={items} />
    </Flex>
  )
}

CheckBoxList.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  selections: PropTypes.arrayOf(PropTypes.string),
  listStyle: PropTypes.object,
  filter: PropTypes.bool,
  disabled: PropTypes.bool,
  freeze: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  itemRenderer: PropTypes.func,
}

export function MultiCheckboxList({
  fieldname,
  label,
  value,
  onChange,
  onDirty,
  onValid,
  labelAttributes = { width: '150px' },
  labelPosition = 'left',
  inputAttributes = { width: '33%', height: '200px' },
  useQueryHook,
  disabled = false,
  filter: fileterListBox = false,
  editable = true,
  itemRenderer,
}) {
  const [, compare] = useInit(value)
  const [selection, setSelection] = useImmer(value)
  const items = useQueryHook()
  const [id] = useState(uuid())
  const error = useError(onValid, id, fieldname, selection, editable)

  useEffect(() => {
    onChange(fieldname, selection)
    onDirty(id, compare(selection))
  }, [selection, fieldname, onChange, onDirty, compare, id])

  useEffect(() => {
    return function cleanupDirtyAndError() {
      onDirty(id, false)
      onValid(fieldname, null, { clean: true, key: id })
    }
  }, [fieldname, onDirty, onValid, id])

  useEffect(() => {
    if (isEmpty(items)) return

    const ids = map(items, 'id')
    if (some(ids, isEmpty)) {
      throw new Error(
        'Each item that returned from useQueryHook must contain id field.',
      )
    }

    setSelection(draft => filter(draft, id => ids.includes(id)))
  }, [items, setSelection])

  const { height, ...inputAttributesExceptHeight } = inputAttributes
  return (
    <Flex
      flexWrap="wrap"
      alignItems="center"
      my="1"
      pr="2"
      {...inputAttributesExceptHeight}
    >
      <Flex
        width="100%"
        position="relative"
        flexWrap={labelPosition === 'top' ? 'wrap' : 'nowrap'}
      >
        <Flex
          justifyContent={labelPosition === 'top' ? 'center' : 'flex-start'}
          py="2"
          bg="blacks.2"
          border="1px solid gray"
          width={labelPosition === 'top' ? '100%' : labelAttributes.width}
        >
          <ToastifyLabel
            fieldname={fieldname}
            changed={compare(selection)}
            label={`${label} (${size(selection)})`}
            error={error}
            editable={!disabled}
            {...labelAttributes}
            width={labelPosition === 'top' ? '' : labelAttributes.width}
          />
        </Flex>
        <StyledOverlay active={!items} size="20px" />
        <CheckBoxList
          listStyle={{ height: `calc(${height} - 40px)` }}
          options={items}
          filter={fileterListBox}
          disabled={disabled}
          selections={selection}
          onChange={({ checked, id }) => {
            setSelection(draft => {
              if (checked) draft.push(id)
              else draft.splice(draft.indexOf(id), 1)
            })
          }}
          items={items}
          itemRenderer={itemRenderer}
        />
      </Flex>
    </Flex>
  )
}

MultiCheckboxList.propTypes = {
  fieldname: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  onDirty: PropTypes.func.isRequired,
  onValid: PropTypes.func.isRequired,
  useQueryHook: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  labelAttributes: PropTypes.object,
  labelPosition: PropTypes.oneOf(['left', 'top']),
  inputAttributes: PropTypes.object,
  height: PropTypes.string,
  filter: PropTypes.bool,
  editable: PropTypes.bool,
  disabled: PropTypes.bool,
  itemRenderer: PropTypes.func,
}

export function MultiCheckboxMergeWidget({
  fieldname,
  leftValue,
  rightValue,
  onChange,
  useQueryHook,
  onValid,
}) {
  const items = useQueryHook()
  const [content, setContent] = useState([])

  const [leftOptions, rightOptions] = useMemo(
    () =>
      map([leftValue, rightValue], values =>
        filter(
          items,
          ({ id }) => includes(values, id) && !includes(content, id),
        ),
      ),
    [leftValue, rightValue, content, items],
  )

  useEffect(() => {
    onChange(fieldname, content)
    onValid(fieldname, content)
  }, [fieldname, content, onValid, onChange])

  return (
    <Flex width="100%">
      <ColumnRoot>
        <Box fontSize="h1">Left</Box>
        <ListBox
          style={{ flex: '1 1 100%', borderRadius: 0, width: '100%' }}
          options={leftOptions}
          optionLabel="name"
          listStyle={{ height: '300px' }}
          itemTemplate={({ name }) => (
            <Flex justifyContent="space-between" alignItems="center">
              <Box>{name}</Box>
              <AiFillPlusCircle />
            </Flex>
          )}
          onChange={({ value: { id } }) => {
            setContent(uniq(concat(content, id)))
          }}
        />
        <SelectionList selected={map(leftOptions, 'id')} items={items} />
        <Button
          style={{ height: '30px' }}
          label="Add All"
          disabled={isEmpty(map(leftOptions, 'id'))}
          onClick={() =>
            setContent(compact(uniq(concat(content, map(leftOptions, 'id')))))
          }
        />
      </ColumnRoot>
      <ColumnRoot>
        <Box fontSize="h1">Result</Box>
        <CheckBoxList
          options={items}
          selections={content}
          listStyle={{ height: '230px' }}
          filter={true}
          onChange={({ checked, id }) => {
            if (checked) setContent(uniq(concat(content, id)))
            else setContent(without(content, id))
          }}
          items={items}
        />
        <Button
          style={{ height: '30px' }}
          label="Pick Common"
          disabled={isEmpty(
            intersection(map(leftOptions, 'id'), map(rightOptions, 'id')),
          )}
          onClick={() =>
            setContent(
              intersection(map(leftOptions, 'id'), map(rightOptions, 'id')),
            )
          }
        />
      </ColumnRoot>
      <ColumnRoot>
        <Box fontSize="h1">Right</Box>
        <ListBox
          style={{ flex: '1 1 100%', borderRadius: 0, width: '100%' }}
          options={rightOptions}
          optionLabel="name"
          listStyle={{ height: '300px' }}
          itemTemplate={({ name }) => (
            <Flex justifyContent="space-between" alignItems="center">
              <Box>{name}</Box>
              <AiFillPlusCircle />
            </Flex>
          )}
          onChange={({ value: { id } }) => {
            setContent(uniq(concat(content, id)))
          }}
        />
        <SelectionList selected={map(rightOptions, 'id')} items={items} />
        <Button
          style={{ height: '30px' }}
          label="Add All"
          disabled={isEmpty(map(rightOptions, 'id'))}
          onClick={() =>
            setContent(compact(uniq(concat(content, map(rightOptions, 'id')))))
          }
        />
      </ColumnRoot>
    </Flex>
  )
}

MultiCheckboxMergeWidget.propTypes = {
  fieldname: PropTypes.string.isRequired,
  leftValue: PropTypes.array.isRequired,
  rightValue: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  useQueryHook: PropTypes.func.isRequired,
  onValid: PropTypes.func.isRequired,
}
