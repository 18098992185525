import { useMemo } from 'react'
import { map } from 'lodash'
import { useQuery } from '@apollo/client'

import { KG_NODES } from '@common'
import { useFetchReferencedKG } from '@schema'
import { cceClient } from '@gql'
import { SingleSelect } from '@widget'

import { cardRewardSchema, cardSetSchema } from './schema'
import { useFilterDocField } from './RewardUtils'

import { useConvertIdToName } from '../Common'
import { useUploadDocWithHistory } from '../Uploader'

import { _queryCardGroup, _uploadRewardCards } from './common.gql'

const useCardGroups = cardSetSchema.card_group_id.useNodes

export function useTranspile(docs, selectedFields) {
  const cardGroups = useCardGroups()
  const nodes = useFetchReferencedKG(docs, cardRewardSchema, selectedFields)
  const referencedList = useMemo(
    () => [
      nodes,
      map(cardGroups, group => ({
        ...group,
        id: group._id,
        name: group.card_name,
      })),
    ],
    [nodes, cardGroups],
  )

  return useConvertIdToName(
    docs,
    referencedList,
    selectedFields,
    cardRewardSchema,
  )
}

const withCardGroup = fn => props => {
  const { providerId } = props

  const { data } = useQuery(_queryCardGroup, {
    client: cceClient,
  })

  const nodes = useMemo(
    () =>
      !data
        ? null
        : data.queryCardGroup
            .filter(({ card_provider_id }) => card_provider_id === providerId)
            .map(group => ({
              ...group,
              id: group._id,
              name: group.card_provider_name + ' - ' + group.card_name,
            })),
    [data, providerId],
  )

  return fn({
    ...props,
    nodes,
  })
}

export const CardGroupSelect = withCardGroup(SingleSelect)

export function useUploadCardReward() {
  const filter = useFilterDocField()
  const upload = useUploadDocWithHistory(cardRewardSchema, async input => {
    const {
      data: { uploadRewardCards },
    } = await cceClient.mutate({
      mutation: _uploadRewardCards,
      variables: {
        input: map(input, filter),
      },
    })

    return uploadRewardCards
  })

  return upload
}

function filterIssuerAndLevel(loadingGroup, cardGroup, issuer) {
  const combinations =
    !loadingGroup && cardGroup
      ? cardGroup.queryCardGroup[0].available_combinations
      : null
  if (!combinations) return [null, null]

  const issuers = combinations.map(({ issuer_id }) => issuer_id)
  const levels =
    !issuer || issuer === KG_NODES.entity.ALL_ISSUER_ID
      ? combinations.map(({ card_level_id }) => card_level_id)
      : combinations
          .filter(({ issuer_id }) => issuer_id === issuer)
          .map(({ card_level_id }) => card_level_id)

  return [
    [...issuers, KG_NODES.entity.ALL_ISSUER_ID],
    [...levels, KG_NODES.entity.ALL_CARD_LEVEL_ID],
  ]
}

export function useCardCombination(group, issuer) {
  const { loading, data } = useQuery(_queryCardGroup, {
    client: cceClient,
    variables: {
      doc_ids: [group],
    },
    skip: !group,
  })

  const [issuers, levels] = useMemo(
    () =>
      !loading && data
        ? filterIssuerAndLevel(loading, data, issuer)
        : [null, null],
    [loading, data, issuer],
  )

  return [issuers, levels]
}
