import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'

import { toast } from 'react-toastify'

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
}

export const createGQLClient = (
  uri,
  possibleTypes,
  options = defaultOptions,
) => {
  const client = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) => {
            const error = `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
              locations,
            )}, Path: ${path}`

            toast.error(`[GraphQL error]: ${error}`, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 5000,
            })
          })
        if (networkError) {
          toast.error(`[Network error]: ${networkError}`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
          })
        }
      }),
      new HttpLink({ uri, credentials: 'include' }),
    ]),
    cache: new InMemoryCache({
      possibleTypes,
    }),
    defaultOptions: options,
  })

  return client
}
