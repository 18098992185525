import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import 'styled-components/macro'
import css from '@styled-system/css'
import { useImmer } from 'use-immer'
import { Button } from 'primereact/button'
import uuid from 'react-uuid'
import { map, size, uniq, flatMap, split } from 'lodash'

import { Box, Flex } from '@changingai/react-editor-common-component'
import theme from '@theme'

import { useInit, useError } from './common'
import { StyledChips, InputContainer, WidgetContainer } from './StyledComps'
import { ToastifyLabel } from './ToastifyLabel'
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md'
import { Checkbox } from './checkbox'

export function MultiInput({
  fieldname,
  label,
  value,
  onChange,
  onDirty,
  onValid,
  width = '33%',
  labelAttributes = { width: '150px' },
  labelPosition = 'left',
  renderChip,
  useQueryHook = () => null,
  disabled = false,
  allowDuplicate = false,
}) {
  const [, compare] = useInit(value)
  const [content, setContent] = useImmer(value)
  const [id] = useState(uuid())
  const error = useError(onValid, id, fieldname, value, true)
  const suggestions = useQueryHook()

  useEffect(() => {
    setContent(() => value)
  }, [value, setContent])

  useEffect(() => {
    return function cleanupDirtyAndError() {
      onDirty(id, false)
      onValid(fieldname, null, { clean: true, key: id })
    }
  }, [fieldname, onDirty, onValid, id])

  return (
    <WidgetContainer
      width={width}
      flexWrap={labelPosition === 'top' ? 'wrap' : 'nowrap'}
    >
      <ToastifyLabel
        error={error}
        fieldname={fieldname}
        label={label}
        changed={compare(content)}
        editable={!disabled}
        {...labelAttributes}
      />
      <InputContainer
        css={css({
          '& .p-chips > ul.p-inputtext': {
            bg: 'azures.2',
            flexWrap: 'wrap',
          },
          '& .p-inputwrapper': {
            width: '100%',
          },
          '& .p-button.p-component': {
            py: 1,
            px: 3,
            mt: 1,
          },
          '& .p-chips .p-chips-multiple-container .p-chips-token': {
            marginY: 1,
          },
        })}
      >
        <StyledChips
          disabled={disabled}
          value={content}
          itemTemplate={renderChip}
          onChange={({ value }) => {
            // use ';' for continous input, i.e. ["a;b;c"] => [a, b, c]
            const splitted = flatMap(value, item => split(item, /[;]/))
            // allowDuplicate prop of Chips does not work. Do the filtering
            // manually.
            if (!allowDuplicate && size(uniq(splitted)) !== size(splitted))
              return

            const storableValue = onChange(fieldname, splitted)
            setContent(() => storableValue)
            onDirty(id, compare(storableValue))
          }}
        />
        <Flex width="100%" justifyContent="flex-end">
          {map(suggestions, ({ label, value }, index) => (
            <Button
              disabled={disabled}
              key={index}
              label={label}
              style={{ marginRight: '5px' }}
              onClick={() => {
                const appended = [...content, value]
                setContent(() => appended)
                onChange(fieldname, appended)
                onDirty(value, compare(appended))
              }}
            />
          ))}
        </Flex>
      </InputContainer>
    </WidgetContainer>
  )
}

MultiInput.propTypes = {
  fieldname: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  onValid: PropTypes.func.isRequired,
  onDirty: PropTypes.func.isRequired,
  width: PropTypes.string,
  labelPosition: PropTypes.oneOf(['left', 'top']),
  renderChip: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  useQueryHook: PropTypes.func,
  allowDuplicate: PropTypes.bool,
  labelAttributes: PropTypes.object,
}

export function MultiInputMergeWidget({
  fieldname,
  leftValue,
  rightValue,
  onChange,
  onValid,
  allowDuplicate,
  renderChip,
  position,
  useQueryHook = () => null,
}) {
  const [localSelected, setLocalSelected] = useState(position)
  const [content, setContent] = useState(
    position === 'left' ? leftValue : rightValue,
  )
  const suggestions = useQueryHook()

  useEffect(() => {
    onChange(fieldname, content)
    onValid(fieldname, content)
  }, [fieldname, content, onValid, onChange])

  return (
    <Flex width="100%" flexDirection="column">
      <Flex>
        <Flex flex="1 1" overflow="auto" alignItems="center">
          <StyledChips
            disabled={true}
            value={leftValue}
            itemTemplate={renderChip}
          />
        </Flex>
        <Checkbox
          icons={[MdRadioButtonChecked, MdRadioButtonUnchecked]}
          checked={localSelected === 'left'}
          onChecked={checked => {
            if (checked) {
              setLocalSelected('left')
              setContent(leftValue)
            }
          }}
          color={theme.colors.icon}
          style={{ flex: '0 1' }}
        />
        <Box width="1" bg="veryLightGrey" />
        <Checkbox
          icons={[MdRadioButtonChecked, MdRadioButtonUnchecked]}
          checked={localSelected === 'right'}
          onChecked={checked => {
            if (checked) {
              setLocalSelected('right')
              setContent(rightValue)
            }
          }}
          color={theme.colors.icon}
          style={{ flex: '0 1' }}
        />
        <Flex flex="1 1" overflow="auto" alignItems="center">
          <StyledChips
            disabled={true}
            value={rightValue}
            itemTemplate={renderChip}
          />
        </Flex>
      </Flex>
      <Box my="2">
        <StyledChips
          value={content}
          itemTemplate={renderChip}
          allowDuplicate={allowDuplicate}
          onChange={({ value }) => {
            const splitted = flatMap(value, item => split(item, /[\s]/))
            // allowDuplicate prop of Chips does not work. Do the filtering
            // manually.
            if (!allowDuplicate && size(uniq(splitted)) !== size(splitted))
              return
            setContent(splitted)
          }}
        />
        <Flex width="100%" justifyContent="flex-end">
          {map(suggestions, ({ label, value }, index) => (
            <Button
              key={index}
              label={label}
              style={{ marginRight: '5px' }}
              onClick={() => {
                setContent([...content, value])
              }}
            />
          ))}
        </Flex>
      </Box>
    </Flex>
  )
}

MultiInputMergeWidget.propTypes = {
  fieldname: PropTypes.string.isRequired,
  leftValue: PropTypes.arrayOf(PropTypes.string).isRequired,
  rightValue: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  onValid: PropTypes.func.isRequired,
  allowDuplicate: PropTypes.bool,
  renderChip: PropTypes.func.isRequired,
  position: PropTypes.oneOf(['left', 'right']),
  useQueryHook: PropTypes.func,
}
