import { useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { isEmpty } from 'lodash'
import { Flex } from '@changingai/react-editor-common-component'

import {
  isTicketViewable,
  isWaitingTicketAccessRight,
  ProjectContext,
} from './common'
import { useTickets } from './view'
import Pikachu from './img/not-assigned.jpg'

const CenterContainer = styled(Flex)``

CenterContainer.defaultProps = {
  width: '100%',
  justifyContent: 'center',
}

export function TicketDoc({ namespace, ticketId, Wrapped, searchIds }) {
  const [tickets, loading] = useTickets({
    ticket_ids: [ticketId],
    namespace,
  })

  const { privilege, email } = useContext(ProjectContext)

  if (isEmpty(tickets) || loading) return null
  if (isWaitingTicketAccessRight(tickets[0], email)) {
    return (
      <>
        <CenterContainer>
          <img alt="等待首位編輯者交回ticket" src={Pikachu} />
        </CenterContainer>
        <CenterContainer>
          <h2>{`等待首位編輯者交回ticket`}</h2>
        </CenterContainer>
      </>
    )
  }

  if (!isTicketViewable(tickets[0], privilege)) {
    return (
      <>
        <CenterContainer>
          <img alt="Ticket尚未分配" src={Pikachu} />
        </CenterContainer>
        <CenterContainer>
          <h2 data-testid="no-privilege">{`Ticket: ${ticketId} - 尚未分配`}</h2>
        </CenterContainer>
      </>
    )
  }

  return <Wrapped ticket={tickets[0]} searchIds={searchIds} />
}

TicketDoc.propTypes = {
  ticketId: PropTypes.string.isRequired,
  namespace: PropTypes.string.isRequired,
  Wrapped: PropTypes.func.isRequired,
  searchIds: PropTypes.arrayOf(PropTypes.string),
}
