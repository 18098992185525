import PropTypes from 'prop-types'
import { partial } from 'lodash'

import { namespace, schema } from './config'
import { NinjaEditor } from './editor'
import { ninjaSchema } from './schema'
import { getNoTicketDocPath } from '../Common'
import { useFetchDoc } from '../DocHelper'
import { referenceDoc } from '../Reference'
import { EditorLoader } from '../SchemaBaseEditorLoader'
import { _queryNinja } from './loader.gql'

const getPath = partial(getNoTicketDocPath, namespace, schema)

export const queryManifest = {
  gql: _queryNinja,
  name: 'queryNinja',
  getPath,
}

export const generateReferenceDoc = partial(
  referenceDoc,
  'name',
  getPath,
  schema,
  namespace,
)

export function NinjaEditorLoader({ docId }) {
  return (
    <EditorLoader
      DialogComp={NinjaEditor}
      useFetchDocAndTicket={partial(
        useFetchDoc,
        _queryNinja,
        'queryNinja',
        docId,
      )}
      currentSchema={ninjaSchema}
    />
  )
}

NinjaEditorLoader.propTypes = {
  docId: PropTypes.string.isRequired,
}

export default NinjaEditorLoader
