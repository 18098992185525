import PropTypes from 'prop-types'
import { Dialog } from 'primereact/dialog'
import { Tag } from 'primereact/tag'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { map, last, uniq, filter, get, toUpper, isEmpty, find } from 'lodash'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { isMongoId } from '@common'
import { Box } from '@changingai/react-editor-common-component'
import { SchemaLabels } from '@schema'
import { cceClient } from '@gql'

import { getViewManifest } from './Config'

import { _queryTickets } from './ReferenceDialog.gql'

export function ReferenceDialog({ refDocs, onHide }) {
  const { data } = useQuery(_queryTickets, {
    client: cceClient,
    variables: {
      filter: {
        ticket_ids: uniq(
          filter(
            map(refDocs, ({ listviewUrl }) => last(listviewUrl.split('/'))),
            isMongoId,
          ),
        ),
      },
      // Assume all refDocs are belonging to the same namespace,
      // so we pick the namespace of the first refDoc.
      namespace: toUpper(get(refDocs, '0.namespace')),
    },
    skip: isEmpty(refDocs),
  })
  return (
    <Dialog
      header="Reference"
      appendTo={document.body}
      style={{ width: '1000px' }}
      modal={true}
      onHide={onHide}
      visible={true}
    >
      <Box height="60vh" width="100%" overflow="auto">
        <DataTable
          value={refDocs}
          rowGroupMode="rowspan"
          sortField="schema"
          sortOrder={1}
        >
          <Column
            header="文件型態"
            field="schema"
            style={{ width: '150px' }}
            body={({ schema }) => <Box>{SchemaLabels[schema]}</Box>}
          />
          <Column
            header="Ticket"
            body={({ schema, listviewUrl, docUrl }) => {
              const docId = last(docUrl.split('/'))
              const ticketId = last(listviewUrl.split('/'))
              const ticket = find(get(data, 'queryTickets'), { _id: ticketId })
              const enableVersion = get(
                find(getViewManifest(), { schema }),
                'ticketProfile.enableVersion',
                false,
              )
              const year = new Date().getFullYear().toString()
              const outOfDate = enableVersion && get(ticket, 'version') !== year

              return (
                <>
                  {outOfDate && <Tag value="過期" severity="warning" />}
                  <Link
                    to={`${listviewUrl}?search=${docId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {get(ticket, 'name', ticketId)}
                  </Link>
                </>
              )
            }}
          />
          <Column
            header="文件"
            body={({ name, docUrl }) => (
              <Link to={docUrl} target="_blank" rel="noopener noreferrer">
                {name}
              </Link>
            )}
          />
        </DataTable>
      </Box>
    </Dialog>
  )
}

ReferenceDialog.propTypes = {
  refDocs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      docUrl: PropTypes.string.isRequired,
      listviewUrl: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onHide: PropTypes.func.isRequired,
}
