import { useState, useCallback, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import produce from 'immer'
import uuid from 'react-uuid'
import 'styled-components/macro'
import css from '@styled-system/css'

import { useImmer } from 'use-immer'
import {
  get,
  isEmpty,
  isEqual,
  findIndex,
  map,
  filter,
  size,
  keys,
  forEach,
  compact,
  isNil,
  noop,
  zip,
} from 'lodash'
import { Button } from 'primereact/button'
import { DataView } from 'primereact/dataview'
import { TabView, TabPanel } from 'primereact/tabview'
import { FaPlus, FaRegClone } from 'react-icons/fa'
import { FiPlusSquare } from 'react-icons/fi'
import { AiOutlineMinusCircle } from 'react-icons/ai'
import { ImArrowLeft, ImArrowRight } from 'react-icons/im'
import {
  Flex,
  Box,
  StyledOverlay,
} from '@changingai/react-editor-common-component'

import { createBlankDoc } from '@schema'
import theme from '@theme'

import { ButtonLike } from './button'
import { Card } from './layout'
import { inputFactory } from './InputFactory'
import {
  useRenderConfigBase,
  useRenderMergeConfigBase,
} from './ConfigRenderBase'

import { DefaultMergeWidget } from './common'

const InputRoot = styled(Flex)``

InputRoot.defaultProps = {
  width: '100%',
  flexWrap: 'wrap',
  margin: 0,
  position: 'relative',
}

const ToolBar = styled(Flex)``

ToolBar.defaultProps = {
  alignItems: 'flex-end',
  position: 'absolute',
  right: '-5px',
}

function SingleSubDocInput({
  onChange,
  onDirty,
  onError,
  context,
  currentSchema,
  itemConfigs,
  currentPath,
}) {
  const [editContext, setEditContext] = useImmer(context)
  useEffect(() => {
    if (!isEqual(editContext, context)) {
      onChange(editContext)
    }
  }, [editContext, context, onChange, currentSchema])

  const renderer = useRenderConfig({
    onError,
    onUpdateContext: setEditContext,
    onDirty,
    editContext: context,
    currentSchema,
    currentPath,
  })

  const configs = useMemo(
    () =>
      !isEmpty(itemConfigs)
        ? itemConfigs
        : map(keys(currentSchema), fieldname =>
            inputFactory(currentSchema, fieldname),
          ),
    [itemConfigs, currentSchema],
  )

  return <Box width="100%">{configs.map(config => renderer(config))}</Box>
}

SingleSubDocInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onDirty: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
  currentSchema: PropTypes.object.isRequired,
  itemConfigs: PropTypes.array,
  currentPath: PropTypes.array.isRequired,
}

export const StyledDataView = styled(DataView)`
  &&& {
    width: 100%;
    height: 500px;
  }
  & .p-grid {
    display: flex;
    flex-wrap: wrap;
  }
`

function compareArrayIds(onDirty, updated, origin) {
  forEach(zip(map(updated, 'id'), origin), ([id, oldId]) => {
    onDirty(id, id !== oldId)
  })
}

export function SubDocInput({
  fieldname,
  label,
  context,
  onDirty,
  onChange,
  onValid,
  onError,
  onClone,
  min = 0,
  currentSchema,
  itemConfigs,
  listview,
  itemStyle,
  defaultValue,
  showCaption = true,
  onCanDelete,
  currentPath = [],
}) {
  const [initIds, setInitIds] = useState()
  const [activeIndex, setActiveIndex] = useState(
    isEmpty(context) ? 0 : size(context) - 1,
  )

  useEffect(() => {
    const ids = map(context, 'id')
    if (size(ids) !== size(compact(ids))) {
      // This hook is called first time.
      const idInjectedContext = map(context, item => ({
        ...item,
        id: get(item, 'id', uuid()),
      }))
      onChange(fieldname, idInjectedContext, currentSchema)
      setInitIds(map(idInjectedContext, 'id'))
    }
  }, [context, currentSchema, fieldname, onChange, setInitIds])

  useEffect(() => {
    onError(
      `${fieldname}-minsize`,
      size(context) < min,
      `${fieldname}至少需有${min}個`,
    )
  }, [context, fieldname, currentSchema, onError, min])

  const onLocalChange = useCallback(
    value => {
      const result = produce(context, draft => {
        const index = findIndex(draft, { id: value.id })
        draft[index] = value
      })
      onChange(fieldname, result, currentSchema)
    },
    [fieldname, onChange, currentSchema, context],
  )

  const onInsertDoc = useCallback(
    items => {
      const created = map(
        isEmpty(items) ? [createBlankDoc(currentSchema, false)] : items,
        item => ({ ...item, id: get(item, 'id', uuid()) }),
      )

      onChange(fieldname, [...context, ...created], currentSchema)
      created.forEach(item => onDirty(item.id, true))
      setActiveIndex(size(context))
    },
    [fieldname, onDirty, onChange, currentSchema, context],
  )

  const onDeleteDoc = useCallback(
    deletedContext => {
      if (!onCanDelete(deletedContext)) return

      // Context can be
      //  1. existed in editContext already or
      //  2. a new created one in this editing session.
      //
      // When deleting #1, we should set editContext dirty since we remove an
      // exited element from it. When deleting #2, which make editContext dirty
      // when it was created, we should clean out the dirty flag from editContext.
      onDirty(deletedContext.id, 'toggle')

      // Unset the error flag before deleting it.
      forEach(keys(currentSchema), key => {
        onValid(key, null, {
          clean: true,
          key: deletedContext.id,
          currentSchema,
        })
      })

      const updated = produce(context, draft =>
        filter(draft, ({ id }) => id !== deletedContext.id),
      )

      compareArrayIds(onDirty, updated, initIds)

      onChange(fieldname, updated, currentSchema)
      if (activeIndex !== 0) setActiveIndex(activeIndex - 1)
    },
    [
      initIds,
      context,
      onDirty,
      currentSchema,
      onChange,
      fieldname,
      onValid,
      activeIndex,
      onCanDelete,
    ],
  )

  const onChangeIndex = useCallback(
    newIndex => {
      const updated = produce(context, draft => {
        ;[draft[newIndex], draft[activeIndex]] = [
          draft[activeIndex],
          draft[newIndex],
        ]
      })
      compareArrayIds(onDirty, updated, initIds)
      onChange(fieldname, updated, currentSchema)
      setActiveIndex(newIndex)
    },
    [
      activeIndex,
      context,
      fieldname,
      onChange,
      onDirty,
      initIds,
      currentSchema,
    ],
  )

  const [cloning, setCloning] = useState(false)
  if (isEmpty(context)) {
    return (
      <Box width="100%" my="2">
        {showCaption && (
          <Flex
            fontSize="h1"
            bg="blacks.2"
            border="1px solid gray"
            my="1"
            py="2"
            justifyContent="center"
          >
            {label}
          </Flex>
        )}
        <InputRoot justifyContent="center" border="1px solid darkgray" my="2">
          <ButtonLike>
            <FaPlus
              style={{
                color: theme.colors.icon,
                width: `${theme.sizes[7]}px`,
                height: `${theme.sizes[7]}px`,
              }}
              onClick={() => onInsertDoc(defaultValue)}
            />
          </ButtonLike>
        </InputRoot>
      </Box>
    )
  }

  return (
    <Box
      css={css({
        '& .p-tabview .p-tabview-panels': {
          bg: theme.colors.panelBG,
          p: 1,
        },
        '&& .p-dataview .p-dataview-content': {
          bg: theme.colors.panelBG,
        },
      })}
      width="100%"
      height="auto"
      position="relative"
      my="1"
      pb="2"
    >
      <StyledOverlay active={cloning} text="Cloning..." />
      <Flex
        fontSize="h1"
        bg="blacks.2"
        border="1px solid gray"
        my="1"
        py="2"
        justifyContent="center"
        alignItem="center"
        height="45px"
      >
        {showCaption ? label : ''}
      </Flex>
      <ToolBar top="0">
        {onClone && !listview && (
          <ButtonLike
            onClick={async () => {
              setCloning(true)
              await onClone(context, activeIndex, onInsertDoc)
              setCloning(false)
            }}
            scaleOnHover
          >
            <FaRegClone
              style={{
                color: theme.colors.icon,
                width: theme.sizes[7],
                height: theme.sizes[7],
              }}
            />
          </ButtonLike>
        )}
        <ButtonLike onClick={() => onInsertDoc(defaultValue)} scaleOnHover>
          <FiPlusSquare
            style={{
              color: theme.colors.icon,
              width: theme.sizes[7],
              height: theme.sizes[7],
            }}
          />
        </ButtonLike>
        {!listview && (
          <>
            <ButtonLike
              onClick={() => onDeleteDoc(context[activeIndex])}
              scaleOnHover
              disabled={
                size(context) === min || !onCanDelete(context[activeIndex])
              }
            >
              <AiOutlineMinusCircle
                style={{
                  color: theme.colors.danger,
                  width: theme.sizes[7],
                  height: theme.sizes[7],
                }}
              />
            </ButtonLike>
            <ButtonLike
              disabled={activeIndex === 0}
              scaleOnHover
              onClick={() => onChangeIndex(activeIndex - 1)}
            >
              <ImArrowLeft
                style={{
                  color: theme.colors.icon,
                  width: theme.sizes[7],
                  height: theme.sizes[7],
                }}
              />
            </ButtonLike>
            <ButtonLike
              disabled={activeIndex >= size(context) - 1}
              scaleOnHover
              onClick={() => onChangeIndex(activeIndex + 1)}
            >
              <ImArrowRight
                style={{
                  color: theme.colors.icon,
                  width: theme.sizes[7],
                  height: theme.sizes[7],
                }}
              />
            </ButtonLike>
          </>
        )}
      </ToolBar>
      {listview && (
        <Flex
          width="100%"
          flexWrap="wrap"
          alignContent="flex-start"
          overflowY="auto"
        >
          <StyledDataView
            value={context}
            itemTemplate={context => {
              if (!context) return null
              return (
                <Card mr="1" style={itemStyle} key={context.id}>
                  <Flex
                    width="100%"
                    height="40px"
                    justifyContent="flex-end"
                    alignItems="center"
                    p="2"
                    bg="blacks.2"
                  >
                    <Button
                      style={{ width: '20px', height: '20px' }}
                      icon="pi pi-times"
                      className="p-button-rounded p-button-danger p-button-outlined"
                      disabled={!onCanDelete(context)}
                      onClick={() => {
                        onDeleteDoc(context)
                      }}
                    />
                  </Flex>
                  <SingleSubDocInput
                    onChange={onLocalChange}
                    onDirty={onDirty}
                    onError={onError}
                    context={context}
                    currentSchema={currentSchema}
                    itemConfigs={itemConfigs}
                    currentPath={currentPath}
                  />
                </Card>
              )
            }}
          />
        </Flex>
      )}
      {/*
        renderActiveOnly must be true. Otherwise, input widget on the invisible
        node will be destructed, so the error or dirty flags of those input
        will be wipe out from useDirtyStore.
      */}
      {!listview && (
        <TabView
          style={{ width: '100%' }}
          activeIndex={activeIndex}
          onTabChange={({ index }) => setActiveIndex(index)}
          renderActiveOnly={false}
        >
          {map(context, (context, index) => (
            <TabPanel key={context.id || index} header={index.toString()}>
              <InputRoot>
                <SingleSubDocInput
                  onChange={onLocalChange}
                  onDirty={onDirty}
                  onError={onError}
                  context={context}
                  currentSchema={currentSchema}
                  itemConfigs={itemConfigs}
                  currentPath={currentPath}
                />
              </InputRoot>
            </TabPanel>
          ))}
        </TabView>
      )}
    </Box>
  )
}

SubDocInput.propTypes = {
  fieldname: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  min: PropTypes.oneOf([0, 1]).isRequired,
  context: PropTypes.array.isRequired,
  onDirty: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onValid: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onClone: PropTypes.func,
  hightlights: PropTypes.arrayOf(PropTypes.string),
  currentSchema: PropTypes.object.isRequired,
  itemConfigs: PropTypes.array,
  listview: PropTypes.bool.isRequired,
  itemStyle: PropTypes.object.isRequired,
  defaultValue: PropTypes.arrayOf(PropTypes.object),
  showCaption: PropTypes.bool,
  onCanDelete: PropTypes.func.isRequired,
  currentPath: PropTypes.array,
}

function generateSubDocInputProps(context, label, config) {
  return {
    context,
    currentSchema: get(config, 'currentSchema'),
    defaultValue: get(config, 'defaultValue', []),
    fieldname: get(config, 'fieldname'),
    itemConfigs: map(config.itemConfigs, inputConfig => ({
      ...inputConfig,
      fieldname: config.fieldname,
    })),
    itemStyle: get(config, 'itemStyle', {}),
    label,
    listview: get(config, 'listview', false),
    min: get(config, 'min', 0),
    onCanDelete: get(config, 'onCanDelete', () => true),
    onClone: get(config, 'onClone'),
    showCaption: get(config, 'showCaption', true),
  }
}

export function useRenderMergeConfig({
  fieldname,
  currentSchema,
  onUpdateContext,
  editContext,
  leftValue,
  rightValue,
  onError,
  position,
}) {
  const context = useMemo(() => {
    if (isNil(editContext))
      return position === 'left' ? leftValue[fieldname] : rightValue[fieldname]
    return editContext
  }, [editContext, position, leftValue, rightValue, fieldname])

  const extenedRender = useCallback(
    (commonProps, config, onChange, onValid, onError) => {
      if (config.widget === 'SubDocInput') {
        return (
          <SubDocInput
            {...generateSubDocInputProps(
              context,
              get(currentSchema, `${config.fieldname}.label`),
              config,
            )}
            key={get(config, 'fieldname')}
            onDirty={noop}
            onChange={onChange}
            onValid={onValid}
            onError={onError}
          />
        )
      }
      return <DefaultMergeWidget {...commonProps} />
    },
    [currentSchema, context],
  )

  const render = useRenderMergeConfigBase({
    fieldname,
    currentSchema,
    onUpdateContext,
    leftValue,
    rightValue,
    onError,
    position,
    extenedRender,
  })

  return render
}

export function useRenderConfig({
  onError,
  onUpdateContext,
  onDirty,
  editContext,
  currentSchema,
  currentPath,
}) {
  const extenedRender = useCallback(
    (config, onChange, onValid, onError, currentPath) => {
      if (config.widget === 'SubDocInput') {
        return (
          <SubDocInput
            {...generateSubDocInputProps(
              editContext[config.fieldname],
              get(currentSchema, `${config.fieldname}.label`),
              config,
            )}
            key={get(config, 'fieldname')}
            onDirty={onDirty}
            onChange={onChange}
            onValid={onValid}
            onError={onError}
            currentPath={currentPath}
          />
        )
      }
    },
    [editContext, onDirty, currentSchema],
  )

  const render = useRenderConfigBase({
    onError,
    onUpdateContext,
    onDirty,
    editContext,
    currentSchema,
    extenedRender,
    currentPath,
  })

  return render
}
