import { createGQLClient } from './Apollo'
import kgFragmentTypes from './kgFragmentTypes.json'
import cceFragmentTypes from './cceFragmentTypes.json'

export function getPrefix() {
  const path = process.env.REACT_APP_BASE_PATH
  const loc = window.location.pathname.search(path)
  if (loc !== -1) {
    return window.location.pathname.substring(0, loc)
  }

  return ''
}

const backendPath = '/myfinance-private-api/graphql'
function getBackendUrl() {
  return window.location.protocol === 'http:'
    ? process.env.REACT_APP_BACKEND_URL + backendPath
    : window.location.origin + getPrefix() + backendPath
}

const kgBackendPath = '/knowledge/graphql'
function getKGBackendUrl() {
  return window.location.protocol === 'http:'
    ? process.env.REACT_APP_KG_URL + kgBackendPath
    : window.location.origin + getPrefix() + kgBackendPath
}

export const cceClient = createGQLClient(getBackendUrl(), cceFragmentTypes)
export const kgClient = createGQLClient(getKGBackendUrl(), kgFragmentTypes, {
  watchQuery: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
})
