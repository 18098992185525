import { useRef, useContext, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { difference, isEmpty, merge } from 'lodash'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import { FaTasks } from 'react-icons/fa'
import { OverlayPanel } from 'primereact/overlaypanel'

import { Flex, Box, Text } from '@changingai/react-editor-common-component'

import { ProjectContext } from '@common'
import { useFieldSelectionPanel, ButtonLike, Checkbox } from '@widget'

import { findSummaryLayoutSchema } from './SummaryFields'
import Pikachu from '../img/not-assigned.jpg'

const CenterContainer = styled(Flex)``

CenterContainer.defaultProps = {
  width: '100%',
  justifyContent: 'center',
}

const RootDiv = styled(Flex)``

RootDiv.defaultProps = {
  height: '100%',
  flex: '0 0 100%',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignContent: 'flex-start',
}

const MenuBar = styled(Flex)``

MenuBar.defaultProps = {
  color: 'white',
  px: 4,
  alignItems: 'center',
  width: '100%',
}

export const MenuIcon = styled(Flex)``

MenuIcon.defaultProps = {
  alignItems: 'stretch',
  flex: '0 0 auto',
  my: 2,
}

export function FloatingMenu(props) {
  return (
    <Box zIndex="7" data-cy={`${props.position}-floating-menu`}>
      <MenuBar
        width="70px"
        top="30px"
        left={props.position == 'left' ? '0' : 'auto'}
        right={props.position == 'right' ? '0' : 'auto'}
        flexWrap="wrap"
        position="fixed"
        justifyContent="center"
        p="2"
      >
        {props.render()}
      </MenuBar>
    </Box>
  )
}

FloatingMenu.propTypes = {
  render: PropTypes.func.isRequired,
  position: PropTypes.string,
}

FloatingMenu.defaultProps = {
  position: 'left',
}

export function ViewRoot({ children, required }) {
  const { privilege } = useContext(ProjectContext)
  if (!isEmpty(difference(required, privilege))) {
    return (
      <>
        <CenterContainer>
          <img alt="無讀取Contract權限" src={Pikachu} />
        </CenterContainer>
        <CenterContainer>
          <Text>{`無讀取Contract權限`}</Text>
        </CenterContainer>
      </>
    )
  }
  return <RootDiv>{children}</RootDiv>
}

ViewRoot.propTypes = {
  required: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired,
}

export function useFieldSelectionMenuIcon({
  currentSchema,
  defaultSummary = {},
  storgeKeyPostfix,
  headerComponents,
}) {
  const fieldSelector = useRef(null)

  const onHidePanel = useCallback(() => {
    fieldSelector.current.hide()
  }, [fieldSelector])

  const layoutSchema = useMemo(
    () => merge(findSummaryLayoutSchema(currentSchema), currentSchema),
    [currentSchema],
  )

  const [selectedFields, renderFieldSelectorPanel] = useFieldSelectionPanel({
    currentSchema,
    layoutSchema,
    defaultSummary,
    onOk: onHidePanel,
    onCancel: onHidePanel,
    headerComponents,
    storgeKeyPostfix,
  })

  const FieldSelectionMenuButton = useCallback(
    () => (
      <MenuIcon>
        <ButtonLike
          data-cy="select-field"
          data-tip="顯示欄位"
          size={6}
          scaleOnHover
          onClick={e => fieldSelector.current.toggle(e)}
        >
          <FaTasks />
        </ButtonLike>
        <OverlayPanel ref={fieldSelector} appendTo={document.body}>
          {renderFieldSelectorPanel()}
        </OverlayPanel>
      </MenuIcon>
    ),
    [fieldSelector, renderFieldSelectorPanel],
  )
  return { FieldSelectionMenuButton, selectedFields }
}

const ToastRoot = styled.div`
  white-space: pre-wrap;
`

export function PeepModeIcon({ peepMode, setPeepMode }) {
  const { privilege } = useContext(ProjectContext)

  if (!privilege.includes('supervisor')) return null

  return (
    <Checkbox
      icons={[FiEye, FiEyeOff]}
      checked={peepMode}
      onChecked={setPeepMode}
      color="yellow"
      disableColor="Gray"
    />
  )
}

PeepModeIcon.propTypes = {
  peepMode: PropTypes.bool.isRequired,
  setPeepMode: PropTypes.func.isRequired,
}

export function DuplicateToast({ dups }) {
  return (
    <ToastRoot>
      {<ToastRoot>重複優惠：</ToastRoot>}
      {dups.map(({ upload_id, duplicated_ids }) => {
        return (
          <>
            <div>{`upload: ${upload_id}`}</div>
            <div>{`duplicated: ${JSON.stringify(duplicated_ids)}`}</div>
          </>
        )
      })}
    </ToastRoot>
  )
}

DuplicateToast.propTypes = {
  dups: PropTypes.arrayOf(
    PropTypes.shape({
      upload_id: PropTypes.string.isRequired,
      duplicated_ids: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export const DataViewHost = styled(Box)`
  & .p-grid {
    display: flex;
    flex-wrap: wrap;
  }
`

DataViewHost.defaultProps = {
  width: '100%',
}
