import { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import 'styled-components/macro'
import css from '@styled-system/css'
import { Dropdown } from 'primereact/dropdown'
import uuid from 'react-uuid'
import { find, get } from 'lodash'
import { Box, Flex } from '@changingai/react-editor-common-component'

import { NodesType, useInit, useError } from './common'
import {
  InputContainer,
  InputRoot,
  WidgetContainer,
  RowRoot,
} from './StyledComps'
import { ToastifyLabel } from './ToastifyLabel'

export function SingleSelect({
  fieldname,
  label,
  value,
  nodes,
  useQueryHook = () => null,
  onChange,
  onDirty,
  onValid = () => true,
  placeholder = '',
  disabled = false,
  hightlights = [],
  labelAttributes = { width: '150px' },
  inputAttributes = { width: '50%' },
  editable = true,
  // TBD: remove filterNodes.
  filterNodes = null,
  itemTemplate,
  valueTemplate,
}) {
  const items = useQueryHook()
  const [, compare] = useInit(value)
  const [id] = useState(uuid())
  const error = useError(onValid, id, fieldname, value, editable)

  const options = useMemo(
    () => [
      {
        // empty item
        label: '',
        value: '',
      },
      ...(nodes || items
        ? (nodes || items)
            .filter(({ id }) => !filterNodes || filterNodes.includes(id))
            .map(node => ({
              label: node.name,
              value: node,
            }))
        : []),
    ],
    [items, nodes, filterNodes],
  )

  const [selected, setSelected] = useState(null)
  useEffect(() => {
    setSelected(
      get(find(options, option => get(option, 'value.id') === value), 'value'),
    )
    onDirty(id, compare(value))
  }, [options, value, setSelected, onDirty, id, compare])

  useEffect(() => {
    return function cleanupDirtyAndError() {
      onDirty(id, false)
      onValid(fieldname, null, { clean: true, key: id })
    }
  }, [fieldname, onDirty, onValid, id])

  // To re-select original node after options been regenerated.
  // One use case is filterNodes might be altered after we selected
  // a new option, which leads to options been generated again.
  useEffect(() => {
    if (!!selected && !options.map(({ value }) => value).includes(selected)) {
      const matched = options.find(({ value }) => value.id === selected.id)
      setSelected(get(matched, 'value', null))
    }
  }, [options, selected, setSelected])

  const changed = selected ? compare(selected.id) : false
  const showHighlight = hightlights.includes(fieldname)
  const {
    maxHeight,
    itemPadding,
    ...inputAttributesWithoutMaxHeight
  } = inputAttributes

  return (
    <WidgetContainer {...inputAttributesWithoutMaxHeight}>
      <InputRoot hightlight={showHighlight}>
        <ToastifyLabel
          fieldname={fieldname}
          label={label}
          error={error}
          changed={changed}
          editable={editable}
          {...labelAttributes}
        />
        {editable && (
          <InputContainer
            css={css({
              ...(itemPadding === 'small'
                ? {
                    '& .p-dropdown-panel .p-dropdown-items .p-dropdown-item': {
                      padding: '0.3rem 0.3rem',
                    },
                  }
                : {}),
              ...(maxHeight
                ? {
                    '& .p-dropdown-items-wrapper': {
                      maxHeight: `${maxHeight} !important`,
                    },
                  }
                : {}),
            })}
          >
            <Dropdown
              value={selected}
              options={options}
              filter={get(options, 'length', 0) > 10}
              onChange={({ value }) => {
                setSelected(value)
                onChange(fieldname, value.id)
                onDirty(id, compare(value.id))
              }}
              placeholder={placeholder}
              style={{ width: '100%' }}
              disabled={disabled}
              itemTemplate={itemTemplate}
              valueTemplate={
                valueTemplate &&
                (option => valueTemplate(option, { placeholder }))
              }
            />
          </InputContainer>
        )}
      </InputRoot>
    </WidgetContainer>
  )
}

SingleSelect.propTypes = {
  fieldname: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  nodes: NodesType,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onDirty: PropTypes.func.isRequired,
  onValid: PropTypes.func,
  disabled: PropTypes.bool,
  hightlights: PropTypes.arrayOf(PropTypes.string),
  filterNodes: PropTypes.arrayOf(PropTypes.string),
  editable: PropTypes.bool,
  useQueryHook: PropTypes.func,
  labelAttributes: PropTypes.shape({
    width: PropTypes.string,
  }),
  inputAttributes: PropTypes.shape({
    width: PropTypes.string,
    maxHeight: PropTypes.string,
    itemPadding: PropTypes.string,
  }),
  itemTemplate: PropTypes.func,
  valueTemplate: PropTypes.func,
}

export function SingleSelectMergeWidget({
  fieldname,
  leftValue,
  rightValue,
  onChange,
  useQueryHook,
  onValid,
}) {
  const items = useQueryHook()
  const [content, setContent] = useState(null)

  const options = useMemo(
    () =>
      items
        ? items.map(node => ({
            label: node.name,
            value: node,
          }))
        : [],
    [items],
  )

  return (
    <Flex flexDirection="column" width="100%">
      <RowRoot>
        <Box fontSize="h1">Left</Box>
        <Box fontSize="h1">Result</Box>
        <Box fontSize="h1">Right</Box>
      </RowRoot>
      <RowRoot>
        <Flex justifyContent="center" m="2" flex="1 1" fontSize="h1">
          {get(find(items, { id: leftValue }), 'name', '🈳')}
        </Flex>
        <Dropdown
          value={content}
          options={options}
          filter={get(options, 'length', 0) > 10}
          onChange={({ value }) => {
            setContent(value)
            onChange(fieldname, value.id)
            onValid(fieldname, value.id)
          }}
          style={{ flex: '1 1', width: '100%' }}
        />
        <Flex justifyContent="center" m="2" flex="1 1" fontSize="h1">
          {get(find(items, { id: rightValue }), 'name', '🈳')}
        </Flex>
      </RowRoot>
    </Flex>
  )
}

SingleSelectMergeWidget.propTypes = {
  fieldname: PropTypes.string.isRequired,
  leftValue: PropTypes.array.isRequired,
  rightValue: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  useQueryHook: PropTypes.func.isRequired,
  onValid: PropTypes.func.isRequired,
}
