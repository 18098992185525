import { useState, useMemo, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { TreeTable } from 'primereact/treetable'
import { InputText } from 'primereact/inputtext'
import { ListBox } from 'primereact/listbox'
import uuid from 'react-uuid'
import {
  cloneDeep,
  sortBy,
  fromPairs,
  map,
  keys,
  size,
  intersection,
  isEmpty,
  uniq,
  compact,
  concat,
  filter,
  includes,
} from 'lodash'
import { AiFillPlusCircle } from 'react-icons/ai'

import { Flex, Box } from '@changingai/react-editor-common-component'
import { graphVisitor } from '@common'
import theme from '@theme'

import { useInit, SelectionList } from './common'
import { ColumnRoot } from './StyledComps'
import { ToastifyLabel } from './ToastifyLabel'

const StyledTreeTableHolder = styled(Box)`
  && .p-treetable .p-treetable-tbody > tr > td {
    padding: 0;
    border: none;
  }
  && .p-treetable .p-treetable-tbody > tr {
    background-color: transparent;
  }
  && .p-treetable .p-treetable-thead > tr > th {
    padding: 0;
  }
  && .p-treetable table {
    background-color: ${theme.colors.azures[1]};
  }
`

StyledTreeTableHolder.defaultProps = {
  width: '100%',
}

function StyledTreeTable({
  tree,
  nodes,
  header,
  options,
  expandAll = false,
  globalFilter = null,
  scrollHeight = '300px',
  selected,
  onSelectionChange,
  items,
}) {
  const selectionKeys = fromPairs(map(selected, s => [s, { checked: true }]))

  const [expandedKeys, setExpandedKeys] = useState({})
  useEffect(() => {
    if (expandAll)
      setExpandedKeys(fromPairs(map(nodes, ({ key }) => [key, true])))
  }, [expandAll, nodes])

  return (
    <StyledTreeTableHolder>
      <TreeTable
        expandedKeys={expandedKeys}
        onToggle={({ value }) => setExpandedKeys(value)}
        loading={!tree}
        header={header}
        globalFilter={globalFilter}
        value={options}
        propagateSelectionUp={false}
        propagateSelectionDown={false}
        metaKeySelection={false}
        scrollable
        scrollHeight={scrollHeight}
        selectionMode="checkbox"
        selectionKeys={selectionKeys}
        onSelectionChange={onSelectionChange}
      >
        <Column field="name" expander></Column>
      </TreeTable>
      <SelectionList selected={selected} items={items} />
    </StyledTreeTableHolder>
  )
}

StyledTreeTable.propTypes = {
  tree: PropTypes.object,
  nodes: PropTypes.arrayOf(PropTypes.object),
  header: PropTypes.node,
  options: PropTypes.arrayOf(PropTypes.object),
  expandAll: PropTypes.bool,
  globalFilter: PropTypes.bool,
  scrollHeight: PropTypes.string,
  selected: PropTypes.arrayOf(PropTypes.string),
  onSelectionChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
}

function useOptions(tree) {
  const [options, nodes, selectionItems] = useMemo(() => {
    if (!tree) return []
    // Do not use immer object since TreeTable may modify children array
    // of the options directly.
    const clone = cloneDeep(tree)
    const nodes = []
    graphVisitor(clone, node => {
      node.key = node.node_id
      node.data = {
        name: node.name,
      }
      if (node.children) node.children = sortBy(node.children, 'name')

      nodes.push(node)
    })

    const selectionItems = map(nodes, ({ name, node_id }) => ({
      name,
      id: node_id,
    }))

    return [clone.children, nodes, selectionItems]
  }, [tree])
  return [options, nodes, selectionItems]
}

export function TreeSelect({
  fieldname,
  label,
  onDirty,
  onChange,
  useQueryHook,
  selected,
  scrollHeight = '300px',
  expandAll = true,
  enableSearch = true,
  labelAttributes,
  inputAttributes,
}) {
  const [, compare] = useInit(selected || [])
  const [id] = useState(uuid())
  const tree = useQueryHook()
  const [options, nodes, selectionItems] = useOptions(tree)

  const [globalFilter, setGlobalFilter] = useState(null)
  const getHeader = useCallback(() => {
    return (
      <div className="p-text-right">
        <div className="p-input-icon-left">
          <i className="pi pi-search"></i>
          <InputText
            type="search"
            onInput={({ target: { value } }) => setGlobalFilter(value)}
            placeholder="Global Search"
            size="50"
          />
        </div>
      </div>
    )
  }, [setGlobalFilter])

  return (
    <Flex
      pr="2"
      width="100%"
      flexWrap="wrap"
      justifyContent="center"
      {...inputAttributes}
    >
      <Flex
        justifyContent="center"
        py="2"
        bg="blacks.2"
        border="1px solid gray"
        width="100%"
      >
        <ToastifyLabel
          fieldname={fieldname}
          changed={compare(selected)}
          label={`${label} (${size(selected)})`}
          error={false}
          editable={true}
          {...labelAttributes}
          width="auto"
        />
      </Flex>
      <StyledTreeTableHolder>
        <StyledTreeTable
          expandAll={expandAll}
          nodes={nodes}
          tree={tree}
          header={enableSearch ? getHeader() : null}
          globalFilter={globalFilter}
          options={options}
          scrollHeight={scrollHeight}
          selected={selected}
          onSelectionChange={({ value }) => {
            onChange(fieldname, keys(value))
            onDirty(id, compare(keys(value)))
          }}
          items={selectionItems}
        />
      </StyledTreeTableHolder>
    </Flex>
  )
}

TreeSelect.propTypes = {
  fieldname: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onDirty: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  useQueryHook: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.string),
  scrollHeight: PropTypes.string,
  expandAll: PropTypes.bool,
  enableSearch: PropTypes.bool,
  labelAttributes: PropTypes.object,
  inputAttributes: PropTypes.object,
}

export function TreeSelectMergeWidget({
  fieldname,
  leftValue,
  rightValue,
  onChange,
  useQueryHook,
  expandAll = true,
  onValid,
}) {
  const tree = useQueryHook()
  const [options, nodes, selectionItems] = useOptions(tree)
  const [content, setContent] = useState([])

  const [leftOptions, rightOptions] = useMemo(
    () =>
      map([leftValue, rightValue], values =>
        filter(
          nodes,
          ({ id }) => includes(values, id) && !includes(content, id),
        ),
      ),
    [leftValue, rightValue, content, nodes],
  )

  useEffect(() => {
    onChange(fieldname, content)
    onValid(fieldname, content)
  }, [content, fieldname, onValid, onChange])

  return (
    <Flex width="100%">
      <ColumnRoot>
        <Box fontSize="h1">Left</Box>
        <ListBox
          style={{ flex: '1 1 100%', borderRadius: 0, width: '100%' }}
          options={leftOptions}
          optionLabel="name"
          listStyle={{ height: '300px' }}
          itemTemplate={({ name }) => (
            <Flex justifyContent="space-between" alignItems="center">
              <Box>{name}</Box>
              <AiFillPlusCircle />
            </Flex>
          )}
          onChange={({ value: { id } }) => {
            setContent(uniq(concat(content, id)))
          }}
        />
        <SelectionList
          selected={map(leftOptions, 'id')}
          items={selectionItems}
        />
        <Button
          style={{ height: '30px' }}
          label="Add All"
          disabled={isEmpty(map(leftOptions, 'id'))}
          onClick={() =>
            setContent(compact(uniq(concat(content, map(leftOptions, 'id')))))
          }
        />
      </ColumnRoot>
      <ColumnRoot>
        <Box fontSize="h1">Result</Box>
        <StyledTreeTable
          expandAll={expandAll}
          nodes={nodes}
          tree={tree}
          options={options}
          selected={content}
          onSelectionChange={({ value }) => {
            setContent(keys(value))
          }}
          items={selectionItems}
        />
        <Button
          style={{ height: '30px' }}
          label="Pick Common"
          disabled={isEmpty(
            intersection(map(leftOptions, 'id'), map(rightOptions, 'id')),
          )}
          onClick={() =>
            setContent(
              intersection(map(leftOptions, 'id'), map(rightOptions, 'id')),
            )
          }
        />
      </ColumnRoot>
      <ColumnRoot>
        <Box fontSize="h1">Right</Box>
        <ListBox
          style={{ flex: '1 1 100%', borderRadius: 0, width: '100%' }}
          options={rightOptions}
          optionLabel="name"
          listStyle={{ height: '300px' }}
          itemTemplate={({ name }) => (
            <Flex justifyContent="space-between" alignItems="center">
              <Box>{name}</Box>
              <AiFillPlusCircle />
            </Flex>
          )}
          onChange={({ value: { id } }) => {
            setContent(uniq(concat(content, id)))
          }}
        />
        <SelectionList
          selected={map(rightOptions, 'id')}
          items={selectionItems}
        />
        <Button
          style={{ height: '30px' }}
          label="Add All"
          disabled={isEmpty(map(rightOptions, 'id'))}
          onClick={() =>
            setContent(compact(uniq(concat(content, map(rightOptions, 'id')))))
          }
        />
      </ColumnRoot>
    </Flex>
  )
}

TreeSelectMergeWidget.propTypes = {
  fieldname: PropTypes.string.isRequired,
  leftValue: PropTypes.arrayOf(PropTypes.string).isRequired,
  rightValue: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  useQueryHook: PropTypes.func.isRequired,
  expandAll: PropTypes.bool,
  onValid: PropTypes.func.isRequired,
}
