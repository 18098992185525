import { useMemo } from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { get, isEmpty, map } from 'lodash'
import styled from 'styled-components'
import { Box, Flex } from '@changingai/react-editor-common-component'

const Badge = styled(Box)`
  transform: translate(1em, -1em);
`

Badge.defaultProps = {
  position: 'absolute',
  bg: 'black',
  top: 1,
  left: 1,
  borderRadius: '50%',
  textAlign: 'center',
  display: 'inline-block',
  color: 'white',
  fontSize: '.75em',
  fontWeight: 700,
  minWidth: '1.5rem',
  height: '1.5rem',
  lineHeight: '1.5rem',
}

function IconWithBadge({ icon, badge }) {
  return (
    <Box position="relative">
      <i
        className={icon.className}
        style={{ fontSize: get(icon, 'size', '1em') }}
      ></i>
      <Badge bg={get(badge, 'bg', 'black')}>{get(badge, 'context', '')}</Badge>
    </Box>
  )
}

IconWithBadge.propTypes = {
  icon: PropTypes.object.isRequired,
  badge: PropTypes.object,
}

export function DirtyStorePanel({ dirties, errors, reasonsMap }) {
  const errorsBadge = useMemo(
    () => ({
      bg: 'red',
      context: errors.length,
    }),
    [errors],
  )

  const dirtiesBadge = useMemo(
    () => ({
      bg: 'blue',
      context: dirties.length,
    }),
    [dirties],
  )

  return (
    <Flex width="100%">
      <ReactTooltip place="top" type="error" id="dirtyStore">
        {isEmpty(errors) ? (
          <Box>No errors</Box>
        ) : (
          map(errors, err => {
            const reason = reasonsMap[err]
            return <Box key={err}>{reason ? reason : '未知錯誤'}</Box>
          })
        )}
      </ReactTooltip>
      <Flex flex="1 1 50%" data-tip data-for="dirtyStore" alignItems="center">
        <IconWithBadge
          icon={{
            className: 'pi pi-exclamation-triangle',
            size: '2em',
          }}
          badge={errorsBadge}
        />
        <Box color="red" mx={3}>
          {isEmpty(errors) ? '' : '請更正錯誤'}
        </Box>
      </Flex>
      <Flex flex="1 1 50%" alignItems="center" data-cy="dirty-store">
        <IconWithBadge
          icon={{
            className: 'pi pi-bell',
            size: '2em',
          }}
          badge={dirtiesBadge}
        />
        <Box color="blue" mx={3}>
          {isEmpty(dirties) ? '無修改' : ''}
        </Box>
      </Flex>
    </Flex>
  )
}

DirtyStorePanel.propTypes = {
  dirties: PropTypes.arrayOf(PropTypes.string).isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  reasonsMap: PropTypes.object.isRequired,
}
