import { useMemo, useState, useEffect, Fragment, useContext } from 'react'
import {
  isEmpty,
  mapValues,
  difference,
  map,
  find,
  isObject,
  keys,
  set,
  join,
  sum,
  size,
  forOwn,
  castArray,
  isNil,
  groupBy,
  uniq,
  has,
  fromPairs,
} from 'lodash'
import { useQuery } from '@apollo/client'
import { Box, Flex, Text } from '@changingai/react-editor-common-component'

import {
  computeType,
  useBuildKGTree,
  builtInRewards,
  KG_NODES,
  useGetConnectedNodes,
  ProjectContext,
} from '@common'
import { SchemaStore } from '@schema'
import { cceClient, kgClient } from '@gql'
import { HomePanel } from './common'
import { _query_kg, _querySystemUsedKGNodes } from './errors.gql'

function extractNodeId(node) {
  const nodes = []
  forOwn(node, value => {
    if (isObject(value)) nodes.push(...extractNodeId(value))
    else nodes.push(...castArray(value))
  })

  return nodes
}

function useKGError() {
  const [missedSystemNodes, setMissedSystemNodes] = useState(null)
  const { data: backendKGRef } = useQuery(_querySystemUsedKGNodes, {
    client: cceClient,
  })
  useEffect(() => {
    async function computeDiff() {
      const namespaceNodes = mapValues(KG_NODES, value => extractNodeId(value))
      const backendRefs = mapValues(
        groupBy(backendKGRef.querySystemUsedKGNodes, 'namespace'),
        pairs => map(pairs, 'node_id'),
      )
      keys(backendRefs).forEach(namespace => {
        namespaceNodes[namespace] = uniq([
          ...(namespaceNodes[namespace] || []),
          ...backendRefs[namespace],
        ])
      })
      const {
        data: { query_kg },
      } = await kgClient.query({
        query: _query_kg,
        variables: {
          namespace: 'config',
          query: {
            op: 'OR',
            conditions: [
              {
                op: 'includes',
                field: 'name',
                values: keys(namespaceNodes),
              },
            ],
          },
        },
      })
      const missed = {}
      keys(namespaceNodes).forEach(name => {
        const matched = find(query_kg, { name })
        const systemNodes = find(matched.attributes, { name: 'systemNodes' })
        const diff = difference(namespaceNodes[name], systemNodes.values)
        if (!isEmpty(diff)) set(missed, name, diff)
      })
      setMissedSystemNodes(missed)
    }
    if (!isNil(backendKGRef)) computeDiff()
  }, [setMissedSystemNodes, backendKGRef])

  return missedSystemNodes
}

function buildSchemaTreeFromStore() {
  return fromPairs(
    map(keys(SchemaStore), name => [name, { type: `objectOf(${name})` }]),
  )
}

function checkSuggestion(graph, currentSchema, errors) {
  for (const node of graph.children) {
    if (!has(currentSchema, node.name)) {
      errors.push(node.node_id)
    } else {
      checkSuggestion(
        node,
        computeType(currentSchema[node.name].type)?.extractedSchema,
        errors,
      )
    }
  }
}

function SuggestionError() {
  const suggestionsGraph = useBuildKGTree(
    KG_NODES.myfinance_editor.SUGGESTIONS_ROOT_ID,
    'myfinance_editor',
  )

  const suggestionError = useMemo(() => {
    if (isNil(suggestionsGraph)) return null
    const errors = []
    checkSuggestion(
      suggestionsGraph,
      buildSchemaTreeFromStore(SchemaStore),
      errors,
    )
    return errors
  }, [suggestionsGraph])

  if (isEmpty(suggestionError)) return null
  return (
    <HomePanel caption="錯誤的 Suggestion 節點">
      {isNil(suggestionError) && <Text>載入中，請稍候。。。</Text>}
      {map(suggestionError, node => {
        return (
          <Flex
            width="100%"
            justifyContent="center"
            fontSize="small"
            color="danger"
          >
            {node}
          </Flex>
        )
      })}
    </HomePanel>
  )
}

function KGError() {
  const kgError = useKGError()
  if (sum(map(keys(kgError), namespace => size(kgError[namespace]))) === 0)
    return null
  return (
    <HomePanel caption="Editor使用但未列入系統節點">
      {map(keys(kgError), namespace => {
        return (
          <Fragment key={namespace}>
            <Flex width="100%" justifyContent="center">
              {namespace}
            </Flex>
            <Flex
              width="100%"
              justifyContent="center"
              fontSize="small"
              color="danger"
            >
              {join(kgError[namespace], ', ')}
            </Flex>
          </Fragment>
        )
      })}
    </HomePanel>
  )
}

const rewardRoot = [
  {
    node_id: KG_NODES.card.CARD_REWARD_ROOT_ID,
    namespace: 'card',
  },
]

function useRewardError() {
  const rewards = useGetConnectedNodes(rewardRoot)

  const missed = useMemo(() => {
    if (!rewards) return null

    const builtIn = Object.values(builtInRewards)
    return map(rewards, 'node_id').filter(reward => !builtIn.includes(reward))
  }, [rewards])

  return [
    !isEmpty(missed),
    '未涵括の回饋類型',
    'Update builtInRewards && Validation rule',
    () => (
      <>
        {missed.map(id => (
          <li key={id}>{find(rewards, { node_id: id }).name}</li>
        ))}
      </>
    ),
  ]
}

function RewardsError() {
  const [error, caption, solution, render] = useRewardError()
  const { namespace } = useContext(ProjectContext)

  if (!error || namespace !== 'card') return null
  return (
    <HomePanel caption={caption}>
      <Box width="100%" color="gray" fontSize="h4">
        {solution}
      </Box>
      <Box width="100%">
        <ul>{render()}</ul>
      </Box>
    </HomePanel>
  )
}

export function Errors() {
  return (
    <>
      <RewardsError />
      <KGError />
      <SuggestionError />
    </>
  )
}
