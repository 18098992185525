import { createContext, useMemo } from 'react'

import { isNil } from 'lodash'
import { useCardRewardType } from './RewardUtils'
import { useCardGroup } from '@schema'

export const CardRewardContext = createContext({})

export function useCardRewardContext() {
  const [cardGroups] = useCardGroup({})
  const rewardTypes = useCardRewardType()

  const isReady = useMemo(() => !isNil(cardGroups), [cardGroups])

  return {
    isReady,
    cardGroups,
    rewardTypes,
  }
}
