import { TicketState } from '@common'

import { backendDocType, namespace } from './config'
import { CRUDO } from '../TicketHelper'

export const ticketProfile = {
  ticketType: backendDocType,
  namespace,
  states: [
    {
      main_state: TicketState.CREATE,
      assignee_quota: 1,
      assignee_as_editor: true,
      crud: CRUDO.READ_ONLY,
    },
    {
      main_state: TicketState.FINISH,
      assignee_quota: 0,
      assignee_as_editor: false,
      crud: CRUDO.READ_ONLY,
    },
  ],
  autoCreate: false,
  enableVersion: false,
  filterByCurrentUser: false,
}
