import { useState, useCallback, useMemo } from 'react'
import { has } from 'lodash'

export function useDialog(DialogComp) {
  const [visible, setVisible] = useState(false)
  const [params, setParams] = useState()

  const render = useCallback(
    props => {
      if (has(props, 'propagateHiding')) {
        return (
          <DialogComp
            {...params}
            {...props}
            setVisible={setVisible}
            visible={visible}
          />
        )
      }
      return (
        <>
          {visible && (
            <DialogComp
              {...params}
              {...props}
              onHide={() => {
                setVisible(false)
                setParams(undefined)
              }}
            />
          )}
        </>
      )
    },
    [params, visible],
  )

  const show = useCallback(
    (params = {}) => {
      setParams(params)
      setVisible(true)
    },
    [setVisible, setParams],
  )

  const result = useMemo(() => [show, render], [show, render])
  return result
}
