import { partial } from 'lodash'

import { useKGTranspile } from '../Common'
import { useUploadDocWithHistory } from '../Uploader'

import { benefitSchema } from './schema'
import { cceClient } from '@gql'
import { dataAnalysisService } from '@remote'

import { _uploadBenefit } from './common.gql'

export const useTranspile = partial(useKGTranspile, benefitSchema)

export function useUploadBenefitOfTicket() {
  const upload = useUploadDocWithHistory(benefitSchema, async benefits => {
    const {
      data: { uploadBenefit },
    } = await cceClient.mutate({
      mutation: _uploadBenefit,
      variables: {
        benefits,
      },
    })

    return uploadBenefit
  })

  return upload
}

export const checkBenefit = partial(dataAnalysisService, 'check_benefit')
