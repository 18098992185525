import { useMemo } from 'react'
import 'styled-components/macro'
import PropTypes from 'prop-types'
import css from '@styled-system/css'
import { get, map } from 'lodash'
import { Button } from 'primereact/button'
import { Flex } from '@changingai/react-editor-common-component'

export function ActionButtons({ buttons, onClicked }) {
  const [leftButtons, rightButtons, centerButtons] = useMemo(
    () => [
      get(buttons, 'left', []).filter(({ visible = true }) => visible),
      get(buttons, 'right', []).filter(({ visible = true }) => visible),
      get(buttons, 'center', []).filter(({ visible = true }) => visible),
    ],
    [buttons],
  )

  return (
    <Flex
      data-cy="action-button"
      my={1}
      alignItems="center"
      flex="1 1 100%"
      css={css({
        '.p-button': {
          mx: 1,
        },
      })}
    >
      {map(
        [
          [leftButtons, 'flex-start'],
          [centerButtons, 'center'],
          [rightButtons, 'flex-end'],
        ],
        ([buttons, justifyContent], index) => (
          <Flex key={index} flex="1 1 33%" justifyContent={justifyContent}>
            {map(
              buttons,
              ({
                label = '',
                action,
                className = 'p-button-primary',
                icon = '',
                enable = true,
                badge = '',
              }) => (
                <Button
                  data-cy={label}
                  data-testid={label}
                  disabled={!enable}
                  key={action}
                  onClick={() => {
                    onClicked(action)
                  }}
                  icon={icon}
                  label={label}
                  className={className}
                  badge={badge}
                />
              ),
            )}
          </Flex>
        ),
      )}
    </Flex>
  )
}

ActionButtons.propTypes = {
  buttons: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({ left: PropTypes.array, right: PropTypes.array }),
  ]).isRequired,
  onClicked: PropTypes.func.isRequired,
}
