import { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  compact,
  get,
  isEmpty,
  isEqual,
  isNil,
  map,
  range,
  size,
  split,
  toString,
  trim,
} from 'lodash'
import { Flex, Box } from '@changingai/react-editor-common-component'

import { ToastifyLabel, TableInput } from '@widget'

import {
  productSchema,
  declaredInterestCellSchema,
  rateSchema,
  yearMonthSchema,
} from './schema'

export function DeclaredInterest({ context, onDirty, onChange, onError }) {
  const fieldname = 'declared_interests'

  const declaredInterests = useMemo(() => {
    const rates = get(context, fieldname)
    return isEmpty(rates) ? Array(120).fill([null, null]) : rates
  }, [context])

  const isValidValue = (schema, value) =>
    isNil(get(schema.validate(value), 'error'))

  const normalizeYearMonth = yearMonth => {
    const [year, month] = split(yearMonth, '/')
    return size(month) === 1 ? `${year}/0${month}` : yearMonth
  }

  const onTableChange = useCallback(
    ({ values }) => {
      const invalidRows = compact(
        map(values, ([yearMonth, rate], rowNum) =>
          isValidValue(yearMonthSchema, yearMonth) &&
          isValidValue(rateSchema, rate)
            ? null
            : rowNum + 1,
        ),
      )
      onError(
        fieldname,
        !isEmpty(invalidRows),
        `第${invalidRows.join(',')}行資料有誤，請檢查`,
      )
      const result = map(values, ([yearMonth, rate]) => [
        normalizeYearMonth(yearMonth),
        rate,
      ])
      onDirty(fieldname, !isEqual(result, declaredInterests))
      onChange(fieldname, result)
    },
    [onChange, onDirty, onError, declaredInterests],
  )

  return (
    <Flex alignItems="center" justifyContent="center">
      <ToastifyLabel
        flex="1 200px"
        fieldname={fieldname}
        label={get(productSchema, `${fieldname}.label`)}
      />
      {!isEmpty(declaredInterests) && (
        <Box width="50vw" height="80vh">
          <TableInput
            schema={declaredInterestCellSchema}
            header={['利率宣告時間(yyyy/mm)', '宣告利率(<1)']}
            columnHeader={map(range(1, size(declaredInterests) + 1), toString)}
            headerEditable={false}
            values={declaredInterests}
            format={v => (isNil(v) || v === '' ? null : trim(v))}
            onChange={table => onTableChange(table)}
          />
        </Box>
      )}
    </Flex>
  )
}

DeclaredInterest.propTypes = {
  context: PropTypes.object.isRequired,
  onDirty: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
}
