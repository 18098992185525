import styled from 'styled-components'
import { Flex } from '@changingai/react-editor-common-component'

export const Card = styled(Flex)`
  & .p-tabview .p-tabview-panels {
    background-color: #c5c1c1;
    padding: 0;
    margin: 0;
    border: none;
  }
`

Card.defaultProps = {
  position: 'relative',
  width: '100%',
  flex: '1 0 auto',
  flexWrap: 'wrap',
  boxShadow:
    '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12)',
}
