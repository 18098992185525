import { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import 'styled-components/macro'
import { FaFileExport, FaFileImport } from 'react-icons/fa'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { TiInputCheckedOutline, TiInputChecked } from 'react-icons/ti'
import { DataView } from 'primereact/dataview'
import { Badge } from 'primereact/badge'
import { useImmer } from 'use-immer'
import { toast } from 'react-toastify'
import ReactTooltip from 'react-tooltip'
import {
  isEmpty,
  get,
  find,
  map,
  cloneDeep,
  isNil,
  findIndex,
  size,
  intersection,
} from 'lodash'
import { CSVReader } from 'react-papaparse'
import { StyledOverlay } from '@changingai/react-editor-common-component'

import { getActiveState, exportDoc, csvBlob, useDialog } from '@common'
import { ButtonLike, Checkbox as IconCheckbox } from '@widget'
import theme from '@theme'

import { useImportCSV, ImportErrorDialog } from './import'
import { useTicketFilter } from './header'
import { TicketItem } from './item'

import { CreateTicketDialog } from '../CreateTicketDialog'
import { FloatingMenu, MenuIcon, ViewRoot, DataViewHost } from '../Shell'
import { findManifest } from '../Config'
import {
  useTickets,
  getNamespaceByScopeType,
  getTicketProfile,
} from '../TicketHelper'
import { TagMemoContext, useTagMemoContext } from '../TagMemoContext'
import { TagButton } from '../TagPanel'

import { _queryTickets } from './listview.gql'

function useTicketContext(ticketType) {
  const [tickets, loading] = useTickets({
    scope_type: ticketType,
    namespace: getNamespaceByScopeType(ticketType),
    gql: _queryTickets,
  })

  const createTicketContext = ticket => ({
    ...ticket,
    editContext: {
      ...cloneDeep(ticket),
      invalid: false,
    },
  })

  const [ticketContext, setTicketContext] = useImmer([])
  useEffect(() => {
    if (!tickets || loading) setTicketContext(() => [])
    else
      setTicketContext(() => tickets.map(ticket => createTicketContext(ticket)))
  }, [loading, tickets, setTicketContext])

  const pushFront = useCallback(
    tickets => {
      setTicketContext(draft => [...tickets.map(createTicketContext), ...draft])
    },
    [setTicketContext],
  )

  const updateTicket = useCallback(
    ticket => {
      setTicketContext(draft => {
        const index = findIndex(draft, { _id: ticket._id })
        draft.splice(index, 1, createTicketContext(ticket))
      })
    },
    [setTicketContext],
  )

  return [ticketContext, setTicketContext, pushFront, updateTicket, loading]
}

function TicketListView({ ticketType }) {
  const [
    tickets,
    setTickets,
    pushFront,
    updateTicket,
    loading,
  ] = useTicketContext(ticketType)

  const ids = useMemo(() => map(tickets, '_id'), [tickets])
  const tagMemoContext = useTagMemoContext(ids)

  const { filteredTickets, header } = useTicketFilter(
    ticketType,
    tickets,
    tagMemoContext,
  )

  const [showCreateTicket, renderCreateTicket] = useDialog(CreateTicketDialog)
  const onCreated = useCallback(tickets => pushFront(tickets), [pushFront])

  const [showError, renderError] = useDialog(ImportErrorDialog)
  const csvRef = useRef()
  const [errors, importFromCSV] = useImportCSV(ticketType)
  const onImport = useCallback(
    async data => {
      try {
        const updatedTickets = await importFromCSV(data.map(({ data }) => data))
        if (updatedTickets) {
          setTickets(draft => {
            updatedTickets.forEach(ticket => {
              const matched = draft.find(({ _id }) => ticket._id === _id)
              if (matched) {
                matched.editContext = {
                  active_state: ticket.active_state,
                  states: cloneDeep(ticket.states),
                  invalid: false,
                }
              }
            })
          })

          toast.success('Batch assign tickets Successfully!', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          })
        }
      } catch (e) {
        toast.error(`Error: ${e}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        })
      }
    },
    [importFromCSV, setTickets],
  )

  useEffect(() => {
    if (!isEmpty(errors)) showError({ errors })
  }, [showError, errors])

  const [selectedTickets, setSelectedTickets] = useState([])

  const tisketsToExport = useMemo(
    () => intersection(selectedTickets, map(filteredTickets, '_id')),
    [selectedTickets, filteredTickets],
  )

  const onSelectAll = useCallback(
    checked => {
      setSelectedTickets(() => (checked ? map(filteredTickets, '_id') : []))
    },
    [filteredTickets, setSelectedTickets],
  )

  const onToggle = useCallback(
    _id => {
      if (selectedTickets.includes(_id))
        setSelectedTickets(selectedTickets.filter(id => id !== _id))
      else setSelectedTickets([...selectedTickets, _id])
    },
    [selectedTickets, setSelectedTickets],
  )

  const onExport = useCallback(() => {
    exportDoc(
      `tickets.csv`,
      csvBlob(
        tisketsToExport
          .map(_id => find(filteredTickets, { _id }))
          .map(ticket => [
            ticket._id,
            ticket.name,
            getActiveState(ticket).main_state,
            ...get(getActiveState(ticket), 'assignees', []),
          ]),
      ),
    )
  }, [filteredTickets, tisketsToExport])

  return (
    <TagMemoContext.Provider value={tagMemoContext}>
      <StyledOverlay active={loading} text="Loading Tickets...">
        <ViewRoot required={['ticket']}>
          {renderError({})}
          {renderCreateTicket({
            onCreated,
            scopeType: ticketType,
            useTicketScopeMap: findManifest(ticketType, 'useTicketScopeMap'),
            useCandidator: findManifest(ticketType, 'useCandidator'),
          })}
          <FloatingMenu
            render={() => (
              <>
                <MenuIcon>
                  <ButtonLike
                    data-tip="新增文件"
                    data-cy="create_ticket"
                    size={6}
                    scaleOnHover
                    disabled={getTicketProfile(ticketType).autoCreate}
                    onClick={() => showCreateTicket()}
                  >
                    <AiOutlinePlusCircle />
                  </ButtonLike>
                  <ReactTooltip place="right" />
                </MenuIcon>
                <MenuIcon
                  data-tip={
                    isEmpty(selectedTickets) ? 'select all' : 'deselect all'
                  }
                  data-cy="select_all"
                >
                  <IconCheckbox
                    icons={[TiInputChecked, TiInputCheckedOutline]}
                    checked={!isEmpty(selectedTickets)}
                    onChecked={onSelectAll}
                    color={theme.colors.icon}
                  />
                </MenuIcon>
                <MenuIcon>
                  <ButtonLike
                    data-tip="匯出Ticket"
                    disabled={isEmpty(tisketsToExport)}
                    size={6}
                    scaleOnHover
                    onClick={() => onExport()}
                    className="p-overlay-badge"
                  >
                    <FaFileExport />
                    {!isEmpty(tisketsToExport) && (
                      <Badge value={size(tisketsToExport)} />
                    )}
                  </ButtonLike>
                </MenuIcon>
                <MenuIcon>
                  <CSVReader
                    ref={csvRef}
                    onFileLoad={onImport}
                    disanoClick
                    noDrag
                    config={{
                      skipEmptyLines: true,
                    }}
                  >
                    <ButtonLike
                      data-tip="Batch Assign"
                      size={6}
                      scaleOnHover
                      onClick={e => {
                        if (csvRef.current) {
                          csvRef.current.open(e)
                        }
                      }}
                    >
                      <FaFileImport />
                    </ButtonLike>
                    <ReactTooltip place="right" />
                  </CSVReader>
                </MenuIcon>
                <MenuIcon>
                  <TagButton />
                </MenuIcon>
              </>
            )}
          />
          <DataViewHost>
            <DataView
              value={filteredTickets}
              header={<>{header}</>}
              itemTemplate={ticket => (
                <>
                  {ticket && !loading && (
                    <TicketItem
                      key={ticket._id}
                      ticketType={ticketType}
                      ticket={ticket}
                      onToggle={onToggle}
                      selected={selectedTickets.includes(ticket._id)}
                      setTickets={setTickets}
                      useScopeName={findManifest(ticketType, 'useScopeName')}
                      updateTicket={updateTicket}
                      tagMemoContext={tagMemoContext}
                    />
                  )}
                </>
              )}
              paginatorPosition={'both'}
              paginator={!isNil(tickets)}
              rows={20}
            />
          </DataViewHost>
        </ViewRoot>
      </StyledOverlay>
    </TagMemoContext.Provider>
  )
}

TicketListView.propTypes = {
  ticketType: PropTypes.string.isRequired,
}

export default TicketListView
