import { useEffect, useCallback, useState, useMemo, useContext } from 'react'
import PropTypes from 'prop-types'
import { Badge } from 'primereact/badge'
import { DataView } from 'primereact/dataview'
import { Tag } from 'primereact/tag'
import {
  compact,
  filter,
  find,
  get,
  has,
  identity,
  indexOf,
  includes,
  intersection,
  isEmpty,
  isNil,
  keys,
  map,
  size,
  sortBy,
  uniq,
  omit,
} from 'lodash'
import { toast } from 'react-toastify'
import { FaFileExport, FaSortAmountDown } from 'react-icons/fa'
import { TiInputCheckedOutline, TiInputChecked } from 'react-icons/ti'
import {
  Box,
  Flex,
  StyledOverlay,
} from '@changingai/react-editor-common-component'

import { cceClient } from '@gql'
import { ProjectContext, PropCrud, exportDoc, jsonBlob } from '@common'
import { toEditableDoc, getSchemaName } from '@schema'
import { ButtonLike, Checkbox as IconCheckbox, SearchWidget } from '@widget'
import theme from '@theme'

import { FloatingMenu, MenuIcon, ViewRoot, DataViewHost } from './Shell'
import { useFilterAndSortDocs } from './FieldSelectorPanel'
import SummaryDoc from './SummaryDoc'
import { createLayoutSchema } from './SummaryFields'
import { TagButton, useTagFilter } from './TagPanel'
import { TagMemoContext, useTagMemoContext } from './TagMemoContext'
import { getViewManifest } from './Config'

async function queryDocByIds(ids, { gql, name }) {
  const { data } = await cceClient.query({
    query: gql,
    variables: {
      doc_ids: ids,
    },
  })

  return get(data, name)
}

function ListviewHeader({
  cacheKey,
  docs,
  HeaderLeftPanel,
  HeaderRightPanel,
  crud,
  setCrud,
  currentSchema,
  onSearch,
  renderTagFilter,
}) {
  const { enableRegExpMode } = useContext(ProjectContext)

  return (
    <Flex width="100%" flexWrap="wrap">
      <Flex width="100%" flexWrap="wrap">
        <Flex m={0} flex="1 1 50%" flexWrap="wrap">
          {HeaderLeftPanel && (
            <HeaderLeftPanel crud={crud} setCrud={setCrud} docs={docs} />
          )}
        </Flex>
        <Flex m={0} flex="1 1 50%" flexWrap="wrap">
          {HeaderRightPanel && (
            <HeaderRightPanel crud={crud} setCrud={setCrud} docs={docs} />
          )}
        </Flex>
      </Flex>
      <Flex flexDirection="column" my="5" width="100%">
        {renderTagFilter()}
        <SearchWidget
          onSearch={onSearch}
          cacheKey={`listview.${cacheKey}`}
          searchFields={map(currentSchema, ({ label }, key) => ({
            value: key,
            label,
          }))}
          enableRegExpMode={enableRegExpMode}
        />
      </Flex>
    </Flex>
  )
}

ListviewHeader.propTypes = {
  docs: PropTypes.arrayOf(PropTypes.object),
  cacheKey: PropTypes.string.isRequired,
  HeaderLeftPanel: PropTypes.func,
  HeaderRightPanel: PropTypes.func,
  crud: PropCrud.isRequired,
  setCrud: PropTypes.func.isRequired,
  currentSchema: PropTypes.object.isRequired,
  onSearch: PropTypes.func.isRequired,
  renderTagFilter: PropTypes.func.isRequired,
}

function SummaryListView({
  docs,
  header,
  updated,
  onOpen,
  onDelete,
  onClone,
  selectedFields,
  crud,
  onSelect,
  selected,
  currentSchema,
  useValidFields,
  useTranspile,
  generateCaption,
  showMemo,
  searchIds,
}) {
  const validFields = useValidFields(selectedFields, currentSchema)

  const layoutSchema = useMemo(() => createLayoutSchema(currentSchema), [
    currentSchema,
  ])

  return (
    <DataViewHost>
      <DataView
        value={docs}
        header={header}
        itemTemplate={item => {
          if (!item) return null
          const { source, transpiled } = item
          const id = source._id
          return (
            <SummaryDoc
              caption={generateCaption(
                indexOf(docs, item),
                transpiled,
                includes(searchIds, id) ? ['Search Target'] : [],
              )}
              doc={source}
              transpiled={transpiled}
              updated={updated.includes(id)}
              crud={crud}
              onOpen={onOpen}
              onDelete={onDelete}
              onClone={crud.update ? onClone : null}
              selectedFields={validFields(source)}
              selected={selected.includes(id)}
              onSelect={onSelect}
              currentSchema={currentSchema}
              useTranspile={useTranspile}
              showMemo={showMemo}
              layoutSchema={layoutSchema}
            />
          )
        }}
        paginatorPosition={'both'}
        paginator={true}
        rows={30}
      />
    </DataViewHost>
  )
}

SummaryListView.propTypes = {
  docs: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.object.isRequired,
      transpiled: PropTypes.object.isRequired,
    }),
  ),
  header: PropTypes.node,
  onOpen: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onClone: PropTypes.func,
  updated: PropTypes.arrayOf(PropTypes.string),
  selectedFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  crud: PropCrud.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  includeTags: PropTypes.arrayOf(PropTypes.string),
  excludeTags: PropTypes.arrayOf(PropTypes.string),
  currentSchema: PropTypes.object.isRequired,
  useValidFields: PropTypes.func.isRequired,
  useTranspile: PropTypes.func.isRequired,
  generateCaption: PropTypes.func.isRequired,
  showMemo: PropTypes.bool.isRequired,
  searchIds: PropTypes.arrayOf(PropTypes.string),
}

export function useValidFieldsBySchema(fields, currentSchema) {
  const validFields = useCallback(
    () => filter(fields, field => has(currentSchema, field)),
    [fields, currentSchema],
  )
  return validFields
}

const controllerProps = PropTypes.shape({
  docs: PropTypes.arrayOf(PropTypes.object),
  createDoc: PropTypes.func,
  updateDoc: PropTypes.func,
  deleteDoc: PropTypes.func,
  importDocs: PropTypes.func,
  transformExportedDocs: PropTypes.func,
  updated: PropTypes.arrayOf(PropTypes.string).isRequired,
  showDoc: PropTypes.func.isRequired,
  renderDoc: PropTypes.func.isRequired,
  cloneDoc: PropTypes.func,
  isReady: PropTypes.bool.isRequired,
  uploadDoc: PropTypes.func,
  showSorting: PropTypes.func,
  renderSorting: PropTypes.func,
  useReference: PropTypes.func,
  generateCaption: PropTypes.func,
  showMemo: PropTypes.bool,
  onSelectFields: PropTypes.func,
})

// In SummaryModel, we should not find specific doc type, doc type
// dependent operations should be wrapped in useController
export function SchemaSummaryModel({
  controller,
  useTranspile,
  useValidFields,
  HeaderLeftPanel,
  HeaderRightPanel,
  requiredPrivilege,
  currentSchema,
  extendedLeftMenuIcons = [],
  extendedRightMenuIcons = [],
  modelId,
  crud,
  sortByField,
  selectedFields,
  searchIds = [],
}) {
  const {
    docs,
    updateDoc,
    deleteDoc,
    transformExportedDocs = identity,
    updated,
    showDoc,
    renderDoc,
    cloneDoc,
    isReady,
    uploadDoc,
    useReference,
    showSorting,
    renderSorting,
    generateCaption = (index, doc, tags) => (
      <Box fontSize="h1" m="5px 10px" data-cy="caption">
        {`${index + 1}.代碼： ${doc._id}`}
        {map(tags, tag => (
          <Tag style={{ marginLeft: '.5rem' }} value={tag} severity="info" />
        ))}
      </Box>
    ),
    showMemo = true,
  } = controller

  const transpiledDocs = useTranspile(docs, selectedFields)

  const onUpdate = useCallback(
    docs => {
      docs.forEach(({ doc, originalId }) =>
        updateDoc(toEditableDoc(doc, currentSchema), originalId),
      )
    },
    [updateDoc, currentSchema],
  )

  const [selectedDocs, setSelectedDocs] = useState([])
  const onSelect = useCallback(
    id => {
      if (selectedDocs.includes(id)) {
        setSelectedDocs(selectedDocs.filter(_id => _id !== id))
      } else setSelectedDocs([...selectedDocs, id])
    },
    [selectedDocs, setSelectedDocs],
  )

  const docIDs = useMemo(() => map(docs, '_id'), [docs])
  const tagMemoContext = useTagMemoContext(docIDs)
  const { tags: tagMap } = tagMemoContext

  const [filters, setFilters] = useState({})
  const [regExpMode, setRegExpMode] = useState(false)
  const [andGate, setAndGate] = useState(false)

  const onSearch = useCallback(
    (searchContext, regExpMode, andGate) => {
      setFilters(searchContext)
      setRegExpMode(regExpMode)
      setAndGate(andGate)
    },
    [setFilters, setRegExpMode, setAndGate],
  )

  const [includeTags, excludeTags, renderTagFilter] = useTagFilter(
    modelId,
    tagMap,
  )

  const ids = useFilterAndSortDocs({
    sortByField,
    filters,
    transpiledDocs,
    docs,
    selectedFields,
    tagMap,
    includeTags,
    excludeTags,
    currentSchema,
    regExpMode,
    andGate,
  })

  const filterAndSortedDocs = useMemo(() => {
    if (!crud.read) return []
    if (!transpiledDocs) return null
    const docMap = docs.remap('_id', doc => doc)
    const transpiledMap = transpiledDocs.remap('_id', doc => doc)

    const validIds = intersection(searchIds, keys(docMap))
    return map(uniq([...validIds, ...ids]), id => ({
      source: docMap[id],
      transpiled: transpiledMap[id],
    }))
  }, [ids, docs, transpiledDocs, crud.read, searchIds])

  const docsToExport = useMemo(
    () =>
      intersection(
        selectedDocs,
        map(filterAndSortedDocs, ({ source: { _id } }) => _id),
      ),
    [selectedDocs, filterAndSortedDocs],
  )

  const onExport = useCallback(async () => {
    const manifest = find(getViewManifest(), {
      schema: getSchemaName(currentSchema),
    })
    const queryManifest = get(manifest, 'queryManifest')
    const docs = await queryDocByIds(docsToExport, queryManifest)
    const transformed = transformExportedDocs(
      map(docs, doc => omit(doc, ['_id'])),
    )
    exportDoc(`${manifest.backendDocType}.json`, jsonBlob(transformed))

    toast.success(
      `Export ${size(transformed)} ${
        size(transformed) > 1 ? 'docs' : 'doc'
      } successfully!`,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      },
    )
  }, [transformExportedDocs, docsToExport, currentSchema])

  const onSelectAll = useCallback(
    checked => {
      setSelectedDocs(() =>
        checked ? map(filterAndSortedDocs, ({ source: { _id } }) => _id) : [],
      )
    },
    [filterAndSortedDocs],
  )

  const [isExporting, setExporting] = useState(false)

  const schemaMenuIcons = useMemo(
    () =>
      compact([
        [
          <MenuIcon
            key="select-all"
            data-tip={isEmpty(selectedDocs) ? 'select all' : 'deselect all'}
          >
            <IconCheckbox
              icons={[TiInputChecked, TiInputCheckedOutline]}
              checked={!isEmpty(selectedDocs)}
              onChecked={onSelectAll}
              color={theme.colors.icon}
            />
          </MenuIcon>,
          0,
        ],
        [
          <MenuIcon key="export">
            <ButtonLike
              data-cy="export-selected-docs"
              data-tip="匯出文件"
              disabled={isEmpty(docsToExport)}
              size={6}
              scaleOnHover
              onClick={async () => {
                setExporting(true)
                await onExport()
                setExporting(false)
              }}
              className="p-overlay-badge"
            >
              <FaFileExport />
              {!isEmpty(docsToExport) && <Badge value={size(docsToExport)} />}
            </ButtonLike>
          </MenuIcon>,
          0,
        ],
        [
          <MenuIcon key="tag">
            <TagButton />
          </MenuIcon>,
          0,
        ],
        !isNil(showSorting) && [
          <MenuIcon key="sort">
            <ButtonLike
              data-tip="排序"
              size={6}
              scaleOnHover
              onClick={() => showSorting()}
            >
              <FaSortAmountDown />
            </ButtonLike>
          </MenuIcon>,
          0,
        ],
      ]),
    [onExport, showSorting, selectedDocs, onSelectAll, docsToExport],
  )

  const [localCrud, setLocalCrud] = useState(crud)
  useEffect(() => {
    setLocalCrud(crud)
  }, [setLocalCrud, crud])

  return (
    <TagMemoContext.Provider value={tagMemoContext}>
      <StyledOverlay active={!isReady || isNil(transpiledDocs) || isExporting}>
        {!isNil(renderSorting) && renderSorting(transpiledDocs)}
        {isReady && (
          <ViewRoot required={requiredPrivilege}>
            {renderDoc({
              onUpdate: !isNil(controller.updateDoc) ? onUpdate : null,
              onDelete: deleteDoc,
              onUpload: uploadDoc,
              useReference,
              currentSchema,
            })}
            <FloatingMenu
              render={() => (
                <>
                  {map(
                    sortBy([...schemaMenuIcons, ...extendedLeftMenuIcons], '1'),
                    ([MenuIcon]) => MenuIcon,
                  )}
                </>
              )}
            />
            <SummaryListView
              docs={filterAndSortedDocs}
              header={
                <ListviewHeader
                  cacheKey={modelId}
                  HeaderLeftPanel={HeaderLeftPanel}
                  HeaderRightPanel={HeaderRightPanel}
                  docs={docs}
                  crud={localCrud}
                  setCrud={setLocalCrud}
                  currentSchema={currentSchema}
                  onSearch={onSearch}
                  renderTagFilter={renderTagFilter}
                />
              }
              modelId={modelId}
              selectedFields={selectedFields}
              updated={updated}
              transpiledDocs={transpiledDocs}
              onOpen={showDoc}
              onDelete={deleteDoc}
              onClone={cloneDoc}
              crud={localCrud}
              onSelect={onSelect}
              selected={selectedDocs}
              currentSchema={currentSchema}
              useValidFields={useValidFields}
              useTranspile={useTranspile}
              generateCaption={generateCaption}
              showMemo={showMemo}
              searchIds={searchIds}
            />
            <FloatingMenu
              position="right"
              render={() => (
                <>
                  {map(
                    sortBy(extendedRightMenuIcons, '1'),
                    ([MenuIcon]) => MenuIcon,
                  )}
                </>
              )}
            />
          </ViewRoot>
        )}
      </StyledOverlay>
    </TagMemoContext.Provider>
  )
}

SchemaSummaryModel.propTypes = {
  controller: controllerProps.isRequired,
  useValidFields: PropTypes.func.isRequired,
  useTranspile: PropTypes.func.isRequired,
  HeaderLeftPanel: PropTypes.func,
  HeaderRightPanel: PropTypes.func,
  requiredPrivilege: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentSchema: PropTypes.object.isRequired,
  extendedLeftMenuIcons: PropTypes.arrayOf(PropTypes.array),
  extendedRightMenuIcons: PropTypes.arrayOf(PropTypes.array),
  modelId: PropTypes.string.isRequired,
  crud: PropCrud.isRequired,
  sortByField: PropTypes.string.isRequired,
  selectedFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  searchIds: PropTypes.arrayOf(PropTypes.string),
}

export default SchemaSummaryModel
