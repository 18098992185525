import { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import {
  isEmpty,
  find,
  map,
  difference,
  flatten,
  values,
  filter,
  has,
} from 'lodash'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { Dialog } from 'primereact/dialog'
import { Link } from 'react-router-dom'
import 'styled-components/macro'
import { CgDanger } from 'react-icons/cg'
import { Flex, Box, Text } from '@changingai/react-editor-common-component'
import theme from '@theme'

export function BenefitsDialog({
  benefitsOfContract,
  onHide,
  onSelect,
  tickets,
  benefitIds,
}) {
  const [selectedIds, setSelectedIds] = useState(benefitIds)

  const hasUnselectedBenefits = useMemo(() => {
    const benefitIds = map(flatten(values(benefitsOfContract)), '_id')
    return !isEmpty(difference(benefitIds, selectedIds))
  }, [benefitsOfContract, selectedIds])

  const emptyBenefitTcikets = useMemo(
    () => filter(tickets, ({ _id }) => !has(benefitsOfContract, _id)),
    [tickets, benefitsOfContract],
  )

  return (
    <Dialog
      header={`Select Benefits`}
      appendTo={document.body}
      visible={true}
      style={{ width: '800px' }}
      modal={true}
      onHide={onHide}
    >
      <Flex
        width="100%"
        height="500px"
        overflowY="auto"
        alignContent="flex-start"
        flexWrap="wrap"
      >
        <Flex width="100%" justifyContent="flex-end">
          <Button
            label="Cancel All"
            className="p-button-outlined p-button-warning"
            onClick={() => {
              setSelectedIds([])
            }}
            disabled={isEmpty(selectedIds)}
          />
          <Box width="10px" />
          <Button
            label="Select All"
            className="p-button-outlined p-button-help"
            onClick={() => {
              setSelectedIds(map(flatten(values(benefitsOfContract)), '_id'))
            }}
            disabled={!hasUnselectedBenefits}
          />
        </Flex>
        {map(emptyBenefitTcikets, ({ _id, name }) => (
          <Flex alignItems="center" fontSize="h1" my="2" key={_id}>
            <CgDanger
              style={{
                color: theme.colors.danger,
                width: '19px',
                height: '19px',
              }}
            />
            <Link
              to={`/insurance/listview/benefitSchema/${_id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {name}(無給付項目)
            </Link>
          </Flex>
        ))}
        {map(benefitsOfContract, (_, _id) => (
          <Box flex="1 1 100%" key={_id} mb="3">
            <Box
              width="100%"
              color="gray"
              borderBottom="2px solid gray"
              my="2"
              fontSize="h1"
            >
              <Link
                to={`/insurance/listview/benefitSchema/${_id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {find(tickets, { _id }).name}
              </Link>
            </Box>
            <Box ml="2">
              {benefitsOfContract[_id].map(({ _id, benefit_name }) => (
                <Box key={_id} width="100%">
                  <Checkbox
                    inputId={_id}
                    onChange={({ checked }) => {
                      setSelectedIds(
                        checked
                          ? [...selectedIds, _id]
                          : selectedIds.filter(id => id !== _id),
                      )
                    }}
                    checked={selectedIds.includes(_id)}
                  />
                  <Text ml="2">{benefit_name}</Text>
                </Box>
              ))}
            </Box>
          </Box>
        ))}
      </Flex>
      <Flex width="100%" justifyContent="flex-end">
        <Button
          icon="pi pi-check"
          label="OK"
          className="p-button-success"
          onClick={() => {
            onSelect(selectedIds)
            onHide()
          }}
        />
        <Box width="2" />
        <Button
          icon="pi pi-times"
          label="Cancel"
          className="p-button-secondary"
          onClick={() => onHide()}
        />
      </Flex>
    </Dialog>
  )
}

BenefitsDialog.propTypes = {
  tickets: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      scope_id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  onHide: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  benefitsOfContract: PropTypes.object.isRequired,
  benefitIds: PropTypes.arrayOf(PropTypes.string).isRequired,
}
