import Joi from 'joi'
import { get, head, some, map, isEmpty, compact, flatMap, filter } from 'lodash'
import '@changing-cc/hooks'

import { KG_NODES, useGetConnectedNodes, namespaces } from '@common'
import {
  addSchema,
  schemaToPropTypes,
  commonRewardSchema,
  DateInTwentyYears,
  NullableString,
  shortString,
  RequiredString,
  UniqueNodeArray,
  UniqueUrlArray,
} from '@schema'

const Array2dOf = type =>
  Joi.array()
    .items(
      Joi.array()
        .items(type)
        .required(),
    )
    .required()

function checkSpans(table) {
  if (some(map(head(table), isEmpty))) return ['第一列不得為空！']

  function checkRow(row, rowNum) {
    const spans = map(row, isEmpty)
    const hasError = some(
      spans,
      (val, idx) => idx && val && !get(spans, idx - 1),
    )
    const errors = []
    if (hasError)
      errors.push(`合併錯誤！第${rowNum}列右側合併範圍超過左側合併欄位`)
    return errors
  }

  return table.map((row, rowNum) => checkRow(row, rowNum + 2)).flat()
}

export const tableSchema = {
  title: {
    schema: RequiredString.max(shortString),
    type: 'string',
    label: '標題',
  },
  header: {
    schema: Joi.array()
      .items(RequiredString.max(shortString))
      .required(),
    type: 'arrayOf(string)',
    label: '表頭',
  },
  values: {
    schema: Array2dOf(
      Joi.string()
        .allow('')
        .required(),
    ).custom((value, helpers) => {
      const errorMsg = checkSpans(value)
      if (!isEmpty(checkSpans(value)))
        return helpers.message(errorMsg.join('\n'))
      for (const row of value) {
        if (isEmpty(compact(row))) return helpers.message('Empty line')
      }
      return value
    }),
    type: 'arrayOf(string)',
    label: '表格內容',
  },
  hide_header: {
    schema: Joi.bool().required(),
    type: 'bool',
    label: '隱藏表頭',
  },
}

export const TablePropType = schemaToPropTypes(tableSchema)

const shopRewardRoot = [
  {
    node_id: KG_NODES.card.SHOP_REWARD_ROOT_ID,
    namespace: 'card',
  },
]
export const shopRewardSchema = {
  ...commonRewardSchema,
  title: {
    schema: NullableString.max(shortString),
    type: 'string',
    label: '主標題',
  },
  subtitle: {
    schema: NullableString.max(shortString),
    type: 'string',
    label: '次標題',
  },
  link_building_urls: {
    schema: UniqueUrlArray,
    type: 'arrayOf(url)',
    label: '導流網址',
    multiInput: true,
  },
  brand_ids: {
    schema: UniqueNodeArray.min(1),
    type: 'arrayOf(node)',
    referToKG: 'card',
    label: '品牌',
  },
  img_urls: {
    schema: UniqueUrlArray.min(1).required(),
    type: 'arrayOf(image)',
    label: '圖片網址',
  },
  launch_date: {
    schema: DateInTwentyYears.required(),
    type: 'date',
    label: '上架日期',
  },
  effective_date_b: {
    schema: Joi.alternatives().try(Joi.string().valid(''), DateInTwentyYears),
    type: 'date',
    label: '有效開始',
    suggestions: [
      {
        label: '年初',
        value: new Date(new Date().getFullYear(), 0, 1).toLocaleDateString(
          'zh-Hans-CN',
        ),
      },
    ],
  },
  effective_date_e: {
    schema: Joi.alternatives().try(Joi.string().valid(''), DateInTwentyYears),
    type: 'date',
    label: '有效結束',
    suggestions: [
      {
        label: '年末',
        value: new Date(new Date().getFullYear(), 11, 31).toLocaleDateString(
          'zh-Hans-CN',
        ),
      },
    ],
  },
  type_id: {
    ...commonRewardSchema.type_id,
    useNodes: () => useGetConnectedNodes(shopRewardRoot),
  },
  tables: {
    schema: Joi.array().custom((tables, helpers) => {
      const error = filter(
        flatMap(tables, ({ title, header, values }) => [
          {
            table: title,
            error: tableSchema.header.schema.validate(header).error,
          },
          {
            table: title,
            error: tableSchema.values.schema.validate(values).error,
          },
        ]),
        'error',
      )
      return isEmpty(error) ? tables : helpers.message(error)
    }),
    type: 'arrayOf(tableSchema)',
    label: '表格資料',
  },
}

addSchema({ tableSchema })
addSchema({ shopRewardSchema }, '店家優惠', namespaces.shop.value)
