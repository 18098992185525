import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog } from 'primereact/dialog'
import { compact, get } from 'lodash'
import { MdError } from 'react-icons/md'
import { Flex } from '@changingai/react-editor-common-component'

import { PropCrud } from '@common'
import { ActionButtons, StatusBarRoot, useRenderMergeConfig } from '@widget'

export function MergeDialog({
  fieldname,
  leftValue,
  rightValue,
  currentSchema,
  config,
  onHide,
  onUpdate,
  crud,
  position,
}) {
  const [editContext, setEditContext] = useState(null)

  const [resetCount, setResetCount] = useState(0)
  const onReset = useCallback(() => {
    setEditContext(null)
    setResetCount(resetCount + 1)
  }, [setResetCount, resetCount, setEditContext])

  const [error, setError] = useState(false)
  const [reason, setReason] = useState(null)

  const onError = useCallback(
    (_, error, reason) => {
      if (error) {
        setError(error)
        setReason(reason)
        return
      }
      setError(false)
      setReason(null)
    },
    [setError, setReason],
  )

  const configRender = useRenderMergeConfig({
    fieldname,
    currentSchema,
    editContext,
    onUpdateContext: setEditContext,
    leftValue,
    rightValue,
    onError,
    position,
  })

  const onAction = useCallback(
    action => {
      switch (action) {
        case 'Update':
          onUpdate(fieldname, 'left', editContext)
          onHide()
          break
        case 'Overwrite':
          onUpdate(fieldname, 'left', editContext)
          onUpdate(fieldname, 'right', editContext)
          onHide()
          break
        case 'Reset':
          onReset()
          break
        case 'Close':
          onHide()
          break
      }
    },
    [onUpdate, onReset, editContext, onHide, fieldname],
  )

  return (
    <Dialog
      header={() =>
        `Merge ${get(currentSchema, `${fieldname}.label`, fieldname)}`
      }
      appendTo={document.body}
      visible={true}
      style={{ width: '90vw' }}
      modal={true}
      onHide={onHide}
      contentStyle={{ overflow: 'visible' }}
    >
      <Flex
        p="1"
        justifyContent="center"
        width="100%"
        fontWeight={'normal'}
        bg={'veryLightGrey'}
        alignItems="center"
      >
        {get(currentSchema, `${fieldname}.label`, fieldname)}
      </Flex>
      <Flex key={resetCount}>{configRender(config)}</Flex>
      <StatusBarRoot>
        {error && (
          <Flex width="100%" alignItems="center">
            <MdError style={{ color: 'red' }} />
            {reason}
          </Flex>
        )}
        <ActionButtons
          buttons={{
            right: compact([
              crud.update && {
                label: crud.overwrite ? 'Overwrite' : 'Update',
                action: crud.overwrite ? 'Overwrite' : 'Update',
                className: 'p-button-primary',
                enable: !error,
              },
              {
                label: 'Reset',
                action: 'Reset',
                className: 'p-button-warning',
              },
              {
                label: 'Close',
                action: 'Close',
                className: 'p-button-secondary',
              },
            ]),
          }}
          onClicked={onAction}
        />
      </StatusBarRoot>
    </Dialog>
  )
}

MergeDialog.propTypes = {
  fieldname: PropTypes.string.isRequired,
  leftValue: PropTypes.object.isRequired,
  rightValue: PropTypes.object.isRequired,
  currentSchema: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  crud: PropCrud.isRequired,
  position: PropTypes.oneOf(['left', 'right']),
}
