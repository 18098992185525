import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from 'primereact/checkbox'
import { useInit } from './common'
import { WidgetContainer } from './StyledComps'
import { ToastifyLabel } from './ToastifyLabel'

export function SingleCheck({
  fieldname,
  label,
  value,
  onCheck,
  onDirty,
  labelAttributes = { width: '150px', showOnRight: false },
  inputAttributes = { width: '100%' },
}) {
  const [, compare] = useInit(value)
  const [checked, setChecked] = useState(value)
  // ref: https://stackoverflow.com/questions/58818727/react-usestate-not-setting-initial-value
  // useState only use initValue in 1st render, so that if we do change outside from this component
  useEffect(() => {
    setChecked(value)
  }, [value])

  const {
    showOnRight: labelOnRight,
    ...labelAttributesWithShowDirection
  } = labelAttributes

  return (
    <WidgetContainer
      width={inputAttributes.width}
      data-cy={`SingleCheck-${fieldname}`}
    >
      {!labelOnRight && (
        <ToastifyLabel
          changed={compare(checked)}
          fieldname={fieldname}
          label={label}
          {...labelAttributesWithShowDirection}
        />
      )}
      <Checkbox
        checked={checked}
        onChange={({ checked }) => {
          setChecked(checked)
          onCheck(fieldname, checked)
          onDirty(fieldname, compare(checked))
        }}
      />
      {labelOnRight && (
        <label style={{ marginLeft: '.5rem' }} className="p-checkbox-label">
          {label}
        </label>
      )}
    </WidgetContainer>
  )
}

SingleCheck.propTypes = {
  fieldname: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
  onDirty: PropTypes.func.isRequired,
  width: PropTypes.string,
  labelAttributes: PropTypes.object,
  inputAttributes: PropTypes.object,
}
