import {
  compact,
  difference,
  filter,
  find,
  flatMap,
  get,
  includes,
  round,
  indexOf,
  isEmpty,
  map,
  range as _range,
  size,
  some,
  toNumber,
  toString,
  every,
} from 'lodash'

import { cceClient } from '@gql'

import { _getPossibleFees } from './rules.gql'

import { KG_NODES, ValidationCategory as State } from '@common'

import { planSchema, amountOptionSchema } from './schema'

function getSelectedTables(productFeeTables, feetables) {
  return map(feetables, feetable => ({
    ...feetable,
    ...find(productFeeTables, { _id: feetable.feetable_id }),
  }))
}

function computeValues({ list, range, type }) {
  return type === 'list' ? list : map(_range(...range), toString)
}

export const rules = [
  ({ request_variables, feetables }, { productFeeTables, variableKGNodes }) => {
    if (isEmpty(feetables)) return { result: [] }
    const selectedTables = getSelectedTables(productFeeTables, feetables)
    const result = flatMap(request_variables, ({ request_variable }, idx) =>
      flatMap(request_variable, variable => {
        const { request_type } = variable
        const { name: requestName, attributes } = find(variableKGNodes, {
          node_id: request_type,
        })
        const {
          values: [valueType],
        } = find(attributes, { name: 'type' })
        const values = computeValues({ ...variable, type: valueType })
        return compact(
          map(selectedTables, ({ name, factors }) => {
            const factorValues = get(
              find(factors, { id: request_type }),
              'values',
            )
            if (
              !isEmpty(factorValues) &&
              !isEmpty(difference(values, factorValues))
            ) {
              return [
                State.Error,
                `需求變數[${idx}]「${requestName}」未涵蓋於費率表「${name}」值域內`,
              ]
            }
          }),
        )
      }),
    )
    return {
      fieldname: planSchema.request_variables.label,
      result,
    }
  },
  ({ amount_options, feetables }, { productFeeTables }) => {
    const selectedTables = getSelectedTables(
      productFeeTables,
      filter(feetables, ({ feetable_id }) => {
        const feetable = find(productFeeTables, { _id: feetable_id })
        return (
          get(feetable, 'fee_base', 0) !== 0 &&
          get(feetable, 'currency_id') ===
            'channel_concept/5f85099dbbad8a45392bd382'
        )
      }),
    )

    const result = compact(
      map(selectedTables, ({ name, axes, fee_base }) => {
        const amount = find(axes, {
          axis_id: KG_NODES.insurance.REQUEST_VARIABLE.REQUEST_AMOUNT,
        })
        const value_type = get(amount, 'value_type')
        // 常數
        if (
          value_type === 'const' &&
          toNumber(get(amount, 'constant')) % fee_base !== 0
        )
          return [
            State.Warning,
            `費率表「${name}」保額對應輸入值應為投保額度(${fee_base})的倍數`,
          ]
        const option = find(amount_options, {
          option_id: get(amount, 'option_id'),
        })
        const planRatio = get(amount, 'plan_ratio', 1)
        // 對應保額選項
        if (value_type === 'id') {
          const values = map(computeValues(option), value =>
            toString(toNumber(value) * planRatio),
          )
          if (some(values, val => parseInt(val) % fee_base !== 0))
            return [
              State.Warning,
              `「${option.name}」保額選項的值(乘以倍率)應全部為投保額度(${fee_base})的倍數`,
            ]
        }
      }),
    )

    return {
      fieldname: planSchema.amount_options.label,
      result,
    }
  },
  ({ amount_options, feetables }, { productFeeTables }) => {
    const selectedTables = getSelectedTables(
      productFeeTables,
      filter(feetables, ({ feetable_id }) => {
        const { factors } = find(productFeeTables, { _id: feetable_id })
        return find(factors, {
          id: KG_NODES.insurance.REQUEST_VARIABLE.REQUEST_AMOUNT,
        })
      }),
    )
    const result = compact(
      map(selectedTables, ({ name, axes, factors }) => {
        const amount = find(axes, {
          axis_id: KG_NODES.insurance.REQUEST_VARIABLE.REQUEST_AMOUNT,
        })
        const value_type = get(amount, 'value_type')
        const requestAmount = find(factors, {
          id: KG_NODES.insurance.REQUEST_VARIABLE.REQUEST_AMOUNT,
        }).values
        if (
          value_type === 'const' &&
          !includes(requestAmount, toString(get(amount, 'constant')))
        )
          return [State.Error, `費率表「${name}」保額選項的值應列於投保額度內`]

        const option = find(amount_options, {
          option_id: get(amount, 'option_id'),
        })
        if (value_type === 'id') {
          // NOTE: 浮點數運算可能會出現誤差，目前解法為
          // 用一個極小位數(目前用10位)作round
          const values = map(computeValues(option), value =>
            toString(round(toNumber(value) * get(amount, 'plan_ratio', 1), 10)),
          )
          if (
            !isEmpty(
              difference(filter(values, value => value !== '0'), requestAmount),
            )
          )
            return [
              State.Error,
              `「${option.name}」保額選項的值(乘以倍率)應全部列於投保額度內`,
            ]
        }
      }),
    )

    return {
      fieldname: planSchema.amount_options.label,
      result,
    }
  },
  async ({ actuarial_samples, feetables }) => {
    if (some([actuarial_samples, feetables], isEmpty))
      return {
        fieldname: planSchema.actuarial_samples.label,
        result: [],
      }

    const possibleFeesGroup = map(
      await Promise.all(
        map(actuarial_samples, ({ issue_age, sex, insured_amount }) => {
          const amount = toNumber(insured_amount)
          const presets = [
            {
              id: KG_NODES.insurance.REQUEST_VARIABLE.AGE,
              value: toString(issue_age),
            },
            {
              id: KG_NODES.insurance.REQUEST_VARIABLE.SEX,
              value: sex,
            },
          ]

          return cceClient.query({
            query: _getPossibleFees,
            variables: {
              ids: map(feetables, 'feetable_id'),
              amount,
              presets,
            },
          })
        }),
      ),
      response => get(response, 'data.getPossibleFees'),
    )

    const result = compact(
      map(actuarial_samples, ({ annual_premium }, idx) => {
        const premium = toNumber(annual_premium)
        const possibleFees = possibleFeesGroup[idx]
        if (indexOf(possibleFees, premium) === -1) {
          const briefFees = `${possibleFees.slice(0, 3)}${
            size(possibleFees) > 3 ? ' ...' : ''
          }`
          return [
            State.Warning,
            `第${idx}個取樣例保費: ${premium}，預期保費:${briefFees}`,
          ]
        }
      }),
    )

    return {
      fieldname: planSchema.actuarial_samples.label,
      result,
    }
  },
  ({ amount_options }) => {
    const result = compact(
      map(amount_options, ({ range, list }, idx) => {
        if (every([range, list], data => !isEmpty(data))) {
          return [
            State.Error,
            `第${idx}個保額選項: ${amountOptionSchema.list.label}及${amountOptionSchema.range.label}不應同時有值`,
          ]
        }
      }),
    )
    return {
      fieldname: planSchema.amount_options.label,
      result,
    }
  },
  ({ amount_options }) => {
    const result = compact(
      map(amount_options, ({ type, list, range }, idx) => {
        if (type === 'list' && isEmpty(list))
          return [
            State.Error,
            `第${idx}個保額選項: ${amountOptionSchema.list.label}不可為空`,
          ]
        if (type === 'range' && size(range) < 2) {
          return [
            State.Error,
            `第${idx}個保額選項: ${amountOptionSchema.range.label}至少需要有2個值(min, max)`,
          ]
        }
      }),
    )
    return {
      fieldname: planSchema.amount_options.label,
      result,
    }
  },
  ({ amount_options }) => {
    const result = compact(
      map(
        amount_options,
        ({ type, range, list, default: defaultValue }, idx) => {
          if (!isEmpty(defaultValue)) {
            if (type === 'list' && !isEmpty(list)) {
              if (!list.includes(defaultValue))
                return [
                  State.Error,
                  `第${idx}個保額選項: 預設值${defaultValue}未落在${amountOptionSchema.list.label}中`,
                ]
            }

            if (type === 'range' && size(range) >= 2) {
              let [start, end, step] = map(range, toNumber)
              step = step || 1
              const defaultValueNum = toNumber(defaultValue)
              const remain = round(defaultValueNum - start, 10) % step
              if (defaultValueNum < start || defaultValueNum > end)
                return [
                  State.Error,
                  `第${idx}個保額選項: 預設值${defaultValue}超過${amountOptionSchema.range.label}界限值`,
                ]

              if (remain !== 0) {
                if (remain < 0.1)
                  return [
                    State.Warning,
                    [
                      `第${idx}個保額選項: 預設值${defaultValue}可能因為浮點數誤差，導致`,
                      `無法被${amountOptionSchema.range.label}條件整除(餘數${remain})，請仔細確認`,
                    ].join(''),
                  ]
                else
                  return [
                    State.Error,
                    [
                      `第${idx}個保額選項: 預設值${defaultValue}無法被`,
                      `${amountOptionSchema.range.label}條件整除(餘數${remain})，請確認`,
                    ].join(''),
                  ]
              }
            }
          }
        },
      ),
    )
    return {
      fieldname: planSchema.amount_options.label,
      result,
    }
  },
]
