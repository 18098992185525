import { useCallback, useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'
import { ApolloProvider } from '@apollo/client'
import { CookiesProvider } from 'react-cookie'
import { toast } from 'react-toastify'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import { IconContext } from 'react-icons/lib'

import {
  compact,
  find,
  get,
  has,
  head,
  isEmpty,
  isNil,
  join,
  map,
  split,
  toUpper,
} from 'lodash'

import { Box } from '@changingai/react-editor-common-component'

import {
  useProjectContext,
  ProjectContext,
  useCacheStore,
  namespaces as currentNamespaces,
} from '@common'
import {
  TicketListView,
  HomeView,
  StatisticView,
  useLoader,
  getViewManifest,
} from '@view'
import { ScrollBar } from '@widget'
import { cceClient } from '@gql'
import theme from '@theme'

import { usePrivilege, MainMenu, menuHeight } from './menu'
import { TicketDoc } from './TicketDoc'

import LoginImage from './img/login.jpeg'
import 'primereact/resources/themes/mdc-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'react-toastify/dist/ReactToastify.css'

// Add Ripple will have side effect in Primereact 5.0.2
// Which will make ListBox itemtemplate not working

// import PrimeReact from 'primereact/utils'
// PrimeReact.ripple = true

toast.configure({
  autoClose: false,
})

const DEFAULT_ICON_PROPS = {
  style: {
    color: theme.colors.icon,
    verticalAlign: 'middle',
    width: theme.sizes[6],
    height: theme.sizes[6],
  },
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  over-flow: hidden;
  width: 100vw;
  height: 100vh;
  align-items: center;
  background-color: #d5d1d1;
`

const MenuContainer = styled.div`
  width: 100%;
`

const ContainerBox = styled(Box)`
  ${ScrollBar}
  overflow: auto;
  min-height: calc(100% - ${menuHeight}px);
  width: calc(100% - 150px);
`

function SwitchNamespace({ children, namespace }) {
  const { setNamespace } = useContext(ProjectContext)

  useEffect(() => {
    if (namespace) setNamespace(namespace)
  }, [setNamespace, namespace])

  return <>{children}</>
}

SwitchNamespace.propTypes = {
  children: PropTypes.node.isRequired,
  namespace: PropTypes.string,
}

const namespaces = join(map(currentNamespaces, 'value'), '|')

function App({ history }) {
  const [privilege, email, renderLogin] = usePrivilege()
  const projectContext = useProjectContext(privilege)

  const onCommand = useCallback(
    (paths, newTab) => {
      if (newTab)
        window.open(`#/${[projectContext.namespace, ...paths].join('/')}`)
      else history.push(`/${[projectContext.namespace, ...paths].join('/')}`)
    },
    [history, projectContext.namespace],
  )

  const cacheStore = useCacheStore()

  const [composedIds, setComposedIds] = useState([])
  const [hideMenu, setHideMenu] = useState(false)
  useEffect(() => {
    const handler = event => {
      if (has(event.data, 'composedIds')) {
        setComposedIds(event.data.composedIds)
        setHideMenu(get(event.data, 'hideMenu', false))
      }
      //https://christoshrousis.com/05-how-to-communicate-with-an-iframe-using-react-hooks/
      //window.top.postMessage
    }

    window.addEventListener('message', handler)
    return () => window.removeEventListener('message', handler)
  }, [setComposedIds, setHideMenu])

  const { loader, supportedNamespace, supportedTypeOfNamespace } = useLoader()
  return (
    <ApolloProvider client={cceClient}>
      <CookiesProvider>
        <ThemeProvider theme={theme}>
          <IconContext.Provider value={DEFAULT_ICON_PROPS}>
            <ProjectContext.Provider
              value={{
                privilege,
                email,
                ...projectContext,
                ...cacheStore,
              }}
            >
              {renderLogin('credit-card-editor')}
              <Root>
                {!hideMenu && (
                  <MenuContainer>
                    <MainMenu onCommand={onCommand} />
                  </MenuContainer>
                )}

                {!!privilege && (
                  <ContainerBox email={email}>
                    <Switch>
                      <Route
                        path={`/:namespace(${namespaces})/statistic`}
                        render={({
                          match: {
                            params: { namespace },
                          },
                        }) => (
                          <SwitchNamespace namespace={namespace}>
                            <StatisticView />
                          </SwitchNamespace>
                        )}
                      />
                      <Route
                        path={`/:namespace(${namespaces})/(listview|compareview)/:schema/:ticketId`}
                        render={({ match: { params } }) => (
                          <SwitchNamespace namespace={params.namespace}>
                            <TicketDoc
                              namespace={toUpper(params.namespace)}
                              ticketId={params.ticketId}
                              Wrapped={get(
                                find(getViewManifest(), {
                                  schema: params.schema,
                                }),
                                params[0],
                              )}
                              searchIds={compact(
                                split(
                                  new URLSearchParams(
                                    head(window.location.href.match(/\?.*/)),
                                  ).get('search'),
                                  ',',
                                ),
                              )}
                            />
                          </SwitchNamespace>
                        )}
                      />
                      <Route
                        path={`/:namespace(${namespaces})/composedview/:schema`}
                        render={({ match: { params } }) => {
                          const config = find(getViewManifest(), {
                            schema: params.schema,
                          })
                          if (get(config, 'namespace') !== params.namespace) {
                            return (
                              <Redirect to={`/${projectContext.namespace}/`} />
                            )
                          }
                          const Comp = get(config, 'composedview')
                          return isNil(Comp) ? (
                            <Redirect to={`/${projectContext.namespace}/`} />
                          ) : (
                            <SwitchNamespace namespace={params.namespace}>
                              <Comp
                                ids={composedIds}
                                modelId={`composedview-${params.schema}`}
                              />
                            </SwitchNamespace>
                          )
                        }}
                      />
                      <Route
                        path={`/:namespace(${namespaces})/ticketList/:ticketType`}
                        render={({
                          match: {
                            params: { ticketType, namespace },
                          },
                        }) => (
                          <SwitchNamespace namespace={namespace}>
                            <TicketListView ticketType={ticketType} />
                          </SwitchNamespace>
                        )}
                      />
                      {map(supportedNamespace(), namespace => (
                        <Route
                          key={namespace}
                          path={[
                            `/${namespace}/(${join(
                              supportedTypeOfNamespace(namespace),
                              '|',
                            )})/:docId`,
                            /* Keep this path until all external users preifx card in the path */
                            `/(${join(
                              supportedTypeOfNamespace(namespace),
                              '|',
                            )})/:docId`,
                          ]}
                          render={({ match: { params } }) => {
                            return (
                              <SwitchNamespace namespace={namespace}>
                                {loader(
                                  namespace,
                                  get(params, '0'),
                                  get(params, 'docId'),
                                )}
                              </SwitchNamespace>
                            )
                          }}
                        />
                      ))}
                      <Route
                        path="/:namespace/:schema/"
                        render={({
                          match: {
                            params: { namespace, schema },
                          },
                        }) => {
                          const Comp = get(
                            find(getViewManifest(), { schema }),
                            'listview',
                          )
                          return isNil(Comp) ? (
                            <Redirect to={`/${projectContext.namespace}/`} />
                          ) : (
                            <SwitchNamespace namespace={namespace}>
                              <Comp modelId={schema} />
                            </SwitchNamespace>
                          )
                        }}
                      />
                      <Route
                        path={`/:namespace(${namespaces})`}
                        render={({
                          match: {
                            params: { namespace },
                          },
                        }) => (
                          <SwitchNamespace namespace={namespace}>
                            {isEmpty(email) && (
                              <img
                                style={{
                                  width: '80%',
                                }}
                                alt="please login"
                                src={LoginImage}
                              />
                            )}
                            {!isEmpty(email) && <HomeView />}
                          </SwitchNamespace>
                        )}
                      />
                      <Route path="/">
                        <Redirect to={`/${projectContext.namespace}/`} />
                      </Route>
                    </Switch>
                  </ContainerBox>
                )}
              </Root>
            </ProjectContext.Provider>
          </IconContext.Provider>
        </ThemeProvider>
      </CookiesProvider>
    </ApolloProvider>
  )
}

App.propTypes = {
  history: PropTypes.object.isRequired,
}
export default withRouter(App)
