import styled, { css } from 'styled-components'
import { Chips } from 'primereact/chips'

import { Box, Flex } from '@changingai/react-editor-common-component'

export const RowRoot = styled(Flex)``
RowRoot.defaultProps = {
  flex: '1 1 100%',
  alignItems: 'stretch',
  m: '1',
  justifyContent: 'space-around',
}

export const ColumnRoot = styled(Flex)``
ColumnRoot.defaultProps = {
  flex: '1 1 100%',
  alignItems: 'center',
  m: '1',
  flexDirection: 'column',
}

export const ScrollBar = css`
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 100, 0.3);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 100, 1);
  }
`

export const StyledChips = styled(Chips)`
  && {
    width: 100%;
    margin: 4px;
  }
  && .p-inputtext {
    width: 100%;
    .p-chips-token {
      color: white;
      background: #007ad9;
    }
  }
`

export const InputContainer = styled(Box)`
  position: relative;
  flex: 1 0;
  margin-right: 5px;
  & .p-inputtext,
  & .p-dropdown {
    width: 100%;
  }
`

export const WidgetContainer = styled(Flex)``
WidgetContainer.defaultProps = {
  my: 1,
  alignItems: 'center',
}

export const Label = styled(Box)`
  border: ${({ error }) => (error ? '2px solid red' : 'none')};
  background-color: ${({ error, editable = true }) =>
    editable && error ? 'rgba(255, 0, 0, 0.5)' : ''};
  color: ${({ changed, editable = true }) => {
    if (!editable) return 'gray'
    return editable && changed ? 'blue' : 'inherit'
  }};
  font-weight: ${({ error, changed = true }) =>
    error || changed ? 'bold' : 'normal'};
  text-decoration: ${({ editable = true }) =>
    editable ? 'inherit' : 'line-through'};
`

Label.defaultProps = {
  flexShrink: '0',
  width: '100px',
  mx: 1,
  px: 2,
  textAlign: 'left',
}

export const InputRoot = styled(Flex)`
  border: ${({ hightlight = false }) =>
    hightlight ? '3px solid green;' : 'none'};
`

InputRoot.defaultProps = {
  alignItems: 'center',
  flex: '0 1 100%',
  borderRadius: 3,
}
