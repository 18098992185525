import { useMemo, useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import {
  flatMap,
  map,
  cloneDeep,
  includes,
  isEqual,
  get,
  isNil,
  compact,
  uniq,
} from 'lodash'
import uuid from 'react-uuid'
import remarkBreaks from 'remark-breaks'

import { TicketPropType } from '@common'
import {
  inputFactory,
  generateSubDocProps,
  createSeparator,
  MarkDownEditor,
  ToastifyLabel,
} from '@widget'
import { Flex, Box } from '@changingai/react-editor-common-component'
import { Feetables } from './feetables'
import { Benefits } from './benefits'
import { SurrenderRates } from './surrenderRates'
import { RequestVariables } from './variables'
import { AmontOptions } from './options'
import { ActuarialSample } from './actuarialSample'
import { rules } from './rules'
import { PlanContext } from './context'
import { planSchema, PlanPropType } from './schema'

import { checkPlan } from './common'

import { SchemaBaseEditor } from '../SchemaBaseEditor'
import { useCheckApi } from '../Validation'
import { useFetchFullDoc } from '../DocHelper'

import { _queryPlan } from './editor.gql'
import { extractOptions } from './paybackEditor'

function NoteEditor({ fieldname, context, onChange, onDirty }) {
  const note = useMemo(() => get(context, fieldname), [context, fieldname])

  const onContentChange = useCallback(
    value => {
      onDirty(fieldname, !isEqual(value, note))
      onChange(fieldname, value)
    },
    [onChange, onDirty, note, fieldname],
  )

  return (
    <Flex alignItems="center" justifyContent="center">
      <ToastifyLabel
        flex="1 200px"
        fieldname={fieldname}
        label={get(planSchema, `${fieldname}.label`)}
      />
      <Box width="85vw">
        <MarkDownEditor
          value={note}
          onChange={onContentChange}
          previewPlugins={[remarkBreaks]}
        />
      </Box>
    </Flex>
  )
}

NoteEditor.propTypes = {
  fieldname: PropTypes.string.isRequired,
  context: PropTypes.object.isRequired,
  onChange: PropTypes.object.isRequired,
  onDirty: PropTypes.func.isRequired,
}

export function PlanEditDialog(props) {
  const { doc, ticket } = props
  const { editContext, setEditContext } = useFetchFullDoc(
    doc,
    planSchema,
    _queryPlan,
  )

  const context = useContext(PlanContext)
  const checker = useCheckApi({ rules, context, externalAPI: checkPlan })

  const BenefitsWrapper = useCallback(
    props => <Benefits {...props} ticket={ticket} />,
    [ticket],
  )

  const amountOptionRefInBriefs = useMemo(
    () =>
      uniq(
        compact(
          flatMap(get(editContext, 'benefits'), benefit =>
            map(extractOptions(benefit.payback_sample), 'id'),
          ),
        ),
      ),
    [editContext],
  )

  const tabs = useMemo(
    () => [
      {
        caption: 'Basic',
        configs: [
          inputFactory(planSchema, 'to_prod'),
          inputFactory(planSchema, 'name'),
          inputFactory(planSchema, 'code'),
          inputFactory(planSchema, 'is_additional', {
            inputAttributes: { width: '33%' },
          }),
          inputFactory(planSchema, 'highlight', {
            labelAttributes: {
              width: '200px',
            },
            inputAttributes: {
              multilines: true,
              rows: 10,
            },
          }),
          {
            fieldname: 'expert_intro',
            widget: 'CustomizedInput',
            RenderComp: NoteEditor,
          },
          {
            fieldname: 'benefit_note',
            widget: 'CustomizedInput',
            RenderComp: NoteEditor,
          },
          inputFactory(planSchema, 'children_age_bound', {
            labelAttributes: {
              width: '300px',
            },
          }),
          inputFactory(planSchema, 'spouse_age_bound', {
            labelAttributes: {
              width: '300px',
            },
          }),
          inputFactory(planSchema, 'parent_age_bound', {
            labelAttributes: {
              width: '300px',
            },
          }),
        ],
      },
      {
        caption: planSchema.request_variables.label,
        configs: [
          inputFactory(planSchema, 'unconfirmed_request_variables'),
          {
            ...generateSubDocProps(planSchema, 'request_variables'),
            itemConfigs: [
              {
                widget: 'CustomizedInput',
                RenderComp: RequestVariables,
              },
            ],
            onClone: (context, index, insertDoc) => {
              insertDoc([
                {
                  ...cloneDeep(context[index]),
                  id: uuid(),
                },
              ])
            },
          },
        ],
      },
      {
        caption: planSchema.amount_options.label,
        configs: [
          inputFactory(planSchema, 'unconfirmed_amount_options'),
          {
            ...generateSubDocProps(planSchema, 'amount_options'),
            itemConfigs: [
              {
                widget: 'CustomizedInput',
                RenderComp: AmontOptions,
              },
            ],
            onCanDelete: ({ option_id }) =>
              !includes(
                [
                  ...map(
                    flatMap(get(editContext, 'feetables'), 'axes'),
                    'variable_id',
                  ),
                  ...map(get(editContext, 'benefits'), 'option_id'),
                  ...amountOptionRefInBriefs,
                ],
                option_id,
              ),
          },
        ],
      },
      {
        caption: 'Benefits',
        configs: [
          {
            fieldname: 'benefits',
            widget: 'CustomizedInput',
            RenderComp: BenefitsWrapper,
          },
        ],
      },
      {
        caption: 'Fee Tables',
        configs: [
          {
            fieldname: 'feetables',
            widget: 'CustomizedInput',
            RenderComp: Feetables,
          },
        ],
      },
      {
        caption: '精算取樣點',
        configs: [
          {
            ...generateSubDocProps(planSchema, 'actuarial_samples'),
            itemConfigs: [
              {
                widget: 'CustomizedInput',
                RenderComp: ActuarialSample,
              },
            ],
          },
        ],
      },
      {
        caption: '精算組態設定',
        configs: [
          inputFactory(planSchema, 'payment_period'),
          inputFactory(planSchema, 'assumed_interest'),
          inputFactory(planSchema, 'tso_version'),
          inputFactory(planSchema, 'tso_discount'),
          {
            fieldname: 'surrender_rates',
            widget: 'CustomizedInput',
            RenderComp: SurrenderRates,
          },
          inputFactory(planSchema, 'actuarial_model_para', {
            inputAttributes: {
              multilines: true,
              rows: 3,
            },
          }),
          createSeparator({}),
          inputFactory(planSchema, 'actuarial_config_note', {
            inputAttributes: {
              multilines: true,
              rows: 5,
            },
          }),
          createSeparator({}),
          inputFactory(planSchema, 'publish_precalculation'),
        ],
      },
      {
        caption: '保額規則設定',
        configs: [
          inputFactory(planSchema, 'amount_option_rule', {
            inputAttributes: {
              multilines: true,
              rows: 15,
            },
          }),
        ],
      },
    ],
    [editContext, BenefitsWrapper, amountOptionRefInBriefs],
  )

  return (
    <SchemaBaseEditor
      {...props}
      currentSchema={planSchema}
      tabs={tabs}
      checker={checker}
      isReady={!isNil(editContext)}
      editContext={editContext}
      setEditContext={setEditContext}
    />
  )
}

PlanEditDialog.propTypes = {
  doc: PlanPropType.isRequired,
  ticket: TicketPropType.isRequired,
  onHide: PropTypes.func.isRequired,
}
