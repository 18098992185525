import { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, isNil, partial } from 'lodash'

import { ProjectContext, TicketState, useDialog } from '@common'
import { Flex, Box } from '@changingai/react-editor-common-component'

import { docPrivilege } from './config'
import { TiiEditor } from './editor'
import { tiiSchema } from './schema'

import { useCacheDocs, useKGTranspile } from '../Common'
import { useFetchDocOfTicket } from '../TicketHelper'
import { SummaryLabel, bindSchemaWithSummaryLayout } from '../SummaryFields'
import TicketSummaryModel from '../TicketSummaryModel'

// align Tii site to use TW date for better data tracing
function TWDateField({ fieldname, value }) {
  const d = new Date(value)
  const transValue =
    isNil(value) || isNaN(d)
      ? ''
      : `${d.getFullYear() - 1911}/${d.getMonth() + 1}/${d.getDate()}`
  return (
    <Flex data-testid="GenericField" alignItems="center">
      <SummaryLabel
        fieldname={tiiSchema[fieldname].label}
        isEmptyField={isEmpty(value)}
      />
      <Box p="0">{`${transValue}`}</Box>
    </Flex>
  )
}

TWDateField.propTypes = {
  fieldname: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
}

bindSchemaWithSummaryLayout(tiiSchema, {
  sell_start_date: {
    renderer: TWDateField,
  },
  sell_end_date: {
    renderer: TWDateField,
  },
  approved_date: {
    renderer: TWDateField,
  },
})

export const useTranspile = partial(useKGTranspile, tiiSchema)

const fullAccessStates = [TicketState.CREATE, TicketState.FINISH]
function useController(ticket, selectedFields) {
  const tiis = useFetchDocOfTicket(ticket, tiiSchema, selectedFields)
  const { email, peepMode } = useContext(ProjectContext)
  const { updated, docs } = useCacheDocs(
    email,
    peepMode,
    ticket,
    tiis,
    fullAccessStates,
  )

  const [showDialog, renderDoc] = useDialog(TiiEditor)
  const showDoc = useCallback(
    doc => {
      showDialog({ doc })
    },
    [showDialog],
  )

  return {
    isReady: !isNil(tiis),
    docs,
    showDoc,
    updated,
    renderDoc,
  }
}

const defaultSummary = {
  selected: [
    'name',
    'link',
    'company_id',
    'category_id',
    'product_id',
    'sell_start_date',
    'sell_end_date',
    'approved_date',
  ],
  filters: {},
}

function useCrud() {
  return {
    read: true,
    update: false,
    create: false,
    delete: false,
    overwrite: false,
  }
}

const TiiListView = props => {
  return (
    <TicketSummaryModel
      {...props}
      useCrud={useCrud}
      useController={useController}
      useTranspile={useTranspile}
      defaultSummary={defaultSummary}
      requiredPrivilege={[docPrivilege]}
      currentSchema={tiiSchema}
    />
  )
}

export default TiiListView
