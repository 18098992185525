import PropTypes from 'prop-types'
import { partial } from 'lodash'

import { TicketState, namespaces } from '@common'

import { NinjaEditor } from './editor'
import { docPrivilege } from './config'
import { useTranspile } from './listview'
import { ninjaSchema } from './schema'

import { useFetchDocsAndTickets } from '../ComposedView'
import OwnedDocSummaryModel from '../OwnedDocSummaryModel'

import { _queryNinja } from './composed.gql'

const fullAccessStates = [TicketState.MERGE, TicketState.FINISH]
const useController = partial(
  useFetchDocsAndTickets,
  _queryNinja,
  fullAccessStates,
  namespaces.test.value,
  NinjaEditor,
)

const defaultSummary = {
  selected: ['name', 'skill'],
  filters: {},
}

const OwnedNinjaListView = props => {
  return (
    <OwnedDocSummaryModel
      {...props}
      useController={useController}
      useTranspile={useTranspile}
      defaultSummary={defaultSummary}
      requiredPrivilege={[docPrivilege]}
      currentSchema={ninjaSchema}
      showIdInputIcon={true}
    />
  )
}

OwnedNinjaListView.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default OwnedNinjaListView
