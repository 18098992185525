import { every, isEqual, size, get, find, partial } from 'lodash'

import { feeTableSchema } from './schema'

import { useKGTranspile } from '../Common'
import { useUploadDocWithHistory } from '../Uploader'

import { cceClient } from '@gql'
import { dataAnalysisService } from '@remote'

import { _uploadFeeTable } from './common.gql'

export const useTranspile = partial(useKGTranspile, feeTableSchema)

export function useUploadFeeTableOfTicket() {
  const upload = useUploadDocWithHistory(feeTableSchema, async feetables => {
    const {
      data: { uploadFeeTable },
    } = await cceClient.mutate({
      mutation: _uploadFeeTable,
      variables: {
        feetables,
      },
    })

    return uploadFeeTable
  })

  return upload
}

export function isEqualFactors(factorsA, factorsB) {
  if (size(factorsA) != size(factorsB)) return false
  return every(factorsA, ({ id, values }) =>
    isEqual(values, get(find(factorsB, { id }), 'values')),
  )
}

export const checkFeeTable = partial(dataAnalysisService, 'check_feetable')
