import { useMemo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'primereact/button'
import { forEach, keys, isEqual, get, isEmpty, partial, isNil } from 'lodash'
import { Flex } from '@changingai/react-editor-common-component'

import { PropCrud, useDialog } from '@common'
import { SingleInput, inputFactory, generateSubDocProps } from '@widget'
import { dataAnalysisService } from '@remote'

import { BenefitPropType, benefitSchema } from './schema'
import { SchemaBaseEditor } from '../SchemaBaseEditor'
import { PickBenefitDialog } from './pick'
import { checkBenefit } from './common'
import { useFetchFullDoc } from '../DocHelper'
import { useCheckApi } from '../Validation'
import { CalculateRule } from './calculateRules'
import { BenefitConceptPaybacksWrap } from './benefitConceptPaybacksWrap'

import { _queryBenefit } from './editor.gql'

const benefitSuggestion = partial(dataAnalysisService, 'suggest_amount_ratio')
export function BenefitEditDialog(props) {
  const { editContext, setEditContext } = useFetchFullDoc(
    props.doc,
    benefitSchema,
    _queryBenefit,
  )

  const [showPick, renderPick] = useDialog(PickBenefitDialog)
  const checker = useCheckApi({ externalAPI: checkBenefit })

  const PayMethod = useCallback(
    ({ context, onDirty, onValid, onChange }) => {
      return (
        <Flex width="100%" flexWrap="wrap" justifyContent="flex-end">
          {renderPick({
            onPick: ({ pay_method }) => {
              onChange('pay_method', pay_method)
            },
          })}
          <Button
            label="詳見條款"
            className="p-button-success"
            icon="pi pi-sort"
            onClick={() => {
              onChange('pay_method', '詳見條款。')
              onChange('clause_main', '詳見條款。')
            }}
          />
          <Button
            label="CopyTo"
            disabled={
              isEmpty(get(context, 'clause_main')) ||
              isEqual(get(context, 'clause_main'), get(context, 'pay_method'))
            }
            style={{ margin: '0 4px' }}
            className="p-button-warning"
            icon="pi pi-chevron-down"
            onClick={() => {
              onChange('pay_method', get(context, 'clause_main'))
            }}
          />
          <Button
            label="FindSimiliar"
            className="p-button-primary"
            icon="pi pi-search-plus"
            onClick={() => showPick({ benefit: context })}
          />
          <SingleInput
            fieldname="pay_method"
            label={benefitSchema.pay_method.label}
            value={context['pay_method']}
            onDirty={onDirty}
            onValid={onValid}
            onChange={onChange}
            labelAttributes={{ width: '200px' }}
            inputAttributes={{ width: '100%', multilines: true }}
            currentSchema={benefitSchema}
          />
        </Flex>
      )
    },
    [renderPick, showPick],
  )

  const [loadingSuggestion, setLoadingSuggestion] = useState(false)
  const AmountRatioSuggestion = useCallback(
    ({ context, onDirty, onChange }) => {
      return (
        <Flex width="100%" flexWrap="wrap" justifyContent="flex-end" p="3">
          <Button
            label="Suggestion"
            className="p-button-primary"
            icon={
              loadingSuggestion ? 'pi pi-spin pi-spinner' : 'pi pi-search-plus'
            }
            disabled={loadingSuggestion}
            onClick={async () => {
              setLoadingSuggestion(true)
              const response = await benefitSuggestion({ doc: context })
              const suggestion = await response.json()
              forEach(keys(suggestion), fieldname => {
                if (!isEqual(suggestion[fieldname], context[fieldname])) {
                  onChange(fieldname, suggestion[fieldname])
                  onDirty(fieldname, true)
                }
              })
              setLoadingSuggestion(false)
            }}
          />
        </Flex>
      )
    },
    [setLoadingSuggestion, loadingSuggestion],
  )

  const tabs = useMemo(
    () => [
      {
        caption: 'Basic',
        configs: [
          inputFactory(benefitSchema, 'benefit_name'),
          inputFactory(benefitSchema, 'clause_main', {
            inputAttributes: {
              multilines: true,
            },
          }),
          {
            fieldname: 'pay_method',
            widget: 'CustomizedInput',
            RenderComp: PayMethod,
          },
          inputFactory(benefitSchema, 'clause_coverage', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(benefitSchema, 'clause_exclusion', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(benefitSchema, 'clause_exception', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(benefitSchema, 'clause_restriction', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(benefitSchema, 'clause_application', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(benefitSchema, 'waiting_period'),
        ],
      },
      {
        caption: 'Detailed',
        configs: [
          {
            fieldname: 'benefit_tag_suggestion',
            widget: 'CustomizedInput',
            RenderComp: AmountRatioSuggestion,
          },
          inputFactory(benefitSchema, 'benefit_type'),
          inputFactory(benefitSchema, 'request_unit'),
          inputFactory(benefitSchema, 'amount_ratio'),
          inputFactory(benefitSchema, 'ratio_without_NHI'),
          inputFactory(benefitSchema, 'currency_id'),
          inputFactory(benefitSchema, 'amount_range'),
        ],
      },
      {
        caption: 'Actuary',
        configs: [
          {
            ...generateSubDocProps(benefitSchema, 'calculate_rules'),
            itemConfigs: [
              {
                widget: 'CustomizedInput',
                RenderComp: CalculateRule,
              },
            ],
          },
        ],
      },
      {
        caption: '公版理賠資訊',
        configs: [
          {
            fieldname: 'benefit_concept_paybacks_wrap',
            widget: 'CustomizedInput',
            RenderComp: BenefitConceptPaybacksWrap,
          },
        ],
      },
    ],
    [PayMethod, AmountRatioSuggestion],
  )

  return (
    <SchemaBaseEditor
      {...props}
      currentSchema={benefitSchema}
      tabs={tabs}
      checker={checker}
      isReady={!isNil(editContext)}
      editContext={editContext}
      setEditContext={setEditContext}
    />
  )
}

BenefitEditDialog.propTypes = {
  doc: BenefitPropType.isRequired,
  crud: PropCrud.isRequired,
  onUpload: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
}
