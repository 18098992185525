import { useState, useCallback } from 'react'
import LoginButton from './LoginButton'

export { MainMenu, menuHeight } from './MainMenu'

export function usePrivilege() {
  const [privilege, setPrivilege] = useState(null)
  const [email, setEmail] = useState('')
  const update = useCallback((can, email) => {
    const privilege = can.includes('all') ? ['view', 'edit'] : can
    setPrivilege(privilege)
    setEmail(email)
  }, [])

  function render(app) {
    return <LoginButton app={app} onChange={update} />
  }

  return [privilege, email, render]
}
