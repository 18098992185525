import { useState, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { sortBy, map } from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { OverlayPanel } from 'primereact/overlaypanel'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { FaInfoCircle } from 'react-icons/fa'
import { Flex } from '@changingai/react-editor-common-component'

import { useDialog } from '@common'
import { cceClient } from '@gql'
import { ButtonLike } from '@widget'
import { CompareDiffDialog } from '@dialog'

import { _queryHistory } from './HistoryPanel.gql'

function HistoryPanel({ id, useTranspile }) {
  const [fetch, { loading, data }] = useLazyQuery(_queryHistory, {
    client: cceClient,
    variables: {
      target_ids: [id],
    },
  })

  const histories = useMemo(() => {
    const idToDT = _id => {
      const timestamp = _id.toString().substring(0, 8)
      const result = new Date(parseInt(timestamp, 16) * 1000)
      return result.toLocaleString()
    }

    if (loading || !data) return null
    return sortBy(
      map(data.queryHistory, ({ _id, actor, actionType, actionContent }) => ({
        dt: idToDT(_id),
        actor,
        actionType,
        _id,
        actionContent,
      })),
      ['_id'],
    )
  }, [loading, data])

  const historyInfo = useRef(null)

  const [selected, setSelected] = useState([])
  const renderSelection = item => (
    <>
      <Checkbox
        value={item._id}
        onChange={({ checked }) => {
          if (checked) {
            if (selected.length < 2) setSelected([...selected, item._id])
          } else setSelected(selected.filter(id => id !== item._id))
        }}
        checked={selected.includes(item._id)}
      />
    </>
  )

  const [showDiff, renderDiff] = useDialog(CompareDiffDialog)

  return (
    <div>
      {histories &&
        renderDiff({
          docs: histories
            .filter(({ _id }) => selected.includes(_id))
            .map(({ actionContent }) => JSON.parse(actionContent)),
          useTranspile,
        })}
      <ButtonLike
        onClick={e => {
          fetch()
          historyInfo.current.toggle(e)
        }}
      >
        <FaInfoCircle
          style={{
            color: 'white',
            width: '20px',
            height: '20px',
            margin: '0 10px',
          }}
        />
      </ButtonLike>
      <OverlayPanel ref={historyInfo} appendTo={document.body}>
        {!histories && <>Loading...</>}
        {histories && histories.length === 0 && <div>No history log.</div>}
        {histories && histories.length > 0 && (
          <>
            <DataTable
              value={histories}
              paginator={false}
              style={{ maxHeight: '600px', width: '800px', overflow: 'auto' }}
            >
              <Column field="actor" header="更新者" />
              <Column field="actionType" header="CRUD" />
              <Column field="dt" header="Timestamp" />
              <Column field="_id" body={renderSelection} header="比對" />
            </DataTable>
            <Flex justifyContent="flex-end" p="2">
              <Button
                type="button"
                label="Compare"
                className="p-button-success"
                style={{ marginRight: '.25em' }}
                onClick={() => showDiff()}
                disabled={selected.length !== 2}
              />
            </Flex>
          </>
        )}
      </OverlayPanel>
    </div>
  )
}

HistoryPanel.propTypes = {
  id: PropTypes.string.isRequired,
  useTranspile: PropTypes.func.isRequired,
}

export default HistoryPanel
