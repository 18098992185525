import { useState, useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from 'primereact/button'
import { ListBox } from 'primereact/listbox'
import { AiFillPlusCircle } from 'react-icons/ai'
import { IoIosCloseCircle } from 'react-icons/io'
import { GoPlus } from 'react-icons/go'
import uuid from 'react-uuid'
import {
  concat,
  filter as _filter,
  map,
  uniq,
  intersection,
  isEmpty,
  includes,
} from 'lodash'

import { Box, Flex } from '@changingai/react-editor-common-component'
import { useQueryAndCacheKG, useDialog } from '@common'

import { useInit, useError, NodesType } from './common'
import { ColumnRoot, InputRoot, WidgetContainer } from './StyledComps'
import { ButtonLike } from './button'
import { ToastifyLabel } from './ToastifyLabel'
import { SelectChannelDialog } from './SelectChannelDialog'

const ChannelButton = styled(ButtonLike)``
ChannelButton.defaultProps = {
  borderTop: '1px solid',
  bg: 'blacks.2',
  borderColor: 'blacks.5',
  m: 0,
  p: 2,
  width: '100%',
}

function NodeList({ showChannel, options, onChange, filter, icon }) {
  const [DelectIcon, color, deletePosition] = icon
  return (
    <Flex flexDirection="column" width="100%">
      <ListBox
        style={{ flex: '1 1 100%', borderRadius: 0 }}
        listStyle={{ height: '300px' }}
        options={options ? options : []}
        optionLabel="name"
        itemTemplate={({ name }) => (
          <Flex justifyContent="space-between" alignItems="center">
            {deletePosition === 'right' && <Box>{name}</Box>}
            <DelectIcon style={{ color }} />
            {deletePosition === 'left' && <Box>{name}</Box>}
          </Flex>
        )}
        onChange={onChange}
        filter={filter}
      />
      <ChannelButton onClick={() => showChannel()}>
        <GoPlus style={{ width: '20px', height: '20px' }} />
      </ChannelButton>
    </Flex>
  )
}

NodeList.propTypes = {
  showChannel: PropTypes.func.isRequired,
  options: NodesType,
  onChange: PropTypes.func.isRequired,
  filter: PropTypes.bool.isRequired,
  icon: PropTypes.array,
}

export function MultipleNodeList({
  fieldname,
  label,
  node_ids,
  onChange,
  onDirty,
  onValid,
  node_types = [],
  hightlights = [],
  showLabel = true,
  inputAttributes = { width: '50%' },
  labelAttributes = { width: '150px' },
  labelPosition = 'left',
  icon = [IoIosCloseCircle, 'green', 'right'],
  filter = false,
  editable = true,
}) {
  const [, compare] = useInit(node_ids)
  const [id] = useState(uuid())
  const error = useError(onValid, id, fieldname, node_ids, editable)
  const [showChannel, renderChannel] = useDialog(SelectChannelDialog)
  const [values, setValues] = useState(node_ids)
  const options = useQueryAndCacheKG(values)

  useEffect(() => {
    onChange(fieldname, values)
    onDirty(id, compare(values))
  }, [values, fieldname, onChange, onDirty, compare, id])

  useEffect(() => {
    return function cleanupDirtyAndError() {
      onDirty(id, false)
      onValid(fieldname, null, { clean: true, key: id })
    }
  }, [fieldname, onDirty, onValid, id])

  const onOK = useCallback(
    nodes => {
      setValues(uniq([...values, ...map(nodes, 'node_id')]))
    },
    [values, setValues],
  )
  const showHighlight = hightlights.includes(fieldname)
  return (
    <WidgetContainer width={inputAttributes.width}>
      {renderChannel({ allowed: node_types, onOK })}
      <InputRoot
        hightlight={showHighlight}
        p="1"
        flexWrap={labelPosition === 'top' ? 'wrap' : 'nowrap'}
      >
        {showLabel && (
          <Flex
            justifyContent={labelPosition === 'top' ? 'center' : 'flex-start'}
            p="2"
            bg="blacks.2"
            border="1px solid gray"
            width={labelPosition === 'top' ? '100%' : labelAttributes.width}
          >
            <ToastifyLabel
              fieldname={fieldname}
              label={`${label} (${options ? options.length : 0})`}
              changed={compare(node_ids)}
              error={error}
              {...labelAttributes}
              width={labelPosition === 'top' ? '' : labelAttributes.width}
            />
          </Flex>
        )}
        <NodeList
          showChannel={showChannel}
          options={options}
          filter={filter}
          icon={icon}
          onChange={({ value: { node_id } }) => {
            setValues(_filter(values, value => value !== node_id))
          }}
        />
      </InputRoot>
    </WidgetContainer>
  )
}

MultipleNodeList.propTypes = {
  fieldname: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  node_ids: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  onDirty: PropTypes.func.isRequired,
  onValid: PropTypes.func.isRequired,
  node_types: PropTypes.arrayOf(PropTypes.string),
  hightlights: PropTypes.arrayOf(PropTypes.string),
  showLabel: PropTypes.bool,
  width: PropTypes.string,
  labelAttributes: PropTypes.object,
  icon: PropTypes.array,
  filter: PropTypes.bool,
  editable: PropTypes.bool,
  labelPosition: PropTypes.string,
  inputAttributes: PropTypes.object,
}

export function MultipleNodeListMergeWidget({
  fieldname,
  leftValue,
  rightValue,
  node_types = [],
  onChange,
  onValid,
  filter = false,
}) {
  const leftNodes = useQueryAndCacheKG(leftValue)
  const rightNodes = useQueryAndCacheKG(rightValue)
  const [content, setContent] = useState([])
  const options = useQueryAndCacheKG(content)

  const [leftOptions, rightOptions] = useMemo(
    () =>
      map([leftNodes, rightNodes], values =>
        _filter(values, ({ node_id }) => !includes(content, node_id)),
      ),
    [leftNodes, rightNodes, content],
  )

  const [showChannel, renderChannel] = useDialog(SelectChannelDialog)

  const onOK = useCallback(
    nodes => {
      setContent(uniq([...content, ...map(nodes, 'node_id')]))
    },
    [setContent, content],
  )

  useEffect(() => {
    onChange(fieldname, content)
    onValid(fieldname, content)
  }, [fieldname, content, onValid, onChange])

  return (
    <Flex width="100%">
      {renderChannel({ allowed: node_types, onOK })}
      <ColumnRoot>
        <Box fontSize="h1">Left</Box>
        <ListBox
          style={{ flex: '1 1 100%', borderRadius: 0, width: '100%' }}
          options={leftOptions}
          optionLabel="name"
          listStyle={{ height: '330px' }}
          itemTemplate={({ name }) => (
            <Flex justifyContent="space-between" alignItems="center">
              <Box>{name}</Box>
              <AiFillPlusCircle />
            </Flex>
          )}
          onChange={({ value: { node_id } }) => {
            setContent(uniq(concat(content, node_id)))
          }}
        />
        <Button
          style={{ height: '30px', marginTop: '5px' }}
          label="Add All"
          disabled={isEmpty(map(leftOptions, 'node_id'))}
          onClick={() =>
            setContent(uniq([...content, ...map(leftOptions, 'node_id')]))
          }
        />
      </ColumnRoot>
      <ColumnRoot>
        <Box fontSize="h1">Result</Box>
        <NodeList
          showChannel={showChannel}
          options={options}
          filter={filter}
          icon={[IoIosCloseCircle, 'green', 'right']}
          onChange={({ value: { node_id } }) => {
            setContent(_filter(content, value => value !== node_id))
          }}
        />
        <Button
          style={{ height: '30px', marginTop: '5px' }}
          label="Pick Common"
          disabled={isEmpty(
            intersection(
              map(leftOptions, 'node_id'),
              map(rightOptions, 'node_id'),
            ),
          )}
          onClick={() =>
            setContent(
              intersection(
                map(leftOptions, 'node_id'),
                map(rightOptions, 'node_id'),
              ),
            )
          }
        />
      </ColumnRoot>
      <ColumnRoot>
        <Box fontSize="h1">Right</Box>
        <ListBox
          style={{ flex: '1 1 100%', borderRadius: 0, width: '100%' }}
          options={rightOptions}
          optionLabel="name"
          listStyle={{ height: '330px' }}
          itemTemplate={({ name }) => (
            <Flex justifyContent="space-between" alignItems="center">
              <Box>{name}</Box>
              <AiFillPlusCircle />
            </Flex>
          )}
          onChange={({ value: { node_id } }) => {
            setContent(uniq(concat(content, node_id)))
          }}
        />
        <Button
          style={{ height: '30px', marginTop: '5px' }}
          label="Add All"
          disabled={isEmpty(map(rightOptions, 'node_id'))}
          onClick={() =>
            setContent(uniq([...content, ...map(rightOptions, 'node_id')]))
          }
        />
      </ColumnRoot>
    </Flex>
  )
}

MultipleNodeListMergeWidget.propTypes = {
  fieldname: PropTypes.string.isRequired,
  leftValue: PropTypes.array.isRequired,
  rightValue: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onValid: PropTypes.func.isRequired,
  node_types: PropTypes.arrayOf(PropTypes.string),
  filter: PropTypes.bool,
}
