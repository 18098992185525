import { useCallback, useContext } from 'react'
import { castArray, find, isNil, partial, stubTrue } from 'lodash'
import { Link } from 'react-router-dom'

import {
  ProjectContext,
  TicketPropType,
  TicketState,
  isLocalDoc,
  useDialog,
} from '@common'
import { createBlankDoc } from '@schema'
import { Flex } from '@changingai/react-editor-common-component'
import { cceClient } from '@gql'

import { useTranspile, useUploadFeeTableOfTicket } from './common'
import { docPrivilege } from './config'
import { FeeTableEditDialog } from './editor'
import { feeTableSchema } from './schema'

import TicketSummaryModel from '../TicketSummaryModel'
import { useCacheDocs } from '../Common'
import { cloneTicketDoc } from '../DocHelper'
import { useReferenceBase } from '../Reference'
import { useFetchDocOfTicket, createBlankTicketable } from '../TicketHelper'
import { useDeleteDocWithHistory } from '../Uploader'
import { bindSchemaWithSummaryLayout } from '../SummaryFields'

import { _deleteFeeTable, _queryFeeTable } from './listview.gql'

bindSchemaWithSummaryLayout(feeTableSchema, {
  matrices: {
    skipRender: true,
  },
})

const useReference = partial(useReferenceBase, 'feetable', stubTrue)

const fullAccessStates = [TicketState.MERGE, TicketState.FINISH]
function useController(ticket, selectedFields) {
  const feetables = useFetchDocOfTicket(ticket, feeTableSchema, selectedFields)

  const { email, peepMode } = useContext(ProjectContext)
  const { updated, deleteCache, updateCache, docs } = useCacheDocs(
    email,
    peepMode,
    ticket,
    feetables,
    fullAccessStates,
  )

  const createDoc = useCallback(
    uploader => {
      const doc = {
        ...createBlankDoc(feeTableSchema, true),
        ...createBlankTicketable(ticket, uploader),
      }
      updateCache(doc)
      return doc
    },
    [updateCache, ticket],
  )

  const deleteFeeTable = useDeleteDocWithHistory(feeTableSchema, ids => {
    cceClient.mutate({
      mutation: _deleteFeeTable,
      variables: {
        ids: castArray(ids),
      },
    })
  })
  const deleteDoc = useCallback(
    _id => {
      deleteCache(_id)
      const deleted = find(docs, { _id })
      if (isNil(deleted) || isLocalDoc(deleted)) return
      return deleteFeeTable(deleted)
    },
    [deleteCache, docs, deleteFeeTable],
  )

  const cloneDoc = useCallback(
    async doc => {
      const cloned = await cloneTicketDoc(
        _queryFeeTable,
        doc,
        ticket,
        email,
        feeTableSchema,
      )
      updateCache({ ...cloned, factors: [] })
    },
    [updateCache, email, ticket],
  )

  const [showDialog, renderDialog] = useDialog(FeeTableEditDialog)
  const showDoc = useCallback(
    (doc, crud) => {
      showDialog({
        doc,
        ticket,
        crud,
      })
    },
    [ticket, showDialog],
  )

  const upload = useUploadFeeTableOfTicket()
  const uploadDoc = useCallback(
    context => upload(ticket, context, email, true),
    [upload, ticket, email],
  )

  return {
    isReady: !isNil(feetables),
    docs,
    createDoc,
    updateDoc: updateCache,
    deleteDoc,
    cloneDoc,
    showDoc,
    renderDoc: renderDialog,
    updated,
    uploadDoc,
    useReference,
  }
}

const defaultSummary = {
  selected: ['name', 'currency_id', 'raw_files', 'fee_base', 'factors'],
  filters: {},
}

function ProductLink({ ticket }) {
  return (
    <Flex
      width="100%"
      height="100%"
      px="2"
      justifyContent="flex-end"
      alignItems="center"
    >
      <Link
        to={`/productSchema/${ticket.scope_id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        產品連結
      </Link>
    </Flex>
  )
}

ProductLink.propTypes = {
  ticket: TicketPropType.isRequired,
}

const FeeTableListView = props => {
  return (
    <TicketSummaryModel
      {...props}
      useController={useController}
      useTranspile={useTranspile}
      defaultSummary={defaultSummary}
      HeaderRightPanel={ProductLink}
      requiredPrivilege={[docPrivilege]}
      currentSchema={feeTableSchema}
    />
  )
}

FeeTableListView.propTypes = {
  ticket: TicketPropType.isRequired,
}

export default FeeTableListView
