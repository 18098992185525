import { useState, useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useImmer } from 'use-immer'
import { parse } from 'papaparse'
import { toast } from 'react-toastify'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import { TabView, TabPanel } from 'primereact/tabview'
import { MdDeleteForever, MdAddCircle } from 'react-icons/md'
import { ImArrowLeft, ImArrowRight } from 'react-icons/im'
import { FaFileExport } from 'react-icons/fa'

import { isEmpty, isEqual, fill, slice, map, partial, isNil } from 'lodash'
import uuid from 'react-uuid'
import { Flex, Box } from '@changingai/react-editor-common-component'

import { exportDoc, csvBlob } from '@common'
import { purifySchemaDoc } from '@schema'
import {
  ButtonLike,
  TableInput,
  ImportButton,
  useError,
  ToastifyLabel,
  create2dArray,
  Checkbox,
  inputFactory,
  createSeparator,
} from '@widget'
import { dataAnalysisService } from '@remote'

import { rules } from './rules'
import { shopRewardSchema, TablePropType, tableSchema } from './schema'

import { useFetchFullDoc } from '../DocHelper'
import { useCheckApi } from '../Validation'
import { SchemaBaseEditor } from '../SchemaBaseEditor'

import { _queryRewardShop } from './editor.gql'

const checkShopReward = partial(dataAnalysisService, 'check_rewardshop')
function TableToolbar({ table, onChange }) {
  const [editTitle, setEditTitle] = useState(table.title || '')
  return (
    <>
      <Box>{`${tableSchema.title.label}：`}</Box>
      <InputText
        value={editTitle}
        placeholder={tableSchema.title.label}
        onChange={({ target: { value } }) => {
          setEditTitle(value)
        }}
        onKeyDown={({ key, target }) => {
          if (key === 'Enter') {
            onChange({ title: editTitle })
            target.blur()
          }
        }}
        onBlur={() => {
          onChange({ title: editTitle })
        }}
      />
      <Checkbox
        text={tableSchema.hide_header.label}
        color="black"
        checked={table.hide_header}
        onChecked={() => {
          onChange({ hide_header: !table.hide_header })
        }}
      />
      <ButtonLike
        data-tip="匯出文件"
        size={6}
        scaleOnHover
        onClick={() => {
          exportDoc('Table.csv', csvBlob([table.header, ...table.values]))
        }}
        m="20px"
      >
        <FaFileExport
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </ButtonLike>
    </>
  )
}

TableToolbar.propTypes = {
  table: TablePropType.isRequired,
  onChange: PropTypes.func.isRequired,
}

function MenuTableEditor({
  context,
  label,
  fieldname,
  onDirty,
  onValid,
  onChange,
}) {
  const value = context[fieldname]
  const [id] = useState(uuid())

  const [init] = useState(value)
  const [tables, setTables] = useImmer(value)
  const [newTableConfig, setNewTableConfig] = useImmer({ row: 3, col: 3 })
  const [activeIndex, setActiveIndex] = useState(0)

  const error = useError(onValid, id, fieldname, value, true)
  const isDirty = useMemo(
    () =>
      !isEqual(
        map(init, table => purifySchemaDoc(table, tableSchema)),
        map(tables, table => purifySchemaDoc(table, tableSchema)),
      ),
    [init, tables],
  )
  useEffect(() => {
    onChange(fieldname, tables)
    onDirty(id, isDirty)
  }, [fieldname, tables, id, onDirty, onChange, isDirty])

  const onTableChange = useCallback(
    (table, index) => {
      setTables(draft => {
        draft[index] = {
          ...draft[index],
          ...table,
        }
      })
    },
    [setTables],
  )

  return (
    <Flex width="100%" alignItems="center" my="2" p="2" flexWrap="wrap">
      <Flex width="100%" alignItems="center">
        <ToastifyLabel
          label={label}
          fieldname={fieldname}
          changed={isDirty}
          error={error}
        />
        <ButtonLike
          data-tip="新增表格"
          size={6}
          scaleOnHover
          onClick={() => {
            const { row, col } = newTableConfig
            setTables(draft => [
              ...draft,
              {
                header: fill(Array(col), ''),
                values: create2dArray(row, col),
                hide_header: false,
              },
            ])
            setActiveIndex(tables.length)
          }}
        >
          <MdAddCircle />
        </ButtonLike>
        列數：
        <InputNumber
          size={1}
          min={1}
          style={{ marginRight: '5px' }}
          value={newTableConfig.row}
          onValueChange={({ value }) =>
            setNewTableConfig(draft => {
              draft.row = value
            })
          }
        />
        欄數：
        <InputNumber
          size={1}
          min={1}
          style={{ marginRight: '5px' }}
          value={newTableConfig.col}
          onValueChange={({ value }) =>
            setNewTableConfig(draft => {
              draft.col = value
            })
          }
        />
        <ImportButton
          accept=".csv"
          onImport={({ target: { files } }) => {
            const reader = new FileReader()
            reader.onload = () => {
              const { data, errors: parseErrors } = parse(reader.result, {
                skipEmptyLines: true,
                transform: v => v.trim(),
              })

              if (!isEmpty(parseErrors) || data.length < 2) {
                toast.error('Empty or illegal file.', {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 2000,
                })
                return
              }

              setTables(draft => [
                ...draft,
                { header: data[0], values: slice(data, 1), hide_header: false },
              ])
              setActiveIndex(tables.length)
            }
            reader.readAsText(files[0])
          }}
        />
        <ButtonLike
          disabled={isEmpty(tables)}
          data-tip="刪除表格"
          size={6}
          scaleOnHover
          onClick={() => {
            setTables(draft => {
              draft.splice(activeIndex, 1)
            })
            if (activeIndex >= tables.length - 1 && activeIndex > 0)
              setActiveIndex(activeIndex - 1)
          }}
        >
          <MdDeleteForever
            style={{
              color: 'red',
            }}
          />
        </ButtonLike>
        <ButtonLike
          disabled={activeIndex === 0}
          size={6}
          scaleOnHover
          onClick={() => {
            setTables(draft => {
              ;[draft[activeIndex - 1], draft[activeIndex]] = [
                draft[activeIndex],
                draft[activeIndex - 1],
              ]
            })
            setActiveIndex(activeIndex - 1)
          }}
        >
          <ImArrowLeft
            style={{
              color: 'blue',
            }}
          />
        </ButtonLike>
        <ButtonLike
          disabled={activeIndex >= tables.length - 1}
          size={6}
          scaleOnHover
          onClick={() => {
            setTables(draft => {
              ;[draft[activeIndex + 1], draft[activeIndex]] = [
                draft[activeIndex],
                draft[activeIndex + 1],
              ]
            })
            setActiveIndex(activeIndex + 1)
          }}
        >
          <ImArrowRight
            style={{
              color: 'blue',
            }}
          />
        </ButtonLike>
      </Flex>
      <TabView
        style={{ width: '100%', position: 'relative' }}
        activeIndex={activeIndex}
        onTabChange={({ index }) => setActiveIndex(index)}
      >
        {tables.map(({ header, values, hide_header, title }, idx) => (
          <TabPanel
            key={idx}
            style={{ width: '100%' }}
            header={title ? title : `Table ${idx}`}
          >
            <Box width="100%" p="1" height="450px">
              <TableInput
                toolBar={
                  <TableToolbar
                    table={{ header, values, hide_header, title }}
                    onChange={table => onTableChange(table, idx)}
                  />
                }
                title={title ? title : `Table ${idx}`}
                header={header}
                values={values}
                onChange={table => onTableChange(table, idx)}
              />
            </Box>
          </TabPanel>
        ))}
      </TabView>
    </Flex>
  )
}

MenuTableEditor.propTypes = {
  context: PropTypes.object.isRequired,
  fieldname: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDirty: PropTypes.func.isRequired,
  onValid: PropTypes.func.isRequired,
}

export function ShopRewardEditDialog(props) {
  const { editContext, setEditContext } = useFetchFullDoc(
    props.doc,
    shopRewardSchema,
    _queryRewardShop,
  )
  const checker = useCheckApi({ rules, externalAPI: checkShopReward })
  const MenuTableEditorWrapper = useCallback(
    props => (
      <MenuTableEditor
        {...props}
        fieldname="tables"
        label={shopRewardSchema.tables.label}
      />
    ),
    [],
  )

  const tabs = useMemo(
    () => [
      {
        caption: 'Basic',
        configs: [
          {
            fieldname: 'img_urls',
            widget: 'ImageUploader',
          },
          inputFactory(shopRewardSchema, 'title'),
          inputFactory(shopRewardSchema, 'subtitle'),
          inputFactory(shopRewardSchema, 'type_id'),
          createSeparator({}),
          inputFactory(shopRewardSchema, 'effective_date_b', {
            inputAttributes: { width: '33%' },
          }),
          inputFactory(shopRewardSchema, 'effective_date_e', {
            inputAttributes: { width: '33%' },
          }),
          inputFactory(shopRewardSchema, 'launch_date', {
            inputAttributes: { width: '33%' },
          }),
          createSeparator({}),
          inputFactory(shopRewardSchema, 'csp_channel_ids', {
            inputAttributes: { width: '33%' },
            labelPosition: 'top',
          }),
          inputFactory(shopRewardSchema, 're_channel_ids', {
            inputAttributes: { width: '33%' },
            labelPosition: 'top',
          }),
          inputFactory(shopRewardSchema, 'csp_target_ids', {
            inputAttributes: { width: '33%' },
            node_types: ['target'],
            labelPosition: 'top',
          }),
          inputFactory(shopRewardSchema, 'exclude_list_ids', {
            inputAttributes: { width: '33%' },
            node_types: ['channel', 'channel_concept', 'target'],
            labelPosition: 'top',
          }),
          inputFactory(shopRewardSchema, 're_target_ids', {
            inputAttributes: { width: '33%' },
            node_types: ['target'],
            labelPosition: 'top',
          }),
          inputFactory(shopRewardSchema, 'by_method_ids', {
            filter: true,
            inputAttributes: { width: '50%' },
            labelPosition: 'top',
          }),
          inputFactory(shopRewardSchema, 'identity_ids', {
            filter: true,
            inputAttributes: { width: '50%' },
            labelPosition: 'top',
          }),
          createSeparator({}),
          inputFactory(shopRewardSchema, 'need_registration', {
            inputAttributes: { width: '50%' },
          }),
          inputFactory(shopRewardSchema, 'special_day', {
            inputAttributes: { width: '50%' },
          }),
          createSeparator({}),
          inputFactory(shopRewardSchema, 'precondition', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(shopRewardSchema, 'detail', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(shopRewardSchema, 'data_source_urls'),
          inputFactory(shopRewardSchema, 'link_building_urls'),
        ],
      },
      {
        caption: 'Table',
        configs: [
          {
            fieldname: 'tables',
            widget: 'CustomizedInput',
            RenderComp: MenuTableEditorWrapper,
          },
        ],
      },
    ],
    [MenuTableEditorWrapper],
  )

  return (
    <SchemaBaseEditor
      {...props}
      currentSchema={shopRewardSchema}
      tabs={tabs}
      checker={checker}
      isReady={!isNil(editContext)}
      editContext={editContext}
      setEditContext={setEditContext}
    />
  )
}

ShopRewardEditDialog.propTypes = {
  doc: PropTypes.object.isRequired,
}
