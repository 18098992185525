import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { get, isNil } from 'lodash'

import { TicketState } from '@common'
import { cceClient } from '@gql'

import { backendDocType, namespace } from './config'
import { CRUDO } from '../TicketHelper'

import { _queryContract } from './ticket.gql'

export function useScopeName(scope_id) {
  const { loading, data } = useQuery(_queryContract, {
    client: cceClient,
    variables: {
      doc_ids: [scope_id],
    },
  })

  return useMemo(
    () =>
      loading || isNil(data)
        ? scope_id
        : get(data, 'queryContract.0.contract_name'),
    [loading, data, scope_id],
  )
}

export const ticketProfile = {
  ticketType: backendDocType,
  namespace,
  states: [
    {
      main_state: TicketState.CREATE,
      assignee_quota: 2,
      assignee_as_editor: true,
      crud: CRUDO.RW_NO_OVERWRITE,
    },
    {
      main_state: TicketState.MERGE,
      assignee_quota: 1,
      assignee_as_editor: false,
      crud: CRUDO.FULLY_ACCESS,
    },
    {
      main_state: TicketState.FINISH,
      assignee_quota: 0,
      assignee_as_editor: false,
      crud: CRUDO.READ_ONLY,
    },
  ],
  autoCreate: true,
  enableVersion: false,
  filterByCurrentUser: true,
}
