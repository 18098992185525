import { namespace, schema, backendDocType, docPrivilege } from './config'
import loader, {
  CardRestorer,
  generateReferenceDoc,
  queryManifest,
} from './loader'
import listview, { useTranspile } from './listview'
import { ticketProfile } from './ticket'
import { addViewManifest } from '../Config'

const manifest = {
  loader,
  schema,
  namespace,
  backendDocType,
  privilege: docPrivilege,
  queryManifest,
  useTranspile,
  generateReferenceDoc,
  restore: CardRestorer,
  listview,
  ticketProfile,
  order: 1,
}

addViewManifest(manifest)
