import { useCallback, useContext, useMemo } from 'react'
import { find, castArray, isNil, get } from 'lodash'
import { Link } from 'react-router-dom'

import {
  ProjectContext,
  TicketPropType,
  TicketState,
  isLocalDoc,
  useDialog,
} from '@common'
import { createBlankDoc } from '@schema'
import { Flex } from '@changingai/react-editor-common-component'
import { cceClient } from '@gql'

import './summary'
import { useTranspile, useUploadPlanOfTicket } from './common'
import { docPrivilege } from './config'
import { usePlanContext, PlanContext } from './context'
import { PlanEditDialog } from './editor'
import { planSchema } from './schema'

import TicketSummaryModel from '../TicketSummaryModel'
import { useFetchDocOfTicket, createBlankTicketable } from '../TicketHelper'
import { useCacheDocs } from '../Common'
import { cloneTicketDoc } from '../DocHelper'
import { useDeleteDocWithHistory } from '../Uploader'

import { _deletePlan, _queryPlan } from './listview.gql'

const fullAccessStates = [TicketState.MERGE, TicketState.FINISH]
function useController(ticket, selectedFields) {
  const plans = useFetchDocOfTicket(ticket, planSchema, selectedFields)
  const { email, peepMode } = useContext(ProjectContext)
  const { updated, deleteCache, updateCache, docs } = useCacheDocs(
    email,
    peepMode,
    ticket,
    plans,
    fullAccessStates,
  )

  const createDoc = useCallback(
    uploader => {
      const doc = {
        ...createBlankDoc(planSchema, true),
        ...createBlankTicketable(ticket, uploader),
      }

      updateCache(doc)
      return doc
    },
    [updateCache, ticket],
  )

  const deletePlan = useDeleteDocWithHistory(planSchema, ids => {
    cceClient.mutate({
      mutation: _deletePlan,
      variables: {
        ids: castArray(ids),
      },
    })
  })
  const deleteDoc = useCallback(
    _id => {
      deleteCache(_id)
      const deleted = find(docs, { _id })
      if (isNil(deleted) || isLocalDoc(deleted)) return
      return deletePlan(deleted)
    },
    [deleteCache, docs, deletePlan],
  )

  const cloneDoc = useCallback(
    async doc => {
      const cloned = await cloneTicketDoc(
        _queryPlan,
        doc,
        ticket,
        email,
        planSchema,
      )
      updateCache(cloned)
    },
    [updateCache, email, ticket],
  )

  const [showDialog, renderDialog] = useDialog(PlanEditDialog)
  const showDoc = useCallback(
    (doc, crud) => {
      showDialog({
        doc,
        ticket,
        crud,
      })
    },
    [ticket, showDialog],
  )

  const upload = useUploadPlanOfTicket()
  const uploadDoc = useCallback(context => upload(ticket, context, email), [
    upload,
    ticket,
    email,
  ])

  const { isReady: isContextReady } = useContext(PlanContext)
  return {
    isReady: !isNil(plans) && isContextReady,
    docs,
    createDoc,
    updateDoc: updateCache,
    deleteDoc,
    cloneDoc,
    showDoc,
    renderDoc: renderDialog,
    updated,
    uploadDoc,
  }
}

const defaultSummary = {
  selected: ['name', 'benefits'],
  filters: {},
}

function ProductLink({ ticket }) {
  return (
    <Flex
      width="100%"
      height="100%"
      px="2"
      justifyContent="flex-end"
      alignItems="center"
    >
      <Link
        to={`/productSchema/${ticket.scope_id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        產品連結
      </Link>
    </Flex>
  )
}

ProductLink.propTypes = {
  ticket: TicketPropType.isRequired,
}

const PlanListView = props => {
  const scopeId = useMemo(() => get(props, 'ticket.scope_id'), [props])
  const context = usePlanContext([scopeId])
  return (
    <PlanContext.Provider value={context}>
      <TicketSummaryModel
        {...props}
        useController={useController}
        useTranspile={useTranspile}
        defaultSummary={defaultSummary}
        HeaderRightPanel={ProductLink}
        requiredPrivilege={[docPrivilege]}
        currentSchema={planSchema}
      />
    </PlanContext.Provider>
  )
}

PlanListView.propTypes = {
  ticket: TicketPropType.isRequired,
}

export default PlanListView
