import { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Dialog } from 'primereact/dialog'
import { create, formatters } from 'jsondiffpatch'
import 'jsondiffpatch/dist/formatters-styles/html.css'

import { Flex, Box } from '@changingai/react-editor-common-component'

const Root = styled(Flex)`
  h3 {
    display: inline;
    margin: 0 0.5em;
  }
  p {
    display: inline;
  }
  flex-wrap: wrap;
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
`

export function CompareDiffDialog({ docs, onHide, useTranspile }) {
  const transpileds = useTranspile(docs, null)

  const fullDiff = useMemo(() => {
    if (!transpileds) return null

    const jsondiffpatch = create()
    const delta = jsondiffpatch.diff(transpileds[0], transpileds[1])
    delete delta._id
    delete delta.uploader
    return formatters.html.format(delta, transpileds[0])
  }, [transpileds])

  return (
    <Dialog
      header="Diff"
      appendTo={document.body}
      visible={true}
      style={{ width: '80vw' }}
      modal={true}
      onHide={onHide}
    >
      <Root>
        {fullDiff && (
          <Box
            style={{ border: '1px solid gray', flex: '1 0 100%' }}
            dangerouslySetInnerHTML={{ __html: fullDiff }}
          />
        )}
      </Root>
    </Dialog>
  )
}

CompareDiffDialog.propTypes = {
  onHide: PropTypes.func.isRequired,
  docs: PropTypes.array.isRequired,
  useTranspile: PropTypes.func.isRequired,
}
