import PropTypes from 'prop-types'
import { partial } from 'lodash'

import { namespace, schema } from './config'
import { ShopRewardEditDialog } from './editor'
import { shopRewardSchema } from './schema'

import { getTicketDocPath } from '../Common'
import { useFetchDocAndTicket } from '../DocHelper'
import { referenceDoc } from '../Reference'
import { useRestoreDoc, RestoreDialog } from '../Restore'
import { EditorLoader } from '../SchemaBaseEditorLoader'
import { _queryRewardShop } from './loader.gql'

const getPath = partial(getTicketDocPath, namespace, schema)

export const queryManifest = {
  gql: _queryRewardShop,
  name: 'queryRewardShop',
  getPath,
}

export const generateReferenceDoc = partial(
  referenceDoc,
  'title',
  getPath,
  schema,
  namespace,
)

export function ShopRewardRestorer({ doc }) {
  return (
    <RestoreDialog
      DialogComp={ShopRewardEditDialog}
      useRestoreDoc={partial(useRestoreDoc, doc, namespace)}
    />
  )
}

ShopRewardRestorer.propTypes = {
  doc: PropTypes.object.isRequired,
}

export function ShopRewardEditorLoader({ docId }) {
  return (
    <EditorLoader
      DialogComp={ShopRewardEditDialog}
      useFetchDocAndTicket={partial(
        useFetchDocAndTicket,
        _queryRewardShop,
        'queryRewardShop',
        namespace,
        docId,
      )}
      currentSchema={shopRewardSchema}
    />
  )
}

ShopRewardEditorLoader.propTypes = {
  docId: PropTypes.string.isRequired,
}

export default ShopRewardEditorLoader
