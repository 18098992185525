import { useMemo, useState, useCallback, useContext, useRef } from 'react'
import PropTypes from 'prop-types'
import { useImmer } from 'use-immer'
import styled from 'styled-components'
import { useLocalStorage } from '@changing-cc/hooks'
import {
  get,
  isEmpty,
  find,
  map,
  isEqual,
  difference,
  flatten,
  values,
  isNil,
  join,
  filter,
  includes,
  reduce,
  isArray,
  every,
  keys,
} from 'lodash'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { InputTextarea } from 'primereact/inputtextarea'
import { InputText } from 'primereact/inputtext'
import { ListBox } from 'primereact/listbox'
import { OverlayPanel } from 'primereact/overlaypanel'
import { Dialog } from 'primereact/dialog'
import 'styled-components/macro'
import ReactMarkdown from 'react-markdown'
import remarkBreaks from 'remark-breaks'
import ReactTooltip from 'react-tooltip'
import { Link } from 'react-router-dom'
import { AiOutlineInfoCircle, AiFillSetting } from 'react-icons/ai'
import { Flex, Box, Text } from '@changingai/react-editor-common-component'

import { TicketPropType, useDialog } from '@common'
import { useBenefitTypes, createBlankDoc, purifySchemaDoc } from '@schema'
import { ScrollBar, ButtonLike, MarkDownEditor, Label } from '@widget'

import {
  OptionId,
  cssDataTable,
  TableCell,
  OptionInput,
  isUuid,
  updateOptionValue,
} from './common'
import { planSchema, benefitsRefSchema } from './schema'
import { PlanContext } from './context'
import { PaybackEditor, PaybackRenderer } from './paybackEditor'
import { BenefitsDialog } from './benefitDialog'
import {
  calculateRuleSchema,
  calculateFactorsSchema,
  calculateFactorSchema,
  CalculateRulePropType,
  CalculateFactorPropType,
  benefitConceptPaybackSchema,
  BenefitConceptPaybackPropType,
} from '../benefit/schema'

const StyledInputTextarea = styled(InputTextarea)`
  &&&& {
    width: 100%;
    background-color: AntiqueWhite;
    height: 170px;
  }
`

const isNilOrEmptyArray = value =>
  isNil(value) || (isArray(value) && isEmpty(value))

const StyledText = styled(Text)`
  width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

function Factors({ label, factors }) {
  return (
    <Box width="100%">
      <Flex m="2" alignItems="center">
        <Label width="200px">{label}</Label>
        <Box>
          {map(keys(calculateFactorSchema), factor =>
            isNilOrEmptyArray(factors?.[factor]) ? null : (
              <Flex alignItems="center" width="100%" m="1" key={factor}>
                <Label width="150px">
                  {calculateFactorSchema?.[factor].label}
                </Label>
                <StyledText mx="2">
                  {JSON.stringify(factors[factor])}
                </StyledText>
              </Flex>
            ),
          )}
        </Box>
      </Flex>
    </Box>
  )
}

Factors.propTypes = {
  label: PropTypes.string.isRequired,
  factors: CalculateFactorPropType.isRequired,
}

function FactorsDialog({ data, benefitName, onHide }) {
  const ruleIds = calculateRuleSchema.rule_id.useNodes()
  const content = useMemo(
    () => map(data, rule => purifySchemaDoc(rule, calculateRuleSchema)),
    [data],
  )

  return (
    <Dialog
      header={benefitName}
      appendTo={document.body}
      visible={true}
      style={{ width: '800px' }}
      onHide={onHide}
    >
      {map(content, (rule, idx) => (
        <Box key={idx}>
          <Box bg="blacks.2" width="100%" height="20px" textAlign="center">
            {find(ruleIds, { id: rule.rule_id })?.name}
          </Box>
          {map(keys(calculateFactorsSchema), factor =>
            every(rule[factor], isNilOrEmptyArray) ? null : (
              <Box key={factor}>
                <Box bg="blacks.2" width="100%" height="2px" />
                <Factors
                  label={calculateFactorsSchema[factor].label}
                  factors={rule[factor]}
                />
              </Box>
            ),
          )}
        </Box>
      ))}
    </Dialog>
  )
}

FactorsDialog.propTypes = {
  data: PropTypes.arrayOf(CalculateRulePropType).isRequired,
  benefitName: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
}

function BenefitConceptPaybackDialog({ data, benefitName, onHide }) {
  const bcMap = reduce(
    benefitConceptPaybackSchema.benefit_concept_id.useNodes(),
    (acc, { id, name }) => {
      acc[id] = name
      return acc
    },
    {},
  )

  const content = useMemo(
    () =>
      filter(
        map(data, bcp => purifySchemaDoc(bcp, benefitConceptPaybackSchema)),
        ({ ratio }) => !!ratio,
      ),
    [data],
  )

  return (
    <Dialog
      header={benefitName}
      appendTo={document.body}
      visible={true}
      style={{ width: '800px' }}
      onHide={onHide}
    >
      {!isEmpty(content) &&
        map(content, ({ benefit_concept_id, ...rest }, idx) => {
          const info = map(
            filter(
              [
                'ratio',
                'prefix',
                'suffix',
                'join_premium',
                'join_policy_value',
                'limited_payback',
                'depend_condition',
                'reduce_previous_payback',
                'limited_insured_scope',
                'has_special_bonus',
                'return_unused_premium',
                'has_similar_payback',
              ],
              field => !!get(rest, field),
            ),
            field =>
              [
                get(benefitConceptPaybackSchema, `${field}.label`),
                get(rest, field),
              ].join(': '),
          )
          return (
            <Box key={idx}>
              <Box
                bg="electricBlues.2"
                width="100%"
                height="20px"
                textAlign="center"
              >
                {get(bcMap, `${benefit_concept_id}`)}
              </Box>
              <Text style={{ whiteSpace: 'pre-line' }}>
                {`${info.join('\n➤ ')}`}
              </Text>
            </Box>
          )
        })}
    </Dialog>
  )
}

BenefitConceptPaybackDialog.propTypes = {
  data: PropTypes.arrayOf(BenefitConceptPaybackPropType).isRequired,
  benefitName: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
}

function BenefitMetaButton({ data, dialog, iconColor, benefitName }) {
  const [showDialog, renderDialog] = useDialog(dialog)

  return (
    <Box>
      <ButtonLike w="40px" h="40px">
        <AiOutlineInfoCircle
          style={{ color: iconColor }}
          onClick={() => showDialog()}
        />
      </ButtonLike>
      {renderDialog({ data, benefitName })}
    </Box>
  )
}

BenefitMetaButton.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.oneOf([BenefitConceptPaybackPropType, CalculateRulePropType]),
  ).isRequired,
  benefitName: PropTypes.string.isRequired,
  dialog: PropTypes.func.isRequired,
  iconColor: PropTypes.string,
}

export function Benefits({ context, onDirty, onChange, ticket, onValid }) {
  const benefitTypes = useBenefitTypes()
  const [showDialog, renderDialog] = useDialog(BenefitsDialog)
  const [initBenefits] = useState(get(context, 'benefits', []))
  const { benefitTickets, benefitsOfContract } = useContext(PlanContext)
  const benefitInfo = useMemo(() => flatten(values(benefitsOfContract)), [
    benefitsOfContract,
  ])

  const [editingRowsCount, setEditingRowsCount] = useState(0)
  const onSaveEdit = useCallback(
    (rowIndex, value) => {
      const benefits = get(context, 'benefits', [])
      const result = [
        ...benefits.slice(0, rowIndex),
        value,
        ...benefits.slice(rowIndex + 1),
      ]

      onDirty('benefits', !isEqual(result, initBenefits))
      onChange('benefits', result)
    },
    [context, initBenefits, onDirty, onChange],
  )

  const [editData, setEditData] = useImmer({})
  const amountRangeTooltip = useCallback(
    benefit_id => {
      const benefit = find(benefitInfo, { _id: benefit_id })
      return `
        <div>
          ${join(
            map(
              get(benefit, 'amount_range'),
              ({ according_to, min, max }) => `
              <div>
                <span>${according_to}</span>
                <span style="color:yellow;font-size:1.5em;">${min}</span>
                <span style="font-size:1.5em;white-space=pre;">  /  </span>
                <span style="color:wheat;font-size:1.5em;">${max}</span>
              </div>
            `,
            ),
            '',
          )}
        </div>
      `
    },
    [benefitInfo],
  )

  const onSelect = useCallback(
    selectedIds => {
      const benefits = get(context, 'benefits', [])
      const originalIds = map(benefits, 'benefit_id')
      const toAdd = map(difference(selectedIds, originalIds), benefit_id => ({
        ...createBlankDoc(benefitsRefSchema, false),
        benefit_id,
      }))
      const selectedBenefits = [
        ...benefits.filter(({ benefit_id }) =>
          selectedIds.includes(benefit_id),
        ),
        ...toAdd,
      ]

      onDirty('benefits', !isEqual(selectedBenefits, initBenefits))
      onChange('benefits', selectedBenefits)
    },
    [context, onDirty, onChange, initBenefits],
  )

  const [columnWidth, setColumnWidth] = useLocalStorage(
    'benefit.columnWidth',
    {},
  )

  const renderBenefitMeta = useCallback(
    benefit_id => {
      const info = find(benefitInfo, { _id: benefit_id })
      const targets = filter(
        [
          {
            data: get(info, 'calculate_rules'),
            iconColor: 'green',
            dialog: FactorsDialog,
          },
          {
            data: filter(
              get(info, 'benefit_concept_paybacks'),
              ({ ratio }) => !!ratio,
            ),
            iconColor: 'orange',
            dialog: BenefitConceptPaybackDialog,
          },
        ],
        ({ data }) => !isEmpty(data),
      )
      return (
        <Flex>
          {map(targets, ({ data, iconColor, dialog }) => (
            <BenefitMetaButton
              benefitName={info?.benefit_name}
              dialog={dialog}
              data={data}
              iconColor={iconColor}
            />
          ))}
        </Flex>
      )
    },
    [benefitInfo],
  )

  const columnOptions = useMemo(
    () => [
      {
        field: 'display_name',
        header: benefitsRefSchema.display_name.label,
        component: (
          <Column
            key="display_name"
            field="display_name"
            header={benefitsRefSchema.display_name.label}
            style={{ width: get(columnWidth, 'display_name', '150px') }}
            body={({ display_name }) => <TableCell>{display_name}</TableCell>}
            editor={({ rowIndex }) => (
              <StyledInputTextarea
                value={get(editData, `${rowIndex}.display_name`) || ''}
                onChange={({ target: { value } }) => {
                  setEditData(draft => {
                    draft[rowIndex].display_name = value
                  })
                }}
              />
            )}
          />
        ),
      },
      {
        field: 'payback_text',
        header: benefitsRefSchema.payback_text.label,
        component: (
          <Column
            key="payback_text"
            field="payback_text"
            header={benefitsRefSchema.payback_text.label}
            style={{ width: get(columnWidth, 'payback_text', 'auto') }}
            body={({ payback_text }) => (
              <ReactMarkdown source={payback_text} plugins={[remarkBreaks]} />
            )}
            editor={({ rowIndex }) => (
              <MarkDownEditor
                value={get(editData, `${rowIndex}.payback_text`) || ''}
                onChange={value => {
                  setEditData(draft => {
                    draft[rowIndex].payback_text = value
                  })
                }}
                previewPlugins={[remarkBreaks]}
              />
            )}
          />
        ),
      },
      {
        field: 'payback_sample',
        header: benefitsRefSchema.payback_sample.label,
        component: (
          <Column
            key="payback_sample"
            field="payback_sample"
            header={benefitsRefSchema.payback_sample.label}
            style={{ width: get(columnWidth, 'payback_sample', 'auto') }}
            body={({ payback_sample }) => (
              <PaybackRenderer
                payback_sample={payback_sample || ''}
                onValid={onValid}
                amountOptions={context.amount_options}
              />
            )}
            editor={({ rowIndex }) => (
              <Box>
                <PaybackEditor
                  amountOptions={context.amount_options}
                  value={get(editData, `${rowIndex}.payback_sample`) || ''}
                  onChange={value => {
                    setEditData(draft => {
                      draft[rowIndex].payback_sample = value
                    })
                  }}
                />
                <Box textAlign="right" width="100%">
                  {`Type @ to load ${planSchema.amount_options.label}`}
                </Box>
              </Box>
            )}
          />
        ),
      },
      {
        field: 'payback_note',
        header: benefitsRefSchema.payback_note.label,
        component: (
          <Column
            key="payback_note"
            field="payback_note"
            header={benefitsRefSchema.payback_note.label}
            style={{ width: get(columnWidth, 'payback_note', 'auto') }}
            body={({ payback_note }) => (
              <ReactMarkdown source={payback_note} plugins={[remarkBreaks]} />
            )}
            editor={({ rowIndex }) => (
              <MarkDownEditor
                value={get(editData, `${rowIndex}.payback_note`) || ''}
                onChange={value => {
                  setEditData(draft => {
                    draft[rowIndex].payback_note = value
                  })
                }}
                previewPlugins={[remarkBreaks]}
              />
            )}
          />
        ),
      },
      {
        field: 'option_id',
        header: benefitsRefSchema.option_id.label,
        component: (
          <Column
            key="option_id"
            field="option_id"
            header={benefitsRefSchema.option_id.label}
            style={{
              width: get(columnWidth, 'option_id', '300px'),
              overflow: 'visible',
            }}
            body={({
              benefit_id,
              option_id,
              constant,
              plan_ratio,
              value_type,
            }) => (
              <Flex>
                <OptionId
                  amount_options={context.amount_options}
                  option_id={option_id}
                  constant={constant}
                  plan_ratio={plan_ratio}
                  value_type={value_type}
                />
                {renderBenefitMeta(benefit_id)}
              </Flex>
            )}
            editor={({ rowIndex }) => {
              const benefit_id = get(editData, `${rowIndex}.benefit_id`)
              const amount_ratio = get(
                find(benefitInfo, { _id: benefit_id }),
                'amount_ratio',
                '',
              )
              return (
                <Flex flexWrap="wrap" justifyContent="flex-end">
                  <OptionInput
                    value_type={get(editData, `${rowIndex}.value_type`)}
                    option_id={get(editData, `${rowIndex}.option_id`)}
                    constant={get(editData, `${rowIndex}.constant`)}
                    plan_ratio={get(editData, `${rowIndex}.plan_ratio`)}
                    context={context}
                    onOptionChange={(value, type) => {
                      setEditData(draft => {
                        updateOptionValue(draft[rowIndex], type, value)
                      })
                    }}
                    onRatioChange={value => {
                      setEditData(draft => {
                        draft[rowIndex].plan_ratio = isEmpty(value) ? 1 : value
                      })
                    }}
                  />
                  <Flex>
                    {renderBenefitMeta(benefit_id)}
                    {!isNil(amount_ratio) && (
                      <Button
                        style={{ margin: '5px 0' }}
                        label="Copy"
                        onClick={() => {
                          setEditData(draft => {
                            if (isUuid(draft[rowIndex].option_id))
                              draft[rowIndex].plan_ratio = amount_ratio
                            else draft[rowIndex].option_id = null
                          })
                        }}
                      />
                    )}
                  </Flex>
                </Flex>
              )
            }}
          />
        ),
      },
      {
        field: 'extra_amount_ratio',
        header: '條款倍率(extra)',
        component: (
          <Column
            key="amount_ratio"
            field="amount_ratio"
            header="條款倍率"
            style={{ width: get(columnWidth, 'extra_amount_ratio', 'auto') }}
            body={({ benefit_id }) => {
              return (
                <Flex
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box width="100%" style={{ whiteSpace: 'pre' }}>
                    {get(
                      find(benefitInfo, { _id: benefit_id }),
                      'amount_ratio',
                      '',
                    )}
                  </Box>

                  {!isEmpty(
                    get(find(benefitInfo, { _id: benefit_id }), 'amount_range'),
                  ) && (
                    <>
                      <AiOutlineInfoCircle
                        style={{
                          color: 'green',
                          width: '40px',
                          height: '40px',
                        }}
                        data-tip={amountRangeTooltip(benefit_id)}
                      />
                      <ReactTooltip type="info" place="bottom" html={true} />
                    </>
                  )}
                </Flex>
              )
            }}
          />
        ),
      },
      {
        field: 'extra_benefit_type',
        header: '給付方式(extra)',
        component: (
          <Column
            key="benefit_type"
            field="benefit_type"
            header="給付方式"
            style={{ width: get(columnWidth, 'extra_benefit_type', 'auto') }}
            body={({ benefit_id }) => {
              const node_id = get(
                find(benefitInfo, { _id: benefit_id }),
                'benefit_type',
                '',
              )
              const name = get(find(benefitTypes, { node_id }), 'name')
              return <Box style={{ whiteSpace: 'pre' }}>{name}</Box>
            }}
          />
        ),
      },
      {
        field: 'request_unit',
        header: benefitsRefSchema.request_unit.label,
        component: (
          <Column
            key="request_unit"
            field="request_unit"
            header={benefitsRefSchema.request_unit.label}
            style={{ width: get(columnWidth, 'request_unit', 'auto') }}
            body={({ request_unit }) => <TableCell>{request_unit}</TableCell>}
            editor={({ rowIndex }) => {
              const benefit_id = get(editData, `${rowIndex}.benefit_id`)
              const request_unit = get(
                find(benefitInfo, { _id: benefit_id }),
                'request_unit',
                '',
              )
              return (
                <Flex flexWrap="wrap" justifyContent="flex-end">
                  <InputText
                    style={{
                      width: '100%',
                      backgroundColor: 'AntiqueWhite',
                    }}
                    value={get(editData, `${rowIndex}.request_unit`) || ''}
                    onChange={({ target: { value } }) => {
                      setEditData(draft => {
                        draft[rowIndex].request_unit = value
                      })
                    }}
                  />
                  {!isEmpty(request_unit) && (
                    <Button
                      style={{ margin: '5px 0' }}
                      label="Copy"
                      onClick={() => {
                        setEditData(draft => {
                          draft[rowIndex].request_unit = request_unit
                        })
                      }}
                    />
                  )}
                </Flex>
              )
            }}
          />
        ),
      },
      {
        field: 'extra_request_unit',
        header: '申請單位(extra)',
        component: (
          <Column
            key="request_unit"
            field="request_unit"
            header="申請單位"
            style={{ width: get(columnWidth, 'extra_request_unit', 'auto') }}
            body={({ benefit_id }) => {
              return (
                <Box style={{ whiteSpace: 'pre' }}>
                  {get(
                    find(benefitInfo, { _id: benefit_id }),
                    'request_unit',
                    '',
                  )}
                </Box>
              )
            }}
          />
        ),
      },
    ],
    [
      amountRangeTooltip,
      benefitInfo,
      benefitTypes,
      context,
      columnWidth,
      editData,
      setEditData,
      onValid,
      renderBenefitMeta,
    ],
  )
  const [selectedColumns, setSelectedColumns] = useLocalStorage(
    'benefit.columns',
    map(columnOptions, 'field'),
  )

  const op = useRef()
  return (
    <Flex width="100%" height="100%" flexDirection="column" css={cssDataTable}>
      <OverlayPanel ref={op} appendTo={document.body}>
        <Flex flexDirection="column">
          <Text textAlign="center" fontSize="h1" my="5px">
            顯示欄位
          </Text>
          <ListBox
            value={selectedColumns}
            options={columnOptions}
            optionLabel="header"
            optionValue="field"
            onChange={({ value }) => {
              setSelectedColumns(
                filter(map(columnOptions, 'field'), column =>
                  includes(value, column),
                ),
              )
            }}
            multiple={true}
          />
        </Flex>
      </OverlayPanel>
      {renderDialog({
        tickets: benefitTickets,
        benefitsOfContract,
        ticket,
        benefitIds: map(get(context, 'benefits', []), 'benefit_id'),
        onSelect,
      })}
      <Flex flex="0 0" width="100%" justifyContent="space-between" mb="2">
        <ButtonLike onClick={e => op.current.toggle(e)}>
          <AiFillSetting />
        </ButtonLike>
        <Button
          label="Select Benefits"
          onClick={() => showDialog()}
          icon={
            isNil(benefitsOfContract)
              ? 'pi pi-spin pi-spinner'
              : 'pi pi-refresh'
          }
          disabled={isNil(benefitsOfContract)}
        />
      </Flex>
      <Box flex="1 1" width="100%" overflow="auto" css={ScrollBar}>
        <DataTable
          value={get(context, 'benefits', [])}
          onRowReorder={({ value }) => {
            onChange('benefits', value)
            onDirty(`benefits`, !isEqual(value, initBenefits))
          }}
          className="p-datatable-gridlines"
          editMode="row"
          onRowEditInit={({ data, index }) => {
            setEditingRowsCount(rows => rows + 1)
            setEditData(draft => {
              draft[index] = data
            })
          }}
          onRowEditSave={({ index }) => {
            setEditingRowsCount(rows => rows - 1)
            onSaveEdit(index, editData[index])
          }}
          onRowEditCancel={({ index }) => {
            setEditingRowsCount(rows => rows - 1)
            setEditData(draft => {
              draft[index] = null
            })
          }}
          resizableColumns
          columnResizeMode="expand"
          onColumnResizeEnd={({
            column: { field },
            element: { offsetWidth },
          }) => {
            setColumnWidth(current => ({ ...current, [field]: offsetWidth }))
          }}
        >
          <Column rowReorder={!editingRowsCount} style={{ width: '3em' }} />
          <Column
            field="benefit_id"
            header="給付名稱"
            style={{ width: '150px' }}
            body={({ benefit_id }) => (
              <Link
                to={`/insurance/benefitSchema/${benefit_id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {find(benefitInfo, { _id: benefit_id })?.benefit_name}
              </Link>
            )}
          />
          {map(selectedColumns, field =>
            get(find(columnOptions, { field }), 'component'),
          )}
          <Column
            rowEditor
            header="編輯"
            headerStyle={{ width: '7rem' }}
            bodyStyle={{ textAlign: 'center' }}
          />
        </DataTable>
      </Box>
    </Flex>
  )
}

Benefits.propTypes = {
  context: PropTypes.object.isRequired,
  onDirty: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  ticket: TicketPropType.isRequired,
  onValid: PropTypes.func.isRequired,
}
