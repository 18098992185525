import { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import 'styled-components/macro'
import css from '@styled-system/css'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { TabView, TabPanel } from 'primereact/tabview'
import { InputSwitch } from 'primereact/inputswitch'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useCookies } from 'react-cookie'
import { Box, Flex } from '@changingai/react-editor-common-component'

import { genesis, ProjectContext } from '@common'
import { StatusBarRoot } from '@widget'

export function AboutDialog({ onHide }) {
  const [{ 'login.sid': sid }] = useCookies(['login.sid'])
  const {
    email,
    privilege,
    ticketVersion,
    setTicketVersion,
    enableRegExpMode,
    setEnableRegExpMode,
    enableMarkdown,
    setEnableMarkdown,
  } = useContext(ProjectContext)

  const ticketOptions = useMemo(() => {
    const current = parseInt(new Date().getFullYear())
    // You can select this year, the next year or the previous year
    return [current - 1, current, current + 1]
      .filter(year => year >= genesis)
      .map(version => ({
        label: version.toString(),
        value: version.toString(),
      }))
  }, [])

  return (
    <Dialog
      header={`About & Config`}
      appendTo={document.body}
      visible={true}
      style={{ width: '500px' }}
      modal={true}
      onHide={onHide}
    >
      <TabView style={{ minHeight: '400px' }}>
        <TabPanel header="About">
          <Flex height="100%" width="100%" fontSize="h3" flexDirection="column">
            <Box p="1">{`Welcome! ${email}`}</Box>
            <Box p="1">
              My privilege:
              {privilege.map(p => (
                <Box key={p} flex="1 1 100%" ml="3" color="blacks.5">
                  {p}
                </Box>
              ))}
            </Box>
            <Flex my={1} justifyContent="space-between">
              {`Token: ${sid ? sid.substring(0, 40) + '...' : 'no token'}`}
              <CopyToClipboard text={sid}>
                <Button className="p-button-secondary" icon="pi pi-copy" />
              </CopyToClipboard>
            </Flex>
          </Flex>
        </TabPanel>
        <TabPanel header="Config">
          <Flex height="100%" width="100%" fontSize="h3" flexDirection="column">
            <Flex width="100%" p="1" alignItems="center">
              <Box flex="1 1 100px">Ticket Version:</Box>
              <Dropdown
                value={ticketVersion}
                options={ticketOptions}
                onChange={({ value }) => {
                  setTicketVersion(value)
                }}
                style={{ flex: '1 1' }}
              />
            </Flex>
            <Flex width="100%" p="1" alignItems="center">
              <Box flex="1 1 100px">Show RegEx Search:</Box>
              <InputSwitch
                checked={enableRegExpMode}
                onChange={({ value }) => setEnableRegExpMode(value)}
              />
            </Flex>
            <Flex width="100%" p="1" alignItems="center">
              <Box flex="1 1 100px">Enable Markdown:</Box>
              <InputSwitch
                checked={enableMarkdown}
                onChange={({ value }) => setEnableMarkdown(value)}
              />
            </Flex>
          </Flex>
        </TabPanel>
      </TabView>
      <StatusBarRoot
        justifyContent="flex-end"
        css={css({
          '& .p-button': {
            mx: 1,
          },
        })}
      >
        <Button
          type="button"
          label="Reset"
          className="p-button-warning"
          onClick={() => {
            setTicketVersion(new Date().getFullYear().toString())
            setEnableRegExpMode(false)
            setEnableMarkdown(true)
          }}
        />
        <Button
          type="button"
          label="OK"
          className="p-button-primary"
          onClick={onHide}
        />
      </StatusBarRoot>
    </Dialog>
  )
}

AboutDialog.propTypes = {
  onHide: PropTypes.func,
}
