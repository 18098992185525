import { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { get, toUpper, isEmpty, head, isNil, noop } from 'lodash'
import produce from 'immer'

import { useDialog } from '@common'
import { useTickets } from './TicketHelper'

function genRestoreDocParam(doc, ticket) {
  return {
    doc: produce(doc, draft => {
      delete draft._id
      draft.local_doc = true
      delete draft.matched_state
      delete draft.matched_target
    }),
    ticket,
    crud: {
      ...get(ticket, 'crud'),
      delete: false,
    },
    updateMatched: false,
    initialDirty: true,
  }
}

export function useRestoreDoc(doc, namespace) {
  const ticketId = useMemo(() => get(doc, 'ticket_id', null), [doc])
  const [tickets] = useTickets({
    ticket_ids: [ticketId],
    namespace: toUpper(namespace),
    skip: !doc,
  })
  return useMemo(() => {
    if (isEmpty(tickets)) return null
    return genRestoreDocParam(doc, head(tickets))
  }, [doc, tickets])
}

export function RestoreDialog({ DialogComp, useRestoreDoc }) {
  const restoreParams = useRestoreDoc()

  const [show, render] = useDialog(DialogComp)

  useEffect(() => {
    if (!isNil(restoreParams)) show(restoreParams)
  }, [restoreParams, show])

  return (
    <>
      {render({
        onDelete: noop,
        onUpdate: noop,
      })}
    </>
  )
}

RestoreDialog.propTypes = {
  DialogComp: PropTypes.func.isRequired,
  useRestoreDoc: PropTypes.func.isRequired,
}
