import { useMemo, useCallback, useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  identity,
  compact,
  pick,
  isEmpty,
  keys,
  get,
  join,
  split,
  filter,
} from 'lodash'
import ReactTooltip from 'react-tooltip'
import { OverlayPanel } from 'primereact/overlaypanel'
import { InputTextarea } from 'primereact/inputtextarea'
import { Button } from 'primereact/button'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { IoMdCodeDownload } from 'react-icons/io'
import { gql, useQuery } from '@apollo/client'
import { Flex, Box } from '@changingai/react-editor-common-component'

import { PropCrud, isMongoId } from '@common'
import { toEditableDocs } from '@schema'
import { ButtonLike } from '@widget'
import { cceClient } from '@gql'

import { MenuIcon, useFieldSelectionMenuIcon } from './Shell'
import SchemaSummaryModel, { useValidFieldsBySchema } from './SummaryListView'
import { genQueryFields } from './TicketHelper'
import { SortByField } from './FieldSelectorPanel'

export function useFetchTecklessDocs(docType, currentSchema, fields = []) {
  const generateQueryTag = (queryName, currentSchema, fields) =>
    gql(`query _${queryName} {
      ${queryName} {
        ${genQueryFields(
          pick(currentSchema, isEmpty(fields) ? keys(currentSchema) : fields),
        )}
      }
    }`)

  const [queryName, queryTag] = useMemo(
    () => [
      `query${docType}`,
      generateQueryTag(`query${docType}`, currentSchema, [
        '_id',
        'uploader',
        ...fields,
      ]),
    ],
    [docType, currentSchema, fields],
  )

  const { data } = useQuery(queryTag, {
    client: cceClient,
  })

  return useMemo(
    () => toEditableDocs(get(data, queryName, null), currentSchema),
    [data, queryName, currentSchema],
  )
}

function IdInputMenuIcon({ ids, onUpdate }) {
  const inputRef = useRef(null)
  const [idContent, setIdContent] = useState(join(ids))
  return (
    <MenuIcon key="input-id" data-cy="IdInputMenuIcon">
      <ReactTooltip place="right" />
      <ButtonLike
        data-tip="輸入文件代碼"
        size={6}
        scaleOnHover
        onClick={e => {
          inputRef.current.toggle(e)
        }}
      >
        <IoMdCodeDownload />
      </ButtonLike>
      <OverlayPanel ref={inputRef} appendTo={document.body}>
        <Flex width="500px" flexWrap="wrap">
          <Box width="100%">請輸入文件代碼</Box>
          <InputTextarea
            data-cy="input-ids"
            autoResize
            style={{
              width: '100%',
              backgroundColor: 'AntiqueWhite',
              margin: '4px 0',
            }}
            value={idContent}
            onChange={({ target: { value } }) => {
              setIdContent(value)
            }}
          />
          <Flex width="100%" justifyContent="flex-end">
            <Button
              data-cy="submit-ids"
              type="button"
              label="OK"
              disabled={isEmpty(
                filter(compact(split(idContent, /[;|,|\s]/)), isMongoId),
              )}
              className="p-button-primary"
              onClick={() => {
                onUpdate(
                  filter(compact(split(idContent, /[;|,|\s]/)), isMongoId),
                )
                inputRef.current.hide()
              }}
            />
          </Flex>
        </Flex>
      </OverlayPanel>
    </MenuIcon>
  )
}

IdInputMenuIcon.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string),
  onUpdate: PropTypes.func.isRequired,
}

export function OwnedDocSummaryModel(props) {
  const {
    ids,
    useController,
    useTranspile = identity,
    HeaderLeftPanel = () => null,
    HeaderRightPanel = () => null,
    useValidFields = useValidFieldsBySchema,
    crud = {
      read: true,
      update: false,
      create: false,
      delete: false,
      overwrite: false,
    },
    currentSchema,
    defaultSummary,
    showIdInputIcon = false,
  } = props

  const [sortByField, setSortByField] = useState('')

  const {
    FieldSelectionMenuButton,
    selectedFields,
  } = useFieldSelectionMenuIcon({
    currentSchema,
    defaultSummary,
    headerComponents: (
      <SortByField onSortField={setSortByField} currentSchema={currentSchema} />
    ),
  })

  const [docIds, setDocIds] = useState([])
  useEffect(() => {
    setDocIds(ids)
  }, [setDocIds, ids])
  const controller = useController(docIds, selectedFields)
  const onCreate = useCallback(() => {
    controller.showDoc(controller.createDoc(), crud)
  }, [controller, crud])

  const extendedLeftMenuIcons = useMemo(
    () =>
      compact([
        controller.createDoc && [
          <MenuIcon key="create">
            <ReactTooltip place="right" />
            <ButtonLike
              data-cy="create-new-doc"
              data-tip="新增文件"
              disabled={!crud.create}
              size={6}
              scaleOnHover
              onClick={() => {
                onCreate()
              }}
            >
              <AiOutlinePlusCircle />
            </ButtonLike>
          </MenuIcon>,
          -1,
        ],
        [<FieldSelectionMenuButton key="show-field" />, -1],
        showIdInputIcon && [
          <IdInputMenuIcon key="id-input" ids={docIds} onUpdate={setDocIds} />,
          -1,
        ],
      ]),
    [
      docIds,
      controller.createDoc,
      crud.create,
      onCreate,
      setDocIds,
      showIdInputIcon,
    ],
  )

  return (
    <SchemaSummaryModel
      {...props}
      ids={docIds}
      controller={controller}
      useValidFields={useValidFields}
      crud={crud}
      useTranspile={useTranspile}
      HeaderLeftPanel={HeaderLeftPanel}
      HeaderRightPanel={HeaderRightPanel}
      extendedLeftMenuIcons={extendedLeftMenuIcons}
      selectedFields={selectedFields}
      sortByField={sortByField}
    />
  )
}

OwnedDocSummaryModel.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string),
  useController: PropTypes.func.isRequired,
  useTranspile: PropTypes.func.isRequired,
  useValidFields: PropTypes.func,
  HeaderLeftPanel: PropTypes.func,
  HeaderRightPanel: PropTypes.func,
  modelId: PropTypes.string.isRequired,
  crud: PropCrud,
  currentSchema: PropTypes.object.isRequired,
  defaultSummary: PropTypes.shape({
    selected: PropTypes.arrayOf(PropTypes.string).isRequired,
    filters: PropTypes.object.isRequired,
  }).isRequired,
  showIdInputIcon: PropTypes.bool,
}

export default OwnedDocSummaryModel
