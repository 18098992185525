import { useMemo, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import 'styled-components/macro'
import css from '@styled-system/css'
import {
  map,
  filter,
  isEmpty,
  size,
  get,
  find,
  isNil,
  slice,
  tail,
  isEqual,
  differenceBy,
  toNumber,
  toString,
  toUpper,
} from 'lodash'
import { Checkbox } from 'primereact/checkbox'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { FaFileExport, FaPlus } from 'react-icons/fa'
import { parse } from 'papaparse'
import { toast } from 'react-toastify'
import uuid from 'react-uuid'
import { useQuery } from '@apollo/client'
import { Flex, Box } from '@changingai/react-editor-common-component'

import { checkFeeTable } from './common'
import { SelectAxisDialog } from './dialog'
import {
  feeTableSchema,
  feeFactorSchema,
  FeeTablePropType,
  feeTabelCellSchema,
} from './schema'

import { SchemaBaseEditor } from '../SchemaBaseEditor'
import { useCheckApi } from '../Validation'
import {
  useGetConnectedNodes,
  PropCrud,
  csvBlob,
  exportDoc,
  KG_NODES,
  isLocalDoc,
  useDialog,
  namespaces,
} from '@common'
import { purifySchemaDoc, schemaToPropTypes } from '@schema'
import {
  inputFactory,
  TableInput,
  ButtonLike,
  ImportButton,
  useError,
  FileUploader,
  SingleSelect,
} from '@widget'
import { cceClient } from '@gql'
import theme from '@theme'
import { useFetchFullDoc } from '../DocHelper'

import {
  _queryFeeTable,
  _createFeeTable,
  _queryMatrices,
  _queryTickets,
  _queryProduct,
  _queryContract,
} from './editor.gql'

import { rules } from './rules'

function CreateTable({ context, onChange, requestVariables, onValid }) {
  const [showCreateDialog, renderCreateDialog] = useDialog(SelectAxisDialog)
  const [color, setColor] = useState()
  useEffect(() => {
    setColor(
      onValid('factors', context) ? theme.colors.icon : theme.colors.danger,
    )
  }, [context, onValid, setColor])

  if (!isEmpty(context.factors) || isEmpty(requestVariables)) return null
  return (
    <Flex
      width="100%"
      border="1px solid darkgray"
      p="2"
      m="2"
      justifyContent="center"
    >
      {renderCreateDialog({ context, onChange, requestVariables })}
      <ButtonLike>
        <FaPlus
          style={{
            color,
            width: `${theme.sizes[7]}px`,
            height: `${theme.sizes[7]}px`,
          }}
          onClick={() => showCreateDialog()}
        />
      </ButtonLike>
    </Flex>
  )
}

CreateTable.propTypes = {
  context: schemaToPropTypes(feeTableSchema).isRequired,
  onDirty: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onUpdateContext: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onValid: PropTypes.func.isRequired,
  requestVariables: PropTypes.arrayOf(PropTypes.object),
}

const queryMatrices = (_id, x_axis, y_axis) =>
  cceClient.query({
    query: _queryMatrices,
    variables: {
      doc_ids: [_id],
      axes: [
        {
          _id,
          x_axis,
          y_axis,
        },
      ],
    },
  })

const updateRemainFactors = (matrices, added, removed) =>
  map(matrices, matrix => ({
    ...matrix,
    remain_factors: [
      ...filter(matrix.remain_factors, ({ id }) => !find(removed, { id })),
      ...map(added, ({ id, values }) => ({ id, value: values[0] })),
    ],
  }))

function SelectAxes({
  context,
  onChange,
  onDirty,
  onValid,
  requestVariables,
  onFetching,
}) {
  const [id] = useState(uuid())
  const [mainAxes, setMainAxes] = useState(
    !isEmpty(context.matrices)
      ? [get(context, `matrices.0.x_axis`), get(context, `matrices.0.y_axis`)]
      : [],
  )
  const [initFactors] = useState(context.factors)
  const [showSelectAxisDialog, renderSelectAxisDialog] = useDialog(
    SelectAxisDialog,
  )
  useEffect(() => {
    onValid('factors', get(context, 'factors'), true)
  }, [onValid, context])

  const onChangeFactors = useCallback(
    (fieldname, factors) => {
      // When we change the factors after fetching matrices,
      // we need to aligned remain_factors with updated factors.
      const added = isEmpty(initFactors)
        ? differenceBy(factors, context.factors, 'id')
        : differenceBy(factors, initFactors, 'id')

      const removed = isEmpty(initFactors)
        ? differenceBy(context.factors, factors, 'id')
        : differenceBy(initFactors, factors, 'id')

      onChange(fieldname, factors)
      onChange(
        'matrices',
        updateRemainFactors(context.matrices, added, removed),
      )
      onDirty(id, true)
    },
    [context, onChange, initFactors, onDirty, id],
  )

  useEffect(() => {
    return function cleanupDirtyAndError() {
      onDirty(id, false)
      onValid('factors', null, { clean: true, key: id })
    }
  }, [onValid, onDirty, id])

  const fetch = useCallback(async () => {
    onFetching(true)
    if (isLocalDoc(context)) {
      const {
        data: { createFeeTable },
      } = await cceClient.query({
        query: _createFeeTable,
        variables: {
          factors: map(context.factors, factor =>
            purifySchemaDoc(factor, feeFactorSchema),
          ),
          x_axis: get(mainAxes, '0'),
          y_axis: get(mainAxes, '1'),
        },
      })
      onChange('matrices', createFeeTable)
    } else {
      const {
        data: { queryFeeTable },
      } = await queryMatrices(
        context._id,
        get(mainAxes, '0'),
        get(mainAxes, '1'),
      )
      const added = differenceBy(context.factors, initFactors, 'id')
      const removed = differenceBy(initFactors, context.factors, 'id')
      onChange(
        'matrices',
        updateRemainFactors(get(queryFeeTable, '0.matrices'), added, removed),
      )
    }
    onFetching(false)
  }, [onChange, mainAxes, context, onFetching, initFactors])

  if (isEmpty(context.factors) || isEmpty(requestVariables)) return null
  return (
    <Flex width="100%" p="2">
      {renderSelectAxisDialog({
        context,
        onChange: onChangeFactors,
        requestVariables,
        mainAxes,
      })}
      <Flex flex="1 1" justifyContent="space-around">
        {context.factors.map(factor => (
          <Flex key={factor.id} flex="1 1">
            <Checkbox
              inputId={factor.id}
              onChange={({ checked }) => {
                if (checked) {
                  if (size(mainAxes) < 2) setMainAxes([...mainAxes, factor.id])
                } else {
                  setMainAxes(mainAxes.filter(id => id !== factor.id))
                }
              }}
              checked={mainAxes.includes(factor.id)}
              disabled={
                (!mainAxes.includes(factor.id) && size(mainAxes) === 2) ||
                // Local added factors can't be fetched until uploaded.
                // This is a backend limitation, backend can't fetch a factor which doesn't exist in DB.
                (!isLocalDoc(context) &&
                  !!find(differenceBy(context.factors, initFactors, 'id'), {
                    id: factor.id,
                  }))
              }
              style={{ margin: '3px 5px' }}
            />
            <label htmlFor={factor.id} className="p-checkbox-label">
              {find(requestVariables, { node_id: factor.id }).name}
            </label>
          </Flex>
        ))}
      </Flex>
      <Button
        style={{ flex: '0 0 ' }}
        type="button"
        label="Fetch"
        disabled={size(mainAxes) !== 2}
        className="p-button-primary"
        onClick={() => {
          fetch()
        }}
      />
      <Button
        style={{ marginLeft: '5px' }}
        type="button"
        label="增/減條件"
        className="p-button-warning"
        onClick={() => {
          showSelectAxisDialog()
        }}
      />
    </Flex>
  )
}

SelectAxes.propTypes = {
  context: schemaToPropTypes(feeTableSchema).isRequired,
  onDirty: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onUpdateContext: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onValid: PropTypes.func.isRequired,
  onFetching: PropTypes.func.isRequired,
  requestVariables: PropTypes.arrayOf(PropTypes.object),
}

function FeeTableEditor({
  context,
  requestVariables,
  onDirty,
  onValid,
  onUpdateContext,
}) {
  const [id] = useState(uuid())
  const error = useError(
    onValid,
    id,
    'matrices',
    get(context, 'matrices'),
    true,
  )

  useEffect(() => {
    return function cleanupDirtyAndError() {
      onDirty(id, false)
      onValid('matrices', null, { clean: true, key: id })
    }
  }, [onDirty, onValid, id, context])

  const computeName = useCallback(
    matrices => {
      if (isEmpty(matrices.remain_factors))
        return `${get(
          find(requestVariables, { node_id: matrices.x_axis }),
          'name',
        )} - ${get(
          find(requestVariables, { node_id: matrices.y_axis }),
          'name',
        )}`
      const names = matrices.remain_factors.map(({ id, value }) => {
        const name = get(find(requestVariables, { node_id: id }), 'name')
        return `${name}(${value})`
      })

      return names.join(' /')
    },
    [requestVariables],
  )

  const [options, setOptions] = useState([])
  useEffect(() => {
    const axesIds = map(
      map(context.matrices, 'remain_factors'),
      remain_factors => map(remain_factors, 'id'),
    )
    if (
      !isEmpty(requestVariables) &&
      !isEmpty(context.matrices) &&
      !isEqual(axesIds, map(options, 'axesIds'))
    ) {
      setOptions(
        map(context.matrices, (current, index) => ({
          label: computeName(current),
          axesIds: map(current.remain_factors, 'id'),
          value: index,
        })),
      )
    }
  }, [setOptions, options, context.matrices, computeName, requestVariables])

  const [selected, setSelected] = useState(null)
  useEffect(() => {
    setSelected(get(options, '0.value'))
  }, [options])

  if (isEmpty(context.matrices) || isEmpty(requestVariables)) return null
  return (
    <Box width="100%" p="1" height="450px" overflow="auto">
      <TableInput
        schema={feeTabelCellSchema}
        format={value => {
          const formattedValue = value.replace(/[^0-9.]/g, '')
          return formattedValue === ''
            ? null
            : toString(toNumber(formattedValue))
        }}
        toolBar={
          <Flex
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            bg={error ? theme.colors.danger : 'auto'}
          >
            <Flex flex="0 0 100px">
              <ImportButton
                accept=".csv"
                onImport={({ target: { files } }) => {
                  const reader = new FileReader()
                  reader.onload = () => {
                    const { data, errors: parseErrors } = parse(reader.result, {
                      skipEmptyLines: true,
                      transform: v => v.trim(),
                    })

                    if (!isEmpty(parseErrors) || data.length < 2) {
                      toast.error('Empty or illegal file.', {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                      })
                      return
                    }

                    onUpdateContext(draft => {
                      // Take out headers.
                      draft.matrices[selected].rows = slice(data, 1).map(tail)
                    })
                    // TBD: more precisely dirty
                    onDirty(computeName(context.matrices[selected]), true)
                  }
                  reader.readAsText(files[0])
                }}
              />
              <ButtonLike
                data-tip="匯出文件"
                size={6}
                scaleOnHover
                onClick={() => {
                  const current = context.matrices[selected]
                  const columnHeader = find(context.factors, {
                    id: current.y_axis,
                  }).values
                  exportDoc(
                    `${computeName(current)}.csv`,
                    csvBlob([
                      [
                        `${get(
                          find(requestVariables, {
                            node_id: current.x_axis,
                          }),
                          'name',
                        )}/${get(
                          find(requestVariables, {
                            node_id: current.y_axis,
                          }),
                          'name',
                        )}`,
                        ...find(context.factors, {
                          id: current.x_axis,
                        }).values,
                      ],
                      ...map(current.rows, (row, index) => [
                        columnHeader[index],
                        ...row,
                      ]),
                    ]),
                  )
                }}
              >
                <FaFileExport
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              </ButtonLike>
            </Flex>
            <Flex
              flex="0 0 50%"
              css={css({
                '& .p-dropdown': {
                  width: '100%',
                },
              })}
            >
              <Dropdown
                value={selected}
                options={options}
                onChange={({ value }) => {
                  setSelected(value)
                }}
              />
            </Flex>
          </Flex>
        }
        fixedDimension
        values={get(context, `matrices.${selected}.rows`, [])}
        headerEditable={false}
        header={
          !isNil(selected)
            ? find(context.factors, {
                id: get(context, `matrices.${selected}.x_axis`),
              }).values
            : []
        }
        columnHeader={
          !isNil(selected)
            ? find(context.factors, {
                id: get(context, `matrices.${selected}.y_axis`),
              }).values
            : []
        }
        onChange={({ values }) => {
          onUpdateContext(draft => {
            draft.matrices[selected].rows = values
          })
          // TBD: more precisely dirty
          onDirty(computeName(context.matrices[selected]), true)
        }}
      />
    </Box>
  )
}

FeeTableEditor.propTypes = {
  context: schemaToPropTypes(feeTableSchema).isRequired,
  onDirty: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onUpdateContext: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onValid: PropTypes.func.isRequired,
  requestVariables: PropTypes.arrayOf(PropTypes.object),
}

// NOTE: feetable raw file comes from one of two sources, i.e contract or feetable itself
// it can pick up contracts only when the contracts have uploaded fee file already
export function FeeFile({ context, onDirty, onValid, onChange }) {
  const { data: tickets } = useQuery(_queryTickets, {
    variables: {
      filter: {
        ticket_ids: [context.ticket_id],
      },
      namespace: toUpper(namespaces.insurance.value),
    },
    client: cceClient,
    skip: isNil(get(context, 'ticket_id')),
  })

  const { data: products } = useQuery(_queryProduct, {
    variables: {
      doc_ids: [get(tickets, 'queryTickets.0.scope_id')],
    },
    client: cceClient,
    skip: isNil(get(tickets, 'queryTickets.0.scope_id')),
  })

  const { data: contracts } = useQuery(_queryContract, {
    variables: {
      doc_ids: get(products, 'queryProduct.0.contract_ids'),
    },
    client: cceClient,
    skip: isEmpty(get(products, 'queryProduct.0.contract_ids')),
  })

  const contractOptions = useMemo(
    () =>
      isNil(contracts)
        ? null
        : filter(
            map(contracts.queryContract, contract => ({
              id: contract._id,
              name: contract.contract_name,
              ...contract,
            })),
            ({ fee_url }) => !isNil(fee_url),
          ),
    [contracts],
  )

  return (
    <Flex flexDirection="column" width="100%">
      <Box data-testid="contract_id">
        {!isEmpty(contractOptions) && (
          <SingleSelect
            fieldname="contract_id"
            label={feeTableSchema.contract_id.label}
            value={get(context, 'contract_id', null)}
            nodes={contractOptions}
            placeholder="選擇費率表"
            valueTemplate={(option, { placeholder }) => (
              <>
                {option ? (
                  <a
                    href={option.value.fee_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {option.value.contract_name}
                  </a>
                ) : (
                  placeholder
                )}
              </>
            )}
            itemTemplate={({ value: { contract_name, approval_doc_no } }) => (
              <Flex justifyContent="flex-start">
                <Box ml="2" flex="1 1">
                  {contract_name}
                </Box>
                {approval_doc_no && (
                  <Box ml="2" flex="1 1" color="electricBlue">
                    {approval_doc_no}
                  </Box>
                )}
              </Flex>
            )}
            onDirty={onDirty}
            onValid={onValid}
            onChange={(fieldname, value) => {
              onChange(fieldname, value)
              onChange(
                'name',
                get(find(contractOptions, { id: value }), 'name'),
              )
              onChange('raw_files', [])
            }}
            labelAttributes={{ width: '200px' }}
            inputAttributes={{ width: '100%' }}
          />
        )}
      </Box>
      <Box data-testid="raw_files">
        <FileUploader
          fieldname="raw_files"
          value={context.raw_files}
          onChange={(fieldname, value) => {
            onChange(fieldname, value)
            onChange('contract_id', null)
          }}
          onValid={onValid}
          onDirty={onDirty}
          label={feeTableSchema.raw_files.label}
          mimetype="application/pdf"
          directInput={true}
          labelAttributes={{ width: '200px' }}
        />
      </Box>
    </Flex>
  )
}

FeeFile.propTypes = {
  context: schemaToPropTypes(feeTableSchema).isRequired,
  onDirty: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onValid: PropTypes.func.isRequired,
}

const roots = [
  {
    node_id: KG_NODES.insurance.REQUEST_VARIABLE_ROOT_ID,
    namespace: 'insurance',
  },
]

export function FeeTableEditDialog(props) {
  const { doc } = props
  const { editContext, setEditContext } = useFetchFullDoc(
    doc,
    feeTableSchema,
    _queryFeeTable,
  )

  const checker = useCheckApi({ rules, externalAPI: checkFeeTable })
  const requestVariables = useGetConnectedNodes(roots, true)
  const validRequestVariables = useMemo(
    () =>
      filter(
        requestVariables,
        node =>
          !isNil(get(find(node.attributes, { name: 'type' }), 'values.0')),
      ),
    [requestVariables],
  )

  const [fetching, setFetching] = useState(false)

  const CreateTableWrapper = useCallback(
    props => (
      <CreateTable {...props} requestVariables={validRequestVariables} />
    ),
    [validRequestVariables],
  )

  const SelectAxesWrapper = useCallback(
    props => (
      <SelectAxes
        {...props}
        requestVariables={validRequestVariables}
        onFetching={setFetching}
      />
    ),
    [validRequestVariables, setFetching],
  )

  const FeeTableEditorWrapper = useCallback(
    props => (
      <FeeTableEditor {...props} requestVariables={validRequestVariables} />
    ),
    [validRequestVariables],
  )
  const tabs = useMemo(
    () => [
      {
        caption: '費率表',
        configs: [
          inputFactory(feeTableSchema, 'name', {
            labelAttributes: {
              width: '100px',
            },
            inputAttributes: {
              width: '33%',
            },
          }),
          inputFactory(feeTableSchema, 'currency_id', {
            labelAttributes: {
              width: '100px',
            },
            inputAttributes: {
              width: '33%',
            },
          }),
          inputFactory(feeTableSchema, 'fee_base', {
            labelAttributes: {
              width: '120px',
            },
            inputAttributes: {
              width: '33%',
            },
          }),
          {
            fieldname: 'contract_id+raw_files',
            widget: 'CustomizedInput',
            RenderComp: FeeFile,
          },
          {
            fieldname: 'CreateTable',
            widget: 'CustomizedInput',
            RenderComp: CreateTableWrapper,
          },
          {
            fieldname: 'SelectAxes',
            widget: 'CustomizedInput',
            RenderComp: SelectAxesWrapper,
          },
          {
            fieldname: 'FeeTableEditor',
            widget: 'CustomizedInput',
            RenderComp: FeeTableEditorWrapper,
          },
        ],
      },
      {
        caption: '投資險',
        configs: [
          inputFactory(feeTableSchema, 'cost_pick_method', {
            inputAttributes: {
              width: '33%',
            },
          }),
          inputFactory(feeTableSchema, 'cost_note', {
            inputAttributes: {
              width: '100%',
              multilines: true,
              rows: 10,
            },
          }),
        ],
      },
    ],
    [CreateTableWrapper, FeeTableEditorWrapper, SelectAxesWrapper],
  )

  return (
    <SchemaBaseEditor
      {...props}
      currentSchema={feeTableSchema}
      tabs={tabs}
      checker={checker}
      isReady={!isEmpty(requestVariables) && !fetching && !isNil(editContext)}
      editContext={editContext}
      setEditContext={setEditContext}
    />
  )
}

FeeTableEditDialog.propTypes = {
  doc: FeeTablePropType.isRequired,
  crud: PropCrud.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
}
