import { useMemo, createContext } from 'react'
import { get, isNil } from 'lodash'
import { useQuery } from '@apollo/client'
import { cceClient } from '@gql'

import { _queryContract } from './context.gql'

export const BenefitContext = createContext({
  product: null,
})

export function useBenefitContext(ticket) {
  const { loading, data } = useQuery(_queryContract, {
    client: cceClient,
    variables: {
      doc_ids: [ticket.scope_id],
    },
  })

  const contract = useMemo(
    () => (loading || isNil(data) ? null : get(data, 'queryContract.0')),
    [loading, data],
  )

  return {
    isReady: !isNil(contract),
    contract,
  }
}
