import { namespace, schema, backendDocType, docPrivilege } from './config'
import { useTranspile } from './common'
import loader, {
  BenefitRestorer,
  generateReferenceDoc,
  queryManifest,
} from './loader'
import listview from './listview'
import compareview from './compare'
import { ticketProfile, useScopeName } from './ticket'
import { addViewManifest } from '../Config'

const manifest = {
  // Mandatory fields.
  loader,
  schema,
  namespace,
  backendDocType,
  privilege: docPrivilege,
  // [MYIP-1119]: TBD: remove these two hooks.
  queryManifest,
  useTranspile,
  // Reference and restore.
  generateReferenceDoc,
  restore: BenefitRestorer,
  // View relative
  listview,
  compareview,
  // Ticket relative
  ticketProfile,
  // Define this hook only if you want the ticket of this doc type be shown
  // on the ticket menu of the main menu bar.
  useScopeName,
  // The order of this type on main menu.
  order: 1,
}

addViewManifest(manifest)
