import { useCallback } from 'react'
import { useLocalStorage } from './Storage'

export function useLru(storageKey = 'LRU', max = 10) {
  const [storage, setStorage] = useLocalStorage(storageKey, [])

  const push = useCallback(
    cache => {
      if (!('_id' in cache)) throw new Error('cache must contain _id as key.')

      const result = [cache, ...storage.filter(({ _id }) => _id !== cache._id)]
      setStorage(result.slice(0, max))
    },
    [storage, setStorage, max],
  )

  return [storage, push]
}
