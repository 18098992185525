import { useMemo } from 'react'
import isNil from 'lodash/isNil'

import { inputFactory } from '@widget'
import { SchemaBaseEditor } from '../SchemaBaseEditor'
import { useFetchFullDoc } from '../DocHelper'

import { muraSchema, MuraPropType } from './schema'
import { _queryMura } from './editor.gql'

export function MuraEditor(props) {
  const { editContext, setEditContext } = useFetchFullDoc(
    props.doc,
    muraSchema,
    _queryMura,
  )
  const tabs = useMemo(
    () => [
      {
        caption: 'Primary',
        configs: [inputFactory(muraSchema, 'name')],
      },
    ],
    [],
  )

  return (
    <SchemaBaseEditor
      {...props}
      currentSchema={muraSchema}
      tabs={tabs}
      crud={{
        create: true,
        update: true,
        delete: true,
        read: true,
        overwrite: true,
      }}
      isReady={!isNil(editContext)}
      editContext={editContext}
      setEditContext={setEditContext}
    />
  )
}

MuraEditor.propTypes = {
  doc: MuraPropType.isRequired,
}
