import { useMemo, useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  get,
  set,
  map,
  some,
  isEmpty,
  find,
  head,
  isNaN,
  isNil,
  sortBy,
  isEqual,
  noop,
  stubTrue,
  toNumber,
} from 'lodash'
import produce from 'immer'
import uuid from 'react-uuid'
import { Flex } from '@changingai/react-editor-common-component'

import {
  SingleInput,
  inputFactory,
  useRenderConfig,
  generateSubDocProps,
} from '@widget'
import theme from '@theme'
import { KG_NODES } from '@common'
import { schemaToPropTypes } from '@schema'

import {
  requestVariableSchema,
  requestVariablesSchema,
  useReqestVariableNode,
} from './schema'

const useRequestVariable = requestVariableSchema.request_type.useNodes

function RequestVariableUnit({ context: { request_type } }) {
  const values = useReqestVariableNode(request_type, 'unit', false)
  const unit = useMemo(
    () => (!request_type || isEmpty(values) ? '' : head(values)),
    [values, request_type],
  )

  return (
    <Flex flex="1 1 30%" alignItems="center" justifyContent="flex-end">
      {`單位：${isEmpty(unit) ? '(無)' : unit}`}
    </Flex>
  )
}

RequestVariableUnit.propTypes = {
  context: schemaToPropTypes(requestVariableSchema).isRequired,
}

const isEmptyValue = value => isNil(value) || value === ''

export function checkAgeVariable(variable) {
  const { min_age, max_age, max_renewable_age } = variable
  if (isEmptyValue(min_age) || isEmptyValue(max_age))
    return 'min/max age should not be empty.'
  const order = map(
    [
      min_age,
      max_age,
      isEmptyValue(max_renewable_age) ? 999 : max_renewable_age,
    ],
    toNumber,
  )
  if (some(order, isNaN)) return 'Illegal age input'
  if (some(order, v => v < 0)) return 'Age should be greater than or equal to 0'
  if (!isEqual(order, sortBy(order))) return 'Input value order is not correct'
}

const ageSchema = {
  min_age: {
    type: 'integer',
  },
  max_age: {
    type: 'integer',
  },
  max_renewable_age: {
    type: 'integer',
  },
}

function AgeVariableInput({ context, onChange, onDirty, onError }) {
  const [id] = useState(uuid())
  const fieldname = 'list'
  const [initVariables] = useState({
    min_age: get(context, `${fieldname}.0`, ''),
    max_age: get(context, `${fieldname}.1`, ''),
    max_renewable_age: get(context, `${fieldname}.2`, ''),
  })
  const [editValue, setEditValue] = useState(initVariables)

  const onAgeChange = useCallback(
    (changedField, value) => {
      const result = produce(editValue, draft => {
        set(draft, changedField, value)
      })
      setEditValue(result)
      onDirty(id, !isEqual(result, initVariables))
      onChange(fieldname, [
        get(result, 'min_age'),
        get(result, 'max_age'),
        get(result, 'max_renewable_age'),
      ])
    },
    [id, onDirty, onChange, editValue, setEditValue, initVariables],
  )

  useEffect(() => {
    const error = checkAgeVariable(editValue)
    onError(id, !isNil(error), error)
  }, [id, onError, editValue])

  useEffect(() => {
    return function cleanupDirtyAndError() {
      onDirty(id, false)
      onError(id, false)
    }
  }, [onDirty, onError, id])

  return (
    <Flex width="100%" flexDirection="column">
      <SingleInput
        fieldname="min_age"
        label="最低投保年齡"
        value={get(editValue, 'min_age')}
        onChange={onAgeChange}
        onDirty={noop}
        onValid={stubTrue}
        inputAttributes={{ width: '100%' }}
        enableHint={false}
        currentSchema={ageSchema}
        computeRenderResult={noop}
      />
      <SingleInput
        fieldname="max_age"
        label="最高投保年齡"
        value={get(editValue, 'max_age')}
        onChange={onAgeChange}
        onDirty={noop}
        onValid={stubTrue}
        inputAttributes={{ width: '100%' }}
        enableHint={false}
        currentSchema={ageSchema}
        computeRenderResult={noop}
      />
      <SingleInput
        fieldname="max_renewable_age"
        label="最高續保年齡"
        value={get(editValue, 'max_renewable_age')}
        onChange={onAgeChange}
        onDirty={noop}
        onValid={stubTrue}
        inputAttributes={{ width: '100%' }}
        disabled={
          isEmpty(get(editValue, 'min_age')) ||
          isEmpty(get(editValue, 'max_age'))
        }
        enableHint={false}
        currentSchema={ageSchema}
        computeRenderResult={noop}
      />
    </Flex>
  )
}

AgeVariableInput.propTypes = {
  context: schemaToPropTypes(requestVariableSchema).isRequired,
  onChange: PropTypes.func.isRequired,
  onDirty: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
}

function RequestVariable({
  context,
  onDirty,
  onUpdateContext,
  onError,
  currentPath,
}) {
  const renderer = useRenderConfig({
    onError,
    onUpdateContext,
    onDirty,
    editContext: context,
    currentSchema: requestVariableSchema,
    currentPath,
  })

  const variables = useRequestVariable()
  const currentType = useMemo(() => {
    if (!variables || !context.request_type) return null

    const attributes = get(
      find(variables, { node_id: context.request_type }),
      'attributes',
    )

    return get(find(attributes, { name: 'type' }), 'values.0')
  }, [variables, context])

  const RequestVariableUnitWrapper = useCallback(
    () => <RequestVariableUnit context={context} />,
    [context],
  )

  const configs = useMemo(() => {
    if (isNil(currentType)) return []
    const inputs = [
      inputFactory(requestVariableSchema, 'request_type', {
        labelAttributes: {
          width: '80px',
          p: 0,
          m: 0,
        },
        width: '70%',
      }),
      {
        fieldname: 'requestVariableSchema.unit',
        widget: 'CustomizedInput',
        RenderComp: RequestVariableUnitWrapper,
      },
    ]

    if (context.request_type === KG_NODES.insurance.REQUEST_VARIABLE.AGE)
      return [
        ...inputs,
        {
          fieldname: 'list',
          widget: 'CustomizedInput',
          RenderComp: AgeVariableInput,
        },
      ]

    if (currentType === 'range')
      return [
        ...inputs,
        inputFactory(requestVariableSchema, 'range', {
          labelAttributes: {
            width: '100%',
            p: 0,
            m: 0,
          },
          labelPosition: 'top',
          allowDuplicate: true,
        }),
      ]

    if (currentType === 'list')
      return [
        ...inputs,
        inputFactory(requestVariableSchema, 'list', {
          labelAttributes: {
            width: '100%',
            p: 0,
            m: 0,
          },
          labelPosition: 'top',
          inputAttributes: {
            width: '100%',
            height: '150px',
          },
        }),
      ]

    throw new Error(`Unexpected type: ${currentType}`)
  }, [context.request_type, currentType, RequestVariableUnitWrapper])

  return (
    <Flex width="100%" flexWrap="wrap">
      {configs.map(config => renderer(config))}
    </Flex>
  )
}

RequestVariable.propTypes = {
  context: PropTypes.object.isRequired,
  onDirty: PropTypes.func.isRequired,
  onUpdateContext: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  currentPath: PropTypes.array.isRequired,
}

export function RequestVariables({
  context,
  onDirty,
  onUpdateContext,
  onError,
  currentPath,
}) {
  const renderer = useRenderConfig({
    onError,
    onUpdateContext,
    onDirty,
    editContext: context,
    currentSchema: requestVariablesSchema,
    currentPath,
  })

  const configs = useMemo(
    () => [
      {
        ...generateSubDocProps(requestVariablesSchema, 'request_variable'),
        itemConfigs: [
          {
            widget: 'CustomizedInput',
            RenderComp: RequestVariable,
          },
        ],
        listview: true,
        showCaption: false,
        itemStyle: {
          flex: '0 0 32%',
          backgroundColor: theme.colors.darkPanelBG,
        },
      },
    ],
    [],
  )

  return (
    <Flex width="100%" flexWrap="wrap">
      {configs.map(config => renderer(config))}
    </Flex>
  )
}

RequestVariables.propTypes = {
  context: PropTypes.object.isRequired,
  onDirty: PropTypes.func.isRequired,
  onUpdateContext: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  currentPath: PropTypes.array.isRequired,
}
