import Joi from 'joi'
import { isEqual } from 'lodash'

export const SchemaEmptyPositive = Joi.alternatives().try(
  Joi.string().valid(''),
  Joi.number().positive(),
)

export const SchemaEmptyNonNegative = Joi.alternatives().try(
  Joi.string().valid(''),
  Joi.number().min(0),
)

export const shortString = 500
export const longString = 3000
export const extremeLongString = 5000
export const urlString = 2048

export const emailString = 254

export const NullableString = Joi.string().allow(null, '')
export const RequiredString = Joi.string().required()

const mongoIdString = Joi.string().pattern(new RegExp('^[a-f0-9]{24}$'))
export const NullableMongoIdString = mongoIdString.allow(null, '')
export const RequiredMongoIdString = mongoIdString.required()

const kgNodeIdString = Joi.string().pattern(
  new RegExp('^(channel(_concept){0,1}|target)/[a-f0-9]{24}$'),
)
export const NullableKGNodeIdString = kgNodeIdString.allow(null, '')
export const RequiredKGNodeIdString = kgNodeIdString.required()

const UniqueArray = Joi.array().unique()
export const UniqueStringArray = UniqueArray.items(Joi.string())
export const UniqueNodeArray = UniqueArray.items(
  Joi.alternatives().try(RequiredKGNodeIdString, RequiredMongoIdString),
)
// use a roughly RE to cover various query string
export const URL_RE = /^(http(s)?:\/\/){1}[\w.-]+([/\w.-])*(\?.*)*/
export const UniqueUrlArray = UniqueArray.items(Joi.string().regex(URL_RE))

const yearsToMilliseconds = years => 1000 * 60 * 60 * 24 * 365 * years
export const InYears = years =>
  Joi.date()
    .max(new Date(Date.now() + yearsToMilliseconds(years)))
    .min(new Date(Date.now() - yearsToMilliseconds(years)))

export const DateInTwentyYears = InYears(20)
export const DateInFiftyYears = InYears(50)

const ascCompareFunction = (a, b) => a - b
const dscCompareFunction = (a, b) => b - a

export function orderedArray(order = 'ascending') {
  if (order !== 'ascending' && order !== 'descending')
    throw new Error(`${order} is not a valid order.`)
  return (values, helpers) => {
    if (!Array.isArray(values)) return values
    const sorted = [...values].sort(
      order === 'ascending' ? ascCompareFunction : dscCompareFunction,
    )
    if (!isEqual(sorted, values))
      return helpers.message(`Values is not in ${order} orders.`)
    return values
  }
}

export const jsonString = (values, helpers) => {
  try {
    JSON.parse(values)
  } catch (err) {
    return helpers.message(`Invalid json string`)
  }
  return values
}
