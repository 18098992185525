import { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import 'styled-components/macro'
import css from '@styled-system/css'
import { Button } from 'primereact/button'
import { PickList } from 'primereact/picklist'
import {
  compact,
  get,
  includes,
  map,
  noop,
  findKey,
  filter,
  keys,
} from 'lodash'
import { Flex } from '@changingai/react-editor-common-component'
import { useLocalStorage } from '@changing-cc/hooks'

import { useDialog } from '@common'
import { SchemaStore } from '@schema'

import { ConfirmDialog } from './ConfirmDialog'
import { StatusBarRoot } from './StatusBarRoot'

const getRenderableFields = (fields, currentSchema) =>
  filter(fields, field => !get(currentSchema, `${field}.skipRender`))

export function useFieldSelectionPanel({
  currentSchema,
  layoutSchema,
  defaultSummary,
  onCancel,
  onOk,
  headerComponents,
  sourceHeader,
  pickedHeader,
  storgeKeyPostfix,
}) {
  const [selectableFields, defaultSelectedFields] = useMemo(
    () =>
      map(
        [
          getRenderableFields(keys(layoutSchema), layoutSchema),
          getRenderableFields(defaultSummary.selected, layoutSchema),
        ],
        fieldList =>
          map(fieldList, value => ({
            value,
            label: layoutSchema[value].label,
          })),
      ),
    [layoutSchema, defaultSummary.selected],
  )

  const [selectedFieldsStorage, setSelectedFieldsStorage] = useLocalStorage(
    compact([
      'FieldSelector',
      findKey(SchemaStore, currentSchema),
      storgeKeyPostfix,
    ]).join('.'),
    defaultSelectedFields,
  )

  const selectedFields = useMemo(
    () =>
      filter(
        selectedFieldsStorage,
        ({ value }) => !get(layoutSchema, `${value}.skipRender`),
      ),
    [layoutSchema, selectedFieldsStorage],
  )

  const renderPickListPanel = useCallback(
    () => (
      <FieldSelectionPanel
        sourceFields={selectableFields}
        selectedFields={selectedFields}
        defaultSelectedFields={defaultSelectedFields}
        onReset={() => {
          setSelectedFieldsStorage(defaultSelectedFields)
        }}
        onCancel={onCancel}
        onOk={selectedFields => {
          setSelectedFieldsStorage(selectedFields)
          onOk(selectedFields)
        }}
        headerComponents={headerComponents}
        sourceHeader={sourceHeader}
        pickedHeader={pickedHeader}
      />
    ),
    [
      selectableFields,
      defaultSelectedFields,
      onCancel,
      onOk,
      headerComponents,
      sourceHeader,
      pickedHeader,
      selectedFields,
      setSelectedFieldsStorage,
    ],
  )

  const result = useMemo(
    () => [map(selectedFields, 'value'), renderPickListPanel],
    [selectedFields, renderPickListPanel],
  )
  return result
}

function FieldSelectionPanel({
  sourceFields,
  selectedFields,
  defaultSelectedFields,
  onReset,
  onCancel,
  onOk,
  headerComponents,
}) {
  const [selected, setSelected] = useState(selectedFields)

  const source = useMemo(
    () =>
      sourceFields.filter(({ value }) => {
        return !includes(map(selected, 'value'), value)
      }),
    [sourceFields, selected],
  )

  const reset = useCallback(() => {
    setSelected(defaultSelectedFields)
  }, [setSelected, defaultSelectedFields])

  const [showConfirm, renderConfirm] = useDialog(ConfirmDialog)

  return (
    <Flex
      flexDirection="column"
      css={css({
        '&& .p-picklist .p-picklist-header': {
          textAlign: 'center',
          bg: 'blacks.2',
        },
      })}
    >
      <Flex m="10px">{headerComponents}</Flex>
      {renderConfirm({
        onYes: () => {
          reset()
          onReset()
        },
      })}
      <PickList
        sourceHeader={'可選欄位'}
        targetHeader={'顯示欄位'}
        source={source}
        target={selected}
        onChange={({ target }) => {
          setSelected(target)
        }}
        itemTemplate={({ label }) => <>{label}</>}
        sourceStyle={{ height: '400px', width: '300px' }}
        targetStyle={{ height: '400px', width: '300px' }}
      />
      <StatusBarRoot justifyContent="flex-end">
        <Button
          style={{ margin: '0 5px' }}
          className="p-button-warning"
          label="Reset"
          onClick={() =>
            showConfirm({
              title: 'Warning',
              message: '重置設定?',
            })
          }
        />
        <Button
          style={{ margin: '0 5px' }}
          className="p-button-primary"
          label="OK"
          onClick={() => {
            onOk(selected)
          }}
        />
        <Button
          style={{ margin: '0 5px' }}
          className="p-button-secondary"
          label="Cancel"
          onClick={() => {
            setSelected(selected)
            onCancel()
          }}
        />
      </StatusBarRoot>
    </Flex>
  )
}

FieldSelectionPanel.propTypes = {
  sourceFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultSelectedFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  onReset: PropTypes.func,
  onCancel: PropTypes.func,
  onOk: PropTypes.func.isRequired,
  headerComponents: PropTypes.node,
}

FieldSelectionPanel.defaultProps = {
  onCancel: noop,
  onReset: noop,
}
