import { useCallback, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { map, partial, get, filter, find, isNil } from 'lodash'

import { Flex } from '@changingai/react-editor-common-component'

import { KG_NODES, CardGroupScope, TicketPropType } from '@common'
import {
  inputFactory,
  MultiCheckboxList,
  MultipleNodeList,
  generateSubDocProps,
  useRenderConfig,
} from '@widget'

import {
  hasPredefinedRewardTarget,
  useEditable,
  useRewardTarget,
  useFilterDocField,
} from './RewardUtils'
import { CardRewardContext } from './context'
import { rules } from './rules'
import { cardRewardSchema, cardSetSchema } from './schema'

import { useCheckApi } from '../Validation'
import { SchemaBaseEditor } from '../SchemaBaseEditor'
import { useFetchFullDoc } from '../DocHelper'

import { _queryRewardCard } from './editor.gql'

export function CardSet({
  context,
  onDirty,
  onUpdateContext,
  onError,
  cardGroup,
  currentPath,
}) {
  const { cardGroups, isReady } = useContext(CardRewardContext)

  const cardGroupNodes = useMemo(
    () =>
      !isReady
        ? null
        : cardGroups
            .filter(
              ({ card_provider_id }) =>
                card_provider_id === get(cardGroup, 'card_provider_id'),
            )
            .map(group => ({
              ...group,
              id: group._id,
              name: group.card_provider_name + ' - ' + group.card_name,
            })),
    [cardGroups, cardGroup, isReady],
  )

  const combinations = useMemo(() => {
    const selectedCardGroup = find(cardGroupNodes, {
      _id: get(context, 'card_group_id'),
    })
    return get(selectedCardGroup, 'available_combinations')
  }, [context, cardGroupNodes])

  const issuersFilter = useMemo(() => {
    if (!combinations) return null
    return [...map(combinations, 'issuer_id'), KG_NODES.entity.ALL_ISSUER_ID]
  }, [combinations])

  const levelsFilter = useMemo(() => {
    if (!combinations) return null

    const issuer = get(context, 'issuer_id')
    if (!issuer || issuer === KG_NODES.entity.ALL_ISSUER_ID)
      return [
        ...map(combinations, 'card_level_id'),
        KG_NODES.entity.ALL_CARD_LEVEL_ID,
      ]

    return [
      ...map(filter(combinations, { issuer_id: issuer }), 'card_level_id'),
      KG_NODES.entity.ALL_CARD_LEVEL_ID,
    ]
  }, [context, combinations])

  const renderer = useRenderConfig({
    onError,
    onUpdateContext,
    onDirty,
    editContext: context,
    currentSchema: cardSetSchema,
    currentPath,
  })

  const configs = useMemo(
    () => [
      inputFactory(cardSetSchema, 'card_group_id', {
        inputAttributes: { width: '50%' },
        disabled: [CardGroupScope.ISSUER, CardGroupScope.CARD].includes(
          get(cardGroup, 'scope'),
        ),
        nodes: cardGroupNodes,
      }),
      inputFactory(cardSetSchema, 'issuer_id', {
        inputAttributes: { width: '50%' },
        filterNodes: issuersFilter,
        disabled: get(cardGroup, 'scope') === CardGroupScope.ISSUER,
      }),
      inputFactory(cardSetSchema, 'card_level_id', {
        inputAttributes: { width: '50%' },
        filterNodes: levelsFilter,
      }),
    ],
    [issuersFilter, levelsFilter, cardGroup, cardGroupNodes],
  )

  return (
    <Flex width="100%" flexWrap="wrap">
      {configs.map(config => renderer(config))}
    </Flex>
  )
}

CardSet.propTypes = {
  context: PropTypes.object.isRequired,
  onDirty: PropTypes.func.isRequired,
  onUpdateContext: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  cardGroup: PropTypes.object.isRequired,
  currentPath: PropTypes.array.isRequired,
}

function CardRewardTarget(props) {
  const { context } = props
  if (hasPredefinedRewardTarget(get(context, 'type_id', '')))
    return (
      <MultiCheckboxList
        {...props}
        label={cardRewardSchema.re_target_ids.label}
        fieldname="re_target_ids"
        value={context['re_target_ids']}
        useQueryHook={partial(useRewardTarget, context.type_id)}
        filter={true}
        labelPosition="top"
      />
    )
  return (
    // Flex for line break
    <Flex width="100%">
      <MultipleNodeList
        {...props}
        fieldname="re_target_ids"
        label={cardRewardSchema.re_target_ids.label}
        inputAttributes={{ width: '25%' }}
        node_ids={context['re_target_ids']}
        node_types={['target']}
        labelPosition="top"
      />
    </Flex>
  )
}

CardRewardTarget.propTypes = {
  context: PropTypes.object.isRequired,
}

export function CardRewardEditDialog(props) {
  const { editContext, setEditContext } = useFetchFullDoc(
    props.doc,
    cardRewardSchema,
    _queryRewardCard,
  )
  const context = useContext(CardRewardContext)
  const cardGroup = useMemo(
    () =>
      find(get(context, 'cardGroups'), { id: get(props.ticket, 'scope_id') }),
    [context, props.ticket],
  )
  const isEditable = useEditable()

  const checker = useCheckApi({
    rules,
    context,
    useDocFilter: useFilterDocField,
  })

  const CardSetWrapper = useCallback(
    props => <CardSet {...props} cardGroup={cardGroup} />,
    [cardGroup],
  )

  const tabs = useMemo(
    () => [
      {
        caption: 'Basic',
        configs: [
          {
            ...generateSubDocProps(cardRewardSchema, 'include_card_sets'),
            min: 1,
            defaultValue: [
              {
                card_group_id: get(cardGroup, 'id'),
                issuer_id:
                  get(cardGroup, 'scope') === CardGroupScope.ISSUER
                    ? get(cardGroup, 'card_provider_id')
                    : null,
              },
            ],
            itemConfigs: [
              {
                widget: 'CustomizedInput',
                RenderComp: CardSetWrapper,
              },
            ],
          },
          {
            ...generateSubDocProps(cardRewardSchema, 'exclude_card_sets'),
            defaultValue: [
              {
                card_group_id: get(cardGroup, 'id'),
                issuer_id:
                  get(cardGroup, 'scope') === CardGroupScope.ISSUER
                    ? get(cardGroup, 'card_provider_id')
                    : null,
              },
            ],
            itemConfigs: [
              {
                widget: 'CustomizedInput',
                RenderComp: CardSetWrapper,
              },
            ],
          },
          inputFactory(cardRewardSchema, 'type_id'),
          inputFactory(cardRewardSchema, 'effective_date_b', {
            inputAttributes: { width: '33%' },
          }),
          inputFactory(cardRewardSchema, 'effective_date_e', {
            inputAttributes: { width: '33%' },
          }),
          inputFactory(cardRewardSchema, 'launch_date', {
            inputAttributes: { width: '33%' },
          }),
          inputFactory(cardRewardSchema, 'csp_channel_ids', {
            inputAttributes: { width: '25%' },
            widget: 'MultipleNodeList',
            labelPosition: 'top',
          }),
          inputFactory(cardRewardSchema, 're_channel_ids', {
            inputAttributes: { width: '25%' },
            widget: 'MultipleNodeList',
            labelPosition: 'top',
          }),
          inputFactory(cardRewardSchema, 'csp_target_ids', {
            inputAttributes: { width: '25%' },
            widget: 'MultipleNodeList',
            node_types: ['target'],
            labelPosition: 'top',
          }),
          inputFactory(cardRewardSchema, 'exclude_list_ids', {
            inputAttributes: { width: '25%' },
            widget: 'MultipleNodeList',
            node_types: ['channel', 'channel_concept', 'target'],
            labelPosition: 'top',
          }),
          {
            fieldname: 're_target_ids',
            widget: 'CustomizedInput',
            RenderComp: CardRewardTarget,
          },
          inputFactory(cardRewardSchema, 'by_method_ids', {
            filter: true,
            inputAttributes: { width: '33%' },
            labelPosition: 'top',
          }),
          inputFactory(cardRewardSchema, 'identity_ids', {
            filter: true,
            inputAttributes: { width: '33%' },
            labelPosition: 'top',
          }),
          inputFactory(cardRewardSchema, 'need_registration'),
          inputFactory(cardRewardSchema, 'free_parking_hrs', {
            inputAttributes: { width: '33%' },
            editable: isEditable(editContext, 'free_parking_hrs'),
          }),
          inputFactory(cardRewardSchema, 'free_parking_times', {
            inputAttributes: { width: '33%' },
            editable: isEditable(editContext, 'free_parking_times'),
          }),
          inputFactory(cardRewardSchema, 'basic_r', {
            inputAttributes: { width: '33%' },
          }),
          inputFactory(cardRewardSchema, 're_upper_bound', {
            inputAttributes: { width: '50%' },
            editable: isEditable(editContext, 're_upper_bound'),
          }),
          inputFactory(cardRewardSchema, 're_upper_bound_unit_id', {
            inputAttributes: { width: '50%' },
            editable: isEditable(editContext, 're_upper_bound_unit_id'),
          }),
          inputFactory(cardRewardSchema, 'special_day', {
            inputAttributes: { width: '33%' },
          }),
          inputFactory(cardRewardSchema, 'currency', {
            inputAttributes: { width: '33%' },
          }),
          inputFactory(cardRewardSchema, 'essential_money', {
            inputAttributes: { width: '33%' },
          }),
          inputFactory(cardRewardSchema, 'essential_day', {
            inputAttributes: { width: '33%' },
          }),
          inputFactory(cardRewardSchema, 'essential_day_unit_id', {
            inputAttributes: { width: '33%' },
            editable: isEditable(editContext, 'essential_day_unit_id'),
          }),
          inputFactory(cardRewardSchema, 'precondition', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(cardRewardSchema, 'detail', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(cardRewardSchema, 'data_source_urls'),
        ],
      },
    ],
    [isEditable, editContext, cardGroup, CardSetWrapper],
  )

  return (
    <SchemaBaseEditor
      {...props}
      isReady={get(context, 'isReady', false) && !isNil(editContext)}
      currentSchema={cardRewardSchema}
      tabs={tabs}
      checker={checker}
      onChange={setEditContext}
      editContext={editContext}
      setEditContext={setEditContext}
    />
  )
}

CardRewardEditDialog.propTypes = {
  doc: PropTypes.object.isRequired,
  ticket: TicketPropType.isRequired,
}
