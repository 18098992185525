import styled from 'styled-components'
import { Box, Grid } from '@changingai/react-editor-common-component'
import { ScrollBar } from '@widget'

export const Header = styled(Box)``
Header.defaultProps = {
  bg: 'blacks.7',
  color: 'white',
  p: '1',
  fontSize: 'h3',
  minWidth: '100px',
  textAlign: 'center',
  fontWeight: 'bold',
}

export const Cell = styled(Box)`
  ${ScrollBar}
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

Cell.defaultProps = {
  bg: 'white',
  px: '2',
  py: '1',
  minWidth: '100px',
  maxHeight: '100px',
}

export const StyledGrid = styled(Grid)``
StyledGrid.defaultProps = {
  width: '100%',
  justifyContent: 'center',
  gridGap: '1px',
  bg: 'lightgray',
  border: '1px solid gray',
  my: '2',
}
