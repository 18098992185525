import { useMemo, useCallback, useContext } from 'react'
import 'styled-components/macro'
import {
  get,
  isNil,
  isEmpty,
  uniq,
  map,
  toUpper,
  castArray,
  find,
} from 'lodash'
import { useQuery } from '@apollo/client'

import { backendDocType, docPrivilege } from './config'
import { CardEditDialog } from './editor'
import { cardSchema } from './schema'

import { useCacheDocs, useConvertIdToName } from '../Common'
import { bindSchemaWithSummaryLayout } from '../SummaryFields'
import TicketSummaryModel from '../TicketSummaryModel'
import { useReferenceBase, filterTicketRef } from '../Reference'
import { useFetchDocOfTicket, recomputeCrud } from '../TicketHelper'
import { useUploadDocWithHistory, useDeleteDocWithHistory } from '../Uploader'

import { cceClient } from '@gql'
import {
  isLocalDoc,
  TicketPropType,
  getActiveState,
  TicketState,
  ProjectContext,
  useDialog,
} from '@common'
import { useFetchReferencedKG, createBlankDoc } from '@schema'
import { DocSortingDialog } from '@dialog'

import { _uploadCards, _queryTickets, _removeCards } from './listview.gql'

bindSchemaWithSummaryLayout(cardSchema, {
  apply_condition: {
    cols: 2,
  },
  apply_doc: {
    cols: 2,
  },
  foreign_trans_fee: {
    cols: 2,
  },
  overdue_fine: {
    cols: 2,
  },
  rebill_fee: {
    cols: 2,
  },
  cash_adv_prepayment_penalty: {
    cols: 2,
  },
  attributes: {
    skipRender: true,
  },
})

function filterRef(doc) {
  return (
    filterTicketRef(doc) &&
    // Discard card reference. If we do not do so, the mutual reference
    // between a card and a cardgroup will make them un-deletable forever.
    doc.type !== backendDocType
  )
}

function useReference(doc) {
  return useReferenceBase('cardgroup', filterRef, { _id: doc.card_group_id })
}

export function useTranspile(docs, selectedFields, ticket) {
  const { data: tickets } = useQuery(_queryTickets, {
    client: cceClient,
    variables: {
      filter: {
        ticket_ids: uniq(map(docs, 'ticket_id')),
      },
      namespace: toUpper('card'),
    },
    skip: !isNil(ticket) || isEmpty(docs),
  })
  const cardGroup = cardSchema.card_group_id.useNodes({
    ticket_id: get(ticket, '_id') || get(tickets, 'queryTickets.0._id'),
  })
  const nodes = useFetchReferencedKG(docs, cardSchema, selectedFields)
  const referencedList = useMemo(() => [cardGroup, nodes], [cardGroup, nodes])

  return useConvertIdToName(docs, referencedList, selectedFields, cardSchema)
}

function useUploadCard() {
  const upload = useUploadDocWithHistory(cardSchema, async input => {
    const {
      data: { uploadCards },
    } = await cceClient.mutate({
      mutation: _uploadCards,
      variables: {
        input,
      },
    })

    return uploadCards
  })

  return upload
}

const fullAccessStates = [TicketState.CREATE, TicketState.FINISH]
function useController(ticket, selectedFields) {
  const cards = useFetchDocOfTicket(ticket, cardSchema, selectedFields)
  const { email, peepMode } = useContext(ProjectContext)
  const { updated, deleteCache, updateCache, docs } = useCacheDocs(
    email,
    peepMode,
    ticket,
    cards,
    fullAccessStates,
  )

  const createDoc = useCallback(() => {
    const result = {
      ...createBlankDoc(cardSchema, true),
      ticket_id: ticket._id,
      enabled: true,
      // TBD: (FIXME)
      // Ideally, one who has card editing privilege should be able to edit
      // any cards. In practice, card editing is bound with ticket system,
      // which only allow assignees of the current ticket to edit card of
      // that ticket.
      // Before we take out this limitation, cheat the backend by sending
      // the first assignee of the current ticket, instead of the actual
      // editor.
      uploader: getActiveState(ticket).assignees[0],
    }
    delete result._id
    return result
  }, [ticket])

  const [showDialog, renderDoc] = useDialog(CardEditDialog)
  const showDoc = useCallback(
    (doc, crud) => {
      showDialog({ doc, crud })
    },
    [showDialog],
  )

  const upload = useUploadCard()
  const uploadDoc = useCallback(
    card => upload(ticket, card, 'anyone@changing.ai'),
    [ticket, upload],
  )

  const [showSorting, renderSortDialog] = useDialog(DocSortingDialog)
  const renderSorting = useCallback(
    transpiledDocs => (
      <>
        {renderSortDialog({
          allItems: transpiledDocs,
          ticket,
          sortedNames: ['card_group_id', 'issuer_id', 'card_level_id'],
        })}
      </>
    ),
    [ticket, renderSortDialog],
  )

  const deleteCard = useDeleteDocWithHistory(cardSchema, ids => {
    cceClient.mutate({
      mutation: _removeCards,
      variables: {
        ids: castArray(ids),
      },
    })
  })
  const deleteDoc = useCallback(
    _id => {
      deleteCache(_id)
      const deleted = find(docs, { _id })
      if (isNil(deleted) || isLocalDoc(deleted)) return
      return deleteCard(deleted)
    },
    [deleteCache, docs, deleteCard],
  )

  return {
    isReady: !isNil(cards),
    docs,
    createDoc,
    updateDoc: updateCache,
    showDoc,
    renderDoc,
    updated,
    uploadDoc,
    showSorting,
    renderSorting,
    useReference,
    deleteDoc,
  }
}

const defaultSummary = {
  selected: [
    'card_group_id',
    'img_urls',
    'issuer_id',
    'card_level_id',
    'function_ids',
  ],
  filters: {},
}

function useCrud(ticket, email) {
  const { privilege } = useContext(ProjectContext)

  const result = useMemo(() => {
    const crud = recomputeCrud(ticket, email, privilege)
    // See the comment in useController.createDoc
    if (privilege.includes('card')) {
      crud.read = true
      crud.update = true
      crud.create = true
      crud.delete = true
    }
    return crud
  }, [ticket, email, privilege])

  return result
}

const CardListView = props => {
  return (
    <TicketSummaryModel
      {...props}
      useController={useController}
      useCrud={useCrud}
      useTranspile={useTranspile}
      defaultSummary={defaultSummary}
      requiredPrivilege={[docPrivilege]}
      currentSchema={cardSchema}
    />
  )
}

CardListView.propTypes = {
  ticket: TicketPropType.isRequired,
}

export default CardListView
