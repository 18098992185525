import { useCallback, useRef } from 'react'
import { useImmer } from 'use-immer'
import Joi from 'joi'
import { cloneDeep, get, slice, reject, isNil, isEqual, size } from 'lodash'
import { getOperationAST } from 'graphql'
import { useLocalStorage } from '@changing-cc/hooks'
import { computeType } from './common'

export function useDirtyStore(initialDirty = false) {
  const [store, setStore] = useImmer({
    error: [],
    dirty: initialDirty ? ['initial-dirty-virtual-id'] : [],
  })
  const [reasons, setReasons] = useImmer({})

  const update = useCallback(
    (which, id, setOrUnset) => {
      setStore(draft => {
        if (setOrUnset === 'toggle') setOrUnset = !draft[which].includes(id)
        if (setOrUnset) {
          if (!draft[which].includes(id)) {
            draft[which].push(id)
          }
        } else {
          const index = draft[which].indexOf(id)
          if (index !== -1) {
            draft[which].splice(index, 1)
          }
        }
      })
    },
    [setStore],
  )

  const cleanStore = useCallback(() => {
    setStore(() => ({ dirty: [], error: [] }))
  }, [setStore])

  const setDirty = useCallback(
    (id, dirty) => {
      update('dirty', id, dirty)
    },
    [update],
  )

  const setError = useCallback(
    (id, error, reason) => {
      update('error', id, error, reason)

      if (error) {
        setReasons(draft => {
          draft[id] = reason
        })
      } else {
        setReasons(draft => {
          delete draft[id]
        })
      }
    },
    [update, setReasons],
  )

  return [setDirty, store.dirty, setError, store.error, cleanStore, reasons]
}

export function validateEmail(email) {
  const schema = Joi.string()
    .email({ tlds: { allow: false } })
    .required()
  const { error } = schema.validate(email)
  return !error
}

export function useDoubleBuffer(source) {
  const [buffers, setBuffers] = useImmer([cloneDeep(source), cloneDeep(source)])

  const updateBuffer = useCallback(
    buffer => {
      setBuffers(draft => [draft[0], cloneDeep(buffer)])
    },
    [setBuffers],
  )

  const swapBuffer = useCallback(
    () => setBuffers(draft => [draft[1], draft[0]]),
    [setBuffers],
  )

  return {
    updateBuffer,
    swapBuffer,
    frontBuffer: get(buffers, '0', null),
  }
}

export function isLocalDoc(doc) {
  return get(doc, 'local_doc', false)
}

export function useCacheStore() {
  const [store, setStore] = useImmer({})
  const addToCacheStore = useCallback(
    (storeName, key, value) => {
      setStore(draft => {
        if (!(storeName in draft)) draft[storeName] = {}
        draft[storeName][key] = value
      })
    },
    [setStore],
  )
  const findInCacheStore = useCallback(
    (storeName, key) => {
      if (!(storeName in store)) return null
      return store[storeName][key]
    },
    [store],
  )

  const getCacheStore = useCallback(storeName => get(store, storeName, {}), [
    store,
  ])

  return { addToCacheStore, findInCacheStore, getCacheStore }
}

const mongoIdRegEx = /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i
export const isMongoId = id => mongoIdRegEx.test(id)

export function getQueryTagName(queryTag) {
  return get(getOperationAST(queryTag), 'name.value')
}

export function useMultiLru(storageKey = 'LRU', max = 10) {
  const [storage, setStorage] = useLocalStorage(storageKey, {})

  const push = useCallback(
    (key, cache) => {
      if (isNil(cache?._id)) throw new Error('cache must contain _id as key.')
      const keyStorage = get(storage, key, [])
      setStorage({
        ...storage,
        [key]: slice(
          [cache, ...reject(keyStorage, { _id: cache._id })],
          0,
          max,
        ),
      })
    },
    [storage, setStorage, max],
  )

  const getLru = useCallback(key => get(storage, key, []), [storage])

  return [push, getLru]
}

export function formatNumber(number) {
  const internationalNumberFormat = new Intl.NumberFormat('en-US')
  return internationalNumberFormat.format(number)
}

export function useDeepMemo(value) {
  const ref = useRef()

  if (isNil(ref.current) || !isEqual(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

export function getSchemaByPath(paths, schema) {
  if (size(paths) === 1) return schema[paths[0]]
  const [field, ...path] = paths
  const { extractedSchema } = computeType(schema[field].type)
  return getSchemaByPath(path, extractedSchema)
}
