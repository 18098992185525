import { FaCcMastercard } from 'react-icons/fa'
import {
  AiFillInsurance,
  AiOutlineShoppingCart,
  AiFillAndroid,
} from 'react-icons/ai'

export const namespaces = {
  card: {
    label: '卡片',
    value: 'card',
    Icon: FaCcMastercard,
    // The privilege defined here should be apply to all views which are
    // belong to this namespace. Each namesapce can add additional, not
    // overwrite, privilege reqiuirement in view.manifest.privilege.
    privilege: 'card',
  },
  shop: {
    label: '店家',
    value: 'shop',
    Icon: AiOutlineShoppingCart,
    privilege: 'shop',
  },
  insurance: {
    label: '保險',
    value: 'insurance',
    Icon: AiFillInsurance,
    privilege: 'insurance',
  },
  test: {
    label: '測試',
    value: 'test',
    Icon: AiFillAndroid,
    privilege: 'test',
  },
}
