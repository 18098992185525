import { useCallback, useMemo, useContext } from 'react'
import { isNil, castArray, find, size, get, partial } from 'lodash'

import { isLocalDoc, namespaces, ProjectContext, useDialog } from '@common'
import { createBlankDoc } from '@schema'
import { cceClient } from '@gql'

import { docPrivilege } from './config'
import { NinjaEditor } from './editor'
import { ninjaSchema } from './schema'

import { useCacheDocs, useKGTranspile } from '../Common'
import { cloneTicketDoc } from '../DocHelper'
import {
  useSoftTicketRef,
  useFetchDocOfTicket,
  createBlankTicketable,
} from '../TicketHelper'
import TicketSummaryModel from '../TicketSummaryModel'
import { useReferenceBase, filterTicketRef } from '../Reference'
import { useUploadDocWithHistory, useDeleteDocWithHistory } from '../Uploader'

import { _uploadNinja, _deleteNinja, _queryNinja } from './listview.gql'

function useReference(doc) {
  const data = useReferenceBase('ninja', filterTicketRef, doc)
  const ticketRef = useSoftTicketRef(doc, namespaces.test.value)
  return useMemo(() => {
    if (isNil(ticketRef) || isNil(get(data, 0))) return [null, null]
    return [size(ticketRef) + get(data, 0), get(data, 1)]
  }, [data, ticketRef])
}

export const useTranspile = partial(useKGTranspile, ninjaSchema)

function useUploadNinjaOfTicket() {
  const upload = useUploadDocWithHistory(ninjaSchema, async ninjas => {
    const {
      data: { uploadNinja },
    } = await cceClient.mutate({
      mutation: _uploadNinja,
      variables: {
        ninjas,
      },
    })

    return uploadNinja
  })

  return upload
}
function useController(ticket, selectedFields) {
  const ninjas = useFetchDocOfTicket(ticket, ninjaSchema, selectedFields)
  const { updated, deleteCache, updateCache, docs } = useCacheDocs(
    null,
    true,
    null,
    ninjas,
  )

  const [showDialog, renderDialog] = useDialog(NinjaEditor)
  const showDoc = useCallback(
    (doc, crud) => {
      showDialog({
        doc,
        ticket,
        crud,
      })
    },
    [ticket, showDialog],
  )

  const upload = useUploadNinjaOfTicket()
  const { email } = useContext(ProjectContext)
  const uploadDoc = useCallback(context => upload(ticket, context, email), [
    upload,
    ticket,
    email,
  ])

  const deleteNinja = useDeleteDocWithHistory(ninjaSchema, ids => {
    cceClient.mutate({
      mutation: _deleteNinja,
      variables: {
        ids: castArray(ids),
      },
    })
  })
  const deleteDoc = useCallback(
    _id => {
      deleteCache(_id)
      const deleted = find(docs, { _id })
      if (isNil(deleted) || isLocalDoc(deleted)) return
      return deleteNinja(deleted)
    },
    [deleteCache, docs, deleteNinja],
  )
  const createDoc = useCallback(
    uploader => {
      const ninja = {
        ...createBlankDoc(ninjaSchema, true),
        ...createBlankTicketable(ticket, uploader),
      }
      updateCache(ninja)

      return ninja
    },
    [updateCache, ticket],
  )

  const cloneDoc = useCallback(
    async doc => {
      const cloned = await cloneTicketDoc(
        _queryNinja,
        doc,
        ticket,
        email,
        ninjaSchema,
      )
      updateCache({ ...cloned, factors: [] })
    },
    [updateCache, email, ticket],
  )

  return {
    isReady: !isNil(docs),
    docs,
    cloneDoc,
    createDoc,
    showDoc,
    updateDoc: updateCache,
    updated,
    renderDoc: renderDialog,
    uploadDoc,
    deleteDoc,
    useReference,
  }
}

const defaultSummary = {
  selected: ['name'],
  filters: {},
}

const NinjaListView = props => {
  return (
    <TicketSummaryModel
      {...props}
      useController={useController}
      defaultSummary={defaultSummary}
      useTranspile={useTranspile}
      requiredPrivilege={[docPrivilege]}
      currentSchema={ninjaSchema}
    />
  )
}

export default NinjaListView
