import PropTypes from 'prop-types'
import { partial } from 'lodash'

import { namespace, schema } from './config'
import { TiiEditor } from './editor'
import { tiiSchema } from './schema'

import { getTicketDocPath } from '../Common'
import { useFetchDocAndTicket } from '../DocHelper'
import { referenceDoc } from '../Reference'
import { EditorLoader } from '../SchemaBaseEditorLoader'

import { _queryTii } from './loader.gql'

const getPath = partial(getTicketDocPath, namespace, schema)

export const queryManifest = {
  gql: _queryTii,
  name: 'queryTii',
  getPath,
}

export const generateReferenceDoc = partial(
  referenceDoc,
  'name',
  getPath,
  schema,
  namespace,
)

export function TiiEditorLoader({ docId }) {
  return (
    <EditorLoader
      DialogComp={TiiEditor}
      useFetchDocAndTicket={partial(
        useFetchDocAndTicket,
        _queryTii,
        'queryTii',
        namespace,
        docId,
      )}
      currentSchema={tiiSchema}
    />
  )
}

TiiEditorLoader.propTypes = {
  docId: PropTypes.string.isRequired,
}

export default TiiEditorLoader
