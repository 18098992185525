import { useMemo, useState, useEffect, useCallback, useContext } from 'react'
import {
  get,
  map,
  isEmpty,
  partition,
  size,
  isNil,
  filter,
  sum,
  find,
  toUpper,
  values,
  includes,
  reduce,
  pick,
  keys,
  partialRight,
  head,
} from 'lodash'
import { gql, useMutation, useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { useLocalStorage } from '@changing-cc/hooks'

import {
  genesis,
  getActiveState,
  TicketState,
  TicketSubState,
  isWaitingTicketAccessRight,
  ProjectContext,
  MatchState,
  computeType,
} from '@common'
import { purifySchemaDoc, toEditableDocs, ticketProfileSchema } from '@schema'
import { cceClient } from '@gql'

import { findManifest } from './Config'

import {
  _returnTicket,
  _createTickets,
  _renameTickets,
  _deleteTickets,
  _queryTickets,
} from './TicketHelper.gql'

export const getTicketProfile = partialRight(findManifest, 'ticketProfile')
export function getNamespaceByScopeType(backendDocType) {
  return toUpper(findManifest(backendDocType, 'namespace'))
}

export function useTicketVersion(ticketType) {
  const { ticketVersion } = useContext(ProjectContext)
  const version = useMemo(() => {
    const enableVersion = get(getTicketProfile(ticketType), 'enableVersion')
    if (isNil(enableVersion)) return
    return enableVersion ? ticketVersion : genesis.toString()
  }, [ticketVersion, ticketType])

  return version
}

// The implementation of this function is highly depenent on the backend ticket
// state machine
export function isTicketEditable(ticket, email) {
  if (!ticket.crud.update) return false

  const activeState = getActiveState(ticket)

  // In any state, a ticket is not changeable before dispatch.
  if (activeState.sub_state === TicketSubState.INITIATED) return false

  // General rule: in all state, the current editor is allow to edit the content of
  // a ticket only if this ticket is assign to this editor, and he does not return
  // this ticket yet.
  if (
    !activeState.assignees.includes(email) ||
    activeState.confirmors.includes(email)
  )
    return false

  return true
}

export function useReturnTicket(ticket, email) {
  const [currentTicket, setCurrentTicket] = useState(ticket)
  useEffect(() => {
    setCurrentTicket(ticket)
  }, [ticket])

  const [returnTicketMutation] = useMutation(_returnTicket)
  const onReturn = useCallback(async () => {
    const {
      data: { returnTicket },
    } = await returnTicketMutation({
      variables: {
        input: { _id: get(currentTicket, '_id') },
        namespace: getNamespaceByScopeType(get(currentTicket, 'scope_type')),
      },
    })
    setCurrentTicket(returnTicket)
  }, [returnTicketMutation, currentTicket, setCurrentTicket])

  const canReturn = useMemo(() => isTicketEditable(currentTicket, email), [
    currentTicket,
    email,
  ])

  return useMemo(
    () => ({
      canReturn,
      onReturn,
    }),
    [canReturn, onReturn],
  )
}

export function useMutateTicket() {
  const [createTicketMutation] = useMutation(_createTickets)
  const [renameTicketMutation] = useMutation(_renameTickets)

  const createOrRenameTicket = useCallback(
    async (docs, scope_type, composeName) => {
      const namespace = getNamespaceByScopeType(scope_type)
      const states = get(getTicketProfile(scope_type), 'states')
      const {
        data: { queryTickets },
      } = await cceClient.query({
        query: _queryTickets,
        variables: {
          filter: {
            scope_ids: map(docs, '_id'),
            scope_type: scope_type,
          },
          namespace,
        },
        skip: isEmpty(docs),
      })

      const [createdDocs, updatedDocs] = partition(docs, ({ _id }) =>
        isNil(find(queryTickets, { scope_id: _id })),
      )

      if (!isEmpty(updatedDocs)) {
        const input = updatedDocs
          .filter(
            doc =>
              get(find(queryTickets, { scope_id: doc._id }), 'name') !==
              composeName(doc),
          )
          .map(doc => ({
            _id: get(find(queryTickets, { scope_id: doc._id }), '_id'),
            name: composeName(doc),
          }))

        if (!isEmpty(input)) {
          await renameTicketMutation({
            variables: {
              input,
              namespace,
            },
          })
          toast.success(
            `Rename ${scope_type} ${size(input)} ticket${
              size(input) === 1 ? '' : 's'
            } successfully!`,
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
            },
          )
        }
      }

      if (!isEmpty(createdDocs)) {
        await createTicketMutation({
          variables: {
            input: createdDocs.map(doc =>
              purifySchemaDoc(
                {
                  scope_id: doc._id,
                  scope_type,
                  name: composeName(doc),
                  states,
                  version: genesis.toString(),
                },
                ticketProfileSchema,
              ),
            ),
            namespace,
          },
        })
        toast.success(
          `Create ${scope_type} ${size(createdDocs)} ticket${
            size(createdDocs) === 1 ? '' : 's'
          } successfully!`,
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          },
        )
      }
    },
    [createTicketMutation, renameTicketMutation],
  )

  const [deleteTicketMutation] = useMutation(_deleteTickets)
  const deleteTicket = useCallback(
    async (ids, scope_type) => {
      if (isEmpty(ids)) return
      const namespace = getNamespaceByScopeType(scope_type)
      await deleteTicketMutation({
        variables: {
          ids,
          namespace,
        },
      })
      toast.success(
        `Delete ${size(ids)} ${scope_type} ticket${
          size(ids) === 1 ? '' : 's'
        } successfully!`,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        },
      )
    },
    [deleteTicketMutation],
  )

  const result = useMemo(
    () => ({
      createOrRenameTicket,
      deleteTicket,
    }),
    [createOrRenameTicket, deleteTicket],
  )

  return result
}

export function useSoftTicketRef(doc, namespace) {
  // CardGroup is referred by two types of doc: card reward doc and ticket doc.
  // When we delete a card group, we want to delete tickets which refer to this card group
  // (by scope_id). We can not count reference from these tickets, if we do so, cyclic
  // reference will make both the card group and reference ticket un-deletable.
  // The solution is to increase the reference count of a card group, by one, only if
  // an associated ticket contains card reward docs. So we can delete both the card group
  // and tickets when there is no reward docs under those tickets.

  const { data: tickets } = useQuery(_queryTickets, {
    client: cceClient,
    variables: {
      filter: {
        scope_ids: [get(doc, '_id')],
      },
      namespace: toUpper(namespace),
    },
    skip: isNil(get(doc, '_id')),
  })

  const ticketRef = useMemo(() => {
    if (!tickets) return null
    return filter(
      tickets.queryTickets,
      ticket => computeDocsOfTicket(ticket) !== 0,
    )
  }, [tickets])

  return ticketRef
}

export function computeDocsOfTicket(ticket) {
  return sum(map(get(ticket, 'meta.assignee_meta'), 'own_docs'))
}

export async function canDeleteTicketRefDoc({ _id }, namespace) {
  const {
    data: { queryTickets },
  } = await cceClient.query({
    query: _queryTickets,
    variables: {
      filter: {
        scope_ids: [_id],
      },
      namespace: toUpper(namespace),
    },
  })

  return [
    computeDocsOfTicket(head(queryTickets)) === 0,
    map(queryTickets, '_id'),
  ]
}

export function useTickets({
  scope_type,
  scope_ids,
  assignees,
  ticket_ids,
  active_states,
  namespace,
  skip = false,
  gql = _queryTickets,
}) {
  const version = useTicketVersion(scope_type)
  const { loading, data } = useQuery(gql, {
    client: cceClient,
    variables: {
      filter: {
        scope_type,
        active_states,
        assignees,
        ticket_ids,
        scope_ids,
        version,
      },
      namespace: toUpper(namespace),
    },
    skip,
  })

  const tickets = useMemo(
    () =>
      !data
        ? null
        : data.queryTickets.map(ticket => {
            if ('crud' in ticket) {
              const crud = { ...ticket.crud }
              delete crud.__typename
              return {
                ...ticket,
                crud,
              }
            }

            return ticket
          }),
    [data],
  )

  return [tickets, loading]
}

export function genQueryFields(currentSchema) {
  return reduce(
    currentSchema,
    (query, { type }, key) => {
      const { extractedSchema } = computeType(type)
      return isNil(extractedSchema)
        ? query + `${key}\n`
        : query + `${key} {\n${genQueryFields(extractedSchema)}}\n`
    },
    '',
  )
}

export function useFetchDocOfTicket(ticket, currentSchema, fields = []) {
  const generateQueryTag = (queryName, currentSchema, fields) =>
    gql(`query _${queryName}($ticket_ids: [String!]) {
      ${queryName}(ticket_ids: $ticket_ids) {
        ${genQueryFields(pick(currentSchema, fields))}
      }
    }`)

  const [queryName, queryTag, variables] = useMemo(
    () => [
      `query${ticket.scope_type}`,
      generateQueryTag(
        `query${ticket.scope_type}`,
        currentSchema,
        isEmpty(fields)
          ? keys(currentSchema)
          : ['_id', 'uploader', 'matched_state', 'matched_target', ...fields],
      ),
      ({ _id }) => ({
        ticket_ids: [_id],
      }),
    ],
    [ticket, currentSchema, fields],
  )

  const { data } = useQuery(queryTag, {
    variables: variables(ticket),
    client: cceClient,
  })

  return useMemo(
    () => toEditableDocs(get(data, queryName, null), currentSchema),
    [data, queryName, currentSchema],
  )
}

export function recomputeCrud(ticket, email, privilege) {
  // ticket.crud is the CRUD state of the given ticket base on
  // ticket.main_state and ticket.sub_state. And this value comes
  // from the backend.
  // editable is determined by whether the current user(email)
  // is allow to edit this ticket base on ticket.assignee. We evaluate
  // this value in the frontend.
  //
  // TBD: we should move code to define the value of editable here
  // to the backend.
  const editable =
    isTicketEditable(ticket, email) &&
    !isWaitingTicketAccessRight(ticket, email)
  const isFinish = getActiveState(ticket).main_state === TicketState.FINISH

  return {
    ...ticket.crud,
    create: ticket.crud.create && editable,
    read: (ticket.crud.read && editable) || isFinish,
    update:
      (ticket.crud.update && editable) ||
      (isFinish && includes(privilege, 'supervisor')),
    delete: ticket.crud.delete && editable,
    review: isFinish,
  }
}

export function useTicketStateStorage(ticketType, storageKey) {
  const defaultMainState = useMemo(
    () => map(getTicketProfile(ticketType).states, 'main_state'),
    [ticketType],
  )
  const defaultSubState = useMemo(() => values(TicketSubState), [])

  const [mainState, setMainState] = useLocalStorage(
    `selectedTicketState3.${storageKey}.${ticketType}`,
    defaultMainState,
  )
  const [subState, setSubState] = useLocalStorage(
    `selectedTicketSubState3.${storageKey}.${ticketType}`,
    defaultSubState,
  )
  const [assignee, setAssignee] = useLocalStorage(
    `selectedAssignee3.${storageKey}.${ticketType}`,
    [],
  )

  return [mainState, setMainState, subState, setSubState, assignee, setAssignee]
}

export function createBlankTicketable(ticket, uploader) {
  return {
    ticket_id: ticket._id,
    matched_state: MatchState.NO_MATCHED,
    uploader,
  }
}

const genCRUDO = (create, read, update, del, overwrite) => ({
  create,
  read,
  update,
  delete: del,
  overwrite,
})

export const CRUDO = {
  RW_NO_OVERWRITE: genCRUDO(true, true, true, true, false),
  RW_NO_DEL_OVERWRITE: genCRUDO(true, true, true, false, false),
  FULLY_ACCESS: genCRUDO(true, true, true, true, true),
  READ_ONLY: genCRUDO(false, true, false, false, false),
}
