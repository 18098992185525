import { useMemo } from 'react'
import Joi from 'joi'
import { useQuery } from '@apollo/client'

import {
  KG_NODES,
  useGetConnectedNodes,
  useBuildKGTree,
  namespaces,
} from '@common'
import {
  addSchema,
  schemaToPropTypes,
  matchStateSchema,
  ticketDocSchema,
  NullableString,
  shortString,
  longString,
  extremeLongString,
  RequiredKGNodeIdString,
  NullableMongoIdString,
  DateInFiftyYears,
  UniqueNodeArray,
  UniqueUrlArray,
} from '@schema'
import { cceClient } from '@gql'

import { _queryContract, _queryProduct } from './schema.gql'

import { compact } from 'lodash'

const currancyRoot = [
  {
    node_id: KG_NODES.insurance.CATEGORY_ROOT_ID,
    namespace: 'insurance',
  },
]

const productAttributeRoot = [
  {
    node_id: KG_NODES.insurance.PRODUCT_ATTRIBUTE_ROOT_ID,
    namespace: 'insurance',
  },
]

// 0.x
export const rateSchema = Joi.string()
  .pattern(/^([0]\.){1}[0-9]+$/)
  .allow(null)

// 20yy/{m, mm}
export const yearMonthSchema = Joi.string()
  .pattern(/^20[0-9]{2}\/(0?[1-9]{1}|1[0-2]{1}){1}$/)
  .allow(null)
export const declaredInterestCellSchema = Joi.alternatives().try(
  rateSchema,
  yearMonthSchema,
)

export const productSchema = {
  ...ticketDocSchema,
  ...matchStateSchema,
  company_id: {
    schema: RequiredKGNodeIdString,
    type: 'node',
    label: '保險公司',
    useNodes: () => {
      const roots = useMemo(
        () => [
          {
            node_id: KG_NODES.insurance.LIFE_INSURANCE_COMPANY_ROOT_ID,
            namespace: 'insurance',
          },
          {
            node_id: KG_NODES.insurance.PROPERTY_INSURANCE_COMPANY_ROOT_ID,
            namespace: 'insurance',
          },
        ],
        [],
      )

      return useGetConnectedNodes(roots)
    },
  },
  title: {
    schema: NullableString.max(shortString),
    type: 'string',
    label: '標題',
  },
  sub_title: {
    schema: NullableString.max(shortString),
    type: 'string',
    label: '副標題',
  },
  code: {
    schema: NullableString.max(shortString),
    type: 'string',
    label: '商品代號',
  },
  dm_url: {
    schema: Joi.alternatives().try(
      Joi.string().allow(null, ''),
      Joi.string().uri(),
    ),
    type: 'url',
    label: 'DM檔案',
    mimetype: 'application/pdf',
  },
  brochure_url: {
    schema: Joi.alternatives().try(
      Joi.string().allow(null, ''),
      Joi.string().uri(),
    ),
    type: 'url',
    label: '商品說明書',
    mimetype: 'application/pdf',
  },
  product_urls: {
    schema: UniqueUrlArray,
    type: 'arrayOf(url)',
    label: '商品網頁',
    multiInput: true,
  },
  purchase_urls: {
    schema: UniqueUrlArray,
    type: 'arrayOf(url)',
    label: '投保連結',
    multiInput: true,
  },
  contract_ids: {
    schema: UniqueNodeArray,
    type: 'arrayOf(node)',
    label: '引用條款',
    useNodes: ({ company_id }) => {
      const { loading: contractLoading, data: contractData } = useQuery(
        _queryContract,
        {
          client: cceClient,
          variables: {
            company_id,
          },
          skip: !company_id,
        },
      )

      const contract_ids = useMemo(() => {
        if (!company_id) return []
        if (contractLoading || !contractData) return null
        return contractData.queryContract.map(doc => ({
          ...doc,
          id: doc._id,
          name: doc.contract_name,
        }))
      }, [contractLoading, contractData, company_id])

      return contract_ids
    },
    multiSelect: true,
  },
  currency_ids: {
    schema: UniqueNodeArray,
    referToKG: 'insurance',
    type: 'arrayOf(node)',
    label: '幣別',
    useNodes: () => {
      const roots = useMemo(
        () => [
          {
            node_id: KG_NODES.insurance.CURRENCY_ROOT_ID,
            namespace: 'insurance',
          },
        ],
        [],
      )
      return useGetConnectedNodes(roots, true)
    },
    multiSelect: true,
  },
  private: {
    schema: Joi.bool(),
    type: 'bool',
    label: '不收/下架此商品所有資訊',
  },
  hide_info: {
    schema: Joi.bool(),
    type: 'bool',
    label: '對消費者隱藏部分商品資訊＋商品卡',
  },
  intro: {
    schema: NullableString.max(longString),
    type: 'string',
    label: '商品介紹',
  },
  info_insure_year: {
    schema: NullableString.max(shortString),
    type: 'string',
    label: '保障年期(DM)',
  },
  info_payment_year: {
    schema: NullableString.max(shortString),
    type: 'string',
    label: '繳費年期(DM)',
  },
  info_payment_method: {
    schema: NullableString.max(longString),
    type: 'string',
    label: '繳費方式(DM)',
  },
  info_require_age: {
    schema: NullableString.max(longString),
    type: 'string',
    label: '投保年齡(DM)',
  },
  info_require_occ: {
    schema: NullableString.max(longString),
    type: 'string',
    label: '投保職業(DM)',
  },
  info_require_amount: {
    schema: NullableString.max(extremeLongString),
    type: 'string',
    label: '投保額度限制(DM)',
  },
  fee_discount: {
    schema: NullableString.max(longString),
    type: 'string',
    label: '保費折扣(DM)',
  },
  info_require_other: {
    schema: NullableString.max(extremeLongString),
    type: 'string',
    label: '其他投保限制(DM)',
  },
  category_ids: {
    schema: UniqueNodeArray,
    referToKG: 'insurance',
    type: 'arrayOf(node)',
    label: '險種標籤',
    useNodes: () => useGetConnectedNodes(currancyRoot),
    multiSelect: true,
  },
  is_additional: {
    schema: Joi.bool().required(),
    type: 'bool',
    label: '附加型',
  },
  channel_ids: {
    schema: UniqueNodeArray,
    type: 'arrayOf(node)',
    referToKG: 'insurance',
    label: '銷售通路',
    useTree: () =>
      useBuildKGTree(
        KG_NODES.insurance.SALE_CHANNEL_ROOT_ID,
        namespaces.insurance.value,
      ),
    multiSelect: true,
  },
  attribute_ids: {
    schema: UniqueNodeArray,
    referToKG: 'insurance',
    type: 'arrayOf(node)',
    label: '商品屬性',
    useNodes: () => useGetConnectedNodes(productAttributeRoot),
    multiSelect: true,
  },
  loading: {
    schema: NullableString.max(extremeLongString),
    type: 'string',
    label: '預定費用率(DM)',
  },
  loading_rates: {
    schema: Joi.array()
      .items(
        Joi.number()
          .min(0)
          .max(100),
      )
      .max(2),
    type: 'arrayOf(number)',
    label: '預定費用率(%)',
  },
  search_codes: {
    schema: Joi.array()
      .items(Joi.string())
      .allow(null),
    type: 'arrayOf(string)',
    skipCompare: true,
    label: '搜尋用代碼',
  },
  declared_interests: {
    schema: Joi.array()
      .items(
        Joi.array()
          .items(declaredInterestCellSchema)
          .length(2),
      )
      .length(120)
      .allow(null),
    type: 'arrayOf(string)',
    skipCompare: true,
    label: '產品宣告利率',
  },
  launch_date: {
    schema: DateInFiftyYears.required(),
    type: 'date',
    label: '銷售日[約略]',
    suggestions: [
      {
        label: '今天',
        value: new Date().toLocaleDateString('zh-Hans-CN'),
      },
    ],
  },
  end_date: {
    schema: DateInFiftyYears.allow(null, ''),
    type: 'date',
    label: '停售日[約略]',
    suggestions: [
      {
        label: '月底',
        value: (() => {
          const now = new Date()
          return new Date(
            new Date(now.getFullYear(), now.getMonth() + 1, 1) - 1,
          ).toLocaleDateString('zh-Hans-CN')
        })(),
      },
    ],
  },
  ancestor_id: {
    schema: NullableMongoIdString,
    type: 'string',
    label: '首版產品',
    useNodes: ({ ticket_id, _id }) => {
      const genName = ({ title, sub_title, code, launch_date, end_date }) => {
        const dateInfo = compact([launch_date, end_date])
          .map(v => new Date(v).toLocaleDateString('zh-Hans-CN'))
          .join('~')
        return compact([
          compact([title, sub_title, code]).join(' ║ '),
          dateInfo,
        ]).join('　　')
      }
      const { loading, data } = useQuery(_queryProduct, {
        client: cceClient,
        variables: {
          ticket_ids: [ticket_id],
        },
        skip: !ticket_id,
      })

      const product_ids = useMemo(() => {
        if (!ticket_id) return []
        if (loading || !data) return null
        return [
          { id: null, name: '--無--' },
          ...data.queryProduct
            .filter(({ _id: docId }) => docId !== _id)
            .map(doc => ({
              ...doc,
              id: doc._id,
              name: genName(doc),
            })),
        ]
      }, [loading, data, ticket_id, _id])

      return product_ids
    },
  },
  user_guide: {
    label: '訪客指引',
    schema: NullableString.max(longString),
    type: 'string',
  },
}

addSchema({ productSchema }, '產品', namespaces.insurance.value)

export const ProductPropType = schemaToPropTypes(productSchema)
