import PropTypes from 'prop-types'
import 'styled-components/macro'

import { Label } from './StyledComps'

export function ToastifyLabel(props) {
  const { label } = props
  return <Label {...props}>{label}</Label>
}

ToastifyLabel.propTypes = {
  fieldname: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}
