import { useMemo, useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import 'styled-components/macro'
import {
  sum,
  size,
  map,
  filter,
  isEmpty,
  get,
  isNil,
  uniq,
  flatMap,
  toUpper,
  partial,
  head,
  trimEnd,
  toNumber,
  includes,
  isEqual,
} from 'lodash'
import { useQuery } from '@apollo/client'
import { Flex, Text, Box } from '@changingai/react-editor-common-component'
import remarkBreaks from 'remark-breaks'
import { IoMdOpen } from 'react-icons/io'
import { Button } from 'primereact/button'

import { PropCrud, ProjectContext } from '@common'
import {
  inputFactory,
  createSeparator,
  ButtonLike,
  ToastifyLabel,
  MarkDownEditor,
} from '@widget'
import { cceClient } from '@gql'

import { checkProduct } from './common'
import { productSchema, ProductPropType } from './schema'

import { SchemaBaseEditor } from '../SchemaBaseEditor'
import { getNamespaceByScopeType } from '../TicketHelper'
import { useCheckApi } from '../Validation'
import { useFetchFullDoc } from '../DocHelper'
import { DeclaredInterest } from './declaredInterests'

import {
  _queryProduct,
  _queryTickets,
  _queryPlanFeeTables,
  _findSimiliarDoc,
} from './editor.gql'

import { rules } from './rules'

function UserGuide({ fieldname, context, onChange, onDirty }) {
  const userGuide = useMemo(() => get(context, fieldname), [context, fieldname])

  const onContentChange = useCallback(
    value => {
      onDirty(fieldname, !isEqual(value, userGuide))
      onChange(fieldname, value)
    },
    [onChange, onDirty, userGuide, fieldname],
  )

  return (
    <Flex alignItems="center" justifyContent="center">
      <ToastifyLabel
        flex="1 200px"
        fieldname={fieldname}
        label={get(productSchema, `${fieldname}.label`)}
      />
      <Box width="85vw">
        <MarkDownEditor
          value={userGuide}
          onChange={onContentChange}
          previewPlugins={[remarkBreaks]}
        />
      </Box>
    </Flex>
  )
}

UserGuide.propTypes = {
  fieldname: PropTypes.string.isRequired,
  context: PropTypes.object.isRequired,
  onChange: PropTypes.object.isRequired,
  onDirty: PropTypes.func.isRequired,
}

function useQueryField(fieldname, doc, value) {
  const { loading, data } = useQuery(_findSimiliarDoc, {
    client: cceClient,
    variables: {
      docType: 'Product',
      value: value,
      field: fieldname,
      ticket_ids: [get(doc, 'ticket_id')],
    },
    skip: isEmpty(value) || isNil(get(doc, 'ticket_id')),
  })

  const linkTo = useCallback(id => `/insurance/productSchema/${id}`, [])

  return useMemo(() => {
    if (loading || isNil(get(data, 'findSimiliarDoc'))) return [null, linkTo]
    return [
      filter(
        get(data, 'findSimiliarDoc'),
        ({ uploader, id }) => uploader === doc.uploader && id !== doc._id,
      ),
      linkTo,
    ]
  }, [loading, data, doc, linkTo])
}

const useQueryTitle = partial(useQueryField, 'title')
const useQueryCode = partial(useQueryField, 'code')

function ReferenceTicket({ doc, scope_type, label, schema }) {
  const { namespace } = useContext(ProjectContext)
  const { loading, data } = useQuery(_queryTickets, {
    client: cceClient,
    variables: {
      filter: {
        scope_ids: [get(doc, '_id')],
        scope_type,
      },
      namespace: getNamespaceByScopeType(scope_type),
    },
  })
  const tickets = useMemo(() => {
    if (loading || !data) return null
    return filter(
      data.queryTickets,
      ticket => sum(map(ticket.meta.assignee_meta, 'own_docs')) > 0,
    )
  }, [loading, data])
  if (size(tickets) > 1)
    throw new Error(`There should be only one ${scope_type} ticket.`)

  const onOpen = useCallback(() => {
    window.open(
      `#/${[namespace, 'listview', schema, get(tickets, '0._id')].join('/')}`,
    )
  }, [namespace, schema, tickets])

  if (isEmpty(tickets)) return null
  return (
    <Flex width="50%" p="2" justifyContent="flex-start" alignItems="center">
      <Text fontSize="h1" color="danger">
        {`${label}：`}
      </Text>
      {get(tickets, '0.name')}
      <ButtonLike size={6} onClick={onOpen}>
        <IoMdOpen />
      </ButtonLike>
    </Flex>
  )
}

ReferenceTicket.propTypes = {
  doc: ProductPropType.isRequired,
  scope_type: PropTypes.string,
  label: PropTypes.string,
  schema: PropTypes.string.isRequired,
}

function useComputeProductRefernceCount(doc) {
  const { data: tickets } = useQuery(_queryTickets, {
    client: cceClient,
    variables: {
      filter: {
        scope_ids: [get(doc, '_id')],
      },
      namespace: toUpper('insurance'),
    },
  })

  const { loading, data: plans } = useQuery(_queryPlanFeeTables, {
    variables: {
      ticket_ids: [get(tickets, 'queryTickets.0._id')],
    },
    client: cceClient,
    skip: isNil(get(tickets, 'queryTickets.0')),
  })

  const planFeeTables = useMemo(() => {
    // There is no associated plan for products of the second asignee
    if (!loading && tickets && isEmpty(tickets.queryTickets)) return []
    return !loading && plans
      ? uniq(map(flatMap(plans.queryPlan, 'feetables'), 'feetable_id'))
      : null
  }, [loading, plans, tickets])

  return planFeeTables
}

function LoadingRateExtraction({ context, onChange, onDirty }) {
  const { loading } = context
  const loadingRates = useMemo(
    () =>
      isNil(loading)
        ? null
        : filter(
            map([...loading.matchAll(/[\d.]+%/g)], matched =>
              toNumber(trimEnd(head(matched), '%')),
            ),
            value => value >= 0 && value <= 100,
          ),
    [loading],
  )

  const onClick = useCallback(() => {
    if (isNil(loadingRates)) return
    // Extract numbers from percentage string
    onChange('loading_rates', loadingRates)
    onDirty('loading_rates', true)
  }, [loadingRates, onChange, onDirty])

  const canExtract = useMemo(
    () => size(loadingRates) == 1 || size(loadingRates) == 2,
    [loadingRates],
  )

  return (
    <Flex width="100px">
      <Button
        disabled={!canExtract}
        label="擷取費率"
        className="p-button-primary"
        onClick={onClick}
      />
    </Flex>
  )
}

LoadingRateExtraction.propTypes = {
  context: ProductPropType,
  onChange: PropTypes.func.isRequired,
  onDirty: PropTypes.func.isRequired,
}

export function ProductEditDialog(props) {
  const { editContext, setEditContext } = useFetchFullDoc(
    props.doc,
    productSchema,
    _queryProduct,
  )

  const checker = useCheckApi({ rules, externalAPI: checkProduct })
  const planFeeTables = useComputeProductRefernceCount(props.doc)

  const ReferenceTicketWrapper = useCallback(
    (scope_type, label, schema) => (
      <ReferenceTicket
        doc={props.doc}
        scope_type={scope_type}
        label={label}
        schema={schema}
      />
    ),
    [props.doc],
  )

  const contractItemRenderer = ({ id, name, approval_doc_no }, selections) => (
    <>
      <Box
        ml="2"
        flex="1 1"
        fontWeight={includes(selections, id) ? 'bold' : 'normal'}
      >
        {name}
      </Box>
      {approval_doc_no && (
        <Box
          ml="2"
          flex="1 1"
          fontWeight={includes(selections, id) ? 'bold' : 'normal'}
          color="electricBlue"
        >
          {approval_doc_no}
        </Box>
      )}
    </>
  )

  const tabs = useMemo(
    () => [
      {
        caption: 'Basic',
        configs: [
          {
            fieldname: 'reference_plan',
            widget: 'CustomizedInput',
            RenderComp: () =>
              ReferenceTicketWrapper('Plan', '參考計畫', 'planSchema'),
          },
          {
            fieldname: 'reference_feetable',
            widget: 'CustomizedInput',
            RenderComp: () =>
              ReferenceTicketWrapper(
                'FeeTable',
                '參考費率表',
                'feeTableSchema',
              ),
          },
          inputFactory(productSchema, 'company_id', { disabled: true }),
          inputFactory(productSchema, 'title', {
            useQuerySimiliar: partial(useQueryTitle, editContext),
          }),
          inputFactory(productSchema, 'sub_title'),
          inputFactory(productSchema, 'code', {
            useQuerySimiliar: partial(useQueryCode, editContext),
          }),
          inputFactory(productSchema, 'dm_url'),
          inputFactory(productSchema, 'brochure_url'),
          inputFactory(productSchema, 'product_urls'),
          inputFactory(productSchema, 'purchase_urls'),
          createSeparator({}),
          inputFactory(productSchema, 'launch_date'),
          inputFactory(productSchema, 'end_date'),
          inputFactory(productSchema, 'ancestor_id'),
          createSeparator({}),
          inputFactory(productSchema, 'is_additional', {
            inputAttributes: { width: '33%' },
            labelAttributes: {
              showOnRight: true,
            },
          }),
          inputFactory(productSchema, 'private', {
            inputAttributes: { width: '33%' },
            labelAttributes: {
              showOnRight: true,
            },
          }),
          inputFactory(productSchema, 'hide_info', {
            inputAttributes: { width: '33%' },
            labelAttributes: {
              showOnRight: true,
            },
          }),
        ],
      },
      {
        caption: 'Contract',
        configs: [
          inputFactory(productSchema, 'contract_ids', {
            filter: true,
            inputAttributes: {
              height: '600px',
            },
            itemRenderer: contractItemRenderer,
          }),
        ],
      },
      {
        caption: 'Attributes',
        configs: [
          inputFactory(productSchema, 'currency_ids', {
            inputAttributes: {
              width: '25%',
              height: '600px',
            },
            labelPosition: 'top',
          }),
          inputFactory(productSchema, 'channel_ids', {
            inputAttributes: {
              width: '25%',
              height: '560px',
            },
            enableSearch: false,
          }),
          inputFactory(productSchema, 'attribute_ids', {
            inputAttributes: {
              width: '25%',
              height: '600px',
            },
            labelPosition: 'top',
          }),
          inputFactory(productSchema, 'category_ids', {
            inputAttributes: {
              width: '25%',
              height: '600px',
            },
            labelPosition: 'top',
          }),
        ],
      },
      {
        caption: 'Detailed',
        configs: [
          inputFactory(productSchema, 'intro', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(productSchema, 'info_insure_year', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(productSchema, 'info_payment_year', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(productSchema, 'info_payment_method', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(productSchema, 'info_require_age', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(productSchema, 'info_require_occ', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(productSchema, 'info_require_amount', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(productSchema, 'fee_discount', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(productSchema, 'info_require_other', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(productSchema, 'loading', {
            inputAttributes: {
              multilines: true,
              width: 'calc(100% - 100px)',
            },
          }),
          {
            fieldname: 'loading_rate_suggestion',
            widget: 'CustomizedInput',
            RenderComp: LoadingRateExtraction,
          },
          inputFactory(productSchema, 'loading_rates'),
        ],
      },
      {
        caption: '宣告利率',
        configs: [
          {
            fieldname: 'declared_interests',
            widget: 'CustomizedInput',
            RenderComp: DeclaredInterest,
          },
        ],
      },
      {
        caption: '訪客指引(User Guide)',
        configs: [
          {
            fieldname: 'user_guide',
            widget: 'CustomizedInput',
            RenderComp: UserGuide,
          },
        ],
      },
      {
        caption: '其他',
        configs: [
          inputFactory(productSchema, 'search_codes', {
            inputAttributes: { width: '50%' },
          }),
        ],
      },
    ],
    [ReferenceTicketWrapper, editContext],
  )

  return (
    <SchemaBaseEditor
      {...props}
      currentSchema={productSchema}
      tabs={tabs}
      checker={checker}
      isReady={!isNil(planFeeTables) && !isNil(editContext)}
      editContext={editContext}
      setEditContext={setEditContext}
    />
  )
}

ProductEditDialog.propTypes = {
  doc: ProductPropType.isRequired,
  crud: PropCrud.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
}
