import { useState, useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import 'styled-components/macro'
import css from '@styled-system/css'
import { useDebounce } from '@changing-cc/hooks'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md'
import uuid from 'react-uuid'
import { get, isEmpty, partial, find, uniqBy, map, isNil, noop } from 'lodash'
import { Link } from 'react-router-dom'
import Joi from 'joi'

import { Box, Flex } from '@changingai/react-editor-common-component'
import theme from '@theme'

import { useInit, useError } from './common'
import { InputRoot, InputContainer, WidgetContainer } from './StyledComps'
import { ToastifyLabel } from './ToastifyLabel'
import { Checkbox } from './checkbox'

function defaultComputeRenderResult(value) {
  if (isEmpty(value)) return '空字串'

  const converted = parseFloat(value)
  if (isNaN(converted)) return '無法辨識'
  return `${(value * 100).toFixed(2)}% or ${value}`
}

function useDefaultQuerySimiliar() {
  return [null, noop]
}

function mapFieldTypeToKeyFilter(fieldname, currentSchema) {
  const keyFilterMap = {
    number: 'num',
    date: /^[\d/]+$/,
    string: null,
    integer: /^[-\d]+$/,
  }

  return get(keyFilterMap, get(currentSchema, `${fieldname}.type`), null)
}

export function SingleInput({
  fieldname,
  value,
  label,
  onChange,
  onDirty,
  onValid,
  currentSchema,
  editable = true,
  enableHint = true,
  hightlights = [],
  labelAttributes = { width: '150px' },
  inputAttributes = { width: '33%', multilines: false, rows: 1 },
  disabled = false,
  computeRenderResult = defaultComputeRenderResult,
  useQuerySimiliar = useDefaultQuerySimiliar,
}) {
  const [init, compare] = useInit(value)
  const [content, setContent] = useState(value)
  const debouncedText = useDebounce(content)
  const [id] = useState(uuid())
  const error = useError(onValid, id, fieldname, value, editable)
  const keyfilter = useMemo(
    () => mapFieldTypeToKeyFilter(fieldname, currentSchema),
    [fieldname, currentSchema],
  )
  useEffect(() => {
    if (editable) onDirty(id, compare(debouncedText))
    onChange(fieldname, debouncedText)
  }, [debouncedText, fieldname, compare, onDirty, onChange, id, editable])

  useEffect(() => {
    setContent(value)
  }, [setContent, value])

  useEffect(() => {
    if (!editable) {
      setContent(init)
      onChange(fieldname, init)
    }
  }, [editable, setContent, onChange, fieldname, init])

  useEffect(() => {
    return function cleanupDirtyAndError() {
      onDirty(id, false)
      onValid(fieldname, null, { clean: true, key: id })
    }
  }, [fieldname, onDirty, onValid, id])

  const renderResult = useMemo(() => computeRenderResult(value), [
    value,
    computeRenderResult,
  ])
  const showHighlight = hightlights.includes(fieldname)

  const computedHint = useMemo(() => {
    if (!enableHint) return
    function convertTypeToHint(currentSchema, fieldname) {
      if (get(currentSchema, `${fieldname}.type`) === 'number') {
        const fieldSchema = get(currentSchema, `${fieldname}.schema`)
        // The assumption here is not very precise. For more accurate
        // hint, please define hint prop in schema.
        if (
          get(fieldSchema.validate(-0.1), 'error') &&
          get(fieldSchema.validate(2), 'error')
        )
          return '0~1'
        if (get(fieldSchema.validate(-0.1), 'error')) return 'positive'
        if (get(fieldSchema.validate(0.1), 'error')) return 'negative'
      }
      const defaultHintMap = {
        number: 'number',
        bool: 'true/false',
        date: 'YYYY/MM/DD',
        string: 'string',
      }

      return get(
        defaultHintMap,
        get(currentSchema, `${fieldname}.type`, 'string'),
        'any',
      )
    }
    const hint = get(currentSchema, `${fieldname}.hint`)
    if (!isEmpty(hint)) return hint

    const joiSchema = get(currentSchema, `${fieldname}.schema`, Joi.string())
    const { error } = joiSchema.validate('')
    const allowEmptyString = isNil(error)
    return `${allowEmptyString ? 'empty/' : ''}${convertTypeToHint(
      currentSchema,
      fieldname,
    )}`
  }, [currentSchema, fieldname, enableHint])

  const [similiars, linkTo] = useQuerySimiliar(debouncedText)

  return (
    <WidgetContainer width={inputAttributes.width}>
      <InputRoot hightlight={showHighlight}>
        {!!label && (
          <ToastifyLabel
            fieldname={fieldname}
            label={label}
            error={error}
            changed={compare(content)}
            editable={editable}
            {...labelAttributes}
          />
        )}
        <Flex flexWrap="wrap" flex="1 0" data-cy={`SingleInput-${fieldname}`}>
          <InputContainer width="100%">
            {editable && (
              <span className="p-float-label">
                {inputAttributes.multilines && (
                  <InputTextarea
                    id={id}
                    value={content || ''}
                    keyfilter={keyfilter}
                    onChange={({ target: { value } }) => {
                      setContent(value)
                    }}
                    disabled={disabled}
                    rows={inputAttributes.rows}
                  />
                )}
                {!inputAttributes.multilines && (
                  <InputText
                    data-testid="SingleInput.input"
                    id={id}
                    value={content || ''}
                    keyfilter={keyfilter}
                    onChange={({ target: { value } }) => {
                      setContent(value)
                    }}
                    disabled={disabled}
                  />
                )}
                {computedHint && (
                  <label
                    style={{ color: 'teal' }}
                    htmlFor={id}
                    data-testid="HintLabel"
                  >
                    {computedHint}
                  </label>
                )}
                {get(currentSchema, `${fieldname}.type`) === 'number' && (
                  <Flex
                    position="absolute"
                    right="0"
                    top="0"
                    height="100%"
                    alignItems="center"
                    mx="2"
                    color="darkHotPinks.8"
                  >
                    {renderResult}
                  </Flex>
                )}
              </span>
            )}
          </InputContainer>
          {useQuerySimiliar !== useDefaultQuerySimiliar && (
            <Flex
              width="100%"
              mt="2"
              mb="1"
              mr="1"
              p="2"
              minHeight="1.5em"
              bg={isEmpty(similiars) ? 'transparent' : 'darkHotPinks.4'}
              border="1px solid"
              borderColor={
                isEmpty(similiars) ? 'transparent' : 'darkHotPinks.7'
              }
            >
              <Box mr="2" fontWeight="bold" color="blacks.5">
                類似：
              </Box>
              {isNil(similiars) && !isEmpty(value) && <Box>比對中...</Box>}
              <Flex flex="1 0">
                {map(uniqBy(similiars, 'value'), ({ id, value }) => (
                  <Box key={id} mr="3">
                    <Link
                      to={linkTo(id)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {value}
                    </Link>
                  </Box>
                ))}
              </Flex>
            </Flex>
          )}
        </Flex>
      </InputRoot>
    </WidgetContainer>
  )
}

SingleInput.propTypes = {
  fieldname: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  onChange: PropTypes.func.isRequired,
  onDirty: PropTypes.func.isRequired,
  onValid: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  enableHint: PropTypes.bool,
  hightlights: PropTypes.arrayOf(PropTypes.string),
  inputAttributes: PropTypes.object,
  labelAttributes: PropTypes.object,
  disabled: PropTypes.bool,
  currentSchema: PropTypes.object,
  computeRenderResult: PropTypes.func,
  useQuerySimiliar: PropTypes.func,
}

function computeRenderResultWithSuggestions(suggestions, value) {
  const matched = find(suggestions, { value })
  return get(matched, 'label') || defaultComputeRenderResult(value)
}

export function SingleInputWithSuggestions(props) {
  const { suggestions, value, editable, onChange } = props

  const [suggested, setSuggested] = useState(null)
  const onLocalChange = useCallback(
    (fieldname, value) => {
      if (value === suggested) setSuggested(null)
      onChange(fieldname, value)
    },
    [onChange, suggested, setSuggested],
  )

  const disabled = suggestedValue => value === suggestedValue
  return (
    <WidgetContainer
      width={get(props, 'inputAttributes.width', '33%')}
      flexWrap="wrap"
      css={css({
        justifyContent: 'flex-end',
        '&& .p-button.p-component': {
          py: 0,
          px: 1,
        },
      })}
    >
      <SingleInput
        {...props}
        value={suggested || value}
        onChange={onLocalChange}
        inputAttributes={{
          ...get(props, 'inputAttributes', { multilines: false }),
          width: '100%',
        }}
        computeRenderResult={partial(
          computeRenderResultWithSuggestions,
          suggestions,
        )}
      />
      {editable &&
        map(suggestions, ({ label, value }) => (
          <Button
            key={label}
            label={label}
            style={{ marginRight: '5px' }}
            className={
              disabled(value) ? 'p-button-secondary' : 'p-button-success'
            }
            onClick={() => {
              setSuggested(value)
            }}
            disabled={disabled(value)}
          />
        ))}
    </WidgetContainer>
  )
}

SingleInputWithSuggestions.propTypes = {
  ...SingleInput.propTypes,
  suggestions: PropTypes.array.isRequired,
}

export function SingleInputWithUrl(props) {
  const { onValid, value, fieldname } = props
  const [hasError, setHasError] = useState(null)
  useEffect(() => {
    setHasError(!onValid(fieldname, value))
  }, [fieldname, value, onValid])

  return (
    <WidgetContainer
      width={get(props, 'inputAttributes.width')}
      flexWrap="wrap"
      justifyContent="flex-end"
      alignItems="stretch"
    >
      <Flex width="100%">
        <SingleInput
          {...props}
          inputAttributes={{
            ...get(props, 'inputAttributes', { multilines: false }),
            width: '100%',
          }}
        />
        <Box width="8" py="1" height="100%">
          <Button
            icon="pi pi-link"
            onClick={() => window.open(props.value)}
            disabled={hasError || isEmpty(value)}
            style={{ height: '100%' }}
          />
        </Box>
      </Flex>
    </WidgetContainer>
  )
}

SingleInputWithUrl.propTypes = {
  ...SingleInput.propTypes,
}

export function SingleInputMergeWidget({
  fieldname,
  leftValue,
  rightValue,
  onChange,
  onValid,
  position,
  suggestions,
}) {
  const [localSelected, setLocalSelected] = useState(position)
  const [content, setContent] = useState(
    position === 'left' ? leftValue : rightValue,
  )

  useEffect(() => {
    onChange(fieldname, content)
    onValid(fieldname, content)
  }, [content, fieldname, onChange, onValid])

  return (
    <Flex width="100%" flexDirection="column">
      <Flex>
        <Flex flex="1 1" overflow="auto" alignItems="center">
          {leftValue}
        </Flex>
        <Checkbox
          icons={[MdRadioButtonChecked, MdRadioButtonUnchecked]}
          checked={localSelected === 'left'}
          onChecked={checked => {
            if (checked) {
              setLocalSelected('left')
              setContent(leftValue)
            }
          }}
          color={theme.colors.icon}
          style={{ flex: '0 1' }}
        />
        <Box width="1" bg="veryLightGrey" />
        <Checkbox
          icons={[MdRadioButtonChecked, MdRadioButtonUnchecked]}
          checked={localSelected === 'right'}
          onChecked={checked => {
            if (checked) {
              setLocalSelected('right')
              setContent(rightValue)
            }
          }}
          color={theme.colors.icon}
          style={{ flex: '0 1' }}
        />
        <Flex flex="1 1" overflow="auto" alignItems="center">
          {rightValue}
        </Flex>
      </Flex>
      <Box my="2">
        <InputTextarea
          style={{
            width: '100%',
            height: '200px',
            my: '2px',
          }}
          value={content}
          onChange={({ target: { value } }) => {
            setContent(value)
          }}
        />
        {suggestions &&
          suggestions.map(({ label, value }) => (
            <Button
              key={label}
              label={label}
              style={{ marginRight: '5px' }}
              onClick={() => {
                setContent(value)
              }}
            />
          ))}
      </Box>
    </Flex>
  )
}

SingleInputMergeWidget.propTypes = {
  fieldname: PropTypes.string.isRequired,
  leftValue: PropTypes.string.isRequired,
  rightValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onValid: PropTypes.func.isRequired,
  position: PropTypes.oneOf(['left', 'right']).isRequired,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
}
