import { useCallback, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { isNil, find } from 'lodash'

import {
  KG_NODES,
  ProjectContext,
  TicketPropType,
  TicketState,
  useDialog,
} from '@common'
import { MergeDialog } from '@dialog'
import { generateSubDocProps, mergeInputFactory } from '@widget'

import { CardRewardContext, useCardRewardContext } from './context'
import { useTranspile, useUploadCardReward } from './common'
import { CardRewardEditDialog, CardSet } from './editor'
import { rules } from './rules'
import { CardSetDiffLayout } from './diffLayout'
import { useFilterDocField } from './RewardUtils'
import { cardRewardSchema } from './schema'

import { useCompareController } from '../Compare'
import CompareView, { bindSchemaWithDiffLayout } from '../CompareView'
import { useCheckApi } from '../Validation'

const fullAccessStates = [TicketState.MERGE, TicketState.FINISH]

const primaryKeyRoot = KG_NODES.myfinance_editor.CARD_PRIMARY_KEY_ROOT_ID

const defaultPrimaryKeys = {
  name: 'default-match-rule',
  keys: [
    'type_id',
    'csp_channel_ids',
    'csp_target_ids',
    're_channel_ids',
    're_target_ids',
  ],
}

function CardRewardMergerDialog(props) {
  const { fieldname, ticket } = props
  const context = useContext(CardRewardContext)
  const { cardGroups } = context
  const cardGroup = useMemo(() => find(cardGroups, { id: ticket.scope_id }), [
    cardGroups,
    ticket.scope_id,
  ])

  const CardSetWrapper = useCallback(
    props => <CardSet {...props} cardGroup={cardGroup} />,
    [cardGroup],
  )

  const config = useMemo(() => {
    switch (fieldname) {
      case 'include_card_sets':
      case 'exclude_card_sets':
        return {
          ...generateSubDocProps(cardRewardSchema, fieldname),
          min: fieldname === 'include_card_sets' ? 1 : 0,
          showCaption: false,
          itemConfigs: [
            {
              widget: 'CustomizedInput',
              RenderComp: CardSetWrapper,
            },
          ],
        }
      default:
        return mergeInputFactory(cardRewardSchema, fieldname)
    }
  }, [fieldname, CardSetWrapper])
  return <MergeDialog config={config} {...props} />
}

CardRewardMergerDialog.propTypes = {
  fieldname: PropTypes.string.isRequired,
  ticket: TicketPropType.isRequired,
}

function useController(ticket) {
  const { email } = useContext(ProjectContext)
  const {
    docs,
    matched,
    uploaders,
    updateMatched,
    oneOneMapping,
    setOneOneMappting,
    computing,
    allPrimaryKeys,
    selectedPrimaryKeys,
    setSelectedPrimaryKeys,
  } = useCompareController(
    ticket,
    fullAccessStates,
    primaryKeyRoot,
    defaultPrimaryKeys,
    cardRewardSchema,
  )

  const [showDialog, renderEditor] = useDialog(CardRewardEditDialog)
  const showEditor = useCallback(
    (doc, crud) => {
      showDialog({
        doc,
        ticket,
        crud,
      })
    },
    [ticket, showDialog],
  )

  const upload = useUploadCardReward()
  const uploadDoc = useCallback(
    context => upload(ticket, context, email, true),
    [upload, ticket, email],
  )
  const context = useContext(CardRewardContext)
  const [showMergeDialog, renderMergeDialog] = useDialog(CardRewardMergerDialog)
  return {
    isReady: !isNil(docs) && context.isReady,
    docs,
    matched,
    uploaders,
    updateMatched,
    uploadDoc,
    oneOneMapping,
    setOneOneMappting,
    computing,
    showEditor,
    renderEditor,
    allPrimaryKeys,
    selectedPrimaryKeys,
    setSelectedPrimaryKeys,
    renderMergeDialog,
    showMergeDialog,
  }
}

bindSchemaWithDiffLayout(cardRewardSchema, {
  include_card_sets: {
    renderer: CardSetDiffLayout,
  },
  exclude_card_sets: {
    renderer: CardSetDiffLayout,
  },
})
export function CardRewardCompareView({ ticket }) {
  const context = useCardRewardContext()
  const checker = useCheckApi({
    rules,
    context,
    useDocFilter: useFilterDocField,
  })
  return (
    <CardRewardContext.Provider value={context}>
      <CompareView
        ticket={ticket}
        useController={useController}
        useTranspile={useTranspile}
        currentSchema={cardRewardSchema}
        checker={checker}
      />
    </CardRewardContext.Provider>
  )
}

CardRewardCompareView.propTypes = {
  ticket: TicketPropType.isRequired,
}

export default CardRewardCompareView
