import { useMemo } from 'react'
import Joi from 'joi'
import '@changing-cc/hooks'

import { KG_NODES, useKGChildren } from '@common'
import {
  NullableString,
  shortString,
  longString,
  RequiredKGNodeIdString,
  UniqueNodeArray,
  UniqueUrlArray,
} from './JoiSchema'
import { ticketDocSchema } from './Ticketable'

export const commonRewardSchema = {
  ...ticketDocSchema,
  type_id: {
    schema: RequiredKGNodeIdString,
    type: 'string',
    referToKG: 'card',
    label: '回饋類型',
  },
  csp_channel_ids: {
    schema: UniqueNodeArray,
    type: 'arrayOf(node)',
    referToKG: 'card',
    label: '消費通路',
  },
  re_channel_ids: {
    schema: UniqueNodeArray,
    type: 'arrayOf(node)',
    referToKG: 'card',
    label: '回饋通路',
  },
  csp_target_ids: {
    schema: UniqueNodeArray,
    type: 'arrayOf(node)',
    referToKG: 'card',
    label: '消費標的',
  },
  re_target_ids: {
    schema: UniqueNodeArray,
    type: 'arrayOf(node)',
    referToKG: 'card',
    label: '回饋標的',
  },
  exclude_list_ids: {
    schema: UniqueNodeArray,
    type: 'arrayOf(node)',
    referToKG: 'card',
    label: '排除通路/標的',
  },
  by_method_ids: {
    schema: UniqueNodeArray,
    type: 'arrayOf(node)',
    referToKG: 'card',
    label: '支付方式',
    useNodes: () => {
      const nodes = useKGChildren(KG_NODES.card.MOBILE_PAYMENT_RC_ID)
      const channels = useMemo(
        () =>
          !nodes
            ? null
            : nodes.filter(({ node_type }) => node_type === 'channel'),
        [nodes],
      )
      return channels
    },
  },
  data_source_urls: {
    schema: UniqueUrlArray.min(1),
    type: 'arrayOf(url)',
    label: '來源網址',
    multiInput: true,
  },
  special_day: {
    schema: NullableString.max(shortString),
    type: 'string',
    label: '特殊日限制',
  },
  need_registration: {
    schema: Joi.alternatives().try(
      Joi.string().valid('true', 'false'),
      Joi.bool(),
    ),
    type: 'bool',
    label: '需註冊',
  },
  identity_ids: {
    schema: UniqueNodeArray,
    type: 'arrayOf(node)',
    referToKG: 'card',
    label: '身份限定',
    useNodes: () => {
      const nodes = useKGChildren(KG_NODES.card.REWARD_IDENTIDY)
      const filtered = useMemo(
        () =>
          !nodes
            ? null
            : nodes.filter(({ id }) => id !== KG_NODES.card.REWARD_IDENTIDY),
        [nodes],
      )
      return filtered
    },
  },
  precondition: {
    schema: NullableString.max(shortString),
    type: 'string',
    label: '預先完成條件',
  },
  detail: {
    schema: NullableString.max(longString),
    type: 'string',
    label: '詳細內容',
  },
}
