import PropTypes from 'prop-types'
import { partial } from 'lodash'

import { namespace, schema } from './config'
import { CardGroupEditor } from './editor'
import { cardGroupSchema } from './schema'
import { EditorLoader } from '../SchemaBaseEditorLoader'
import { getNoTicketDocPath } from '../Common'
import { useFetchDoc } from '../DocHelper'
import { referenceDoc } from '../Reference'
import { _queryCardGroup } from './loader.gql'

const getPath = partial(getNoTicketDocPath, namespace, schema)
export const queryManifest = {
  gql: _queryCardGroup,
  name: 'queryCardGroup',
  getPath,
}

export const generateReferenceDoc = partial(
  referenceDoc,
  'card_name',
  getPath,
  schema,
  namespace,
)

function CardGroupEditorLoader({ docId }) {
  return (
    <EditorLoader
      DialogComp={CardGroupEditor}
      useFetchDocAndTicket={partial(
        useFetchDoc,
        _queryCardGroup,
        'queryCardGroup',
        docId,
      )}
      currentSchema={cardGroupSchema}
    />
  )
}

CardGroupEditorLoader.propTypes = {
  docId: PropTypes.string.isRequired,
}

export default CardGroupEditorLoader
