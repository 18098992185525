import { useState, useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { InputText } from 'primereact/inputtext'
import { MultiSelect } from 'primereact/multiselect'
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md'
import { compact, map, find, get, join, isEmpty } from 'lodash'
import { Box, Flex } from '@changingai/react-editor-common-component'
import theme from '@theme'

import { Checkbox } from './checkbox'
import { _getUploadUrl } from './common.gql'

export function useUploadFile() {
  const [getUploadURL] = useMutation(_getUploadUrl)
  const uploadBlob = useCallback(
    async (filename, blob, mimetype) => {
      const {
        data: {
          getUploadUrl: { signedUrl, url },
        },
      } = await getUploadURL({
        variables: {
          filename,
          mimetype,
        },
      })
      await fetch(signedUrl, {
        method: 'PUT',
        mode: 'cors',
        body: blob,
      })

      return url
    },
    [getUploadURL],
  )

  return uploadBlob
}

export const NodesType = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string,
    node_id: PropTypes.string,
  }),
)

// Used to store the initial data, so that we can compare state
// change of that data later.
export function useInit(stored) {
  const [init] = useState(stored)

  // Quite limited. We can compare only scalar types, such as number and
  // string and array of scalar types. The result of passing an object to
  // this function is not predictable.
  //
  // Return true if we detect init & current are different
  const compare = useCallback(
    current => {
      if (Array.isArray(init) && Array.isArray(current)) {
        if (init.length !== current.length) return true
        return init.some((item, index) => current[index] !== item)
      }

      return init !== current
    },
    [init],
  )

  return [init, compare]
}

export function useError(onValid, key, fieldname, value, editable) {
  const [error, setError] = useState(false)
  useEffect(() => {
    setError(
      !onValid(fieldname, value, !editable ? { key, force: false } : { key }),
    )
  }, [setError, onValid, key, fieldname, value, editable])

  return error
}

export function useMultiSelectColumnFilter(
  tableRef,
  colName,
  options,
  filter = false,
) {
  const [selected, setSelected] = useState([])

  return useMemo(
    () => (
      <MultiSelect
        filter={filter}
        style={{ width: '100%' }}
        value={selected}
        options={options}
        onChange={({ value }) => {
          setSelected(value)
          tableRef.current.filter(value, colName, 'in')
        }}
        placeholder="All"
        className="p-column-filter"
      />
    ),
    [colName, options, tableRef, selected, setSelected, filter],
  )
}

// In primereact 5.0.2, datatable inputtext filter cannot type in chinese
// So we need a custom column filter component to prevent the problem
export function useColumnFilter(ref, colName) {
  const [value, setValue] = useState('')

  return useMemo(
    () => (
      <InputText
        value={value}
        onChange={({ target: { value } }) => {
          setValue(value)
          if (!ref.current) return
          ref.current.filter(value, colName, 'contains')
        }}
        style={{ width: '100%' }}
      />
    ),
    [ref, colName, value],
  )
}

export function SelectionList({ items, selected }) {
  const selectedNames = compact(
    map(selected, id => get(find(items, { id }), 'name')),
  )
  return (
    <Flex p="1" my="1" border="1px solid" borderColor="blacks.1" width="100%">
      {isEmpty(selectedNames) ? (
        <Box color="gray">無選取</Box>
      ) : (
        <Box color="azure">{join(selectedNames, ' /')}</Box>
      )}
    </Flex>
  )
}

SelectionList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  selected: PropTypes.arrayOf(PropTypes.string),
}

export function DefaultMergeWidget({
  fieldname,
  leftValue,
  rightValue,
  onChange,
  onValid,
  position,
}) {
  const [localSelected, setLocalSelected] = useState(position)
  const [content, setContent] = useState(
    position === 'left' ? leftValue : rightValue,
  )

  useEffect(() => {
    onChange(fieldname, content)
    onValid(fieldname, content)
  }, [content, fieldname, onChange, onValid])

  return (
    <Flex width="100%">
      <Flex flex="1 1" overflow="auto" alignItems="center">
        {JSON.stringify(leftValue)}
      </Flex>
      <Checkbox
        icons={[MdRadioButtonChecked, MdRadioButtonUnchecked]}
        checked={localSelected === 'left'}
        onChecked={checked => {
          if (checked) {
            setLocalSelected('left')
            setContent(leftValue)
          }
        }}
        color={theme.colors.icon}
        style={{ flex: '0 1' }}
      />
      <Box width="1" bg="veryLightGrey" />
      <Checkbox
        icons={[MdRadioButtonChecked, MdRadioButtonUnchecked]}
        checked={localSelected === 'right'}
        onChecked={checked => {
          if (checked) {
            setLocalSelected('right')
            setContent(rightValue)
          }
        }}
        color={theme.colors.icon}
        style={{ flex: '0 1' }}
      />
      <Flex flex="1 1" overflow="auto" alignItems="center">
        {JSON.stringify(rightValue)}
      </Flex>
    </Flex>
  )
}

DefaultMergeWidget.propTypes = {
  fieldname: PropTypes.string.isRequired,
  leftValue: PropTypes.any.isRequired,
  rightValue: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  onValid: PropTypes.func.isRequired,
  position: PropTypes.oneOf(['left', 'right']).isRequired,
}
