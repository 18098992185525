import { useState, forwardRef } from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import ReactMde from 'react-mde'
import 'react-mde/lib/styles/css/react-mde-all.css'

const TextAreaComponent = forwardRef(function TextArea(props, ref) {
  return (
    <textarea
      {...props}
      spellCheck="false"
      ref={ref}
      style={{
        fontSize: '1rem',
        height: '200px',
        width: '100%',
        border: '0',
        padding: '10px',
        verticalAlign: 'top',
        resize: 'vertical',
        overflowY: 'auto',
      }}
    />
  )
})

export function MarkDownEditor({
  value,
  onChange,
  previewRenderers,
  previewPlugins,
  ...props
}) {
  const [tab, setTab] = useState('write')

  return (
    <ReactMde
      value={value}
      onChange={onChange}
      selectedTab={tab}
      onTabChange={setTab}
      generateMarkdownPreview={markdown =>
        Promise.resolve(
          <ReactMarkdown plugins={previewPlugins} renderers={previewRenderers}>
            {markdown}
          </ReactMarkdown>,
        )
      }
      textAreaComponent={TextAreaComponent}
      {...props}
    />
  )
}

MarkDownEditor.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  previewRenderers: PropTypes.object,
  previewPlugins: PropTypes.array,
}
