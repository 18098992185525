import { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'primereact/dropdown'
import {
  escapeRegExp,
  every,
  filter,
  get,
  includes,
  intersection,
  isEmpty,
  isNil,
  isNull,
  last,
  map,
  some,
  sortBy,
  uniq,
} from 'lodash'
import { Box, Flex } from '@changingai/react-editor-common-component'

import { iterateDoc, toEditableValue, purifySchemaDoc } from '@schema'
import { computeType } from '@common'

export function useFilterAndSortDocs({
  sortByField,
  filters,
  transpiledDocs,
  selectedFields,
  tagMap,
  includeTags,
  excludeTags,
  currentSchema,
  regExpMode,
  andGate,
}) {
  const filterResult = useMemo(
    () =>
      filter(transpiledDocs, doc => {
        // 1. Filters
        const result = map(filters, ({ field, value: filterValue }) => {
          // generic filter
          if (isNull(field)) {
            const checkFields = uniq([...selectedFields, '_id'])
            return some(
              [...iterateDoc(doc, currentSchema)],
              ([ancestors, fieldValue]) => {
                return (
                  !!fieldValue &&
                  includes(checkFields, last(ancestors)) &&
                  RegExp(
                    regExpMode ? filterValue : escapeRegExp(filterValue),
                    'ig',
                  ).test(fieldValue)
                )
              },
            )
          }
          // field filter
          const fieldValue = get(doc, field)
          const { type: fieldType } = get(currentSchema, field)

          if (fieldType === 'date') {
            const actualTime = new Date(fieldValue).getTime()
            const [, op, date] = filterValue.match(/(<=|>=)?(.*)/)
            // Transform input date string into ISO 8061 format, so that Javascript.Date
            // will treat it as UTC date.
            const filterTime = new Date(date.replace(/\//g, '-')).getTime()

            if (op) {
              return op === '<='
                ? actualTime <= filterTime
                : actualTime >= filterTime
            }
          }

          return RegExp(
            regExpMode ? filterValue : escapeRegExp(filterValue),
            'ig',
          ).test(toEditableValue(field, fieldValue, currentSchema))
        })

        if (!isEmpty(result) && !(andGate ? every(result) : some(result)))
          return false

        // 2. tag filter.
        const tags = get(tagMap, doc._id, [])
        const notIncluded =
          !isEmpty(includeTags) && isEmpty(intersection(tags, includeTags))
        const excluded = !isEmpty(intersection(tags, excludeTags))

        if (notIncluded || excluded) return false

        // Pass all filters, this doc is ready to be rendered.
        return true
      }),
    [
      filters,
      transpiledDocs,
      selectedFields,
      tagMap,
      includeTags,
      excludeTags,
      currentSchema,
      regExpMode,
      andGate,
    ],
  )

  const sortedResult = useMemo(() => {
    const sorted = sortByField
      ? sortBy(map(filterResult, doc => purifySchemaDoc(doc, currentSchema)), [
          sortByField,
        ])
      : filterResult
    return map(sorted, '_id')
  }, [filterResult, sortByField, currentSchema])

  return sortedResult
}

export function SortByField({ onSortField, currentSchema }) {
  const [selected, setSelected] = useState()
  const options = useMemo(() => {
    return Object.keys(currentSchema)
      .filter(fieldname =>
        isNil(computeType(currentSchema[fieldname].type).extractedSchema),
      )
      .map(fieldname => ({
        label: currentSchema[fieldname].label,
        value: fieldname,
      }))
  }, [currentSchema])

  return (
    <Flex width="100%" alignItems="center" justifyContent="flex-end">
      <Box mx="2">Sort By:</Box>
      <Dropdown
        value={selected}
        options={options}
        style={{ flex: '0 0 50%' }}
        onChange={({ value }) => {
          setSelected(value)
          onSortField(value)
        }}
        filter={true}
      />
    </Flex>
  )
}

SortByField.propTypes = {
  onSortField: PropTypes.func.isRequired,
  currentSchema: PropTypes.object.isRequired,
}
