import {
  isEmpty,
  isNil,
  difference,
  map,
  includes,
  some,
  every,
  negate,
} from 'lodash'

import { KG_NODES, rewardNameToID, ValidationCategory as State } from '@common'
import { kgClient } from '@gql'

import { cardRewardSchema } from './schema'

import { _query_kg, _get_descendant } from './rules.gql'

export const rules = [
  // basic_r rules
  ({ type_id, basic_r }) => {
    const summary = `當[${cardRewardSchema.basic_r.label}]為現金回饋、現金回饋-級距、類現金回饋(非現金但兌換比例為1:1)、類現金回饋-級距、紅利回饋、紅利回饋-級距時，[${cardRewardSchema.basic_r.label}]大於等於0且小於等於1`
    const types = rewardNameToID([
      '現金回饋',
      '現金回饋-級距',
      '類現金回饋(非現金但兌換比例為1:1)',
      '類現金回饋-級距',
      '紅利回饋',
      '紅利回饋-級距',
    ])
    return {
      fieldname: cardRewardSchema.basic_r.label,
      result:
        !types.includes(type_id) ||
        (!isNil(basic_r) && basic_r >= 0 && basic_r <= 1)
          ? []
          : [[State.Error, summary]],
    }
  },
  ({ type_id, basic_r }) => {
    const summary = `當[${cardRewardSchema.type_id.label}]為現金回饋-滿額、紅利回饋-定額、類現金回饋-滿額、里程回饋-定額、首刷禮-現金回饋、首刷禮-類現金回饋、首刷禮-紅利回饋、首刷禮-里程數時，[${cardRewardSchema.basic_r.label}]為大於1之正整數`
    const types = rewardNameToID([
      '現金回饋-滿額',
      '紅利回饋-定額',
      '類現金回饋-滿額',
      '里程回饋-定額',
      '首刷禮-現金回饋',
      '首刷禮-類現金回饋',
      '首刷禮-紅利回饋',
      '首刷禮-里程數',
    ])
    return {
      fieldname: cardRewardSchema.basic_r.label,
      result:
        !types.includes(type_id) || (basic_r > 1 && basic_r % 1 === 0)
          ? []
          : [[State.Error, summary]],
    }
  },
  ({ type_id, basic_r }) => {
    const summary = `當[${cardRewardSchema.type_id.label}]為里程回饋時，[${cardRewardSchema.basic_r.label}]大於1`
    const types = rewardNameToID(['里程回饋'])
    return {
      fieldname: cardRewardSchema.basic_r.label,
      result:
        types.includes(type_id) && basic_r <= 1 ? [[State.Error, summary]] : [],
    }
  },
  ({ type_id, basic_r }) => {
    const summary = `當[${cardRewardSchema.type_id.label}]為分期期數時，[${cardRewardSchema.basic_r.label}]為大於2且小於40之正整數`
    const types = rewardNameToID(['分期期數'])
    return {
      fieldname: cardRewardSchema.basic_r.label,
      result:
        !types.includes(type_id) ||
        (basic_r > 2 && basic_r < 40 && basic_r % 1 === 0)
          ? []
          : [[State.Error, summary]],
    }
  },
  ({ type_id, basic_r }) => {
    const summary = `當[${cardRewardSchema.type_id.label}]為打折優惠、機場接送-打折、公益提撥、旅遊平安險-打折時時，[${cardRewardSchema.basic_r.label}]大於0且小於1`
    const types = rewardNameToID([
      '打折優惠',
      '機場接送-打折',
      '公益提撥',
      '旅遊平安險-打折',
    ])
    return {
      fieldname: cardRewardSchema.basic_r.label,
      result:
        !types.includes(type_id) || (basic_r > 0 && basic_r < 1)
          ? []
          : [[State.Error, summary]],
    }
  },
  ({ type_id, basic_r }) => {
    const summary = `當[${cardRewardSchema.type_id.label}]為旅遊不便險時，[${cardRewardSchema.basic_r.label}]為大於10,000且小於10,000,000之間的正整數`
    const types = rewardNameToID(['旅遊不便險'])
    return {
      fieldname: cardRewardSchema.basic_r.label,
      result:
        !types.includes(type_id) ||
        (basic_r > 10000 && basic_r < 10000000 && basic_r % 1 === 0)
          ? []
          : [[State.Error, summary]],
    }
  },
  ({ type_id, basic_r }) => {
    const summary = `當[${cardRewardSchema.type_id.label}]為旅遊平安險時，[${cardRewardSchema.basic_r.label}]為小於等於8000萬的正整數`
    const types = rewardNameToID(['旅遊平安險'])
    return {
      fieldname: cardRewardSchema.basic_r.label,
      result:
        !types.includes(type_id) ||
        (!isNil(basic_r) &&
          basic_r > 0 &&
          basic_r <= 80000000 &&
          basic_r % 1 === 0)
          ? []
          : [[State.Error, summary]],
    }
  },
  ({ type_id, basic_r }) => {
    const summary = `當[${cardRewardSchema.type_id.label}]為道路救援、道路救援-紅利折抵時，[${cardRewardSchema.basic_r.label}]為20/30/50/100/99999(不限次)`
    const types = rewardNameToID(['道路救援', '道路救援-紅利折抵'])
    return {
      fieldname: cardRewardSchema.basic_r.label,
      result:
        types.includes(type_id) && !includes([20, 30, 50, 100, 99999], basic_r)
          ? [[State.Error, summary]]
          : [],
    }
  },
  ({ type_id, basic_r }) => {
    const summary = `當[${cardRewardSchema.type_id.label}]為機場接送、機場貴賓室、機場接送-消費額折抵時，[${cardRewardSchema.basic_r.label}]為大於等於1且小於等於20之間的正整數，或是99999(不限次)`
    const types = rewardNameToID([
      '機場接送',
      '機場貴賓室',
      '機場接送-消費額折抵',
    ])
    return {
      fieldname: cardRewardSchema.basic_r.label,
      result:
        !types.includes(type_id) ||
        (basic_r % 1 === 0 &&
          ((basic_r >= 1 && basic_r <= 20) || basic_r === 99999))
          ? []
          : [[State.Error, summary]],
    }
  },
  ({ type_id, basic_r }) => {
    const summary = `當[${cardRewardSchema.type_id.label}]為機場接送-指定價格時，[${cardRewardSchema.basic_r.label}]為大於200且小於1000之正整數`
    const types = rewardNameToID(['機場接送-指定價格'])
    return {
      fieldname: cardRewardSchema.basic_r.label,
      result:
        !types.includes(type_id) ||
        (basic_r > 200 && basic_r < 1000 && basic_r % 1 === 0)
          ? []
          : [[State.Error, summary]],
    }
  },
  ({ type_id, basic_r }) => {
    const summary = `當[${cardRewardSchema.type_id.label}]為機場貴賓室-指定價格時，[${cardRewardSchema.basic_r.label}]為大於100且小於1000之正整數`
    const types = rewardNameToID(['機場貴賓室-指定價格'])
    return {
      fieldname: cardRewardSchema.basic_r.label,
      result:
        !types.includes(type_id) ||
        (basic_r > 100 && basic_r < 1000 && basic_r % 1 === 0)
          ? []
          : [[State.Error, summary]],
    }
  },
  ({ type_id, basic_r }) => {
    const summary = `當[${cardRewardSchema.type_id.label}]為道路救援-指定價格時，[${cardRewardSchema.basic_r.label}]為大於100且小於等於1500之正整數`
    const types = rewardNameToID(['道路救援-指定價格'])
    return {
      fieldname: cardRewardSchema.basic_r.label,
      result:
        !types.includes(type_id) ||
        (basic_r > 100 && basic_r <= 1500 && basic_r % 1 === 0)
          ? []
          : [[State.Error, summary]],
    }
  },
  ({ type_id, basic_r }) => {
    const summary = `當[${cardRewardSchema.type_id.label}]為加油折價時，[${cardRewardSchema.basic_r.label}]大於0`
    const types = rewardNameToID(['加油折價'])
    return {
      fieldname: cardRewardSchema.basic_r.label,
      result:
        types.includes(type_id) && basic_r <= 0 ? [[State.Error, summary]] : [],
    }
  },
  ({ type_id, basic_r }) => {
    const summary = `當[${cardRewardSchema.type_id.label}]為首刷禮-行李箱時，[${cardRewardSchema.basic_r.label}]大於10且小於30`
    const types = rewardNameToID(['首刷禮-行李箱'])
    return {
      fieldname: cardRewardSchema.basic_r.label,
      result:
        !types.includes(type_id) || (basic_r > 10 && basic_r < 30)
          ? []
          : [[State.Error, summary]],
    }
  },
  ({ type_id, basic_r }) => {
    const summary = `當[${cardRewardSchema.type_id.label}]為定價優惠、折價優惠時，[${cardRewardSchema.basic_r.label}]為大於1之正整數`
    const types = rewardNameToID(['定價優惠', '折價優惠'])
    return {
      fieldname: cardRewardSchema.basic_r.label,
      result:
        !types.includes(type_id) || (basic_r > 1 && basic_r % 1 === 0)
          ? []
          : [[State.Error, summary]],
    }
  },
  ({ type_id, basic_r }) => {
    const summary = `當[${cardRewardSchema.type_id.label}]為打折優惠時，[${cardRewardSchema.basic_r.label}]大於0且小於等於0.9`
    const types = rewardNameToID(['打折優惠'])
    return {
      fieldname: cardRewardSchema.basic_r.label,
      result:
        !types.includes(type_id) || (basic_r > 0 && basic_r <= 0.9)
          ? []
          : [[State.Error, summary]],
    }
  },
  ({ type_id, basic_r }) => {
    const summary = `打折優惠 基本回饋為 0.6時，前端顯示 4折；
      打折優惠 基本回饋為 0.7時，前端顯示 3折；
      打折優惠 基本回饋為 0.8時，前端顯示 2折；
      打折優惠 基本回饋為 0.9時，前端顯示 1折；
      請再次確認資料填寫正確。`
    const types = rewardNameToID(['打折優惠'])
    return {
      fieldname: cardRewardSchema.basic_r.label,
      result:
        types.includes(type_id) && (basic_r >= 0.6 && basic_r <= 0.9)
          ? [[State.Warning, summary]]
          : [],
    }
  },
  ({ type_id, re_upper_bound }) => {
    const summary = `當 [${cardRewardSchema.type_id.label}] 不為現金回饋、現金回饋-級距、紅利回饋、紅利回饋-級距、里程回饋、打折優惠、類現金回饋(非現金但兌換比例為1:1)、類現金回饋-級距時，[${cardRewardSchema.re_upper_bound.label}] 必為empty：`
    const types = rewardNameToID([
      '現金回饋',
      '現金回饋-級距',
      '紅利回饋',
      '紅利回饋-級距',
      '里程回饋',
      '打折優惠',
      '類現金回饋(非現金但兌換比例為1:1)',
      '類現金回饋-級距',
    ])
    return {
      fieldname: cardRewardSchema.basic_r.label,
      result:
        types.includes(type_id) || isNil(re_upper_bound)
          ? []
          : [[State.Error, summary]],
    }
  },
  ({ type_id, basic_r }) => {
    const summary = `當 [${cardRewardSchema.type_id.label}] 為機場接送、機場貴賓室時，[${cardRewardSchema.basic_r.label}] 必不等於365`
    const types = rewardNameToID(['機場接送', '機場貴賓室'])
    return {
      fieldname: cardRewardSchema.basic_r.label,
      result:
        types.includes(type_id) && basic_r === 365
          ? [[State.Error, summary]]
          : [],
    }
  },
  ({ type_id, re_target_ids }) => {
    const names = [
      '紅利回饋',
      '紅利回饋-定額',
      '紅利回饋-級距',
      '其他',
      '首刷禮-其他',
      '首刷禮-類現金回饋',
      '類現金回饋(非現金但兌換比例為1:1)',
      '類現金回饋-級距',
      '類現金回饋-滿額',
    ]
    const types = rewardNameToID(names)
    const summary = `當 [${cardRewardSchema.type_id.label}] 為${names.join(
      '、',
    )}時，[${cardRewardSchema.re_target_ids.label}] 必填`
    return {
      fieldname: cardRewardSchema.re_target_ids.label,
      result:
        !types.includes(type_id) || !isEmpty(re_target_ids)
          ? []
          : [[State.Error, summary]],
    }
  },
  ({ re_channel_ids }) => {
    const summary = `[${cardRewardSchema.re_channel_ids.label}] 必不為以下值：一般、一般_海外、實體、實體_海外、網購、網購_海外`
    const nodes = [
      KG_NODES.card.GENERAL_ID,
      KG_NODES.card.GENERAL_OVERSEAS_ID,
      KG_NODES.card.REAL_ID,
      KG_NODES.card.REAL_OVERSEAS_ID,
      KG_NODES.card.ONLINE_ID,
      KG_NODES.card.ONLINE_OVERSEAS_ID,
    ]
    return {
      fieldname: cardRewardSchema.re_channel_ids.label,
      result: some(nodes, id => re_channel_ids.includes(id))
        ? [[State.Error, summary]]
        : [],
    }
  },
  ({ type_id, re_channel_ids }) => {
    const summary = `當 [${cardRewardSchema.type_id.label}] 為市區停車、市區停車-紅利折抵、里程回饋、里程回饋-定額、首刷禮-里程數時，[${cardRewardSchema.re_channel_ids.label}] 必填`
    const types = rewardNameToID([
      '市區停車',
      '市區停車-紅利折抵',
      '里程回饋',
      '里程回饋-定額',
      '首刷禮-里程數',
    ])
    return {
      fieldname: cardRewardSchema.re_channel_ids.label,
      result:
        types.includes(type_id) && isEmpty(re_channel_ids)
          ? [[State.Error, summary]]
          : [],
    }
  },
  async ({ type_id, re_channel_ids, by_method_ids }) => {
    const summary = `當 [${cardRewardSchema.type_id.label}] 為現金回饋、類現金回饋(非現金但兌換比例為1:1)、類現金回饋-級距、類現金回饋-滿額、紅利回饋、打折優惠，且${cardRewardSchema.by_method_ids.label}不為空，[${cardRewardSchema.re_channel_ids.label}] 只允許特定通路`
    const types = rewardNameToID([
      '現金回饋',
      '類現金回饋(非現金但兌換比例為1:1)',
      '類現金回饋-級距',
      '類現金回饋-滿額',
      '紅利回饋',
      '打折優惠',
    ])

    if (!types.includes(type_id) || isEmpty(by_method_ids))
      return { result: [] }
    const {
      data: { get_descendant },
    } = await kgClient.query({
      query: _get_descendant,
      variables: {
        node_id: KG_NODES.card.REWARD_CHNNAEL_BY_PAYMETHOD,
      },
    })

    return {
      fieldname: cardRewardSchema.re_channel_ids.label,
      result: !isEmpty(
        difference(re_channel_ids, map(get_descendant, 'node_id')),
      )
        ? [[State.Error, summary]]
        : [],
    }
  },
  ({ type_id, csp_channel_ids }) => {
    const summary = `當 [${cardRewardSchema.type_id.label}] 為打折優惠時，[${cardRewardSchema.csp_channel_ids.label}] 不可為以下值：一般、一般_海外、實體、實體_海外、網購、網購_海外`
    const reward = rewardNameToID(['打折優惠'])[0]
    const nodes = [
      KG_NODES.card.GENERAL_ID,
      KG_NODES.card.GENERAL_OVERSEAS_ID,
      KG_NODES.card.REAL_ID,
      KG_NODES.card.REAL_OVERSEAS_ID,
      KG_NODES.card.ONLINE_ID,
      KG_NODES.card.ONLINE_OVERSEAS_ID,
    ]
    return {
      fieldname: cardRewardSchema.csp_channel_ids.label,
      result:
        reward === type_id && some(nodes, id => csp_channel_ids.includes(id))
          ? [[State.Error, summary]]
          : [],
    }
  },
  ({ type_id, csp_channel_ids }) => {
    const summary = `當 [${cardRewardSchema.type_id.label}] 為旅遊不便險、道路救援、旅遊平安險、機場接送、機場接送-打折、機場接送-指定價格、機場貴賓室、機場貴賓室-指定價格、機場停車時，[${cardRewardSchema.csp_channel_ids.label}] 必為空`
    const types = rewardNameToID([
      '旅遊不便險',
      '道路救援',
      '旅遊平安險',
      '機場接送',
      '機場接送-打折',
      '機場接送-指定價格',
      '機場貴賓室',
      '機場貴賓室-指定價格',
      '機場停車',
    ])
    return {
      fieldname: cardRewardSchema.csp_channel_ids.label,
      result:
        types.includes(type_id) && !isEmpty(csp_channel_ids)
          ? [[State.Error, summary]]
          : [],
    }
  },
  async ({ type_id, csp_channel_ids, csp_target_ids }) => {
    const summary = `當 [${cardRewardSchema.type_id.label}] 為現金回饋、現金回饋-級距、現金回饋-滿額、類現金回饋(非現金但兌換比例為1:1)、類現金回饋-級距、類現金回饋-滿額、打折優惠、紅利回饋、紅利回饋-級距、里程回饋、定價優惠、折價優惠、分期期數、公益提撥、公益提撥-定額、加油折價，[${cardRewardSchema.csp_channel_ids.label}] 必不為空`
    const types = rewardNameToID([
      '現金回饋',
      '現金回饋-級距',
      '現金回饋-滿額',
      '類現金回饋(非現金但兌換比例為1:1)',
      '類現金回饋-級距',
      '類現金回饋-滿額',
      '打折優惠',
      '紅利回饋',
      '紅利回饋-級距',
      '里程回饋',
      '定價優惠',
      '折價優惠',
      '分期期數',
      '公益提撥',
      '公益提撥-定額',
      '加油折價',
    ])

    const nochannelok = await (async () => {
      if (!!csp_target_ids && csp_target_ids.length > 0) {
        const {
          data: { query_kg },
        } = await kgClient.query({
          query: _query_kg,
          variables: {
            query: {
              op: 'OR',
              conditions: [
                {
                  op: 'includes',
                  field: 'node_id',
                  values: csp_target_ids,
                },
              ],
            },
          },
        })
        return query_kg.every(({ tags }) => tags.includes('nochannelok'))
      }
      return false
    })()

    return {
      fieldname: cardRewardSchema.csp_channel_ids.label,
      result:
        types.includes(type_id) && !nochannelok && isEmpty(csp_channel_ids)
          ? [[State.Error, summary]]
          : [],
    }
  },
  ({ type_id, csp_target_ids }) => {
    const summary = `當[${cardRewardSchema.type_id.label}]為旅遊不便險、道路救援、旅遊平安險、機場接送、機場接送-打折、機場接送-指定價格、機場貴賓室、機場貴賓室-指定價格、機場停車時，[${cardRewardSchema.csp_target_ids.label}]必為[國際線機票]或[國外旅行團費]或[旅行團費]或[機票]：`
    const types = rewardNameToID([
      '旅遊不便險',
      '道路救援',
      '旅遊平安險',
      '機場接送',
      '機場接送-打折',
      '機場接送-指定價格',
      '機場貴賓室',
      '機場貴賓室-指定價格',
      '機場停車',
    ])
    const allowed = [
      KG_NODES.card.INTERNATIONAL_TICKET_ID,
      KG_NODES.card.INTERNATIONAL_TRAVEL_FEE_ID,
      KG_NODES.card.TRAVEL_FEE_ID,
      KG_NODES.card.TICKET_ID,
    ]
    return {
      fieldname: cardRewardSchema.csp_target_ids.label,
      result:
        types.includes(type_id) &&
        !csp_target_ids.every(id => allowed.includes(id))
          ? [[State.Error, summary]]
          : [],
    }
  },
  ({ type_id, free_parking_hrs, free_parking_times }, { rewardTypes }) => {
    const summary = `當 [${cardRewardSchema.type_id.label}] 為${Object.values(
      rewardTypes,
    )
      .filter(({ tags }) => tags.includes('parking'))
      .map(({ name }) => name)
      .join('、')}時，[${cardRewardSchema.free_parking_hrs.label}] [${
      cardRewardSchema.free_parking_times.label
    }] 必填`
    const types = Object.values(rewardTypes)
      .filter(({ tags }) => tags.includes('parking'))
      .map(({ node_id }) => node_id)
    return {
      fieldname: cardRewardSchema.free_parking_hrs.label,
      result:
        types.includes(type_id) &&
        (isNil(free_parking_hrs) || isNil(free_parking_times))
          ? [[State.Error, summary]]
          : [],
    }
  },
  ({ type_id, free_parking_hrs }) => {
    const summary = `當 [${cardRewardSchema.type_id.label}] 為市區停車、市區停車-紅利折抵時，[${cardRewardSchema.free_parking_hrs.label}] 必小於等於24：`
    const types = rewardNameToID(['市區停車', '市區停車-紅利折抵'])
    return {
      fieldname: cardRewardSchema.free_parking_hrs.label,
      result:
        !types.includes(type_id) || free_parking_hrs <= 24
          ? []
          : [[State.Error, summary]],
    }
  },
  ({ free_parking_times }) => {
    const summary = `[${cardRewardSchema.free_parking_times.label}] 必不等於365`
    return {
      fieldname: cardRewardSchema.free_parking_hrs.label,
      result: free_parking_times !== 365 ? [] : [[State.Error, summary]],
    }
  },
  ({ type_id, free_parking_hrs, basic_r }) => {
    const summary = `當 [${cardRewardSchema.type_id.label}] 為道路救援時，[${cardRewardSchema.free_parking_hrs.label}] 必等於 [${cardRewardSchema.basic_r.label}]`
    return {
      fieldname: cardRewardSchema.free_parking_hrs.label,
      result:
        type_id !== rewardNameToID(['道路救援'])[0] ||
        free_parking_hrs === basic_r
          ? []
          : [[State.Error, summary]],
    }
  },
  ({ type_id, essential_money, essential_day }) => {
    const summary = `當 [${cardRewardSchema.type_id.label}] 為市區停車、首刷禮-現金回饋、首刷禮-類現金回饋、首刷禮-紅利回饋、首刷禮-行李箱、首刷禮-其他、紅利回饋-定額、分期期數、類現金回饋-滿額時，[${cardRewardSchema.essential_money.label}]、[${cardRewardSchema.essential_day.label}] 必不為empty`
    const types = rewardNameToID([
      '市區停車',
      '首刷禮-現金回饋',
      '首刷禮-類現金回饋',
      '首刷禮-紅利回饋',
      '首刷禮-行李箱',
      '紅利回饋-定額',
      '分期期數',
      '類現金回饋-滿額',
    ])
    return {
      fieldname:
        cardRewardSchema.essential_money.label +
        '/' +
        cardRewardSchema.essential_day.label,
      result:
        !types.includes(type_id) ||
        (!isNil(essential_money) && !isNil(essential_day))
          ? []
          : [[State.Error, summary]],
    }
  },
  ({ essential_money, essential_day, essential_day_unit_id }) => {
    const summary = `[${cardRewardSchema.essential_day.label}, ${cardRewardSchema.essential_money.label}, ${cardRewardSchema.essential_day_unit_id.label}] 應同時有值，或同時為空`

    const fields = [essential_money, essential_day, essential_day_unit_id]
    return {
      fieldname: [
        cardRewardSchema.essential_money.label,
        cardRewardSchema.essential_day.label,
        cardRewardSchema.essential_day_unit_id.label,
      ].join('/'),
      result:
        every(fields, isNil) || every(fields, negate(isNil))
          ? []
          : [[State.Error, summary]],
    }
  },
  ({ essential_day_unit_id, essential_day }) => {
    const summary = `當[${cardRewardSchema.essential_day_unit_id.label}]為月時，[${cardRewardSchema.essential_day.label}]為大於1之正整數`
    if (KG_NODES.card.DATE_UNIT_MONTH !== essential_day_unit_id)
      return { result: [] }
    return {
      fieldname: cardRewardSchema.essential_day.label,
      result:
        essential_day > 1 && essential_day % 1 === 0
          ? []
          : [[State.Error, summary]],
    }
  },
  ({ type_id, re_target_ids, detail }) => {
    const summary = `當 [${cardRewardSchema.type_id.label}] 為"其他"、"首刷禮-其他"時， [${cardRewardSchema.re_target_ids.label}] 與 [${cardRewardSchema.detail.label}] 必不為空`
    const types = rewardNameToID(['其他', '首刷禮-其他'])
    return {
      fieldname:
        cardRewardSchema.re_target_ids.label +
        '/' +
        cardRewardSchema.detail.label,
      result:
        !types.includes(type_id) ||
        (!isEmpty(detail) && !isEmpty(re_target_ids))
          ? []
          : [[State.Error, summary]],
    }
  },
  ({ include_card_sets, exclude_card_sets }) => {
    const summary = `include_card_sets/exclude_card_sets內容不可重複`
    const composed = [...include_card_sets, ...exclude_card_sets].map(cardSet =>
      JSON.stringify(
        cardSet.card_group_id + cardSet.issuer_id + cardSet.card_level_id,
      ),
    )
    return {
      fieldname:
        cardRewardSchema.include_card_sets.label +
        '/' +
        cardRewardSchema.exclude_card_sets.label,
      result: isEmpty(
        composed.filter((string, index) => composed.indexOf(string) !== index),
      )
        ? []
        : [[State.Error, summary]],
    }
  },
  ({ include_card_sets, exclude_card_sets }, { cardGroups }) => {
    const summary = 'card_sets組合不存在'
    return {
      fieldname:
        cardRewardSchema.include_card_sets.label +
        '/' +
        cardRewardSchema.exclude_card_sets.label,
      result: [...include_card_sets, ...exclude_card_sets].every(cardSet => {
        const group = cardGroups.find(g => g._id === cardSet.card_group_id)
        if (!group) return false

        if (
          cardSet.issuer_id === KG_NODES.entity.ALL_ISSUER_ID &&
          cardSet.card_level_id === KG_NODES.entity.ALL_CARD_LEVEL_ID
        ) {
          return true
        }

        // TODO: backend should report correct combinations for all-card group.
        if (!group.available_combinations) return true

        const availableCombinations = group.available_combinations || []
        if (cardSet.issuer_id === KG_NODES.entity.ALL_ISSUER_ID) {
          return !!availableCombinations.find(
            e => e.card_level_id === cardSet.card_level_id,
          )
        }

        if (cardSet.card_level_id === KG_NODES.entity.ALL_CARD_LEVEL_ID) {
          return !!availableCombinations.find(
            e => e.issuer_id === cardSet.issuer_id,
          )
        }

        return !!availableCombinations.find(
          e =>
            e.issuer_id === cardSet.issuer_id &&
            e.card_level_id === cardSet.card_level_id,
        )
      })
        ? []
        : [[State.Error, summary]],
    }
    // TBD: more advance test cases:
    // 1. issuer in exclude_card_sets in 'All issuers' and issuer in include_card_sets
    //    is Master, withe all the other three fields are the same - should be treated
    //    as an error.
    // 2. card_level in exclude_card_sets in '全卡等' .....
    // In short, We need check 'All issuers' and '全卡等' in these two sets
  },
  ({ effective_date_b, effective_date_e }) => {
    const summary = `[${cardRewardSchema.effective_date_b.label}]必小於等於[${cardRewardSchema.effective_date_e.label}]`
    const begin = new Date(effective_date_b)
    const end = new Date(effective_date_e)
    return {
      fieldname:
        cardRewardSchema.effective_date_b.label +
        '/' +
        cardRewardSchema.effective_date_e.label,
      result:
        isEmpty(effective_date_b) || isEmpty(effective_date_e) || begin <= end
          ? []
          : [[State.Error, summary]],
    }
  },
  ({ effective_date_b, effective_date_e }) => {
    const summary = `[${cardRewardSchema.effective_date_b.label}或[${cardRewardSchema.effective_date_e.label}]不合理（超過目前日期五年）`
    const begin = new Date(effective_date_b)
    const end = new Date(effective_date_e)
    const now = new Date()
    const limit = new Date(now.getFullYear() + 5, now.getMonth(), now.getDate())
    return {
      fieldname:
        cardRewardSchema.effective_date_b.label +
        '/' +
        cardRewardSchema.effective_date_e.label,
      result: begin < limit && end < limit ? [] : [[State.Error, summary]],
    }
  },
]
