import { useState, useCallback, useContext, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useImmer } from 'use-immer'
import 'styled-components/macro'
import {
  get,
  isEmpty,
  find,
  map,
  isEqual,
  isNil,
  join,
  set,
  filter,
  difference,
  forEach,
  findIndex,
  isNumber,
} from 'lodash'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { TabView, TabPanel } from 'primereact/tabview'
import produce from 'immer'
import { GiCancel } from 'react-icons/gi'
import {
  Flex,
  Box,
  Text,
  StyledOverlay,
} from '@changingai/react-editor-common-component'

import { useDialog, formatNumber, KG_NODES } from '@common'
import { schemaToPropTypes, createBlankDoc } from '@schema'

import {
  FeeVariables,
  cssDataTable,
  TableCell,
  OptionInput,
  AxisValueInput,
  updateOptionValue,
} from './common'
import { PlanContext } from './context'
import { axisSchema, feeTableRefSchema } from './schema'

const REQUEST_AMOUNT_NODE = KG_NODES.insurance.REQUEST_VARIABLE.REQUEST_AMOUNT
function FeeTablesDialog({
  onHide,
  onSelect,
  productFeeTables,
  planFeeTables,
}) {
  const [selectedFeeTableIds, setSelectedFeeTableIds] = useState(
    map(planFeeTables, 'feetable_id'),
  )

  const hasUnselectedProductFeeTable = useMemo(() => {
    const productFeeTableIds = map(productFeeTables, '_id')
    return !isEmpty(difference(productFeeTableIds, selectedFeeTableIds))
  }, [productFeeTables, selectedFeeTableIds])
  return (
    <Dialog
      header={`Select Fee Tables`}
      appendTo={document.body}
      visible={true}
      style={{ width: '800px' }}
      modal={true}
      onHide={onHide}
    >
      <Flex
        width="100%"
        height="500px"
        overflowY="auto"
        alignContent="flex-start"
        flexWrap="wrap"
      >
        <Flex width="100%" justifyContent="flex-end">
          <Button
            label="Cancel All"
            className="p-button-outlined p-button-warning"
            onClick={() => {
              setSelectedFeeTableIds([])
            }}
            disabled={isEmpty(selectedFeeTableIds)}
          />
          <Box width="10px" />
          <Button
            label="Select All"
            className="p-button-outlined p-button-help"
            onClick={() => {
              setSelectedFeeTableIds(map(productFeeTables, '_id'))
            }}
            disabled={!hasUnselectedProductFeeTable}
          />
        </Flex>
        {map(productFeeTables, ({ _id, name }) => (
          <Box flex="1 1 100%" key={_id} mb="3">
            <Checkbox
              inputId={_id}
              onChange={({ checked }) => {
                setSelectedFeeTableIds(
                  checked
                    ? [...selectedFeeTableIds, _id]
                    : selectedFeeTableIds.filter(id => id !== _id),
                )
              }}
              checked={selectedFeeTableIds.includes(_id)}
            />
            <Text ml="2">{name}</Text>
          </Box>
        ))}
      </Flex>
      <Flex width="100%" justifyContent="flex-end">
        <Button
          icon="pi pi-check"
          label="OK"
          className="p-button-success"
          onClick={() => {
            onSelect(
              map(selectedFeeTableIds, _id => find(productFeeTables, { _id })),
            )
            onHide()
          }}
        />
        <Box width="2" />
        <Button
          icon="pi pi-times"
          label="Cancel"
          className="p-button-secondary"
          onClick={() => onHide()}
        />
      </Flex>
    </Dialog>
  )
}

FeeTablesDialog.propTypes = {
  onHide: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  productFeeTables: PropTypes.arrayOf(schemaToPropTypes(feeTableRefSchema))
    .isRequired,
  planFeeTables: PropTypes.arrayOf(schemaToPropTypes(feeTableRefSchema))
    .isRequired,
}

function EmptyError() {
  return (
    <Flex width="100%" justifyContent="center" alignItems="center">
      <GiCancel
        style={{
          color: 'red',
          margin: '4px',
          width: '20px',
          height: '20px',
        }}
      />
      不可空白
    </Flex>
  )
}

function iterateAxes(planFeeTables, callback) {
  forEach(planFeeTables, planFeeTable => {
    forEach(planFeeTable.axes, axis => {
      callback(planFeeTable, axis)
    })
  })
}

function isEmptyAxis({ axis_id, option_id, constant, axis_value }) {
  return axis_id === REQUEST_AMOUNT_NODE
    ? isEmpty(option_id) && isNil(constant)
    : isEmpty(axis_value)
}

export function Feetables({ context, onDirty, onChange, onError }) {
  const {
    productFeeTables,
    planRequestVariables,
    variableKGNodes,
    isReady,
  } = useContext(PlanContext)

  const [initFeetables] = useState(get(context, 'feetables', []))
  const planFeeTables = useMemo(
    () =>
      filter(
        get(context, 'feetables', []),
        ({ feetable_id }) =>
          !isNil(find(productFeeTables, { _id: feetable_id })),
      ),
    [context, productFeeTables],
  )

  const [showDialog, renderDialog] = useDialog(FeeTablesDialog)
  const selectFeeTable = useCallback(() => {
    showDialog()
  }, [showDialog])

  const [editData, setEditData] = useImmer({})
  const onSaveEdit = useCallback(
    (tableIndex, rowIndex, value) => {
      const result = produce(planFeeTables, draft => {
        set(draft, `${tableIndex}.axes.${rowIndex}`, value)
      })
      const key = `${planFeeTables[tableIndex].feetable_id}`
      onDirty(key, !isEqual(result, initFeetables))
      onChange('feetables', result)
    },
    [planFeeTables, onDirty, onChange, initFeetables],
  )

  const getFactor = useCallback(
    (axis_id, feetableId) => {
      const productFeeTable = find(productFeeTables, {
        _id: feetableId,
      })
      return [
        productFeeTable,
        find(get(productFeeTable, 'factors'), {
          id: axis_id,
        }),
      ]
    },
    [productFeeTables],
  )

  const canEdit = useCallback(
    (axis_id, feetableId) => {
      const [productFeeTable, factor] = getFactor(axis_id, feetableId)
      if (
        axis_id === REQUEST_AMOUNT_NODE &&
        isNil(factor) &&
        get(productFeeTable, 'fee_base') === 0
      )
        return false
      const variable = find(variableKGNodes, { node_id: axis_id })
      return (
        get(find(variable.attributes, { name: 'type' }), 'values.0') === 'free'
      )
    },
    [variableKGNodes, getFactor],
  )

  const canEmpty = useCallback(
    (axis_id, feetableId) => {
      if (!canEdit(axis_id, feetableId)) return true

      const [productFeeTable, factor] = getFactor(axis_id, feetableId)
      return (
        axis_id === REQUEST_AMOUNT_NODE &&
        isNil(factor) &&
        get(productFeeTable, 'fee_base') === 0
      )
    },
    [getFactor, canEdit],
  )

  const onSelectProductFeeTabels = useCallback(
    selectedProductFeeTables => {
      function createDefaultAxes(productFeeTable) {
        const { factors, _id: feetable_id } = productFeeTable
        const axesIds = filter(map(factors, 'id'), axesId =>
          canEdit(axesId, feetable_id),
        )
        const result = {
          feetable_id,
          axes: map(axesIds, axis_id => ({
            ...createBlankDoc(axisSchema, false),
            axis_id,
          })),
        }

        if (
          !axesIds.includes(REQUEST_AMOUNT_NODE) &&
          canEdit(REQUEST_AMOUNT_NODE, feetable_id)
        ) {
          result.axes.push({
            ...createBlankDoc(axisSchema, false),
            axis_id: REQUEST_AMOUNT_NODE,
          })
        }
        return result
      }

      const result = produce(planFeeTables, draft =>
        map(
          selectedProductFeeTables,
          productFeeTable =>
            find(draft, { feetable_id: productFeeTable._id }) ||
            createDefaultAxes(productFeeTable),
        ),
      )

      // Wipe out all dirty/ errors, since we are going to recount them by
      // new planFeeTables.
      iterateAxes(planFeeTables, ({ feetable_id }, { axis_id }) => {
        onError(`${feetable_id}.${axis_id}`, false)
        onDirty(feetable_id, false)
      })
      onDirty('feetables', !isEqual(result, initFeetables))
      onChange('feetables', result)
    },
    [planFeeTables, onDirty, canEdit, onChange, onError, initFeetables],
  )

  useEffect(() => {
    iterateAxes(planFeeTables, (planFeeTable, axis) => {
      const error =
        !canEmpty(axis.axis_id, planFeeTable.feetable_id) && isEmptyAxis(axis)
      onError(`${planFeeTable.feetable_id}.${axis.axis_id}`, error)
    })
  }, [planFeeTables, canEmpty, onError, onDirty])

  return (
    <Flex width="100%" css={cssDataTable} flexWrap="wrap">
      <StyledOverlay active={!isReady} />
      {renderDialog({
        productFeeTables,
        planFeeTables,
        onSelect: onSelectProductFeeTabels,
      })}
      <Flex width="100%" justifyContent="flex-end" mb="2">
        <Button
          label="Select Fee Tables"
          icon="pi pi-refresh"
          onClick={() => {
            selectFeeTable()
          }}
        />
      </Flex>
      <TabView renderActiveOnly={false}>
        {map(planFeeTables, (planFeeTable, tableIndex) => {
          const feetable = find(productFeeTables, {
            _id: planFeeTable.feetable_id,
          })
          const factors = map(planFeeTable.axes, axis => {
            if (
              axis.axis_id === REQUEST_AMOUNT_NODE &&
              isNil(find(feetable.factors, { id: axis.axis_id }))
            )
              return {
                id: REQUEST_AMOUNT_NODE,
                values: [`每 ${formatNumber(get(feetable, 'fee_base', 0))}`],
                axis,
              }
            return { ...find(feetable.factors, { id: axis.axis_id }), axis }
          })

          return (
            <TabPanel
              key={planFeeTable.feetable_id}
              header={get(feetable, 'name')}
            >
              <Box flex="1 1" width="100%" overflow="visible">
                <DataTable
                  value={factors}
                  className="p-datatable-gridlines"
                  editMode="row"
                  onRowEditInit={({ data: { id: axis_id } }) => {
                    const index = findIndex(planFeeTable.axes, { axis_id })
                    if (index !== -1) {
                      setEditData(draft => {
                        set(
                          draft,
                          `${tableIndex}.axes.${index}`,
                          planFeeTable.axes[index],
                        )
                      })
                    }
                  }}
                  onRowEditSave={({ data: { id: axis_id } }) => {
                    const index = findIndex(planFeeTable.axes, { axis_id })
                    if (index !== -1) {
                      onSaveEdit(
                        tableIndex,
                        index,
                        get(editData, `${tableIndex}.axes.${index}`),
                      )
                    }
                  }}
                  onRowEditCancel={({ data: { id: axis_id } }) => {
                    const index = findIndex(planFeeTable.axes, { axis_id })
                    if (index !== -1) {
                      setEditData(draft => {
                        set(draft, `${tableIndex}.axes.${index}`, null)
                      })
                    }
                  }}
                >
                  <Column
                    field="id"
                    header="維度"
                    style={{ width: '150px' }}
                    body={({ id: node_id }) => (
                      <Box>
                        {get(
                          find(planRequestVariables, { node_id }),
                          'name',
                          '載入中...',
                        )}
                      </Box>
                    )}
                  />
                  <Column
                    field="values"
                    header="值域"
                    body={({ values }) => (
                      <TableCell>
                        {join(
                          map(values, value =>
                            isNumber(value) ? formatNumber(value) : value,
                          ),
                        )}
                      </TableCell>
                    )}
                  />
                  <Column
                    field="option_id"
                    header="需求變數"
                    style={{ width: '300px', overflow: 'visible' }}
                    body={({ id, axis }) => {
                      if (
                        isEmptyAxis(axis) &&
                        !canEmpty(id, get(planFeeTable, 'feetable_id'))
                      )
                        return <EmptyError />

                      return (
                        <FeeVariables
                          option_id={get(axis, 'option_id')}
                          constant={get(axis, 'constant')}
                          plan_ratio={get(axis, 'plan_ratio')}
                          amount_options={context.amount_options}
                          axis_id={axis.axis_id}
                          axis_value={get(axis, 'axis_value')}
                          value_type={get(axis, 'value_type')}
                        />
                      )
                    }}
                    editor={({ rowData: { id: axis_id } }) => {
                      const index = findIndex(planFeeTable.axes, {
                        axis_id,
                      })
                      const path = fieldname =>
                        `${tableIndex}.axes.${index}.${fieldname}`
                      if (axis_id === REQUEST_AMOUNT_NODE) {
                        return (
                          <>
                            <OptionInput
                              value_type={get(editData, path('value_type'))}
                              option_id={get(editData, path('option_id'))}
                              constant={get(editData, path('constant'))}
                              plan_ratio={get(editData, path('plan_ratio'))}
                              context={context}
                              onOptionChange={(value, type) => {
                                setEditData(draft => {
                                  updateOptionValue(
                                    draft[tableIndex].axes[index],
                                    type,
                                    value,
                                  )
                                })
                              }}
                              onRatioChange={value => {
                                setEditData(draft => {
                                  set(
                                    draft,
                                    path('plan_ratio'),
                                    isEmpty(value) ? 1 : value,
                                  )
                                })
                              }}
                            />
                          </>
                        )
                      }

                      const [, factor] = getFactor(
                        get(planFeeTables, path('axis_id')),
                        get(planFeeTable, 'feetable_id'),
                      )

                      return (
                        <AxisValueInput
                          axis_value={get(editData, path('axis_value'))}
                          factor_values={get(factor, 'values', [])}
                          onChange={value => {
                            if (get(factor, 'values', []).includes(value)) {
                              setEditData(draft => {
                                set(draft, path('axis_value'), value)
                              })
                            } else {
                              setEditData(draft => {
                                set(draft, path('axis_value'), null)
                              })
                            }
                          }}
                        />
                      )
                    }}
                  />
                  <Column
                    rowEditor
                    header="編輯"
                    headerStyle={{ width: '7rem' }}
                    bodyStyle={{ textAlign: 'center' }}
                  />
                </DataTable>
              </Box>
            </TabPanel>
          )
        })}
      </TabView>
    </Flex>
  )
}

Feetables.propTypes = {
  context: PropTypes.object.isRequired,
  onDirty: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
}
