import { useRef } from 'react'
import PropTypes from 'prop-types'
import { FaFileImport } from 'react-icons/fa'
import theme from '@theme'

import { ButtonLike } from './button'

export function ImportButton({
  onImport,
  accept,
  disabled = false,
  scaleOnHover = true,
  sizes = 6,
  multiple = false,
}) {
  const fileInput = useRef(null)

  return (
    <>
      <ButtonLike
        data-tip="匯入文件"
        disabled={disabled}
        size={sizes}
        scaleOnHover={scaleOnHover}
        onClick={() => {
          if (fileInput.current) fileInput.current.click()
        }}
      >
        <FaFileImport
          style={{
            color: theme.colors.icon,
            width: '100%',
            height: '100%',
          }}
        />
      </ButtonLike>
      <input
        ref={fileInput}
        accept={accept}
        multiple={multiple}
        style={{ display: 'none' }}
        type="file"
        onChange={onImport}
        onClick={e => {
          e.target.value = null
        }}
      />
    </>
  )
}

ImportButton.propTypes = {
  onImport: PropTypes.func.isRequired,
  accept: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  scaleOnHover: PropTypes.bool,
  sizes: PropTypes.number,
  multiple: PropTypes.bool,
}
