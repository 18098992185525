import { namespace, schema, backendDocType, docPrivilege } from './config'
import loader, { queryManifest, generateReferenceDoc } from './loader'
import listview, { useTranspile } from './listview'
import { ticketProfile } from './ticket'
import { addViewManifest } from '../Config'

const manifest = {
  loader,
  schema,
  namespace,
  backendDocType,
  privilege: docPrivilege,
  queryManifest,
  useTranspile,
  generateReferenceDoc,
  listview,
  ticketProfile,
  order: 5,
}

addViewManifest(manifest)
