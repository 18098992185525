import { useMemo } from 'react'
import Joi from 'joi'
import { isEmpty, get, compact, toUpper } from 'lodash'
import { useQuery } from '@apollo/client'

import {
  namespaces,
  useQueryKG,
  useGetConnectedNodes,
  KG_NODES,
  CardGroupScope,
} from '@common'
import {
  useCardGroup,
  schemaToPropTypes,
  ticketDocSchema,
  addSchema,
  DateInTwentyYears,
  NullableString,
  shortString,
  RequiredMongoIdString,
  RequiredKGNodeIdString,
  SchemaEmptyNonNegative,
  RequiredString,
  UniqueNodeArray,
  UniqueStringArray,
  UniqueUrlArray,
} from '@schema'
import { cceClient } from '@gql'

import { _query_kg, _queryTickets } from './schema.gql'

const attributeSchema = {
  key: {
    label: '屬性',
    schema: RequiredString.max(shortString),
    type: 'string',
  },
  value: {
    label: '屬性值',
    schema: RequiredString.max(shortString),
    type: 'string',
  },
}

const roots = {
  issueRoot: [
    {
      node_id: KG_NODES.entity.ISSUER_ROOT_ID,
      namespace: 'entity',
    },
  ],
  levelRoot: [
    {
      node_id: KG_NODES.entity.LEVEL_ROOT_ID,
      namespace: 'entity',
    },
  ],
  functionRoot: [
    {
      node_id: KG_NODES.entity.ENTITY_FUNCTION,
      namespace: 'entity',
    },
  ],
}
export const cardSchema = {
  ...ticketDocSchema,
  card_group_id: {
    label: '卡片名稱',
    schema: RequiredMongoIdString,
    type: 'string',
    useNodes: ({ ticket_id }) => {
      const { loading, data } = useQuery(_queryTickets, {
        client: cceClient,
        variables: {
          filter: {
            ticket_ids: compact([ticket_id]),
          },
          namespace: toUpper('card'),
        },
        skip: isEmpty(compact([ticket_id])),
      })

      const [cardGroups] = useCardGroup({
        card_provider_ids: compact([get(data, 'queryTickets.0.scope_id')]),
        skip: loading || isEmpty(get(data, 'queryTickets')),
      })

      return cardGroups
    },
  },
  issuer_id: {
    label: '發卡組織',
    schema: RequiredKGNodeIdString,
    referToKG: 'entity',
    type: 'node',
    useNodes: () => useGetConnectedNodes(roots.issueRoot),
  },
  card_level_id: {
    label: '卡等',
    schema: RequiredKGNodeIdString,
    referToKG: 'entity',
    type: 'node',
    useNodes: () => useGetConnectedNodes(roots.levelRoot),
  },
  function_ids: {
    label: '卡片功能',
    schema: UniqueNodeArray,
    referToKG: 'entity',
    type: 'arrayOf(node)',
    useNodes: () => useGetConnectedNodes(roots.functionRoot),
  },
  launch_date: {
    label: '上架日期',
    schema: Joi.alternatives().try(Joi.string().valid(''), DateInTwentyYears),
    type: 'date',
    privilege: ['sensitive-field'],
  },
  effective_date_b: {
    label: '有效日期-起',
    schema: DateInTwentyYears.required(),
    type: 'date',
  },
  effective_date_e: {
    label: '有效日期-訖',
    schema: DateInTwentyYears.required(),
    type: 'date',
  },
  ratable_filter: {
    label: 'ratable白名單',
    schema: UniqueNodeArray,
    type: 'arrayOf(node)',
    useNodes: () => {
      const { loading, data } = useQueryKG(_query_kg, {
        variables: {
          query: {
            op: 'eq',
            field: 'tags',
            value: 'ratable',
          },
        },
      })

      const result = useMemo(
        () =>
          loading || !data
            ? null
            : data.query_kg.map(({ node_id: id, name }) => ({ id, name })),
        [loading, data],
      )

      return result
    },
  },
  scope_filter: {
    label: 'scope白名單',
    schema: UniqueNodeArray,
    type: 'arrayOf(node)',
    useNodes: () => {
      const scopeOptions = useMemo(
        () =>
          Object.values(CardGroupScope).map(scope => ({
            id: scope,
            name: scope,
          })),
        [],
      )

      return scopeOptions
    },
  },
  aliases: {
    label: '卡片暱稱/簡稱',
    schema: UniqueStringArray,
    type: 'arrayOf(string)',
  },
  apply_url: {
    label: '申請頁面網址',
    schema: Joi.string()
      .uri()
      .allow(null, ''),
    type: 'url',
  },
  apply_condition: {
    label: '申請資格/申辦條件',
    schema: NullableString.max(shortString),
    type: 'string',
  },
  apply_doc: {
    label: '申請文件',
    schema: NullableString.max(shortString),
    type: 'string',
  },
  income_threshold: {
    label: '申請年收入門檻',
    schema: SchemaEmptyNonNegative,
    type: 'number',
  },
  fee_1st_l: {
    label: '首年年費(最低)',
    schema: SchemaEmptyNonNegative,
    type: 'number',
  },
  fee_1st_h: {
    label: '首年年費(最高)',
    schema: SchemaEmptyNonNegative,
    type: 'number',
  },
  fee_1st_note: {
    label: '首年免年費說明',
    schema: NullableString.max(shortString),
    type: 'string',
  },
  fee_2nd_l: {
    label: '次年起年費(最低)',
    schema: SchemaEmptyNonNegative,
    type: 'number',
  },
  fee_2nd_h: {
    label: '次年起年費(最高)',
    schema: SchemaEmptyNonNegative,
    type: 'number',
  },
  fee_2nd_note: {
    label: '次年免年費說明',
    schema: NullableString.max(shortString),
    type: 'string',
  },
  apply_process: {
    label: '申請/核卡流程',
    schema: NullableString.max(shortString),
    type: 'string',
  },
  suspend_process: {
    label: '停卡流程',
    schema: NullableString.max(shortString),
    type: 'string',
  },
  rir_date_b: {
    label: '循環起息日',
    schema: NullableString.max(shortString),
    type: 'string',
  },
  rir: {
    label: '循環利息',
    schema: NullableString.max(shortString),
    type: 'string',
  },
  overdue_fine: {
    label: '逾期違約金',
    schema: NullableString.max(shortString),
    type: 'string',
  },
  cash_adv_fee: {
    label: '預借現金手續費',
    schema: NullableString.max(shortString),
    type: 'string',
  },
  foreign_trans_fee: {
    label: '國外交易手續費',
    schema: NullableString.max(shortString),
    type: 'string',
  },
  report_loss_fee: {
    label: '掛失停用手續費',
    schema: NullableString.max(shortString),
    type: 'string',
  },
  report_loss_charge: {
    label: '掛失自付額',
    schema: NullableString.max(shortString),
    type: 'string',
  },
  review_slip_fee: {
    label: '調閱簽帳單手續費',
    schema: NullableString.max(shortString),
    type: 'string',
  },
  rebill_fee: {
    label: '補寄帳單手續費',
    schema: NullableString.max(shortString),
    type: 'string',
  },
  zero_interest_installments: {
    label: '零利率分期期數',
    schema: NullableString.max(shortString),
    type: 'string',
  },
  min_installment_money: {
    label: '最低可分期金額',
    schema: NullableString.max(shortString),
    type: 'string',
  },
  cash_adv_interest: {
    label: '預借現金分期付款利率',
    schema: NullableString.max(shortString),
    type: 'string',
  },
  cash_adv_prepayment_penalty: {
    label: '預借現金分期付款提前償還利率',
    schema: NullableString.max(shortString),
    type: 'string',
  },
  birth_present: {
    label: '生日禮簡述',
    schema: NullableString.max(shortString),
    type: 'string',
  },
  birth_present_detail: {
    label: '生日禮詳述',
    schema: NullableString.max(shortString),
    type: 'string',
  },
  enabled: {
    label: 'enable',
    schema: Joi.bool(),
    type: 'bool',
  },
  attributes: {
    label: '客製化屬性',
    schema: Joi.array(),
    type: 'arrayOf(attributeSchema)',
  },
  img_urls: {
    schema: UniqueUrlArray.min(1).required(),
    type: 'arrayOf(image)',
    label: '卡片圖示',
  },
}

const spotlightSchema = {
  title: {
    schema: RequiredString,
    type: 'string',
    label: '亮點抬頭',
  },
  brief: {
    schema: RequiredString,
    type: 'string',
    label: '亮點簡介',
  },
  detail: {
    schema: RequiredString,
    type: 'string',
    label: '亮點詳情',
  },
}

export const spotlightsSchema = {
  spotlight_summary: {
    schema: NullableString.max(shortString),
    type: 'string',
    label: '亮點總結',
  },
  spotlight: {
    schema: Joi.array(),
    type: 'arrayOf(spotlightSchema)',
    label: '亮點',
  },
}

addSchema({ spotlightSchema })
addSchema({ spotlightsSchema })
addSchema({ attributeSchema })
addSchema({ cardSchema }, '卡片', namespaces.card.value)

export const CardPropType = schemaToPropTypes(cardSchema)
