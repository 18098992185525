import { useMemo, createContext } from 'react'
import {
  find,
  get,
  isNil,
  filter,
  isEmpty,
  head,
  map,
  toUpper,
  flatMap,
  groupBy,
} from 'lodash'
import { useQuery } from '@apollo/client'

import { getDataEditors } from '@common'
import { cceClient } from '@gql'

import { requestVariableSchema } from './schema'

import {
  _queryTickets,
  _queryFeeTable,
  _queryProduct,
  _queryBenefit,
} from './context.gql'

export const PlanContext = createContext({
  product: null,
})

function useBenefitsOfTicket(scope_ids) {
  const { data: products } = useQuery(_queryProduct, {
    variables: {
      doc_ids: scope_ids,
    },
    client: cceClient,
  })

  const contractIds = useMemo(
    () => flatMap(get(products, 'queryProduct', []), 'contract_ids'),
    [products],
  )

  const { data } = useQuery(_queryTickets, {
    client: cceClient,
    variables: {
      filter: {
        scope_ids: contractIds,
      },
      namespace: toUpper('insurance'),
    },
    skip: isEmpty(contractIds),
  })

  const tickets = useMemo(() => get(data, 'queryTickets'), [data])
  const { data: benefits } = useQuery(_queryBenefit, {
    client: cceClient,
    variables: {
      ticket_ids: map(tickets, '_id'),
    },
    skip: isEmpty(tickets),
  })

  const benefitMap = useMemo(() => {
    if (isNil(products)) return null
    if (isEmpty(contractIds)) return {}
    if (isNil(benefits)) return null
    return groupBy(
      filter(
        get(benefits, 'queryBenefit'),
        ({ ticket_id, uploader }) =>
          uploader === head(getDataEditors(find(tickets, { _id: ticket_id }))),
      ),
      'ticket_id',
    )
  }, [products, tickets, benefits, contractIds])

  return [tickets, benefitMap]
}

export function usePlanContext(productIds) {
  const { data: feeTableTicket } = useQuery(_queryTickets, {
    variables: {
      filter: {
        scope_type: 'FeeTable',
        scope_ids: productIds,
      },
      namespace: toUpper('insurance'),
    },
    skip: isEmpty(productIds),
    client: cceClient,
  })

  const { data: feeTables } = useQuery(_queryFeeTable, {
    variables: {
      ticket_ids: map(get(feeTableTicket, 'queryTickets'), '_id'),
    },
    skip: isEmpty(feeTableTicket),
    client: cceClient,
  })

  const mainEditor = map(get(feeTableTicket, 'queryTickets'), ticket => ({
    ticket_id: ticket._id,
    // If feetable ticket state been rolled back to create/unassigned, get feeTables with uploader '0'.
    // Alternatively, we can create virtual editor for ticket when its' in create/unassigned state.
    uploader: head(getDataEditors(ticket)) || '0',
  }))

  const productFeeTables = useMemo(
    () =>
      filter(
        get(feeTables, 'queryFeeTable'),
        ({ uploader, ticket_id }) =>
          get(find(mainEditor, { ticket_id }), 'uploader') === uploader,
      ),
    [feeTables, mainEditor],
  )

  const variableKGNodes = requestVariableSchema.request_type.useNodes()
  const planRequestVariables = useMemo(
    () =>
      filter(
        variableKGNodes,
        node =>
          !isNil(get(find(node.attributes, { name: 'type' }), 'values.0')),
      ),
    [variableKGNodes],
  )

  const [benefitTickets, benefitsOfContract] = useBenefitsOfTicket(productIds)

  const isReady = useMemo(
    () =>
      !isNil(productFeeTables) &&
      !isNil(planRequestVariables) &&
      !isNil(variableKGNodes) &&
      !isNil(benefitsOfContract),
    [
      productFeeTables,
      planRequestVariables,
      variableKGNodes,
      benefitsOfContract,
    ],
  )

  return {
    isReady,
    productFeeTables,
    planRequestVariables,
    variableKGNodes,
    benefitTickets,
    benefitsOfContract,
  }
}
