import { isNil, isEmpty } from 'lodash'
import { ValidationCategory as State } from '@common'

import { feeTableSchema } from './schema'

export const rules = [
  ({ raw_files, contract_id }) => {
    if (isEmpty(raw_files) && isNil(contract_id))
      return {
        fieldname: feeTableSchema.raw_files.label,
        result: [
          [
            State.Error,
            `${feeTableSchema.raw_files.label}, ${feeTableSchema.contract_id.label}不可同時為空`,
          ],
        ],
      }

    if (!isEmpty(raw_files) && !isNil(contract_id))
      throw new Error(
        `${feeTableSchema.raw_files.label}, ${feeTableSchema.contract_id.label} should not both have value.`,
      )

    return { result: [] }
  },
]
