import { useMemo, useState, useCallback, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { MultiSelect } from 'primereact/multiselect'
import {
  isEmpty,
  reduce,
  get,
  map,
  includes,
  forEach,
  sortBy,
  filter,
  escapeRegExp,
  every,
  some,
  intersection,
} from 'lodash'
import { Flex } from '@changingai/react-editor-common-component'

import { ProjectContext, getActiveState } from '@common'
import { SearchWidget } from '@widget'

import { useTagFilter } from '../TagPanel'
import { getTicketProfile, useTicketStateStorage } from '../TicketHelper'
import { TagMemoContext } from '../TagMemoContext'

function TicketListViewHeader({
  ticketType,
  tickets,
  setSelectedState,
  selectedState,
  setSubStatesSelection,
  subStatesSelection,
  setAssigneeSelection,
  setIncludeTags,
  setExcludeTags,
  assigneeSelection,
  ticketSuggestions,
  onSearch,
  enableRegExpMode,
}) {
  const { tags: tagMap } = useContext(TagMemoContext)
  const [includeTags, excludeTags, renderTagFilter] = useTagFilter(
    ticketType,
    tagMap,
  )
  useEffect(() => {
    setIncludeTags(includeTags)
    setExcludeTags(excludeTags)
  }, [excludeTags, includeTags, setExcludeTags, setIncludeTags])

  const { mainStateCount, subStateCount, assigneeCount } = useMemo(
    () =>
      reduce(
        tickets,
        (total, ticket) => {
          total.mainStateCount[ticket.active_state.toLowerCase()] += 1
          total.subStateCount[getActiveState(ticket).sub_state] += 1
          forEach(get(getActiveState(ticket), 'assignees'), assignee => {
            total.assigneeCount[assignee] =
              get(total.assigneeCount, assignee, 0) + 1
          })
          return total
        },
        {
          mainStateCount: { create: 0, approximate: 0, merge: 0, finish: 0 },
          subStateCount: { INITIATED: 0, DISPATCHED: 0 },
          assigneeCount: {},
        },
      ),
    [tickets],
  )
  const [mainStateOptions, subStateOptions, assigneeOptions] = useMemo(
    () => [
      map(
        get(getTicketProfile(ticketType), 'states', []),
        ({ main_state }) => ({
          name: `${main_state}(${get(
            mainStateCount,
            main_state.toLowerCase(),
          )})`,
          value: main_state,
        }),
      ),
      [
        {
          name: `未分配(${subStateCount['INITIATED']})`,
          value: 'INITIATED',
        },
        {
          name: `已分配(${subStateCount['DISPATCHED']})`,
          value: 'DISPATCHED',
        },
      ],
      sortBy(
        map(
          reduce(
            tickets,
            (assignees, ticket) => {
              forEach(get(getActiveState(ticket), 'assignees'), assignee => {
                if (!includes(assignees, assignee)) assignees.push(assignee)
              })

              return assignees
            },
            [],
          ),
          assignee => ({
            name: `${assignee.substring(0, assignee.indexOf('@'))}(${get(
              assigneeCount,
              assignee,
              0,
            )})`,
            value: assignee,
          }),
        ),
        ['name'],
      ),
    ],
    [tickets, ticketType, mainStateCount, subStateCount, assigneeCount],
  )

  return (
    <Flex flexDirection="column">
      <Flex width="100%">
        <Flex flexWrap="wrap" flex="1 1 75%" p="1">
          <Flex color="blacks.8" width="100%">
            選擇 Main State
          </Flex>
          <MultiSelect
            style={{ width: '100%' }}
            options={mainStateOptions}
            placeholder="Select main states"
            display="chip"
            optionLabel="name"
            value={selectedState}
            onChange={({ value }) => setSelectedState(value)}
          />
        </Flex>
        <Flex flexWrap="wrap" flex="1 1 25%" p="1">
          <Flex color="blacks.8" width="100%">
            選擇 Sub State
          </Flex>
          <MultiSelect
            options={subStateOptions}
            style={{ width: '100%' }}
            placeholder="Select sub states"
            display="chip"
            optionLabel="name"
            value={subStatesSelection}
            onChange={({ value }) => setSubStatesSelection(value)}
          />
        </Flex>
      </Flex>
      <Flex width="100%">
        <Flex flexWrap="wrap" flex="1 1 50%" p="1">
          <Flex color="blacks.8" width="100%">
            選擇指定者
          </Flex>
          {!isEmpty(assigneeOptions) && (
            <MultiSelect
              options={assigneeOptions}
              style={{ width: '100%' }}
              placeholder="Select assignees"
              display="chip"
              optionLabel="name"
              value={assigneeSelection}
              onChange={({ value }) => setAssigneeSelection(value)}
            />
          )}
        </Flex>
        <Flex flexWrap="wrap" flex="1 1 50%">
          {renderTagFilter()}
        </Flex>
      </Flex>
      <SearchWidget
        searchSuggestions={ticketSuggestions}
        onSearch={onSearch}
        cacheKey={`ticket.${ticketType}`}
        enableRegExpMode={enableRegExpMode}
      />
    </Flex>
  )
}

TicketListViewHeader.propTypes = {
  tickets: PropTypes.array,
  selectedState: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedState: PropTypes.func.isRequired,
  subStatesSelection: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSubStatesSelection: PropTypes.func.isRequired,
  assigneeSelection: PropTypes.arrayOf(PropTypes.string).isRequired,
  setAssigneeSelection: PropTypes.func.isRequired,
  ticketType: PropTypes.string.isRequired,
  ticketSuggestions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSearch: PropTypes.func.isRequired,
  enableRegExpMode: PropTypes.bool.isRequired,
  setIncludeTags: PropTypes.func.isRequired,
  setExcludeTags: PropTypes.func.isRequired,
}

export function useTicketFilter(ticketType, tickets, tagMemoContext) {
  const [
    selectedState,
    setSelectedState,
    subStatesSelection,
    setSubStatesSelection,
    assigneeSelection,
    setAssigneeSelection,
  ] = useTicketStateStorage(ticketType, 'TicketListView')

  const [searchContext, setSearchContext] = useState(null)
  const [includeTags, setIncludeTags] = useState([])
  const [excludeTags, setExcludeTags] = useState([])
  const [regExpMode, setRegExpMode] = useState(false)
  const [andGate, setAndGate] = useState(false)

  const { tags: tagMap } = tagMemoContext

  const filteredTickets = useMemo(() => {
    if (!tickets) return []
    return filter(tickets, ticket => {
      if (!isEmpty(searchContext)) {
        const result = searchContext.map(({ value }) => {
          const searchText = regExpMode ? value : escapeRegExp(value)
          return RegExp(searchText, 'ig').test(
            [ticket.name, ticket._id].join(' '),
          )
        })
        if (!(andGate ? every(result) : some(result))) return false
      }

      // Use ticket instead of ticket.editContext on purpose.
      //
      // We get active state of ticket, instead of ticket.editContext to keep a state
      // changed ticket stay in the list.
      //
      // DO NOT replace ticket by ticket.editContext.
      const { main_state, sub_state, assignees } = getActiveState(ticket)
      if (!includes(selectedState, main_state)) return false
      if (!includes(subStatesSelection, sub_state)) return false
      if (
        !isEmpty(assigneeSelection) &&
        isEmpty(intersection(assigneeSelection, assignees))
      )
        return false

      const tags = get(tagMap, ticket._id, [])
      const notIncluded =
        !isEmpty(includeTags) && isEmpty(intersection(tags, includeTags))
      const excluded = !isEmpty(intersection(tags, excludeTags))

      if (notIncluded || excluded) return false
      return true
    })
  }, [
    tickets,
    selectedState,
    subStatesSelection,
    searchContext,
    regExpMode,
    andGate,
    assigneeSelection,
    includeTags,
    excludeTags,
    tagMap,
  ])

  const { enableRegExpMode } = useContext(ProjectContext)
  const onSearch = useCallback(
    (searchContext, regExpMode, andGate) => {
      setSearchContext(searchContext)
      setRegExpMode(regExpMode)
      setAndGate(andGate)
    },
    [setSearchContext, setRegExpMode, setAndGate],
  )

  const ticketSuggestions = useMemo(
    () =>
      map(
        filter(tickets, ticket =>
          includes(subStatesSelection, getActiveState(ticket).sub_state),
        ),
        'name',
      ),
    [tickets, subStatesSelection],
  )

  return {
    filteredTickets,
    header: (
      <TagMemoContext.Provider value={tagMemoContext}>
        <TicketListViewHeader
          ticketType={ticketType}
          tickets={tickets}
          selectedState={selectedState}
          setSelectedState={setSelectedState}
          subStatesSelection={subStatesSelection}
          setSubStatesSelection={setSubStatesSelection}
          assigneeSelection={assigneeSelection}
          setAssigneeSelection={setAssigneeSelection}
          setIncludeTags={setIncludeTags}
          setExcludeTags={setExcludeTags}
          ticketSuggestions={ticketSuggestions}
          onSearch={onSearch}
          enableRegExpMode={enableRegExpMode}
        />
      </TagMemoContext.Provider>
    ),
  }
}
