import {
  useState,
  useRef,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import { OverlayPanel } from 'primereact/overlaypanel'
import { MultiSelect } from 'primereact/multiselect'
import { MdAddCircle } from 'react-icons/md'
import { FaTags, FaRegTimesCircle } from 'react-icons/fa'
import {
  isNil,
  isEmpty,
  countBy,
  flatten,
  size,
  filter,
  map,
  values,
  keys,
  includes,
  pickBy,
  uniq,
  get,
} from 'lodash'
import { Box, Flex } from '@changingai/react-editor-common-component'
import { useToggle, useLocalStorage } from '@changing-cc/hooks'

import { ProjectContext } from '@common'
import { ButtonLike, useColumnFilter } from '@widget'

import { TagMemoContext } from './TagMemoContext'

const TagIcon = styled.span`
  border: ${({ borderColor }) => `3px solid ${borderColor}`};
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ fontColor }) => fontColor};
  padding: 3px 10px;
  border-radius: 15px 0 0 15px;
  margin: 0 10px 0 0;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`

function PostIt({ tag, removeTag }) {
  const { privilege } = useContext(ProjectContext)
  const canRemoveTag = useMemo(
    () =>
      !isNil(removeTag) && (tag !== 'lock' || privilege.includes('supervisor')),
    [removeTag, privilege, tag],
  )

  return (
    <TagIcon
      key={tag}
      backgroundColor={tag === 'lock' ? 'red' : 'midnightblue'}
      borderColor="yellow"
      fontColor="white"
    >
      {canRemoveTag && (
        <FaRegTimesCircle
          style={{
            width: '18px',
            height: '18px',
            color: 'white',
            margin: '0 5px 0 0',
          }}
          onClick={() => {
            removeTag(tag)
          }}
        />
      )}
      {tag}
    </TagIcon>
  )
}

PostIt.propTypes = {
  tag: PropTypes.string.isRequired,
  removeTag: PropTypes.func,
}

const StatisticDiv = styled.div`
  border: 1px solid lightgray;
  width: 100%;
  padding: 5px;
  margin: 5px 0;
`

const NO_TAG = '無標籤'

function TagStatistic() {
  const {
    isLoadingTag,
    tags: tagMap,
    updateTag: [, removeTag],
  } = useContext(TagMemoContext)

  const tableRef = useRef(null)
  const nameFilter = useColumnFilter(tableRef, 'name')
  const tagStatisticData = useMemo(
    () => [
      {
        tag: NO_TAG,
        count: size(filter(tagMap, isEmpty)),
      },
      ...map(countBy(flatten(values(tagMap))), (count, tag) => ({
        tag,
        count,
      })),
    ],
    [tagMap],
  )

  const onLocalDelete = useCallback(
    tag => removeTag(keys(pickBy(tagMap, tags => includes(tags, tag))), tag),
    [removeTag, tagMap],
  )

  return (
    <>
      {!isLoadingTag && (
        <DataTable
          value={tagStatisticData}
          paginator={false}
          style={{ height: '300px', overflow: 'auto' }}
          ref={tableRef}
        >
          <Column
            filter={true}
            filterElement={nameFilter}
            field="tag"
            header="標籤名稱"
          />
          <Column sortable={true} field="count" header="合計" />
          <Column
            field="actions"
            body={({ tag }) =>
              tag !== NO_TAG ? (
                <Button
                  label="刪除標籤"
                  icon="pi pi-trash"
                  className="p-button-danger"
                  onClick={() => onLocalDelete(tag)}
                />
              ) : null
            }
            header="Delete"
          />
        </DataTable>
      )}
    </>
  )
}

TagStatistic.propTypes = {
  docs: PropTypes.arrayOf(PropTypes.object),
}

export function TagButton() {
  const tagFilterSelector = useRef(null)
  return (
    <>
      <ButtonLike
        data-tip="標籤"
        size={6}
        scaleOnHover
        onClick={e => {
          tagFilterSelector.current.toggle(e)
        }}
      >
        <FaTags />
      </ButtonLike>
      <OverlayPanel
        dismissable
        ref={tagFilterSelector}
        appendTo={document.body}
      >
        <Flex
          justifyContent="flex-begin"
          flexWrap="wrap"
          width="50vw"
          overflow="hidden"
          minWidth="1024px"
        >
          <StatisticDiv>
            <TagStatistic />
          </StatisticDiv>
        </Flex>
      </OverlayPanel>
    </>
  )
}

export function TagPanel({ doc, tagsContext, updateTag }) {
  const { privilege } = useContext(ProjectContext)
  const [tags, setTags] = useState(null)
  const [addTagFromContext, removeTagFromContext] = updateTag
  useEffect(() => {
    setTags(tagsContext || [])
  }, [tagsContext])

  const addTag = useCallback(
    tag => {
      addTagFromContext(get(doc, '_id'), tag)
    },
    [doc, addTagFromContext],
  )

  const removeTag = useCallback(
    tag => {
      removeTagFromContext(get(doc, '_id'), tag)
    },
    [doc, removeTagFromContext],
  )

  const { on, toggle } = useToggle()
  const tagInput = useRef(null)

  const [tagContent, setTagContent] = useState('')

  useEffect(() => {
    if (on && tagInput.current) tagInput.current.element.focus()
  }, [on, tagInput])

  return (
    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
      {
        // Use Loading compoment is too sluggish when there are more then 300
        // docs, so only use a simple string to reveal the current state.
      }
      {!tags && <div>Loading Tags...</div>}
      {!isEmpty(tags) &&
        tags.map(tag => (
          <PostIt key={tag} tag={tag} removeTag={() => removeTag(tag)} />
        ))}
      {on && (
        <InputText
          ref={tagInput}
          value={tagContent}
          onChange={({ target: { value } }) => {
            // To prevent a too long tag
            if (value.length < 10) setTagContent(value)
          }}
          onBlur={() => toggle()}
          onKeyPress={({ key }) => {
            if (key === 'Enter' && tagContent && !tags.includes(tagContent)) {
              if (tagContent !== 'lock' || privilege.includes('supervisor')) {
                addTag(tagContent)
              }
              setTagContent('')
              toggle()
            }
          }}
        />
      )}
      <ButtonLike
        margin="0 5px"
        disabled={isNil(tagsContext)}
        onClick={() => toggle()}
      >
        <MdAddCircle
          style={{
            width: '28px',
            height: '28px',
            color: isNil(tagsContext) ? 'gray' : 'white',
          }}
        />
      </ButtonLike>
    </div>
  )
}

TagPanel.propTypes = {
  doc: PropTypes.object.isRequired,
  tagsContext: PropTypes.arrayOf(PropTypes.string),
  updateTag: PropTypes.arrayOf(PropTypes.func).isRequired,
}

export function useTagFilter(modelId, tagMap) {
  const tags = useMemo(() => uniq(flatten(values(tagMap))), [tagMap])

  const [includeTags, setIncludeTag] = useLocalStorage(
    `includeTags.${modelId}`,
    [],
  )
  const [excludeTags, setExcludeTag] = useLocalStorage(
    `excludeTags.${modelId}`,
    [],
  )

  useEffect(() => {
    setIncludeTag(includeTag => filter(includeTag, tag => includes(tags, tag)))
    setExcludeTag(excludeTag => filter(excludeTag, tag => includes(tags, tag)))
  }, [tags, setIncludeTag, setExcludeTag])

  const includeOption = useMemo(
    () => filter(tags, tag => !includes(excludeTags, tag)),
    [tags, excludeTags],
  )
  const excludeOption = useMemo(
    () => filter(tags, tag => !includes(includeTags, tag)),
    [tags, includeTags],
  )

  const renderTagFilter = useCallback(
    () =>
      !isEmpty(tags) && (
        <Flex width="100%">
          <Flex flex="1 1 50%" flexWrap="wrap" p="1">
            <Flex color="blacks.8" width="100%">
              選擇標籤
            </Flex>
            <Box data-cy="include-tags" width="100%">
              <MultiSelect
                display="chip"
                style={{ width: '100%' }}
                options={includeOption}
                placeholder="Select tag filter"
                value={includeTags}
                onChange={({ value }) => setIncludeTag(value)}
              />
            </Box>
          </Flex>
          <Flex flex="1 1 50%" flexWrap="wrap" p="1">
            <Flex color="blacks.8" width="100%">
              排除標籤
            </Flex>
            <Box data-cy="exclude-tags" width="100%">
              <MultiSelect
                display="chip"
                style={{ width: '100%' }}
                options={excludeOption}
                placeholder="Select tag filter"
                value={excludeTags}
                onChange={({ value }) => setExcludeTag(value)}
              />
            </Box>
          </Flex>
        </Flex>
      ),
    [
      tags,
      excludeTags,
      includeTags,
      excludeOption,
      includeOption,
      setExcludeTag,
      setIncludeTag,
    ],
  )

  return [includeTags, excludeTags, renderTagFilter]
}
