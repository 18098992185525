import { useMemo, useContext, useEffect, useState } from 'react'
import { useImmer } from 'use-immer'
import { useQuery, useLazyQuery } from '@apollo/client'
import {
  castArray,
  compact,
  filter,
  get,
  uniq,
  uniqBy,
  sortBy,
  find,
  flatMap,
  head,
  isEmpty,
  map,
  set,
  xor,
  isNil,
  values,
  keys,
  size,
  indexOf,
  flatten,
} from 'lodash'

import { kgClient } from '@gql'

import { ProjectContext } from './ProjectContext'

import {
  _query_kg,
  _get_connected,
  _get_descendant,
  _get_n_layers,
  _query_kg_buid_graph,
} from './KGQuery.gql'

function useQueryKGOption(options) {
  const { kgNamespace } = useContext(ProjectContext)
  const appendNamespace = { ...options }
  if (isNil(appendNamespace?.variables?.namespace))
    set(appendNamespace, 'variables.namespace', kgNamespace)

  return appendNamespace
}

export function useLazyQueryKG(query, options) {
  const extended = useQueryKGOption(options)
  return useLazyQuery(query, {
    client: kgClient,
    ...extended,
  })
}

export function useQueryKG(query, options) {
  const extended = useQueryKGOption(options)
  return useQuery(query, {
    client: kgClient,
    ...extended,
  })
}

export function composeSortedOrderName(namedRelation) {
  return '.children_order_' + namedRelation
}

async function getConnectedNode(node_id, namespace, namedRelation) {
  const [connected, rootNode] = await Promise.all([
    kgClient.query({
      query: _get_connected,
      variables: {
        node_id,
        namespace,
        edge_types: isEmpty(namedRelation) ? null : castArray(namedRelation),
      },
    }),
    kgClient.query({
      query: _query_kg,
      variables: {
        query: {
          op: 'eq',
          field: 'node_id',
          value: node_id,
        },
        namespace,
      },
    }),
  ])

  const orders = get(
    find(get(rootNode, 'data.query_kg.0.attributes'), {
      name: composeSortedOrderName(namedRelation),
    }),
    'values',
  )

  return sortBy(get(connected, 'data.get_connected'), ({ node_id }) =>
    indexOf(orders, node_id),
  )
}

export function useGetConnectedNodes(roots, replaceNameByDisplayName = false) {
  const [nodes, setNodes] = useState(null)

  useEffect(() => {
    let mounted = true
    Promise.all(
      roots.map(({ node_id, namespace, namedRelation = '' }) =>
        getConnectedNode(node_id, namespace, namedRelation),
      ),
    ).then(results => {
      if (mounted) {
        setNodes(
          uniqBy(flatten(results), 'node_id').map(node => ({
            ...node,
            id: node.node_id,
            name: replaceNameByDisplayName
              ? get(
                  find(node.attributes, { name: 'display_name' }),
                  'values.0',
                  node.name,
                )
              : node.name,
          })),
        )
      }
    })
    return () => (mounted = false)
  }, [roots, setNodes, replaceNameByDisplayName])

  return nodes
}

export function useQueryAndCache(
  nodes,
  storeKey,
  replaceNameByDisplayName,
  namespace,
) {
  const { addToCacheStore, findInCacheStore, getCacheStore } = useContext(
    ProjectContext,
  )

  const willDownload = useMemo(
    () =>
      filter(nodes, node => !isNil(node) && !findInCacheStore(storeKey, node)),
    [nodes, findInCacheStore, storeKey],
  )

  const { data } = useQueryKG(_query_kg, {
    variables: {
      query: {
        op: 'OR',
        conditions: [
          {
            op: 'includes',
            field: 'node_id',
            values: willDownload,
          },
        ],
      },
      namespace,
    },
    skip: isEmpty(willDownload),
  })

  useEffect(() => {
    if (!data) return

    // cache downloaded KG nodes to prevent redundant network fetching.
    const { query_kg } = data
    query_kg.forEach(node => {
      addToCacheStore(
        storeKey,
        node.node_id,
        replaceNameByDisplayName
          ? {
              ...node,
              name: get(
                find(node.attributes, { name: 'display_name' }),
                'values.0',
                node.name,
              ),
            }
          : node,
      )
    })
  }, [
    addToCacheStore,
    data,
    nodes,
    willDownload,
    replaceNameByDisplayName,
    storeKey,
  ])

  const [result, setResult] = useImmer(null)
  useEffect(() => {
    setResult(draft => {
      if (isNil(nodes)) return null
      if (!draft && isEmpty(nodes)) return []
      if (!isEmpty(xor(map(draft, 'node_id'), nodes))) {
        return compact(map(nodes, node => findInCacheStore(storeKey, node)))
      }
    })
  }, [setResult, nodes, getCacheStore, findInCacheStore, storeKey])

  return result
}

export function useQueryAndCacheKGByNamespace(
  namespaceMap,
  replaceNameByDisplayName = false,
) {
  const first = useQueryAndCache(
    get(values(namespaceMap), 0),
    `kg_query.${get(keys(namespaceMap), 0)}`,
    replaceNameByDisplayName,
    get(keys(namespaceMap), 0),
  )
  const second = useQueryAndCache(
    get(values(namespaceMap), 1),
    `kg_query.${get(keys(namespaceMap), 1)}`,
    replaceNameByDisplayName,
    get(keys(namespaceMap), 1),
  )

  if (size(keys, namespaceMap) > 2)
    throw new Error(`We do not expect more then 2 namespace used in one type`)

  const result = useMemo(() => [...(first || []), ...(second || [])], [
    first,
    second,
  ])

  return result
}

export function useQueryAndCacheKG(nodes, replaceNameByDisplayName = false) {
  const { kgNamespace } = useContext(ProjectContext)
  return useQueryAndCache(
    nodes,
    `kg_query.${kgNamespace}`,
    replaceNameByDisplayName,
    kgNamespace,
  )
}

export function useKGChildren(root) {
  const { data } = useQueryKG(_get_descendant, {
    variables: {
      node_id: root,
    },
  })

  const children = useMemo(
    () =>
      !data
        ? null
        : data.get_descendant.map(({ node_id: id, name, node_type }) => ({
            id,
            name,
            node_type,
          })),
    [data],
  )

  return children
}

// The following functions are copy from kg-frontend.
export const parentToChildRelationMap = {
  channel_concept: {
    channel: 'InstanceOf',
    channel_concept: 'SubClassOf',
    target: 'SoldBy',
  },
  channel: {
    channel: 'ContainedBy',
    target: 'SoldBy',
  },
  target: {
    target: 'SubClassOf',
  },
}

export const childToParentRelationMap = (() => {
  const map = {}

  for (const parent in parentToChildRelationMap) {
    for (const child in parentToChildRelationMap[parent]) {
      if (!(child in map)) {
        map[child] = {}
      }
      if (!(parent in map[child])) {
        map[child][parent] = {}
      }
      map[child][parent] = parentToChildRelationMap[parent][child]
    }
  }

  return map
})()

export const upstreamRelations = node_type => {
  return uniq(Object.values(childToParentRelationMap[node_type]))
}

export function createNodeMap(root, keys = ['children']) {
  return graphVisitor(
    root,
    (node, map) => {
      map[node.node_id] = node
      return map
    },
    {},
    keys,
  )
}

export function graphVisitor(node, callback, total) {
  function visitor(node, callback, total, parent) {
    total = callback(node, total, parent)
    for (const child of node.children) {
      total = visitor(child, callback, total, node)
    }

    return total
  }
  return visitor(node, callback, total, null)
}

function getUpstreamRelations(node, namedRelation) {
  if (!isEmpty(namedRelation))
    return flatMap(
      node.out_relations.filter(({ name }) => name === namedRelation),
      'nodes',
    )

  const relations = upstreamRelations(node.node_type)
  return flatMap(
    node.relations.filter(({ name }) => relations.includes(name)),
    'nodes',
  )
}

function generateGraph(root, descendants, namedRelation) {
  function buildGraph(root, descendants) {
    const graph = {
      ...root,
      children: [],
      upstream: [],
    }

    function appendChild(ascendants, child, ascendantId) {
      const ascendant = ascendants.find(
        ({ node_id }) => node_id === ascendantId,
      )
      if (
        ascendant &&
        !ascendant.children.find(({ node_id }) => node_id === child.node_id)
      ) {
        ascendant.children.push(child)
      }
    }

    let ascendants = [graph]
    for (const level of descendants) {
      for (const child of level.flat()) {
        child.children = []
        child.upstream = getUpstreamRelations(child, namedRelation)

        for (const upstream of child.upstream) {
          appendChild(ascendants, child, upstream)
        }
      }
      ascendants = level.flat()
    }

    return graph
  }

  return buildGraph(root, descendants)
}

export function useBuildKGTree(node_id, namespace) {
  const { data: root } = useQueryKG(_query_kg_buid_graph, {
    variables: {
      query: {
        op: 'eq',
        field: 'node_id',
        value: node_id,
      },
      namespace,
    },
    skip: isNil(node_id),
  })

  const { data: layers } = useQueryKG(_get_n_layers, {
    variables: {
      node_id,
      depth: -1,
      namespace,
    },
    skip: isNil(root),
  })

  const result = useMemo(() => {
    if (isNil(root) || isNil(layers)) return null
    const node = head(root.query_kg)
    return generateGraph(
      { ...node, id: node.node_id },
      layers.get_n_layers.map(level =>
        level.map(node => ({ ...node, id: node.node_id })),
      ),
    )
  }, [root, layers])

  return result
}
