import { useCallback } from 'react'
import { isNil, castArray, find, partial } from 'lodash'

import { isLocalDoc, useDialog } from '@common'
import { createBlankDoc } from '@schema'
import { cceClient } from '@gql'

import { docPrivilege, namespace } from './config'
import { CardGroupEditor } from './editor'
import { cardGroupSchema } from './schema'
import { useCacheDocs, useKGTranspile } from '../Common'
import { useReferenceBase, filterTicketRef } from '../Reference'
import { useMutateTicket, canDeleteTicketRefDoc } from '../TicketHelper'
import OwnedDocSummaryModel, {
  useFetchTecklessDocs,
} from '../OwnedDocSummaryModel'
import { useUploadDocWithHistory, useDeleteDocWithHistory } from '../Uploader'

import { _uploadCardGroups, _removeCardGroups } from './listview.gql'

const useReference = partial(useReferenceBase, 'cardgroup', filterTicketRef)

export const useTranspile = partial(useKGTranspile, cardGroupSchema)

function useUploadCardGroup() {
  const upload = useUploadDocWithHistory(cardGroupSchema, async groups => {
    const {
      data: { uploadCardGroups },
    } = await cceClient.mutate({
      mutation: _uploadCardGroups,
      variables: {
        groups,
      },
    })

    return uploadCardGroups
  })

  return upload
}

function useController(_, selectedFields) {
  const groups = useFetchTecklessDocs(
    'CardGroup',
    cardGroupSchema,
    selectedFields,
  )
  const { updated, deleteCache, updateCache, docs } = useCacheDocs(
    null,
    true,
    null,
    groups,
  )

  const [showDialog, renderDialog] = useDialog(CardGroupEditor)
  const showDoc = useCallback(
    (doc, crud) => {
      showDialog({
        doc,
        crud,
      })
    },
    [showDialog],
  )

  const upload = useUploadCardGroup()
  const uploadDoc = useCallback(group => upload(null, group), [upload])

  const deleteCG = useDeleteDocWithHistory(cardGroupSchema, ids => {
    cceClient.mutate({
      mutation: _removeCardGroups,
      variables: {
        group_ids: castArray(ids),
      },
    })
  })
  const { deleteTicket } = useMutateTicket()
  const deleteDoc = useCallback(
    async _id => {
      deleteCache(_id)
      const deleted = find(docs, { _id })
      if (isNil(deleted) || isLocalDoc(deleted)) return
      const [canDelete, ticketIds] = await canDeleteTicketRefDoc(
        { _id },
        namespace,
      )
      if (canDelete) await deleteTicket(ticketIds, 'RewardCard')
      return deleteCG(deleted)
    },
    [deleteCache, docs, deleteCG, deleteTicket],
  )

  const createDoc = useCallback(() => {
    const group = createBlankDoc(cardGroupSchema, true)
    updateCache(group)

    return group
  }, [updateCache])

  return {
    isReady: !isNil(docs),
    docs,
    createDoc,
    showDoc,
    updateDoc: updateCache,
    updated,
    renderDoc: renderDialog,
    uploadDoc,
    deleteDoc,
    useReference,
  }
}

const defaultSummary = {
  selected: ['card_name', 'scope', 'card_provider_id'],
  filters: {},
}

const OwnedCardGroupListView = props => {
  return (
    <OwnedDocSummaryModel
      {...props}
      crud={{
        read: true,
        update: true,
        create: true,
        delete: true,
        overwrite: true,
      }}
      useController={useController}
      useTranspile={useTranspile}
      defaultSummary={defaultSummary}
      requiredPrivilege={[docPrivilege]}
      currentSchema={cardGroupSchema}
    />
  )
}

export default OwnedCardGroupListView
