import PropTypes from 'prop-types'
import { partial } from 'lodash'

import { namespace, schema } from './config'
import { CardEditDialog } from './editor'
import { cardSchema } from './schema'
import { getTicketDocPath } from '../Common'
import { referenceDoc } from '../Reference'
import { useRestoreDoc, RestoreDialog } from '../Restore'
import { useFetchDocAndTicket } from '../DocHelper'
import { EditorLoader } from '../SchemaBaseEditorLoader'

import { _queryCard } from './loader.gql'

const getPath = partial(getTicketDocPath, namespace, schema)

export const queryManifest = {
  gql: _queryCard,
  name: 'queryCard',
  getPath,
}

export const generateReferenceDoc = partial(
  referenceDoc,
  'name',
  getPath,
  schema,
  namespace,
)

export function CardRestorer({ doc }) {
  return (
    <RestoreDialog
      DialogComp={CardEditDialog}
      useRestoreDoc={partial(useRestoreDoc, doc, namespace)}
    />
  )
}

CardRestorer.propTypes = {
  doc: PropTypes.object.isRequired,
}

function CardEditorLoader({ docId }) {
  return (
    <EditorLoader
      DialogComp={CardEditDialog}
      useFetchDocAndTicket={partial(
        useFetchDocAndTicket,
        _queryCard,
        'queryCard',
        namespace,
        docId,
      )}
      currentSchema={cardSchema}
    />
  )
}

CardEditorLoader.propTypes = {
  docId: PropTypes.string.isRequired,
}

export default CardEditorLoader
