import {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
  memo,
} from 'react'
import PropTypes from 'prop-types'
import 'styled-components/macro'
import styled from 'styled-components'
import css from '@styled-system/css'
import { useMutation } from '@apollo/client'
import { MdError } from 'react-icons/md'
import { AiFillMediumCircle } from 'react-icons/ai'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { Steps } from 'primereact/steps'
import { InputText } from 'primereact/inputtext'
import {
  isEmpty,
  isEqual,
  get,
  find,
  filter,
  map,
  compact,
  cloneDeep,
  size,
  isNil,
  difference,
  noop,
  last,
  uniq,
  flatMap,
  split,
  findIndex,
} from 'lodash'
import { toast } from 'react-toastify'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import {
  Box,
  Flex,
  Text,
  Grid,
} from '@changingai/react-editor-common-component'

import {
  TicketBasePropType,
  TicketSubState,
  getActiveState,
  validateEmail,
  ProjectContext,
  useDialog,
} from '@common'
import {
  Card,
  InputContainer,
  ButtonLike,
  StyledChips,
  ConfirmDialog,
} from '@widget'

import { getStateIndex } from './common'

import { getViewManifest } from '../Config'
import { MemoPanel } from '../MemoPanel'
import { getNamespaceByScopeType, getTicketProfile } from '../TicketHelper'
import { TagPanel } from '../TagPanel'

import {
  _assignTickets,
  _rollbackTickets,
  _popTicketState,
  _renameTickets,
  _returnTicket,
  _returnTwoConfirmors,
} from './item.gql'

const Label = styled(Text)``

Label.defaultProps = {
  color: 'black',
  px: 2,
}

const NormalValue = styled(Text)``

NormalValue.defaultProps = {
  color: 'blacks.8',
  px: 2,
  fontSize: 1,
}
function isTheFirstState({ active_state, states }) {
  return get(states, '0.main_state') === active_state
}

function isTicketDispatch(ticket) {
  return getActiveState(ticket).sub_state === TicketSubState.DISPATCHED
}

function unassignTicket(ticket) {
  const index = findIndex(ticket.states, { main_state: ticket.active_state })

  ticket.states[index].sub_state = TicketSubState.INITIATED
  ticket.states[index].assignees = []
}

function moveBackwardState(ticket) {
  const index = findIndex(ticket.states, { main_state: ticket.active_state })
  if (index === 0) return

  ticket.active_state = ticket.states[index - 1].main_state
  ticket.states[index].assignees = []
  ticket.states[index - 1].sub_state = TicketSubState.DISPATCHED
  ticket.states[index - 1].confirmors = []
}

function AssigneesChip({ ticket, setTickets, invalid }) {
  const { assigneeWhiteList } = useContext(ProjectContext)
  const { _id, editContext } = ticket
  const currentIndex = getStateIndex(editContext, editContext.active_state)

  const updateAssignees = useCallback(
    (index, assignees) => {
      setTickets(draft => {
        const ticket = find(draft, { _id })
        ticket.editContext.states[index].assignees = compact(
          uniq(
            assignees.map(assignee => {
              const email =
                assignee.indexOf('@') === -1
                  ? assignee + '@changing.ai'
                  : assignee
              return assigneeWhiteList.includes(email) ? email : null
            }),
          ),
        )
        ticket.editContext.action = 'assign'
      })
    },
    [setTickets, assigneeWhiteList, _id],
  )

  return (
    <Flex overflow="auto" width="100%" flexWrap="wrap" my="2">
      <Flex justifyContent="flex-start" alignItems="center" width="100%">
        {editContext.states.map((state, index) => {
          if (index === ticket.states.length - 1) return null

          const disabled =
            index !== currentIndex ||
            state.sub_state === TicketSubState.DISPATCHED ||
            get(editContext, 'action', 'assign') !== 'assign'
          const enableShift = !disabled && state.assignees.length === 0
          return (
            <Flex
              flex={`1 1 ${100 / ticket.states.length}%`}
              key={state.main_state}
              flexWrap="wrap"
            >
              <Flex width="100%">
                {index !== 0 && (
                  <ButtonLike
                    disabled={!enableShift}
                    onClick={() => {
                      setTickets(draft => {
                        const { editContext } = find(draft, { _id })
                        const prevAssignees =
                          editContext.states[index - 1].assignees
                        editContext.states[
                          index
                        ].assignees = prevAssignees.slice(
                          0,
                          editContext.states[index].assignee_quota,
                        )
                        editContext.action = 'assign'
                      })
                    }}
                  >
                    <FaArrowAltCircleRight
                      style={{
                        color: enableShift ? 'black' : 'gray',
                        margin: '5px',
                      }}
                    />
                  </ButtonLike>
                )}
                <StyledChips
                  css={css({
                    '&& .p-inputtext': {
                      borderRadius: 0,
                    },
                  })}
                  value={state.assignees}
                  onChange={({ value }) => {
                    updateAssignees(
                      index,
                      flatMap(value, email => split(email, /[;|,|\s]/)),
                    )
                  }}
                  itemTemplate={assignee => {
                    const returned = state.confirmors.includes(assignee)
                    return (
                      <Box color={returned ? 'yellow' : 'white'}>
                        {`${assignee.substring(0, assignee.indexOf('@'))}${
                          returned ? '(Return)' : ''
                        }`}
                      </Box>
                    )
                  }}
                  disabled={disabled}
                />
              </Flex>
              <Flex
                p="1"
                mx="1"
                height="30px"
                border="1px solid"
                borderColor="blacks.2"
                bg={currentIndex === index ? 'azures.1' : 'blacks.1'}
                width="100%"
                alignItems="center"
              >
                {currentIndex === index && (
                  <Box color="red" height="20px">
                    {invalid.join(', ')}
                  </Box>
                )}
              </Flex>
            </Flex>
          )
        })}
      </Flex>
    </Flex>
  )
}

AssigneesChip.propTypes = {
  ticket: TicketBasePropType.isRequired,
  setTickets: PropTypes.func.isRequired,
  invalid: PropTypes.arrayOf(PropTypes.string).isRequired,
}

function useTicketCRUD(ticketType, updateTicket) {
  const [assignTickets] = useMutation(_assignTickets)
  const [rollbackTickets] = useMutation(_rollbackTickets)

  const assign = useCallback(
    async ticket => {
      const result = await assignTickets({
        variables: {
          input: [
            {
              _id: ticket._id,
              whos: getActiveState(ticket.editContext).assignees,
            },
          ],
          namespace: getNamespaceByScopeType(ticketType),
        },
      })

      return get(result, 'data.assignTickets.0')
    },
    [assignTickets, ticketType],
  )

  const [renameTicketMutation] = useMutation(_renameTickets)
  const rename = useCallback(
    async ticket => {
      const result = await renameTicketMutation({
        variables: {
          input: [
            {
              _id: ticket._id,
              name: ticket.editContext.name,
            },
          ],
          namespace: getNamespaceByScopeType(ticketType),
        },
      })

      return get(result, 'data.renameTickets.0')
    },
    [renameTicketMutation, ticketType],
  )

  const rollback = useCallback(
    async ticket => {
      const rollback_count = get(ticket, 'editContext.rollback')
      const result = await rollbackTickets({
        variables: {
          input: [
            {
              _id: ticket._id,
              rollback_count,
            },
          ],
          namespace: getNamespaceByScopeType(ticketType),
        },
      })

      return get(result, 'data.rollbackTickets.0')
    },
    [rollbackTickets, ticketType],
  )

  const onUpload = useCallback(
    async ticket => {
      try {
        const results = []
        if (ticket.name !== ticket.editContext.name)
          results.push(await rename(ticket))
        if (get(ticket, 'editContext.action') === 'rollback')
          results.push(await rollback(ticket))
        if (get(ticket, 'editContext.action') === 'assign')
          results.push(await assign(ticket))

        const updated = compact(results)
        if (!isEmpty(updated)) {
          toast.success('Upload Successfully!', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          })

          updateTicket(last(updated))
        }
      } catch (e) {
        toast.error(`Error: ${e}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        })
      }
    },
    [rename, assign, rollback, updateTicket],
  )

  return onUpload
}

export const TicketItem = memo(function TicketItem({
  ticket,
  setTickets,
  selected,
  onToggle,
  ticketType,
  updateTicket,
  tagMemoContext,
  useScopeName,
}) {
  const upload = useTicketCRUD(ticketType, updateTicket)
  const disableRename = useMemo(
    () =>
      get(
        find(getViewManifest(), { backendDocType: ticketType }),
        'ticketProfile.fixedTickeName',
        false,
      ),
    [ticketType],
  )
  const { tags, updateTag, memos, updateMemo } = tagMemoContext
  const memo = useMemo(() => get(memos, ticket._id, ''), [memos, ticket._id])

  const tag = useMemo(() => get(tags, ticket._id, []), [tags, ticket._id])

  const { _id, scope_id, name, last_modified, editContext, states } = ticket
  const [changed, setChanged] = useState(false)
  const invalid = useMemo(() => {
    const assignees = getActiveState(editContext).assignees
    const invalid = []
    if (assignees.some(email => !validateEmail(email))) {
      invalid.push('email must be valid')
    }

    if (
      assignees.length !== 0 &&
      getActiveState(editContext).assignee_quota !== assignees.length
    ) {
      invalid.push(`${getActiveState(editContext).assignee_quota} assignee(s)`)
    }

    return invalid
  }, [editContext])

  const model = useMemo(
    () => states.map(({ main_state }) => ({ label: main_state })),
    [states],
  )
  const [localName, setLocalName] = useState(name)
  useEffect(() => {
    if (
      !isEqual(states, editContext.states) ||
      localName !== name ||
      'rollback' in editContext
    ) {
      setChanged(true)
      return
    }

    setChanged(false)
  }, [states, localName, editContext, setChanged, _id, invalid, name])

  const [uploading, setUploading] = useState(false)
  const [canRollback, canReset] = useMemo(
    () => [
      // Disable rollback if we apply any other kind of action on this ticket.
      get(editContext, 'action', 'rollback') === 'rollback' &&
        (!isTheFirstState(editContext) ||
          getActiveState(editContext).sub_state !== TicketSubState.INITIATED) &&
        !uploading,
      (!isEqual(ticket.states, editContext.states) ||
        ticket.active_state !== editContext.active_state ||
        localName !== name) &&
        !uploading,
    ],
    [localName, name, editContext, ticket, uploading],
  )

  const [popState] = useMutation(_popTicketState)
  const [showConfirmDialog, renderConfirmDialog] = useDialog(ConfirmDialog)
  const readOnly = useMemo(() => {
    const existedStates = map(states, 'main_state')
    const profiles = filter(
      getTicketProfile(ticketType).states,
      ({ main_state }) => existedStates.includes(main_state),
    )
    return size(compact(map(profiles, 'removeable'))) === 0
  }, [states, ticketType])

  const { privilege } = useContext(ProjectContext)
  const canForceReturn = useMemo(() => {
    if (!isNil(get(editContext, 'action'))) return false

    const { confirmors, assignees } = getActiveState(editContext)
    return !isEmpty(difference(assignees, confirmors))
  }, [editContext])
  const [returnOneConfirmor] = useMutation(_returnTicket)
  const [returnTwoConfirmors] = useMutation(_returnTwoConfirmors)
  const onForceReturn = useCallback(async () => {
    const { confirmors, assignees } = getActiveState(ticket)
    const needReturn = difference(assignees, confirmors)
    if (size(needReturn) !== 1 && size(needReturn) !== 2) {
      throw new Error(
        `We expect one or two confirmors but get ${size(needReturn)}`,
      )
    }

    async function returnConfirmor() {
      if (size(needReturn) === 1) {
        const {
          data: { returnTicket },
        } = await returnOneConfirmor({
          variables: {
            input: {
              _id: ticket._id,
              confirmor: get(needReturn, '0'),
            },
            namespace: getNamespaceByScopeType(ticket.scope_type),
          },
        })

        return returnTicket
      } else {
        // The backend can not handle two successive returnTicket calls well.
        // We walkaround this bug by using one composed call.
        const {
          data: { The2ndReturn },
        } = await returnTwoConfirmors({
          variables: {
            input1: {
              _id: ticket._id,
              confirmor: get(needReturn, '0'),
            },
            input2: {
              _id: ticket._id,
              confirmor: get(needReturn, '1'),
            },
            namespace: getNamespaceByScopeType(ticket.scope_type),
          },
        })
        return The2ndReturn
      }
    }

    updateTicket(await returnConfirmor())
  }, [ticket, returnOneConfirmor, returnTwoConfirmors, updateTicket])

  const scopeName = useScopeName(scope_id)

  return (
    <Card position="relative" my="1" boxShadow="none">
      {renderConfirmDialog({
        onYes: async (action, userdata) => {
          if (action === 'DeleteState') {
            const { data } = await popState({
              variables: {
                input: {
                  _id,
                  pop_state: get(
                    getTicketProfile(ticketType).states[userdata],
                    'main_state',
                  ),
                },
                namespace: getNamespaceByScopeType(ticketType),
              },
            })
            updateTicket(data.popTicketState)
          }
          if (action === 'ForceReturn') {
            await onForceReturn()
          }
          if (action === 'UploadTicket') {
            upload(ticket)
          }
        },
        onNo: noop,
      })}
      <Flex
        position="relative"
        overflow="auto"
        flexWrap="wrap"
        width="100%"
        border="1px solid gray"
      >
        <Flex
          px="2"
          alignItems="center"
          color="white"
          bg="DarkSlateBlue"
          flex="1 1 100%"
          justifyContent="space-between"
        >
          <Flex alignItems="center" mx={2}>
            <Checkbox
              onChange={() => onToggle(ticket._id)}
              checked={selected}
            />
            <Text mx={2}>{name}</Text>
          </Flex>
          <TagPanel doc={ticket} tagsContext={tag} updateTag={updateTag} />
        </Flex>
        <Flex width="100%" my="1">
          <Flex flex="1 1 33%">
            <Steps
              style={{ width: '90%' }}
              model={model}
              activeIndex={getStateIndex(editContext, editContext.active_state)}
              onSelect={({ index }) => {
                if (
                  get(
                    getTicketProfile(ticketType).states[index],
                    'removeable',
                  ) &&
                  getStateIndex(editContext, editContext.active_state) !== index
                ) {
                  showConfirmDialog({
                    title: 'Warning',
                    message: '確定刪除此步驟?',
                    action: 'DeleteState',
                    userdata: index,
                    needPrivilege: true,
                  })
                }
              }}
              readOnly={readOnly}
            />
          </Flex>
          <Flex flex="1 1 33%" flexWrap="wrap" alignItems="center">
            {!disableRename && (
              <>
                <Label mr="2">名稱</Label>
                <InputContainer
                  width="100%"
                  css={css({
                    '&& .p-inputtext': {
                      color: localName === name ? 'black' : 'blue',
                    },
                  })}
                >
                  <InputText
                    value={localName}
                    onChange={({ target: { value } }) => {
                      setLocalName(value)
                      setTickets(draft => {
                        const current = find(draft, { _id })
                        current.editContext.name = value
                      })
                    }}
                  />
                </InputContainer>
              </>
            )}

            <Grid
              width="100%"
              justifyContent="center"
              alignItems="center"
              gridTemplateColumns="auto 1fr auto 1fr"
              gridGap="1px"
              my="2"
            >
              <Label>{'代碼'}</Label>
              <NormalValue>{_id}</NormalValue>
              <Label>{'狀態'}</Label>
              <NormalValue
                color={
                  isTicketDispatch(ticket.editContext) ? 'blacks.8' : 'red'
                }
              >
                {isTicketDispatch(ticket.editContext) ? '已分配' : '未分配'}
              </NormalValue>
              <Label>{'範圍'}</Label>
              <NormalValue>{scopeName}</NormalValue>
              <Label>{'修改日期'}</Label>
              <NormalValue>
                {new Date(last_modified).toLocaleDateString('zh-Hans-CN')}
              </NormalValue>
            </Grid>
          </Flex>
          <Flex flex="1 1 33%" p="1" flexWrap="wrap">
            <MemoPanel
              doc={ticket}
              memoContext={memo}
              updateMemo={updateMemo}
            />
          </Flex>
        </Flex>
        <AssigneesChip
          ticket={ticket}
          setTickets={setTickets}
          invalid={invalid}
        />
        <Flex justifyContent="flex-end" width="100%" mb="1" mr="1">
          {privilege.includes('supervisor') && (
            <Button
              className="p-button-warning"
              label="ForceReturn"
              disabled={!canForceReturn}
              onClick={() => {
                showConfirmDialog({
                  title: 'Warning',
                  message: '強制歸還ticket?',
                  action: 'ForceReturn',
                  needPrivilege: true,
                })
              }}
            />
          )}
          <Button
            style={{ marginLeft: '5px' }}
            label="Rollback"
            className="p-button-primary"
            disabled={!canRollback}
            onClick={() => {
              setTickets(draft => {
                const context = get(find(draft, { _id }), 'editContext')
                if (isTicketDispatch(context)) unassignTicket(context)
                else moveBackwardState(context)
                context.action = 'rollback'
                context.rollback = get(context, 'rollback', 0) + 1
              })
            }}
          />
          <Button
            style={{ marginLeft: '5px' }}
            label="Reset"
            className="p-button-warning"
            disabled={!canReset}
            onClick={() => {
              setTickets(draft => {
                const ticket = find(draft, { _id })
                ticket.editContext = {
                  active_state: ticket.active_state,
                  states: cloneDeep(ticket.states),
                  invalid: false,
                }
              })
              setLocalName(name)
            }}
          />
          <Button
            style={{ marginLeft: '5px' }}
            label="Upload"
            className="p-button-success"
            disabled={!canReset}
            icon={uploading ? 'pi pi-spin pi-spinner' : 'pi pi-cloud-upload'}
            onClick={async () => {
              setUploading(true)
              await upload(ticket)
              setUploading(false)
            }}
          />
        </Flex>
      </Flex>

      <Flex
        p="2"
        justifyContent="flex-end"
        position="absolute"
        left="0"
        bottom="0"
      >
        <AiFillMediumCircle
          style={{
            visibility: changed ? 'visible' : 'hidden',
            color: 'LightSeaGreen',
            margin: '5px',
          }}
        />
        <MdError
          style={{
            visibility: !isEmpty(invalid) ? 'visible' : 'hidden',
            color: 'red',
            margin: '5px',
          }}
        />
      </Flex>
    </Card>
  )
})

TicketItem.propTypes = {
  ticket: TicketBasePropType.isRequired,
  setTickets: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  ticketType: PropTypes.string.isRequired,
  updateTicket: PropTypes.func.isRequired,
  tagMemoContext: PropTypes.object.isRequired,
  useScopeName: PropTypes.func.isRequired,
}
