import { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { get, isEmpty, keys, isNil } from 'lodash'
import { InputText } from 'primereact/inputtext'
import { Tag } from 'primereact/tag'
import { Link } from 'react-router-dom'
import { Box, Flex, Text } from '@changingai/react-editor-common-component'

import { HomePanel } from './common'
import SummaryDoc from '../SummaryDoc'
import { createLayoutSchema } from '../SummaryFields'
import { isMongoId } from '@common'
import { SchemaStore, SchemaLabels } from '@schema'
import { useTryLoad } from '@view'

function SummaryDocHost({ schema, doc, useTranspile }) {
  const [transpiled] = useTranspile([doc])
  const layoutSchema = useMemo(
    () => createLayoutSchema(get(SchemaStore, schema)),
    [schema],
  )
  return (
    <SummaryDoc
      currentSchema={get(SchemaStore, schema)}
      doc={doc}
      transpiled={transpiled}
      crud={{
        create: false,
        read: true,
        update: false,
        delete: false,
        overwrite: false,
      }}
      selectedFields={keys(get(SchemaStore, schema))}
      updated={false}
      highlightWhenHover={false}
      layoutSchema={layoutSchema}
    />
  )
}

SummaryDocHost.propTypes = {
  schema: PropTypes.string.isRequired,
  doc: PropTypes.object.isRequired,
  useTranspile: PropTypes.func.isRequired,
}

export function DocLoader() {
  const [inputText, setInputText] = useState('')
  const match = useTryLoad(inputText)
  const urls = useMemo(
    () => (isNil(match) ? null : match.config.queryManifest.getPath(match.doc)),
    [match],
  )
  return (
    <HomePanel caption="文件搜尋">
      <Flex alignItems="center" width="100%" data-cy="doc_search">
        <Text mr="2" fontSize="h1">
          輸入文件代碼
        </Text>
        <InputText
          style={{
            width: '300px',
          }}
          value={inputText}
          onChange={({ target: { value } }) => {
            setInputText(value)
          }}
        />
        {!isNil(urls) && (
          <>
            {!isNil(urls.docUrl) && (
              <Box mx="2">
                <Link
                  data-cy="doc-link"
                  to={urls.docUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  文件連結
                </Link>
              </Box>
            )}
            {!isNil(urls.listviewUrl) && (
              <Box mx="2">
                <Link
                  data-cy="ticket-link"
                  to={`${urls.listviewUrl}?search=${inputText}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  載入列表
                </Link>
              </Box>
            )}
            <Box mx="2" data-cy="doc_format">
              文件格式
            </Box>
            <Tag value={SchemaLabels[match.config.schema]} />
          </>
        )}
      </Flex>
      {!isNil(match) && (
        <>
          {/*
              set key prop base on current schema, so that we can have one component
              instance per useTranspile
            */}
          <SummaryDocHost
            key={get(match, 'config.schema')}
            schema={get(match, 'config.schema')}
            doc={get(match, 'doc')}
            useTranspile={get(match, 'config.useTranspile')}
          />
        </>
      )}
      {!isMongoId(inputText) && !isEmpty(inputText) && (
        <Box color="red">(無效的文件代碼)</Box>
      )}
      {isMongoId(inputText) && isEmpty(match) && (
        <Box color="gray">(無符合文件)</Box>
      )}
    </HomePanel>
  )
}
