import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Flex } from '@changingai/react-editor-common-component'
import { isEmpty } from 'lodash'
import { amountOptionSchema } from './schema'
import { inputFactory, useRenderConfig } from '@widget'

export function AmontOptions({
  context,
  onDirty,
  onUpdateContext,
  onError,
  currentPath,
}) {
  const renderer = useRenderConfig({
    onError,
    onUpdateContext,
    onDirty,
    editContext: context,
    currentSchema: amountOptionSchema,
    currentPath,
  })

  const configs = useMemo(
    () => [
      inputFactory(amountOptionSchema, 'name', {
        labelAttributes: { width: '100px' },
        width: '50%',
      }),
      inputFactory(amountOptionSchema, 'unit', {
        labelAttributes: { width: '100px' },
        width: '50%',
      }),
      inputFactory(amountOptionSchema, 'type', {
        labelAttributes: { width: '100px' },
        width: '50%',
      }),
      inputFactory(amountOptionSchema, 'range', {
        labelAttributes: { width: '100px' },
        disabled: context.type !== 'range',
        allowDuplicate: true,
      }),
      inputFactory(amountOptionSchema, 'list', {
        labelAttributes: { width: '100px' },
        disabled: context.type !== 'list',
      }),
      inputFactory(amountOptionSchema, 'default', {
        labelAttributes: { width: '100px' },
        disabled: isEmpty(context.type),
      }),
      inputFactory(amountOptionSchema, 'private_code', {
        labelAttributes: { width: '100px' },
        width: '50%',
      }),
    ],
    [context],
  )

  return (
    <Flex width="100%" flexWrap="wrap">
      {configs.map(config => renderer(config))}
    </Flex>
  )
}

AmontOptions.propTypes = {
  context: PropTypes.object.isRequired,
  onDirty: PropTypes.func.isRequired,
  onUpdateContext: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  currentPath: PropTypes.arrayOf(PropTypes.string).isRequired,
}
