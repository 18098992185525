import { useState, useContext, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  countBy,
  escapeRegExp,
  findIndex,
  get,
  has,
  isEmpty,
  map,
  sumBy,
  filter,
  includes,
  pick,
} from 'lodash'
import { useQuery } from '@apollo/client'
import { Chart } from 'primereact/chart'
import { DataView } from 'primereact/dataview'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Steps } from 'primereact/steps'
import styled from 'styled-components'
import { GoPerson } from 'react-icons/go'
import { IoMdOpen } from 'react-icons/io'
import { TiFolderOpen } from 'react-icons/ti'

import {
  Flex,
  Box,
  StyledOverlay,
} from '@changingai/react-editor-common-component'

import {
  useTickets,
  getNamespaceByScopeType,
  getTicketProfile,
  useTicketStateStorage,
} from '../TicketHelper'
import { PeepModeIcon } from '../Shell'
import {
  ProjectContext,
  TicketState,
  TicketBaseShape,
  getActiveState,
  TicketSubState,
  isTicketViewable,
  isWaitingTicketAccessRight,
} from '@common'
import { Checkbox as IconCheckbox, ButtonLike } from '@widget'
import theme from '@theme'

import Pikachu from '../../img/not-assigned.jpg'
import { cceClient } from '@gql'

import { _queryTickets, _queryTicketsMeta } from './select.gql'

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

const Title = styled.div`
  flex: 1 0 100%;
  color: white;
  background-color: #4267b2;
  text-align: center;
  font-size: 1.5em;
`

const ListRoot = styled.div`
  margin: 0;
  width: 100%;
  div .p-inputtext {
    border-radius: 0;
  }
`

const StyledDataView = styled(DataView)`
  width: 100%;
  height: 100%;
  && .p-dataview-content {
    height: 526px;
    width: 100%;
  }
`

const Colors = {
  FullMatch: 'MediumBlue',
  PartialMatch: 'SeaGreen',
  NoMatch: 'OrangeRed',
}

const Labels = {
  FullMatch: '完全吻合',
  PartialMatch: '部分吻合',
  NoMatch: '未配對',
}

function TicketInfoPanel({ ticket }) {
  const { loading, data } = useQuery(_queryTicketsMeta, {
    client: cceClient,
    variables: {
      filter: {
        ticket_ids: [ticket._id],
      },
      namespace: getNamespaceByScopeType(ticket.scope_type),
    },
  })

  const assignees = useMemo(() => {
    const assignee_meta = get(data, 'queryTickets.0.meta.assignee_meta')
    return !isEmpty(assignee_meta)
      ? assignee_meta
      : get(ticket, 'assignees', []).map(assignee => ({
          assignee,
          own_docs: 0,
          fully_matched_docs: 0,
        }))
  }, [ticket, data])

  const total = useMemo(() => sumBy(assignees, 'own_docs'), [assignees])
  const colors = ['black', 'gray']

  const items = map(ticket.states, ({ main_state }) => ({ label: main_state }))
  const currentState = findIndex(
    items,
    ({ label }) => label === ticket.active_state,
  )

  const docs = useMemo(
    () => [
      get(assignees, [0, 'own_docs'], 0),
      get(assignees, [1, 'own_docs'], 0),
    ],
    [assignees],
  )

  const info = useMemo(
    () =>
      isEmpty(assignees)
        ? null
        : {
            labels: [Labels.FullMatch, Labels.PartialMatch, Labels.NoMatch],
            datasets: [
              {
                data: [
                  assignees[0].fully_matched_docs,
                  Math.min(...docs) - assignees[0].fully_matched_docs,
                  Math.abs(docs[0] - docs[1]),
                ],
                backgroundColor: [
                  Colors.FullMatch,
                  Colors.PartialMatch,
                  Colors.NoMatch,
                ],
                hoverBackgroundColor: [
                  Colors.FullMatch,
                  Colors.PartialMatch,
                  Colors.NoMatch,
                ],
              },
            ],
          },
    [assignees, docs],
  )

  const options = {
    legend: {
      display: false,
    },
  }

  const activeState = useMemo(() => getActiveState(ticket), [ticket])
  const confirmors = useMemo(() => get(activeState, 'confirmors'), [
    activeState,
  ])
  return (
    <Flex
      height="100%"
      flexDirection="column"
      p="2"
      justifyContent="space-between"
      bg="blacks.0"
    >
      <StyledOverlay active={loading} />
      <Box
        mb="2"
        color={
          activeState.sub_state === TicketSubState.INITIATED ? 'gray' : 'black'
        }
        jc="center"
        flex="0 0 10px"
        fontSize="h1"
      >
        {`${ticket.name}(${activeState.sub_state})`}
      </Box>
      {isEmpty(assignees) && (
        <Flex justifyContent="center" flexWrap="wrap">
          <img alt="Not dispatched yet" src={Pikachu} />
          <h1>{'なんか寂しいナァ'}</h1>
        </Flex>
      )}
      {assignees.map(({ assignee, own_docs, fully_matched_docs }, index) => (
        <Box key={assignee} mb="2" flex="0 0 50px">
          <Flex width="100%" alignItems="center">
            <GoPerson
              style={{
                color: colors[index],
                width: '40px',
                height: '40px',
                margin: '5px',
                flex: '0 0 auto',
              }}
            />
            <Box>
              {assignee}
              {confirmors.includes(assignee) && (
                <span style={{ fontWeight: 'bold' }}>(Returned)</span>
              )}
            </Box>
          </Flex>
          <Flex
            flex="1 0 auto"
            flexDirection="column"
            justifyContent="space-between"
            position="relative"
          >
            {/* Total amount bar */}
            {own_docs > 0 && (
              <Box
                width={`${(100 * own_docs) / total}%`}
                bg="LightSeaGreen"
                height="4"
                borderRadius="0 2 2 0"
              />
            )}
            {/* Full match bar */}
            {fully_matched_docs > 0 && (
              <Box
                position="absolute"
                left="0"
                bottom="0"
                width={`${(100 * fully_matched_docs) / total}%`}
                bg={Colors.FullMatch}
                height="2"
              />
            )}
            <Box position="absolute" right="2" bottom="0" fontSize="2em">
              {own_docs}
            </Box>
          </Flex>
        </Box>
      ))}
      {!!info && (
        <Flex width="100%" justifyContent="center" my="2">
          <Chart
            type="doughnut"
            data={info}
            options={options}
            width="200px"
            height="200px"
          />
        </Flex>
      )}
      <Box flex="1 0 auto">
        <Steps
          style={{ width: '100%' }}
          model={items}
          activeIndex={currentState}
        />
      </Box>
    </Flex>
  )
}

TicketInfoPanel.propTypes = {
  ticket: PropTypes.shape(
    pick(TicketBaseShape, [
      '_id',
      'name',
      'scope_type',
      'active_state',
      'states',
    ]),
  ).isRequired,
}

function TicketItem({
  ticket,
  setSelected,
  onOpen,
  email,
  privilege,
  selected,
}) {
  const [mouseEnter, onMouseEnter] = useState(false)

  const backgroundColor = useMemo(() => {
    if (selected === ticket) return '#007ad9'
    return mouseEnter ? '#f4f4f4' : 'white'
  }, [ticket, selected, mouseEnter])

  const iconColor =
    selected === ticket ? theme.colors.lightYello : theme.colors.icon

  return (
    <Flex
      onMouseEnter={() => onMouseEnter(true)}
      onMouseLeave={() => onMouseEnter(false)}
      onClick={() => setSelected(ticket)}
      p="2"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      backgroundColor={backgroundColor}
      style={{ cursor: 'pointer' }}
      height="50px"
    >
      <Box>
        <Box
          flex="0 1"
          fontSize="h3"
          color={selected === ticket ? 'white' : 'black'}
        >{`${ticket.name}(${ticket.active_state}).`}</Box>
        {isWaitingTicketAccessRight(ticket, email) && (
          <Box
            flex="0 1"
            fontSize="small"
            color="darkHotPink"
          >{`等待首位編輯者交回ticket.`}</Box>
        )}
      </Box>
      <Flex>
        {map(
          [[TiFolderOpen, false], [IoMdOpen, true]],
          ([Icon, openInNewTab], index) => (
            <ButtonLike
              key={index}
              size={6}
              onClick={() => onOpen(ticket, openInNewTab)}
              disabled={
                !isTicketViewable(ticket, privilege) ||
                isWaitingTicketAccessRight(ticket, email)
              }
            >
              <Icon style={{ color: iconColor }} />
            </ButtonLike>
          ),
        )}
      </Flex>
    </Flex>
  )
}

TicketItem.propTypes = {
  ticket: PropTypes.object.isRequired,
  setSelected: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  privilege: PropTypes.array.isRequired,
  selected: PropTypes.object,
}

function TicketList({ scopeType, onSelected }) {
  const [selected, setSelected] = useState(null)
  const [mainState, setMainState] = useTicketStateStorage(
    scopeType,
    'selectDialog',
  )
  const {
    email,
    peepMode: originPeepMode,
    setPeepMode,
    privilege,
  } = useContext(ProjectContext)
  // Card/Tii Ticket is accessable by anyone who has card access privilege.
  const peepMode = useMemo(
    () =>
      !get(getTicketProfile(scopeType), 'filterByCurrentUser', true) ||
      originPeepMode,
    [scopeType, originPeepMode],
  )

  const [first, setFirst] = useState(0)

  const ticketStatus = useMemo(
    () => map(get(getTicketProfile(scopeType), 'states'), 'main_state'),
    [scopeType],
  )

  const [tickets, loadingTicket] = useTickets({
    scope_type: scopeType,
    assignees: peepMode ? null : [email],
    namespace: getNamespaceByScopeType(scopeType),
    gql: _queryTickets,
  })
  const [searchText, setSearchText] = useState('')

  const viewableTickets = useMemo(() => {
    if (!tickets) return null

    return tickets.filter(ticket => {
      // Everyone is allow to view FINISH ticket.
      if (getActiveState(ticket).main_state === TicketState.FINISH) return true

      return peepMode || getActiveState(ticket).assignees.includes(email)
    })
  }, [tickets, email, peepMode])

  const renderTickets = useMemo(
    () =>
      !viewableTickets
        ? null
        : viewableTickets
            .filter(ticket =>
              includes(mainState, getActiveState(ticket).main_state),
            )
            .map(ticket => ({
              label: ticket.name,
              value: ticket,
            })),
    [viewableTickets, mainState],
  )

  const filteredTickets = useMemo(() => {
    if (!renderTickets) return null
    if (isEmpty(searchText)) return renderTickets
    return renderTickets.filter(({ label }) =>
      RegExp(escapeRegExp(searchText.trim()), 'ig').test(label),
    )
  }, [searchText, renderTickets])

  useEffect(() => {
    setSelected(null)
    if (first > get(filteredTickets, 'length', 0)) setFirst(0)
  }, [peepMode, filteredTickets, first])

  const statistic = useMemo(
    () =>
      !viewableTickets
        ? null
        : countBy(viewableTickets, ticket => getActiveState(ticket).main_state),
    [viewableTickets],
  )

  return (
    <ListRoot>
      <Title>
        <Flex justifyContent="space-between" alignItems="center">
          <Box mx="2">{scopeType}</Box>
          <PeepModeIcon peepMode={peepMode} setPeepMode={setPeepMode} />
        </Flex>
      </Title>
      <StyledOverlay active={loadingTicket} text={`Loading ${scopeType}...`}>
        <Flex flexDirection="row">
          <StyledDataView
            first={first}
            onPage={({ first }) => setFirst(first)}
            header={
              <InputText
                style={{ width: '100%' }}
                placeholder="搜尋..."
                onChange={e => setSearchText(e.target.value)}
                value={searchText}
              />
            }
            value={filteredTickets}
            itemTemplate={item => {
              if (!has(item, 'value')) return <></>
              return (
                <TicketItem
                  ticket={item.value}
                  setSelected={setSelected}
                  onOpen={onSelected}
                  privilege={privilege}
                  email={email}
                  selected={selected}
                />
              )
            }}
            paginator
            pageLinkSize={3}
            rows={10}
          />
          <Box
            flex="0 0 500px"
            borderBottom="1px solid #a6a6a6"
            borderRight="1px solid #a6a6a6"
          >
            {selected && <TicketInfoPanel ticket={selected} />}
          </Box>
        </Flex>
        <Flex width="100%" justifyContent="space-between">
          {ticketStatus.map((status, index) => (
            <IconCheckbox
              key={index}
              checked={includes(mainState, status)}
              onChecked={checked => {
                setMainState(
                  checked
                    ? [...mainState, status]
                    : filter(mainState, state => state !== status),
                )
              }}
              disabled={!statistic}
              style={{ flex: '1 0' }}
              color={theme.colors.azure}
              text={`${status}(${
                statistic ? get(statistic, status, 0) : 'Loading...'
              }）`}
            />
          ))}
        </Flex>
      </StyledOverlay>
    </ListRoot>
  )
}

TicketList.propTypes = {
  scopeType: PropTypes.string.isRequired,
  onSelected: PropTypes.func.isRequired,
}

export function TicketSelectionDialog({
  onOK,
  onHide,
  scopeType,
  viewType,
  schema,
}) {
  return (
    <Dialog
      header="Select a Ticket"
      appendTo={document.body}
      visible={true}
      style={{ width: '1024px' }}
      contentStyle={{ overflow: 'visible', position: 'relative' }}
      modal={true}
      onHide={() => onHide()}
    >
      <Root>
        <Flex flexWrap="wrap" overflow="auto" width="100%">
          <Flex width="100%" flexWrap="wrap" margin="0 2px 5px 0">
            <TicketList
              scopeType={scopeType}
              onSelected={(ticket, openInNewTab) => {
                onOK(scopeType, ticket, viewType, openInNewTab, schema)
                if (!openInNewTab) onHide()
              }}
            />
          </Flex>
        </Flex>
      </Root>
    </Dialog>
  )
}

TicketSelectionDialog.propTypes = {
  onOK: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  scopeType: PropTypes.string.isRequired,
  schema: PropTypes.string.isRequired,
  viewType: PropTypes.oneOf(['listview', 'compareview']).isRequired,
}
