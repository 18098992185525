import { join, map, partialRight } from 'lodash'

export function csvBlob(content) {
  return (
    'data:text/csv;charset=utf-8,' +
    encodeURIComponent(join(map(content, line => join(line, ',')), '\n'))
  )
}

export function jsonBlob(content) {
  return (
    'data:application/json;charset=utf-8,' +
    encodeURIComponent(JSON.stringify(content))
  )
}

export function exportDoc(filename, dataUri) {
  const linkElement = document.createElement('a')
  linkElement.setAttribute('href', dataUri)
  linkElement.setAttribute('download', filename)
  linkElement.click()
}

export function asyncRead(file, action) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
    reader[action](file)
  })
}

export const asyncReadFile = partialRight(asyncRead, 'readAsText')
export const asyncReadDataURL = partialRight(asyncRead, 'readAsDataURL')

export async function readImageSize(file) {
  const blob = await asyncReadDataURL(file)

  const image = new Image()
  return new Promise((resolve, reject) => {
    image.onload = () => {
      const { width, height } = image
      resolve([width, height])
    }
    image.onerror = reject
    image.src = blob
  })
}
