import { useMemo } from 'react'
import Joi from 'joi'

import { KG_NODES, useGetConnectedNodes, namespaces } from '@common'
import {
  addSchema,
  schemaToPropTypes,
  DateInTwentyYears,
  NullableString,
  RequiredString,
  shortString,
  longString,
  urlString,
  RequiredMongoIdString,
  RequiredKGNodeIdString,
  emailString,
} from '@schema'

export const ContractType = Object.freeze({
  main: {
    name: 'main',
    label: '主約',
  },
  sub: {
    name: 'sub',
    label: '附約',
  },
  rider: {
    name: 'rider',
    label: '附加條款',
  },
})

export const contractSchema = {
  _id: {
    schema: RequiredMongoIdString,
    label: 'id',
    type: 'string',
  },
  contract_name: {
    schema: RequiredString.max(shortString),
    type: 'string',
    label: '保險商品名稱',
  },
  contract_type: {
    schema: Joi.string()
      .valid(...Object.values(ContractType).map(({ name }) => name))
      .required(),
    type: 'string',
    label: '型態',
    useNodes: () => {
      const types = useMemo(
        () =>
          Object.values(ContractType).map(({ name, label }) => ({
            name: label,
            id: name,
          })),
        [],
      )

      return types
    },
  },
  contract_code: {
    schema: NullableString.max(shortString),
    type: 'string',
    label: '條款代號',
  },
  insurance_company: {
    schema: RequiredKGNodeIdString,
    referToKG: 'insurance',
    type: 'node',
    label: '保險公司',
    useNodes: () => {
      const roots = useMemo(
        () => [
          {
            node_id: KG_NODES.insurance.LIFE_INSURANCE_COMPANY_ROOT_ID,
            namespace: 'insurance',
          },
          {
            node_id: KG_NODES.insurance.PROPERTY_INSURANCE_COMPANY_ROOT_ID,
            namespace: 'insurance',
          },
        ],
        [],
      )
      const nodes = useGetConnectedNodes(roots)
      const result = useMemo(
        () =>
          nodes ? nodes.map(node => ({ ...node, id: node.node_id })) : null,
        [nodes],
      )
      return result
    },
  },
  approval_date: {
    schema: Joi.alternatives().try(Joi.string().valid(''), DateInTwentyYears),
    type: 'date',
    label: '核備日期',
  },
  approval_doc_no: {
    schema: NullableString.max(shortString),
    type: 'string',
    label: '最新核備文號',
  },
  oldest_approval_doc_no: {
    schema: NullableString.max(shortString),
    type: 'string',
    label: '最舊核備文號',
  },
  start_date: {
    schema: Joi.alternatives().try(Joi.string().valid(''), DateInTwentyYears),
    type: 'date',
    label: '銷售日',
  },
  end_date: {
    schema: Joi.alternatives().try(Joi.string().valid(''), DateInTwentyYears),
    type: 'date',
    label: '停售日',
  },
  file_url: {
    schema: NullableString.max(urlString),
    type: 'url',
    label: '檔案連結',
  },
  fee_url: {
    schema: NullableString.max(urlString),
    type: 'url',
    label: '費率連結',
  },
  ref_url: {
    schema: NullableString.max(urlString),
    type: 'url',
    label: '內部參考資料連結',
  },
  glossary: {
    schema: Joi.string().allow(null, ''),
    type: 'string',
    label: '名詞解釋',
  },
  guaranteed_renewal: {
    schema: Joi.bool().required(),
    type: 'bool',
    label: '保證續保',
  },
  exception: {
    schema: NullableString.max(longString),
    type: 'string',
    label: '除外責任',
  },
  exclusion: {
    schema: NullableString.max(longString),
    type: 'string',
    label: '不保事項',
  },
  uploader: {
    schema: RequiredString.max(emailString),
    type: 'string',
    label: '上傳者',
  },
}

addSchema({ contractSchema }, '保險合約', namespaces.insurance.value)
export const ContractPropType = schemaToPropTypes(contractSchema)
