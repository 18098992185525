import { namespaces } from '@common'
import {
  addSchema,
  schemaToPropTypes,
  RequiredString,
  shortString,
  RequiredMongoIdString,
} from '@schema'

export const muraSchema = {
  _id: {
    schema: RequiredMongoIdString,
    label: 'id',
    type: 'string',
  },
  name: {
    schema: RequiredString.max(shortString),
    filterable: true,
    type: 'string',
    label: '村落名稱',
  },
}

addSchema({ muraSchema }, '村落', namespaces.test.value)
export const MuraPropType = schemaToPropTypes(muraSchema)
