import { useMemo, useEffect } from 'react'
import {
  get,
  trimStart,
  isNil,
  toUpper,
  isEmpty,
  head,
  cloneDeep,
} from 'lodash'
import uuid from 'react-uuid'
import { useImmer } from 'use-immer'
import { useQuery } from '@apollo/client'
import {
  getQueryTagName,
  isLocalDoc,
  getActiveState,
  MatchState,
} from '@common'
import { toEditableDoc } from '@schema'
import { cceClient } from '@gql'

import { useTickets } from './TicketHelper'

export function useFetchFullDoc(doc, currentSchema, queryTag) {
  const [editContext, setEditContext] = useImmer(null)

  const { loading, data } = useQuery(queryTag, {
    variables: {
      doc_ids: [get(doc, '_id')],
    },
    client: cceClient,
    skip: isLocalDoc(doc),
  })

  useEffect(() => {
    if (isLocalDoc(doc)) {
      return setEditContext(() => toEditableDoc(doc, currentSchema))
    }
    const fetchedDoc = get(
      data,
      `${trimStart(getQueryTagName(queryTag), '_')}.0`,
      null,
    )
    setEditContext(() =>
      loading || isNil(fetchedDoc)
        ? null
        : toEditableDoc(fetchedDoc, currentSchema),
    )
  }, [doc, data, loading, queryTag, setEditContext, currentSchema])

  return useMemo(
    () => ({
      editContext,
      setEditContext,
    }),
    [editContext, setEditContext],
  )
}

export function useFetchDoc(gql, name, docId) {
  const { loading, data } = useQuery(gql, {
    variables: {
      doc_ids: [docId],
    },
    client: cceClient,
    skip: isNil(docId),
  })

  return useMemo(
    () =>
      loading || isNil(get(data, name))
        ? [null, null]
        : [get(data, `${name}.0`), null],
    [loading, data, name],
  )
}

export function useFetchDocAndTicket(gql, name, namespace, docId) {
  const [doc] = useFetchDoc(gql, name, docId)
  const [tickets] = useTickets({
    ticket_ids: [get(doc, 'ticket_id')],
    namespace: toUpper(namespace),
    skip: isNil(doc),
  })

  return useMemo(
    () =>
      !isNil(doc) && !isEmpty(tickets) ? [doc, head(tickets)] : [null, null],
    [doc, tickets],
  )
}

function toLocalDoc(doc) {
  doc.local_doc = true
  doc._id = uuid()
  doc.matched_state = MatchState.NO_MATCHED
  doc.matched_target = ''
  return doc
}

export async function cloneTicketDoc(
  queryTag,
  doc,
  ticket,
  email,
  currentSchema,
) {
  async function getEditableDoc() {
    if (isLocalDoc(doc)) return cloneDeep(doc)
    const { data } = await cceClient.query({
      query: queryTag,
      variables: {
        doc_ids: [doc._id],
      },
    })
    return toEditableDoc(
      get(data, `${trimStart(getQueryTagName(queryTag), '_')}.0`),
      currentSchema,
    )
  }

  const clonedDoc = await getEditableDoc()
  if (getActiveState(ticket).assignee_as_editor) clonedDoc.uploader = email
  return toLocalDoc(clonedDoc)
}
