import styled from 'styled-components'
import PropTypes from 'prop-types'

import { Flex } from '@changingai/react-editor-common-component'

const Caption = styled(Flex)``
Caption.defaultProps = {
  width: '100%',
  fontSize: 'h1',
  justifyContent: 'center',
  bg: 'blacks.1',
  py: 1,
  mt: 1,
}

const Content = styled(Flex)``
Content.defaultProps = {
  width: '100%',
  p: 2,
  flexWrap: 'wrap',
  border: '1px solid',
  borderColor: 'blacks.1',
  alignItems: 'center',
}

export function HomePanel({ caption, children }) {
  return (
    <>
      <Caption>{caption}</Caption>
      <Content>{children}</Content>
    </>
  )
}

HomePanel.propTypes = {
  children: PropTypes.node.isRequired,
  caption: PropTypes.string.isRequired,
}
