import { useCallback, useMemo } from 'react'
import { isNil, castArray, find, size, get, partial } from 'lodash'

import { isLocalDoc, namespaces, useDialog } from '@common'
import { createBlankDoc } from '@schema'
import { cceClient } from '@gql'

import { docPrivilege, namespace } from './config'
import { MuraEditor } from './editor'
import { muraSchema } from './schema'
import { useCacheDocs, useKGTranspile } from '../Common'
import {
  useSoftTicketRef,
  useMutateTicket,
  canDeleteTicketRefDoc,
} from '../TicketHelper'
import OwnedDocSummaryModel, {
  useFetchTecklessDocs,
} from '../OwnedDocSummaryModel'
import { useReferenceBase, filterTicketRef } from '../Reference'
import { useUploadDocWithHistory, useDeleteDocWithHistory } from '../Uploader'

import { _uploadMura, _deleteMura } from './listview.gql'

function useReference(doc) {
  const data = useReferenceBase('mura', filterTicketRef, doc)
  const ticketRef = useSoftTicketRef(doc, namespaces.test.value)
  return useMemo(() => {
    if (isNil(ticketRef) || isNil(get(data, 0))) return [null, null]
    return [size(ticketRef) + get(data, 0), get(data, 1)]
  }, [data, ticketRef])
}

export const useTranspile = partial(useKGTranspile, muraSchema)

function useUploadMura() {
  const { createOrRenameTicket } = useMutateTicket()
  const upload = useUploadDocWithHistory(muraSchema, async muras => {
    const {
      data: { uploadMura },
    } = await cceClient.mutate({
      mutation: _uploadMura,
      variables: {
        muras,
      },
    })

    await createOrRenameTicket(uploadMura, 'Ninja', ({ name }) => name)

    return uploadMura
  })

  return upload
}

function useController(_, selectedFields) {
  const muras = useFetchTecklessDocs('Mura', muraSchema, selectedFields)
  const { updated, deleteCache, updateCache, docs } = useCacheDocs(
    null,
    true,
    null,
    muras,
  )

  const [showDialog, renderDialog] = useDialog(MuraEditor)
  const showDoc = useCallback(
    (doc, crud) => {
      showDialog({
        doc,
        crud,
      })
    },
    [showDialog],
  )

  const upload = useUploadMura()
  const uploadDoc = useCallback(mura => upload(null, mura), [upload])

  const { deleteTicket } = useMutateTicket()
  const deleteMura = useDeleteDocWithHistory(muraSchema, ids => {
    cceClient.mutate({
      mutation: _deleteMura,
      variables: {
        ids: castArray(ids),
      },
    })
  })
  const deleteDoc = useCallback(
    async _id => {
      deleteCache(_id)
      const deleted = find(docs, { _id })
      if (isNil(deleted) || isLocalDoc(deleted)) return
      const [canDelete, ticketIds] = await canDeleteTicketRefDoc(
        { _id },
        namespace,
      )
      if (canDelete) await deleteTicket(ticketIds, 'Mura')
      return deleteMura(deleted)
    },
    [deleteCache, docs, deleteMura, deleteTicket],
  )
  const createDoc = useCallback(() => {
    const mura = createBlankDoc(muraSchema, true)
    updateCache(mura)
    return mura
  }, [updateCache])

  return {
    isReady: !isNil(docs),
    docs,
    createDoc,
    showDoc,
    updateDoc: updateCache,
    updated,
    renderDoc: renderDialog,
    uploadDoc,
    deleteDoc,
    useReference,
  }
}

const defaultSummary = {
  selected: ['name'],
  filters: {},
}

const OwnedMuraListView = props => {
  return (
    <OwnedDocSummaryModel
      {...props}
      crud={{
        read: true,
        update: true,
        create: true,
        delete: true,
        overwrite: true,
      }}
      useController={useController}
      useTranspile={useTranspile}
      defaultSummary={defaultSummary}
      requiredPrivilege={[docPrivilege]}
      currentSchema={muraSchema}
    />
  )
}

export default OwnedMuraListView
