import { useMemo } from 'react'
import isNil from 'lodash/isNil'

import { inputFactory } from '@widget'
import { useFetchFullDoc } from '../DocHelper'
import { SchemaBaseEditor } from '../SchemaBaseEditor'

import { TiiPropType, tiiSchema } from './schema'
import { _queryTii } from './editor.gql'

export function TiiEditor(props) {
  const { editContext, setEditContext } = useFetchFullDoc(
    props.doc,
    tiiSchema,
    _queryTii,
  )
  const tabs = useMemo(
    () => [
      {
        caption: '',
        configs: [
          inputFactory(tiiSchema, 'name'),
          inputFactory(tiiSchema, 'company_id'),
          inputFactory(tiiSchema, 'category_id'),
          inputFactory(tiiSchema, 'product_id'),
          inputFactory(tiiSchema, 'link'),
          inputFactory(tiiSchema, 'sell_start_date'),
          inputFactory(tiiSchema, 'sell_end_date'),
          inputFactory(tiiSchema, 'approved_date'),
          inputFactory(tiiSchema, 'approved_id'),
          inputFactory(tiiSchema, 'contents'),
          inputFactory(tiiSchema, 'contracts'),
          inputFactory(tiiSchema, 'proposals'),
          inputFactory(tiiSchema, 'fees'),
          inputFactory(tiiSchema, 'appended_insurances'),
          inputFactory(tiiSchema, 'benefits'),
          inputFactory(tiiSchema, 'registers'),
          inputFactory(tiiSchema, 'status'),
        ],
      },
    ],
    [],
  )
  return (
    <SchemaBaseEditor
      {...props}
      currentSchema={tiiSchema}
      tabs={tabs}
      crud={{
        create: false,
        update: false,
        delete: false,
        read: true,
        overwrite: false,
      }}
      isReady={!isNil(editContext)}
      editContext={editContext}
      setEditContext={setEditContext}
    />
  )
}

TiiEditor.propTypes = {
  doc: TiiPropType.isRequired,
}
