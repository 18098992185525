export const chartBGColors = [
  '#EC407A',
  '#AB47BC',
  '#42A5F5',
  '#7E57C2',
  '#66BB6A',
  '#FFCA28',
  '#26A69A',
  '#FFA726',
  '#805ad5',
  '#ecc94b',
  '#805ad5',
]

export const stackedOptions = {
  tooltips: {
    mode: 'index',
    intersect: false,
  },
  responsive: true,
  scales: {
    xAxes: [
      {
        stacked: true,
        ticks: {
          fontColor: '#495057',
        },
        gridLines: {
          color: '#ebedef',
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        ticks: {
          fontColor: '#495057',
        },
        gridLines: {
          color: '#ebedef',
        },
      },
    ],
  },
  legend: {
    labels: {
      fontColor: '#495057',
    },
  },
}
