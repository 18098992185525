import { compact, find, get, isEmpty, map, size, some } from 'lodash'
import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { useImmer } from 'use-immer'
import { Flex } from '@changingai/react-editor-common-component'

import { spotlightsSchema } from './schema'
import { purifySchemaDoc } from '@schema'
import { inputFactory, useRenderConfig } from '@widget'

export function Spotlights({
  context,
  onDirty,
  onUpdateContext,
  onError,
  currentPath,
}) {
  const [editContext, setEditContext] = useImmer({
    spotlight_summary: get(
      find(context.attributes, { key: 'spotlight_summary' }),
      'value',
      '',
    ),
    spotlight: JSON.parse(
      get(find(context.attributes, { key: 'spotlight' }), 'value', '[]'),
    ),
  })

  useEffect(() => {
    const { spotlight_summary, spotlight } = purifySchemaDoc(
      editContext,
      spotlightsSchema,
    )

    const lackSpotlightSummaryOrSpotlight =
      size(compact(map([spotlight_summary, spotlight], isEmpty))) === 1
    onError(
      'spotlight',
      lackSpotlightSummaryOrSpotlight,
      `${map(spotlightsSchema, 'label').join('/')}必需同時設定}`,
    )

    const attributes = some([spotlight_summary, spotlight], isEmpty)
      ? []
      : [
          { key: 'spotlight_summary', value: spotlight_summary },
          { key: 'spotlight', value: JSON.stringify(spotlight) },
        ]
    onUpdateContext(draft => {
      draft['attributes'] = attributes
    })
  }, [editContext, onUpdateContext, onError])

  const renderer = useRenderConfig({
    onError,
    onUpdateContext: setEditContext,
    onDirty,
    editContext,
    currentSchema: spotlightsSchema,
    currentPath,
  })

  const configs = useMemo(
    () => [
      inputFactory(spotlightsSchema, 'spotlight_summary'),
      inputFactory(spotlightsSchema, 'spotlight'),
    ],
    [],
  )

  return (
    <Flex width="100%" flexWrap="wrap">
      {configs.map(config => renderer(config))}
    </Flex>
  )
}

Spotlights.propTypes = {
  context: PropTypes.object.isRequired,
  onDirty: PropTypes.func.isRequired,
  onUpdateContext: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  currentPath: PropTypes.array.isRequired,
}
