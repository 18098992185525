import { isEmpty, every, negate, get, map } from 'lodash'
import { ValidationCategory as State } from '@common'
import { productSchema } from './schema'

export const rules = [
  ({ launch_date, end_date }) => {
    const fieldname = map(['launch_date', 'end_date'], field =>
      get(productSchema, `${field}.label`),
    ).join('/')

    if (isEmpty(launch_date) && !isEmpty(end_date))
      return {
        fieldname,
        result: [[State.Error, '不可只有停售日[約略]沒有銷售日[約略]']],
      }

    if (every([launch_date, end_date], negate(isEmpty))) {
      const launchDate = new Date(launch_date)
      const endDate = new Date(end_date)
      if (endDate < launchDate)
        return {
          fieldname,
          result: [
            [
              State.Error,
              [
                `停售日[約略]${endDate.toLocaleDateString('zh-Hans-CN')}`,
                `早於銷售日[約略]${launchDate.toLocaleDateString(
                  'zh-Hans-CN',
                )}`,
              ].join(', '),
            ],
          ],
        }
    }

    return { fieldname, result: [] }
  },
]
