import { useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { get, isNil, isEmpty, noop } from 'lodash'
import { FaFileAlt, FaLock } from 'react-icons/fa'
import { Box, Flex, Grid } from '@changingai/react-editor-common-component'

import { PropCrud, MatchState, isLocalDoc } from '@common'
import { Card } from '@widget'
import theme from '@theme'

import { TagPanel } from './TagPanel'
import { MemoPanel } from './MemoPanel'
import { SummaryField } from './SummaryFields'
import HistoryPanel from './HistoryPanel'
import { TagMemoContext } from './TagMemoContext'

const SummaryGrid = styled(Grid)``
SummaryGrid.defaultProps = {
  gridAutoFlow: 'row dense',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: 'auto',
}

const SummaryCol = styled(Flex)``
SummaryCol.defaultProps = {
  alignItems: 'flex-start',
  alignContent: 'flex-start',
  flexWrap: 'wrap',
}

function ActionButton({
  transpiled,
  doc,
  crud,
  onClone,
  onOpen,
  onDelete,
  isLoadingTag,
}) {
  const openButtonLabel = useMemo(() => {
    if (crud.update) return 'Open'
    return crud.review ? 'Open(Reviewable)' : 'Open(ReadOnly)'
  }, [crud])

  if (!crud.read) return null
  return (
    <Flex flex="0 0" justifyContent="flex-end" width="100%">
      {transpiled.matched_state === MatchState.FULLY_MATCHED && (
        <FaLock
          style={{
            color: 'red',
            margin: `0 ${theme.sizes[2]}px`,
          }}
        />
      )}
      {isLocalDoc(doc) && (
        <FaFileAlt
          style={{
            color: 'DeepPink',
            margin: `0 ${theme.sizes[2]}px`,
          }}
        />
      )}
      {isLocalDoc(doc) && !isNil(onDelete) && (
        <Button
          data-cy="delete-doc-button"
          className="p-button-danger"
          style={{ margin: '0 5px' }}
          label="Delete"
          onClick={() => onDelete(doc._id)}
        />
      )}
      {crud.create && !isNil(onClone) && (
        <Button
          data-cy="clone-doc-button"
          className="p-button-success"
          style={{ margin: '0 5px' }}
          label="Clone"
          onClick={() => {
            onClone(doc)
          }}
        />
      )}
      {crud.read && !isNil(onOpen) && (
        <Button
          data-cy="open-doc-button"
          className="p-button-primary"
          disabled={isLoadingTag}
          label={openButtonLabel}
          onClick={() => {
            onOpen(doc, crud)
          }}
        />
      )}
    </Flex>
  )
}

ActionButton.propTypes = {
  transpiled: PropTypes.object.isRequired,
  doc: PropTypes.object.isRequired,
  crud: PropCrud.isRequired,
  onOpen: PropTypes.func,
  onClone: PropTypes.func,
  onDelete: PropTypes.func,
  isLoadingTag: PropTypes.bool.isRequired,
}

function SummaryDoc({
  caption,
  doc,
  transpiled,
  onOpen,
  onDelete,
  onClone,
  updated,
  selectedFields,
  crud,
  selected = false,
  onSelect = noop,
  currentSchema,
  useTranspile,
  showMemo = false,
  highlightWhenHover = true,
  layoutSchema,
}) {
  const {
    isLoadingTag = false,
    tags,
    updateTag,
    memos,
    updateMemo,
  } = useContext(TagMemoContext)
  const memo = useMemo(() => get(memos, doc._id, ''), [memos, doc._id])
  const myTags = useMemo(() => (isLoadingTag ? null : get(tags, doc._id, [])), [
    tags,
    isLoadingTag,
    doc._id,
  ])

  const [bg, setBg] = useState('white')
  return (
    <Card
      flex="1 0 100%"
      my="1"
      boxShadow="none"
      data-cy={`summary-doc-${doc._id}`}
    >
      <Flex width="100%" overflow="auto" flexWrap="wrap">
        <Flex
          color="white"
          bg="DarkSlateBlue"
          alignItems="center"
          justifyContent="space-between"
          flex="1 1 100%"
          borderTopRightRadius="15px"
        >
          {!isEmpty(caption) && (
            <>
              <Flex
                alignItems="center"
                mx={2}
                fontWeight={updated ? 'bold' : 'normal'}
                color={updated ? 'yellow' : 'white'}
              >
                <Box data-cy="select-doc-checkbox">
                  <Checkbox
                    onChange={() => onSelect(doc._id)}
                    checked={selected}
                  />
                </Box>
                {!isNil(useTranspile) && (
                  <HistoryPanel id={doc._id} useTranspile={useTranspile} />
                )}
                {caption}
                {updated ? '(Updated)' : ''}
              </Flex>
              {!isNil(updateTag) && showMemo && (
                <TagPanel
                  doc={doc}
                  tagsContext={myTags}
                  updateTag={updateTag}
                />
              )}
            </>
          )}
        </Flex>
        <Flex
          alignItems="stretch"
          justifyContent="space-between"
          bg={bg}
          flex="1 1 100%"
          border="1px solid gray"
          p="1"
          onMouseEnter={() => {
            if (highlightWhenHover) setBg('reddishOranges.2')
          }}
          onMouseLeave={() => {
            if (highlightWhenHover) setBg('white')
          }}
        >
          <SummaryGrid flex="1 1 auto">
            {selectedFields.map(fieldname => (
              <SummaryField
                context={doc}
                key={fieldname}
                currentSchema={currentSchema}
                fieldname={fieldname}
                value={transpiled[fieldname]}
                layoutSchema={layoutSchema}
              />
            ))}
          </SummaryGrid>
          {!isNil(updateMemo) && showMemo && (
            <SummaryCol
              flex="0 0 350px"
              alignItems="flex-end"
              alignContent="space-between"
              flexDirection="column"
            >
              <Box flex="1 0" width="100%" p="1">
                <MemoPanel
                  doc={doc}
                  memoContext={memo}
                  updateMemo={updateMemo}
                />
              </Box>
              <ActionButton
                transpiled={transpiled}
                doc={doc}
                crud={crud}
                onOpen={onOpen}
                onClone={onClone}
                onDelete={onDelete}
                isLoadingTag={isLoadingTag}
              />
            </SummaryCol>
          )}
        </Flex>
        {!showMemo && (
          <Flex width="100%" justifyContent="flex-end" my="1">
            <ActionButton
              transpiled={transpiled}
              doc={doc}
              crud={crud}
              onOpen={onOpen}
              onClone={onClone}
              onDelete={onDelete}
              isLoadingTag={isLoadingTag}
            />
          </Flex>
        )}
      </Flex>
    </Card>
  )
}

SummaryDoc.propTypes = {
  caption: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  doc: PropTypes.object.isRequired,
  transpiled: PropTypes.object.isRequired,
  onOpen: PropTypes.func,
  onDelete: PropTypes.func,
  onClone: PropTypes.func,
  updated: PropTypes.bool.isRequired,
  selectedFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  crud: PropCrud.isRequired,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
  currentSchema: PropTypes.object.isRequired,
  useTranspile: PropTypes.func,
  tagMemoContext: PropTypes.object,
  showMemo: PropTypes.bool,
  highlightWhenHover: PropTypes.bool,
  layoutSchema: PropTypes.object.isRequired,
}

export default SummaryDoc
