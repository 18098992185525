import { useCallback, useEffect, useState, useMemo, createContext } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useImmer } from 'use-immer'
import { get, filter, isEmpty, isNil, forEach, castArray, find } from 'lodash'
import { useDebounce } from '@changing-cc/hooks/lib/Debounce'

import { useDeepMemo } from '@common'
import { cceClient } from '@gql'

import {
  _queryMemo,
  _queryTag,
  _addTag,
  _removeTag,
  _updateMemo,
} from './TagMemoContext.gql'

export const TagMemoContext = createContext({})

export function useTagContext(ids) {
  const localIds = useDeepMemo(ids)

  const { loading, data } = useQuery(_queryTag, {
    client: cceClient,
    variables: {
      target_ids: localIds,
    },
  })

  const [tags, setTags] = useImmer(null)

  useEffect(() => {
    if (loading || !data) return setTags(() => null)
    setTags(() => ({
      ...data.queryTag.remap(({ target_id }) => target_id, ({ tags }) => tags),
      ...filter(localIds, id => !find(data.queryTag, { target_id: id })).remap(
        id => id,
        () => [],
      ),
    }))
  }, [loading, data, setTags, localIds])

  const [addTagMutation] = useMutation(_addTag)
  const addTag = useCallback(
    (id, tag) => {
      setTags(() => ({ ...tags, [id]: [...get(tags, id, []), tag] }))
      addTagMutation({
        variables: {
          target_id: id,
          tag,
        },
      })
    },
    [setTags, addTagMutation, tags],
  )

  const [removeTagMutation] = useMutation(_removeTag)
  const removeTag = useCallback(
    (ids, tag) => {
      setTags(draft => {
        forEach(castArray(ids), id => {
          draft[id] = filter(tags[id], t => t !== tag)
        })
      })
      removeTagMutation({
        variables: {
          target_ids: castArray(ids),
          tag,
        },
      })
    },
    [setTags, removeTagMutation, tags],
  )

  const result = useMemo(
    () => ({
      isLoadingTag: loading || isNil(data),
      tags,
      updateTag: [addTag, removeTag],
    }),
    [loading, data, tags, addTag, removeTag],
  )

  return result
}

export function useMemoContext(ids) {
  const { data: memoData } = useQuery(_queryMemo, {
    client: cceClient,
    variables: {
      target_ids: ids,
    },
  })

  const [memos, setMemos] = useState(null)

  useEffect(() => {
    if (!memoData) return
    setMemos(() =>
      memoData.queryMemo.remap(
        ({ target_id }) => target_id,
        ({ memo }) => memo,
      ),
    )
  }, [memoData, setMemos])

  const [memoWithId, setMemoWithId] = useState(null)
  const debounced = useDebounce(memoWithId)

  const updateMemo = useCallback(
    (id, memo) => {
      setMemos({ ...memos, [id]: memo })
      setMemoWithId({ id, memo })
    },
    [setMemos, memos],
  )

  const [updateMemoMutation] = useMutation(_updateMemo)
  useEffect(() => {
    if (isEmpty(debounced)) return
    updateMemoMutation({
      variables: {
        target_id: debounced.id,
        memo: debounced.memo,
      },
    })
  }, [debounced, updateMemoMutation])

  const result = useMemo(
    () => ({
      memos,
      updateMemo,
    }),
    [memos, updateMemo],
  )

  return result
}

export function useTagMemoContext(ids) {
  const memoResult = useMemoContext(ids)
  const tagResult = useTagContext(ids)

  const result = useMemo(
    () => ({
      ...memoResult,
      ...tagResult,
    }),
    [memoResult, tagResult],
  )

  return result
}
