import { useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  filter,
  find,
  fromPairs,
  flatMap,
  isEqual,
  map,
  size,
  sortBy,
  zip,
} from 'lodash'
import { diffChars } from 'diff'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

import { FeeTablePropType } from './schema'

import { DiffContent } from '../CompareView'

export function FactorDiffLayout({
  current,
  pair,
  transpiledDocs,
  showDeleteChar,
  showDiffColor,
}) {
  const diffs = useMemo(() => {
    const currentDocs = find(transpiledDocs, { _id: current._id })
    const pairDocs = find(transpiledDocs, { _id: pair._id })
    const currentFactor = sortBy(currentDocs.factors, 'id')
    const pairFactor = sortBy(pairDocs.factors, 'id')
    if (!isEqual(map(currentFactor, 'id'), map(pairFactor, 'id')))
      return diffChars(
        map(currentFactor, 'id').toString(),
        map(pairFactor, 'id').toString(),
      )

    const diffFactor = (factor1, factor2) =>
      map(
        filter(
          factor1,
          ({ id, values }) => !isEqual(find(factor2, { id }).values, values),
        ),
        ({ id, values }) => [id, values.toString()].join('\n'),
      )
    return diffChars(
      diffFactor(currentFactor, pairFactor).toString(),
      diffFactor(pairFactor, currentFactor).toString(),
    )
  }, [current, pair, transpiledDocs])
  return (
    <DiffContent
      diffs={diffs}
      showDeleteChar={showDeleteChar}
      showDiffColor={showDiffColor}
    />
  )
}

FactorDiffLayout.propTypes = {
  current: FeeTablePropType.isRequired,
  pair: FeeTablePropType.isRequired,
  transpiledDocs: PropTypes.arrayOf(FeeTablePropType).isRequired,
  showDeleteChar: PropTypes.bool.isRequired,
  showDiffColor: PropTypes.bool.isRequired,
}

function rowsDiff(rowsA, rowsB) {
  const indices = []
  for (let y = 0; y < size(rowsA); y++)
    for (let x = 0; x < size(rowsA[y]); x++) {
      if (rowsA[y][x] !== rowsB[y][x]) indices.push([y, x, rowsA[y][x]])
    }
  return indices
}

export function MatricesDiffLayout({ current, pair, transpiledDocs }) {
  const columns = useMemo(() => {
    const transpiledAxis = map(
      find(transpiledDocs, { _id: current._id }).factors,
      'id',
    )
    const axis = map(current.factors, 'id')
    return [
      ...map(zip(axis, transpiledAxis), ([id, header]) => (
        <Column key={id} field={id} header={header}></Column>
      )),
      <Column key="value" field={'value'} header={'Value'}></Column>,
    ]
  }, [current, transpiledDocs])

  const diffs = useMemo(() => {
    const { factors, matrices: currentMatrices } = current
    const { factors: pairFactors, matrices: pairMatrices } = pair
    if (!isEqual(factors, pairFactors)) return
    return flatMap(
      currentMatrices,
      ({ rows, remain_factors, x_axis, y_axis }, index) => {
        const diff = rowsDiff(rows, pairMatrices[index].rows)
        return map(diff, ([y, x, value]) => ({
          value,
          ...fromPairs(map(remain_factors, ({ id, value }) => [id, value])),
          [x_axis]: find(factors, {
            id: x_axis,
          }).values[x],
          [y_axis]: find(factors, {
            id: y_axis,
          }).values[y],
        }))
      },
    )
  }, [current, pair])
  if (!diffs) return '條件無法對齊，無法比較費率表，請回費率表編輯器修改'
  return (
    <DataTable value={diffs} rows={10} paginator>
      {columns}
    </DataTable>
  )
}

MatricesDiffLayout.propTypes = {
  current: FeeTablePropType.isRequired,
  pair: FeeTablePropType.isRequired,
  transpiledDocs: PropTypes.arrayOf(FeeTablePropType).isRequired,
}
