import { useMemo, useCallback } from 'react'
import produce from 'immer'
import { isEmpty, get, keyBy } from 'lodash'
import uuid from 'react-uuid'

import { useQueryKG, KG_NODES, rewardNameToID, CardGroupScope } from '@common'

import { cardRewardSchema } from './schema'

import { _get_connected } from './RewardUtils.gql'

// Depends on reward type, the selectable reward target of a reward
// may be different.
export const rewardTargetGroup = [
  {
    ids: rewardNameToID([
      '現金回饋',
      '現金回饋-級距',
      '現金回饋-滿額',
      '首刷禮-現金回饋',
    ]),
    root: KG_NODES.card.CASH_REWARD_GROUP_ID,
  },
  {
    ids: rewardNameToID(['打折優惠']),
    root: KG_NODES.card.DISCOUNT_REWARD_GROUP_ID,
  },
  {
    ids: rewardNameToID([
      '類現金回饋(非現金但兌換比例為1:1)',
      '類現金回饋-級距',
      '類現金回饋-滿額',
      '首刷禮-類現金回饋',
    ]),
    root: KG_NODES.card.CASHLIKE_REWARD_GROUP_ID,
  },
  {
    ids: rewardNameToID(['其他', '首刷禮-其他']),
    root: KG_NODES.card.OTHERS_REWARD_GROUP_ID,
  },
]

const rewardTargetGroupMap = rewardTargetGroup.reduce(
  (acc, { ids, root }) => ({
    ...acc,
    ...ids.remap(v => v, () => root),
  }),
  {},
)

export function hasPredefinedRewardTarget(rewardType) {
  return rewardType in rewardTargetGroupMap
}

export function useRewardTarget(rewardType) {
  const root = useMemo(() => get(rewardTargetGroupMap, rewardType), [
    rewardType,
  ])

  const { loading, data } = useQueryKG(_get_connected, {
    variables: {
      node_id: root,
    },
    skip: !root,
  })

  const nodes = useMemo(() => {
    if (loading || !data) return null
    return data.get_connected.map(({ node_id: id, name }) => ({ id, name }))
  }, [loading, data])

  return nodes
}

// This function is trying to fix validation failure in the following
// use case:
// 1. A user selects a reward type and fills a valid value into a field A.
//    Upload this reward then.
// 2. Clone a new doc from the one created in #1
// 3. Switch to another reward type. In this new reward type, field A is
//    a non-editable field. Since it's non-editable, we hide the input
//    of this field.
// 4. Try to upload this new doc. RewardValidationDialog reports validation
//    failure since field A is not emtpy.
//
// In #3, remove field A in the cloned reward right after reward type
// switching can fix this problem but will cause document dirty, which is not
// we want.
// As a result, implement useFilterDocField to remove those
// none-editablefields in a new created reward doc, use that new create one for
// validation and uploading and keep the original reward doc unchanged.
export function useFilterDocField() {
  const isEditable = useEditable()
  const filter = useCallback(
    doc =>
      produce(doc, draft => {
        for (const field of Object.keys(draft)) {
          if (!isEditable(draft, field)) {
            draft[field] = null
          }
          if (typeof draft[field] === 'string' && isEmpty(draft[field])) {
            draft[field] = null
          }
        }
      }),
    [isEditable],
  )

  return filter
}

export function createCardSet(groupId, cardProviderId, cardGroupScope) {
  if (cardGroupScope === CardGroupScope.ISSUER) {
    return {
      id: uuid(),
      card_group_id: groupId ? groupId : null,
      issuer_id: cardProviderId ? cardProviderId : null,
      card_level_id: null,
    }
  }
  return {
    id: uuid(),
    card_group_id: groupId ? groupId : null,
    issuer_id: null,
    card_level_id: null,
  }
}

const editableTags = {
  parking: ['free_parking_hrs', 'free_parking_times'],
  cash: ['re_upper_bound'],
}

const editableDep = {
  re_upper_bound_unit_id: ['re_upper_bound'],
  essential_day_unit_id: ['essential_day'],
}

export const useCardRewardType = cardRewardSchema.type_id.useNodes

export function useEditable() {
  const nodes = useCardRewardType()
  const rewards = useMemo(() => keyBy(nodes, 'node_id'), [nodes])

  const isEditable = useCallback(
    (reward, fieldname) => {
      if (isEmpty(reward)) return false
      if (process.env.REACT_APP_SHOW_ALL_FIELDS || !reward.type_id) return true
      if (fieldname in editableDep) {
        if (
          editableDep[fieldname].some(
            dep => !isEditable(reward, dep) || !reward[dep],
          )
        )
          return false
      }

      for (const key of Object.keys(editableTags)) {
        if (editableTags[key].includes(fieldname)) {
          return get(rewards, `${reward.type_id}.tags`, []).includes(key)
        }
      }

      return true
    },
    [rewards],
  )

  return isEditable
}
