import Joi from 'joi'
import { compact, isEmpty, map } from 'lodash'

import { KG_NODES, useGetConnectedNodes, namespaces } from '@common'
import {
  addSchema,
  schemaToPropTypes,
  matchStateSchema,
  ticketDocSchema,
  RequiredString,
  shortString,
  NullableKGNodeIdString,
  RequiredKGNodeIdString,
  NullableString,
  UniqueUrlArray,
} from '@schema'

export const remainFeeFactorSchema = {
  id: {
    schema: RequiredString.max(shortString),
    type: 'string',
    label: '維度代碼',
  },
  value: {
    schema: RequiredString.max(shortString),
    type: 'string',
    label: '維度名稱',
  },
}

export const feeTabelCellSchema = Joi.string()
  .pattern(/^([0-9]+\.)?[0-9]+$/)
  .allow(null)

export const feeTableMatrixSchema = {
  x_axis: {
    schema: RequiredString.max(shortString),
    type: 'string',
    label: 'X軸',
  },
  y_axis: {
    schema: RequiredString.max(shortString),
    type: 'string',
    label: 'Y軸',
  },
  remain_factors: {
    schema: Joi.array().required(),
    type: 'arrayOf(remainFeeFactorSchema)',
    label: '剩餘維度',
  },
  rows: {
    schema: Joi.array()
      .items(Joi.array().items(feeTabelCellSchema))
      .required(),
    type: 'arrayOf(string)',
    label: '原始資料',
  },
}

const factorRoot = [
  {
    node_id: KG_NODES.insurance.REQUEST_VARIABLE_ROOT_ID,
    namespace: 'insurance',
  },
]

export const feeFactorSchema = {
  id: {
    schema: RequiredKGNodeIdString,
    type: 'node',
    referToKG: 'insurance',
    label: '維度代碼',
    useNodes: () => useGetConnectedNodes(factorRoot),
  },
  values: {
    schema: Joi.array().items(Joi.string()),
    type: 'arrayOf(string)',
    label: '維度數值',
  },
}

const currencyRoot = [
  {
    node_id: KG_NODES.insurance.CURRENCY_ROOT_ID,
    namespace: 'insurance',
  },
]
const costPickMethodRoot = [
  {
    node_id: KG_NODES.insurance.COST_PICK_METHOD_ROOT_ID,
    namespace: 'insurance',
  },
]
export const feeTableSchema = {
  ...ticketDocSchema,
  ...matchStateSchema,
  name: {
    schema: RequiredString.max(shortString),
    type: 'string',
    label: '表格名稱',
  },
  currency_id: {
    schema: RequiredKGNodeIdString,
    referToKG: 'insurance',
    type: 'node',
    label: '幣別',
    useNodes: () => useGetConnectedNodes(currencyRoot),
  },
  fee_base: {
    schema: Joi.number()
      .integer()
      .min(0)
      .required(),
    type: 'number',
    label: '每 N 投保額度',
    hint: 'positive integer',
  },
  cost_pick_method: {
    schema: NullableKGNodeIdString,
    referToKG: 'insurance',
    type: 'node',
    label: '保險成本收取方式',
    useNodes: () => useGetConnectedNodes(costPickMethodRoot),
  },
  cost_note: {
    schema: NullableString,
    type: 'string',
    label: '保險成本備註',
  },
  contract_id: {
    schema: NullableString,
    type: 'string',
    label: '費率表合約',
  },
  raw_files: {
    schema: UniqueUrlArray.required(),
    type: 'arrayOf(url)',
    label: '原始檔案',
  },
  factors: {
    schema: Joi.array()
      .min(2)
      .required(),
    type: 'arrayOf(feeFactorSchema)',
    label: '條件',
  },
  matrices: {
    schema: Joi.array().custom(matrices => {
      const errors = compact(
        map(
          matrices,
          ({ rows }) => feeTableMatrixSchema.rows.schema.validate(rows).error,
        ),
      )
      if (!isEmpty(errors)) throw errors
      return matrices
    }),
    type: 'arrayOf(feeTableMatrixSchema)',
    label: '矩陣',
  },
}

addSchema({ remainFeeFactorSchema })
addSchema({ feeTableMatrixSchema })
addSchema({ feeFactorSchema })
addSchema({ feeTableSchema }, '產品費率表', namespaces.insurance.value)

export const FeeTablePropType = schemaToPropTypes(feeTableSchema)
