import { get, isEmpty, has, isNil } from 'lodash'
import uuid from 'react-uuid'

import { computeType } from '@common'

import { renderPlainTextChip, renderUrlChip } from './Chips'

export function generateSubDocProps(schema, fieldname) {
  return {
    widget: 'SubDocInput',
    fieldname,
    label: schema[fieldname].label,
    currentSchema: computeType(schema[fieldname].type).extractedSchema,
    defaultValue: schema[fieldname].defaultValue || [],
    min: 0,
    showCaption: true,
    // Return false if you do not want a doc being deleted.
    onCanDelete: () => true,
  }
}

export function createSeparator({ height = '2px', bg = 'azures.3' }) {
  return {
    bg,
    height,
    key: uuid(),
    fieldname: 'separator',
    widget: 'Separator',
  }
}

function arrayInputFactory(currentSchema, fieldname, options, nestedType) {
  if (nestedType === 'image') {
    return {
      fieldname,
      widget: 'ImageUploader',
      ...options,
    }
  }

  if (nestedType === 'node') {
    if (has(currentSchema[fieldname], 'useNodes')) {
      return {
        fieldname,
        widget: 'MultiCheckboxList',
        useQueryHook: get(currentSchema, `${fieldname}.useNodes`),
        ...options,
      }
    }

    if (has(currentSchema[fieldname], 'useTree')) {
      return {
        fieldname,
        widget: 'TreeSelect',
        useQueryHook: get(currentSchema, `${fieldname}.useTree`),
        ...options,
      }
    }

    return {
      fieldname,
      widget: 'MultipleNodeList',
      ...options,
    }
  }

  if (!isNil(computeType(currentSchema[fieldname].type).extractedSchema)) {
    return {
      ...generateSubDocProps(currentSchema, fieldname),
      ...options,
    }
  }

  if (
    get(currentSchema[fieldname], 'multiInput', false) ||
    !has(currentSchema[fieldname], 'useNodes')
  ) {
    return {
      fieldname,
      widget: 'MultiInput',
      renderChip: nestedType === 'url' ? renderUrlChip : renderPlainTextChip,
      useQueryHook: get(currentSchema, `${fieldname}.useNodes`),
      ...options,
    }
  }

  return {
    fieldname,
    widget: 'MultiCheckboxList',
    useQueryHook: get(currentSchema, `${fieldname}.useNodes`),
    ...options,
  }
}

const MergeInputMap = {
  SingleInput: 'SingleInputMergeWidget',
  SingleInputWithSuggestions: 'SingleInputMergeWidget',
  SingleSelect: 'SingleSelectMergeWidget',
  MultiInput: 'MultiInputMergeWidget',
  MultiCheckboxList: 'MultiCheckboxMergeWidget',
  TreeSelect: 'TreeSelectMergeWidget',
  MultipleNodeList: 'MultipleNodeListMergeWidget',
}

export function mergeInputFactory(currentSchema, fieldname, options = {}) {
  const config = inputFactory(currentSchema, fieldname, options)

  return {
    ...config,
    widget: get(MergeInputMap, config.widget),
  }
}

export function inputFactory(currentSchema, fieldname, options = {}) {
  const { isTypedArray, extractedType } = computeType(
    currentSchema[fieldname].type,
  )
  const type = isTypedArray ? 'array' : currentSchema[fieldname].type

  switch (type) {
    case 'number':
    case 'string':
    case 'node':
    case 'date':
      if ('useNodes' in currentSchema[fieldname]) {
        return {
          fieldname,
          widget: get(currentSchema[fieldname], 'multiSelect', false)
            ? 'MultiCheckboxList'
            : 'SingleSelect',
          useQueryHook: currentSchema[fieldname].useNodes,
          ...options,
        }
      }

      if (!isEmpty(currentSchema[fieldname].enum)) {
        return {
          fieldname,
          widget: 'SingleSelect',
          nodes: currentSchema[fieldname].enum.map(name => ({
            name,
            id: name,
          })),
          ...options,
        }
      }
      return {
        fieldname,
        widget: 'SingleInput',
        suggestions: currentSchema[fieldname]?.suggestions,
        ...options,
      }
    case 'bool':
      return {
        fieldname,
        widget: 'SingleCheck',
        ...options,
      }
    case 'url':
      if (has(currentSchema[fieldname], 'mimetype'))
        return {
          fieldname,
          widget: 'FileUploader',
          mimetype: get(currentSchema[fieldname], 'mimetype', ''),
          ...options,
        }
      return {
        fieldname,
        widget: 'SingleInputWithUrl',
        ...options,
      }
    case 'array':
      return arrayInputFactory(currentSchema, fieldname, options, extractedType)
    default:
      throw new Error(`${currentSchema[fieldname].type} is not supported yet`)
  }
}
