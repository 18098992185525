import { createContext, useCallback, useMemo } from 'react'
import { useLocalStorage } from '@changing-cc/hooks'
import {
  isNil,
  find,
  filter,
  flatten,
  includes,
  set,
  attempt,
  map,
  get,
  isEmpty,
} from 'lodash'
import { toast } from 'react-toastify'
import { useQuery } from '@apollo/client'

import { kgClient, cceClient } from '@gql'

import { SchemaStore } from '../schema/SchemaStore'
import { KG_NODES } from './KGNodes'
import { getSchemaByPath } from './Util'

import { _get_n_layers, _query_assignees } from './ProjectContext.gql'

export const ProjectContext = createContext({
  privilege: [],
})

function findPath(node, tree, path) {
  const parentId = node.relations?.[0]?.nodes?.[0]
  const parentNode = find(tree, { node_id: parentId })
  return isNil(parentNode)
    ? path
    : findPath(parentNode, tree, [parentId, ...path])
}

export function useProjectContext(privilege) {
  const [peepModeStorage, setPeepMode] = useLocalStorage('peepMode', false)
  const peepMode = useMemo(() => {
    return includes(privilege, 'supervisor') ? peepModeStorage : false
  }, [peepModeStorage, privilege])

  const { loading, data } = useQuery(_query_assignees, {
    client: cceClient,
    skip: !includes(privilege, 'view'),
  })

  const assigneeWhiteList = useMemo(() => {
    if (loading || isNil(data?.queryAssignees)) return []
    if (isEmpty(data.queryAssignees))
      toast.error('No assignee found in the system', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
      })
    return data.queryAssignees
  }, [loading, data])

  const [namespace, setNamespace] = useLocalStorage('namespace', 'Card')
  const [ticketVersion, setTicketVersion] = useLocalStorage(
    'ticketVersion',
    new Date().getFullYear().toString(),
  )

  const [enableRegExpMode, setEnableRegExpMode] = useLocalStorage(
    'SearchWidget.enableRegExpMode',
    false,
  )

  const [enableMarkdown, setEnableMarkdown] = useLocalStorage(
    'Memo.enableMarkdown',
    true,
  )

  const kgNamespace = useMemo(
    () =>
      // shop and card editor namespace share card namespace of kg
      namespace === 'shop' ? 'card' : namespace,
    [namespace],
  )

  const { loading: loadingSuggestions, data: suggestionNodes } = useQuery(
    _get_n_layers,
    {
      client: kgClient,
      variables: {
        namespace: 'myfinance_editor',
        node_id: KG_NODES.myfinance_editor.SUGGESTIONS_ROOT_ID,
        depth: -1,
      },
      skip: !includes(privilege, 'view'),
    },
  )

  const suggestions = useMemo(() => {
    if (loadingSuggestions || isNil(suggestionNodes?.get_n_layers)) return {}
    const nodes = flatten(suggestionNodes.get_n_layers)
    const targets = filter(nodes, ({ attributes }) =>
      find(attributes, { name: 'suggestions' }),
    )
    const suggestions = {}
    for (const node of targets) {
      set(
        suggestions,
        map(
          findPath(node, nodes, [node.node_id]),
          node_id => find(nodes, { node_id }).name,
        ),
        attempt(
          JSON.parse,
          find(node.attributes, { name: 'suggestions' }).values,
        ),
      )
    }
    return suggestions
  }, [suggestionNodes, loadingSuggestions])

  const getSuggestions = useCallback(
    paths => {
      const [schemaName, ...path] = paths
      const rootSchema = SchemaStore?.[schemaName]
      if (isNil(rootSchema)) return []
      const schema = getSchemaByPath(path, rootSchema)
      return schema?.suggestions || get(suggestions, paths, [])
    },
    [suggestions],
  )

  return {
    peepMode,
    setPeepMode,
    assigneeWhiteList,
    namespace,
    setNamespace,
    ticketVersion,
    setTicketVersion,
    kgNamespace,
    enableRegExpMode,
    setEnableRegExpMode,
    enableMarkdown,
    setEnableMarkdown,
    getSuggestions,
  }
}
