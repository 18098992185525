import { useState, useEffect, useCallback } from 'react'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from 'primereact/button'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { position } from 'styled-system'
import { get } from 'lodash'
import { GoogleLogin, GoogleLogout } from '@changingai/react-google-login'

const ButtonDiv = styled.div`
  ${position}
  position: absolute;
  right: 1px;
  top: 1px;
  width: 41px;
  height: 41px;
  & .p-button.p-button-icon-only {
    width: 100%;
    height: 100%;
    background-color: YellowGreen;
    border: 1px solid YellowGreen;
    border-radius: unset;
  }
`

function GoogleButton(props) {
  const {
    callback: [doLogin, doLogout],
    login,
    skipLogin,
  } = props

  const createButton = (icon, callback) => (
    <Button className="p-button-warning" icon={icon} onClick={callback} />
  )

  const icon = login ? 'pi pi-sign-out' : 'pi pi-sign-in'
  if (skipLogin) {
    return createButton(icon, login ? doLogout : doLogin)
  }

  const clientId =
    '169058844350-08md520dgjbd9uvj2r3gdqvm06sb6378.apps.googleusercontent.com'

  if (!login) {
    return (
      <GoogleLogin
        clientId={clientId}
        onSuccess={doLogin}
        onFailure={doLogin}
        render={renderProps => createButton(icon, renderProps.onClick)}
      />
    )
  }

  return (
    <GoogleLogout
      clientId={clientId}
      onLogoutSuccess={doLogout}
      render={renderProps => createButton(icon, renderProps.onClick)}
    />
  )
}

GoogleButton.propTypes = {
  callback: PropTypes.arrayOf(PropTypes.func),
  skipLogin: PropTypes.bool,
  login: PropTypes.bool,
}

const createOption = (data, endpoint) => {
  const url = (endpoint => {
    if (process.env.REACT_APP_LOGIN_URL) {
      return process.env.REACT_APP_LOGIN_URL + endpoint
    }
    return window.location.origin + endpoint
  })(endpoint)

  return {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    withCredentials: true,
    timeout: 1500,
    data,
    url,
  }
}

const loginService = {
  can: '/login-service/can',
  who: '/login-service/who',
  login: '/login-service/login',
  logout: '/login-service/logout',
}

const ok = 200

function LoginButton(props) {
  const [login, setLogin] = useState(false)
  const [skipLogin, setSkipLogin] = useState(false)
  const { onChange, app } = props

  const fetchPrivilege = useCallback(
    async app => {
      const failure = () => {
        setLogin(false)
        onChange([], '')
      }
      try {
        const promises = [
          axios(createOption({ app }, loginService.can)),
          axios(createOption({ app }, loginService.who)),
        ]

        const [
          { status: canStatus, data: can },
          {
            status: whoStatus,
            data: { email },
          },
        ] = await Promise.all(promises)

        if (canStatus !== ok || whoStatus !== ok) return failure()

        setLogin(true)
        onChange(can, email)
      } catch (e) {
        if (get(e, 'code') === 'ECONNABORTED')
          toast.error(
            `Error: Login has timedout, it took over ${get(
              e,
              'config.timeout',
            )} ms`,
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: false,
            },
          )
        failure()
      }
    },
    [setLogin, onChange],
  )

  useEffect(() => {
    if (process.env.REACT_APP_SKIP_LOGIN === '1') {
      setSkipLogin(true)
    } else {
      fetchPrivilege(app)
    }
  }, [app, fetchPrivilege, onChange])

  async function doLogin({ tokenId }) {
    if (skipLogin) {
      setLogin(true)
      onChange(['all'])
      return
    }

    if (tokenId) {
      let options = createOption({ token_id: tokenId }, loginService.login)
      const { status } = await axios(options)
      if (status === ok) {
        await fetchPrivilege(app)
      }
    }
  }

  function doLogout() {
    setLogin(false)
    onChange([])
    const cookies = new Cookies()
    cookies.set('login.sid', '', { path: '/' })
  }

  return (
    <ButtonDiv zIndex={9}>
      <GoogleButton
        callback={[doLogin, doLogout]}
        login={login}
        skipLogin={skipLogin}
      />
    </ButtonDiv>
  )
}

LoginButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  app: PropTypes.string,
}

export default LoginButton
