import { namespace, schema, backendDocType, docPrivilege } from './config'
import { useTranspile } from './common'
import loader, {
  CardRewardRestorer,
  generateReferenceDoc,
  queryManifest,
  fetchPerfectMatchedDocs,
} from './loader'
import listview from './listview'
import compareview from './compare'
import composedview from './composed'
import {
  useTicketScopeMap,
  ticketProfile,
  useScopeName,
  useCandidator,
} from './ticket'
import { addViewManifest } from '../Config'

const manifest = {
  loader,
  schema,
  namespace,
  backendDocType,
  privilege: docPrivilege,
  queryManifest,
  useTranspile,
  generateReferenceDoc,
  restore: CardRewardRestorer,
  listview,
  compareview,
  composedview,
  fetchPerfectMatchedDocs,
  useTicketScopeMap,
  ticketProfile,
  useScopeName,
  useCandidator,
  order: 2,
}

addViewManifest(manifest)
