import { useMemo, useCallback, useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useImmer } from 'use-immer'
import {
  castArray,
  compact,
  concat,
  countBy,
  filter,
  find,
  flatten,
  flattenDeep,
  forEach,
  fromPairs,
  get,
  groupBy,
  has,
  head,
  includes,
  isArrayLike,
  isEmpty,
  isFunction,
  isNil,
  isNull,
  isPlainObject,
  join,
  keys,
  map,
  noop,
  omit,
  partial,
  partition,
  pickBy,
  repeat,
  set,
  setWith,
  size,
  some,
  split,
  uniq,
} from 'lodash'
import md5 from 'md5'
import produce from 'immer'
import { Chip } from 'primereact/chip'
import { DataView } from 'primereact/dataview'
import { Dropdown } from 'primereact/dropdown'
import { AiFillMediumCircle } from 'react-icons/ai'
import {
  MdRadioButtonChecked,
  MdRadioButtonUnchecked,
  MdInvertColors,
  MdInvertColorsOff,
} from 'react-icons/md'
import {
  RiExchangeBoxLine,
  RiExchangeBoxFill,
  RiFileList3Line,
} from 'react-icons/ri'
import { TiMinus, TiMinusOutline } from 'react-icons/ti'
import ReactTooltip from 'react-tooltip'
import { diffChars } from 'diff'
import { useLocalStorage } from '@changing-cc/hooks'
import {
  Box,
  Flex,
  Text,
  StyledOverlay,
} from '@changingai/react-editor-common-component'
import { Link } from 'react-router-dom'

import { CompareDiffDialog } from '@dialog'
import {
  TicketPropType,
  PropCrud,
  getActiveState,
  ProjectContext,
  computeType,
  useDialog,
} from '@common'
import {
  ActionButtons,
  Card,
  Checkbox,
  StatusBarRoot,
  ButtonLike,
  Checkbox as IconCheckbox,
  ConfirmDialog,
} from '@widget'
import theme from '@theme'

import { useDefaultCrud, bindLayoutSchema, findLayoutSchema } from './Common'
import {
  FloatingMenu,
  MenuIcon,
  ViewRoot,
  useFieldSelectionMenuIcon,
  DataViewHost,
} from './Shell'
import { useValidateDialog } from './Validation'
import { useTagContext } from './TagMemoContext'
import { useReturnTicket } from './TicketHelper'
import SummaryDoc from './SummaryDoc'
import { createLayoutSchema } from './SummaryFields'

import ReturnIcon from '../img/return.png'

const dotPrefix = '．'

// using var to avoid Temporal Dead Zone
// https://developer.mozilla.org/zh-TW/docs/Web/JavaScript/Reference/Statements/let#temporal_dead_zone_and_errors_with_let
var diffLayoutMap = {}
export const bindSchemaWithDiffLayout = partial(bindLayoutSchema, diffLayoutMap)
export const findDiffLayoutSchema = partial(findLayoutSchema, diffLayoutMap)

var linkedFieldsMap = {}
export function bindSchemaWithLinkedFields(currentSchema, linkedFields) {
  if (size(flatten(linkedFields)) !== size(uniq(flatten(linkedFields))))
    throw new Error('Linked fields should be unique')
  if (some(linkedFields, isEmpty))
    throw new Error('Linked fields should not be empty')
  if (some(flatten(linkedFields), field => !has(currentSchema, field)))
    throw new Error('Found field not exist in schema')
  set(linkedFieldsMap, md5(join(keys(currentSchema))), linkedFields)
}

export function findLinkedFields(currentSchema) {
  return get(linkedFieldsMap, md5(join(keys(currentSchema))), [])
}

export function generateStringifyTree(doc, currentSchema, diffPaths) {
  function generateTree(paths) {
    return paths.reduce(
      (tree, path) => setWith(tree, path, get(doc, path), Object),
      {},
    )
  }
  function drawTree(tree, currentSchema, depth = 0) {
    if (!isPlainObject(tree)) return [` : ${tree}`]

    return join(
      flattenDeep(
        map(tree, (fieldValue, fieldIndex) => {
          const schema = !has(currentSchema, fieldIndex)
            ? currentSchema
            : computeType(currentSchema[fieldIndex].type).extractedSchema ??
              currentSchema
          return concat(
            `\n${repeat(dotPrefix, depth)}`,
            isNaN(parseInt(fieldIndex))
              ? get(currentSchema, `${fieldIndex}.label`, fieldIndex)
              : `【${fieldIndex}】`,
            drawTree(fieldValue, schema, depth + 1),
          )
        }),
      ),
      '',
    )
  }
  return drawTree(generateTree(diffPaths), currentSchema)
}

export function DiffContent({ diffs, showDiffColor, showDeleteChar }) {
  return (
    <Box>
      {map(diffs, (part, index) => {
        const color = part.added ? 'red' : part.removed ? 'green' : 'black'
        const textDecoration = part.added ? 'line-through' : ''
        const backgroundColor = part.removed && showDiffColor ? 'pink' : 'white'
        return (
          <Text
            key={index}
            style={{
              display: 'inline',
              color,
              padding: '0',
              textDecoration,
              backgroundColor,
            }}
          >
            {showDeleteChar || !part.added ? part.value : ''}
          </Text>
        )
      })}
    </Box>
  )
}

DiffContent.propTypes = {
  diffs: PropTypes.array.isRequired,
  showDiffColor: PropTypes.bool.isRequired,
  showDeleteChar: PropTypes.bool.isRequired,
}

function GeneralDiffContent({
  current,
  pair,
  transpiledDocs,
  fieldname,
  currentSchema,
  showDeleteChar,
  showDiffColor,
  diffPaths,
}) {
  const [currentTranspiled, pairTranspiled] = useMemo(
    () => [
      find(transpiledDocs, { _id: get(current, '_id') }),
      find(transpiledDocs, { _id: get(pair, '_id') }),
    ],
    [transpiledDocs, current, pair],
  )

  const composeRenderContent = useCallback(
    doc => {
      if (isNil(doc[fieldname])) return ''
      const { isTypedArray, extractedSchema } = computeType(
        currentSchema[fieldname].type,
      )
      if (!isNil(extractedSchema)) {
        return generateStringifyTree(doc, currentSchema, diffPaths)
      }

      return isTypedArray
        ? get(doc, fieldname, [])
            .map(row => `• ${row}`)
            .join('\n')
        : get(doc, fieldname, '').toString()
    },
    [currentSchema, fieldname, diffPaths],
  )

  const diffs = useMemo(
    () =>
      diffChars(
        composeRenderContent(currentTranspiled),
        composeRenderContent(pairTranspiled),
      ),
    [composeRenderContent, currentTranspiled, pairTranspiled],
  )
  if (
    isNil(currentTranspiled[fieldname]) ||
    (isArrayLike(currentTranspiled[fieldname]) &&
      isEmpty(currentTranspiled[fieldname]))
  ) {
    return '🈳'
  }

  // decorate diff color for two urls is verbose
  if (computeType(currentSchema[fieldname].type).extractedType === 'url') {
    return (
      <>
        {castArray(currentTranspiled[fieldname]).map(url => (
          <Box key={url}>
            <a href={url} target="_blank" rel="noopener noreferrer">
              {`• ${url}`}
            </a>
          </Box>
        ))}
      </>
    )
  }
  if (
    isEmpty(pairTranspiled[fieldname]) ||
    ['date', 'bool', 'number'].includes(currentSchema[fieldname].type)
  )
    return <>{composeRenderContent(currentTranspiled)}</>

  return (
    <DiffContent
      diffs={diffs}
      showDeleteChar={showDeleteChar}
      showDiffColor={showDiffColor}
    />
  )
}

GeneralDiffContent.propTypes = {
  current: PropTypes.any.isRequired,
  pair: PropTypes.any.isRequired,
  transpiledDocs: PropTypes.array.isRequired,
  fieldname: PropTypes.string.isRequired,
  currentSchema: PropTypes.object.isRequired,
  showDeleteChar: PropTypes.bool.isRequired,
  showDiffColor: PropTypes.bool.isRequired,
  diffPaths: PropTypes.array.isRequired,
}

function FieldValue({
  fieldname,
  diffPaths,
  current,
  pair,
  transpiledDocs,
  currentSchema,
  position,
  selectedDiff,
  updateSelectedDiff,
  selectable,
  showDeleteChar,
  showDiffColor,
  showMergeDialog,
  onUpdate,
  isUpdated,
  crud,
}) {
  const FieldComp = useMemo(() => {
    const renderer = get(
      findDiffLayoutSchema(currentSchema),
      `${fieldname}.renderer`,
      'others',
    )
    return isFunction(renderer) ? renderer : GeneralDiffContent
  }, [currentSchema, fieldname])

  const onChecked = useCallback(
    checked => {
      const fieldsToUpdate = find(findLinkedFields(currentSchema), fields =>
        includes(fields, fieldname),
      ) || [fieldname]
      forEach(fieldsToUpdate, field =>
        updateSelectedDiff(field, checked ? position : ''),
      )
    },
    [fieldname, position, updateSelectedDiff, currentSchema],
  )

  const overwritable = useMemo(
    () => !isUpdated && selectable && crud.overwrite,
    [isUpdated, selectable, crud.overwrite],
  )

  return (
    <Flex alignItems="center" height="100%" style={{ whiteSpace: 'pre-line' }}>
      {position === 'right' && (
        <Checkbox
          icons={[MdRadioButtonChecked, MdRadioButtonUnchecked]}
          checked={selectedDiff[fieldname] === position}
          disabled={!selectable || isUpdated}
          onChecked={onChecked}
          color={theme.colors.icon}
          style={{ flex: '0 1' }}
        />
      )}
      <Flex
        flex="1 1"
        style={{ cursor: overwritable ? 'pointer' : 'auto' }}
        overflow="auto"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        px={2}
        onClick={() => {
          if (overwritable)
            showMergeDialog({
              fieldname,
              leftValue: position === 'left' ? current : pair,
              rightValue: position === 'right' ? current : pair,
              crud,
              onUpdate,
              position,
            })
        }}
      >
        <FieldComp
          current={current}
          pair={pair}
          transpiledDocs={transpiledDocs}
          fieldname={fieldname}
          currentSchema={currentSchema}
          showDeleteChar={showDeleteChar}
          showDiffColor={showDiffColor}
          diffPaths={diffPaths}
        />
      </Flex>
      {position === 'left' && (
        <Checkbox
          icons={[MdRadioButtonChecked, MdRadioButtonUnchecked]}
          checked={selectedDiff[fieldname] === position}
          disabled={!selectable || isUpdated}
          onChecked={onChecked}
          color={theme.colors.icon}
          style={{ flex: '0 1' }}
        />
      )}
    </Flex>
  )
}

FieldValue.propTypes = {
  current: PropTypes.any.isRequired,
  pair: PropTypes.any.isRequired,
  fieldname: PropTypes.string.isRequired,
  diffPaths: PropTypes.array,
  index: PropTypes.number,
  transpiledDocs: PropTypes.array.isRequired,
  currentSchema: PropTypes.object.isRequired,
  position: PropTypes.oneOf(['left', 'right']).isRequired,
  selectedDiff: PropTypes.object.isRequired,
  updateSelectedDiff: PropTypes.func.isRequired,
  selectable: PropTypes.bool.isRequired,
  showDeleteChar: PropTypes.bool.isRequired,
  showDiffColor: PropTypes.bool.isRequired,
  showMergeDialog: PropTypes.func.isRequired,
  crud: PropCrud.isRequired,
  onUpdate: PropTypes.func.isRequired,
  isUpdated: PropTypes.bool.isRequired,
}

function DiffPanel({
  currentSchema,
  fieldname,
  diffPaths,
  left,
  right,
  selectedDiff,
  updateSelectedDiff,
  transpiledDocs,
  crud,
  isPrimary,
  showDeleteChar,
  showDiffColor,
  showMergeDialog,
  onUpdate,
  isUpdated,
  disableSelect,
}) {
  return (
    <>
      <Flex
        p="1"
        justifyContent="center"
        textAlign
        width="100%"
        fontWeight={isPrimary ? 'bold' : 'normal'}
        bg={isUpdated ? 'darkHotPink' : isPrimary ? 'warning' : 'veryLightGrey'}
        alignItems="center"
      >
        {get(currentSchema, `${fieldname}.label`, fieldname) +
          `${isPrimary ? ' (Primary)' : ''}` +
          `${isUpdated ? ' (Overwritten)' : ''}`}
      </Flex>
      {
        <Flex width="100%" bg={isUpdated ? 'paleGrey' : 'auto'}>
          <Box width="50%">
            <FieldValue
              currentSchema={currentSchema}
              fieldname={fieldname}
              diffPaths={diffPaths}
              transpiledDocs={transpiledDocs}
              current={left}
              pair={right}
              position="left"
              selectedDiff={selectedDiff}
              updateSelectedDiff={updateSelectedDiff}
              selectable={!disableSelect && crud.overwrite}
              showDeleteChar={showDeleteChar}
              showDiffColor={showDiffColor}
              showMergeDialog={showMergeDialog}
              crud={crud}
              onUpdate={onUpdate}
              isUpdated={isUpdated}
            />
          </Box>
          {/* Splitter */}
          <Box width="1" bg="veryLightGrey" />
          <Box width="50%">
            <FieldValue
              currentSchema={currentSchema}
              fieldname={fieldname}
              diffPaths={diffPaths}
              transpiledDocs={transpiledDocs}
              current={right}
              pair={left}
              position="right"
              selectedDiff={selectedDiff}
              updateSelectedDiff={updateSelectedDiff}
              selectable={!disableSelect}
              showDeleteChar={showDeleteChar}
              showDiffColor={showDiffColor}
              showMergeDialog={showMergeDialog}
              crud={crud}
              onUpdate={onUpdate}
              isUpdated={isUpdated}
            />
          </Box>
        </Flex>
      }
    </>
  )
}

DiffPanel.propTypes = {
  currentSchema: PropTypes.object.isRequired,
  fieldname: PropTypes.string.isRequired,
  diffPaths: PropTypes.array,
  left: PropTypes.object.isRequired,
  right: PropTypes.object.isRequired,
  selectedDiff: PropTypes.object.isRequired,
  updateSelectedDiff: PropTypes.func.isRequired,
  transpiledDocs: PropTypes.array.isRequired,
  crud: PropCrud.isRequired,
  isPrimary: PropTypes.bool.isRequired,
  showDeleteChar: PropTypes.bool.isRequired,
  showDiffColor: PropTypes.bool.isRequired,
  showMergeDialog: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  isUpdated: PropTypes.bool.isRequired,
  disableSelect: PropTypes.bool.isRequired,
}

const statusMap = [
  {
    name: 'FullMatched',
    color: 'Black',
    caption: 'Perfect Match',
  },
  {
    name: 'MightMatched',
    color: 'DarkSlateBlue',
    caption: 'Might Match',
  },
  {
    name: 'Orphan',
    color: 'SeaGreen',
    caption: 'No Match',
  },
]
function CompareDoc({
  status,
  leftDoc,
  rightDoc,
  point,
  transpiledDocs,
  currentSchema,
  diffs,
  updateMatched,
  changed,
  uploadDoc,
  crud,
  primaryKeys,
  showDeleteChar,
  showDiffColor,
  useTranspile,
  showEditor,
  tags,
  showMergeDialog,
  selectedFields,
}) {
  const [selectedDiff, setSelectedDiff] = useImmer({})
  const updateSelectedDiff = useCallback(
    (fieldname, position) => {
      setSelectedDiff(draft => {
        draft[fieldname] = position
      })
    },
    [setSelectedDiff],
  )

  const [updatedFields, setUpdatedFields] = useState([])

  const [leftContext, setLeftContext] = useImmer(leftDoc)
  const [rightContext, setRightContext] = useImmer(rightDoc)

  const onUpdate = useCallback(
    (fieldname, leftOrRight, value) => {
      const setter = leftOrRight === 'left' ? setLeftContext : setRightContext
      setter(draft => {
        draft[fieldname] = value
      })
      setUpdatedFields(uniq([fieldname, ...updatedFields]))
      setSelectedDiff(draft => omit(draft, fieldname))
    },
    [
      setLeftContext,
      setRightContext,
      setUpdatedFields,
      setSelectedDiff,
      updatedFields,
    ],
  )

  const onReset = useCallback(() => {
    setSelectedDiff(() => ({}))
    setLeftContext(() => leftDoc)
    setRightContext(() => rightDoc)
    setUpdatedFields([])
  }, [
    setUpdatedFields,
    setSelectedDiff,
    setLeftContext,
    setRightContext,
    leftDoc,
    rightDoc,
  ])

  useEffect(() => {
    onReset()
  }, [onReset])

  const [showDiff, renderDiff] = useDialog(CompareDiffDialog)
  const [uploading, setUploading] = useState(false)

  const onAction = useCallback(
    async action => {
      function multiplexDoc(current, pair, selectedDiff, position) {
        const switchToPair = pickBy(pair, (_, fieldname) => {
          return get(selectedDiff, fieldname) === position
        })
        if (isEmpty(switchToPair) && isEmpty(updatedFields)) return null
        return {
          ...current,
          ...switchToPair,
        }
      }
      switch (action) {
        case 'Reset':
          onReset()
          break
        case 'Upload': {
          const toBeUpdated = compact([
            multiplexDoc(leftContext, rightContext, selectedDiff, 'right'),
            multiplexDoc(rightContext, leftContext, selectedDiff, 'left'),
          ])

          if (isEmpty(toBeUpdated))
            throw new Error(
              'Upload should be trigger only if one of docs is dirty at least.',
            )

          setUploading(true)
          updateMatched(await uploadDoc(toBeUpdated))
          setUploading(false)

          setUpdatedFields([])
          setSelectedDiff(() => ({}))
          break
        }
        case 'Diff':
          showDiff()
          break
        case 'Left':
          showEditor(leftDoc, crud)
          break
        case 'Right':
          showEditor(rightDoc, crud)
          break
        default:
          throw new Error(`Unexpected action: ${action}`)
      }
    },
    [
      selectedDiff,
      updatedFields,
      updateMatched,
      leftContext,
      rightContext,
      uploadDoc,
      showDiff,
      onReset,
      setSelectedDiff,
      setUpdatedFields,
      crud,
      leftDoc,
      rightDoc,
      showEditor,
    ],
  )

  const sortedDiffs = useMemo(() => {
    return [
      ...map(groupBy(diffs, 'fieldname'), (values, fieldname) => ({
        fieldname,
        diffPaths: uniq(map(values, 'diffPath')),
        disableSelect: some(map(values, 'disableSelect')),
      })).sort(({ fieldname }) => (primaryKeys.includes(fieldname) ? -1 : 1)),
    ]
  }, [diffs, primaryKeys])

  const matchedFields = useMemo(
    () =>
      isNil(leftDoc) || isNil(rightDoc)
        ? []
        : filter(selectedFields, fieldname => !find(diffs, { fieldname })),
    [selectedFields, diffs, leftDoc, rightDoc],
  )

  const hasLockTag = useMemo(
    () =>
      includes(
        [
          ...get(tags, get(leftDoc, '_id'), []),
          ...get(tags, get(rightDoc, '_id'), []),
        ],
        'lock',
      ),
    [tags, leftDoc, rightDoc],
  )
  const canUpload = useMemo(() => {
    return (
      (!isEmpty(compact(Object.values(selectedDiff))) ||
        !isEmpty(updatedFields)) &&
      !hasLockTag
    )
  }, [updatedFields, selectedDiff, hasLockTag])

  const layoutSchema = useMemo(() => createLayoutSchema(currentSchema), [
    currentSchema,
  ])

  const rightButtons = useMemo(
    () =>
      !isNil(leftDoc) && !isNil(rightDoc)
        ? compact([
            crud.update && {
              label: 'Reset',
              action: 'Reset',
              className: 'p-button-warning',
              enable: canUpload,
            },
            crud.update && {
              label: 'Upload',
              action: 'Upload',
              className: 'p-button-primary',
              icon: uploading ? 'pi pi-spin pi-spinner' : '',
              enable: canUpload,
              badge: hasLockTag ? 'L' : '',
            },
            {
              label: 'Diff',
              action: 'Diff',
              className: 'p-button-success',
            },
          ])
        : [],
    [leftDoc, rightDoc, canUpload, uploading, hasLockTag, crud.update],
  )

  const centerButtons = useMemo(
    () =>
      compact([
        crud.update &&
          !isNil(leftDoc) && {
            action: 'Left',
            className: 'p-button-rounded',
            icon: 'pi pi-arrow-left',
          },
        crud.update &&
          !isNil(rightDoc) && {
            action: 'Right',
            className: 'p-button-rounded',
            icon: 'pi pi-arrow-right',
          },
      ]),
    [crud.update, leftDoc, rightDoc],
  )

  return (
    <Card flex="1 0 100%" my="1" boxShadow="none">
      <Flex position="relative" width="100%" flexWrap="wrap">
        {renderDiff({
          docs: [leftDoc, rightDoc],
          useTranspile,
        })}
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flex="1 1 100%"
          bg={find(statusMap, { name: status }).color}
          color="white"
          px="2"
          mb="1"
        >
          <Box size="6">
            {changed && (
              <AiFillMediumCircle
                style={{
                  color: 'light',
                  width: '100%',
                  height: '100%',
                }}
              />
            )}
          </Box>
          <Box>{`${
            leftDoc
              ? `${leftDoc._id} [${head(leftDoc.uploader.split('@'))}]`
              : 'NoMatch'
          } ${
            rightDoc
              ? ` - ${rightDoc._id} [${head(rightDoc.uploader.split('@'))}]`
              : ''
          }`}</Box>
          <Box>{`primary: ${point[0]}/secondary: ${point[1]}`}</Box>
        </Flex>
        {!isEmpty(matchedFields) && (
          <SummaryDoc
            doc={leftDoc}
            transpiled={find(transpiledDocs, { _id: get(leftDoc, '_id') })}
            updated={false}
            crud={{ ...crud, read: false }}
            currentSchema={currentSchema}
            selectedFields={matchedFields}
            layoutSchema={layoutSchema}
          />
        )}
        <Box flex="1 1 100%" width="100%" border="1px solid lightgray">
          {sortedDiffs.map(diff => {
            const { fieldname, diffPaths, disableSelect = false } = diff
            return (
              <DiffPanel
                key={fieldname}
                fieldname={fieldname}
                diffPaths={diffPaths}
                left={leftDoc}
                right={rightDoc}
                isPrimary={includes(primaryKeys, fieldname)}
                currentSchema={currentSchema}
                transpiledDocs={transpiledDocs}
                selectedDiff={selectedDiff}
                updateSelectedDiff={updateSelectedDiff}
                crud={crud}
                showDeleteChar={showDeleteChar}
                showDiffColor={showDiffColor}
                showMergeDialog={showMergeDialog}
                onUpdate={onUpdate}
                isUpdated={includes(updatedFields, fieldname)}
                disableSelect={disableSelect}
              />
            )
          })}
        </Box>
        {(!isEmpty(centerButtons) || !isEmpty(rightButtons)) && (
          <StatusBarRoot justifyContent="space-between">
            {!isEmpty(updatedFields) && (
              <Box flex="1 1 100%">
                <Text mx={1}>修改內容</Text>
                {map(updatedFields, key => (
                  <Chip
                    style={{ marginLeft: '5px' }}
                    key={key}
                    label={currentSchema[key].label}
                  />
                ))}
              </Box>
            )}
            <ActionButtons
              buttons={{
                right: rightButtons,
                center: centerButtons,
              }}
              onClicked={onAction}
            />
          </StatusBarRoot>
        )}
      </Flex>
    </Card>
  )
}

CompareDoc.propTypes = {
  status: PropTypes.string.isRequired,
  leftDoc: PropTypes.object,
  rightDoc: PropTypes.object,
  point: PropTypes.arrayOf(PropTypes.number).isRequired,
  transpiledDocs: PropTypes.array.isRequired,
  currentSchema: PropTypes.object.isRequired,
  diffs: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateMatched: PropTypes.func.isRequired,
  changed: PropTypes.bool.isRequired,
  uploadDoc: PropTypes.func.isRequired,
  crud: PropCrud.isRequired,
  primaryKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  showDeleteChar: PropTypes.bool.isRequired,
  showDiffColor: PropTypes.bool.isRequired,
  useTranspile: PropTypes.func.isRequired,
  showEditor: PropTypes.func.isRequired,
  tags: PropTypes.object,
  showMergeDialog: PropTypes.func.isRequired,
  selectedFields: PropTypes.arrayOf(PropTypes.string).isRequired,
}

function TicketHeader({ ticket }) {
  return (
    <Box>
      <Box fontSize="h1">{ticket.name}</Box>
      <Box fontSize="small" color="info" width="100%">
        {`狀態：${getActiveState(ticket).main_state}/${
          getActiveState(ticket).sub_state
        }`}
      </Box>
    </Box>
  )
}

TicketHeader.propTypes = {
  ticket: TicketPropType.isRequired,
}

export function TicketHeaderWithLink({ ticket, to, linkText, externalUrl }) {
  return (
    <Box width="50%">
      <Flex fontSize="h1">
        <Box>{ticket.name}</Box>
        {/* <Link/> is a router-aware anchor, if you want to link to an external site, use an <a/>. */}
        {externalUrl && (
          <a href={to} target="_blank" rel="noopener noreferrer">
            {linkText}
          </a>
        )}
        {!externalUrl && (
          <Link to={to} target="_blank" rel="noopener noreferrer">
            {linkText}
          </Link>
        )}
      </Flex>
      <Box fontSize="small" color="info" width="100%">
        {`狀態：${getActiveState(ticket).main_state}/${
          getActiveState(ticket).sub_state
        }`}
      </Box>
    </Box>
  )
}

TicketHeaderWithLink.propTypes = {
  ticket: TicketPropType.isRequired,
  to: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  externalUrl: PropTypes.bool.isRequired,
}

function PrimaryKeySelector({
  currentSchema,
  allPrimaryKeys,
  selectedPrimaryKeys,
  onSelected,
}) {
  const options = useMemo(
    () =>
      allPrimaryKeys.map(item => ({
        label: item.name,
        value: item,
      })),
    [allPrimaryKeys],
  )

  return (
    <Flex width="20vw" flexWrap="wrap">
      <Dropdown
        value={selectedPrimaryKeys}
        placeholder="選擇主要比對欄位"
        options={options}
        onChange={({ value }) => {
          onSelected(value)
        }}
        style={{ flex: '1 1 100%' }}
      />
      <Flex flex="1 1 100%" color="brownGrey" justifyContent="flex-end">
        {join(
          map(
            get(selectedPrimaryKeys, 'keys'),
            fieldname => get(currentSchema, `${fieldname}.label`),
            ', ',
          ),
        )}
      </Flex>
    </Flex>
  )
}

PrimaryKeySelector.propTypes = {
  selectedPrimaryKeys: PropTypes.shape({
    name: PropTypes.string.isRequired,
    keys: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
  onSelected: PropTypes.func.isRequired,
  allPrimaryKeys: PropTypes.array.isRequired,
  currentSchema: PropTypes.object.isRequired,
}

function CompareViewHeader({
  docs,
  value,
  allPrimaryKeys,
  selectedPrimaryKeys,
  onSelected,
  currentSchema,
  ticket,
  uploaders = [],
  filters,
  setFilters,
  Caption = TicketHeader,
}) {
  const docsCount = countBy(value, 'status')
  const groupDocs = partition(docs, {
    uploader: get(uploaders, 0),
  })

  return (
    <Flex width="100%" flexWrap="wrap">
      <Flex
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        mb="2"
      >
        <Caption ticket={ticket} />
        <PrimaryKeySelector
          allPrimaryKeys={allPrimaryKeys}
          selectedPrimaryKeys={selectedPrimaryKeys}
          onSelected={onSelected}
          currentSchema={currentSchema}
        />
      </Flex>
      <Flex width="100%">
        {map(groupDocs, (docs, index) => (
          <Flex
            key={index}
            fontSize="larger"
            fontWeight="bold"
            flex="1 1 50%"
            justifyContent="center"
          >
            {`${split(get(uploaders, index), '@')[0]} created ${size(
              docs,
            )} docs`}
          </Flex>
        ))}
      </Flex>
      <Flex justifyContent="space-evenly" width="100%" mt={2}>
        {map(statusMap, ({ name, color, caption }) => (
          <IconCheckbox
            key={name}
            color={color}
            text={`${caption}: ${get(docsCount, name, 0)}`}
            checked={filters[name]}
            onChecked={() => {
              setFilters(
                produce(filters, draft => {
                  draft[name] = !draft[name]
                }),
              )
            }}
          />
        ))}
      </Flex>
    </Flex>
  )
}

CompareViewHeader.propTypes = {
  docs: PropTypes.arrayOf(PropTypes.shape({ _id: PropTypes.string.isRequired }))
    .isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      current: PropTypes.object,
      scores: PropTypes.array,
    }),
  ),
  allPrimaryKeys: PropTypes.array.isRequired,
  selectedPrimaryKeys: PropTypes.shape({
    name: PropTypes.string.isRequired,
    keys: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
  onSelected: PropTypes.func.isRequired,
  currentSchema: PropTypes.object.isRequired,
  ticket: TicketPropType.isRequired,
  uploaders: PropTypes.arrayOf(PropTypes.string),
  filters: PropTypes.shape({
    FullMatched: PropTypes.bool.isRequired,
    MightMatched: PropTypes.bool.isRequired,
    Orphan: PropTypes.bool.isRequired,
  }),
  setFilters: PropTypes.func.isRequired,
  Caption: PropTypes.func,
}

function CompareView({
  ticket,
  useController,
  useTranspile,
  currentSchema,
  useCrud = useDefaultCrud,
  Caption,
  checker,
}) {
  const {
    docs,
    matched,
    updateMatched,
    uploadDoc,
    oneOneMapping,
    setOneOneMappting,
    computing,
    showEditor,
    renderEditor,
    uploaders,
    allPrimaryKeys,
    selectedPrimaryKeys,
    setSelectedPrimaryKeys,
    renderMergeDialog = noop,
    showMergeDialog = noop,
  } = useController(ticket)

  const transpiledDocs = useTranspile(docs, null)
  const [filters, setFilters] = useLocalStorage(
    `compare.${ticket.scope_type}.filters`,
    fromPairs(map(statusMap, ({ name }) => [name, true])),
  )

  const filteredDocs = useMemo(
    () =>
      isEmpty(transpiledDocs)
        ? []
        : filter(matched, ({ status }) => get(filters, status)),
    [transpiledDocs, matched, filters],
  )

  const crud = useCrud(ticket)

  const [showDeleteChar, setShowDeleteChar] = useLocalStorage(
    'showDeleteChar',
    true,
  )
  const [showDiffColor, setShowDiffColor] = useLocalStorage(
    'showDiffColor',
    true,
  )
  const [swapCompareDocBase, setSwapCompareDocBase] = useState(false)

  const { tags, isLoadingTag } = useTagContext(map(docs, '_id'))

  const [showConfirmDialog, renderConfirmDialog] = useDialog(ConfirmDialog)
  const { email } = useContext(ProjectContext)
  const { canReturn, onReturn } = useReturnTicket(ticket, email)

  const onUpdateMatched = useCallback(
    ({ docs }) => {
      if (!isNil(docs)) updateMatched(docs)
    },
    [updateMatched],
  )

  const { renderValidation, onValidAndUpload } = useValidateDialog({
    checkOp: checker,
    onUpdate: onUpdateMatched,
    onUpload: uploadDoc,
    currentSchema,
  })

  const {
    FieldSelectionMenuButton,
    selectedFields,
  } = useFieldSelectionMenuIcon({
    currentSchema,
    storgeKeyPostfix: 'compare',
  })

  return (
    <StyledOverlay active={computing || isLoadingTag} text="Computing...">
      {renderConfirmDialog({
        onYes: action => {
          if (action === 'OK') onReturn()
        },
      })}
      {allPrimaryKeys && (
        <ViewRoot required={['edit']}>
          {renderValidation({})}
          {renderMergeDialog({ currentSchema, ticket })}
          {renderEditor({
            onUpdate: updateMatched,
            onUpload: uploadDoc,
            currentSchema,
          })}
          <FloatingMenu
            render={() => (
              <>
                <MenuIcon
                  data-tip={
                    oneOneMapping ? '文件1-to-1對應' : '文件1-to-many對應'
                  }
                  size={6}
                >
                  <Checkbox
                    checked={oneOneMapping}
                    onChecked={setOneOneMappting}
                    color={theme.colors.icon}
                    text="1:1"
                  />
                </MenuIcon>
                <ReactTooltip place="right" />
                <MenuIcon
                  data-tip={showDeleteChar ? '顯示刪除文字' : '隱藏刪除文字'}
                  size={6}
                >
                  <Checkbox
                    icons={[TiMinus, TiMinusOutline]}
                    checked={showDeleteChar}
                    onChecked={setShowDeleteChar}
                    color="red"
                  />
                </MenuIcon>
                <ReactTooltip place="right" />
                <MenuIcon
                  data-tip={showDiffColor ? '顯示差異底色' : '隱藏差異底色'}
                  size={6}
                >
                  <Checkbox
                    icons={[MdInvertColors, MdInvertColorsOff]}
                    checked={showDiffColor}
                    onChecked={setShowDiffColor}
                    color={theme.colors.icon}
                  />
                </MenuIcon>
                <MenuIcon
                  data-tip={!swapCompareDocBase ? '以A為基準' : '以B為基準'}
                  size={6}
                >
                  <Checkbox
                    icons={[RiExchangeBoxFill, RiExchangeBoxLine]}
                    checked={swapCompareDocBase}
                    onChecked={setSwapCompareDocBase}
                    color={theme.colors.icon}
                  />
                </MenuIcon>
                <FieldSelectionMenuButton />
                <ReactTooltip place="right" />
                {canReturn && (
                  <MenuIcon key="return">
                    <ButtonLike
                      data-tip="Return"
                      size={6}
                      scaleOnHover
                      onClick={() => {
                        showConfirmDialog({
                          title: 'Info',
                          message: 'Return this ticket?',
                          action: 'OK',
                        })
                      }}
                    >
                      <img
                        width="100%"
                        alt="Not dispatched yet"
                        src={ReturnIcon}
                      />
                    </ButtonLike>
                  </MenuIcon>
                )}
              </>
            )}
          />
          <DataViewHost>
            <DataView
              value={filteredDocs}
              header={
                <CompareViewHeader
                  docs={docs}
                  value={matched}
                  allPrimaryKeys={allPrimaryKeys}
                  uploaders={uploaders}
                  selectedPrimaryKeys={selectedPrimaryKeys}
                  onSelected={primaryKeys => {
                    setSelectedPrimaryKeys(primaryKeys)
                  }}
                  currentSchema={currentSchema}
                  ticket={ticket}
                  filters={filters}
                  setFilters={setFilters}
                  Caption={Caption}
                />
              }
              paginatorPosition={'both'}
              paginator={true}
              rows={30}
              emptyMessage={
                !isNull(docs) && isEmpty(docs) ? '無文件' : '載入中'
              }
              itemTemplate={item => {
                if (!item) return null
                const compareDocs = [
                  item.current,
                  get(item.scores, '0.doc', null),
                ]
                const [leftDoc, rightDoc] = swapCompareDocBase
                  ? compareDocs.reverse()
                  : compareDocs

                return (
                  <CompareDoc
                    key={`${get(leftDoc, '_id')}-${get(rightDoc, '_id')}`}
                    status={item.status}
                    leftDoc={leftDoc}
                    changed={!!item.changed}
                    rightDoc={rightDoc}
                    diffs={get(item, 'scores.0.diff', [])}
                    point={get(item, 'scores.0.score', [0, 0])}
                    transpiledDocs={transpiledDocs}
                    currentSchema={currentSchema}
                    updateMatched={onUpdateMatched}
                    uploadDoc={onValidAndUpload}
                    crud={crud}
                    primaryKeys={selectedPrimaryKeys.keys}
                    showDeleteChar={showDeleteChar}
                    showDiffColor={showDiffColor}
                    useTranspile={useTranspile}
                    showEditor={showEditor}
                    tags={tags}
                    showMergeDialog={showMergeDialog}
                    selectedFields={selectedFields}
                  />
                )
              }}
            />
          </DataViewHost>
          <FloatingMenu
            position="right"
            render={() => (
              <MenuIcon size={6}>
                <ButtonLike
                  data-cy="listview-button"
                  data-tip="goto listview"
                  size={6}
                  scaleOnHover
                  onClick={() => {
                    window.location.assign(
                      window.location.href.replace('compareview', 'listview'),
                    )
                  }}
                >
                  <RiFileList3Line />
                </ButtonLike>
              </MenuIcon>
            )}
          />
        </ViewRoot>
      )}
    </StyledOverlay>
  )
}

CompareView.propTypes = {
  ticket: TicketPropType.isRequired,
  currentSchema: PropTypes.object.isRequired,
  useController: PropTypes.func.isRequired,
  useTranspile: PropTypes.func.isRequired,
  useCrud: PropTypes.func,
  Caption: PropTypes.func,
  checker: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
  ]),
}

export default CompareView
