import { useCallback, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  get,
  isEmpty,
  isEqual,
  isNil,
  map,
  range,
  size,
  trim,
  toString,
} from 'lodash'
import { Flex, Box } from '@changingai/react-editor-common-component'

import {
  TableInput,
  ToastifyLabel,
  createSeparator,
  inputFactory,
  useRenderConfig,
} from '@widget'

import { PlanContext } from './context'
import { actuarialSampleSchema, numberCellSchema } from './schema'

function CashFlow({ context, onDirty, onChange }) {
  const { isReady } = useContext(PlanContext)

  const fieldname = 'cash_flow'

  const cashFlows = useMemo(() => {
    const flows = get(context, fieldname)
    return isEmpty(flows) ? Array(111).fill(Array(8).fill(null)) : flows
  }, [context])

  const onTableChange = useCallback(
    ({ values }) => {
      onDirty(fieldname, !isEqual(values, cashFlows))
      onChange(fieldname, values)
    },
    [onChange, onDirty, cashFlows],
  )

  return (
    <Flex alignItems="center" justifyContent="center">
      <ToastifyLabel
        flex="1 200px"
        fieldname={fieldname}
        label={get(actuarialSampleSchema, `${fieldname}.label`)}
      />
      {isReady && !isEmpty(cashFlows) && (
        <Box width="80vw" height="70vh">
          <TableInput
            schema={numberCellSchema}
            header={map(
              [
                '基本身故金',
                '基本解約金',
                '基本當年生存金',
                '基本滿期金',
                '總身故金',
                '總解約金',
                '總當年生存金',
                '總滿期金',
              ],
              name => `${name}(期末)`,
            )}
            headerEditable={false}
            columnHeader={map(range(1, size(cashFlows) + 1), toString)}
            values={cashFlows}
            format={v => {
              if (isNil(v)) return null
              const formatted = trim(v.replace(/,/g, ''))
              return formatted === '' ? null : formatted
            }}
            fixedDimension
            onChange={table => onTableChange(table)}
          />
        </Box>
      )}
    </Flex>
  )
}

CashFlow.propTypes = {
  context: PropTypes.object.isRequired,
  onDirty: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

export function ActuarialSample({
  context,
  onDirty,
  onUpdateContext,
  onError,
  currentPath,
}) {
  const renderer = useRenderConfig({
    onError,
    onUpdateContext,
    onDirty,
    editContext: context,
    currentSchema: actuarialSampleSchema,
    currentPath,
  })

  const configs = useMemo(
    () => [
      inputFactory(actuarialSampleSchema, 'issue_age'),
      inputFactory(actuarialSampleSchema, 'sex'),
      inputFactory(actuarialSampleSchema, 'insured_amount'),
      inputFactory(actuarialSampleSchema, 'annual_premium'),
      inputFactory(actuarialSampleSchema, 'declared_interest'),
      {
        fieldname: 'cash_flow',
        widget: 'CustomizedInput',
        RenderComp: CashFlow,
      },
      createSeparator({}),
      inputFactory(actuarialSampleSchema, 'note', {
        inputAttributes: {
          multilines: true,
          rows: 5,
        },
      }),
    ],
    [],
  )

  return (
    <Flex width="100%" flexWrap="wrap">
      {configs.map(config => renderer(config))}
    </Flex>
  )
}

ActuarialSample.propTypes = {
  context: PropTypes.object.isRequired,
  onDirty: PropTypes.func.isRequired,
  onUpdateContext: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  currentPath: PropTypes.arrayOf(PropTypes.string).isRequired,
}
