import PropTypes from 'prop-types'
import { partial } from 'lodash'

import { namespace, schema } from './config'
import { BenefitEditDialog } from './editor'
import { benefitSchema } from './schema'
import { getTicketDocPath } from '../Common'
import { useFetchDocAndTicket } from '../DocHelper'
import { referenceDoc } from '../Reference'
import { useRestoreDoc, RestoreDialog } from '../Restore'
import { EditorLoader } from '../SchemaBaseEditorLoader'

import { _queryBenefit } from './loader.gql'

const getPath = partial(getTicketDocPath, namespace, schema)

export const queryManifest = {
  gql: _queryBenefit,
  name: 'queryBenefit',
  getPath,
}

export const generateReferenceDoc = partial(
  referenceDoc,
  'benefit_name',
  getPath,
  schema,
  namespace,
)

export function BenefitRestorer({ doc }) {
  return (
    <RestoreDialog
      DialogComp={BenefitEditDialog}
      useRestoreDoc={partial(useRestoreDoc, doc, namespace)}
    />
  )
}

BenefitRestorer.propTypes = {
  doc: PropTypes.object.isRequired,
}

function BenefitEditorLoader({ docId }) {
  return (
    <EditorLoader
      DialogComp={BenefitEditDialog}
      useFetchDocAndTicket={partial(
        useFetchDocAndTicket,
        _queryBenefit,
        'queryBenefit',
        namespace,
        docId,
      )}
      currentSchema={benefitSchema}
    />
  )
}

BenefitEditorLoader.propTypes = {
  docId: PropTypes.string.isRequired,
}

export default BenefitEditorLoader
