import Joi from 'joi'
import { head, includes } from 'lodash'
import '@changing-cc/hooks'

import {
  KG_NODES,
  rewardNameToID,
  useGetConnectedNodes,
  namespaces,
} from '@common'
import {
  addSchema,
  useCardGroup,
  schemaToPropTypes,
  commonRewardSchema,
  matchStateSchema,
  DateInTwentyYears,
  SchemaEmptyPositive,
  SchemaEmptyNonNegative,
  NullableKGNodeIdString,
  RequiredKGNodeIdString,
  RequiredMongoIdString,
} from '@schema'

const NoUpperBound = 99999

const roots = {
  issuerRoot: [
    {
      node_id: KG_NODES.entity.ISSUER_ROOT_ID,
      namespace: 'entity',
    },
  ],
  levelRoot: [
    {
      node_id: KG_NODES.entity.LEVEL_ROOT_ID,
      namespace: 'entity',
    },
  ],
  currencyRoot: [
    {
      node_id: KG_NODES.entity.CURRENCY,
      namespace: 'entity',
    },
  ],
  dateUnitRoot: [
    {
      node_id: KG_NODES.card.ESSENTIAL_DATE_UNIT,
      namespace: 'card',
    },
  ],
  boundUnitRoot: [
    {
      node_id: KG_NODES.card.REWARD_UPPER_BOUND_UNIT,
      namespace: 'card',
    },
  ],
  rewardRoot: [
    {
      node_id: KG_NODES.card.CARD_REWARD_ROOT_ID,
      namespace: 'card',
    },
  ],
}

export const cardSetSchema = {
  issuer_id: {
    schema: RequiredKGNodeIdString,
    referToKG: 'entity',
    type: 'node',
    label: '發卡組織',
    useNodes: () => useGetConnectedNodes(roots.issuerRoot),
  },
  card_level_id: {
    schema: RequiredKGNodeIdString,
    referToKG: 'entity',
    type: 'node',
    label: '卡等',
    useNodes: () => useGetConnectedNodes(roots.levelRoot),
  },
  card_group_id: {
    schema: RequiredMongoIdString,
    referToCG: true,
    type: 'string',
    label: '卡片名稱',
    useNodes: () => {
      const [cardGroups] = useCardGroup({})
      return cardGroups
    },
  },
}

export const cardRewardSchema = {
  ...commonRewardSchema,
  ...matchStateSchema,
  basic_r: {
    schema: SchemaEmptyNonNegative,
    type: 'number',
    label: '基本回饋',
  },
  re_upper_bound: {
    schema: Joi.alternatives().try(
      Joi.string().valid(''),
      Joi.number()
        .positive()
        .max(100000),
    ),
    type: 'number',
    label: '回饋上限(單位新台幣元)',
    hint: 'empty/positive/!99999',
  },
  essential_money: {
    schema: SchemaEmptyPositive,
    type: 'number',
    label: '取得回饋門檻(消費額)',
  },
  essential_day: {
    schema: SchemaEmptyPositive,
    type: 'number',
    label: '回饋門檻天數',
  },
  free_parking_hrs: {
    schema: Joi.alternatives().try(
      Joi.string().valid(''),
      Joi.number().valid(NoUpperBound),
      // Unit: 小時/次. Free parking for one year is long enough,
      // Check with angela + abnerl to get a reasonable upper bound.
      Joi.number().max(24 * 360),
    ),
    type: 'number',
    label: '免費停車小時',
    hint: 'empty/positive/99999',
    toast: '條件複雜，使用前請詳閱公開說明書',
    dynamicLabel: ({ type_id }) => {
      if (
        includes(rewardNameToID(['市區停車', '市區停車-紅利折抵']), type_id)
      ) {
        return '每次免費停車小時數'
      } else if (type_id === head(rewardNameToID(['道路救援']))) {
        return '道路救援'
      } else if (type_id === head(rewardNameToID(['機場停車']))) {
        return '每次免費停車天數'
      }

      return '免費停車小時'
    },
  },
  free_parking_times: {
    schema: Joi.alternatives().try(
      Joi.string().valid(''),
      Joi.number().positive(),
    ),
    type: 'number',
    label: '免費停車次數',
    dynamicLabel: ({ type_id }) => {
      if (
        includes(rewardNameToID(['市區停車', '市區停車-紅利折抵']), type_id)
      ) {
        return '每月免費停車次數'
      } else if (type_id === head(rewardNameToID(['道路救援']))) {
        return '每年免費拖吊次數'
      } else if (type_id === head(rewardNameToID(['機場停車']))) {
        return '免費停車次數'
      }

      return '免費停車次數'
    },
  },
  currency: {
    schema: NullableKGNodeIdString,
    referToKG: 'entity',
    type: 'node',
    label: '幣別',
    useNodes: () => useGetConnectedNodes(roots.currencyRoot),
  },
  essential_day_unit_id: {
    schema: NullableKGNodeIdString,
    type: 'node',
    referToKG: 'card',
    label: '回饋門檻天數單位',
    useNodes: () => useGetConnectedNodes(roots.dateUnitRoot),
  },
  re_upper_bound_unit_id: {
    schema: NullableKGNodeIdString,
    type: 'node',
    referToKG: 'card',
    label: '回饋上限效期',
    useNodes: () => useGetConnectedNodes(roots.boundUnitRoot),
  },
  include_card_sets: {
    schema: Joi.array()
      .min(1)
      .required(),
    type: 'arrayOf(cardSetSchema)',
    defaultLength: 1,
    label: '卡片組合（包含）',
  },
  exclude_card_sets: {
    schema: Joi.array(),
    type: 'arrayOf(cardSetSchema)',
    label: '卡片組合（剔除）',
  },
  launch_date: {
    schema: Joi.alternatives().try(Joi.string().valid(''), DateInTwentyYears),
    type: 'date',
    label: '上架日期',
  },
  effective_date_b: {
    schema: DateInTwentyYears.required(),
    type: 'date',
    label: '有效開始',
    suggestions: [
      {
        label: '年初',
        value: new Date(new Date().getFullYear(), 0, 1).toLocaleDateString(
          'zh-Hans-CN',
        ),
      },
    ],
  },
  effective_date_e: {
    schema: DateInTwentyYears.required(),
    type: 'date',
    label: '有效結束',
    suggestions: [
      {
        label: '年末',
        value: new Date(new Date().getFullYear(), 11, 31).toLocaleDateString(
          'zh-Hans-CN',
        ),
      },
    ],
  },
  type_id: {
    ...commonRewardSchema.type_id,
    useNodes: () => useGetConnectedNodes(roots.rewardRoot),
  },
}

addSchema({ cardSetSchema })
addSchema({ cardRewardSchema }, '卡片優惠', namespaces.card.value)

export const CardRewardPropType = schemaToPropTypes(cardRewardSchema)
