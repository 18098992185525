import { useMemo } from 'react'
import {
  attempt,
  compact,
  castArray,
  range,
  filter,
  findKey,
  flatMap,
  get,
  groupBy,
  has,
  head,
  isBoolean,
  isEmpty,
  isEqual,
  isError,
  isFunction,
  isNil,
  isNumber,
  isObject,
  keys,
  last,
  map,
  mapValues,
  omit,
  pick,
  trim as lodashTrim,
  uniq,
  size,
  max,
  xor,
  join,
} from 'lodash'

import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import uuid from 'react-uuid'

import {
  KG_NODES,
  computeType,
  PrimitiveTypes,
  useGetConnectedNodes,
  useQueryAndCacheKGByNamespace,
} from '@common'
import { cceClient } from '@gql'

import { SchemaStore } from './SchemaStore'
import { _queryCardGroup } from './SchemaUtils.gql'

export const getSchemaName = currentSchema =>
  findKey(SchemaStore, schema => isEqual(schema, currentSchema))

export function iterateSchema(currentSchema) {
  function* iterate(currentSchema) {
    for (const fieldname of Object.keys(currentSchema)) {
      yield [fieldname, currentSchema[fieldname]]

      const { extractedSchema } = computeType(currentSchema[fieldname].type)
      if (!isNil(extractedSchema)) {
        yield* iterate(extractedSchema)
      }
    }
  }

  return iterate(currentSchema)
}

export function findSchema(fieldname, currentSchema) {
  const matched = [...iterateSchema(currentSchema)].find(
    ([curFieldname]) => curFieldname === fieldname,
  )
  return get(matched, '1')
}

function findTruthValue(currentSchema, key) {
  return uniq(
    [...iterateSchema(currentSchema)]
      .filter(([, val]) => get(val, key, false))
      .map(([fieldname]) => fieldname),
  )
}

export const referToKG = currentSchema =>
  findTruthValue(currentSchema, 'referToKG')
export const referToCG = currentSchema =>
  findTruthValue(currentSchema, 'referToCG')

export function useFetchReferencedKG(
  docs,
  currentSchema,
  selectedFields,
  replaceNameByDisplayName = false,
) {
  const namespaceMap = useMemo(() => {
    const kgs = referToKG(currentSchema)
    const selectedFirstLevelFields = selectedFields || keys(currentSchema)

    const result = flatMap(docs, doc =>
      compact(
        map([...iterateDoc(doc, currentSchema)], ([fieldname, value]) =>
          !kgs.includes(last(fieldname)) ||
          !selectedFirstLevelFields.includes(head(fieldname))
            ? null
            : {
                value,
                namespace: get(
                  findSchema(last(fieldname), currentSchema),
                  'referToKG',
                ),
              },
        ),
      ),
    )

    return mapValues(groupBy(result, 'namespace'), list =>
      uniq(compact(flatMap(list, 'value'))),
    )
  }, [docs, currentSchema, selectedFields])

  const nodes = useQueryAndCacheKGByNamespace(
    namespaceMap,
    replaceNameByDisplayName,
  )
  const carriedId = useMemo(
    () => map(nodes, node => ({ ...node, id: node.node_id })),
    [nodes],
  )

  return carriedId
}

export function schemaToPropTypes(schema) {
  const typeToPropTypesMap = {
    string: PropTypes.string,
    number: PropTypes.number,
    date: PropTypes.string,
    bool: PropTypes.bool,
    node: PropTypes.string,
    url: PropTypes.string,
    image: PropTypes.string,
  }

  const diff = xor(PrimitiveTypes, keys(typeToPropTypesMap))
  if (!isEmpty(diff)) {
    throw new Error(
      `typeToPropTypesMap miss match PrimitiveTypes: ${join(diff, ', ')}`,
    )
  }

  function access(field) {
    const { isTypedArray, isTypedObject, extractedSchema } = computeType(
      field.type,
    )
    if (!isNil(extractedSchema)) {
      if (isTypedArray) {
        return PropTypes.arrayOf(
          PropTypes.shape(mapValues(extractedSchema, field => access(field))),
        )
      }
      if (isTypedObject) {
        return PropTypes.shape(
          mapValues(extractedSchema, field => access(field)),
        )
      }
      throw new Error('schema.type has to be array or object')
    }

    return PropTypes[typeToPropTypesMap[field.type]]
  }

  return PropTypes.shape(mapValues(schema, field => access(field)))
}

export function toEditableDocs(contexts, currentSchema) {
  return map(contexts, context => toEditableDoc(context, currentSchema))
}

export function toEditableDoc(context, currentSchema) {
  if (isNil(currentSchema)) throw new Error('Schema should not be nil')
  if (isNil(context)) return null
  return mapValues(context, (value, key) => {
    // A field which is not in the currentSchema, such as __typename
    // which is appended by gql server.
    if (!has(currentSchema, key)) return value
    const { isTypedArray, extractedSchema } = computeType(
      currentSchema[key].type,
    )
    if (!isNil(extractedSchema)) {
      return isTypedArray
        ? value.map(v => toEditableDoc(v, extractedSchema))
        : toEditableDoc(value, extractedSchema)
    }

    if (get(currentSchema, `${key}.type`) === 'date' && !isNil(value)) {
      return new Date(value).toLocaleDateString('zh-Hans-CN')
    }

    return value
  })
}

export function purifySchemaDoc(context, currentSchema) {
  const result = pick(context, Object.keys(currentSchema))

  return mapValues(result, (value, key) => {
    const { isTypedArray, extractedSchema } = computeType(
      currentSchema[key].type,
    )
    if (!isNil(extractedSchema)) {
      return isTypedArray
        ? value.map(v => purifySchemaDoc(v, extractedSchema))
        : purifySchemaDoc(value, extractedSchema)
    }

    if (currentSchema[key].type === 'date') {
      return toStoreableValue(
        key,
        value ? new Date(value).toISOString() : value,
        currentSchema,
      )
    }

    if (isTypedArray) {
      return map(value, item =>
        isObject(item) && has(item, '__typename')
          ? omit(item, ['__typename'])
          : item,
      )
    }

    return toStoreableValue(
      key,
      isObject(value) && has(value, '__typename')
        ? omit(value, ['__typename'])
        : value,
      currentSchema,
      true,
    )
  })
}

export function iterateDoc(doc, currentSchema) {
  function* iterateInternal(doc, currentSchema, ancestors) {
    for (const fieldname of Object.keys(currentSchema)) {
      yield [[...ancestors, fieldname], doc[fieldname]]
    }

    for (const fieldname of Object.keys(currentSchema).filter(
      fieldname =>
        !isNil(computeType(currentSchema[fieldname].type).extractedSchema),
    )) {
      // doc.fieldname can be null. For example, we do not fetch
      // feeTableSchema.matrices when downloading a product doc. So when
      // you iterate to productSchema.feeTableSchema.matrices of a product
      // doc, you will get null.
      if (!isNil(doc?.[fieldname])) {
        const { extractedSchema, isTypedArray, isTypedObject } = computeType(
          currentSchema[fieldname].type,
        )
        if (isTypedArray) {
          for (const child of get(doc, fieldname, [])) {
            yield* iterateInternal(child, extractedSchema, [
              ...ancestors,
              fieldname,
            ])
          }
        }

        if (isTypedObject) {
          yield* iterateInternal(get(doc, fieldname, {}), extractedSchema, [
            ...ancestors,
            fieldname,
          ])
        }
      }
    }
  }

  return iterateInternal(doc, currentSchema, [])
}

function getDefaultTypedValue(type) {
  const { isTypedArray } = computeType(type)
  if (isTypedArray) {
    return []
  }

  const defaultValueMap = {
    number: null,
    string: null,
    date: null,
    bool: false,
    array: [],
  }

  return defaultValueMap[type]
}

export function createBlankDoc(currentSchema, isRootDoc) {
  function createDoc(currentSchema) {
    return mapValues(
      currentSchema,
      ({ defaultValue, type, defaultLength = 0 }) => {
        const { extractedSchema, isTypedArray } = computeType(type)
        const resolvedValue = isNil(defaultValue)
          ? undefined
          : isFunction(defaultValue)
          ? defaultValue()
          : defaultValue
        if (!isNil(extractedSchema)) {
          if (isTypedArray) {
            return map(
              range(
                max([size(compact(castArray(resolvedValue))), defaultLength]),
              ),
              index =>
                get(castArray(resolvedValue), index) ||
                createDoc(extractedSchema),
            )
          }
          return resolvedValue || createDoc(extractedSchema)
        }
        return resolvedValue || getDefaultTypedValue(type)
      },
    )
  }

  const doc = createDoc(currentSchema)
  return isRootDoc
    ? {
        ...doc,
        _id: uuid(),
        local_doc: true,
      }
    : doc
}

export function getFieldLabel(currentSchema, context, fieldname) {
  const dynamicLabel = get(currentSchema, `${fieldname}.dynamicLabel`)
  return dynamicLabel
    ? dynamicLabel(context)
    : get(currentSchema, `${fieldname}.label`, fieldname)
}

export function useCardGroup({
  scope_id = null,
  card_provider_ids = [],
  skip = false,
}) {
  const { loading, data } = useQuery(_queryCardGroup, {
    client: cceClient,
    variables: {
      doc_ids: !scope_id ? null : [scope_id],
      card_provider_ids: isEmpty(card_provider_ids) ? null : card_provider_ids,
    },
    skip,
  })

  const cardGroups = useMemo(
    () =>
      !data
        ? null
        : data.queryCardGroup.map(group => ({
            ...group,
            id: group._id,
            name: group.card_name,
          })),
    [data],
  )

  return [cardGroups, loading]
}

const benefitRoot = [
  {
    node_id: KG_NODES.insurance.BENEFIT_TYPE_ROOT_ID,
    namespace: 'insurance',
  },
]
export function useBenefitTypes() {
  return useGetConnectedNodes(benefitRoot)
}

// When editing the value of a field, we are forced to change the value
// of some field into string, so that we can render corretly in input.
// Use this function to transform back value back to original type.
export function toStoreableValue(
  fieldname,
  value,
  currentSchema,
  trim = false,
) {
  function toTypedStoreableValue(value, type) {
    switch (type) {
      case 'number': {
        const number = parseFloat(value)
        return isNaN(number) ? null : number
      }
      case 'bool':
        if (isBoolean(value)) return value
        else {
          const parsed = attempt(JSON.parse, value)
          if (isError(parsed)) return false
          return isBoolean(parsed) ? parsed : false
        }
      case 'node':
      case 'string': {
        const string = trim ? lodashTrim(value) : value
        return isEmpty(string) ? null : string
      }
      default:
        // Turn empty to null, which is the default value of all optional field.
        return value || null
    }
  }

  if (!has(currentSchema, fieldname)) return value
  const { isTypedArray, extractedType } = computeType(
    currentSchema[fieldname].type,
  )

  return isTypedArray
    ? filter(
        map(value, item => toTypedStoreableValue(item, extractedType)),
        item =>
          get(currentSchema[fieldname], 'allowNullElement', false) ||
          !isNil(item),
      )
    : toTypedStoreableValue(value, extractedType)
}

// Inverse of toStoreableValue. Use this function to make data to be renderable
// by input widget.
export function toEditableValue(fieldname, value, currentSchema) {
  function toTypedEditableValue(value, type) {
    switch (type) {
      case 'node':
      case 'string':
        return value ? value.toString() : ''
      case 'number':
        return isNumber(value) ? value.toString() : ''
      case 'bool':
        return value ? 'true' : 'false'
      default:
        return value || ''
    }
  }

  const { isTypedArray, extractedType } = computeType(
    currentSchema[fieldname].type,
  )
  return isTypedArray
    ? map(value, item => toTypedEditableValue(item, extractedType))
    : toTypedEditableValue(value, extractedType)
}
