import { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { get, find, map, isEmpty } from 'lodash'
import { Box } from '@changingai/react-editor-common-component'

import { cardSetSchema, CardRewardPropType } from './schema'

const Table = styled(DataTable)`
  .p-column-title {
    font-weight: bold;
  }
`

export function CardSetDiffLayout({ fieldname, current, transpiledDocs }) {
  const values = useMemo(() => {
    return get(find(transpiledDocs, { _id: current._id }), fieldname, [])
  }, [current, transpiledDocs, fieldname])
  if (isEmpty(values)) return '🈳'
  return (
    <Box height="100%">
      <Table value={values} rows={10} paginator>
        {map(cardSetSchema, ({ label }, fieldname) => (
          <Column key={fieldname} field={fieldname} header={label} />
        ))}
      </Table>
    </Box>
  )
}

CardSetDiffLayout.propTypes = {
  fieldname: PropTypes.string.isRequired,
  current: CardRewardPropType.isRequired,
  transpiledDocs: PropTypes.arrayOf(CardRewardPropType).isRequired,
}
