import { useCallback, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { isNil, get } from 'lodash'

import {
  KG_NODES,
  ProjectContext,
  TicketPropType,
  TicketState,
  useDialog,
} from '@common'
import { mergeInputFactory } from '@widget'
import { MergeDialog } from '@dialog'

import { checkPlan, useTranspile, useUploadPlanOfTicket } from './common'
import { planSchema } from './schema'
import { PlanContext, usePlanContext } from './context'
import { PlanEditDialog } from './editor'
import { rules } from './rules'
import { PlanBenefitDiffLayout, PlanFeetableDiffLayout } from './diffLayout'

import { useCompareController } from '../Compare'
import CompareView, {
  bindSchemaWithDiffLayout,
  bindSchemaWithLinkedFields,
} from '../CompareView'
import { useCheckApi } from '../Validation'

function PlanMergerDialog(props) {
  const { fieldname } = props
  const config = useMemo(() => mergeInputFactory(planSchema, fieldname), [
    fieldname,
  ])
  return <MergeDialog config={config} {...props} />
}

PlanMergerDialog.propTypes = {
  fieldname: PropTypes.string.isRequired,
}

const fullAccessStates = [TicketState.MERGE, TicketState.FINISH]

const primaryKeyRoot = KG_NODES.myfinance_editor.PLAN_PRIMARY_KEY_ROOT_ID

const defaultPrimaryKeys = {
  name: 'default-match-rule',
  keys: ['name'],
}

function useController(ticket) {
  const { email } = useContext(ProjectContext)
  const {
    docs,
    matched,
    uploaders,
    updateMatched,
    oneOneMapping,
    setOneOneMappting,
    computing,
    allPrimaryKeys,
    selectedPrimaryKeys,
    setSelectedPrimaryKeys,
  } = useCompareController(
    ticket,
    fullAccessStates,
    primaryKeyRoot,
    defaultPrimaryKeys,
    planSchema,
  )

  const [showDialog, renderEditor] = useDialog(PlanEditDialog)
  const showEditor = useCallback(
    (doc, crud) => {
      showDialog({
        doc,
        ticket,
        crud,
      })
    },
    [ticket, showDialog],
  )

  const upload = useUploadPlanOfTicket()
  const uploadDoc = useCallback(context => upload(ticket, context, email), [
    upload,
    ticket,
    email,
  ])
  const { isReady: isContextReady } = useContext(PlanContext)
  const [showMergeDialog, renderMergeDialog] = useDialog(PlanMergerDialog)
  return {
    isReady: !isNil(docs) && isContextReady,
    docs,
    matched,
    uploaders,
    updateMatched,
    uploadDoc,
    oneOneMapping,
    setOneOneMappting,
    computing,
    showEditor,
    renderEditor,
    allPrimaryKeys,
    selectedPrimaryKeys,
    setSelectedPrimaryKeys,
    showMergeDialog,
    renderMergeDialog,
  }
}

bindSchemaWithLinkedFields(planSchema, [
  ['amount_options', 'benefits', 'feetables'],
])

bindSchemaWithDiffLayout(planSchema, {
  benefits: {
    renderer: PlanBenefitDiffLayout,
  },
  feetables: {
    renderer: PlanFeetableDiffLayout,
  },
})
function PlanCompareView({ ticket }) {
  const scopeIds = useMemo(() => [get(ticket, 'scope_id')], [ticket])
  const context = usePlanContext(scopeIds)
  const checker = useCheckApi({ rules, context, externalAPI: checkPlan })

  return (
    <PlanContext.Provider value={context}>
      <CompareView
        ticket={ticket}
        useController={useController}
        useTranspile={useTranspile}
        currentSchema={planSchema}
        checker={checker}
      />
    </PlanContext.Provider>
  )
}

PlanCompareView.propTypes = {
  ticket: TicketPropType.isRequired,
}

export default PlanCompareView
