import { useState } from 'react'
import PropTypes from 'prop-types'
import { Sidebar } from 'primereact/sidebar'
import { Button } from 'primereact/button'
import { FaInfoCircle } from 'react-icons/fa'
import { noop } from 'lodash'
import { Flex, StyledOverlay } from '@changingai/react-editor-common-component'

import theme from '@theme'

export function ConfirmSidebar({
  onYES,
  onNO,
  visible,
  setVisible,
  content,
  action,
  userData,
  showWaitingAnimation,
}) {
  const [waiting, setWaiting] = useState(false)
  return (
    <Sidebar
      visible={visible}
      position="top"
      baseZIndex={theme.zIndices.l9}
      onHide={() => {
        onNO()
        setVisible(false)
      }}
    >
      <StyledOverlay
        active={waiting && showWaitingAnimation}
        size="100px"
        height="auto"
        text="Uploading tickets"
      />
      <Flex flexWrap="wrap" alignItems="space-between" height="100%">
        <Flex alignItems="center" flex="0 0 50%" fontSize="h1" m="2">
          <FaInfoCircle
            style={{
              color: 'DarkGreen',
              width: '28px',
              height: '28px',
              marginRight: '10px',
            }}
          />
          {content}
        </Flex>
        <Flex flex="1 0 100%">
          <Button
            disabled={waiting}
            type="button"
            label="Yes"
            className="p-button-success"
            style={{ marginRight: '.25em' }}
            onClick={async () => {
              setWaiting(true)
              try {
                await onYES(action, userData)
              } finally {
                setWaiting(false)
                setVisible(false)
              }
            }}
          />
          <Button
            disabled={waiting}
            type="button"
            onClick={() => {
              onNO()
              setVisible(false)
            }}
            label="No"
            className="p-button-secondary"
          />
        </Flex>
      </Flex>
    </Sidebar>
  )
}

ConfirmSidebar.propTypes = {
  content: PropTypes.string,
  action: PropTypes.string,
  userData: PropTypes.any,
  onYES: PropTypes.func.isRequired,
  onNO: PropTypes.func,
  setVisible: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  showWaitingAnimation: PropTypes.bool,
}

ConfirmSidebar.defaultProps = {
  onNO: noop,
  showWaitingAnimation: false,
}
