import { namespace, backendDocType, schema, docPrivilege } from './config'
import loader, { queryManifest } from './loader'
import listview, { useTranspile } from './listview'
import { addViewManifest } from '../Config'

const manifest = {
  loader,
  schema,
  namespace,
  backendDocType,
  privilege: docPrivilege,
  queryManifest,
  useTranspile,
  listview,
  order: 0,
}

addViewManifest(manifest)
