import { useMemo } from 'react'
import isNil from 'lodash/isNil'

import { PropCrud } from '@common'
import { inputFactory } from '@widget'

import { Spotlights } from './spotlights'
import { cardSchema, CardPropType } from './schema'
import { rules } from './rules'

import { SchemaBaseEditor } from '../SchemaBaseEditor'
import { useCheckApi } from '../Validation'
import { useFetchFullDoc } from '../DocHelper'

import { _queryCard } from './editor.gql'

export function CardEditDialog(props) {
  const { editContext, setEditContext } = useFetchFullDoc(
    props.doc,
    cardSchema,
    _queryCard,
  )
  const checker = useCheckApi({
    rules,
  })

  const tabs = useMemo(
    () => [
      {
        caption: 'Primary',
        configs: [
          inputFactory(cardSchema, 'enabled'),
          inputFactory(cardSchema, 'card_group_id'),
          inputFactory(cardSchema, 'issuer_id'),
          inputFactory(cardSchema, 'card_level_id'),
          inputFactory(cardSchema, 'launch_date'),
          inputFactory(cardSchema, 'effective_date_b'),
          inputFactory(cardSchema, 'effective_date_e'),
          inputFactory(cardSchema, 'img_urls', { disableResize: true }),
        ],
      },
      {
        caption: 'Selection',
        configs: [
          inputFactory(cardSchema, 'ratable_filter'),
          inputFactory(cardSchema, 'scope_filter'),
          inputFactory(cardSchema, 'function_ids'),
        ],
      },
      {
        caption: '年費',
        configs: [
          inputFactory(cardSchema, 'fee_1st_l', {
            inputAttributes: {
              width: '50%',
            },
          }),
          inputFactory(cardSchema, 'fee_1st_h', {
            inputAttributes: {
              width: '50%',
            },
          }),
          inputFactory(cardSchema, 'fee_1st_note', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(cardSchema, 'fee_2nd_l', {
            inputAttributes: {
              width: '50%',
            },
          }),
          inputFactory(cardSchema, 'fee_2nd_h', {
            inputAttributes: {
              width: '50%',
            },
          }),
          inputFactory(cardSchema, 'fee_2nd_note', {
            inputAttributes: {
              multilines: true,
            },
          }),
        ],
      },
      {
        caption: 'Detailed',
        configs: [
          inputFactory(cardSchema, 'aliases'),
          inputFactory(cardSchema, 'apply_url'),
          inputFactory(cardSchema, 'apply_condition', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(cardSchema, 'apply_doc', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(cardSchema, 'income_threshold'),
          inputFactory(cardSchema, 'apply_process', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(cardSchema, 'suspend_process', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(cardSchema, 'rir'),
          inputFactory(cardSchema, 'rir_date_b', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(cardSchema, 'overdue_fine', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(cardSchema, 'cash_adv_fee', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(cardSchema, 'foreign_trans_fee', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(cardSchema, 'report_loss_fee', {
            inputAttributes: {
              multilines: true,
              width: '100%',
            },
          }),
          inputFactory(cardSchema, 'report_loss_charge', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(cardSchema, 'review_slip_fee', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(cardSchema, 'rebill_fee', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(cardSchema, 'zero_interest_installments', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(cardSchema, 'min_installment_money', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(cardSchema, 'cash_adv_interest', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(cardSchema, 'cash_adv_prepayment_penalty', {
            multilines: true,
          }),
          inputFactory(cardSchema, 'birth_present', {
            inputAttributes: {
              multilines: true,
            },
          }),
          inputFactory(cardSchema, 'birth_present_detail', {
            inputAttributes: {
              multilines: true,
            },
          }),
        ],
      },
      {
        caption: '亮點',
        configs: [
          {
            fieldname: 'attributes',
            widget: 'CustomizedInput',
            RenderComp: Spotlights,
          },
        ],
      },
    ],
    [],
  )

  return (
    <SchemaBaseEditor
      {...props}
      currentSchema={cardSchema}
      tabs={tabs}
      checker={checker}
      isReady={!isNil(editContext)}
      editContext={editContext}
      setEditContext={setEditContext}
    />
  )
}

CardEditDialog.propTypes = {
  doc: CardPropType.isRequired,
  crud: PropCrud.isRequired,
}
