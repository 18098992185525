import { useState } from 'react'
import PropTypes from 'prop-types'
import Resizer from 'react-image-file-resizer'
import uuid from 'react-uuid'
import isEmpty from 'lodash/isEmpty'
import { toast } from 'react-toastify'
import { MdDeleteForever } from 'react-icons/md'

import { Flex, Box } from '@changingai/react-editor-common-component'
import { readImageSize } from '@common'

import { ToastifyLabel } from './ToastifyLabel'
import { ImportButton } from './ImportButton'
import { useInit, useError, useUploadFile } from './common'
import { ButtonLike } from './button'

export function ImageUploader({
  fieldname,
  value,
  onChange,
  onDirty,
  label,
  onValid,
  editable,
  disableResize = false,
}) {
  const [id] = useState(uuid())
  const [, compare] = useInit(value)
  const [urls, setUrls] = useState(value)

  const error = useError(onValid, id, fieldname, value, editable)
  function resize(file, [width, height]) {
    return new Promise(resolve =>
      Resizer.imageFileResizer(
        file,
        width,
        height,
        file.type === 'image/jpeg' ? 'JPEG' : 'PNG',
        100,
        0,
        blob => {
          resolve(blob)
        },
        'blob',
      ),
    )
  }

  const aspecRatioLimit = 3
  const uploadBlob = useUploadFile()

  return (
    <Flex width="100%" alignItems="center" flexWrap="wrap" my="2" p="2">
      <Flex width="100%" alignItems="center">
        <ToastifyLabel
          label={label}
          fieldname={fieldname}
          changed={compare(urls)}
          error={error}
        />
        <ImportButton
          accept="image/png,image/jpeg"
          onImport={async ({ target: { files } }) => {
            const file = files[0]
            const [width, height] = await readImageSize(file)

            if (height / width > aspecRatioLimit) {
              toast.error(`長寬比限制${aspecRatioLimit}(h/w)`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
              })
              return
            }
            const blobs = [file]
            if (!disableResize && width > 720)
              blobs.unshift(await resize(file, [720, (720 * height) / width]))

            try {
              const urls = await Promise.all(
                blobs.map(blob => uploadBlob(id, blob, file.type)),
              )
              setUrls(urls)

              onChange(fieldname, urls)
              onDirty(id, compare(urls))
            } catch (e) {
              toast.error(`Import failed: ${e}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
              })
            }
          }}
        />
        <ButtonLike
          disabled={isEmpty(urls)}
          size={6}
          scaleOnHover
          onClick={() => {
            setUrls([])
            onChange(fieldname, [])
            onDirty(id, compare([]))
          }}
        >
          <MdDeleteForever
            style={{
              color: 'red',
            }}
          />
        </ButtonLike>
        {!disableResize && <Box>{`長寬比限制${aspecRatioLimit}(h/w)`}</Box>}
      </Flex>
      {!isEmpty(urls) && (
        <Flex
          width="100%"
          justifyContent="center"
          border="1px solid black"
          backgroundImage="linear-gradient(45deg,#efefef 25%,rgba(239,239,239,0) 25%,rgba(239,239,239,0) 75%,#efefef 75%,#efefef),linear-gradient(45deg,#efefef 25%,rgba(239,239,239,0) 25%,rgba(239,239,239,0) 75%,#efefef 75%,#efefef)"
          backgroundColor="#fff"
          backgroundPosition="0 0,10px 10px"
          backgroundSize="21px 21px"
        >
          <Flex width="720px" justifyContent="center" border="1px dashed blue">
            <img
              src={urls[0]}
              alt="not found"
              style={{
                border: '1px dashed grey',
              }}
            />
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

ImageUploader.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  fieldname: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDirty: PropTypes.func.isRequired,
  onValid: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
  disableResize: PropTypes.bool,
}
