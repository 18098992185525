import { useCallback, useContext } from 'react'
import {
  find,
  forEach,
  isNil,
  castArray,
  partial,
  stubTrue,
  omit,
  map,
} from 'lodash'
import uuid from 'react-uuid'
import { Flex } from '@changingai/react-editor-common-component'

import {
  ProjectContext,
  isLocalDoc,
  TicketPropType,
  TicketState,
  useDialog,
} from '@common'
import { createBlankDoc, purifySchemaDoc } from '@schema'
import { cceClient } from '@gql'

import { useTranspile, useUploadBenefitOfTicket } from './common'
import { BenefitContext, useBenefitContext } from './context'
import { docPrivilege } from './config'
import { BenefitEditDialog } from './editor'
import { benefitSchema } from './schema'
import TicketSummaryModel from '../TicketSummaryModel'
import { useCacheDocs } from '../Common'
import { cloneTicketDoc } from '../DocHelper'
import { useReferenceBase } from '../Reference'
import { useFetchDocOfTicket, createBlankTicketable } from '../TicketHelper'
import { useDeleteDocWithHistory } from '../Uploader'
import { bindSchemaWithSummaryLayout } from '../SummaryFields'
import { _deleteBenefit, _queryBenefit } from './listview.gql'

bindSchemaWithSummaryLayout(benefitSchema, {
  pay_method: {
    cols: 2,
  },
  clause_main: {
    cols: 2,
  },
})

const useReference = partial(useReferenceBase, 'benefit', stubTrue)

const fullAccessStates = [TicketState.MERGE, TicketState.FINISH]
function useController(ticket, selectedFields) {
  const benefits = useFetchDocOfTicket(ticket, benefitSchema, selectedFields)
  const { email, peepMode } = useContext(ProjectContext)
  const { updated, deleteCache, updateCache, docs } = useCacheDocs(
    email,
    peepMode,
    ticket,
    benefits,
    fullAccessStates,
  )

  const createDoc = useCallback(
    uploader => {
      const benefit = {
        ...createBlankDoc(benefitSchema, true),
        ...createBlankTicketable(ticket, uploader),
      }
      updateCache(benefit)

      return benefit
    },
    [updateCache, ticket],
  )

  const deleteBenefit = useDeleteDocWithHistory(benefitSchema, ids => {
    cceClient.mutate({
      mutation: _deleteBenefit,
      variables: {
        ids: castArray(ids),
      },
    })
  })
  const deleteDoc = useCallback(
    _id => {
      deleteCache(_id)
      const deleted = find(docs, { _id })
      if (isNil(deleted) || isLocalDoc(deleted)) return
      return deleteBenefit(deleted)
    },
    [deleteCache, docs, deleteBenefit],
  )

  const cloneDoc = useCallback(
    async doc => {
      const cloned = await cloneTicketDoc(
        _queryBenefit,
        doc,
        ticket,
        email,
        benefitSchema,
      )
      updateCache(cloned)
    },
    [updateCache, email, ticket],
  )

  const [showDialog, renderDialog] = useDialog(BenefitEditDialog)
  const showDoc = useCallback(
    (doc, crud) => {
      showDialog({
        doc,
        ticket,
        crud,
      })
    },
    [ticket, showDialog],
  )

  const upload = useUploadBenefitOfTicket()
  const uploadDoc = useCallback(context => upload(ticket, context, email), [
    upload,
    ticket,
    email,
  ])

  const importDocs = useCallback(
    (docs, uploader) => {
      forEach(
        map(docs, doc =>
          purifySchemaDoc(
            omit(
              {
                ...doc,
                ...createBlankTicketable(ticket, uploader),
                _id: uuid(),
              },
              ['matched_state', 'matched_target'],
            ),
            benefitSchema,
          ),
        ),
        doc =>
          updateCache({
            ...doc,
            local_doc: true,
          }),
      )
    },
    [updateCache, ticket],
  )

  return {
    isReady: !isNil(benefits),
    docs,
    createDoc,
    updateDoc: updateCache,
    deleteDoc,
    cloneDoc,
    showDoc,
    renderDoc: renderDialog,
    updated,
    uploadDoc,
    useReference,
    importDocs,
  }
}

const defaultSummary = {
  selected: ['ticket_id', 'benefit_name'],
  filters: {},
}

const BenefitListView = props => {
  const context = useBenefitContext(props.ticket)

  if (!context.isReady) return null
  return (
    <BenefitContext.Provider value={context}>
      <TicketSummaryModel
        {...props}
        useController={useController}
        defaultSummary={defaultSummary}
        useTranspile={useTranspile}
        HeaderRightPanel={() => {
          return (
            <Flex
              width="100%"
              height="100%"
              px="2"
              justifyContent="flex-end"
              alignItems="center"
            >
              <a
                href={context.contract.file_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                合約連結
              </a>
            </Flex>
          )
        }}
        requiredPrivilege={[docPrivilege]}
        currentSchema={benefitSchema}
      />
    </BenefitContext.Provider>
  )
}

BenefitListView.propTypes = {
  ticket: TicketPropType.isRequired,
}

export default BenefitListView
