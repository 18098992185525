import { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import uuid from 'react-uuid'
import { castArray, find, isEmpty, isNil, map, partial } from 'lodash'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { TabView, TabPanel } from 'primereact/tabview'

import { Flex } from '@changingai/react-editor-common-component'
import {
  ProjectContext,
  TicketPropType,
  TicketState,
  isLocalDoc,
  useDialog,
} from '@common'
import { purifySchemaDoc, createBlankDoc } from '@schema'
import { cceClient } from '@gql'

import { docPrivilege } from './config'
import { ShopRewardEditDialog } from './editor'
import { TablePropType, shopRewardSchema } from './schema'

import TicketSummaryModel from '../TicketSummaryModel'
import { bindSchemaWithSummaryLayout, SummaryLabel } from '../SummaryFields'
import { useFetchDocOfTicket, createBlankTicketable } from '../TicketHelper'
import { useCacheDocs, useKGTranspile } from '../Common'
import { cloneTicketDoc } from '../DocHelper'
import { useUploadDocWithHistory, useDeleteDocWithHistory } from '../Uploader'

import {
  _uploadRewardShops,
  _removeRewardShops,
  _queryRewardShop,
} from './listview.gql'

const Table = styled(DataTable)`
  && table thead tr {
    display: ${({ hideHeader }) => (hideHeader ? 'none' : true)};
  }
  && table thead tr th {
    background: #f4f4f4;
    text-align: center;
  }
`

function TableField({ value }) {
  return (
    <Flex flexDirection="column">
      <Flex alignItems="center">
        <SummaryLabel
          fieldname={shopRewardSchema.tables.label}
          isEmptyField={isEmpty(value)}
        />
      </Flex>
      <TabView>
        {map(value, ({ title, header, values, hide_header }, idx) => (
          <TabPanel header={title ? title : `Table ${idx}`} key={idx}>
            <Table
              hideHeader={hide_header}
              value={values}
              scrollHeight="200px"
              scrollable
            >
              {header.map((column, idx) => (
                <Column key={idx} field={idx.toString()} header={column} />
              ))}
            </Table>
          </TabPanel>
        ))}
      </TabView>
    </Flex>
  )
}

TableField.propTypes = {
  value: PropTypes.arrayOf(TablePropType).isRequired,
}

bindSchemaWithSummaryLayout(shopRewardSchema, {
  tables: {
    renderer: TableField,
  },
})

export const useTranspile = partial(useKGTranspile, shopRewardSchema)

const fullAccessStates = [TicketState.MERGE, TicketState.FINISH]
function useController(ticket, selectedFields) {
  const rewards = useFetchDocOfTicket(ticket, shopRewardSchema, selectedFields)
  const { email, peepMode } = useContext(ProjectContext)
  const { updated, deleteCache, updateCache, docs } = useCacheDocs(
    email,
    peepMode,
    ticket,
    rewards,
    fullAccessStates,
  )

  const createDoc = useCallback(
    uploader => {
      let reward = {
        ...createBlankDoc(shopRewardSchema, true),
        brand_ids: [ticket.scope_id],
        effective_date_b: new Date(new Date().getFullYear(), 0, 1),
        effective_date_e: new Date(new Date().getFullYear(), 11, 31),
        ...createBlankTicketable(ticket, uploader),
      }

      updateCache(reward)
      return reward
    },
    [ticket, updateCache],
  )

  const importDocs = useCallback(
    (docs, uploader) => {
      docs
        .map(doc => {
          const result = {
            ...doc,
            ...createBlankTicketable(ticket, uploader),
            _id: uuid(),
          }
          delete result.matched_target
          delete result.matched_state
          return result
        })
        // TBD: [MYCC-2008]
        // instead of purify it, we should break this import and tell
        // the one who import this file what's wrong.
        .map(doc => purifySchemaDoc(doc, shopRewardSchema))
        .forEach(doc =>
          updateCache({
            ...doc,
            local_doc: true,
          }),
        )
    },
    [updateCache, ticket],
  )

  const deleteReward = useDeleteDocWithHistory(shopRewardSchema, ids => {
    cceClient.mutate({
      mutation: _removeRewardShops,
      variables: {
        ids: castArray(ids),
      },
    })
  })

  const deleteDoc = useCallback(
    _id => {
      deleteCache(_id)
      const deleted = find(docs, { _id })
      if (isNil(deleted) || isLocalDoc(deleted)) return
      return deleteReward(deleted)
    },
    [deleteCache, docs, deleteReward],
  )

  const cloneDoc = useCallback(
    async doc => {
      const cloned = await cloneTicketDoc(
        _queryRewardShop,
        doc,
        ticket,
        email,
        shopRewardSchema,
      )

      updateCache(cloned)
    },
    [updateCache, email, ticket],
  )

  const [showDialog, renderDialog] = useDialog(ShopRewardEditDialog)
  const showDoc = useCallback(
    (doc, crud) => {
      showDialog({
        doc,
        ticket,
        crud,
      })
    },
    [ticket, showDialog],
  )

  const upload = useUploadDocWithHistory(shopRewardSchema, async input => {
    const {
      data: { uploadRewardShops },
    } = await cceClient.mutate({
      mutation: _uploadRewardShops,
      variables: {
        input,
      },
    })

    return uploadRewardShops
  })

  const uploadDoc = useCallback(
    context => upload(ticket, context, email, true),
    [upload, ticket, email],
  )

  return {
    isReady: !isNil(rewards),
    docs,
    createDoc,
    updateDoc: updateCache,
    deleteDoc,
    importDocs,
    cloneDoc,
    showDoc,
    renderDoc: renderDialog,
    updated,
    uploadDoc,
  }
}

const defaultSummary = {
  selected: ['title', 'img_urls', 'subtitle', 'link_building_urls'],
  filters: {},
}

const ShopRewardListView = props => {
  return (
    <TicketSummaryModel
      {...props}
      useController={useController}
      useTranspile={useTranspile}
      defaultSummary={defaultSummary}
      requiredPrivilege={[docPrivilege]}
      currentSchema={shopRewardSchema}
    />
  )
}

ShopRewardListView.propTypes = {
  ticket: TicketPropType.isRequired,
}

export default ShopRewardListView
