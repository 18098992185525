import { useMemo } from 'react'
import Joi from 'joi'
import { useQuery } from '@apollo/client'
import {
  compact,
  flatMap,
  uniq,
  isEmpty,
  get,
  find,
  head,
  map,
  toNumber,
  isNaN,
  isNil,
  isUndefined,
} from 'lodash'
import uuid from 'react-uuid'
import remark from 'remark'
import visit from 'unist-util-visit'

import { KG_NODES, useQueryKG, useGetConnectedNodes, namespaces } from '@common'
import {
  NullableString,
  RequiredString,
  SchemaEmptyNonNegative,
  SchemaEmptyPositive,
  UniqueStringArray,
  addSchema,
  createBlankDoc,
  RequiredKGNodeIdString,
  matchStateSchema,
  RequiredMongoIdString,
  orderedArray,
  schemaToPropTypes,
  shortString,
  longString,
  ticketDocSchema,
  jsonString,
} from '@schema'
import { cceClient } from '@gql'

import { _queryBenefit, _query_kg } from './schema.gql'

export function useReqestVariableNode(request_type, fieldname, filterByType) {
  const { data } = useQueryKG(_query_kg, {
    variables: {
      query: {
        op: 'eq',
        field: 'node_id',
        value: request_type,
      },
    },
    skip: isEmpty(request_type),
  })

  const result = useMemo(() => {
    if (!data) return null
    const node = head(data.query_kg)
    if (filterByType) {
      if (
        get(find(node.attributes, { name: 'type' }), 'values.0') !== fieldname
      ) {
        return []
      }
    }
    return get(find(node.attributes, { name: fieldname }), 'values', [])
  }, [data, fieldname, filterByType])

  return result
}

export const AmountOptionType = Object.freeze({
  main: {
    name: 'range',
    label: '範圍',
  },
  sub: {
    name: 'list',
    label: '列表',
  },
})

const optionValueSchema = {
  value_type: {
    schema: Joi.string()
      .valid('id', 'const', 'empty')
      .required(),
    defaultValue: 'empty',
    type: 'string',
    label: '保額型態',
  },
  option_id: {
    schema: Joi.string(),
    type: 'string',
    label: '給付金額',
  },
  plan_ratio: {
    schema: SchemaEmptyPositive,
    type: 'number',
    label: '倍率',
  },
  constant: {
    schema: SchemaEmptyPositive,
    type: 'number',
    label: '常數保額',
  },
}

export const amountOptionProtocol = 'option:'

function extractOptionIds() {
  return function transformer(tree, file) {
    visit(tree, node => {
      if (isNil(file.data.options)) file.data.options = []
      if (node.type === 'link') {
        const { protocol, pathname: option_id, searchParams } = new URL(
          get(node, 'url'),
        )
        if (protocol === amountOptionProtocol) {
          file.data.options.push({
            searchParams,
            option_id,
          })
        }
      }
    })
  }
}

export function computeAmount(amountOption, multiplier) {
  const minAmount = toNumber(
    get(amountOption, `${get(amountOption, 'type')}.0`),
  )
  return {
    minAmount,
    multiplier: isUndefined(multiplier) ? NaN : multiplier,
    amount: minAmount * toNumber(multiplier),
  }
}

export function isValidAmount(amount, multiplier) {
  return !isNaN(amount) && amount >= 0 && toNumber(multiplier) > 0
}

export const benefitsRefSchema = {
  benefit_id: {
    schema: RequiredMongoIdString,
    type: 'string',
    label: 'benefit代碼',
  },
  display_name: {
    schema: Joi.string(),
    type: 'string',
    label: '顯示名稱',
  },
  payback_text: {
    schema: Joi.string(),
    type: 'string',
    label: '給付內容(說明)',
  },
  payback_sample: {
    schema: Joi.string()
      .allow('')
      .allow(null)
      .custom((value, helpers) => {
        const amountOptions = get(helpers, 'prefs.context.amountOptions', [])
        const {
          data: { options },
        } = remark()
          .use(extractOptionIds)
          .processSync(value)
        const errors = compact(
          map(options, ({ option_id, searchParams }) => {
            const amountOption = find(amountOptions, { option_id })
            if (isNil(amountOption)) return `${option_id} not found`

            const { amount } = computeAmount(
              amountOption,
              searchParams.get('multiplier'),
            )
            if (!isValidAmount(amount, searchParams.get('multiplier')))
              return `${amount} is invalid`
          }),
        )
        return isEmpty(errors) ? value : helpers.message(errors.join('\n'))
      }),
    type: 'string',
    label: '給付內容(理賠範例)',
  },
  payback_note: {
    schema: Joi.string(),
    type: 'string',
    label: '給付備註',
  },
  request_unit: {
    label: '申請單位',
    schema: NullableString.max(shortString),
    type: 'string',
  },
  ...optionValueSchema,
}

export const amountOptionSchema = {
  name: {
    schema: RequiredString.max(shortString),
    type: 'string',
    label: '名稱',
  },
  type: {
    schema: Joi.string()
      .valid(...map(Object.values(AmountOptionType), 'name'))
      .required(),
    type: 'string',
    label: '型態',
    useNodes: () => {
      const types = useMemo(
        () =>
          Object.values(AmountOptionType).map(({ name, label }) => ({
            name: label,
            id: name,
          })),
        [],
      )

      return types
    },
  },
  range: {
    schema: Joi.array()
      .items(Joi.number())
      .max(3)
      .required(),
    type: 'arrayOf(number)',
    label: '範圍',
    useNodes: () => {
      const result = useMemo(() => {
        const values = [
          ['百萬', '1000000'],
          ['十萬', '100000'],
          ['一萬', '10000'],
        ]
        return map(values, ([label, value]) => ({ label, value }))
      }, [])

      return result
    },
    multiInput: true,
  },
  default: {
    schema: Joi.string().allow(null, ''),
    type: 'string',
    label: '保額預設值',
  },
  private_code: {
    schema: Joi.string().allow(null, ''),
    type: 'string',
    label: '系統代碼[勿亂填]',
  },
  list: {
    schema: UniqueStringArray.required(),
    type: 'arrayOf(string)',
    label: '列表',
  },
  unit: {
    schema: RequiredKGNodeIdString,
    referToKG: 'insurance',
    type: 'node',
    label: '單位',
    useNodes: () => {
      const roots = useMemo(
        () => [
          {
            node_id: KG_NODES.insurance.AMOUNT_OPTION_UNIT_ROOT_ID,
            namespace: 'insurance',
          },
        ],
        [],
      )
      const nodes = useGetConnectedNodes(roots, true)
      return nodes
    },
  },
  option_id: {
    schema: RequiredString.max(shortString),
    type: 'string',
    label: '選項代碼',
    skipCompare: true,
    defaultValue: () => uuid(),
  },
}

export const requestVariableSchema = {
  request_type: {
    schema: RequiredKGNodeIdString,
    referToKG: 'insurance',
    type: 'node',
    label: '依據條件',
    useNodes: () => {
      const roots = useMemo(
        () => [
          {
            node_id: KG_NODES.insurance.REQUEST_VARIABLE_ROOT_ID,
            namespace: 'insurance',
          },
        ],
        [],
      )
      const nodes = useGetConnectedNodes(roots, true)
      return nodes
    },
  },
  range: {
    schema: Joi.array()
      .items(Joi.number().min(0))
      .custom(orderedArray())
      .min(2)
      .max(3)
      .required(),
    type: 'arrayOf(number)',
    label: '範圍(最小值/最大值/間距)',
    useNodes: ({ request_type }) => {
      const values = useReqestVariableNode(request_type, 'range', true)
      const result = useMemo(
        () =>
          map(values, value => ({
            label: value.toString(),
            value: value.toString(),
          })),
        [values],
      )

      return result
    },
    multiInput: true,
  },
  list: {
    schema: UniqueStringArray.min(1).required(),
    type: 'arrayOf(string)',
    label: '列表',
    useNodes: ({ request_type }) => {
      const values = useReqestVariableNode(request_type, 'list', true)
      const result = useMemo(
        () =>
          map(values, node => ({
            name: node,
            id: node,
          })),
        [values],
      )
      return result
    },
  },
  variable_id: {
    schema: RequiredString.max(shortString),
    type: 'string',
    label: '變數代碼',
    skipCompare: true,
    defaultValue: () => uuid(),
  },
}

export const requestVariablesSchema = {
  request_variable: {
    schema: Joi.array().required(),
    type: 'arrayOf(requestVariableSchema)',
    label: '需求變數',
    defaultValue: map(
      [
        KG_NODES.insurance.REQUEST_VARIABLE.INSURE_DURATION,
        KG_NODES.insurance.REQUEST_VARIABLE.AGE,
        KG_NODES.insurance.REQUEST_VARIABLE.PAYMENT_YEARS,
      ],
      request_type => ({
        ...createBlankDoc(requestVariableSchema, false),
        request_type,
      }),
    ),
  },
}

export const axisSchema = {
  axis_id: {
    schema: RequiredString.max(shortString),
    referToKG: 'insurance',
    type: 'string',
    label: '維度代碼',
  },
  axis_value: {
    schema: Joi.string(),
    type: 'string',
    label: '維度值',
  },
  ...optionValueSchema,
}

export const feeTableRefSchema = {
  feetable_id: {
    schema: RequiredMongoIdString,
    type: 'string',
    label: '費率表代碼',
  },
  axes: {
    schema: Joi.array().required(),
    type: 'arrayOf(axisSchema)',
    label: '維度',
  },
}

export const actuarialSampleSchema = {
  issue_age: {
    schema: Joi.number()
      .integer()
      .min(0)
      .max(111)
      .required(),
    type: 'number',
    hint: '0 ~ 111',
    label: '取樣年齡',
  },
  sex: {
    schema: Joi.string()
      .valid('男', '女')
      .required(),
    type: 'string',
    hint: '男/女',
    label: '取樣性別',
  },
  insured_amount: {
    schema: Joi.number()
      .integer()
      .min(1)
      .required(),
    type: 'number',
    hint: '整數/大於等於1',
    label: '投保金額',
  },
  annual_premium: {
    schema: Joi.number()
      .min(0)
      .required(),
    type: 'number',
    hint: '大於等於0',
    label: '年繳保費(原價)',
  },
  declared_interest: {
    schema: Joi.number()
      .min(0.0075)
      .max(0.05)
      .required(),
    type: 'number',
    hint: '0.0075 ~ 0.05',
    label: '試算時宣告利率',
  },
  cash_flow: {
    schema: Joi.array()
      .items(
        Joi.array()
          .items(Joi.string())
          .length(8),
      )
      .length(111)
      .allow(null),
    type: 'arrayOf(string)',
    skipCompare: true,
    allowNullElement: true,
    label: '試算現金流',
  },
  note: {
    schema: Joi.string().allow(null, ''),
    type: 'string',
    label: '精算取樣註記',
    hint: 'Empty/string',
  },
}

export const planSchema = {
  ...ticketDocSchema,
  ...matchStateSchema,
  name: {
    schema: RequiredString.max(shortString),
    type: 'string',
    label: '計劃名稱',
  },
  is_additional: {
    schema: Joi.bool(),
    type: 'bool',
    label: '是否為加購方案',
  },
  publish_precalculation: {
    schema: Joi.bool(),
    type: 'bool',
    label: '是否上架試算模型',
  },
  benefits: {
    schema: Joi.array().required(),
    type: 'arrayOf(benefitsRefSchema)',
    label: '連結給付項目',
    useNodes: docs => {
      const doc_ids = useMemo(
        () => uniq(map(compact(flatMap(docs, 'benefits')), 'benefit_id')),
        [docs],
      )

      const { data } = useQuery(_queryBenefit, {
        client: cceClient,
        variables: {
          doc_ids,
        },
        skip: isEmpty(doc_ids),
      })

      const benefitNames = useMemo(() => {
        if (isEmpty(doc_ids)) return []
        return !data
          ? null
          : data.queryBenefit.map(({ _id: id, benefit_name: name }) => ({
              id,
              name,
            }))
      }, [data, doc_ids])

      return benefitNames
    },
  },
  feetables: {
    schema: Joi.array().required(),
    type: 'arrayOf(feeTableRefSchema)',
    label: '連結費率表',
  },
  code: {
    schema: NullableString.max(shortString),
    type: 'string',
    label: '代碼[覆寫原產品代碼]',
  },
  unconfirmed_request_variables: {
    schema: Joi.bool(),
    type: 'bool',
    label: '有任一需求變數無法確認',
  },
  request_variables: {
    schema: Joi.array().required(),
    type: 'arrayOf(requestVariablesSchema)',
    label: '需求變數',
  },
  unconfirmed_amount_options: {
    schema: Joi.bool(),
    type: 'bool',
    label: '有任一保額選項無法確認',
  },
  amount_options: {
    schema: Joi.array().required(),
    type: 'arrayOf(amountOptionSchema)',
    label: '保額選項',
  },
  amount_option_rule: {
    schema: Joi.string().allow(null, ''),
    type: 'string',
    label: '保額規則設定[勿亂填]',
    hint: 'Empty/string',
  },
  benefit_note: {
    schema: NullableString.max(longString),
    type: 'string',
    label: '給付備註',
  },
  expert_intro: {
    schema: NullableString.max(longString),
    type: 'string',
    label: '專家說明',
  },
  highlight: {
    schema: NullableString.max(longString),
    type: 'string',
    label: '精選說明',
  },
  to_prod: {
    schema: Joi.bool(),
    type: 'bool',
    label: '確認可以release to production',
  },
  spouse_age_bound: {
    schema: Joi.array()
      .custom(orderedArray())
      .items(SchemaEmptyNonNegative)
      .max(3),
    type: 'arrayOf(number)',
    label: '配偶可投保年齡(下限/上限/續保年齡)',
  },
  children_age_bound: {
    schema: Joi.array()
      .custom(orderedArray())
      .items(SchemaEmptyNonNegative)
      .max(3),
    type: 'arrayOf(number)',
    label: '子女可投保年齡(下限/上限/續保年齡)',
  },
  parent_age_bound: {
    schema: Joi.array()
      .custom(orderedArray())
      .items(SchemaEmptyNonNegative)
      .max(3),
    type: 'arrayOf(number)',
    label: '父母可投保年齡(下限/上限/續保年齡)',
  },
  // === actuarial config ===
  payment_period: {
    schema: Joi.number()
      .integer()
      .min(1)
      .allow(null, ''),
    type: 'number',
    label: '繳費年期',
    hint: 'Empty/整數',
  },
  assumed_interest: {
    schema: Joi.number()
      .min(0.0025)
      .max(0.05)
      .allow(null, ''),
    type: 'number',
    label: '保單預定利率',
    hint: 'Empty/0.0025 ~ 0.05',
  },
  tso_version: {
    schema: Joi.string().allow(null, ''),
    type: 'string',
    label: '生命表版本',
    hint: 'Empty/TSO[5-9]',
  },
  tso_discount: {
    schema: Joi.number()
      .min(0.5)
      .max(1.5)
      .allow(null, ''),
    type: 'number',
    label: '生命表修正倍率',
    hint: 'Empty/0.5 ~ 1.5',
  },
  surrender_rates: {
    schema: Joi.array()
      .items(Joi.string().allow(null))
      .length(20)
      .allow(null),
    type: 'arrayOf(string)',
    allowNullElement: true,
    label: '解約金比例',
  },
  actuarial_model_para: {
    schema: Joi.string()
      .custom(jsonString)
      .allow(null, ''),
    type: 'string',
    label: '精算模型參數',
    hint: 'Empty/JSON string',
  },
  actuarial_config_note: {
    schema: Joi.string().allow(null, ''),
    type: 'string',
    label: '精算組態註記',
    hint: 'Empty/string',
  },
  // === end ===
  actuarial_samples: {
    schema: Joi.array(),
    type: 'arrayOf(actuarialSampleSchema)',
    label: '精算取樣例',
  },
}

export const numberCellSchema = Joi.string()
  .pattern(/^([0-9]+\.)?[0-9]+$/)
  .allow(null)

addSchema({ amountOptionSchema })
addSchema({ benefitsRefSchema })
addSchema({ requestVariableSchema })
addSchema({ requestVariablesSchema })
addSchema({ feeTableRefSchema })
addSchema({ axisSchema })
addSchema({ actuarialSampleSchema })
addSchema({ planSchema }, '計畫', namespaces.insurance.value)

export const PlanPropType = schemaToPropTypes(planSchema)
