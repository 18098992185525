import { useCallback, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { isNil } from 'lodash'

import {
  KG_NODES,
  ProjectContext,
  TicketPropType,
  TicketState,
  useDialog,
} from '@common'
import { MergeDialog } from '@dialog'
import { mergeInputFactory } from '@widget'

import { checkProduct, useTranspile, useUploadProductOfTicket } from './common'
import { productSchema } from './schema'
import { ProductContext, useProductContext } from './context'
import { ProductEditDialog } from './editor'

import { useCompareController } from '../Compare'
import CompareView from '../CompareView'
import { useCheckApi } from '../Validation'

const fullAccessStates = [
  TicketState.CREATE,
  TicketState.MERGE,
  TicketState.FINISH,
]

const primaryKeyRoot = KG_NODES.myfinance_editor.PRODUCT_PRIMARY_KEY_ROOT_ID

const defaultPrimaryKeys = {
  name: 'default-match-rule',
  keys: ['title', 'contract_ids', 'code'],
}

function ProductMergerDialog(props) {
  const { fieldname } = props
  const config = useMemo(() => mergeInputFactory(productSchema, fieldname), [
    fieldname,
  ])
  return <MergeDialog config={config} {...props} />
}

ProductMergerDialog.propTypes = {
  fieldname: PropTypes.string.isRequired,
}

function useController(ticket) {
  const { email } = useContext(ProjectContext)
  const {
    docs,
    matched,
    uploaders,
    updateMatched,
    oneOneMapping,
    setOneOneMappting,
    computing,
    allPrimaryKeys,
    selectedPrimaryKeys,
    setSelectedPrimaryKeys,
  } = useCompareController(
    ticket,
    fullAccessStates,
    primaryKeyRoot,
    defaultPrimaryKeys,
    productSchema,
  )

  const [showDialog, renderEditor] = useDialog(ProductEditDialog)
  const showEditor = useCallback(
    (doc, crud) => {
      showDialog({
        doc,
        ticket,
        crud,
      })
    },
    [ticket, showDialog],
  )

  const upload = useUploadProductOfTicket(ticket)
  const uploadDoc = useCallback(context => upload(ticket, context, email), [
    upload,
    ticket,
    email,
  ])
  const { isReady: isContextReady } = useContext(ProductContext)
  const [showMergeDialog, renderMergeDialog] = useDialog(ProductMergerDialog)
  return {
    isReady: !isNil(docs) && isContextReady,
    docs,
    matched,
    uploaders,
    updateMatched,
    uploadDoc,
    oneOneMapping,
    setOneOneMappting,
    computing,
    showEditor,
    renderEditor,
    allPrimaryKeys,
    selectedPrimaryKeys,
    setSelectedPrimaryKeys,
    renderMergeDialog,
    showMergeDialog,
  }
}
export function ProductCompareView({ ticket }) {
  const context = useProductContext()
  const checker = useCheckApi({ externalAPI: checkProduct })

  return (
    <ProductContext.Provider value={context}>
      <CompareView
        ticket={ticket}
        useController={useController}
        useTranspile={useTranspile}
        currentSchema={productSchema}
        checker={checker}
      />
    </ProductContext.Provider>
  )
}

ProductCompareView.propTypes = {
  ticket: TicketPropType.isRequired,
}

export default ProductCompareView
