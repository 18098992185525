import { namespace, schema, backendDocType, docPrivilege } from './config'
import { useTranspile } from './common'
import loader, {
  ProductRestorer,
  generateReferenceDoc,
  queryManifest,
} from './loader'
import listview from './listview'
import compareview from './compare'
import { ticketProfile, useScopeName } from './ticket'
import { addViewManifest } from '../Config'

const manifest = {
  loader,
  schema,
  namespace,
  backendDocType,
  privilege: docPrivilege,
  queryManifest,
  useTranspile,
  generateReferenceDoc,
  restore: ProductRestorer,
  listview,
  compareview,
  ticketProfile,
  useScopeName,
  order: 2,
}

addViewManifest(manifest)
