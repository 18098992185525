import { useCallback, useContext, useMemo } from 'react'
import { diffChars } from 'diff'
import {
  get,
  map,
  zip,
  find,
  omit,
  isNil,
  sortBy,
  values,
  flatten,
  isEqual,
} from 'lodash'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Box } from '@changingai/react-editor-common-component'

import { KG_NODES } from '@common'
import { Card } from '@widget'
import theme from '@theme'

import { PlanContext } from './context'
import { PlanPropType, benefitsRefSchema } from './schema'

import { DiffContent } from '../CompareView'

function renderValue(value) {
  return value || '🈳'
}

const StyledCard = styled(Card)``

StyledCard.defaultProps = {
  p: 1,
  my: 1,
  bg: 'blacks.1',
  flex: 'none',
}

function getOptionValueString(value) {
  if (get(value, 'value_type') === 'empty') return '投保額度: (空白)'
  if (get(value, 'value_type') === 'const')
    return `投保額度(常數): ${value.constant}`
  if (get(value, 'value_type') === 'id')
    return `保額選項: ${value.option.name}\n倍率: ${value.plan_ratio}`
  return '🈳'
}

function FeetableAxes({ current, pair, showDeleteChar, showDiffColor }) {
  const { planRequestVariables } = useContext(PlanContext)

  const currentAxes = get(current, 'axes')
  const pairAxes = get(pair, 'axes')

  const getAxisName = useCallback(
    node_id => get(find(planRequestVariables, { node_id }), 'name'),
    [planRequestVariables],
  )

  if (
    isEqual(
      omit(currentAxes, ['variable_id', 'option.option_id']),
      omit(pairAxes, ['variable_id', 'option.option_id']),
    )
  )
    return null

  if (isNil(current))
    return (
      <Box
        style={{ textDecoration: 'line-through' }}
        color={theme.colors.danger}
      >
        {pair.feetable_name}
      </Box>
    )

  return (
    <StyledCard>
      {current.feetable_name}
      {map(currentAxes, axis => {
        const pairAxis = find(pairAxes, { axis_id: axis.axis_id })
        if (axis.axis_id === KG_NODES.insurance.REQUEST_VARIABLE.REQUEST_AMOUNT)
          return (
            <StyledCard key={axis.axis_id}>
              <DiffContent
                diffs={diffChars(
                  getOptionValueString(axis),
                  getOptionValueString(pairAxis),
                )}
                showDeleteChar={showDeleteChar}
                showDiffColor={showDiffColor}
              />
            </StyledCard>
          )
        return (
          <StyledCard key={axis.axis_id}>
            {getAxisName(axis.axis_id)}:
            <DiffContent
              diffs={diffChars(
                renderValue(axis.axis_value),
                renderValue(get(pairAxis, 'axis_value')),
              )}
              showDeleteChar={showDeleteChar}
              showDiffColor={showDiffColor}
            />
          </StyledCard>
        )
      })}
    </StyledCard>
  )
}

FeetableAxes.propTypes = {
  current: PlanPropType,
  pair: PlanPropType,
  showDeleteChar: PropTypes.bool.isRequired,
  showDiffColor: PropTypes.bool.isRequired,
}

export function PlanFeetableDiffLayout({
  current,
  pair,
  showDeleteChar,
  showDiffColor,
}) {
  const { productFeeTables } = useContext(PlanContext)
  const [currentFeetables, pairFeetables] = useMemo(
    () =>
      map(
        [current, pair],
        ({ amount_options, feetables }) =>
          sortBy(
            map(feetables, feetable => ({
              ...feetable,
              feetable_name: get(
                find(productFeeTables, { _id: feetable.feetable_id }),
                'name',
              ),
              axes: map(feetable.axes, axis => ({
                ...axis,
                option:
                  find(amount_options, {
                    option_id: axis.option_id,
                  }) || axis.option_id,
              })),
            })),
          ),
        'feetable_id',
      ),
    [pair, current, productFeeTables],
  )
  return (
    <>
      {map(
        zip(currentFeetables, pairFeetables),
        ([currentFeetable, pairFeetable]) => (
          <FeetableAxes
            key={`${get(currentFeetable, 'feetable_id')}-${get(
              pairFeetable,
              'feetable_id',
            )}`}
            current={currentFeetable}
            pair={pairFeetable}
            showDeleteChar={showDeleteChar}
            showDiffColor={showDiffColor}
          />
        ),
      )}
    </>
  )
}

PlanFeetableDiffLayout.propTypes = {
  current: PlanPropType,
  pair: PlanPropType,
  showDeleteChar: PropTypes.bool.isRequired,
  showDiffColor: PropTypes.bool.isRequired,
}

function PlanBenefit({ current, pair, showDeleteChar, showDiffColor }) {
  const renderOptionDiff = useCallback(
    () =>
      isEqual(
        get(current, 'option', null),
        get(pair, 'option', null),
      ) ? null : (
        <StyledCard>
          <DiffContent
            diffs={diffChars(
              getOptionValueString(current),
              getOptionValueString(pair),
            )}
            showDeleteChar={showDeleteChar}
            showDiffColor={showDiffColor}
          />
        </StyledCard>
      ),
    [current, pair, showDeleteChar, showDiffColor],
  )

  const renderDiff = useCallback(
    fieldName =>
      isEqual(
        get(current, fieldName, null),
        get(pair, fieldName, null),
      ) ? null : (
        <StyledCard key={fieldName}>
          {get(benefitsRefSchema, `${fieldName}.label`)}:
          <DiffContent
            diffs={diffChars(
              renderValue(current[fieldName]),
              renderValue(get(pair, fieldName)),
            )}
            showDeleteChar={showDeleteChar}
            showDiffColor={showDiffColor}
          />
        </StyledCard>
      ),
    [current, pair, showDeleteChar, showDiffColor],
  )

  if (
    isEqual(
      omit(current, ['option_id', 'option.option_id']),
      omit(pair, ['option_id', 'option.option_id']),
    )
  )
    return null

  if (isNil(current))
    return (
      <Box
        style={{ textDecoration: 'line-through' }}
        color={theme.colors.danger}
      >
        {pair.benefit_name}
      </Box>
    )

  return (
    <StyledCard>
      <DiffContent
        diffs={diffChars(
          renderValue(get(current, 'benefit_name')),
          renderValue(get(pair, 'benefit_name')),
        )}
        showDeleteChar={showDeleteChar}
        showDiffColor={showDiffColor}
      />
      {map(
        [
          'display_name',
          'payback_text',
          'payback_sample',
          'request_unit',
          'payback_note',
        ],
        renderDiff,
      )}
      {renderOptionDiff()}
    </StyledCard>
  )
}

PlanBenefit.propTypes = {
  current: PropTypes.object,
  pair: PropTypes.object,
  showDeleteChar: PropTypes.bool.isRequired,
  showDiffColor: PropTypes.bool.isRequired,
}

export function PlanBenefitDiffLayout({
  current,
  pair,
  showDeleteChar,
  showDiffColor,
}) {
  const { benefitsOfContract } = useContext(PlanContext)
  const benefitInfo = useMemo(() => flatten(values(benefitsOfContract)), [
    benefitsOfContract,
  ])
  const [currentBenefits, pairBenefits] = useMemo(
    () =>
      map([current, pair], ({ benefits, amount_options }) =>
        map(benefits, benefit => ({
          ...benefit,
          benefit_name: get(
            find(benefitInfo, { _id: get(benefit, 'benefit_id') }),
            'benefit_name',
          ),
          option:
            find(amount_options, {
              option_id: get(benefit, 'option_id'),
            }) || get(benefit, 'option_id'),
        })),
      ),
    [pair, current, benefitInfo],
  )
  return (
    <>
      {map(zip(currentBenefits, pairBenefits), ([current, pair]) => (
        <PlanBenefit
          key={`${get(current, 'benefit_id')}-${get(pair, 'benefit_id')}}`}
          current={current}
          pair={pair}
          showDeleteChar={showDeleteChar}
          showDiffColor={showDiffColor}
        />
      ))}
    </>
  )
}

PlanBenefitDiffLayout.propTypes = {
  current: PlanPropType.isRequired,
  pair: PlanPropType,
  showDeleteChar: PropTypes.bool.isRequired,
  showDiffColor: PropTypes.bool.isRequired,
}
