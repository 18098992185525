import { useMemo, useCallback, useContext, useEffect } from 'react'
import { useImmer } from 'use-immer'
import produce from 'immer'

import {
  find,
  differenceBy,
  findIndex,
  compact,
  get,
  map,
  some,
  isNil,
  flatMap,
  mapValues,
  pickBy,
  has,
  filter,
  isPlainObject,
  keys,
  join,
  set,
} from 'lodash'
import md5 from 'md5'

import { computeType, ProjectContext, graphVisitor, MatchState } from '@common'
import { useFetchReferencedKG } from '@schema'
import { recomputeCrud } from './TicketHelper'

export function useCacheDocs(
  email,
  peepMode,
  ticket,
  remoteDocs,
  fullAccessStates,
) {
  const [docs, setDocs] = useImmer([])
  const [updated, setUpdated] = useImmer([])
  const [deleted, setDeleted] = useImmer([])

  // Reset cache buffers once remoteDocs was changed.
  useEffect(() => {
    setDocs(() => [])
    setUpdated(() => [])
    setDeleted(() => [])
  }, [remoteDocs, setDocs, setUpdated, setDeleted])

  useEffect(() => {
    const bothDocs =
      peepMode ||
      isNil(ticket) ||
      fullAccessStates.includes(ticket.active_state)

    if (remoteDocs) {
      setDocs(draft =>
        filter(
          [...draft, ...differenceBy(remoteDocs, draft, '_id')],
          ({ _id, uploader }) =>
            !deleted.includes(_id) && (bothDocs || uploader === email),
        ),
      )
    }
  }, [
    remoteDocs,
    peepMode,
    ticket,
    deleted,
    setDocs,
    setUpdated,
    email,
    fullAccessStates,
  ])

  const deleteCache = useCallback(
    _id => {
      setDeleted(draft => [...draft, _id])
    },
    [setDeleted],
  )

  const updateCache = useCallback(
    (doc, originalId) => {
      setDocs(draft => {
        const id = originalId ? originalId : doc._id
        const index = findIndex(draft, { _id: id })
        if (index === -1) {
          draft.push(doc)
        } else {
          draft[index] = produce(doc, draft => {
            delete draft.local_doc
          })
        }

        setUpdated(draft => [...draft, doc._id])

        // Update the match_state of the pair one so that we can see the lock
        // icon on both docs.
        if (doc.matched_target) {
          const pair = find(draft, { _id: doc.matched_target })
          if (pair) {
            pair.matched_target = doc._id
            pair.matched_state = MatchState.FULLY_MATCHED
          }
        }
      })
    },
    [setDocs, setUpdated],
  )

  return {
    updated,
    deleteCache,
    updateCache,
    docs,
  }
}

export function useConvertIdToName(
  docs,
  referencedList,
  selectedFields,
  currentSchema,
) {
  const referenced = useMemo(() => {
    if (some(referencedList, isNil)) return null
    return compact(
      flatMap(referencedList, root => {
        if ('children' in root) {
          const nodes = []
          graphVisitor(root, node => nodes.push(node))
          return nodes
        }

        return root
      }),
    )
  }, [referencedList])

  const dictonary = useMemo(() => {
    function convertToName(value) {
      return get(find(referenced, { id: value }), 'name', value)
    }

    function transpileDoc(doc, schema) {
      mapValues(
        pickBy(
          schema,
          (_, fieldname) =>
            schema !== currentSchema ||
            !selectedFields ||
            selectedFields.includes(fieldname),
        ),
        (_, fieldname) => {
          if (!has(doc, fieldname)) {
            doc[fieldname] = null
            return
          }
          const { isTypedArray, extractedSchema } = computeType(
            schema[fieldname].type,
          )
          if (!isNil(extractedSchema)) {
            if (isTypedArray) {
              return doc[fieldname].forEach(item =>
                transpileDoc(item, extractedSchema),
              )
            }
            return transpileDoc(doc[fieldname], extractedSchema)
          }
          if (isTypedArray) {
            doc[fieldname] = map(doc[fieldname], convertToName)
          } else {
            doc[fieldname] = convertToName(doc[fieldname])
          }
        },
      )
    }

    return map(docs, doc =>
      produce(doc, draft => {
        transpileDoc(draft, currentSchema)
      }),
    )
  }, [docs, selectedFields, referenced, currentSchema])

  return dictonary
}

export function useKGTranspile(currentSchema, docs, selectedFields) {
  const nodes = useFetchReferencedKG(docs, currentSchema, selectedFields, true)
  const referencedList = useMemo(() => [nodes], [nodes])
  return useConvertIdToName(docs, referencedList, selectedFields, currentSchema)
}

export function useDefaultCrud(ticket) {
  const { email, peepMode, privilege } = useContext(ProjectContext)

  const result = useMemo(() => {
    const crud = recomputeCrud(ticket, email, privilege)
    if (peepMode) {
      crud.read = true
      crud.update = false
      crud.create = false
      crud.delete = false
    }
    return crud
  }, [ticket, email, peepMode, privilege])

  return result
}

export function getTicketDocPath(namespace, schema, doc) {
  return {
    schema,
    docUrl: `/${namespace}/${schema}/${get(doc, '_id')}`,
    listviewUrl: `/${namespace}/listview/${schema}/${get(doc, 'ticket_id')}`,
  }
}

export function getNoTicketDocPath(namespace, schema, doc) {
  return {
    schema,
    docUrl: `/${namespace}/${schema}/${get(doc, '_id')}`,
    listviewUrl: `/${namespace}/${schema}/`,
  }
}

export function bindLayoutSchema(layoutMap, currentSchema, layoutSchema) {
  if (!isPlainObject(layoutSchema))
    throw new Error('LayoutSchema should be plain object')
  if (some(keys(layoutSchema), field => !has(currentSchema, field)))
    throw new Error('Found field not exist in schema')
  set(layoutMap, md5(join(keys(currentSchema))), layoutSchema)
}

export function findLayoutSchema(layoutMap, currentSchema) {
  return get(layoutMap, md5(join(keys(currentSchema))), currentSchema)
}
