import PropTypes from 'prop-types'
import { get, find, findLast } from 'lodash'

import { toPairs } from './common'

export const TicketState = Object.freeze(
  toPairs(['CREATE', 'APPROXIMATE', 'MERGE', 'FINISH']),
)
export const TicketSubState = Object.freeze(
  toPairs(['INITIATED', 'DISPATCHED']),
)
export const MatchState = Object.freeze(
  toPairs(['NO_MATCHED', 'PARTIALLY_MATCHED', 'FULLY_MATCHED']),
)

export const PropCrud = PropTypes.shape({
  create: PropTypes.bool.isRequired,
  read: PropTypes.bool.isRequired,
  update: PropTypes.bool.isRequired,
  delete: PropTypes.bool.isRequired,
  overwrite: PropTypes.bool.isRequired,
  review: PropTypes.bool,
})

export const TicketStateType = PropTypes.shape({
  main_state: PropTypes.string.isRequired,
  sub_state: PropTypes.string.isRequired,
  assignees: PropTypes.arrayOf(PropTypes.string).isRequired,
  confirmors: PropTypes.arrayOf(PropTypes.string).isRequired,
})

export const AssigneeMetaType = PropTypes.shape({
  assignee: PropTypes.string.isRequired,
  own_docs: PropTypes.number.isRequired,
  fully_matched_docs: PropTypes.number.isRequired,
})

export const TicketMetaType = PropTypes.shape({
  assignee_meta: PropTypes.arrayOf(AssigneeMetaType).isRequired,
})

export const TicketBaseShape = {
  name: PropTypes.string.isRequired,
  _id: PropTypes.string.isRequired,
  scope_id: PropTypes.string.isRequired,
  crud: PropCrud.isRequired,
  states: PropTypes.arrayOf(TicketStateType).isRequired,
  active_state: PropTypes.string.isRequired,
  last_modified: PropTypes.string.isRequired,
}

export const TicketBasePropType = PropTypes.shape(TicketBaseShape)
export const TicketPropType = PropTypes.shape({
  ...TicketBaseShape,
  meta: TicketMetaType.isRequired,
})

export function getActiveState({ active_state, states }) {
  return find(states, { main_state: active_state })
}

export function isWaitingTicketAccessRight(ticket, email) {
  const activeState = getActiveState(ticket)
  // In APPROXIMATE state, the second editor is allow to edit rewards of this
  // ticket only after the first(main) editor returned this ticket.
  if (activeState.main_state === TicketState.APPROXIMATE) {
    if (
      activeState.assignees.indexOf(email) !== 0 &&
      activeState.confirmors.length !== 1
    )
      return true
  }

  return false
}

export function isTicketViewable(ticket, privilege) {
  if (!privilege.includes('view')) return false

  const activeState = getActiveState(ticket)
  return (
    activeState.main_state === TicketState.FINISH ||
    activeState.sub_state === TicketSubState.DISPATCHED
  )
}

export const getDataEditors = ({ states }) => {
  return get(
    findLast(states, {
      assignee_as_editor: true,
      sub_state: TicketSubState.DISPATCHED,
    }),
    'assignees',
    [],
  )
}

export const genesis = 2020
