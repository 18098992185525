import {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
  Fragment,
} from 'react'
import PropTypes from 'prop-types'
import 'styled-components/macro'
import css from '@styled-system/css'
import { Menubar } from 'primereact/menubar'
import styled from 'styled-components'
import { position } from 'styled-system'
import {
  isEmpty,
  get,
  compact,
  map,
  includes,
  has,
  filter,
  flatten,
  slice,
  size,
} from 'lodash'
import { Flex, Text } from '@changingai/react-editor-common-component'

import { ProjectContext, useMultiLru, namespaces, useDialog } from '@common'
import { AboutDialog } from '@dialog'
import { ButtonLike } from '@widget'
import { TicketSelectionDialog, getViewManifest, PeepModeIcon } from '@view'

const MenuContainer = styled.div`
  ${position}
  position: relative;
  width: 100%;
}
`

export const menuHeight = 42

const StyledMenubar = styled(Menubar)`
  && {
    background: ${props => props.theme.colors.menu};
    color: white;
    height: ${menuHeight}px;
  }
  && .p-menubar-root-list {
    background: ${props => props.theme.colors.menu};
  }
  &&&& .p-menuitem-link {
    .p-menuitem-text {
      color: white;
    }
    .p-menuitem-icon {
      color: white;
    }
    .p-submenu-icon {
      color: white;
    }
  }
  && .p-submenu-list {
    background-color: ${props => props.theme.colors.menu};
    white-space: nowrap;
    width: unset;
  }
`

function createDocItem(view, onCommand, { privilege, showTicket, getLru }) {
  function createTicketlessDocItem(view, onCommand) {
    return [
      {
        label: `Edit ${view.backendDocType}`,
        command: () => {
          onCommand([view.schema])
        },
      },
    ]
  }

  function createTicketDocItem(view, onCommand, showTicket, getLru) {
    const items = []

    const createItem = (label, viewType) => ({
      label,
      items: [
        {
          label: 'Select a Ticket...',
          icon: 'pi pi-fw pi-ticket',
          command: () => {
            showTicket({
              scopeType: view.backendDocType,
              schema: view.schema,
              viewType,
            })
          },
        },
        {
          separator: true,
        },
        ...getLru(`${view.backendDocType}.${viewType}`).map(ticket => ({
          label: ticket.name,
          command: () => {
            onCommand([viewType, view.schema, ticket._id])
          },
        })),
      ],
    })

    if (has(view, 'listview'))
      items.push(createItem(`Edit ${view.backendDocType}`, 'listview'))
    if (has(view, 'compareview'))
      items.push(createItem(`Compare ${view.backendDocType}`, 'compareview'))
    if (has(view, 'composedview'))
      items.push({
        label: `Compose ${view.backendDocType}`,
        command: () => {
          onCommand(['composedview', view.schema])
        },
      })
    return items
  }

  if (has(view, 'privilege') && !includes(privilege, get(view, 'privilege'))) {
    return []
  }

  return has(view, 'ticketProfile')
    ? createTicketDocItem(view, onCommand, showTicket, getLru)
    : createTicketlessDocItem(view, onCommand)
}

function createTicketItem(view, onCommand) {
  if (!has(view, 'backendDocType') || !get(view, 'useScopeName')) return null
  return {
    label: `${view.backendDocType} Ticket`,
    icon: 'pi pi-fw pi-ticket',
    command: () => {
      onCommand(['ticketList', get(view, 'backendDocType')])
    },
  }
}

function useCreateMenu(namespace, onCommand, showTicket) {
  const { privilege, ticketVersion } = useContext(ProjectContext)
  const [pushLru, getLru] = useMultiLru(`${namespace}.${ticketVersion}`)

  return useMemo(() => {
    const hasDocEditingPrivilege =
      !has(namespaces[namespace], 'privilege') ||
      includes(privilege, get(namespaces[namespace], 'privilege'))

    const editItems = hasDocEditingPrivilege
      ? flatten(
          map(filter(getViewManifest(), { namespace }), view => {
            const item = createDocItem(view, onCommand, {
              privilege,
              showTicket,
              getLru,
            })
            return isEmpty(item)
              ? []
              : [
                  ...item,
                  {
                    separator: true,
                  },
                ]
          }),
        )
      : []
    return {
      ticketMenu: includes(privilege, 'ticket')
        ? [
            {
              label: 'Ticket',
              icon: 'pi pi-fw pi-ticket',
              items: compact(
                map(filter(getViewManifest(), { namespace }), view =>
                  createTicketItem(view, onCommand),
                ),
              ),
            },
          ]
        : [],
      editMenu: compact([
        !isEmpty(editItems) && {
          label: 'Doc Editing',
          // Remove the latest separator.
          items: slice(editItems, 0, size(editItems) - 1),
        },
      ]),
      onSelect: (type, ticket, viewType) => {
        pushLru(`${type}.${viewType}`, ticket)
      },
    }
  }, [onCommand, showTicket, privilege, getLru, pushLru, namespace])
}

export function MainMenu({ onCommand }) {
  const {
    privilege,
    peepMode,
    setPeepMode,
    email,
    namespace,
    ticketVersion,
  } = useContext(ProjectContext)

  const [showAbout, renderAbout] = useDialog(AboutDialog)

  const [showTicket, renderSelectTicketDialog] = useDialog(
    TicketSelectionDialog,
  )

  const { onSelect, ticketMenu, editMenu } = useCreateMenu(
    namespace,
    onCommand,
    showTicket,
  )
  const onSelectTicketCallback = useCallback(
    (type, ticket, viewType, openInNewTab, schema) => {
      onSelect(type, ticket, viewType)
      onCommand([viewType, schema, ticket._id], openInNewTab)
    },
    [onSelect, onCommand],
  )

  const [menuItems, setMenuItems] = useState([])
  useEffect(() => {
    if (isEmpty(privilege)) return setMenuItems([])

    setMenuItems(
      compact([
        includes(privilege, 'view') && {
          label: 'Home',
          icon: 'pi pi-fw pi-home',
          command: () => {
            onCommand([''])
          },
        },
        ...ticketMenu,
        ...editMenu,
        includes(privilege, 'view') && {
          label: 'Config',
          icon: 'pi pi-fw pi-info-circle',
          command: () => {
            showAbout()
          },
        },
        includes(privilege, 'view') && {
          label: 'Statistic',
          icon: 'pi pi-chart-bar',
          command: () => {
            onCommand(['statistic'])
          },
        },
      ]),
    )
  }, [
    namespace,
    onCommand,
    showTicket,
    showAbout,
    privilege,
    peepMode,
    ticketMenu,
    editMenu,
  ])

  const renderNamspaceIcons = useCallback(() => {
    return (
      <Flex alignItems="center">
        {map(
          filter(namespaces, namespace =>
            includes(privilege, namespace.privilege),
          ),
          ({ value, Icon }) =>
            namespace !== value ? (
              <ButtonLike
                key={value}
                onClick={() => {
                  window.open(`#/${value}/`)
                }}
              >
                <Icon style={{ color: 'Gainsboro' }} />
              </ButtonLike>
            ) : (
              <Fragment key={value}>
                <Icon style={{ color: 'yellow' }} />
              </Fragment>
            ),
        )}
      </Flex>
    )
  }, [namespace, privilege])

  if (!includes(privilege, 'view')) return null

  return (
    <MenuContainer zIndex={8} data-cy="main_menu">
      <StyledMenubar model={menuItems} start={renderNamspaceIcons} />
      {renderSelectTicketDialog({ onOK: onSelectTicketCallback })}
      {renderAbout({ onOK: () => {} })}
      <Flex
        position="absolute"
        right="50px"
        top="0"
        color="white"
        justifyContent="space-between"
        alignItems="center"
        fontSize="2em"
        height="40px"
        css={css({
          '& .p-selectbutton .p-button:first-child, & .p-selectbutton .p-button:last-child': {
            borderRadius: 'unset',
          },
        })}
      >
        <Text textShadow="2px 2px gray">
          {email
            ? `${email.substring(0, email.indexOf('@'))}(${ticketVersion})`
            : 'Unknown'}
        </Text>
        <PeepModeIcon peepMode={peepMode} setPeepMode={setPeepMode} />
      </Flex>
    </MenuContainer>
  )
}

MainMenu.propTypes = {
  onCommand: PropTypes.func.isRequired,
}
