import { useCallback, useMemo, useContext } from 'react'
import { isNil, castArray, find, size, get, partial } from 'lodash'

import { isLocalDoc, ProjectContext, namespaces, useDialog } from '@common'
import { createBlankDoc } from '@schema'
import { cceClient } from '@gql'

import { docPrivilege, namespace } from './config'
import { ContractEditor, useUploadContract } from './editor'
import { contractSchema } from './schema'
import { useCacheDocs, useKGTranspile } from '../Common'
import {
  useMutateTicket,
  useSoftTicketRef,
  canDeleteTicketRefDoc,
} from '../TicketHelper'
import OwnedDocSummaryModel, {
  useFetchTecklessDocs,
} from '../OwnedDocSummaryModel'
import { useReferenceBase, filterTicketRef } from '../Reference'
import { useDeleteDocWithHistory } from '../Uploader'

import { _deleteContract } from './listview.gql'

function useReference(doc) {
  const data = useReferenceBase('contract', filterTicketRef, doc)
  const ticketRef = useSoftTicketRef(doc, namespaces.insurance.value)
  return useMemo(() => {
    if (isNil(ticketRef) || isNil(get(data, 0))) return [null, null]
    return [size(ticketRef) + get(data, 0), get(data, 1)]
  }, [data, ticketRef])
}

export const useTranspile = partial(useKGTranspile, contractSchema)

function useController(_, selectedFields) {
  const contracts = useFetchTecklessDocs(
    'Contract',
    contractSchema,
    selectedFields,
  )
  const { updated, deleteCache, updateCache, docs } = useCacheDocs(
    null,
    true,
    null,
    contracts,
  )

  const [showDialog, renderDialog] = useDialog(ContractEditor)
  const showDoc = useCallback(
    (doc, crud) => {
      showDialog({
        doc,
        crud,
      })
    },
    [showDialog],
  )

  const upload = useUploadContract()
  const uploadDoc = useCallback(contract => upload(null, contract), [upload])

  const { deleteTicket } = useMutateTicket()
  const deleteContract = useDeleteDocWithHistory(contractSchema, ids => {
    cceClient.mutate({
      mutation: _deleteContract,
      variables: {
        ids: castArray(ids),
      },
    })
  })
  const deleteDoc = useCallback(
    async _id => {
      deleteCache(_id)
      const deleted = find(docs, { _id })
      if (isNil(deleted) || isLocalDoc(deleted)) return
      const [canDelete, ticketIds] = await canDeleteTicketRefDoc(
        { _id },
        namespace,
      )
      if (canDelete) await deleteTicket(ticketIds, 'Benefit')
      return deleteContract(deleted)
    },
    [deleteCache, docs, deleteContract, deleteTicket],
  )
  const { email } = useContext(ProjectContext)
  const createDoc = useCallback(() => {
    const contract = {
      ...createBlankDoc(contractSchema, true),
      uploader: email,
    }
    updateCache(contract)

    return contract
  }, [updateCache, email])

  return {
    isReady: !isNil(docs),
    docs,
    createDoc,
    showDoc,
    updateDoc: updateCache,
    updated,
    renderDoc: renderDialog,
    uploadDoc,
    deleteDoc,
    useReference,
  }
}

const defaultSummary = {
  selected: ['contract_name', 'insurance_company', 'contract_type'],
  filters: {},
}

const OwnedContractListView = props => {
  return (
    <OwnedDocSummaryModel
      {...props}
      crud={{
        read: true,
        update: true,
        create: true,
        delete: true,
        overwrite: true,
      }}
      useController={useController}
      useTranspile={useTranspile}
      defaultSummary={defaultSummary}
      requiredPrivilege={[docPrivilege]}
      currentSchema={contractSchema}
    />
  )
}

export default OwnedContractListView
