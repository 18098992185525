import { DocLoader } from './loader'
import { Errors } from './errors'
import { History } from './history'
import { Flex } from '@changingai/react-editor-common-component'

export function HomeView() {
  return (
    <Flex flexWrap="wrap" bg="Bisque" padding={2} width="100%">
      <Errors />
      <DocLoader />
      <History />
    </Flex>
  )
}
