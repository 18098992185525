import {
  fromPairs,
  isEmpty,
  toString,
  flow,
  some,
  trim,
  map,
  zip,
} from 'lodash'
import { useState, useCallback, useEffect } from 'react'

Array.prototype.remap = function(keyExtractor, valueExtractor) {
  const extract = (item, extractor) =>
    typeof extractor === 'function' ? extractor(item) : item[extractor]

  const keys = this.map(item => extract(item, keyExtractor))
  const values = this.map(item => extract(item, valueExtractor))
  if (some(map(keys, flow([toString, trim])), isEmpty))
    throw `Can't remap array with invalid/empty key(s)`

  return fromPairs(zip(keys, values))
}

export const useToggle = (initialOn = false) => {
  const [on, setOn] = useState(initialOn)
  const toggle = useCallback(() => setOn(on => !on), [setOn])

  return { on, toggle }
}

export const useHasMounted = () => {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)

    return () => {
      setHasMounted(false)
    }
  }, [])

  return hasMounted
}
