import { Fragment, useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { isEmpty, map, get, isNil, find, join, omit } from 'lodash'
import { TabView, TabPanel } from 'primereact/tabview'
import { Flex, Box } from '@changingai/react-editor-common-component'

import { OptionId, FeeVariables } from './common'
import { PlanContext } from './context'
import { Header, Cell, StyledGrid } from '../GridElement'
import {
  SummaryLabel,
  bindSchemaWithSummaryLayout,
  renderGridHeader,
} from '../SummaryFields'
import { schemaToPropTypes } from '@schema'
import {
  planSchema,
  requestVariableSchema,
  requestVariablesSchema,
  benefitsRefSchema,
  PlanPropType,
  feeTableRefSchema,
  axisSchema,
} from './schema'

const RequestLabel = styled(Box)``
RequestLabel.defaultProps = {
  bg: 'panelBG',
  fontWeight: 'bold',
  px: '2',
}

const RequestValue = styled(Box)``
RequestValue.defaultProps = {
  bg: 'whites.9',
  px: '2',
}

function RenderRequestVariables({ value }) {
  return (
    <>
      <Flex width="100%" alignItems="center">
        <SummaryLabel
          fieldname={planSchema.request_variables.label}
          isEmptyField={isEmpty(value)}
        />
      </Flex>
      {!isEmpty(value) && (
        <TabView>
          {map(value, ({ request_variable }, index) => (
            <TabPanel key={index} header={index}>
              <StyledGrid gridTemplateColumns="repeat(4, 25%)">
                <Header>{requestVariableSchema.request_type.label}</Header>
                <Header>{requestVariableSchema.range.label}</Header>
                <Header>{requestVariableSchema.list.label}</Header>
                <Header>{requestVariableSchema.variable_id.label}</Header>
                {map(
                  request_variable,
                  ({ request_type, range, list, variable_id }) => (
                    <Fragment key={variable_id}>
                      <Cell>{request_type}</Cell>
                      <Cell>{join(range, ', ')}</Cell>
                      <Cell>{join(list, ', ')}</Cell>
                      <Cell>{variable_id}</Cell>
                    </Fragment>
                  ),
                )}
              </StyledGrid>
            </TabPanel>
          ))}
        </TabView>
      )}
    </>
  )
}

RenderRequestVariables.propTypes = {
  value: PropTypes.arrayOf(schemaToPropTypes(requestVariablesSchema))
    .isRequired,
}

function RenderBenefitGrid({ context, values }) {
  const keyOrder = Object.keys(
    omit(benefitsRefSchema, ['constant', 'value_type']),
  )
  return (
    <>
      {renderGridHeader(benefitsRefSchema, keyOrder)}
      {map(values, (item, index) => (
        <Fragment key={index}>
          {map(keyOrder, key =>
            key !== 'option_id' ? (
              <Cell>{get(item, key, '')}</Cell>
            ) : (
              <Cell>
                <OptionId
                  amount_options={get(context, 'amount_options', [])}
                  option_id={get(item, 'option_id', '')}
                  constant={get(item, 'constant', '')}
                  value_type={get(item, 'value_type', '')}
                />
              </Cell>
            ),
          )}
        </Fragment>
      ))}
    </>
  )
}

RenderBenefitGrid.propTypes = {
  context: PlanPropType.isRequired,
  values: PropTypes.arrayOf(schemaToPropTypes(benefitsRefSchema)).isRequired,
}

function RenderFeetables({ context, value: planFeetTables }) {
  const { productFeeTables, planRequestVariables } = useContext(PlanContext)

  if (isNil(productFeeTables) || isNil(planRequestVariables)) return null
  return (
    <>
      <Flex width="100%" alignItems="center">
        <SummaryLabel
          fieldname={planSchema.feetables.label}
          isEmptyField={isEmpty(planFeetTables)}
        />
      </Flex>
      {!isEmpty(planFeetTables) && (
        <TabView>
          {map(planFeetTables, (feetable, tableIndex) => (
            <TabPanel
              key={feetable.feetable_id}
              header={get(
                find(productFeeTables, { _id: feetable.feetable_id }),
                'name',
              )}
            >
              <StyledGrid gridTemplateColumns="33% 33% 33%">
                <Header>維度</Header>
                <Header>{`${axisSchema.option_id.label}/${axisSchema.axis_value.label}`}</Header>
                <Header>{axisSchema.plan_ratio.label}</Header>
                {map(
                  feetable.axes,
                  (
                    {
                      axis_id,
                      plan_ratio,
                      option_id,
                      constant,
                      value_type,
                      axis_value,
                    },
                    axisIndex,
                  ) => (
                    <Fragment key={axis_id}>
                      <Cell>{axis_id}</Cell>
                      <Cell>
                        <FeeVariables
                          amount_options={context.amount_options}
                          axis_id={get(
                            context,
                            `feetables.${tableIndex}.axes.${axisIndex}.axis_id`,
                          )}
                          option_id={option_id}
                          constant={constant}
                          value_type={value_type}
                          axis_value={axis_value}
                        />
                      </Cell>
                      <Cell>{plan_ratio}</Cell>
                    </Fragment>
                  ),
                )}
              </StyledGrid>
            </TabPanel>
          ))}
        </TabView>
      )}
    </>
  )
}
RenderFeetables.propTypes = {
  context: PlanPropType.isRequired,
  value: PropTypes.arrayOf(schemaToPropTypes(feeTableRefSchema)).isRequired,
}

bindSchemaWithSummaryLayout(planSchema, {
  request_variables: {
    renderer: RenderRequestVariables,
  },
  benefits: {
    gridTemplateColumns: '2fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr',
    gridRenderer: RenderBenefitGrid,
  },
  feetables: {
    renderer: RenderFeetables,
  },
  amount_options: {
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
  },
})
