import { useMemo, useCallback, useEffect, useState, useRef } from 'react'
import {
  keys,
  has,
  get,
  groupBy,
  mapValues,
  fromPairs,
  map,
  compact,
  flatMap,
  isNil,
  head,
} from 'lodash'
import uuid from 'react-uuid'

import { getViewManifest } from './Config'
import { isMongoId } from '@common'
import { cceClient } from '@gql'

const genLoaderMap = () => {
  const loaders = getViewManifest()

  return mapValues(groupBy(loaders, 'namespace'), items =>
    fromPairs(map(items, ({ schema, loader }) => [schema, loader])),
  )
}

export function useFetchPerfectMatchedDocs(doc_ids, currentSchema) {
  const [loading, setLoading] = useState(false)
  const [docs, setDocs] = useState(null)
  useEffect(() => {
    setLoading(true)
    const configs = compact(map(getViewManifest(), 'fetchPerfectMatchedDocs'))
    Promise.all(map(configs, config => config(doc_ids, currentSchema))).then(
      results => {
        setDocs(flatMap(results))
        setLoading(false)
      },
    )
  }, [doc_ids, currentSchema, setLoading])

  return useMemo(
    () => ({
      loading,
      docs,
    }),
    [loading, docs],
  )
}

const supportedNamespace = () => keys(genLoaderMap())
const supportedTypeOfNamespace = namespace => {
  const loaders = genLoaderMap()
  if (!has(loaders, namespace)) {
    throw new Error(`Unexpected namespace: ${namespace}`)
  }
  return keys(loaders[namespace])
}

export function useLoader() {
  const loader = useCallback((namespace, view, docId) => {
    const loaderMap = genLoaderMap()
    if (!has(loaderMap, namespace)) {
      throw new Error(`Unexpected namespace: ${namespace}`)
    }
    if (!has(loaderMap[namespace], view)) {
      throw new Error(`Unexpected view: ${view}`)
    }

    const Loader = get(loaderMap[namespace], view)
    return <Loader docId={docId} />
  }, [])

  return useMemo(
    () => ({
      loader,
      supportedNamespace,
      supportedTypeOfNamespace,
    }),
    [loader],
  )
}

async function tryLoad(config, docId) {
  // Not sending a doc query to the backend with invalid mongo id
  // to prevent get an gql error from gql schema validator.
  if (!isMongoId(docId)) return null

  const {
    queryManifest: { gql, name },
  } = config
  const { data } = await cceClient.query({
    query: gql,
    variables: {
      doc_ids: [docId],
    },
  })

  const doc = get(data, `${name}.0`)
  return isNil(doc) ? null : { config, doc }
}

export function useTryLoad(docId) {
  const [result, setResult] = useState(null)
  const downloadId = useRef()
  useEffect(() => {
    const currentDownloadId = uuid()
    downloadId.current = currentDownloadId

    async function tryLoadDocs(docId) {
      const results = await Promise.all(
        map(getViewManifest(), config => tryLoad(config, docId)),
      )
      if (currentDownloadId === downloadId.current) {
        setResult(head(compact(results)))
      }
    }
    setResult(null)
    tryLoadDocs(docId)
  }, [setResult, docId])

  return result
}
