import { useMemo, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  isEmpty,
  find,
  get,
  set,
  map,
  includes,
  isNil,
  partial,
  toNumber,
} from 'lodash'
import { RadioButton } from 'primereact/radiobutton'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { Tag } from 'primereact/tag'
import css from '@styled-system/css'
import { Flex, Box } from '@changingai/react-editor-common-component'

import { KG_NODES } from '@common'
import { useFetchReferencedKG, schemaToPropTypes } from '@schema'
import { ScrollBar } from '@widget'
import { cceClient } from '@gql'
import { dataAnalysisService } from '@remote'

import { amountOptionSchema, planSchema, axisSchema } from './schema'

import { useConvertIdToName } from '../Common'
import { useUploadDocWithHistory } from '../Uploader'

import { _uploadPlan } from './common.gql'

function isDigit(value) {
  return !isNaN(value)
}

export function isUuid(value) {
  return !isEmpty(value) && !isDigit(value)
}

function EmptyConstant({ value, label = '常數' }) {
  if (isNil(value)) {
    return (
      <Flex width="100%" justifyContent="flex-end">
        <Tag severity="warning" value="空白" />
      </Flex>
    )
  }

  return (
    <Flex width="100%" alignItems="center">
      <Tag value={label} />
      <Box ml="2">{value}</Box>
    </Flex>
  )
}

EmptyConstant.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
}

export function OptionId({
  amount_options,
  option_id,
  plan_ratio,
  constant,
  value_type,
}) {
  if (value_type !== 'id') {
    return <EmptyConstant value={constant} />
  }

  const option = find(amount_options, { option_id })
  return (
    <>
      <Flex
        width="100%"
        alignItems="center"
        color={isEmpty(get(option, 'name')) ? 'danger' : 'auto'}
      >
        <Tag severity="success" value={get(option, 'name', '（錯誤代碼）')} />
        <Box ml="2">{plan_ratio}</Box>
      </Flex>
    </>
  )
}

OptionId.propTypes = {
  amount_options: PropTypes.arrayOf(schemaToPropTypes(amountOptionSchema)),
  option_id: PropTypes.string,
  plan_ratio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  constant: PropTypes.number,
  value_type: PropTypes.string.isRequired,
}

export function FeeVariables({
  amount_options,
  axis_id,
  option_id,
  plan_ratio,
  axis_value,
  constant,
  value_type,
}) {
  if (axis_id === KG_NODES.insurance.REQUEST_VARIABLE.REQUEST_AMOUNT) {
    return (
      <OptionId
        constant={constant}
        amount_options={amount_options}
        option_id={option_id}
        plan_ratio={plan_ratio}
        value_type={value_type}
      />
    )
  }

  return (
    <EmptyConstant value={axis_value} label={axisSchema.axis_value.label} />
  )
}

FeeVariables.propTypes = {
  option_id: PropTypes.string,
  plan_ratio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  axis_id: PropTypes.string,
  amount_options: PropTypes.arrayOf(schemaToPropTypes(amountOptionSchema)),
  axis_value: PropTypes.string,
  constant: PropTypes.number,
  value_type: PropTypes.string.isRequired,
}

export function TrineInput({
  options,
  option_id,
  constant,
  label,
  onChange,
  value_type,
}) {
  useEffect(() => {
    // In case the selected option was been removed from options
    if (
      value_type === 'id' &&
      !isNil(option_id) &&
      !includes(map(options, 'value'), option_id)
    ) {
      onChange(null, 'empty')
    }
  }, [value_type, onChange, options, option_id])

  const onTypeChange = useCallback(
    ({ value: type }) => {
      const value = type === 'id' ? get(options, '0.value') : null
      onChange(value, type)
    },
    [onChange, options],
  )

  return (
    <>
      <Flex width="100%" mb="1" alignItems="space-between">
        <Flex flex="1 1">
          <RadioButton
            name="currentType"
            value="empty"
            onChange={onTypeChange}
            checked={value_type === 'empty'}
          />
          <Box ml="1">空白</Box>
        </Flex>
        <Flex flex="1 1">
          <RadioButton
            name="currentType"
            value="id"
            onChange={onTypeChange}
            checked={value_type === 'id'}
          />
          <Box ml="1">{label}</Box>
        </Flex>
        <Flex flex="1 1">
          <RadioButton
            name="currentType"
            value="const"
            onChange={onTypeChange}
            checked={value_type === 'const'}
          />
          <Box ml="1">常數</Box>
        </Flex>
      </Flex>
      {value_type === 'id' && (
        <Dropdown
          style={{ width: '100%' }}
          placeholder="選擇基底保額"
          value={option_id}
          options={options}
          onChange={({ value }) => onChange(value, 'id')}
        />
      )}
      {value_type === 'const' && (
        <InputText
          keyfilter="num"
          style={{ width: '100%' }}
          placeholder="請輸入數字"
          value={constant}
          onChange={({ target: { value } }) =>
            onChange(toNumber(value), 'const')
          }
        />
      )}
    </>
  )
}

TrineInput.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  option_id: PropTypes.string,
  constant: PropTypes.number,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value_type: PropTypes.string.isRequired,
}

export const cssDataTable = css({
  '& .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th': {
    bg: 'black',
    color: 'white',
    textAlign: 'center',
    fontSize: '0.75em',
  },
  '&&& .p-editable-column': {
    bg: 'white',
  },
  '& .p-datatable .p-datatable-tbody > tr > td': {
    bg: 'blacks.2',
  },
  '&& textarea.p-inputtext': {
    height: '200px',
  },
})

export const TableCell = styled(Box)`
  ${ScrollBar}
  white-space: pre-wrap;
  word-break: break-all;
  max-height: 200px;
`

export function OptionInput({
  value_type,
  option_id,
  plan_ratio,
  constant,
  onOptionChange,
  onRatioChange,
  context,
}) {
  const options = useMemo(
    () =>
      map(context.amount_options, ({ name, option_id }) => ({
        label: name,
        value: option_id,
      })),
    [context.amount_options],
  )

  return (
    <>
      <TrineInput
        value_type={value_type}
        options={options}
        option_id={option_id}
        constant={constant}
        label={planSchema.amount_options.label}
        onChange={onOptionChange}
      />
      {value_type === 'id' && (
        <>
          <Box width="100%">保額倍率</Box>
          <InputText
            style={{
              width: '100%',
            }}
            keyfilter="num"
            value={plan_ratio || ''}
            onChange={({ target: { value } }) => {
              onRatioChange(value)
            }}
          />
        </>
      )}
    </>
  )
}

OptionInput.propTypes = {
  option_id: PropTypes.string,
  plan_ratio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onOptionChange: PropTypes.func.isRequired,
  onRatioChange: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
  constant: PropTypes.number,
  value_type: PropTypes.string.isRequired,
}

export function AxisValueInput({ axis_value, factor_values, onChange }) {
  return (
    <Flex alignItems="center">
      <Dropdown
        placeholder={`選擇${axisSchema.axis_value.label}`}
        style={{ width: '100%' }}
        options={factor_values}
        value={axis_value}
        onChange={({ value }) => {
          onChange(value)
        }}
        filter
      />
    </Flex>
  )
}

AxisValueInput.propTypes = {
  axis_value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  factor_values: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export function useTranspile(docs, selectedFields) {
  const benefits = planSchema.benefits.useNodes(docs || [])
  const nodes = useFetchReferencedKG(docs, planSchema, selectedFields, true)
  const referencedList = useMemo(() => [benefits, nodes], [benefits, nodes])

  return useConvertIdToName(docs, referencedList, selectedFields, planSchema)
}

export function useUploadPlanOfTicket() {
  const upload = useUploadDocWithHistory(planSchema, async plans => {
    const {
      data: { uploadPlan },
    } = await cceClient.mutate({
      mutation: _uploadPlan,
      variables: {
        plans,
      },
    })

    return uploadPlan
  })

  return upload
}

export const checkPlan = partial(dataAnalysisService, 'check_plan')

export function updateOptionValue(optionValue, type, value) {
  set(optionValue, 'value_type', type)
  set(optionValue, 'option_id', null)
  set(optionValue, 'constant', null)
  set(optionValue, 'plan_ratio', null)

  if (type === 'id') set(optionValue, 'option_id', value)
  else if (type === 'const') set(optionValue, 'constant', value)

  if (isEmpty(get(optionValue, 'plan_ratio'))) set(optionValue, 'plan_ratio', 1)
}
