import { useState, useMemo, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { map } from 'lodash'
import { Dialog } from 'primereact/dialog'
import { ListBox } from 'primereact/listbox'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { useImmer } from 'use-immer'
import { IoIosCloseCircle } from 'react-icons/io'

import { Flex, Text } from '@changingai/react-editor-common-component'
import { useLazyQueryKG } from '@common'

import { ActionButtons } from './ActionButtons'
import { ButtonLike } from './button'
import { StatusBarRoot } from './StatusBarRoot'

import { _search_uri } from './SelectChannelDialog.gql'

function useSearchURI() {
  const [keyword, setKeyword] = useState()
  const [searchURI, { loading, data }] = useLazyQueryKG(_search_uri, {
    variables: {
      keyword,
    },
  })

  useEffect(() => {
    if (keyword) {
      searchURI()
    }
  }, [keyword, searchURI])

  return [setKeyword, loading, data]
}

const Root = styled.div`
  h3 {
    display: inline;
    margin: 0 0.5em;
  }
  p {
    display: inline;
  }
`

const LabelListBox = styled(Flex)``

LabelListBox.defaultProps = {
  px: 1,
  width: '100%',
  flexWrap: 'wrap',
  justifyContent: 'center',
}

const ListboxCaption = styled(Flex)``

ListboxCaption.defaultProps = {
  bg: 'blacks.8',
  color: 'white',
  height: '30px',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  p: 1,
  fontSize: 'h1',
  fontWeight: 'bold',
}

const StyledListBox = styled(ListBox)`
  && .p-listbox-list-wrapper {
    background-color: AntiqueWhite;
  }
`

function MyChoiceListBox({ selected, onChoice }) {
  const [choice, setChoice] = useImmer([])
  const [current, setCurrent] = useState()

  useEffect(() => {
    if (selected) {
      setChoice(draft => {
        if (!draft.map(({ node_id }) => node_id).includes(selected.node_id)) {
          draft.push(selected)
        }
      })
    }
  }, [selected, setChoice])

  useEffect(() => {
    onChoice(choice)
  }, [onChoice, choice])

  return (
    <LabelListBox>
      <ListboxCaption>Selected</ListboxCaption>
      <StyledListBox
        value={current}
        options={choice}
        onChange={({ value }) => {
          setCurrent(value)
        }}
        optionLabel="node_id"
        itemTemplate={({ name, node_id }) => (
          <Flex justifyContent="space-between">
            <Text>{name}</Text>
            <ButtonLike>
              <IoIosCloseCircle
                style={{ color: 'green', width: '20px', height: '20px' }}
                onClick={() => {
                  setChoice(draft =>
                    draft.filter(node => node.node_id !== node_id),
                  )
                }}
              />
            </ButtonLike>
          </Flex>
        )}
        style={{ width: '100%', flex: '1 0 auto' }}
        listStyle={{ maxHeight: '500px', height: '20vh' }}
      />
    </LabelListBox>
  )
}

MyChoiceListBox.propTypes = {
  selected: PropTypes.shape({
    node_id: PropTypes.string,
    name: PropTypes.string,
  }),
  onChoice: PropTypes.func.isRequired,
}

function KGNodeListBox({ node_type, nodes, onSelected }) {
  const [selected, setSelected] = useState()

  return (
    <LabelListBox>
      <ListboxCaption>{node_type}</ListboxCaption>
      <ListBox
        value={selected}
        options={nodes.map(node => ({ ...node, value: node }))}
        optionLabel="node_id"
        onChange={e => {
          setSelected(e.value)
          onSelected(e.value)
        }}
        itemTemplate={({ name }) => <Flex>{name}</Flex>}
        style={{ width: '100%', flex: '1 0 auto' }}
        listStyle={{ maxHeight: '500px', height: '20vh' }}
      />
    </LabelListBox>
  )
}

KGNodeListBox.propTypes = {
  node_type: PropTypes.oneOf(['channel', 'channel_concept', 'target']),
  nodes: PropTypes.arrayOf(PropTypes.object),
  onSelected: PropTypes.func.isRequired,
}

export function SelectChannelDialog({ onHide, allowed, onOK }) {
  const [text, setText] = useState('')
  const [setKeyword, loading, data] = useSearchURI()
  const [selected, setSelected] = useState()
  const [choice, setChoice] = useState([])

  const group = useMemo(() => {
    const result = {
      channel: [],
      channel_concept: [],
      target: [],
    }

    if (!loading && data) {
      const filtered = data.search_uri.filter(
        node => !node['private'] && !node.tags.includes('entity'),
      )
      result.channel = filtered.filter(
        ({ node_type }) => node_type === 'channel',
      )
      result.channel_concept = filtered.filter(
        ({ node_type }) => node_type === 'channel_concept',
      )
      result.target = filtered.filter(({ node_type }) => node_type === 'target')
    }

    return result
  }, [loading, data])

  const onSelected = useCallback(
    node => {
      setSelected(node)
    },
    [setSelected],
  )

  const onChoice = useCallback(
    nodes => {
      setChoice(nodes)
    },
    [setChoice],
  )

  const widths = ['30vw', '30vw', '50vw', '50vw']
  return (
    <Dialog
      header="Select Nodes"
      appendTo={document.body}
      visible={true}
      style={{ width: widths[allowed.length] }}
      modal={true}
      onHide={() => onHide()}
      contentStyle={{ overflow: 'visible' }}
    >
      <Root>
        <Flex alignItems="center" justifyContent="flex-end">
          <Text fontSize="h1" mr="3">{`請輸入關鍵字: `}</Text>
          <InputText
            value={text}
            onChange={({ target: { value } }) => setText(value)}
            onKeyPress={({ key }) => {
              if (key === 'Enter' && text) setKeyword(text)
            }}
          />
          <Button
            disabled={!text}
            style={{ margin: '0 5px' }}
            icon={loading ? 'pi pi-spin pi-spinner' : 'pi pi-search'}
            className="p-button-rounded"
            onClick={() => setKeyword(text)}
          />
        </Flex>
        <Flex flexWrap="wrap" overflow="auto" width="100%">
          {map(allowed, node_type => (
            <Flex key={node_type} width={`${100 / allowed.length}%`} my="1">
              <KGNodeListBox
                node_type={node_type}
                nodes={group[node_type]}
                onSelected={onSelected}
              />
            </Flex>
          ))}
        </Flex>
        <Flex width="100%" alignItems="stretch">
          <MyChoiceListBox selected={selected} onChoice={onChoice} />
        </Flex>
        <StatusBarRoot>
          <ActionButtons
            buttons={{
              right: [
                {
                  label: 'OK',
                  action: 'OK',
                  className: 'p-button-primary',
                  icon: 'pi pi-check',
                },
                {
                  label: 'Cancel',
                  action: 'Cancel',
                  className: 'p-button-secondary',
                  icon: 'pi pi-times',
                },
              ],
            }}
            onClicked={action => {
              if (action === 'OK') onOK(choice)
              onHide()
            }}
          />
        </StatusBarRoot>
      </Root>
    </Dialog>
  )
}

SelectChannelDialog.propTypes = {
  onHide: PropTypes.func.isRequired,
  allowed: PropTypes.arrayOf(PropTypes.string).isRequired,
  onOK: PropTypes.func.isRequired,
}
