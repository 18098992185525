import { fromPairs, map, includes, has } from 'lodash'
import { SchemaStore } from '../schema/SchemaStore'

export const PrimitiveTypes = [
  'string',
  'node',
  'date',
  'url',
  'bool',
  'number',
  'image',
]

export function computeType(type) {
  if (typeof type !== 'string') {
    throw new Error(`type argument should be a string but get ${typeof type}`)
  }

  const match = type?.match(/^objectOf\((.+)\)$|^arrayOf\((.+)\)$/)
  const objectType = match?.[1]
  const arrayType = match?.[2]
  const extractedType = objectType || arrayType || type
  if (
    !includes(PrimitiveTypes, extractedType) &&
    !has(SchemaStore, extractedType)
  ) {
    throw new Error(`Undefined type: ${extractedType}`)
  }
  return {
    isTypedObject: !!objectType,
    isTypedArray: !!arrayType,
    extractedType,
    extractedSchema: SchemaStore?.[extractedType],
  }
}

export const toPairs = items => fromPairs(map(items, item => [item, item]))

export const ValidationCategory = Object.freeze(
  toPairs(['Pass', 'Error', 'Warning']),
)

export const CardGroupScope = Object.freeze(toPairs(['BANK', 'ISSUER', 'CARD']))
