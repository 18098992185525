import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { isNil } from 'lodash'

import { useDialog } from '@common'

export function EditorLoader(props) {
  const { DialogComp, useFetchDocAndTicket, currentSchema } = props
  const [doc, ticket] = useFetchDocAndTicket()

  const [show, render] = useDialog(DialogComp)
  useEffect(() => {
    if (!isNil(doc)) show(props)
  }, [show, doc, props])

  return (
    <>
      {render({
        doc,
        ticket,
        crud: {
          create: false,
          read: true,
          update: false,
          delete: false,
          overwrite: false,
          review: false,
        },
        currentSchema,
      })}
    </>
  )
}

EditorLoader.propTypes = {
  DialogComp: PropTypes.func.isRequired,
  useFetchDocAndTicket: PropTypes.func.isRequired,
  currentSchema: PropTypes.object.isRequired,
}
