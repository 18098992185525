import styled from 'styled-components'
import { Flex } from '@changingai/react-editor-common-component'

export const StatusBarRoot = styled(Flex)`
  & .p-button {
    margin: ${({ theme }) => `0 ${theme.space[1]}px`};
  }
`

StatusBarRoot.defaultProps = {
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  mt: 2,
  p: 1,
  bg: 'paleGrey',
  border: '1px solid',
  borderColor: 'blacks.3',
}
