import Joi from 'joi'

import { useGetConnectedNodes, KG_NODES, namespaces } from '@common'
import {
  addSchema,
  schemaToPropTypes,
  NullableString,
  shortString,
  RequiredString,
  RequiredMongoIdString,
  RequiredKGNodeIdString,
} from '@schema'

const hyperlinkSchema = {
  text: {
    schema: Joi.string().allow(''),
    type: 'string',
    label: '連結文字',
  },
  link: {
    schema: Joi.alternatives().try(Joi.string().uri(), Joi.string().allow('')),
    type: 'url',
    label: '連結網址',
  },
}

const roots = [
  {
    node_id: KG_NODES.insurance.LIFE_INSURANCE_COMPANY_ROOT_ID,
    namespace: 'insurance',
  },
  {
    node_id: KG_NODES.insurance.PROPERTY_INSURANCE_COMPANY_ROOT_ID,
    namespace: 'insurance',
  },
]
export function useInsuranceCompany() {
  return useGetConnectedNodes(roots)
}

const categoryRoot = [
  {
    node_id: KG_NODES.insurance.TII_CATEGORY_ID,
    namespace: 'insurance',
  },
]
export const tiiSchema = {
  _id: {
    schema: RequiredMongoIdString,
    type: 'string',
    label: '代碼',
    skipCompare: true,
  },
  link: {
    schema: Joi.alternatives().try(Joi.string(), Joi.string().uri()),
    type: 'url',
    label: '保發中心連結',
  },
  company_id: {
    schema: RequiredKGNodeIdString,
    type: 'node',
    label: '發行公司',
    referToKG: 'insurance',
    useNodes: () => useInsuranceCompany(),
  },
  category_id: {
    schema: RequiredKGNodeIdString,
    type: 'node',
    label: '保發分類',
    referToKG: 'insurance',
    useNodes: () => useGetConnectedNodes(categoryRoot),
  },
  product_id: {
    schema: RequiredString.max(shortString),
    type: 'string',
    label: '保險商品編號',
  },
  name: {
    schema: RequiredString.max(shortString),
    type: 'string',
    label: '保險名稱',
  },
  sell_start_date: {
    schema: Joi.alternatives().try(Joi.date(), Joi.string().valid('')),
    type: 'date',
    label: '銷售日',
  },
  sell_end_date: {
    schema: Joi.alternatives().try(Joi.date(), Joi.string().valid('')),
    type: 'date',
    label: '停售日',
  },
  approved_date: {
    schema: Joi.alternatives().try(Joi.date(), Joi.string().valid('')),
    type: 'date',
    label: '核淮/核備/備查日期',
  },
  approved_id: {
    schema: NullableString.max(shortString),
    type: 'string',
    label: '核淮/核備/備查文號',
  },
  contents: {
    schema: Joi.array().required(),
    type: 'arrayOf(hyperlinkSchema)',
    label: '保險商品內容說明',
  },
  contracts: {
    schema: Joi.array().required(),
    type: 'arrayOf(hyperlinkSchema)',
    label: '保單條款',
  },
  proposals: {
    schema: Joi.array().required(),
    type: 'arrayOf(hyperlinkSchema)',
    label: '要保書',
  },
  fees: {
    schema: Joi.array().required(),
    type: 'arrayOf(hyperlinkSchema)',
    label: '費率',
  },
  appended_insurances: {
    schema: Joi.array().required(),
    type: 'arrayOf(hyperlinkSchema)',
    label: '附加保險',
  },
  benefits: {
    schema: Joi.array().required(),
    type: 'arrayOf(hyperlinkSchema)',
    label: '理賠文件',
  },
  registers: {
    schema: Joi.array().required(),
    type: 'arrayOf(hyperlinkSchema)',
    label: '簽署人員名冊',
  },
  status: {
    schema: NullableString.max(shortString),
    type: 'string',
    label: '爬取狀態',
  },
}

addSchema({ tiiSchema }, '保發中心保險商品', namespaces.insurance.value)
addSchema({ hyperlinkSchema })

export const TiiPropType = schemaToPropTypes(tiiSchema)
