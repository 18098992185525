import { useCallback, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import 'styled-components/macro'
import {
  get,
  head,
  isEmpty,
  isEqual,
  isNil,
  map,
  range,
  trim,
  size,
  toString,
} from 'lodash'
import { Flex, Box } from '@changingai/react-editor-common-component'

import { ToastifyLabel, TableInput } from '@widget'

import { PlanContext } from './context'
import { planSchema, numberCellSchema } from './schema'

export function SurrenderRates({ context, onDirty, onChange }) {
  const { isReady } = useContext(PlanContext)

  const fieldname = 'surrender_rates'

  const surRates = useMemo(() => {
    const rates = get(context, fieldname)
    return map(isEmpty(rates) ? Array(20).fill(null) : rates, rate => [rate])
  }, [context])

  const onTableChange = useCallback(
    ({ values }) => {
      const result = map(values, head)
      onDirty(fieldname, !isEqual(result, surRates))
      onChange(fieldname, result)
    },
    [onChange, onDirty, surRates],
  )

  return (
    <Flex alignItems="center" justifyContent="center">
      <ToastifyLabel
        flex="1 200px"
        fieldname={fieldname}
        label={get(planSchema, `${fieldname}.label`)}
      />
      {isReady && !isEmpty(surRates) && (
        <Box width="50vw" height="50vh">
          <TableInput
            schema={numberCellSchema}
            header={['比例']}
            columnHeader={map(range(1, size(surRates) + 1), toString)}
            headerEditable={false}
            values={surRates}
            format={v => (isNil(v) || v === '' ? null : trim(v))}
            onChange={table => onTableChange(table)}
          />
        </Box>
      )}
    </Flex>
  )
}

SurrenderRates.propTypes = {
  context: PropTypes.object.isRequired,
  onDirty: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}
