import {
  every,
  get,
  isFinite,
  isNil,
  join,
  map,
  negate,
  overEvery,
  pick,
  size,
  toNumber,
} from 'lodash'

import { cardSchema } from './schema'

import { ValidationCategory as State } from '@common'

const getLabels = fields => map(fields, f => get(cardSchema, `${f}.label`))

export const allValuesAreNilOrNumber = (doc, keys) => {
  const checker = values =>
    every(values, isNil) || every(values, overEvery([negate(isNil), isFinite]))
  return {
    fieldname: join(getLabels(keys), '/'),
    result: checker(Object.values(pick(doc, keys)))
      ? []
      : [[State.Error, '必需同時為空或同時為數字']],
  }
}

export const compareBoundaryLowerAndUpper = (doc, boundary) => {
  const checker = ([low, high]) => toNumber(low) <= toNumber(high)

  if (size(boundary) !== 2)
    throw 'Error! boundary comparison only accepts two-item boundary'

  return {
    fieldname: join(getLabels(boundary), '/'),
    result: checker(map(boundary, b => get(doc, b)))
      ? []
      : [[State.Error, '最高年費需大於等於最低年費']],
  }
}

export const rules = [
  doc => allValuesAreNilOrNumber(doc, ['fee_1st_l', 'fee_1st_h']),
  doc => allValuesAreNilOrNumber(doc, ['fee_2nd_l', 'fee_2nd_h']),
  doc => compareBoundaryLowerAndUpper(doc, ['fee_1st_l', 'fee_1st_h']),
  doc => compareBoundaryLowerAndUpper(doc, ['fee_2nd_l', 'fee_2nd_h']),
]
