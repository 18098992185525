import { useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Galleria } from 'primereact/galleria'
import { Tag } from 'primereact/tag'

import {
  castArray,
  compact,
  fill,
  get,
  has,
  head,
  isEmpty,
  isFunction,
  isNil,
  join,
  keys,
  map,
  mapValues,
  merge,
  pickBy,
  size,
  partial,
} from 'lodash'
import uuid from 'react-uuid'
import { Box, Flex, Text } from '@changingai/react-editor-common-component'

import { computeType } from '@common'
import { getFieldLabel, toEditableValue } from '@schema'

import { bindLayoutSchema, findLayoutSchema } from './Common'
import { StyledGrid, Header, Cell } from './GridElement'

const layoutMap = {}
export const bindSchemaWithSummaryLayout = partial(bindLayoutSchema, layoutMap)
export const findSummaryLayoutSchema = partial(findLayoutSchema, layoutMap)

const LableStick = styled(Box)`
  background: ${({ isEmptyField }) =>
    isEmptyField ? 'rgba(229,0,111,.4)' : 'rgba(13,152,253,.4)'};
  border-left: 5px solid;
  border-color: ${({ isEmptyField }) =>
    isEmptyField ? 'rgba(229,0,111,.8)' : 'rgba(13,152,253,.8)'};
`

LableStick.defaultProps = {
  mr: '2',
  minWidth: '200px',
  p: 1,
}

export function SummaryLabel({ fieldname, isEmptyField = false }) {
  return (
    <>
      <LableStick my="1" mr="3" isEmptyField={isEmptyField}>
        <Text mx="2" fontWeight="bold">
          {fieldname}
        </Text>
      </LableStick>
      {isEmptyField && <Tag value="空" severity="info" />}
    </>
  )
}

SummaryLabel.propTypes = {
  fieldname: PropTypes.string.isRequired,
  isEmptyField: PropTypes.bool,
}

function ImageField({ currentSchema, fieldname, value, context }) {
  const label = getFieldLabel(currentSchema, context, fieldname)
  const imgRef = useRef()
  // Depends on the fact that we store smaller image first in image list,
  // only present the smaller one in the listview.
  const url = Array.isArray(value) ? get(value, 0) : value
  return (
    <Flex flexWrap="wrap">
      <Flex flex="1 0 100%" alignItems="center">
        <SummaryLabel fieldname={label} />
        <Box color="blacks.5">{`${size(value)} image`}</Box>
      </Flex>

      <Flex flexWrap="wrap" px="2">
        {url && (
          <>
            <Galleria
              value={[{ url }]}
              ref={imgRef}
              fullScreen
              style={{ maxWidth: '50%' }}
              showThumbnails={false}
              item={({ url }) => <img src={url} alt={'Downloading'}></img>}
            />
            <img
              src={url}
              alt={'Downloading'}
              style={{ width: '20%', height: '100%' }}
              onClick={() => imgRef.current.show()}
            />
          </>
        )}
      </Flex>
    </Flex>
  )
}

ImageField.propTypes = {
  currentSchema: PropTypes.object.isRequired,
  fieldname: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  context: PropTypes.object.isRequired,
}

function UrlField({ currentSchema, fieldname, value, context }) {
  const compactValue = compact(value)
  const isArray = useMemo(
    () => computeType(currentSchema[fieldname].type).isTypedArray,
    [currentSchema, fieldname],
  )
  return (
    <Flex alignItems="center">
      <SummaryLabel
        fieldname={getFieldLabel(currentSchema, context, fieldname)}
        isEmptyField={isEmpty(compactValue)}
      />
      {isArray && !isEmpty(compactValue) && (
        <ul style={{ margin: '0' }}>
          {map(compactValue, url => (
            <li key={url}>
              <a href={url} target="_blank" rel="noopener noreferrer">
                {new URL(value).hostname}
              </a>
            </li>
          ))}
        </ul>
      )}
      {!isArray && !isEmpty(compactValue) && (
        <a href={head(compactValue)} target="_blank" rel="noopener noreferrer">
          {new URL(head(compactValue)).hostname}
        </a>
      )}
    </Flex>
  )
}

UrlField.propTypes = {
  currentSchema: PropTypes.object.isRequired,
  fieldname: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  context: PropTypes.object.isRequired,
}

function BoolField({ currentSchema, fieldname, value, context }) {
  return (
    <Flex alignItems="center">
      <SummaryLabel
        fieldname={getFieldLabel(currentSchema, context, fieldname)}
        isEmptyField={isNil(value)}
      />
      {value === true && <Tag value="TRUE" severity="success" />}
      {value === false && <Tag value="FALSE" severity="success" />}
    </Flex>
  )
}

BoolField.propTypes = {
  currentSchema: PropTypes.object.isRequired,
  fieldname: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  context: PropTypes.object.isRequired,
}

function NumberField({ currentSchema, fieldname, value, context }) {
  return (
    <Flex alignItems="center">
      <SummaryLabel
        fieldname={getFieldLabel(currentSchema, context, fieldname)}
        isEmptyField={isNil(value)}
      />
      <Box p="0">{join(value, ', ')}</Box>
    </Flex>
  )
}

NumberField.propTypes = {
  currentSchema: PropTypes.object.isRequired,
  fieldname: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.number),
  context: PropTypes.object.isRequired,
}

const ONE_COL_MAX_ITEMS = 5

function StringField({ currentSchema, fieldname, value, context }) {
  const isArray = useMemo(
    () => computeType(currentSchema[fieldname].type).isTypedArray,
    [currentSchema, fieldname],
  )
  return (
    <Flex data-testid="GenericField" alignItems="center">
      <SummaryLabel
        fieldname={getFieldLabel(currentSchema, context, fieldname)}
        isEmptyField={isEmpty(compact(value))}
      />
      {isArray && !isEmpty(compact(value)) && (
        <ul
          style={{
            margin: 0,
            width: '100%',
            columns: size(value) > ONE_COL_MAX_ITEMS ? 2 : 1,
          }}
        >
          {map(value, v => (
            <li key={uuid()}>{v}</li>
          ))}
        </ul>
      )}
      {!isArray && (
        <Box p="0">{`${toEditableValue(fieldname, value, currentSchema)}`}</Box>
      )}
    </Flex>
  )
}

StringField.propTypes = {
  currentSchema: PropTypes.object.isRequired,
  fieldname: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  context: PropTypes.object.isRequired,
}

export function renderGridHeader(schema, keyOrder = []) {
  return isEmpty(keyOrder)
    ? map(schema, ({ label }, key) => <Header key={key}>{label}</Header>)
    : map(keyOrder, key => (
        <Header key={key}>{get(schema, `${key}.label`)}</Header>
      ))
}

function SchemaField({
  context,
  currentSchema,
  layoutSchema,
  fieldname,
  value,
}) {
  const { extractedSchema } = computeType(currentSchema[fieldname].type)
  const fieldCount = size(keys(extractedSchema))

  return (
    <>
      <Flex width="100%" alignItems="center">
        <SummaryLabel
          fieldname={getFieldLabel(currentSchema, context, fieldname)}
          isEmptyField={isEmpty(value)}
        />
      </Flex>
      {!isEmpty(castArray(value)) && (
        <StyledGrid
          gridTemplateColumns={get(
            layoutSchema[fieldname],
            'gridTemplateColumns',
            join(fill(Array(fieldCount), '1fr'), ' '),
          )}
        >
          {!has(layoutSchema[fieldname], 'gridRenderer') &&
            renderGridHeader(extractedSchema)}
          {has(layoutSchema[fieldname], 'gridRenderer')
            ? layoutSchema[fieldname].gridRenderer({
                context,
                values: castArray(value),
              })
            : map(castArray(value), (item, index) =>
                map(keys(extractedSchema), key => (
                  <Cell key={`${index}-${key}`}>
                    {join(castArray(get(item, key, ''), ', '))}
                  </Cell>
                )),
              )}
        </StyledGrid>
      )}
    </>
  )
}

SchemaField.propTypes = {
  context: PropTypes.object.isRequired,
  currentSchema: PropTypes.object.isRequired,
  layoutSchema: PropTypes.object.isRequired,
  fieldname: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
}

function GenericField(props) {
  const { currentSchema, fieldname, value } = props
  const { extractedType, extractedSchema } = computeType(
    currentSchema[fieldname].type,
  )
  if (!isNil(extractedSchema)) {
    return <SchemaField {...props} />
  }
  const skipRender = get(
    findSummaryLayoutSchema(currentSchema),
    `${fieldname}.skipRender`,
    false,
  )
  if (skipRender) return null
  switch (extractedType) {
    case 'string':
    case 'node':
    case 'date':
      return <StringField {...props} value={castArray(value)} />
    case 'url':
      return <UrlField {...props} value={castArray(value)} />
    case 'bool':
      return <BoolField {...props} />
    case 'number':
      return <NumberField {...props} value={castArray(value)} />
    case 'image':
      return <ImageField {...props} value={castArray(value)} />
    default:
      throw new Error(`unsupported field type ${extractedType}/${fieldname}`)
  }
}

GenericField.propTypes = {
  currentSchema: PropTypes.object.isRequired,
  layoutSchema: PropTypes.object.isRequired,
  fieldname: PropTypes.string.isRequired,
  value: PropTypes.any,
  context: PropTypes.object.isRequired,
}

const SummaryItem = styled(Box)``

SummaryItem.defaultProps = {
  px: 1,
  borderTop: '.75px solid lightgrey',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}

export function createLayoutSchema(schema) {
  const widerLayout = mapValues(
    pickBy(schema, ({ type }) => {
      const { extractedType, extractedSchema, isTypedArray } = computeType(type)
      return (
        (isTypedArray && !isNil(extractedSchema)) || extractedType === 'url'
      )
    }),
    () => ({ cols: 2 }),
  )
  const higherLayout = mapValues(
    pickBy(schema, ({ type }) => computeType(type).extractedType === 'image'),
    () => ({ rows: 2 }),
  )
  return merge(findSummaryLayoutSchema(schema), widerLayout, higherLayout)
}

export function SummaryField({
  layoutSchema,
  currentSchema,
  fieldname,
  value,
  context,
}) {
  const FieldComp = useMemo(() => {
    const renderer = get(layoutSchema, `${fieldname}.renderer`, 'others')
    return isFunction(renderer) ? renderer : GenericField
  }, [layoutSchema, fieldname])

  const style = useMemo(() => {
    const colSpan = {
      gridColumn: `auto / span ${get(layoutSchema, `${fieldname}.cols`, 1)}`,
    }
    const rowSpan = {
      gridRow: `auto / span ${get(layoutSchema, `${fieldname}.rows`, 1)}`,
    }
    return {
      ...colSpan,
      ...rowSpan,
    }
  }, [fieldname, layoutSchema])

  return (
    <SummaryItem {...style}>
      <FieldComp
        currentSchema={currentSchema}
        layoutSchema={layoutSchema}
        fieldname={fieldname}
        value={value}
        context={context}
      />
    </SummaryItem>
  )
}

SummaryField.propTypes = {
  currentSchema: PropTypes.object.isRequired,
  fieldname: PropTypes.string.isRequired,
  value: PropTypes.any,
  context: PropTypes.object.isRequired,
  layoutSchema: PropTypes.object.isRequired,
}
