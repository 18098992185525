import { useCallback, useContext, useMemo } from 'react'
import uuid from 'react-uuid'
import { castArray, find, get, intersection, isNil, map } from 'lodash'

import {
  ProjectContext,
  TicketPropType,
  TicketState,
  getActiveState,
  isLocalDoc,
  useDialog,
} from '@common'
import { createBlankDoc, purifySchemaDoc } from '@schema'
import { cceClient } from '@gql'

import { docPrivilege } from './config'

import { CardRewardContext, useCardRewardContext } from './context'
import { CardRewardEditDialog } from './editor'
import { useTranspile, useUploadCardReward } from './common'
import { createCardSet, useEditable } from './RewardUtils'
import { cardRewardSchema } from './schema'

import TicketSummaryModel from '../TicketSummaryModel'
import { useValidFieldsBySchema } from '../SummaryListView'
import { useFetchDocOfTicket, createBlankTicketable } from '../TicketHelper'
import { useCacheDocs } from '../Common'
import { cloneTicketDoc } from '../DocHelper'
import { useDeleteDocWithHistory } from '../Uploader'
import { bindSchemaWithSummaryLayout } from '../SummaryFields'

import { _removeRewardCards, _queryRewardCard } from './listview.gql'

bindSchemaWithSummaryLayout(cardRewardSchema, {
  detail: {
    cols: 2,
  },
})

const fullAccessStates = [TicketState.MERGE, TicketState.FINISH]
function useController(ticket, selectedFields) {
  const rewards = useFetchDocOfTicket(ticket, cardRewardSchema, selectedFields)
  const { email, peepMode } = useContext(ProjectContext)
  const { updated, deleteCache, updateCache, docs } = useCacheDocs(
    email,
    peepMode,
    ticket,
    rewards,
    fullAccessStates,
  )

  const { isReady: isContextReady, cardGroups } = useContext(CardRewardContext)
  const cardGroup = useMemo(
    () => find(cardGroups, { id: get(ticket, 'scope_id') }),
    [cardGroups, ticket],
  )
  const cardSetDefault = useCallback(
    () => ({
      include_card_sets: [
        createCardSet(
          ticket.scope_id,
          cardGroup.card_provider_id,
          cardGroup.scope,
        ),
      ],
      exclude_card_sets: [],
    }),
    [ticket.scope_id, cardGroup],
  )

  const createDoc = useCallback(
    uploader => {
      const reward = {
        ...createBlankDoc(cardRewardSchema, true),
        effective_date_b: new Date(new Date().getFullYear(), 0, 1),
        effective_date_e: new Date(new Date().getFullYear(), 11, 31),
        ...createBlankTicketable(ticket, uploader),
        ...cardSetDefault(),
      }

      updateCache(reward)
      return reward
    },
    [cardSetDefault, ticket, updateCache],
  )

  const importDocs = useCallback(
    (docs, uploader) => {
      docs
        .map(doc => {
          const result = {
            ...doc,
            ...createBlankTicketable(ticket, uploader),
            ...cardSetDefault(),
            _id: uuid(),
          }
          delete result.matched_target
          delete result.matched_state
          return result
        })
        // TBD: [MYCC-2008]
        // instead of purify it, we should break this import and tell
        // the one who import this file what's wrong.
        .map(doc => purifySchemaDoc(doc, cardRewardSchema))
        .forEach(doc =>
          updateCache({
            ...doc,
            local_doc: true,
          }),
        )
    },
    [updateCache, ticket, cardSetDefault],
  )

  const transformExportedDocs = useCallback(
    docs =>
      map(docs, doc => ({
        ...doc,
        include_card_sets: [],
        exclude_card_sets: [],
      })),
    [],
  )

  const deleteReward = useDeleteDocWithHistory(cardRewardSchema, ids => {
    cceClient.mutate({
      mutation: _removeRewardCards,
      variables: {
        ids: castArray(ids),
      },
    })
  })

  const deleteDoc = useCallback(
    _id => {
      deleteCache(_id)
      const deleted = find(docs, { _id })
      if (isNil(deleted) || isLocalDoc(deleted)) return
      return deleteReward(deleted)
    },
    [deleteCache, docs, deleteReward],
  )

  const cloneDoc = useCallback(
    async doc => {
      const cloned = await cloneTicketDoc(
        _queryRewardCard,
        doc,
        ticket,
        email,
        cardRewardSchema,
      )

      updateCache(cloned)
    },
    [updateCache, email, ticket],
  )

  const [showDialog, renderDialog] = useDialog(CardRewardEditDialog)
  const showDoc = useCallback(
    (doc, crud) => {
      showDialog({
        doc,
        ticket,
        crud,
        updateMatched: !getActiveState(ticket).assignee_as_editor,
      })
    },
    [ticket, showDialog],
  )

  const upload = useUploadCardReward()
  const uploadDoc = useCallback(
    context => upload(ticket, context, email, true),
    [upload, ticket, email],
  )

  return {
    isReady: !isNil(rewards) && isContextReady,
    docs,
    createDoc,
    updateDoc: updateCache,
    deleteDoc,
    importDocs,
    transformExportedDocs,
    cloneDoc,
    showDoc,
    renderDoc: renderDialog,
    updated,
    uploadDoc,
  }
}

function useValidFields(fields, currentSchema) {
  const schemaValidate = useValidFieldsBySchema(fields, currentSchema)
  const isEditable = useEditable()
  const validFields = useCallback(
    doc =>
      intersection(
        fields.filter(field => isEditable(doc, field)),
        schemaValidate(),
      ),
    [fields, isEditable, schemaValidate],
  )

  return validFields
}

const defaultSummary = {
  selected: [
    'type_id',
    'uploader',
    'basic_r',
    'csp_channel_ids',
    'csp_target_ids',
    're_channel_ids',
    're_target_ids',
  ],
  filters: {},
}

const CardRewardListView = props => {
  const context = useCardRewardContext()
  return (
    <CardRewardContext.Provider value={context}>
      <TicketSummaryModel
        {...props}
        useController={useController}
        useTranspile={useTranspile}
        useValidFields={useValidFields}
        defaultSummary={defaultSummary}
        requiredPrivilege={[docPrivilege]}
        currentSchema={cardRewardSchema}
      />
    </CardRewardContext.Provider>
  )
}

CardRewardListView.propTypes = {
  ticket: TicketPropType.isRequired,
}

export default CardRewardListView
