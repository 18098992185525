import { useRef, useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import uuid from 'react-uuid'
import 'styled-components/macro'
import styled from 'styled-components'
import {
  values,
  isEmpty,
  flatten,
  uniq,
  find,
  map,
  get,
  isArray,
  toNumber,
  partial,
} from 'lodash'
import { diffChars } from 'diff'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Slider } from 'primereact/slider'
import { useLocalStorage, useDebounce } from '@changing-cc/hooks'
import {
  Box,
  Flex,
  Text,
  StyledOverlay,
} from '@changingai/react-editor-common-component'

import { useQueryKG } from '@common'
import { cceClient } from '@gql'
import { ScrollBar, Card } from '@widget'
import { dataAnalysisService } from '@remote'

import { BenefitPropType, benefitSchema } from './schema'

import { _queryBenefit, _query_kg } from './pick.gql'

const Caption = styled(Box)``

Caption.defaultProps = {
  width: '100%',
  textAlign: 'center',
  color: 'white',
  p: 1,
  bg: 'blacks.8',
}

const findBenefit = partial(dataAnalysisService, 'find_benefit')
function BenefitDiff({ origin, candidate, onSelect }) {
  const [ltor, rtol] = useMemo(
    () => [
      diffChars(candidate.clause_main, origin.clause_main),
      diffChars(origin.clause_main, candidate.clause_main),
    ],
    [origin.clause_main, candidate.clause_main],
  )
  return (
    <Flex flex="1 0 100%" flexWrap="wrap" my="2">
      <Box
        flex="1 1 50%"
        borderBottom="1px solid gray"
        borderRight="1px solid gray"
      >
        <Caption>Current</Caption>
        <Box p={1}>
          {ltor.map((part, index) =>
            part.removed ? null : (
              <Box
                display="inline"
                color={part.added ? 'red' : 'black'}
                key={index}
              >
                {part.value}
              </Box>
            ),
          )}
        </Box>
      </Box>
      <Box flex="1 1 50%" borderBottom="1px solid gray">
        <Caption>Candidate</Caption>
        <Box p={1}>
          {rtol.map((part, index) => {
            if (part.removed) return null
            return (
              <Box
                display="inline"
                color={part.added ? 'red' : 'black'}
                key={index}
              >
                {part.value}
              </Box>
            )
          })}
        </Box>
      </Box>
      <Flex flex="1 1 100%" borderBottom="1px solid gray" p="3">
        <Box flex="0 0 200px" textAlign="center" fontSize="h1">
          {benefitSchema.pay_method.label}
        </Box>
        <Flex
          flex="1 0"
          alignItems="center"
          border="1px solid gray"
          bg="blacks.0"
        >
          {candidate.pay_method}
        </Flex>
      </Flex>
      <Flex flex="1 1 100%" justifyContent="flex-end" my="2">
        <Button
          label="Select"
          className="p-button-primary"
          onClick={() => onSelect(candidate)}
        />
      </Flex>
    </Flex>
  )
}

BenefitDiff.propTypes = {
  origin: BenefitPropType.isRequired,
  candidate: BenefitPropType.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export function PickBenefitDialog({ benefit, onHide, onPick }) {
  const [allowDiff, setAllowDiff, , isLoading] = useLocalStorage(
    'findBenefit.allowDiff',
    0,
  )
  const [error, setError] = useState()
  const debounced = useDebounce(allowDiff)
  const [fetching, setFetching] = useState(false)
  const [candidates, setCandidates] = useState([])

  const downloadId = useRef()
  useEffect(() => {
    async function downloadCandidates(doc_ids) {
      try {
        const {
          data: { queryBenefit },
        } = await cceClient.query({
          query: _queryBenefit,
          variables: {
            doc_ids,
          },
        })

        setCandidates(
          doc_ids
            .map(id => queryBenefit.find(({ _id }) => _id === id))
            .filter(benefit => !isEmpty(benefit)),
        )
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
        setCandidates([])
      }

      setFetching(false)
    }
    if (isLoading || debounced === 0) return

    const currentDownloadId = uuid()
    downloadId.current = currentDownloadId
    setFetching(true)
    findBenefit({ doc: benefit, allow_diff: toNumber(debounced) })
      .then(response => response.json())
      .then(ids => {
        if (get(ids, 'status') === 'ERROR') {
          setError(ids)
          setCandidates([])
          setFetching(false)
        } else if (currentDownloadId === downloadId.current && isArray(ids)) {
          setError(null)
          const benefitIds = ids.filter(id => id !== benefit._id)
          if (isEmpty(benefitIds)) {
            setCandidates([])
            setFetching(false)
          } else downloadCandidates(benefitIds)
        }
      })
  }, [benefit, debounced, setFetching, setCandidates, isLoading])

  const onSelect = useCallback(
    candidate => {
      onPick(candidate)
      onHide()
    },
    [onPick, onHide],
  )

  return (
    <Dialog
      header="Pick a benefit"
      appendTo={document.body}
      visible={true}
      style={{ width: '1200px' }}
      contentStyle={{ position: 'relative' }}
      modal={true}
      onHide={onHide}
    >
      <Flex
        css={ScrollBar}
        flexWrap="wrap"
        height="60vh"
        alignItems="flex-start"
        alignContent="flex-start"
      >
        <StyledOverlay active={fetching} />
        {!fetching &&
          !isEmpty(candidates) &&
          candidates.map(candidate => (
            <BenefitDiff
              key={candidate._id}
              origin={benefit}
              candidate={candidate}
              onSelect={onSelect}
            />
          ))}
        {!fetching && isEmpty(candidates) && !error && (
          <Box fontSize="h1">無類似Benefit</Box>
        )}
        {error && <Box fontSize="h1">{error.reason}</Box>}
      </Flex>
      <Flex width="100%" justifyContent="flex-end" py="2" alignItems="center">
        <Box width="200px" mr="2" textAlign="right">
          {`Match count: ${candidates.length}`}
        </Box>
        <Box width="200px" m="2">
          <InputText
            value={allowDiff}
            onChange={({ target: { value } }) => {
              setAllowDiff(value)
            }}
          />
          <Slider
            value={allowDiff}
            onChange={({ value }) => {
              setAllowDiff(value)
            }}
          />
        </Box>
      </Flex>
    </Dialog>
  )
}

PickBenefitDialog.propTypes = {
  onHide: PropTypes.func.isRequired,
  onPick: PropTypes.func.isRequired,
  benefit: BenefitPropType.isRequired,
}

function BenefitSuggestion({ suggestion, onSelect }) {
  const [count, tags, ids, detail] = useMemo(() => {
    const { count, detail, ...tags } = suggestion
    return [count, tags, uniq(flatten(values(tags))), detail]
  }, [suggestion])

  const { data } = useQueryKG(_query_kg, {
    variables: {
      query: {
        op: 'OR',
        conditions: [
          {
            op: 'includes',
            field: 'node_id',
            values: ids,
          },
        ],
      },
    },
    skip: isEmpty(ids),
  })

  const rows = useMemo(
    () =>
      Object.keys(tags).map(key => [
        key,
        !data || !tags
          ? []
          : tags[key].map(node_id =>
              get(find(data.query_kg, { node_id }), 'name'),
            ),
      ]),
    [data, tags],
  )

  const [showDetail, setShowDetail] = useState(false)

  return (
    <Card>
      <Flex width="100%" flexWrap="wrap">
        <Flex justifyContent="flex-end" width="100%">
          <Text fontSize="h1">{`次數：${count}`}</Text>
        </Flex>
        {map(rows, ([key, names]) => (
          <Flex
            key={key}
            width="100%"
            mb="1"
            borderBottom="1px solid lightgray"
          >
            <Box width="200px">{`${benefitSchema[key].label}: `}</Box>
            <Box>{`${names.join(', ')}`}</Box>
          </Flex>
        ))}
        <Flex width="100%">{showDetail && <Box>{detail}</Box>}</Flex>
        <Flex width="100%" justifyContent="flex-end">
          <Button
            style={{ marginRight: '5px' }}
            label="Select"
            className="p-button-primary"
            onClick={() => onSelect(suggestion)}
          />
          <Button
            label="Detail"
            className="p-button-secondary"
            onClick={() => setShowDetail(!showDetail)}
          />
        </Flex>
      </Flex>
    </Card>
  )
}

BenefitSuggestion.propTypes = {
  suggestion: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
}
