import { useMemo, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { map, get, isEmpty, forEach, isNil } from 'lodash'

import { toast } from 'react-toastify'
import { Button } from 'primereact/button'

import { dataAnalysisService } from '@remote'
import { Flex, Text } from '@changingai/react-editor-common-component'
import {
  inputFactory,
  createSeparator,
  useRenderConfig,
  generateSubDocProps,
} from '@widget'

import {
  benefitConceptPaybackSchema,
  BenefitConceptPaybackPropType,
  BenefitPropType,
  benefitSchema,
} from './schema'

function hintText(text) {
  return (
    <Text
      flex="1 200px"
      color="reddishOranges.9"
      flexShrink="0"
      width="100px"
      textAlign="left"
    >
      {text}
    </Text>
  )
}

const fullContext = {
  data: {},
  get context() {
    return this.data
  },
  set context(ctx) {
    this.data = ctx
  },
}

function AutoFill({ context, onChange }) {
  const invalidInput = useMemo(() => {
    return !get(context, 'benefit_concept_id')
  }, [context])

  const reset = useCallback(
    () =>
      forEach(Object.keys(benefitConceptPaybackSchema), field => {
        if (field !== 'benefit_concept_id') onChange(field, null)
      }),
    [onChange],
  )

  const onFetchSuggestion = useCallback(async () => {
    const response = await dataAnalysisService('suggest_bc_amount_ratio', {
      doc: fullContext.context,
      bc_id: get(context, 'benefit_concept_id'),
    })
    const { suggestion, hint } = await response.json()
    if (response.ok) {
      if (!isNil(suggestion)) {
        if (!isEmpty(hint))
          toast.warn(hint.join(''), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          })
        forEach(suggestion, (v, k) => {
          if (!isNil(v)) onChange(k, v)
        })
      }
    }
  }, [context, onChange])

  return (
    <Flex width="100%" flexWrap="wrap" justifyContent="flex-end">
      <Button
        label={'清除填值'}
        className="p-button-warning"
        icon="pi pi-undo"
        onClick={reset}
        style={{ marginRight: '5px' }}
      />
      <Button
        disabled={invalidInput}
        label={`自動填值${invalidInput ? '(公版不可為空)' : ''}`}
        className="p-button-help"
        icon="pi pi-cloud-download"
        onClick={onFetchSuggestion}
      />
    </Flex>
  )
}

AutoFill.propTypes = {
  context: BenefitConceptPaybackPropType.isRequired,
  onChange: PropTypes.func.isRequired,
}

function BenefitConceptPayback({
  context,
  onDirty,
  onUpdateContext,
  onError,
  currentPath,
}) {
  const renderer = useRenderConfig({
    onError,
    onUpdateContext,
    onDirty,
    editContext: context,
    currentSchema: benefitConceptPaybackSchema,
    currentPath,
  })

  const showLabelOnRightAttr = useMemo(
    () => ({
      labelAttributes: {
        showOnRight: true,
      },
    }),
    [],
  )

  const configBasic = useMemo(
    () => [
      {
        widget: 'CustomizedInput',
        RenderComp: AutoFill,
      },
      inputFactory(benefitConceptPaybackSchema, 'benefit_concept_id', {
        inputAttributes: {
          maxHeight: '600px',
          itemPadding: 'small',
        },
      }),
      inputFactory(benefitConceptPaybackSchema, 'prefix'),
      inputFactory(benefitConceptPaybackSchema, 'ratio'),
      inputFactory(benefitConceptPaybackSchema, 'suffix'),
      createSeparator({}),
    ],
    [],
  )
  const configJoinConditions = useMemo(
    () => [
      inputFactory(
        benefitConceptPaybackSchema,
        'join_premium',
        showLabelOnRightAttr,
      ),
      inputFactory(
        benefitConceptPaybackSchema,
        'join_policy_value',
        showLabelOnRightAttr,
      ),
      createSeparator({}),
    ],
    [showLabelOnRightAttr],
  )

  const configAtMostConditions = useMemo(
    () => [
      inputFactory(
        benefitConceptPaybackSchema,
        'limited_payback',
        showLabelOnRightAttr,
      ),
      inputFactory(
        benefitConceptPaybackSchema,
        'depend_condition',
        showLabelOnRightAttr,
      ),
      inputFactory(
        benefitConceptPaybackSchema,
        'reduce_previous_payback',
        showLabelOnRightAttr,
      ),
      inputFactory(
        benefitConceptPaybackSchema,
        'limited_insured_scope',
        showLabelOnRightAttr,
      ),
      createSeparator({}),
    ],
    [showLabelOnRightAttr],
  )

  const configBonusConditions = useMemo(
    () => [
      inputFactory(
        benefitConceptPaybackSchema,
        'has_special_bonus',
        showLabelOnRightAttr,
      ),
      inputFactory(
        benefitConceptPaybackSchema,
        'return_unused_premium',
        showLabelOnRightAttr,
      ),
    ],
    [showLabelOnRightAttr],
  )

  const configHasETCConditions = useMemo(
    () => [
      inputFactory(
        benefitConceptPaybackSchema,
        'has_similar_payback',
        showLabelOnRightAttr,
      ),
    ],
    [showLabelOnRightAttr],
  )

  return (
    <Flex width="100%" flexWrap="wrap">
      {map(configBasic, renderer)}
      {hintText('要再與下列取大值')}
      {map(configJoinConditions, renderer)}
      {hintText('僅代表最高理賠(恐低於此金額)')}
      {map(configAtMostConditions, renderer)}
      {hintText('還有更高的理賠(但沒有收)')}
      {map(configBonusConditions, renderer)}
      {hintText('無條件加入"等"')}
      {map(configHasETCConditions, renderer)}
    </Flex>
  )
}

BenefitConceptPayback.propTypes = {
  context: BenefitConceptPaybackPropType.isRequired,
  onChange: PropTypes.func.isRequired,
  onDirty: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onUpdateContext: PropTypes.func.isRequired,
  onValid: PropTypes.func.isRequired,
  currentPath: PropTypes.arrayOf(PropTypes.string).isRequired,
  meta: PropTypes.object,
  itemConfigs: PropTypes.array,
}

/* AutoFill needs full context of benefit, so we use this wrap to pick
it first but not render SubDoc in editors.js */
export function BenefitConceptPaybacksWrap({
  context,
  onDirty,
  onUpdateContext,
  onError,
}) {
  const renderer = useRenderConfig({
    onError,
    onUpdateContext,
    onDirty,
    editContext: context,
    currentSchema: benefitSchema,
    currentPath: ['benefitSchema'],
  })

  useEffect(() => {
    fullContext.context = context
  }, [context])

  const configs = useMemo(
    () => [
      {
        ...generateSubDocProps(benefitSchema, 'benefit_concept_paybacks'),
        itemConfigs: [
          {
            widget: 'CustomizedInput',
            RenderComp: BenefitConceptPayback,
          },
        ],
      },
    ],
    [],
  )

  return (
    <Flex width="100%" flexWrap="wrap">
      {map(configs, renderer)}
    </Flex>
  )
}

BenefitConceptPaybacksWrap.propTypes = {
  context: BenefitPropType.isRequired,
  onChange: PropTypes.func.isRequired,
  onDirty: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onUpdateContext: PropTypes.func.isRequired,
  onValid: PropTypes.func.isRequired,
}
