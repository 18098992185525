import PropTypes from 'prop-types'
import { FiSquare, FiCheckSquare } from 'react-icons/fi'
import { ButtonLike } from './button'

export function Checkbox({
  icons = [FiCheckSquare, FiSquare],
  checked,
  onChecked,
  color = 'white',
  disableColor = color,
  text,
  textColor,
  width = 6,
  height = 6,
  disabled = false,
}) {
  const [On, Off] = icons
  return (
    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
      <ButtonLike
        margin="0 5px"
        width={width}
        height={height}
        disabled={disabled}
      >
        {checked && (
          <On
            style={{
              color,
              width: '100%',
              height: '100%',
            }}
            onClick={() => !disabled && onChecked && onChecked(false)}
          />
        )}
        {!checked && (
          <Off
            style={{
              color: disableColor,
              width: '100%',
              height: '100%',
            }}
            onClick={() => !disabled && onChecked && onChecked(true)}
          />
        )}
      </ButtonLike>
      {text && (
        <div style={{ color: textColor ? textColor : color }}>{text}</div>
      )}
    </div>
  )
}

Checkbox.propTypes = {
  icons: PropTypes.arrayOf(PropTypes.func),
  checked: PropTypes.bool.isRequired,
  onChecked: PropTypes.func,
  color: PropTypes.string,
  disableColor: PropTypes.string,
  text: PropTypes.string,
  textColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  disabled: PropTypes.bool,
}
