import { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import 'styled-components/macro'
import { useMutation } from '@apollo/client'
import { Dialog } from 'primereact/dialog'
import { TabView, TabPanel } from 'primereact/tabview'
import { find, map } from 'lodash'
import { Box } from '@changingai/react-editor-common-component'

import { cceClient } from '@gql'
import { TicketSubState, getActiveState } from '@common'
import { ActionButtons, StatusBarRoot } from '@widget'

import { getNamespaceByScopeType, useTicketVersion } from '../TicketHelper'

import { _queryTickets, _assignTickets, _rollbackTickets } from './import.gql'

export function ImportErrorDialog({
  errors: { names, states, assignee },
  onHide,
}) {
  return (
    <Dialog
      header="Import Error"
      appendTo={document.body}
      visible={true}
      style={{ width: '800px' }}
      modal={true}
      onHide={onHide}
    >
      <Box fontSize="h1" p="1">
        CSV格式：TicketName,State,Assignees
      </Box>
      <TabView>
        <TabPanel header="錯誤的Ticket名稱">
          <Box overflow="auto" height="50vh">
            <Box fontSize="h1" my="3">
              以下Ticket名稱不存在
            </Box>
            {names.map(name => (
              <Box width="100%" key={name}>
                {name}
              </Box>
            ))}
          </Box>
        </TabPanel>
        <TabPanel header="錯誤的Ticket State">
          <Box overflow="auto" height="50vh">
            <Box fontSize="h1" my="3">
              以下Ticket State錯誤
            </Box>
            {states.map(([_id, state, currentState]) => (
              <Box width="100%" key={_id}>
                {`id: ${_id}, 設定狀態: ${state}, 目前狀態: ${currentState}`}
              </Box>
            ))}
          </Box>
        </TabPanel>
        <TabPanel header="分配人數錯誤">
          <Box overflow="auto" height="50vh">
            <Box fontSize="h1" my="3">
              以下分配人數錯誤
            </Box>
            {assignee.map(([_id, name, assignees, quota]) => (
              <Box width="100%" key={_id}>
                {`name: ${name}, 配置人數: ${assignees.length}, 允許人數: ${quota}`}
              </Box>
            ))}
          </Box>
        </TabPanel>
      </TabView>

      <StatusBarRoot justifyContent="flex-end" py={2}>
        <ActionButtons
          buttons={{
            right: [
              {
                label: 'Close',
                action: 'Close',
                className: 'p-button-secondary',
              },
            ],
          }}
          onClicked={() => {
            onHide()
          }}
        />
      </StatusBarRoot>
    </Dialog>
  )
}

ImportErrorDialog.propTypes = {
  errors: PropTypes.shape({
    names: PropTypes.array.isRequired,
    states: PropTypes.array.isRequired,
    assignee: PropTypes.array.isRequired,
  }).isRequired,
  onHide: PropTypes.func.isRequired,
}

export function useImportCSV(ticketType) {
  const [assignTickets] = useMutation(_assignTickets)
  const [rollbackTickets] = useMutation(_rollbackTickets)
  const [errors, setErrors] = useState()
  const version = useTicketVersion(ticketType)
  const namespace = useMemo(() => getNamespaceByScopeType(ticketType), [
    ticketType,
  ])
  const importFromCSV = useCallback(
    async rows => {
      setErrors(null)
      async function validate(rows) {
        const errors = {
          names: [],
          states: [],
          assignee: [],
        }

        const ticket_ids = map(rows, '0')
        const {
          data: { queryTickets },
        } = await cceClient.query({
          query: _queryTickets,
          variables: {
            filter: {
              ticket_ids,
              version,
            },
            namespace,
          },
        })

        rows.forEach(([_id, name, state, ...assignees]) => {
          const current = find(queryTickets, { _id })
          if (!current) errors.names.push(name)
          else {
            if (current.active_state !== state)
              errors.states.push([name, state, current.active_state])
            const quota = getActiveState(current).assignee_quota
            if (quota !== assignees.length)
              errors.assignee.push([_id, name, assignees, quota])
          }
        })

        return [errors, queryTickets]
      }

      const [errors, tickets] = await validate(rows)

      if (Object.values(errors).flat().length > 0) {
        setErrors(errors)
        return null
      }

      async function rollback(tickets) {
        const assignedTickets = tickets.filter(
          ticket =>
            getActiveState(ticket).sub_state === TicketSubState.DISPATCHED,
        )

        if (assignedTickets.length > 0) {
          await rollbackTickets({
            variables: {
              input: map(assignedTickets, ({ _id }) => ({
                _id,
                rollback_count: 1,
              })),
              namespace,
            },
          })
        }
      }

      await rollback(tickets)

      const { data } = await assignTickets({
        variables: {
          input: rows.map(([_id, , , ...whos]) => ({
            _id,
            whos,
          })),
          namespace,
        },
      })
      return data.assignTickets
    },
    [assignTickets, rollbackTickets, setErrors, version, namespace],
  )

  return [errors, importFromCSV]
}
