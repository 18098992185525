import { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import produce from 'immer'
import { isEmpty, maxBy, fill, size, map, identity } from 'lodash'
import styled from 'styled-components'
import { InputText } from 'primereact/inputtext'
import { ContextMenu } from 'primereact/contextmenu'
import ReactDataSheet from 'react-datasheet'
import 'react-datasheet/lib/react-datasheet.css'
import { Box, Flex } from '@changingai/react-editor-common-component'

import theme from '@theme'

const DataSheet = styled(ReactDataSheet)`
  width: 100%;
`

const Cell = styled.td`
  &&&& {
    height: 30px;
    border: 1px solid #cbcfd7;
    text-align: left;
    vertical-align: middle;
    font-size: 11px;
    background: ${({ error }) => (error ? theme.colors.cellError : 'white')};
    > span {
      padding-left: 20px;
    }
    > input {
      padding-left: 20px;
      width: 100%;
      height: 100%;
      text-align: left;
      font-size: inherit;
    }
  }
`

const HeaderInput = styled(InputText)`
  && {
    flex: 1 1 auto;
    width: 1%;
    min-width: 100px;
    font-size: 11px;
    height: 30px;
    text-align: center;
    padding: 0px;
    border-radius: 0px;
    border-width: 2px 1px 1px 1px;
    border-color: #cbcfd7;
    font-weight: bold;
    box-shadow: none;
    background: ${({ error }) =>
      error ? theme.colors.cellError : theme.colors.veryLightGrey};
    :focus {
      background: white;
    }
  }
`

function Header({ value, onChange, editable }) {
  const [editValue, setEditValue] = useState(value)

  useEffect(() => {
    setEditValue(value)
  }, [value])

  return (
    <HeaderInput
      disabled={!editable}
      error={isEmpty(editValue)}
      value={editValue}
      onKeyDown={({ key, target }) => {
        if (key === 'Enter') {
          onChange(editValue)
          target.blur()
        }
      }}
      onBlur={() => {
        onChange(editValue)
      }}
      onChange={({ target: { value } }) => {
        setEditValue(value)
      }}
      placeholder="填入表頭"
    />
  )
}

Header.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
}

const DIRECTION = {
  ABOVE: 0,
  BELOW: 1,
  LEFT: 2,
  RIGHT: 3,
  ROW: 4,
  COLUMN: 5,
}

export function create2dArray(numRows, numCols, defaultValue = '') {
  // Nested fill cause a lof of problem, push is more steady
  const grid = []
  for (let i = 0; i < numRows; i++) {
    const row = []
    for (let j = 0; j < numCols; j++) {
      row.push(defaultValue)
    }
    grid.push(row)
  }
  return grid
}

function CustomCell(props) {
  const { cell, children, schema } = props
  const { colSpan, rowSpan } = cell
  const error = useMemo(() => {
    if (!schema) return false
    const { error } = schema.validate(cell.value)
    return !!error
  }, [cell, schema])
  return (
    <Cell {...props} colSpan={colSpan} rowSpan={rowSpan} error={error}>
      {children}
    </Cell>
  )
}

CustomCell.propTypes = {
  children: PropTypes.node.isRequired,
  cell: PropTypes.shape({
    colSpan: PropTypes.string,
    rowSpan: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  schema: PropTypes.object,
}

export function TableInput({
  toolBar,
  header,
  columnHeader,
  values,
  onChange,
  headerEditable = true,
  fixedDimension = false,
  format = identity,
  schema,
}) {
  const data = useMemo(
    () => map(values, row => map(row, value => ({ value }))),
    [values],
  )
  const [selectedCells, setSelectedCells] = useState(null)

  const resize = useCallback(
    (numRows, numCols) => {
      const newHeader = fill(new Array(numCols), '')
      const newValues = create2dArray(numRows, numCols, format(''))

      header.forEach((value, index) => {
        newHeader[index] = value
      })

      values.forEach((row, i) => {
        row.forEach((value, j) => {
          newValues[i][j] = value
        })
      })

      return [newHeader, newValues]
    },
    [header, values, format],
  )

  const insert = useCallback(
    direction => {
      const { i: row, j: col } = selectedCells.start
      if (direction === DIRECTION.ABOVE || direction === DIRECTION.BELOW) {
        const anchor = direction === DIRECTION.ABOVE ? row : row + 1
        onChange({
          values: [
            ...values.slice(0, anchor),
            fill(new Array(size(header)), format('')),
            ...values.slice(anchor),
          ],
        })
      }
      if (direction === DIRECTION.LEFT || direction === DIRECTION.RIGHT) {
        const anchor = direction === DIRECTION.LEFT ? col : col + 1
        onChange({
          header: [
            ...header.slice(0, anchor),
            format(''),
            ...header.slice(anchor),
          ],
          values: values.map(row => [
            ...row.slice(0, anchor),
            format(''),
            ...row.slice(anchor),
          ]),
        })
      }
    },
    [selectedCells, header, values, onChange, format],
  )

  const remove = useCallback(
    direction => {
      const { i: row, j: col } = selectedCells.start
      if (direction === DIRECTION.ROW) {
        onChange({
          values: [...values.slice(0, row), ...values.slice(row + 1)],
        })
      }
      if (direction === DIRECTION.COLUMN) {
        onChange({
          header: [...header.slice(0, col), ...header.slice(col + 1)],
          values: values.map(row => [
            ...row.slice(0, col),
            ...row.slice(col + 1),
          ]),
        })
      }
    },
    [selectedCells, header, values, onChange],
  )

  const contextMenuRef = useRef(null)

  const contextMenu = useMemo(() => {
    const menu = []
    if (!fixedDimension) {
      menu.push(
        {
          label: 'Insert row above',
          command: () => insert(DIRECTION.ABOVE),
        },
        {
          label: 'Insert row below',
          command: () => insert(DIRECTION.BELOW),
        },
        {
          label: 'Insert column left',
          command: () => insert(DIRECTION.LEFT),
        },
        {
          label: 'Insert column right',
          command: () => insert(DIRECTION.RIGHT),
        },
        {
          label: 'Delete this column',
          command: () => remove(DIRECTION.COLUMN),
          disabled: size(header) <= 1,
        },
        {
          label: 'Delete this row',
          command: () => remove(DIRECTION.ROW),
          disabled: size(values) <= 1,
        },
      )
    }

    menu.push({
      label: 'Clean all',
      command: () => {
        const formatVal = format('')
        onChange({
          values: map(values, row => map(row, () => formatVal)),
        })
      },
    })

    return menu
  }, [fixedDimension, header, insert, remove, values, onChange, format])

  const onCellsChange = useCallback(
    (changes, additions = []) => {
      const additionCells = fixedDimension ? [] : additions
      const [newHeader, newValues] = isEmpty(additionCells)
        ? [header, values]
        : resize(
            Math.max(maxBy(additionCells, 'row').row + 1, values.length),
            Math.max(maxBy(additionCells, 'col').col + 1, header.length),
          )
      onChange({
        header: newHeader,
        values: produce(newValues, draft => {
          ;[...changes, ...additionCells].forEach(({ row, col, value }) => {
            draft[row][col] = format(value)
          })
        }),
      })
    },
    [header, values, onChange, resize, fixedDimension, format],
  )

  const renderHeader = (header, direction) => {
    if (!header) return null
    return (
      <Flex
        width={direction === 'horizontal' ? '100%' : '100px'}
        height={direction === 'horizontal' ? '30px' : 'unset'}
        flexWrap={direction === 'horizontal' ? 'nowrap' : 'wrap'}
      >
        {header.map((value, index) => (
          <Header
            key={index}
            value={value}
            onChange={value => {
              onChange({
                header: produce(header, draft => {
                  draft[index] = value
                }),
              })
            }}
            editable={headerEditable}
          />
        ))}
      </Flex>
    )
  }

  const cellRenderCallback = useCallback(
    props => <CustomCell schema={schema} {...props}></CustomCell>,
    [schema],
  )

  return (
    <Flex flexDirection="column" width="100%" height="100%">
      <ContextMenu
        appendTo={document.body}
        ref={contextMenuRef}
        model={contextMenu}
      />
      {toolBar && (
        <Flex flex="0 0 50px" bg="#f2f6fe" alignItems="center" px="2">
          {toolBar}
        </Flex>
      )}
      <Flex
        flex="1 1 auto"
        overflow="auto"
        flexWrap="wrap"
        alignContent="flex-start"
        position="relative"
      >
        <Box position="absolute" top="30px">
          {renderHeader(columnHeader, 'vertical')}
        </Box>
        <Box position="absolute" left={columnHeader ? '100px' : '0'}>
          {renderHeader(header, 'horizontal')}

          <DataSheet
            data={data}
            selected={selectedCells}
            onSelect={({ start, end }) => {
              setSelectedCells({ start, end })
            }}
            onContextMenu={event => {
              contextMenuRef.current.show(event)
            }}
            onCellsChanged={onCellsChange}
            valueRenderer={({ value }) => value}
            cellRenderer={cellRenderCallback}
          />
        </Box>
      </Flex>
    </Flex>
  )
}

TableInput.propTypes = {
  toolBar: PropTypes.object,
  header: PropTypes.arrayOf(PropTypes.string),
  columnHeader: PropTypes.arrayOf(PropTypes.string),
  values: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  onChange: PropTypes.func.isRequired,
  headerEditable: PropTypes.bool,
  fixedDimension: PropTypes.bool,
  format: PropTypes.func,
  schema: PropTypes.object,
}
