import { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { Dialog } from 'primereact/dialog'
import { useImmer } from 'use-immer'
import { toast } from 'react-toastify'
import { get, chunk, isEmpty, size } from 'lodash'
import {
  Flex,
  Box,
  StyledOverlay,
} from '@changingai/react-editor-common-component'

import { useDirtyStore, useDialog } from '@common'
import { purifySchemaDoc, ticketProfileSchema } from '@schema'
import {
  ActionButtons,
  SingleInput,
  StatusBarRoot,
  SingleSelect,
  ConfirmDialog,
} from '@widget'

import {
  useTicketVersion,
  getTicketProfile,
  useTickets,
  getNamespaceByScopeType,
} from './TicketHelper'

import { _createTickets } from './CreateTicketDialog.gql'

export function CreateTicketDialog({
  onCreated,
  onHide,
  scopeType,
  useTicketScopeMap,
  useCandidator,
}) {
  const scopeTargetMap = useTicketScopeMap(scopeType)
  const [tickets] = useTickets({
    scope_type: scopeType,
    namespace: getNamespaceByScopeType(scopeType),
  })

  const { isLoading, manuallyCreate, autoCreate } = useCandidator(
    scopeType,
    tickets,
    scopeTargetMap,
  )

  const [createTicketsMutation] = useMutation(_createTickets)
  const [setDirty, dirties, setError, errors, , reasons] = useDirtyStore()
  const [showConfirmDialog, renderConfirmDialog] = useDialog(ConfirmDialog)

  const [doc, setDoc] = useImmer({
    scope_id: '',
    name: '',
  })

  const onChange = useCallback(
    (fieldname, value) => {
      setDoc(draft => {
        draft[fieldname] = value
        if (fieldname === 'scope_id' && value in scopeTargetMap) {
          draft.name = scopeTargetMap[value].ticket_name
        }
      })
    },
    [setDoc, scopeTargetMap],
  )

  const onDirty = useCallback(
    (fieldname, dirty) => {
      setDirty(fieldname, dirty)
    },
    [setDirty],
  )

  const onValid = useCallback(
    (fieldname, value) => {
      if (!value || value.length === 0) {
        setError(fieldname, true)
        return false
      }

      if (fieldname === 'scope_id' && !(value in scopeTargetMap)) {
        setError(fieldname, true, 'Invalid scope id')
        return false
      }

      if (
        fieldname === 'name' &&
        tickets.map(({ name }) => name).includes(value)
      ) {
        setError(fieldname, true, '已存在相同名稱的Ticket')
        return false
      }
      setError(fieldname, false)
      return true
    },
    [setError, scopeTargetMap, tickets],
  )

  const [creating, setCreating] = useState(false)
  const canCreate = useMemo(
    () =>
      size(errors) === 0 &&
      size(dirties) > 0 &&
      !isLoading &&
      !creating &&
      !isEmpty(manuallyCreate),
    [errors, dirties, isLoading, manuallyCreate, creating],
  )

  const renderError = fieldname => (
    <>
      {!!reasons[fieldname] && (
        <Box width="100%" color="red" textAlign="right">
          {reasons[fieldname]}
        </Box>
      )}
    </>
  )

  const version = useTicketVersion(scopeType)
  const [progress, setProgress] = useState(-1)

  const onAutoCreate = useCallback(
    async candidators => {
      const createdTickets = []
      const step = 500
      setProgress(0)
      const createTickets = candidators =>
        createTicketsMutation({
          variables: {
            input: candidators.map(candidator =>
              purifySchemaDoc(
                {
                  scope_id: candidator.scope_id,
                  name: candidator.ticket_name,
                  scope_type: scopeType,
                  states: get(getTicketProfile(scopeType), 'states'),
                  version,
                },
                ticketProfileSchema,
              ),
            ),
            namespace: getNamespaceByScopeType(scopeType),
          },
        })

      for await (const tickets of chunk(candidators, step).map(chunks =>
        createTickets(chunks),
      )) {
        createdTickets.push(...tickets.data.createTickets)
        setProgress(createdTickets.length)
      }

      return createdTickets
    },
    [createTicketsMutation, scopeType, setProgress, version],
  )

  return (
    <Dialog
      header={`Create a new ticket`}
      appendTo={document.body}
      visible={true}
      style={{ width: '800px' }}
      contentStyle={{ overflow: 'visible', position: 'relative' }}
      modal={true}
      onHide={onHide}
    >
      {renderConfirmDialog({
        onYes: async action => {
          if (action === 'AutoCreate') {
            setCreating(true)
            const createTickets = await onAutoCreate(autoCreate)
            onCreated(createTickets)
            setCreating(false)
            toast.success(
              `Create ${createTickets.length} new tickets Successfully!`,
              {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
              },
            )

            onHide()
          }
        },
      })}
      <Flex width="100%" flexWrap="wrap">
        <StyledOverlay active={isLoading} size="50px" />
        <Flex width="100%" flexWrap="wrap">
          <SingleSelect
            fieldname="scope_id"
            label="範圍"
            nodes={manuallyCreate}
            value={doc.scope_id}
            onChange={onChange}
            onDirty={onDirty}
            disabled={isEmpty(manuallyCreate) || progress !== -1}
            inputAttributes={{ width: '100%' }}
          />
          {renderError('scope_id')}
          <SingleInput
            value={doc.name}
            overwrite={doc.name}
            label="名稱"
            fieldname="name"
            onDirty={onDirty}
            onChange={onChange}
            onValid={onValid}
            inputAttributes={{ width: '100%' }}
            disabled={isEmpty(manuallyCreate) || progress !== -1}
          />
          {renderError('name')}
        </Flex>
        <StatusBarRoot style={{ justifyContent: 'space-between' }}>
          <Box flex="1 1 100%">
            {progress === -1 ? '' : `Progress: ${progress}/${size(autoCreate)}`}
          </Box>
          <ActionButtons
            buttons={{
              right: [
                {
                  label: `AutoCreate(${size(autoCreate)})`,
                  action: `AutoCreate`,
                  className: 'p-button-warning',
                  icon: creating ? 'pi pi-spin pi-spinner' : 'pi pi-check',
                  enable: size(autoCreate) > 0 && !creating,
                },
                {
                  label: 'Create',
                  action: 'Create',
                  className: 'p-button-primary',
                  icon: creating ? 'pi pi-spin pi-spinner' : 'pi pi-check',
                  enable: canCreate,
                },
                {
                  label: 'Close',
                  action: 'Close',
                  className: 'p-button-secondary',
                  enable: !creating,
                },
              ],
            }}
            onClicked={async action => {
              if (action === 'Close') {
                onHide()
              } else if (action === 'Create') {
                try {
                  setCreating(true)
                  const {
                    data: { createTickets },
                  } = await createTicketsMutation({
                    variables: {
                      input: [
                        purifySchemaDoc(
                          {
                            ...doc,
                            scope_type: scopeType,
                            states: get(getTicketProfile(scopeType), 'states'),
                            version,
                          },
                          ticketProfileSchema,
                        ),
                      ],
                      namespace: getNamespaceByScopeType(scopeType),
                    },
                  })
                  onCreated(createTickets)
                  setCreating(false)
                  toast.success('Create a new ticket Successfully!', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                  })
                } catch (e) {
                  setCreating(false)
                  toast.error(`Error: ${e}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: false,
                  })
                }
                onHide()
              } else if (action === 'AutoCreate') {
                showConfirmDialog({
                  title: 'Info',
                  message: `產生${size(autoCreate)}張tickets?`,
                  action: 'AutoCreate',
                })
              }
            }}
          />
        </StatusBarRoot>
      </Flex>
    </Dialog>
  )
}

CreateTicketDialog.propTypes = {
  useTicketScopeMap: PropTypes.func.isRequired,
  useCandidator: PropTypes.func.isRequired,
  onCreated: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  scopeType: PropTypes.string.isRequired,
}
