import PropTypes from 'prop-types'
import { isEmpty, partial, filter } from 'lodash'
import { StyledOverlay } from '@changingai/react-editor-common-component'
import { MatchState } from '@common'
import { cceClient } from '@gql'

import { namespace, schema } from './config'
import { CardRewardContext, useCardRewardContext } from './context'
import { CardRewardEditDialog } from './editor'
import { cardRewardSchema } from './schema'

import { getTicketDocPath } from '../Common'
import { useFetchDocAndTicket } from '../DocHelper'
import { referenceDoc } from '../Reference'
import { useRestoreDoc, RestoreDialog } from '../Restore'
import { EditorLoader } from '../SchemaBaseEditorLoader'

import { _queryRewardCard, _queryMatchedDocs } from './loader.gql'

const getPath = partial(getTicketDocPath, namespace, schema)

export const queryManifest = {
  gql: _queryRewardCard,
  name: 'queryRewardCard',
  getPath,
}

export const generateReferenceDoc = partial(
  referenceDoc,
  '_id',
  getPath,
  schema,
  namespace,
)

export function CardRewardRestorer({ doc }) {
  const context = useCardRewardContext()

  if (!context.isReady) return <StyledOverlay active={!context.isReady} />
  return (
    <CardRewardContext.Provider value={context}>
      <RestoreDialog
        DialogComp={CardRewardEditDialog}
        useRestoreDoc={partial(useRestoreDoc, doc, namespace)}
      />
    </CardRewardContext.Provider>
  )
}

CardRewardRestorer.propTypes = {
  doc: PropTypes.object.isRequired,
}

export async function fetchPerfectMatchedDocs(doc_ids, currentSchema) {
  if (currentSchema !== cardRewardSchema || isEmpty(doc_ids)) return []

  const {
    data: { queryRewardCard },
  } = await cceClient.query({
    query: _queryMatchedDocs,
    variables: {
      doc_ids,
    },
  })

  return filter(queryRewardCard, {
    matched_state: MatchState.FULLY_MATCHED,
  })
}

export function CardRewardEditorLoader({ docId }) {
  const context = useCardRewardContext()

  if (!context.isReady) return <StyledOverlay active={!context.isReady} />

  return (
    <CardRewardContext.Provider value={context}>
      <EditorLoader
        DialogComp={CardRewardEditDialog}
        useFetchDocAndTicket={partial(
          useFetchDocAndTicket,
          _queryRewardCard,
          'queryRewardCard',
          namespace,
          docId,
        )}
        currentSchema={cardRewardSchema}
      />
    </CardRewardContext.Provider>
  )
}

CardRewardEditorLoader.propTypes = {
  docId: PropTypes.string.isRequired,
}

export default CardRewardEditorLoader
