import { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { get, compact, partial } from 'lodash'

import { namespace, schema } from './config'
import { PlanEditDialog } from './editor'
import { usePlanContext, PlanContext } from './context'
import { planSchema } from './schema'

import { getTicketDocPath } from '../Common'
import { useFetchDocAndTicket } from '../DocHelper'
import { referenceDoc } from '../Reference'
import { useRestoreDoc, RestoreDialog } from '../Restore'
import { EditorLoader } from '../SchemaBaseEditorLoader'

import { _queryPlan } from './loader.gql'

const getPath = partial(getTicketDocPath, namespace, schema)

export const queryManifest = {
  gql: _queryPlan,
  name: 'queryPlan',
  getPath,
}

export const generateReferenceDoc = partial(
  referenceDoc,
  'name',
  getPath,
  schema,
  namespace,
)

export function PlanRestorer({ doc }) {
  return (
    <RestoreDialog
      DialogComp={PlanEditDialog}
      useRestoreDoc={partial(useRestoreDoc, doc, namespace)}
    />
  )
}

PlanRestorer.propTypes = {
  doc: PropTypes.object.isRequired,
}

export function PlanEditorLoader({ docId }) {
  const [plan, ticket] = useFetchDocAndTicket(
    _queryPlan,
    'queryPlan',
    namespace,
    docId,
  )
  const scopeIds = useMemo(() => compact([get(ticket, 'scope_id')]), [ticket])
  const context = usePlanContext(scopeIds)
  const fetch = useCallback(() => [plan, ticket], [plan, ticket])
  return (
    <PlanContext.Provider value={context}>
      <EditorLoader
        DialogComp={PlanEditDialog}
        useFetchDocAndTicket={fetch}
        currentSchema={planSchema}
      />
    </PlanContext.Provider>
  )
}

PlanEditorLoader.propTypes = {
  docId: PropTypes.string.isRequired,
}

export default PlanEditorLoader
