export const KG_NODES = {
  card: {
    // Card Reward_Type
    CARD_REWARD_ROOT_ID: 'channel_concept/5d6cee5c8a0112506b5ed6d6',
    // Shop Reward_Type
    SHOP_REWARD_ROOT_ID: 'channel_concept/5f608ca721986059fc8d7291',
    // 行動支付
    MOBILE_PAYMENT_RC_ID: 'channel_concept/5db286566bc9ba0d89ad90e8',
    // 一般
    GENERAL_ID: 'channel_concept/5d67382c9345e9d712735788',
    // 一般_海外
    GENERAL_OVERSEAS_ID: 'channel_concept/5d67382c9345e9d712735789',
    // 實體
    REAL_ID: 'channel_concept/5d67382c9345e9d71273578a',
    // 實體_海外
    REAL_OVERSEAS_ID: 'channel_concept/5d67382c9345e9d71273578c',
    // 網購
    ONLINE_ID: 'channel_concept/5d67382c9345e9d71273578b',
    // 網購
    ONLINE_OVERSEAS_ID: 'channel_concept/5d67382c9345e9d71273578d',
    // 國際線機票
    INTERNATIONAL_TICKET_ID: 'target/5d7f296bf57ef9a845dc224d',
    // 國外旅行團費
    INTERNATIONAL_TRAVEL_FEE_ID: 'target/5d7f2999f57ef9a845dc224f',
    // 旅行團費
    TRAVEL_FEE_ID: 'target/5d64bff2d9dea4b00a1a68f5',
    // 機票
    TICKET_ID: 'target/5d64bff2d9dea4b00a1a68ed',
    // Reward_item >> 現金回饋用
    CASH_REWARD_GROUP_ID: 'channel_concept/5f28dbdfb415d9740f5a9b99',
    // Reward_item >> 類現金回饋用
    CASHLIKE_REWARD_GROUP_ID: 'channel_concept/5e16b9eb1b207d182d6bd6c0',
    // Reward_item >> 打折用
    DISCOUNT_REWARD_GROUP_ID: 'channel_concept/5e16b9b31b207d182d6bd6b9',
    // Reward_item >> 其他用
    OTHERS_REWARD_GROUP_ID: 'channel_concept/5e16ba601b207d182d6bd6ca',
    // 回饋門檻天數單位
    ESSENTIAL_DATE_UNIT: 'channel_concept/5ec4ce8a823b1642b2291569',
    // 月
    DATE_UNIT_MONTH: 'channel_concept/5eec756b1c8445f7bb803c1c',
    // 回饋上限效期
    REWARD_UPPER_BOUND_UNIT: 'channel_concept/5ec4d13012064c10265ae6df',
    // 身份限定
    REWARD_IDENTIDY: 'channel_concept/5ec4ccf812064c10265ae6ba',
    // Ticket Assignee White List
    TICKET_ROOT_ID: 'channel_concept/5fadfb17dfde1373c1421f59',
    // 各種支付或組織合作店家(可跨國內外)
    REWARD_CHNNAEL_BY_PAYMETHOD: 'channel_concept/5e4263cd39e01c8fda576773',
  },
  insurance: {
    // 壽險公司
    LIFE_INSURANCE_COMPANY_ROOT_ID: 'channel_concept/5f3c9b97e7f351c385f74bb7',
    // 產險公司
    PROPERTY_INSURANCE_COMPANY_ROOT_ID:
      'channel_concept/5f3c9c0435cedc6b5a98f817',
    // 保發中心保險類別代碼
    TII_CATEGORY_ID: 'channel_concept/60d383207eab07d44d9b6e9f',
    // benefit_type root
    BENEFIT_TYPE_ROOT_ID: 'channel_concept/5f6ea965c245822981194272',
    CURRENCY_ROOT_ID: 'channel_concept/5f85098e8ddec2fcddbb6d89',
    CURRENCY_NTD: 'channel_concept/5f85099dbbad8a45392bd382',
    SALE_CHANNEL_ROOT_ID: 'channel_concept/5f8972ae542d4784a7b290d2',
    PRODUCT_ATTRIBUTE_ROOT_ID: 'channel_concept/5f8d4d53716844ec5c9034ce',
    CATEGORY_ROOT_ID: 'channel_concept/5f86910a0968da7bbac8cdc5',
    // Fee table axis nodes
    REQUEST_VARIABLE_ROOT_ID: 'channel_concept/5fa37da59996e69baaa8394d',
    AMOUNT_OPTION_UNIT_ROOT_ID: 'channel_concept/5fdb06afd759eee32150d613',
    BENEFIT_CONCEPT_MEDIAWIKI_ROOT_ID:
      'channel_concept/60530ae82f4baf0c7c32c2de',
    REQUEST_VARIABLE: {
      // 投保額度
      REQUEST_AMOUNT: 'channel_concept/602097c3f335128cb446f3e7',
      // 保障期間.
      INSURE_DURATION: 'channel_concept/5fc718aa8bd54ddc2541ee31',
      // 年齡
      AGE: 'channel_concept/5fa37deb1ca8dabf782a7c6e',
      // 性別
      SEX: 'channel_concept/5fa37df3066d9af1eb1fb511',
      // 繳費年期
      PAYMENT_YEARS: 'channel_concept/5facaef0ffd34717bea79904',
      // 計畫/方案別
      PLAN: 'channel_concept/603f318ac0654befb6272928',
    },
    // 保險成本收取方式(append to feetable for investment ins)
    COST_PICK_METHOD_ROOT_ID: 'channel_concept/640953f44ac562d173bc646d',
    ACTUARIAL_RULE: 'channel_concept/615587385ea35315c72da5cf',
  },
  entity: {
    // entity_bank
    BANK_ROOT_ID: 'channel_concept/5dccdfac82d7a71059d90652',
    // entity_issuer
    ISSUER_ROOT_ID: 'channel_concept/5dccdfb682d7a71059d906c1',
    // entity_card_level
    LEVEL_ROOT_ID: 'channel_concept/5dccdfba82d7a71059d906eb',
    // 卡片功能
    ENTITY_FUNCTION: 'channel_concept/5dccdfc082d7a71059d9072d',
    // 全卡等
    ALL_CARD_LEVEL_ID: 'channel_concept/5dccdfbf82d7a71059d9071e',
    // All issuers
    ALL_ISSUER_ID: 'channel_concept/5dccdfb882d7a71059d906d0',
    // All banks
    ALL_BANK_ID: 'channel_concept/5dccdfb482d7a71059d906a6',
    // entity_currency
    CURRENCY: 'channel_concept/5e12ea431b207d182d6bd46b',
  },
  myfinance_editor: {
    PRODUCT_PRIMARY_KEY_ROOT_ID: 'channel_concept/5fb3edae1131fe9498ccc9e9',
    BENEFIT_PRIMARY_KEY_ROOT_ID: 'channel_concept/60754c4ba831bb95a11e5cae',
    PLAN_PRIMARY_KEY_ROOT_ID: 'channel_concept/60767cc33dd2e7024d64211a',
    CARD_PRIMARY_KEY_ROOT_ID: 'channel_concept/5fffb8daa964d27c2f34533e',
    STATISTIC_ROOT_ID: 'channel_concept/60bedba99b846129b9eb4993',
    FEETABLE_PRIMARY_KEY_ROOT_ID: 'channel_concept/60a717a42bf9999e83998cf0',
    SUGGESTIONS_ROOT_ID: 'channel_concept/6189d7ee4bc00f016137e471',

    // Cypress testing roots.
    NINJA_SKILLS: 'channel_concept/611e453e1761fd7ccd7e742a',
  },
}

export const builtInRewards = {
  現金回饋: 'channel_concept/5d6de1c7320238366c95b158',
  '現金回饋-級距': 'channel_concept/5d81cbb83e7de4cf5b61ece4',
  公益提撥: 'channel_concept/5d81cb763e7de4cf5b61ecde',
  '現金回饋-滿額': 'channel_concept/5d81cbaf3e7de4cf5b61ece2',
  '首刷禮-現金回饋': 'channel_concept/5d6deae6320238366c95b17a',
  定價優惠: 'channel_concept/5d81cb4b3e7de4cf5b61ecd8',
  '公益提撥-定額': 'channel_concept/5d81cb7f3e7de4cf5b61ece0',
  紅利回饋: 'channel_concept/5d6de13c320238366c95b156',
  '紅利回饋-級距': 'channel_concept/5d81cbce3e7de4cf5b61ece8',
  '紅利回饋-定額': 'channel_concept/5d81cbc53e7de4cf5b61ece6',
  打折優惠: 'channel_concept/5d6de299320238366c95b15a',
  '機場接送-打折': 'channel_concept/5d6de65f320238366c95b164',
  '旅遊平安險-打折': 'channel_concept/5dbfae1f582569f95399eab7',
  里程回饋: 'channel_concept/5d6de0ee320238366c95b154',
  市區停車: 'channel_concept/5d6cee118a0112506b5ed6ce',
  '市區停車-紅利折抵': 'channel_concept/5d6cee268a0112506b5ed6d0',
  機場接送: 'channel_concept/5d6de63f320238366c95b162',
  '機場接送-紅利折抵': 'channel_concept/5dcd0b7282d7a71059d907e7',
  '機場接送-消費額折抵': 'channel_concept/5dcd0b7e82d7a71059d907ea',
  '機場接送-指定價格': 'channel_concept/5d6de723320238366c95b166',
  機場貴賓室: 'channel_concept/5d6de75b320238366c95b168',
  '機場貴賓室-指定價格': 'channel_concept/5d6de779320238366c95b16c',
  機場停車: 'channel_concept/5d6de80f320238366c95b170',
  旅遊平安險: 'channel_concept/5d6de5e2320238366c95b160',
  旅遊不便險: 'channel_concept/5d6de506320238366c95b15c',
  道路救援: 'channel_concept/5d6de534320238366c95b15e',
  '道路救援-紅利折抵': 'channel_concept/5dca55f40802cc5071e0905e',
  '道路救援-指定價格': 'channel_concept/5d6de847320238366c95b172',
  '首刷禮-紅利回饋': 'channel_concept/5d6deaf6320238366c95b17c',
  '首刷禮-其他': 'channel_concept/5d6deb34320238366c95b182',
  其他: 'channel_concept/5d81cb6c3e7de4cf5b61ecdc',
  '首刷禮-行李箱': 'channel_concept/5d6deb29320238366c95b180',
  '首刷禮-里程數': 'channel_concept/5d6deb02320238366c95b17e',
  '里程回饋-定額': 'channel_concept/5dcd0b5482d7a71059d907e1',
  加油折價: 'channel_concept/5d6de951320238366c95b176',
  折價優惠: 'channel_concept/5d81cb543e7de4cf5b61ecda',
  分期期數: 'channel_concept/5d6cf1e38a0112506b5ed6da',
  '類現金回饋(非現金但兌換比例為1:1)':
    'channel_concept/5dfb36601b207d182d6bd16f',
  '類現金回饋-級距': 'channel_concept/5dfb4d221b207d182d6bd17a',
  '類現金回饋-滿額': 'channel_concept/5dfb4de21b207d182d6bd17d',
  '首刷禮-類現金回饋': 'channel_concept/5e44ee795149d2d2c3fae261',
  機場貴賓室_紅利折抵: 'channel_concept/5e3ebfda7783e815362578f9',
  '首刷禮-現金回饋(滙豐專用)': 'channel_concept/600103dab6fc518ac44c66a0',
  待定: 'channel_concept/5dcd0b6682d7a71059d907e4',
}

export function rewardNameToID(names) {
  return names.map(name => {
    if (!(name in builtInRewards)) {
      throw new Error(`rewardNameToID get an invalid ratable name:${name}`)
    }

    return builtInRewards[name]
  })
}
