import { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'primereact/button'
import {
  compact,
  some,
  isNil,
  isNaN,
  map,
  head,
  toNumber,
  overSome,
  get,
  isEmpty,
  every,
  forEach,
} from 'lodash'
import moment from 'moment-taiwan'
import { Flex } from '@changingai/react-editor-common-component'

import { inputFactory, SingleInput } from '@widget'
import { dataAnalysisService } from '@remote'
import { toEditableValue } from '@schema'
import { cceClient } from '@gql'

import { useMutateTicket } from '../TicketHelper'
import { useUploadDocWithHistory } from '../Uploader'
import { contractSchema, ContractPropType } from './schema'
import { SchemaBaseEditor } from '../SchemaBaseEditor'
import { useFetchFullDoc } from '../DocHelper'

import { _queryContract, _uploadContract } from './editor.gql'

function parseDateString(dateString) {
  const [year, month, day] = map(dateString.split(/\.|年|月|日|\//), toNumber)
  if (some([year, month, day], overSome(isNil, isNaN))) return null
  const parsedDate = moment(`${year}/${month}/${day}`, 'tYY/MM/DD')
  return parsedDate
}

function ApproveDate({ context, onChange, onDirty, onValid }) {
  const { approval_doc_no } = context
  const extractedDate = useMemo(() => {
    if (isNil(approval_doc_no)) return null
    const matchedString = compact(
      map(
        [
          ...approval_doc_no
            .replaceAll(' ', '')
            .matchAll(
              /\d{2,3}\.\d{1,2}\.\d{1,2}|\d{2,3}年\d{1,2}月\d{1,2}日|\d{2,3}\/\d{1,2}\/\d{1,2}/g,
            ),
        ],
        matched => parseDateString(head(matched)),
      ),
    )
    return isEmpty(matchedString) ? null : moment.max(matchedString)
  }, [approval_doc_no])

  const onClick = useCallback(() => {
    if (isNil(extractedDate)) throw new Error('extractedDate should not be nil')
    onChange('approval_date', extractedDate.format('YYYY/M/D'))
    onDirty('approval_date', true)
  }, [extractedDate, onChange, onDirty])

  return (
    <>
      <SingleInput
        fieldname="approval_date"
        value={toEditableValue(
          'approval_date',
          context.approval_date,
          contractSchema,
        )}
        label={get(contractSchema, 'approval_date.label')}
        onChange={onChange}
        onDirty={onDirty}
        onValid={onValid}
        currentSchema={contractSchema}
        inputAttributes={{ width: 'calc(100% - 100px)', multilines: false }}
        labelAttributes={{ width: '200px' }}
      />
      <Flex width="100px">
        <Button
          disabled={isNil(extractedDate)}
          label="擷取日期"
          className="p-button-primary"
          onClick={onClick}
        />
      </Flex>
    </>
  )
}

ApproveDate.propTypes = {
  context: ContractPropType,
  onChange: PropTypes.func.isRequired,
  onDirty: PropTypes.func.isRequired,
  onValid: PropTypes.func.isRequired,
}

function AutoFill({ context, onChange }) {
  const invalidInput = useMemo(() => {
    return every(
      compact([get(context, 'contract_name'), get(context, 'file_url')]),
      isEmpty,
    )
  }, [context])

  const onFetchSuggestion = useCallback(async () => {
    const response = await dataAnalysisService('suggest_contract', {
      doc: context,
    })
    const suggestion = await response.json()
    if (response.ok && !isNil(suggestion)) {
      forEach(suggestion, (v, k) => {
        if (!isNil(v)) onChange(k, v)
      })
    }
  }, [context, onChange])

  return (
    <Flex width="100%" flexWrap="wrap" justifyContent="flex-end">
      <Button
        disabled={invalidInput}
        label={`自動填值${invalidInput ? '(名稱或檔案不可為空)' : ''}`}
        className="p-button-help"
        icon="pi pi-cloud-download"
        onClick={onFetchSuggestion}
      />
    </Flex>
  )
}
AutoFill.propTypes = {
  context: ContractPropType,
  onChange: PropTypes.func.isRequired,
}

export function useUploadContract() {
  const { createOrRenameTicket } = useMutateTicket()
  const upload = useUploadDocWithHistory(contractSchema, async contracts => {
    const {
      data: { uploadContract },
    } = await cceClient.mutate({
      mutation: _uploadContract,
      variables: {
        contracts,
      },
    })

    await createOrRenameTicket(
      uploadContract,
      'Benefit',
      ({ contract_name }) => contract_name,
    )
    return uploadContract
  })

  return upload
}

export function ContractEditor(props) {
  const { doc } = props
  const { editContext, setEditContext } = useFetchFullDoc(
    doc,
    contractSchema,
    _queryContract,
  )
  const tabs = useMemo(
    () => [
      {
        caption: 'Primary',
        configs: [
          {
            widget: 'CustomizedInput',
            RenderComp: AutoFill,
          },
          inputFactory(contractSchema, 'contract_name'),
          inputFactory(contractSchema, 'contract_type'),
          inputFactory(contractSchema, 'insurance_company'),
          {
            fieldname: 'file_url',
            widget: 'FileUploader',
            mimetype: 'application/pdf',
          },
          {
            fieldname: 'fee_url',
            widget: 'FileUploader',
            mimetype: 'application/pdf',
          },
          {
            fieldname: 'ref_url',
            widget: 'FileUploader',
            mimetype: 'application/pdf',
          },
          inputFactory(contractSchema, 'contract_code'),
          inputFactory(contractSchema, 'oldest_approval_doc_no'),
          inputFactory(contractSchema, 'approval_doc_no'),
          {
            fieldname: 'approval_date',
            widget: 'CustomizedInput',
            RenderComp: ApproveDate,
          },
          inputFactory(contractSchema, 'start_date'),
          inputFactory(contractSchema, 'guaranteed_renewal'),
        ],
      },
      {
        caption: 'Secondary',
        configs: [
          inputFactory(contractSchema, 'glossary'),
          inputFactory(contractSchema, 'exception', {
            inputAttributes: {
              multilines: true,
              rows: 5,
            },
          }),
          inputFactory(contractSchema, 'exclusion', {
            inputAttributes: {
              multilines: true,
              rows: 5,
            },
          }),
        ],
      },
    ],
    [],
  )

  const upload = useUploadContract()
  const uploadDoc = useCallback(contract => upload(null, contract), [upload])

  return (
    <SchemaBaseEditor
      {...props}
      onUpload={uploadDoc}
      currentSchema={contractSchema}
      tabs={tabs}
      crud={{
        create: true,
        update: true,
        delete: true,
        read: true,
        overwrite: true,
      }}
      isReady={!isNil(editContext)}
      editContext={editContext}
      setEditContext={setEditContext}
    />
  )
}

ContractEditor.propTypes = {
  doc: ContractPropType.isRequired,
}
