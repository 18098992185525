import { useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import {
  groupBy,
  isNil,
  get,
  uniq,
  map,
  filter,
  pickBy,
  mapValues,
  find,
  findIndex,
  isEmpty,
  attempt,
  isError,
  negate,
  size,
  flow,
  slice,
} from 'lodash'
import { useQuery } from '@apollo/client'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import {
  Flex,
  Box,
  StyledOverlay,
} from '@changingai/react-editor-common-component'

import { ProjectContext, MatchState, KG_NODES } from '@common'
import { SchemaStore, SchemaLabels, SchemaNamespace } from '@schema'
import { cceClient, kgClient } from '@gql'

import { ChangeScoreChart } from './ChangeScoreChart'
import { MergeChart } from './MergeChart'

import { useFetchPerfectMatchedDocs } from '../Loader'

import {
  _queryHistiryByDuration,
  _queryHistory,
  _get_connected,
} from './index.gql'

import Dobutsu from '../../img/no-permission.jpg'

function useFetchHistory(duration, schemaName, namespace) {
  const { loading: loardTargetId, data: targetData } = useQuery(
    _queryHistiryByDuration,
    {
      client: cceClient,
      variables: {
        startDate: new Date(get(duration, '0')),
        endDate: new Date(get(duration, '1')),
        docTypes: [schemaName],
      },
      skip: isEmpty(duration) || isNil(schemaName) || isNil(namespace),
    },
  )

  const targetIds = useMemo(() => {
    const data = get(targetData, 'queryHistory')
    return loardTargetId || isNil(data) ? null : uniq(map(data, 'target_id'))
  }, [loardTargetId, targetData])

  const { loading: loadingDoc, docs } = useFetchPerfectMatchedDocs(
    targetIds,
    SchemaStore[schemaName],
  )

  const matchedDoc = useMemo(
    () =>
      loadingDoc || isNil(docs)
        ? null
        : filter(docs, { matched_state: MatchState.FULLY_MATCHED }),
    [loadingDoc, docs],
  )

  const { loading: loadingHitoryData, data: historyData } = useQuery(
    _queryHistory,
    {
      client: cceClient,
      variables: {
        target_ids: map(matchedDoc, '_id'),
      },
      skip: isNil(matchedDoc),
    },
  )

  const sourceDocs = useMemo(
    () =>
      loadingHitoryData || isNil(get(historyData, 'queryHistory'))
        ? null
        : mapValues(
            pickBy(
              groupBy(get(historyData, 'queryHistory'), 'target_id'),
              docs =>
                // Get  rid of doc chains which miss CREATE history. Supposely,
                // it should not happen, just in case.
                !isNil(find(docs, { actionType: 'CREATE' })),
            ),
            docs =>
              filter(
                map(docs, doc => {
                  const actionContent = attempt(JSON.parse, doc.actionContent)
                  return isError(actionContent)
                    ? null
                    : {
                        ...doc,
                        actionContent,
                      }
                }),
                doc => !isNil(doc),
              ),
          ),
    [loadingHitoryData, historyData],
  )

  const editingHitoryOfDocs = useMemo(() => {
    if (isNil(sourceDocs)) return null

    // In some old history records, there is no ticket_state date stored.
    // Skip it!
    const filterTicketState = docs => {
      const hasTicketState = filter(
        docs,
        ({ actionContent: { ticket_state } }) => !isNil(ticket_state),
      )

      return size(hasTicketState) !== size(docs) ? [] : docs
    }

    // Take out all records after perfect match. An editor may keep updating
    // docs after those docs are perfect-matched, one of the reason to do this
    // is to extend effective end date of a card reward. We should trim out
    // this kind of history data since those records are created on purpose
    // instead of fixing errors.
    const filterAfterMatched = docs => {
      const index = findIndex(
        docs,
        ({ actionContent }) =>
          actionContent.matched_state === MatchState.FULLY_MATCHED,
      )
      return index === -1 ? docs : slice(docs, 0, index + 1)
    }

    return pickBy(
      mapValues(sourceDocs, docs =>
        flow([filterTicketState, filterAfterMatched])(docs),
      ),
      negate(isEmpty),
    )
  }, [sourceDocs])

  return useMemo(
    () => ({
      loading: loadingHitoryData || loadingDoc || loardTargetId,
      editingHitoryOfDocs,
    }),
    [editingHitoryOfDocs, loadingHitoryData, loadingDoc, loardTargetId],
  )
}

function useFecthCompareFields(namespace) {
  const { loading, data } = useQuery(_get_connected, {
    client: kgClient,
    variables: {
      node_id: KG_NODES.myfinance_editor.STATISTIC_ROOT_ID,
    },
    skip: isNil(namespace),
  })

  return useMemo(() => {
    if (loading || isNil(get(data, 'get_connected'))) return null

    const schemasOfCurrentNamespace = map(
      SchemaNamespace[namespace],
      ([label]) => label,
    )
    return filter(
      map(get(data, 'get_connected'), node => {
        const fields = get(
          find(get(node, 'attributes'), { name: 'fields' }),
          'values',
        )
        return isNil(fields) ? null : { name: node.name, fields }
      }),
      result =>
        !isNil(result) && schemasOfCurrentNamespace.includes(result.name),
    )
  }, [loading, data, namespace])
}

function Duration({ onDuration }) {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  return (
    <Flex alignItems="center">
      <Box mx="2">開始日期</Box>
      <Calendar
        id="icon"
        value={startDate}
        onChange={({ value }) => {
          setStartDate(value)
          onDuration(!isNil(value) && !isNil(endDate) ? [value, endDate] : null)
        }}
        showIcon
      />
      <Box mx="2">結束日期</Box>
      <Calendar
        id="icon"
        value={endDate}
        onChange={({ value }) => {
          setEndDate(value)
          onDuration(
            !isNil(value) && !isNil(startDate) ? [startDate, value] : null,
          )
        }}
        showIcon
      />
    </Flex>
  )
}

Duration.propTypes = {
  onDuration: PropTypes.func.isRequired,
}

function SchemaSelector({ onSelect, compareNodes }) {
  const [selected, setSelected] = useState(null)
  const options = useMemo(
    () =>
      map(compareNodes, ({ name }) => ({
        label: SchemaLabels[name],
        name,
        value: SchemaStore[name],
      })),
    [compareNodes],
  )
  return (
    <Flex flex="0 0 500px" alignItems="center">
      <Box mx="2">選擇分析文件</Box>
      <Dropdown
        value={selected}
        options={options}
        onChange={({ value }) => {
          setSelected(value)
          onSelect(get(find(options, { value }), 'name'))
        }}
        style={{ flex: '1 1' }}
      />
    </Flex>
  )
}

SchemaSelector.propTypes = {
  compareNodes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      fields: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ),
  onSelect: PropTypes.func.isRequired,
}

export function StatisticView() {
  const { namespace, privilege } = useContext(ProjectContext)
  const [duration, setDuration] = useState(null)
  const [currentSchemaName, setCurrentSchemaName] = useState(null)
  const { editingHitoryOfDocs, loading } = useFetchHistory(
    duration,
    currentSchemaName,
    namespace,
  )
  const compareNodes = useFecthCompareFields(namespace)
  const fields = useMemo(
    () => get(find(compareNodes, { name: currentSchemaName }), 'fields'),
    [compareNodes, currentSchemaName],
  )
  const chartProps = useMemo(
    () => ({
      fields,
      currentSchema: get(SchemaStore, currentSchemaName),
      editingHitoryOfDocs,
    }),
    [fields, currentSchemaName, editingHitoryOfDocs],
  )

  if (!privilege.includes('supervisor')) {
    return (
      <Flex
        justifyContent="center"
        flexWrap="wrap"
        bg="Bisque"
        padding={2}
        width="100%"
      >
        <img alt="Not dispatched yet" src={Dobutsu} />
        <h1 style={{ flex: '1 0 100%', textAlign: 'center' }}>無讀取權限</h1>
      </Flex>
    )
  }

  return (
    <Flex flexWrap="wrap" bg="Bisque" padding={2} width="100%">
      <StyledOverlay active={loading} />
      <Flex flex="1 1 100%" alignItems="center" justifyContent="space-between">
        <Duration
          onDuration={duration => {
            setDuration(duration)
          }}
        />
        <SchemaSelector
          compareNodes={compareNodes}
          onSelect={currentSchemaName => {
            setCurrentSchemaName(currentSchemaName)
          }}
        />
      </Flex>
      <Flex flex="1 1 100%" justifyContent="center" flexWrap="wrap">
        {!isEmpty(editingHitoryOfDocs) && !loading && (
          <>
            <ChangeScoreChart
              {...chartProps}
              assigneeIndex={-1}
              title="更動次數統計"
            />
            <ChangeScoreChart
              {...chartProps}
              assigneeIndex={0}
              title="更動次數統計/A"
            />
            <ChangeScoreChart
              {...chartProps}
              assigneeIndex={1}
              title="更動次數統計/B"
            />
            <MergeChart {...chartProps} />
          </>
        )}
      </Flex>
    </Flex>
  )
}
