import { useState, useMemo, isValidElement } from 'react'
import PropTypes from 'prop-types'
import { isNil, compact } from 'lodash'
import { Dialog } from 'primereact/dialog'
import { Checkbox } from 'primereact/checkbox'
import { FaInfoCircle } from 'react-icons/fa'
import { TiWarning } from 'react-icons/ti'
import { MdError } from 'react-icons/md'
import { Text, Flex } from '@changingai/react-editor-common-component'

import { ActionButtons } from './ActionButtons'
import { StatusBarRoot } from './StatusBarRoot'

import theme from '@theme'

export function ConfirmDialog({
  onHide,
  title = '',
  message = '',
  action = '',
  userdata,
  needPrivilege = false,
  onYes,
}) {
  const [confirmPrivilege, setConfirmPrivilege] = useState(false)
  const [loading, setLoading] = useState(false)

  const [Icon, color] = useMemo(() => {
    if (title.toLowerCase() === 'warning') {
      return [TiWarning, theme.colors.warning]
    } else if (title.toLowerCase() === 'error') {
      return [MdError, theme.colors.danger]
    }

    return [FaInfoCircle, theme.colors.info]
  }, [title])

  return (
    <Dialog
      header={title}
      appendTo={document.body}
      visible={true}
      style={{ width: '500px' }}
      modal={true}
      onHide={onHide}
    >
      <Flex width="100%" alignItems="center" jc="flex-start">
        <Icon style={{ color, width: '50px', height: '50px' }} />
        <Flex
          flex="1 0"
          fontSize="1.5em"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
        >
          {isValidElement(message) && message}
          {!isValidElement(message) && <Text>{message}</Text>}
        </Flex>
      </Flex>
      {needPrivilege && (
        <Flex width="100%" justifyContent="center" alignItems="center" my="3">
          <Checkbox
            inputId="confirmPrivilege"
            onChange={e => {
              setConfirmPrivilege(e.checked)
            }}
            checked={confirmPrivilege}
          />
          <Text ml="2" color="red">
            確定使用管理者權限？
          </Text>
        </Flex>
      )}

      <StatusBarRoot>
        <ActionButtons
          buttons={{
            right: compact([
              !isNil(onYes) && {
                label: 'Yes',
                action: 'Yes',
                className: 'p-button-primary',
                icon: loading ? 'pi pi-spin pi-spinner' : 'pi pi-check',
                enable: !needPrivilege || confirmPrivilege,
              },
              {
                label: 'No',
                action: 'No',
                className: 'p-button-secondary',
                icon: 'pi pi-times',
              },
            ]),
          }}
          onClicked={async name => {
            if (name === 'Yes') {
              setLoading(true)
              await onYes(action, userdata)
              setLoading(false)
              return onHide()
            }

            return onHide()
          }}
        />
      </StatusBarRoot>
    </Dialog>
  )
}

ConfirmDialog.propTypes = {
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  action: PropTypes.string,
  userdata: PropTypes.any,
  onYes: PropTypes.func.isRequired,
  needPrivilege: PropTypes.bool,
}
