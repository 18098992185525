// Currently, reward editors work in staging. This value should be changed after
// we migrate to production cluster.
const isMainStream =
  window.location.hostname.search('dev') === -1 &&
  window.location.hostname.search('localhost') === -1
// Use red color to tell the current user that he is not using mainstream editor,
// what he changed will be overwrited periodically.
const identifyColor = isMainStream ? '#4267b2' : 'IndianRed'

// Default Space for Margin and Padding
const DEFAULT_SPACES = [0, 4, 8, 16, 20, 24, 32, 40, 48, 56, 64, 128, 256, 512]

// Default FontSizes
const DEFAULT_FONT_SIZES = [11, 12, 13, 14, 15, 16, 18, 20, 24, 28, 32, 48, 64]
// Alias
DEFAULT_FONT_SIZES.small = DEFAULT_FONT_SIZES[0]
DEFAULT_FONT_SIZES.body = DEFAULT_FONT_SIZES[1]
DEFAULT_FONT_SIZES.introduction = DEFAULT_FONT_SIZES[2]
DEFAULT_FONT_SIZES.intro = DEFAULT_FONT_SIZES[2] // alias of introduction
DEFAULT_FONT_SIZES.h4 = DEFAULT_FONT_SIZES[3]
DEFAULT_FONT_SIZES.h3 = DEFAULT_FONT_SIZES[4]
DEFAULT_FONT_SIZES.h2 = DEFAULT_FONT_SIZES[5]
DEFAULT_FONT_SIZES.h1 = DEFAULT_FONT_SIZES[6]

// Default z-index. DEFAULT_ZINDICES[10] is for dev, don't use it.
const DEFAULT_ZINDICES = [0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]
DEFAULT_ZINDICES.modal = DEFAULT_ZINDICES[9]
DEFAULT_ZINDICES.topmost = DEFAULT_ZINDICES[9]
DEFAULT_ZINDICES.header = DEFAULT_ZINDICES[8]
DEFAULT_ZINDICES.bottommost = -900
DEFAULT_ZINDICES.l1 = DEFAULT_ZINDICES[1]
DEFAULT_ZINDICES.l2 = DEFAULT_ZINDICES[2]
DEFAULT_ZINDICES.l3 = DEFAULT_ZINDICES[3]
DEFAULT_ZINDICES.l4 = DEFAULT_ZINDICES[4]
DEFAULT_ZINDICES.l5 = DEFAULT_ZINDICES[5]
DEFAULT_ZINDICES.l6 = DEFAULT_ZINDICES[6]
DEFAULT_ZINDICES.l7 = DEFAULT_ZINDICES[7] // Floating Menu
DEFAULT_ZINDICES.l8 = DEFAULT_ZINDICES[8] // MenuBar
DEFAULT_ZINDICES.l9 = DEFAULT_ZINDICES[9] // LoginButton

const DEFAULT_BORDER_RADIUS = [0, 2, 4, 8, 14, 9999, '100%']

// Default theme color
const DEFAULT_THEME_COLOR = {
  menu: identifyColor,
  icon: '#3257a2',
  paleGrey: '#f2f6fe',
  veryLightGrey: '#c0c0c0',
  brownGrey: '#818181',
  primary: '#35b9e7',
  secondary: '#464646',
  dark: '#333333',
  light: '#eee',
  lightYello: '#FFFF00',
  warning: '#e5c378',
  danger: '#ed553b',
  info: 'green',
  cellError: '#dbacac',
  hover: 'rgba(255, 255, 255, 0.5)',
  active: 'rgba(255, 255, 255, 0.7)',
  textLight: 'rgba(40,40,40,0.4)',
  electricBlue: '#0d45fd',
  azure: '#0d98fd',
  darkHotPink: '#e5006f',
  panelBG: 'rgb(238, 238, 221)',
  darkPanelBG: 'rgb(218, 218, 201)',
  /***
   *
   * other color palette
   *
   * */
  // other colors. etc: blacks.3
  darkHotPinks: [
    'rgba(229,0,111,.05)',
    'rgba(229,0,111,.1)',
    'rgba(229,0,111,.2)',
    'rgba(229,0,111,.3)',
    'rgba(229,0,111,.4)',
    'rgba(229,0,111,.5)',
    'rgba(229,0,111,.6)',
    'rgba(229,0,111,.7)',
    'rgba(229,0,111,.8)',
    'rgba(229,0,111,.9)',
  ],
  reddishOranges: [
    'rgba(254,95,22,.05)',
    'rgba(254,95,22,.1)',
    'rgba(254,95,22,.2)',
    'rgba(254,95,22,.3)',
    'rgba(254,95,22,.4)',
    'rgba(254,95,22,.5)',
    'rgba(254,95,22,.6)',
    'rgba(254,95,22,.7)',
    'rgba(254,95,22,.8)',
    'rgba(254,95,22,.9)',
  ],
  azures: [
    'rgba(13,152,253,.05)',
    'rgba(13,152,253,.1)',
    'rgba(13,152,253,.2)',
    'rgba(13,152,253,.3)',
    'rgba(13,152,253,.4)',
    'rgba(13,152,253,.5)',
    'rgba(13,152,253,.6)',
    'rgba(13,152,253,.7)',
    'rgba(13,152,253,.8)',
    'rgba(13,152,253,.9)',
  ],
  electricBlues: [
    'rgba(13,69,253,.05)',
    'rgba(13,69,253,.1)',
    'rgba(13,69,253,.2)',
    'rgba(13,69,253,.3)',
    'rgba(13,69,253,.4)',
    'rgba(13,69,253,.5)',
    'rgba(13,69,253,.6)',
    'rgba(13,69,253,.7)',
    'rgba(13,69,253,.8)',
    'rgba(13,69,253,.9)',
  ],
  blacks: [
    'rgba(40,40,40,.05)',
    'rgba(40,40,40,.1)',
    'rgba(40,40,40,.2)',
    'rgba(40,40,40,.3)',
    'rgba(40,40,40,.4)',
    'rgba(40,40,40,.5)',
    'rgba(40,40,40,.6)',
    'rgba(40,40,40,.7)',
    'rgba(40,40,40,.8)',
    'rgba(40,40,40,.9)',
  ],
  // other white colors. etc: white.5
  whites: [
    'rgba(255,255,255,.05)',
    'rgba(255,255,255,.1)',
    'rgba(255,255,255,.2)',
    'rgba(255,255,255,.3)',
    'rgba(255,255,255,.4)',
    'rgba(255,255,255,.5)',
    'rgba(255,255,255,.6)',
    'rgba(255,255,255,.7)',
    'rgba(255,255,255,.8)',
    'rgba(255,255,255,.9)',
  ],
}

const theme = {
  colors: DEFAULT_THEME_COLOR,
  space: DEFAULT_SPACES,
  sizes: DEFAULT_SPACES,
  fontSizes: DEFAULT_FONT_SIZES,
  zIndices: DEFAULT_ZINDICES,
  radii: DEFAULT_BORDER_RADIUS,
}

export default theme
