import Joi from 'joi'

import { KG_NODES, namespaces, useGetConnectedNodes } from '@common'

import {
  NullableString,
  RequiredString,
  SchemaEmptyPositive,
  addSchema,
  createBlankDoc,
  RequiredKGNodeIdString,
  NullableKGNodeIdString,
  longString,
  matchStateSchema,
  schemaToPropTypes,
  shortString,
  ticketDocSchema,
  useBenefitTypes,
} from '@schema'
import { every, map, pick, some, keys, isNull } from 'lodash'

export const amountRangeSchema = {
  according_to: {
    schema: RequiredString.max(shortString),
    type: 'string',
    label: '依據條件',
  },
  according_to_source: {
    schema: RequiredString.max(shortString),
    type: 'string',
    label: '依據條件來源',
    skipCompare: true,
  },
  min: {
    schema: Joi.number()
      .positive()
      .required(),
    type: 'number',
    label: '最小倍率',
  },
  max: {
    schema: Joi.number()
      .positive()
      .required(),
    type: 'number',
    label: '最大倍率',
  },
}

export const benefitConceptPaybackSchema = {
  benefit_concept_id: {
    schema: RequiredKGNodeIdString,
    referToKG: 'insurance',
    type: 'string',
    label: '公版解釋',
    useNodes: () => useGetConnectedNodes(roots.concept),
  },
  prefix: {
    schema: NullableString.max(shortString),
    type: 'string',
    label: '前綴詞',
  },
  ratio: {
    schema: Joi.number().allow(null, ''),
    type: 'number',
    label: '保額倍率',
  },
  suffix: {
    schema: NullableString.max(shortString),
    type: 'string',
    label: '後綴詞',
  },
  join_premium: { schema: Joi.bool(), type: 'bool', label: '與《保費》取大' },
  join_policy_value: {
    schema: Joi.bool(),
    type: 'bool',
    label: '與《保價金》取大',
  },
  limited_payback: {
    schema: Joi.bool(),
    type: 'bool',
    label: '1.實支實付，限額理賠',
  },
  depend_condition: {
    schema: Joi.bool(),
    type: 'bool',
    label: '2.要看年度/年齡、程度/項目或其他情況',
  },
  reduce_previous_payback: {
    schema: Joi.bool(),
    type: 'bool',
    label: '3.要扣除其他已領金額',
  },
  limited_insured_scope: {
    schema: Joi.bool(),
    type: 'bool',
    label: '4.保障範圍比公版窄 (例: 限1級失能) 或 日額選擇權',
  },
  has_special_bonus: {
    schema: Joi.bool(),
    type: 'bool',
    label: '+ 除上述金額外，有其他增額(如高齡、外溢等)',
  },
  return_unused_premium: {
    schema: Joi.bool(),
    type: 'bool',
    label: '+ 加計未到期保費',
  },
  has_similar_payback: {
    schema: Joi.bool(),
    type: 'bool',
    label: '顯示金額時暗示還有其他項目 (實支實付)',
  },
}

export const coefCellSchema = Joi.string().pattern(/^([0-9]+\.)?[0-9]+$/)
export const COEF_LENGTH = 112
const coefSchema = Joi.alternatives().try(
  Joi.array()
    .items(
      Joi.number()
        .min(0)
        .required(),
    )
    .length(COEF_LENGTH),
  Joi.array().length(0),
)

export const calculateFactorSchema = {
  base: {
    schema: Joi.number()
      .allow(null, '')
      .required(),
    type: 'number',
    label: '基礎係數',
  },
  installment: {
    schema: Joi.number()
      .allow(null, '')
      .required(),
    type: 'number',
    label: '分期折現既期望值',
  },
  expectation: {
    schema: Joi.number()
      .allow(null, '')
      .required(),
    type: 'number',
    label: '表格範圍期望值',
  },
  policy_year: {
    schema: coefSchema,
    type: 'arrayOf(number)',
    label: '保單年度係數',
  },
  attained_age: {
    schema: coefSchema,
    type: 'arrayOf(number)',
    label: '保險年齡係數',
  },
}

export const calculateFactorsSchema = {
  amount_ratio: {
    schema: calculateFactorSchema,
    type: 'objectOf(calculateFactorSchema)',
    label: '保額參數',
    defaultValue: createBlankDoc(calculateFactorSchema, false),
  },
  total_fee_ratio: {
    schema: calculateFactorSchema,
    type: 'objectOf(calculateFactorSchema)',
    label: '年繳保險費總和參數',
    defaultValue: createBlankDoc(calculateFactorSchema, false),
  },
  policy_value_ratio: {
    schema: calculateFactorSchema,
    type: 'objectOf(calculateFactorSchema)',
    label: '保單價值準備金參數',
    defaultValue: createBlankDoc(calculateFactorSchema, false),
  },
}

const roots = {
  currencyRoot: [
    {
      node_id: KG_NODES.insurance.CURRENCY_ROOT_ID,
      namespace: 'insurance',
    },
  ],
  concept: [
    {
      node_id: KG_NODES.insurance.BENEFIT_CONCEPT_MEDIAWIKI_ROOT_ID,
      namespace: 'insurance',
    },
  ],
  actuarialRule: [
    {
      node_id: KG_NODES.insurance.ACTUARIAL_RULE,
      namespace: 'insurance',
    },
  ],
}

export const calculateRuleSchema = {
  rule_id: {
    schema: RequiredKGNodeIdString,
    type: 'string',
    label: '計算公式',
    referToKG: 'insurance',
    useNodes: () => useGetConnectedNodes(roots.actuarialRule),
  },
  ...calculateFactorsSchema,
  detail: {
    schema: NullableString.max(longString),
    type: 'string',
    label: '詳細資訊',
  },
}

export const benefitSchema = {
  ...ticketDocSchema,
  ...matchStateSchema,
  pay_method: {
    label: '給付方式(摘要)',
    schema: RequiredString.max(longString),
    type: 'string',
  },
  benefit_name: {
    label: '給付項目名稱',
    schema: RequiredString.max(shortString),
    type: 'string',
  },
  clause_main: {
    label: '條款原文(給付)',
    schema: Joi.string()
      .max(2000)
      .required(),
    type: 'string',
  },
  clause_coverage: {
    label: '條款原文(承保範圍)',
    schema: NullableString.max(longString),
    type: 'string',
  },
  clause_exclusion: {
    label: '條款原文(不保項目)',
    schema: NullableString.max(longString),
    type: 'string',
  },
  clause_exception: {
    label: '條款原文(除外責任)',
    schema: NullableString.max(longString),
    type: 'string',
  },
  clause_restriction: {
    label: '條款原文(給付限制)',
    schema: NullableString.max(longString),
    type: 'string',
  },
  clause_application: {
    label: '條款原文(申請文件)',
    schema: NullableString.max(longString),
    type: 'string',
  },
  waiting_period: {
    label: '等待期',
    schema: NullableString.max(shortString),
    type: 'string',
  },
  elimination_period: {
    label: '免責期',
    schema: NullableString.max(shortString),
    type: 'string',
  },
  benefit_type: {
    label: '給付方式類型',
    referToKG: 'insurance',
    schema: NullableKGNodeIdString,
    type: 'string',
    useNodes: useBenefitTypes,
  },
  request_unit: {
    label: '申請單位',
    schema: NullableString.max(shortString),
    type: 'string',
  },
  amount_ratio: {
    label: '基底保險金額的倍率',
    schema: SchemaEmptyPositive,
    type: 'number',
  },
  amount_range: {
    schema: Joi.array().required(),
    type: 'arrayOf(amountRangeSchema)',
    label: '給付倍率（其他）',
  },
  ratio_without_NHI: {
    label: '非健保就醫給付比例',
    schema: Joi.alternatives().try(
      Joi.string().valid(''),
      Joi.number()
        .max(1)
        .min(0),
    ),
    type: 'number',
  },
  currency_id: {
    schema: NullableKGNodeIdString,
    type: 'node',
    label: '幣別',
    useNodes: () => useGetConnectedNodes(roots.currencyRoot),
  },
  calculate_rules: {
    schema: Joi.array()
      .required()
      .custom((rules, helper) => {
        const factorFieldnames = keys(calculateFactorsSchema)
        const hasBlankFactorRules = rule =>
          every(factorFieldnames, fieldname => every(rule[fieldname], isNull))

        if (some(rules, hasBlankFactorRules)) {
          return helper.message(
            `${map(
              pick(calculateFactorsSchema, factorFieldnames),
              'label',
            ).join('/')}不可全為空值`,
          )
        }
        return rules
      }),
    type: 'arrayOf(calculateRuleSchema)',
    label: '精算公式',
  },
  benefit_concept_paybacks: {
    schema: Joi.array().required(),
    type: 'arrayOf(benefitConceptPaybackSchema)',
    label: '公版理賠資訊',
  },
}

addSchema({ amountRangeSchema })
addSchema({ calculateFactorSchema })
addSchema({ calculateRuleSchema })
addSchema({ benefitConceptPaybackSchema })
addSchema({ benefitSchema }, '給付項目', namespaces.insurance.value)

export const BenefitPropType = schemaToPropTypes(benefitSchema)
export const BenefitConceptPaybackPropType = schemaToPropTypes(
  benefitConceptPaybackSchema,
)
export const CalculateFactorPropType = schemaToPropTypes(calculateFactorSchema)
export const CalculateRulePropType = schemaToPropTypes(calculateRuleSchema)
