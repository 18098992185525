import { get, isNil, map, includes, sortBy, every, some, find } from 'lodash'

export function addViewManifestInner(target, config) {
  if (every([get(config, 'listview'), get(config, 'compareview')], isNil)) {
    throw new Error('listview and compareview are both null')
  }
  if (
    some(
      map(
        [
          'loader',
          'schema',
          'namespace',
          'backendDocType',
          'privilege',
          'useTranspile',
        ],
        key => get(config, key),
      ),
      isNil,
    )
  ) {
    throw new Error('Some mandatory fields are null')
  }

  return includes(map(target, 'schema'), config.schema)
    ? target
    : sortBy([...target, config], 'order')
}

let viewConfig = []
export function addViewManifest(config) {
  viewConfig = addViewManifestInner(viewConfig, config)
}

export function getViewManifest() {
  return viewConfig
}

export function findManifest(backendDocType, target) {
  return get(find(getViewManifest(), { backendDocType }), target)
}
