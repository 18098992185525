import PropTypes from 'prop-types'
import { partial } from 'lodash'

import { TicketState, namespaces } from '@common'

import { useTranspile } from './common'
import { CardRewardEditDialog } from './editor'
import { docPrivilege } from './config'
import { CardRewardContext, useCardRewardContext } from './context'
import { cardRewardSchema } from './schema'

import { useFetchDocsAndTickets } from '../ComposedView'
import OwnedDocSummaryModel from '../OwnedDocSummaryModel'

import { _queryRewardCard } from './composed.gql'

const fullAccessStates = [TicketState.MERGE, TicketState.FINISH]
const useController = partial(
  useFetchDocsAndTickets,
  _queryRewardCard,
  fullAccessStates,
  namespaces.card.value,
  CardRewardEditDialog,
)

const defaultSummary = {
  selected: [
    'type_id',
    'uploader',
    'basic_r',
    'csp_channel_ids',
    'csp_target_ids',
    're_channel_ids',
    're_target_ids',
  ],
  filters: {},
}

const OwnedRewardListView = props => {
  const context = useCardRewardContext()
  return (
    <CardRewardContext.Provider value={context}>
      <OwnedDocSummaryModel
        {...props}
        useController={useController}
        useTranspile={useTranspile}
        defaultSummary={defaultSummary}
        requiredPrivilege={[docPrivilege]}
        currentSchema={cardRewardSchema}
        showIdInputIcon={true}
      />
    </CardRewardContext.Provider>
  )
}

OwnedRewardListView.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default OwnedRewardListView
