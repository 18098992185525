import gql from 'graphql-tag'

const COMMENT = `
  by
  comment
  modifyAt
`

const MERGEREQUEST = `
  _id
  target_id
  content
  status
  comments {
    ${COMMENT}
  }
  uploader
  reviewer
`

const MRRESULT = `
  success
  request {
    ${MERGEREQUEST}
  }
  reason
`

export const CCE_Q_MR = gql`
  query($mine: Boolean!, $statuses: [MRStatus!], $targets: [String!]) {
    queryMergeRequests(mine: $mine, statuses: $statuses, targets: $targets) {
      ${MERGEREQUEST}
    }
  }
`

export const CCE_M_SUBMIT_MR = gql`
  mutation($profile: MRProfile!) {
    submitMergeRequest(profile: $profile) {
      ${MRRESULT}
    }
  }
`
