import { useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  isNil,
  get,
  map,
  size,
  mapValues,
  sortBy,
  has,
  keys,
  find,
  includes,
  compact,
  uniq,
  flatMap,
  fromPairs,
  zip,
  forEach,
  every,
  pickBy,
} from 'lodash'
import { Chart } from 'primereact/chart'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Flex, Box } from '@changingai/react-editor-common-component'

import { exportDoc, csvBlob, MatchState, TicketState } from '@common'

import { chartBGColors, stackedOptions } from './common'

function groupPairedDoc(editingHitoryOfDocs) {
  const pairedIds = []
  return compact(
    map(keys(editingHitoryOfDocs), id => {
      if (!includes(pairedIds, id)) {
        const matchedHistory = find(editingHitoryOfDocs[id], {
          actionContent: { matched_state: MatchState.FULLY_MATCHED },
        })
        if (matchedHistory) {
          const pairedId = get(matchedHistory, 'actionContent.matched_target')
          if (has(editingHitoryOfDocs, pairedId)) {
            pairedIds.push(id, pairedId)
            // perfect match at create time.
            if (
              size(editingHitoryOfDocs[id]) === 1 ||
              size(editingHitoryOfDocs[pairedId]) === 1
            )
              return

            return [
              editingHitoryOfDocs[id].reverse(),
              editingHitoryOfDocs[pairedId].reverse(),
            ]
          }
        }
      }
    }),
  )
}

function computeMergePreferenceScore(editingHitoryOfDocs, fields) {
  const pairedDocs = groupPairedDoc(editingHitoryOfDocs)

  const uploaders = fromPairs(
    map(
      uniq(
        flatMap(keys(editingHitoryOfDocs), id =>
          uniq(map(editingHitoryOfDocs[id], 'actor')),
        ),
      ),
      uploader => [uploader, { self: 0, another: 0 }],
    ),
  )

  forEach(pairedDocs, paired => {
    const mixed = zip(paired[0], paired[1])

    forEach(mixed, (paired, index) => {
      // C merged docs of A & B. Take out this kind of history log.
      if (
        every(
          paired,
          history =>
            get(history, 'actor') !== get(history, 'actionContent.uploader'),
        )
      )
        return

      forEach(paired, (history, pairIndex) => {
        // Only track history items created in MERGE state
        if (get(history, 'actionContent.ticket_state') !== TicketState.MERGE)
          return
        const next = get(mixed, `${index + 1}.${pairIndex}`)
        if (!next) return
        forEach(fields, field => {
          if (history.actionContent[field] !== next.actionContent[field]) {
            if (history.actor === history.actionContent.uploader)
              uploaders[history.actor].self += 1
            else uploaders[history.actor].another += 1
          }
        })
      })
    })
  })

  return pickBy(
    mapValues(uploaders, (values, key) => ({
      uploader: key,
      ...values,
    })),
    ({ self, another }) => self + another > 0,
  )
}

export function MergeChart({ fields, editingHitoryOfDocs }) {
  const scoreOfUploader = useMemo(
    () =>
      isNil(fields)
        ? null
        : sortBy(
            computeMergePreferenceScore(editingHitoryOfDocs, fields),
            ({ self, another }) => self / another,
          ),
    [editingHitoryOfDocs, fields],
  )

  const stackedData = useMemo(
    () =>
      isNil(scoreOfUploader)
        ? null
        : {
            labels: map(scoreOfUploader, ({ uploader }) =>
              uploader.substr(0, uploader.indexOf('@')),
            ),
            datasets: map(
              [['self', '選擇自己'], ['another', '選擇別人']],
              ([key, label], index) => ({
                type: 'bar',
                label,
                backgroundColor: chartBGColors[index],
                data: map(
                  scoreOfUploader,
                  data => data[key] / (data['self'] + data['another']),
                ),
              }),
            ),
          },
    [scoreOfUploader],
  )

  return (
    <Box width="80%">
      <Flex width="100%" justifyContent="center" fontSize="h1">
        合併意向統計表
      </Flex>
      <Flex width="100%" justifyContent="flex-end">
        <Button
          label="Export"
          className="p-button-primary"
          icon="pi pi-save"
          onClick={() => {
            exportDoc(
              'merge_statistic.csv',
              csvBlob([
                ['上傳者', '選擇自己', '選擇別人'],
                ...map(scoreOfUploader, row => [
                  row.uploader,
                  ...row.scores,
                  row.count,
                ]),
              ]),
            )
          }}
        />
      </Flex>
      <Chart type="bar" data={stackedData} options={stackedOptions} />
      <DataTable
        value={scoreOfUploader}
        paginator={false}
        style={{ width: '100%', margin: '4px 0' }}
      >
        <Column
          header="上傳者"
          field="uploader"
          body={({ uploader }) => (
            <Box>{uploader.substr(0, uploader.indexOf('@'))}</Box>
          )}
        />
        {map(
          [['self', '選擇自己'], ['another', '選擇別人']],
          ([key, header]) => (
            <Column
              key={key}
              sortable
              header={header}
              body={item => <Box>{item[key]}</Box>}
            />
          ),
        )}
      </DataTable>
    </Box>
  )
}

MergeChart.propTypes = {
  editingHitoryOfDocs: PropTypes.object.isRequired,
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
}
