import { useCallback, useContext, useMemo } from 'react'
import { find, map, isNil, castArray, size, sumBy, toUpper, get } from 'lodash'

import {
  ProjectContext,
  TicketPropType,
  TicketState,
  isLocalDoc,
  KG_NODES,
  namespaces,
  useDialog,
} from '@common'
import { createBlankDoc } from '@schema'
import { cceClient } from '@gql'

import { useTranspile, useUploadProductOfTicket } from './common'
import { useProductContext, ProductContext } from './context'
import { docPrivilege } from './config'
import { productSchema } from './schema'
import { ProductEditDialog } from './editor'

import { useCacheDocs } from '../Common'
import { cloneTicketDoc } from '../DocHelper'
import TicketSummaryModel from '../TicketSummaryModel'
import { bindSchemaWithSummaryLayout } from '../SummaryFields'
import { useReferenceBase, filterTicketRef } from '../Reference'
import {
  useMutateTicket,
  useSoftTicketRef,
  computeDocsOfTicket,
  useFetchDocOfTicket,
  createBlankTicketable,
} from '../TicketHelper'
import { useDeleteDocWithHistory } from '../Uploader'

import { _queryTickets, _deleteProduct, _queryProduct } from './listview.gql'

bindSchemaWithSummaryLayout(productSchema, {
  contract_ids: {
    cols: 2,
  },
  intro: {
    cols: 2,
  },
})

function useReference(doc) {
  const data = useReferenceBase('product', filterTicketRef, doc)
  const ticketRef = useSoftTicketRef(doc, namespaces.insurance.value)
  return useMemo(() => {
    if (isNil(ticketRef) || isNil(get(data, 0))) return [null, null]
    return [size(ticketRef) + get(data, 0), get(data, 1)]
  }, [data, ticketRef])
}

const fullAccessStates = [TicketState.MERGE, TicketState.FINISH]
function useController(ticket, selectedFields) {
  const { email, peepMode } = useContext(ProjectContext)
  const products = useFetchDocOfTicket(ticket, productSchema, selectedFields)
  const { updated, deleteCache, updateCache, docs } = useCacheDocs(
    email,
    peepMode,
    ticket,
    products,
    fullAccessStates,
  )

  const createDoc = useCallback(
    uploader => {
      const doc = {
        ...createBlankDoc(productSchema, true),
        ...createBlankTicketable(ticket, uploader),
        company_id: ticket.scope_id,
        currency_ids: [KG_NODES.insurance.CURRENCY_NTD],
      }

      updateCache(doc)
      return doc
    },
    [updateCache, ticket],
  )

  const remove = useDeleteDocWithHistory(productSchema, ids => {
    cceClient.mutate({
      mutation: _deleteProduct,
      variables: {
        ids: castArray(ids),
      },
    })
  })
  const { deleteTicket } = useMutateTicket()
  const localDeleteDoc = useCallback(
    async _id => {
      deleteCache(_id)
      const deleted = find(docs, { _id })
      if (deleted && !isLocalDoc(deleted)) {
        // The scope_id of plan and feetable tickets is the id of product.
        // Make sure we delete both tickets which are associated with the current
        // deleted product.
        const {
          data: { queryTickets },
        } = await cceClient.query({
          query: _queryTickets,
          variables: {
            filter: {
              scope_ids: [_id],
            },
            namespace: toUpper('insurance'),
          },
        })
        if (
          size(queryTickets) > 0 &&
          sumBy(queryTickets, computeDocsOfTicket) !== 0
        )
          throw new Error(`Plan/FeeTable Ticket sum(own_docs) is not zero.`)
        await Promise.all(
          map(queryTickets, ({ _id, scope_type }) =>
            deleteTicket([_id], scope_type),
          ),
        )
        remove(deleted)
      }
    },
    [deleteCache, docs, remove, deleteTicket],
  )

  const cloneDoc = useCallback(
    async doc => {
      const cloned = await cloneTicketDoc(
        _queryProduct,
        doc,
        ticket,
        email,
        productSchema,
      )
      updateCache(cloned)
    },
    [updateCache, email, ticket],
  )

  const [showDialog, renderDialog] = useDialog(ProductEditDialog)
  const showDoc = useCallback(
    (doc, crud) => {
      showDialog({
        doc,
        ticket,
        crud,
      })
    },
    [ticket, showDialog],
  )

  const upload = useUploadProductOfTicket(ticket)

  const uploadDoc = useCallback(context => upload(ticket, context, email), [
    upload,
    ticket,
    email,
  ])

  const { isReady: isContextReady } = useContext(ProductContext)
  return {
    isReady: !isNil(products) && isContextReady,
    docs,
    createDoc,
    updateDoc: updateCache,
    deleteDoc: localDeleteDoc,
    cloneDoc,
    showDoc,
    renderDoc: renderDialog,
    updated,
    uploadDoc,
    useReference,
  }
}

const defaultSummary = {
  selected: ['company_id', 'title'],
  filters: {},
}

const ProductListView = props => {
  const context = useProductContext()
  return (
    <ProductContext.Provider value={context}>
      <TicketSummaryModel
        {...props}
        useController={useController}
        useTranspile={useTranspile}
        defaultSummary={defaultSummary}
        requiredPrivilege={[docPrivilege]}
        currentSchema={productSchema}
      />
    </ProductContext.Provider>
  )
}

ProductListView.propTypes = {
  ticket: TicketPropType.isRequired,
}

export default ProductListView
