import { useEffect, useMemo, useState } from 'react'
import uuid from 'react-uuid'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import remarkBreaks from 'remark-breaks'
import { getDefaultToolbarCommands } from 'react-mde'
import { ImCross, ImPencil } from 'react-icons/im'
import { Tooltip } from 'primereact/tooltip'
import { get, map, find, isNil, attempt, keys } from 'lodash'
import { Text } from '@changingai/react-editor-common-component'
import { schemaToPropTypes } from '@schema'
import { MarkDownEditor } from '@widget'
import {
  isValidAmount,
  amountOptionProtocol,
  amountOptionSchema,
  benefitsRefSchema,
  computeAmount,
} from './schema'

export function LinkRender({ node, linkChildren, amountOptions }) {
  const [protocol, toolTip, error, amount] = useMemo(() => {
    const { protocol, pathname: option_id, searchParams } = attempt(
      url => new URL(url),
      node.url,
    )
    if (protocol !== amountOptionProtocol) return [protocol, null, false]

    const amountOption = find(amountOptions, {
      option_id: option_id,
    })

    if (isNil(amountOption))
      return [protocol, `${option_id} not found`, true, 'Error']

    const { minAmount, multiplier, amount } = computeAmount(
      amountOption,
      searchParams.get('multiplier'),
    )
    return [
      protocol,
      `${get(node, 'children.0.value')}\n(${
        amountOption.name
      }: ${minAmount.toLocaleString()} * ${multiplier})`,
      !isValidAmount(amount, searchParams.get('multiplier')),
      `${amount.toLocaleString()} 元`,
    ]
  }, [node, amountOptions])

  if (protocol !== 'option:')
    return (
      <a href={node.url} target="_blank" rel="noreferrer">
        {get(linkChildren, '0.props.children')}
      </a>
    )

  return (
    <>
      <Tooltip target=".amountRender" />
      <Text
        className="amountRender"
        data-pr-tooltip={toolTip}
        bg={error ? 'danger' : 'azures.2'}
      >
        {amount}
      </Text>
    </>
  )
}

LinkRender.propTypes = {
  node: PropTypes.object.isRequired,
  linkChildren: PropTypes.array.isRequired,
  amountOptions: PropTypes.arrayOf(schemaToPropTypes(amountOptionSchema))
    .isRequired,
}

export function extractOptions(brief) {
  return map(
    [
      ...(brief || '').matchAll(
        /\[[^\]]*\]\(option:([\w-]+)\?multiplier=([\d.]+)\)/g,
      ),
    ],
    ([, id, multiplier]) => ({ id, multiplier }),
  )
}

function loadSuggestions(amountOptions) {
  return Promise.resolve(
    map(amountOptions, ({ name, option_id }) => ({
      preview: name,
      value: `[](option:${option_id}?multiplier=1)`,
    })),
  )
}

export function useMarkDownRenderer(amountOptions) {
  const renderers = useMemo(
    () => ({
      link: ({ node, children }) => (
        <LinkRender
          node={node}
          linkChildren={children}
          amountOptions={amountOptions}
        />
      ),
    }),
    [amountOptions],
  )
  return renderers
}

const iconStyle = {
  height: '1em',
  width: '1em',
  color: 'black',
}

const CrossIcon = () => <ImCross style={iconStyle} />
const PencilIcon = () => <ImPencil style={iconStyle} />

export function PaybackEditor({ value, onChange, amountOptions }) {
  const renderers = useMarkDownRenderer(amountOptions)

  const customCommands = useMemo(() => {
    const commands = {
      'paste-cross': {
        name: 'paste-cross',
        icon: CrossIcon,
        execute: ({ textApi }) => {
          textApi.replaceSelection('×')
        },
      },
    }
    if (get(amountOptions, '0.option_id'))
      commands['option-template'] = {
        name: 'option-template',
        icon: PencilIcon,
        execute: ({ textApi, initialState: { selectedText } }) => {
          textApi.replaceSelection(
            `[${selectedText}](option:${get(
              amountOptions,
              '0.option_id',
            )}?multiplier=1)`,
          )
        },
      }
    return commands
  }, [amountOptions])

  return (
    <MarkDownEditor
      previewRenderers={renderers}
      value={value}
      onChange={onChange}
      loadSuggestions={() => loadSuggestions(amountOptions)}
      previewPlugins={[remarkBreaks]}
      commands={customCommands}
      toolbarCommands={[...getDefaultToolbarCommands(), keys(customCommands)]}
    />
  )
}

PaybackEditor.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  amountOptions: PropTypes.arrayOf(schemaToPropTypes(amountOptionSchema)),
}

export function PaybackRenderer({ payback_sample, onValid, amountOptions }) {
  const [id] = useState(uuid())
  const renderers = useMarkDownRenderer(amountOptions)

  useEffect(() => {
    onValid('payback_sample', payback_sample, {
      key: id,
      currentSchema: benefitsRefSchema,
      context: { amountOptions },
    })
    return function cleanupDirtyAndError() {
      onValid('payback_sample', null, {
        clean: true,
        key: id,
        currentSchema: benefitsRefSchema,
      })
    }
  }, [onValid, id, payback_sample, amountOptions])
  return (
    <ReactMarkdown
      source={payback_sample}
      plugins={[remarkBreaks]}
      renderers={renderers}
    />
  )
}

PaybackRenderer.propTypes = {
  payback_sample: PropTypes.string.isRequired,
  onValid: PropTypes.func.isRequired,
  amountOptions: PropTypes.arrayOf(schemaToPropTypes(amountOptionSchema)),
}
