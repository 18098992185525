import { useMemo } from 'react'
import {
  size,
  isError,
  attempt,
  isNil,
  compact,
  map,
  find,
  get,
  startsWith,
} from 'lodash'
import { useQuery } from '@apollo/client'

import { isLocalDoc } from '@common'
import { cceClient } from '@gql'

import { getViewManifest } from './Config'

import { _downloadReferencedDocs } from './Reference.gql'

export function generateReferenceDoc(doc, query) {
  const manifest = find(getViewManifest(), query)
  return get(manifest, 'generateReferenceDoc', () => null)(doc)
}

export function filterTicketRef({ type }) {
  return !startsWith(type, 'Ticket')
}

export function useReferenceBase(ref_name, filterRef, doc) {
  const { loading: loadingRefernce, data: referencedData } = useQuery(
    _downloadReferencedDocs,
    {
      client: cceClient,
      variables: {
        values: [get(doc, '_id')],
        ref_name,
      },
      skip: isNil(doc) || isLocalDoc(doc),
    },
  )

  const data = useMemo(() => {
    if (isLocalDoc(doc)) return [0, []]
    if (loadingRefernce || isNil(referencedData)) return [null, null]
    const docRef = compact(
      map(referencedData.downloadReferencedDocs, doc => {
        const { type, stringify_doc } = doc
        const parsedDoc = attempt(JSON.parse, stringify_doc)
        return isError(parsedDoc) || !filterRef(doc)
          ? null
          : generateReferenceDoc(parsedDoc, { backendDocType: type })
      }),
    )

    return [size(docRef), docRef]
  }, [loadingRefernce, referencedData, filterRef, doc])

  return data
}

export function referenceDoc(nameField, getPath, schema, namespace, doc) {
  return {
    name: get(doc, nameField),
    schema,
    namespace,
    ...getPath(doc),
  }
}
