import { useContext, useMemo, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  get,
  isEmpty,
  attempt,
  countBy,
  groupBy,
  mapValues,
  uniq,
  uniqBy,
  isNil,
  keys,
  map,
  values,
  size,
  filter,
  toLower,
} from 'lodash'
import { useQuery } from '@apollo/client'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { Tag } from 'primereact/tag'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Link } from 'react-router-dom'

import { ProjectContext } from '@common'
import { SchemaLabels } from '@schema'
import { cceClient } from '@gql'
import {
  Box,
  Flex,
  Text,
  StyledOverlay,
} from '@changingai/react-editor-common-component'

import { HomePanel } from './common'
import { getViewManifest } from '../Config'
import { generateReferenceDoc } from '../Reference'
import { useTickets } from '../TicketHelper'

import { _queryHistory } from './history.gql'

const getRestore = schema => get(find(getViewManifest(), { schema }), 'restore')
function canRestore({ docType }) {
  return !isNil(getRestore(docType))
}

function DocRestorer({ doc, schemaName }) {
  const Comp = getRestore(schemaName)
  // Suppose the user should call canRestore before using DocRestorer.
  // The following exception should not been seen.
  if (isNil(Comp))
    throw new Error(`${schemaName} not supported for restoring doc`)

  return <Comp doc={doc} />
}

DocRestorer.propTypes = {
  doc: PropTypes.object.isRequired,
  schemaName: PropTypes.string.isRequired,
}

function Statistic({ statistic }) {
  return (
    <Flex width="100%" justifyContent="flex-end">
      {map(keys(statistic), state => (
        <Flex
          key={state}
          alignItems="flex-end"
          bg="blacks.0"
          px="2"
          py="1"
          borderBottom="1px dashed #a6a6a6"
        >
          <Box>
            <Text fontSize="h1" fontWeight="bold" mr="1">
              {isEmpty(state) ? 'Ticketless' : state}
            </Text>
            {map(keys(statistic[state]), action => (
              <>
                <Text color="blacks.8">{`${action[0]}(`}</Text>
                <Text color="blue">{`${statistic[state][action]}`}</Text>
                <Text color="blacks.8">{`)`}</Text>
              </>
            ))}
          </Box>
        </Flex>
      ))}
    </Flex>
  )
}

Statistic.propTypes = {
  statistic: PropTypes.object.isRequired,
}

function getSupportedDocTypes(namespace) {
  const manifests = getViewManifest()
  return map(filter(manifests, { namespace: toLower(namespace) }), 'schema')
}

export function History() {
  const { namespace } = useContext(ProjectContext)
  const [date, setDate] = useState(new Date())

  const { loading: loadingHistory, data: historyData } = useQuery(
    _queryHistory,
    {
      client: cceClient,
      variables: {
        startDate: new Date(
          `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`,
        ),
        docTypes: getSupportedDocTypes(namespace),
      },
    },
  )

  const [history, docToTicket, ticketIds] = useMemo(() => {
    if (loadingHistory || !historyData) return [null, null, null]

    const ticketMap = historyData.queryHistory
      .map(({ target_id, actionContent }) => ({
        target_id,
        ticket_id: get(attempt(JSON.parse, actionContent), 'ticket_id'),
      }))
      .filter(({ ticket_id }) => !isNil(ticket_id))
      .remap('target_id', 'ticket_id')

    return [
      groupBy(historyData.queryHistory, 'actor'),
      ticketMap,
      uniq(values(ticketMap)),
    ]
  }, [loadingHistory, historyData])

  const [tickets, loadingTickets] = useTickets({
    ticket_ids: ticketIds || [],
    namespace,
    skip: isEmpty(ticketIds),
  })

  const ticketMap = useMemo(
    () => (!tickets || loadingTickets ? null : groupBy(tickets, '_id')),
    [tickets, loadingTickets],
  )

  const [activeIndex, setActiveIndex] = useState(null)
  const [willUpload, setWillUpload] = useState(null)

  // TBD: Restore function should be disabled or hide if the deleted
  // doc has been restored. Currently, we will get duplicate upload
  // error if we restore same doc.
  const onRestore = useCallback(
    ({ docType, content }) => {
      setWillUpload({
        doc: content,
        schemaName: docType,
      })
    },
    [setWillUpload],
  )

  return (
    <HomePanel caption="每日動態">
      {!isNil(willUpload) && (
        <DocRestorer doc={willUpload.doc} schemaName={willUpload.schemaName} />
      )}
      <Flex width="100%" justifyContent="center" mb="2">
        <Calendar value={date} onChange={e => setDate(e.value)} inline={true} />
      </Flex>
      <StyledOverlay active={loadingHistory || loadingTickets} />
      {ticketMap && (
        <Box width="100%">
          <Accordion
            activeIndex={activeIndex}
            onTabChange={e => setActiveIndex(e.index)}
          >
            {map(keys(history), actor => {
              const name = actor.substr(0, actor.indexOf('@'))
              const docs = uniqBy(
                map(history[actor], doc => ({
                  ...doc,
                  id: doc.target_id,
                  content: JSON.parse(doc.actionContent),
                })),
                ({ state, actionType, id }) => actionType + state + id,
              )

              const statistic = mapValues(
                groupBy(
                  docs
                    .filter(
                      ({ content }) => !isNil(get(content, 'ticket_state')),
                    )
                    .map(({ actionType, content }) => ({
                      state: get(content, 'ticket_state'),
                      actionType,
                    })),
                  'state',
                ),
                values => countBy(values, 'actionType'),
              )

              const actions = groupBy(docs, 'actionType')
              const count = (label, count) => (
                <>
                  <Text>{label}</Text>
                  <Box fontWeight="bold" mx="1" color="blue">
                    {count}
                  </Box>
                  <Text>docs。</Text>
                </>
              )
              return (
                <AccordionTab
                  key={name}
                  header={
                    <Flex width="100%">
                      <i className="pi pi-user" />
                      <Box mx="2" fontSize="6" fontWeight="bold" width="100px">
                        {name}
                      </Box>
                      {count('建立', size(get(actions, 'CREATE')))}
                      {count('修改', size(get(actions, 'UPDATE')))}
                      {count('刪除', size(get(actions, 'DELETE')))}
                      {count('總數', size(uniq(map(docs, 'target_id'))))}
                    </Flex>
                  }
                >
                  <Statistic statistic={statistic} />
                  {docs.map((doc, index) => {
                    const { id, content, actionType, docType } = doc
                    const paths = generateReferenceDoc(content, {
                      schema: docType,
                    })
                    if (isNil(paths)) return null

                    const ticket = get(ticketMap, `${docToTicket[id]}.0`)
                    const severityMap = {
                      UPDATE: 'info',
                      CREATE: 'warning',
                      DELETE: 'danger',
                    }
                    return (
                      <Flex
                        width="100%"
                        key={index}
                        alignItems="center"
                        borderBottom="1px dashed #a6a6a6"
                      >
                        <Tag
                          style={{ width: '80px' }}
                          value={actionType}
                          severity={severityMap[actionType]}
                        />
                        <Text
                          width="70px"
                          ml="2"
                          fontSize="small"
                          color="blacks.7"
                        >
                          {get(SchemaLabels, docType)}
                        </Text>
                        <Text width="160px" fontSize="small" color="blacks.5">
                          {id}
                        </Text>

                        {!isNil(paths.listviewUrl) && (
                          <Box
                            width="300px"
                            px="2"
                            style={{
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            <Link
                              to={paths.listviewUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {`${get(ticket, 'name', '載入總表')}`}
                            </Link>
                          </Box>
                        )}

                        {!isNil(paths.docUrl) && actionType !== 'DELETE' && (
                          <Box
                            width="1 1 auto"
                            px="2"
                            style={{
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            <Link
                              to={paths.docUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {`${get(paths, 'name', '開啟文件')}`}
                            </Link>
                          </Box>
                        )}

                        {canRestore(doc) && actionType === 'DELETE' && (
                          <Flex flex="1 0 auto" justifyContent="flex-end">
                            <Button
                              style={{ width: '30px', height: '30px' }}
                              icon="pi pi-download"
                              className="p-button-rounded p-button-outlined"
                              onClick={() => {
                                onRestore(doc)
                              }}
                            />
                          </Flex>
                        )}
                      </Flex>
                    )
                  })}
                </AccordionTab>
              )
            })}
          </Accordion>
        </Box>
      )}
    </HomePanel>
  )
}
