import { useState, useMemo, useCallback, useRef, useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import 'styled-components/macro'
import { Dialog } from 'primereact/dialog'
import { TabView, TabPanel } from 'primereact/tabview'
import { Messages } from 'primereact/messages'
import {
  noop,
  compact,
  isEmpty,
  flatten,
  size,
  toString,
  get,
  isNil,
  includes,
  values,
} from 'lodash'

import {
  Box,
  Flex,
  StyledOverlay,
} from '@changingai/react-editor-common-component'

import {
  PropCrud,
  isLocalDoc,
  useDirtyStore,
  ProjectContext,
  MatchState,
  useDialog,
} from '@common'
import {
  ActionButtons,
  ScrollBar,
  StatusBarRoot,
  useRenderConfig,
  ConfirmDialog,
} from '@widget'
import { getSchemaName, toEditableDoc } from '@schema'

import { DirtyStorePanel } from './DirtyStorePanel'
import { useTagContext } from './TagMemoContext'
import { useValidateDialog } from './Validation'
import { ReferenceDialog } from './ReferenceDialog'

const FailureRoot = styled(Box)``
FailureRoot.defaultProps = {
  position: 'absolute',
  width: '100%',
  left: 0,
  top: '2em',
  zIndex: 'l7',
}

const StyledMessages = styled(Messages)`
  && .p-message .p-message-wrapper {
    height: 20px;
  }
  && .p-message .p-message-icon {
    font-size: 12px;
  }
  && .p-message .p-message-detail {
    font-size: 12px;
  }
`

function useFailedRuleList() {
  const msgRef = useRef(null)
  const updateFailure = useCallback(
    errors => {
      msgRef.current.clear()
      if (!errors) {
        return
      }
      msgRef.current.show(
        errors.map(error => ({
          severity: 'error',
          detail: error,
          sticky: true,
        })),
      )
    },
    [msgRef],
  )

  function render() {
    return (
      <FailureRoot>
        <StyledMessages ref={msgRef} />
      </FailureRoot>
    )
  }
  return [updateFailure, render]
}
export function SchemaBaseEditor(props) {
  const { isReady = true, editContext, onHide } = props
  const header = useCallback(
    () => (
      <Box data-cy="editor-header">
        {isLocalDoc(editContext)
          ? 'New Doc'
          : `Edit Doc - ${get(editContext, '_id')}`}
      </Box>
    ),
    [editContext],
  )

  return (
    <Dialog
      header={header()}
      appendTo={document.body}
      visible={true}
      style={{ width: '100vw', height: '100vh' }}
      modal={true}
      onHide={onHide}
      contentStyle={{ overflow: 'visible' }}
      position="top"
    >
      <StyledOverlay active={!isReady} text={`Initializing...`} />
      {isReady && <SchemaBaseEditorInner {...props} />}
    </Dialog>
  )
}

SchemaBaseEditor.propTypes = {
  onHide: PropTypes.func.isRequired,
  editContext: PropTypes.object,
  isReady: PropTypes.bool,
}

function SchemaBaseEditorInner({
  onUpload,
  onDelete,
  onUpdate = noop,
  checker,
  onHide,
  editContext,
  setEditContext,
  crud,
  currentSchema,
  tabs,
  useReference = () => [0, {}],
  initialDirty,
}) {
  const { privilege } = useContext(ProjectContext)
  const [initDoc, replaceInitDoc] = useState(editContext)
  const [updateFailure, renderFailures] = useFailedRuleList()

  const [uploading, setUploading] = useState(false)

  const [showConfirmDialog, renderConfirmDialog] = useDialog(ConfirmDialog)
  const [
    onDirty,
    dirties,
    onError,
    errors,
    cleanStore,
    reasonsMap,
  ] = useDirtyStore(initialDirty)

  const canUpload = useMemo(() => isEmpty(errors) && !isEmpty(dirties), [
    errors,
    dirties,
  ])

  const configRender = useRenderConfig({
    onError,
    onUpdateContext: setEditContext,
    onDirty,
    editContext,
    currentSchema,
    currentPath: [getSchemaName(currentSchema)],
  })

  const [resetCount, setResetCount] = useState(0)
  const onReset = useCallback(() => {
    cleanStore()
    setResetCount(resetCount + 1)
  }, [cleanStore, setResetCount, resetCount])

  const onLocalUpdate = useCallback(
    ({ docs, errors }) => {
      updateFailure(errors)
      if (!isEmpty(docs)) {
        onReset()
        const doc = get(docs, '0.doc')
        replaceInitDoc(toEditableDoc(doc, currentSchema))
        setEditContext(draft => {
          draft._id = get(doc, '_id')
          delete draft.local_doc
        })
        onUpdate(docs)
      }
    },
    [
      updateFailure,
      onUpdate,
      onReset,
      setEditContext,
      replaceInitDoc,
      currentSchema,
    ],
  )

  const { renderValidation, onValidAndUpload } = useValidateDialog({
    checkOp: checker,
    onUpdate: onLocalUpdate,
    onUpload,
    currentSchema,
  })

  const ids = useMemo(
    () => compact([get(initDoc, '_id'), get(initDoc, 'matched_target')]),
    [initDoc],
  )
  const { isLoadingTag, tags } = useTagContext(ids)
  const isLocked = useMemo(
    () => isLoadingTag || includes(flatten(values(tags)), 'lock'),
    [isLoadingTag, tags],
  )

  const [refCount, reference] = useReference(initDoc)
  const [showReference, renderReference] = useDialog(ReferenceDialog)

  const onAction = useCallback(
    async action => {
      switch (action) {
        case 'Close':
          if (dirties.length > 0 && crud.update) {
            showConfirmDialog({
              title: 'Warning',
              message: '你的修改將會遺失, 繼續?',
              action: 'Close',
            })
          } else onHide()
          break
        case 'Upload':
        case 'Create': {
          if (isLocked) {
            return showConfirmDialog({
              title: 'Warning',
              message: '確定更動鎖定文件?',
              action: 'UploadLock',
              needPrivilege: true,
            })
          }

          if (get(editContext, 'matched_state') === MatchState.FULLY_MATCHED) {
            return showConfirmDialog({
              title: 'Warning',
              message: '確定更動已配對文件?',
              action: 'UploadPaired',
            })
          }
          setUploading(true)
          onLocalUpdate(await onValidAndUpload(editContext))
          setUploading(false)
          break
        }

        case 'Delete':
          showConfirmDialog({
            title: 'Warning',
            message: '刪除的文件無法復原, 繼續?',
            action: 'Delete',
          })
          break
        case 'Ref':
          showReference({
            refDocs: reference,
          })
          break
        case 'Reset':
          setEditContext(() => initDoc)
          onReset()
          break
        default:
          throw new Error('Unexpected action')
      }
    },
    [
      initDoc,
      dirties.length,
      editContext,
      onHide,
      showConfirmDialog,
      onLocalUpdate,
      onValidAndUpload,
      crud.update,
      setEditContext,
      onReset,
      reference,
      showReference,
      isLocked,
    ],
  )
  const [deleting, setDeleting] = useState(false)

  const working = uploading || deleting
  const dataReady = useMemo(
    () => !working && !isNil(refCount) && !isNil(reference),
    [working, refCount, reference],
  )
  // Enable delete button in two condition
  // 1. refCount is equal to zero, enable the delete button for delete action
  // 2. reference is larger then zero, enable the reference button for launching
  //    reference dialog.
  const deleteButtonStatus = useMemo(() => {
    const canDelete =
      !isNil(onDelete) && crud.delete && refCount === 0 && !isLocked
    return dataReady && (canDelete || !isEmpty(reference))
  }, [onDelete, crud.delete, dataReady, refCount, reference, isLocked])

  const [deleteButtonLabel, deleteButtonClassname] = useMemo(
    () =>
      isEmpty(reference)
        ? ['Delete', 'p-button-danger']
        : ['Ref', 'p-button-info'],
    [reference],
  )

  return (
    <Box position="relative" data-cy="editor_container">
      {renderValidation({})}
      {renderConfirmDialog({
        onYes: async action => {
          switch (action) {
            case 'Close':
              onHide()
              break
            case 'Delete':
              setDeleting(true)
              await onDelete(editContext._id)
              setDeleting(false)
              onHide()
              break
            case 'UploadPaired':
            case 'UploadLock':
              setUploading(true)
              onLocalUpdate(await onValidAndUpload(editContext))
              setUploading(false)
              break
            default:
              break
          }
        },
      })}
      {renderReference({})}
      {/*
          resetCount was advanced each time a user click reset button.
          Each time we change the value of key prop of a component, we force this
          component to be remounted.
          By doing this, all the input widget inside TabView will be re-created,
          a new created input widget will respect the props.doc, so reset takes
          effect.
        */}
      <TabView renderActiveOnly={false} key={resetCount}>
        {tabs.map(({ caption, configs }) => (
          <TabPanel key={caption} header={caption}>
            <Flex
              width="100%"
              flexWrap="wrap"
              alignContent="flex-start"
              alignItems="baseline"
              height="75vh"
              overflow="auto"
              css={ScrollBar}
            >
              {configs.map(configRender)}
            </Flex>
          </TabPanel>
        ))}
      </TabView>
      <StatusBarRoot py={2}>
        <Box flex="1 1 50%">
          <DirtyStorePanel
            errors={errors}
            dirties={dirties}
            reasonsMap={reasonsMap}
          />
        </Box>
        <Box flex="1 1 50%">
          <ActionButtons
            buttons={{
              right: compact([
                crud.update && {
                  label: isLocalDoc(editContext) ? 'Create' : 'Upload',
                  action: isLocalDoc(editContext) ? 'Create' : 'Upload',
                  className: 'p-button-primary',
                  icon: uploading ? 'pi pi-spin pi-spinner' : '',
                  enable:
                    canUpload &&
                    !working &&
                    !isNil(onUpload) &&
                    !isLoadingTag &&
                    (!includes(flatten(values(tags)), 'lock') ||
                      privilege.includes('supervisor')),
                  badge: includes(flatten(values(tags)), 'lock') ? 'L' : '',
                },
                {
                  label: deleteButtonLabel,
                  action: deleteButtonLabel,
                  className: deleteButtonClassname,
                  icon: dataReady ? '' : 'pi pi-spin pi-spinner',
                  enable: deleteButtonStatus,
                  badge: toString(refCount),
                },
                crud.update && {
                  label: 'Reset',
                  action: 'Reset',
                  className: 'p-button-warning',
                  enable: size(dirties) > 0 && !working,
                },
                {
                  label: 'Close',
                  action: 'Close',
                  className: 'p-button-secondary',
                  enable: !uploading,
                },
              ]),
            }}
            onClicked={onAction}
          />
        </Box>
      </StatusBarRoot>
      {renderFailures()}
    </Box>
  )
}

SchemaBaseEditorInner.propTypes = {
  onUpload: PropTypes.func,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  onHide: PropTypes.func.isRequired,
  editContext: PropTypes.object.isRequired,
  setEditContext: PropTypes.func.isRequired,
  crud: PropCrud.isRequired,
  currentSchema: PropTypes.object.isRequired,
  checker: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
  ]),
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      caption: PropTypes.string.isRequired,
      configs: PropTypes.arrayOf(
        PropTypes.shape({
          fieldname: PropTypes.string.isRequired,
          widget: PropTypes.oneOf([
            'MultipleNodeList',
            'SingleInput',
            'SingleInputWithSuggestions',
            'SingleInputWithUrl',
            'SingleCheck',
            'SingleSelect',
            'FileUploader',
            'MultiCheckboxList',
            'MultiInput',
            'ImageUploader',
            'CustomizedInput',
            'SubDocInput',
            'TreeSelect',
            'Separator',
          ]).isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  useReference: PropTypes.func,
  initialDirty: PropTypes.bool,
}
