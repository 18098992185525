import { useEffect, useCallback, useState, useMemo, useContext } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { Dialog } from 'primereact/dialog'
import {
  compact,
  find,
  get,
  map,
  head,
  identity,
  isNil,
  partial,
  partialRight,
} from 'lodash'
import { useQuery } from '@apollo/client'
import ReactTooltip from 'react-tooltip'
import { toast } from 'react-toastify'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { MdCompare } from 'react-icons/md'
import { Box, Flex, Text } from '@changingai/react-editor-common-component'

import { cceClient } from '@gql'
import {
  MatchState,
  ProjectContext,
  PropCrud,
  TicketPropType,
  TicketState,
  asyncReadFile,
  getActiveState,
  getDataEditors,
  useDialog,
} from '@common'
import { getSchemaName } from '@schema'
import {
  ButtonLike,
  ImportButton,
  ActionButtons,
  StatusBarRoot,
  ConfirmDialog,
} from '@widget'

import { getViewManifest } from './Config'
import { useDefaultCrud } from './Common'
import { MenuIcon, useFieldSelectionMenuIcon } from './Shell'
import SchemaSummaryModel, { useValidFieldsBySchema } from './SummaryListView'
import { useReturnTicket, getNamespaceByScopeType } from './TicketHelper'
import { SortByField } from './FieldSelectorPanel'

import { _queryTickets } from './TicketSummaryModel.gql'
import ReturnIcon from '../img/return.png'

function VersionCheckHeader({ ticket, crud, setCrud, docs }) {
  const { name } = ticket
  const { data } = useQuery(_queryTickets, {
    client: cceClient,
    variables: {
      filter: {
        scope_ids: [ticket.scope_id],
      },
      namespace: getNamespaceByScopeType(ticket.scope_type),
    },
  })

  const [originCrud] = useState(crud)
  const [lock, setLock] = useState(true)
  const hasNewVersionTicket = useMemo(
    () =>
      get(data, 'queryTickets', []).some(
        ({ version }) => version > ticket.version,
      ),
    [data, ticket.version],
  )

  useEffect(() => {
    if (hasNewVersionTicket && lock && crud.update) {
      setCrud({
        ...crud,
        update: false,
        create: false,
      })
    }
  }, [lock, hasNewVersionTicket, setCrud, crud, originCrud])

  return (
    <Flex width="100%" flexWrap="wrap">
      {lock && hasNewVersionTicket && (
        <Flex flex="0 0 auto" mr="2" data-cy="warning_button">
          <Button
            label="存在新版，是否繼續編輯？"
            className="p-button-warning"
            icon="pi pi-lock"
            onClick={() => {
              setCrud({
                ...crud,
                update: originCrud.update,
                create: originCrud.create,
              })
              setLock(false)
            }}
          />
        </Flex>
      )}
      <Box flex="1 1">
        <Box fontSize="h2" width="100%">
          <Text color="blacks.5" mr="1">
            {ticket.scope_type}
          </Text>
          <Text>{name}</Text>
          <Text color="reddishOranges.9">
            {hasNewVersionTicket ? `(${ticket.version})` : ''}
          </Text>
        </Box>
        <Box fontSize="small" color="info" width="100%">
          {docs
            ? `合計：${docs.length}筆. 狀態：${
                getActiveState(ticket).main_state
              }`
            : ''}
        </Box>
      </Box>
    </Flex>
  )
}

VersionCheckHeader.propTypes = {
  docs: PropTypes.arrayOf(PropTypes.object),
  ticket: TicketPropType.isRequired,
  crud: PropCrud.isRequired,
  setCrud: PropTypes.func.isRequired,
}

function useTicketTranspile(docs, selectedKeys, useTranspile, ticket) {
  const transpiledDocs = useTranspile(docs, selectedKeys, ticket)
  const filteredTranspiledDocs = useMemo(() => {
    if (!transpiledDocs) return null
    const mainEditor = head(getDataEditors(ticket))
    return getActiveState(ticket).main_state === TicketState.FINISH
      ? transpiledDocs.filter(
          ({ matched_state, uploader }) =>
            matched_state !== MatchState.FULLY_MATCHED ||
            mainEditor === uploader,
        )
      : transpiledDocs
  }, [transpiledDocs, ticket])

  // map uploader to A or B side and simplify uploader for listview mode
  return map(filteredTranspiledDocs, ({ uploader, ...rest }) => ({
    ...rest,
    uploader: compact([
      ['A', 'B'][getDataEditors(ticket).indexOf(uploader)],
      uploader.replace('@changing.ai', ''),
    ]).join(':'),
  }))
}

function UploaderSelector({ uploaders, mainUploader, onSelected, disabled }) {
  const options = useMemo(
    () =>
      uploaders.map(uploader => ({
        label: uploader.substr(0, uploader.indexOf('@')),
        value: uploader,
      })),
    [uploaders],
  )

  const [uploader, setUploader] = useState(
    mainUploader ? mainUploader : uploaders[0],
  )
  return (
    <Box width="100%" height="110px">
      <Dropdown
        value={uploader}
        options={options}
        onChange={({ value }) => {
          setUploader(value)
          onSelected(value)
        }}
        style={{ width: '100%' }}
        disabled={disabled}
      />
    </Box>
  )
}

UploaderSelector.propTypes = {
  uploaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  mainUploader: PropTypes.string,
  onSelected: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

function UploaderSelectionDialog({ onHide, uploaders, onSelected }) {
  const [selected, setSelected] = useState(uploaders[0])
  return (
    <Dialog
      header="Select an uploader"
      appendTo={document.body}
      visible={true}
      style={{ width: '500px' }}
      modal={true}
      onHide={onHide}
    >
      <UploaderSelector
        uploaders={uploaders}
        mainUploader={uploaders[0]}
        onSelected={uploader => setSelected(uploader)}
        disabled={false}
      />
      <StatusBarRoot style={{ justifyContent: 'flex-end' }}>
        <ActionButtons
          buttons={{
            right: [
              {
                label: 'Yes',
                action: 'Yes',
                className: 'p-button-primary',
                icon: 'pi pi-check',
              },
              {
                label: 'No',
                action: 'No',
                className: 'p-button-secondary',
                icon: 'pi pi-times',
              },
            ],
          }}
          onClicked={async name => {
            if (name === 'Yes') {
              await onSelected(selected)
            }

            onHide()
          }}
        />
      </StatusBarRoot>
    </Dialog>
  )
}

UploaderSelectionDialog.propTypes = {
  onHide: PropTypes.func.isRequired,
  uploaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelected: PropTypes.func.isRequired,
}

export function TicketSummaryModel(props) {
  const {
    ticket,
    useController,
    useTranspile = identity,
    useCrud = useDefaultCrud,
    useValidFields = useValidFieldsBySchema,
    HeaderLeftPanel = VersionCheckHeader,
    HeaderRightPanel = () => null,
    requiredPrivilege = ['edit'],
    currentSchema,
    defaultSummary,
  } = props

  const HeaderLeftPanelWithTicket = useCallback(
    props => <HeaderLeftPanel {...props} ticket={ticket} />,
    [ticket],
  )
  const HeaderRightPanelWithTicket = useCallback(
    props => <HeaderRightPanel {...props} ticket={ticket} />,
    [ticket],
  )
  const [showConfirmDialog, renderConfirmDialog] = useDialog(ConfirmDialog)
  const { email } = useContext(ProjectContext)
  const { canReturn, onReturn } = useReturnTicket(ticket, email)

  const crud = useCrud(ticket)

  const [sortByField, setSortByField] = useState('')
  const {
    FieldSelectionMenuButton,
    selectedFields,
  } = useFieldSelectionMenuIcon({
    currentSchema,
    defaultSummary,
    headerComponents: (
      <SortByField onSortField={setSortByField} currentSchema={currentSchema} />
    ),
  })

  const controller = useController(ticket, selectedFields)
  const [showUploader, renderUploader] = useDialog(UploaderSelectionDialog)
  const onImport = useCallback(
    async ({ target: { files } }) => {
      try {
        if (files.length === 0) return
        const docs = JSON.parse(await asyncReadFile(files[0]))
        if (!getActiveState(ticket).assignee_as_editor)
          showUploader({ onSelected: partial(controller.importDocs, docs) })
        else controller.importDocs(docs, email)
      } catch (e) {
        toast.error(`Import Error: ${e}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        })
      }
    },
    [showUploader, ticket, email, controller],
  )
  const onCreate = useCallback(
    uploader => {
      controller.showDoc(controller.createDoc(uploader), crud)
    },
    [controller, crud],
  )
  const hasCompareView = useMemo(
    () =>
      !isNil(
        get(
          find(getViewManifest(), { schema: getSchemaName(currentSchema) }),
          'compareview',
        ),
      ),
    [currentSchema],
  )

  const extendedRightMenuIcons = useMemo(
    () =>
      compact([
        hasCompareView && [
          <MenuIcon key="goto-compareview">
            <ButtonLike
              data-cy="compareview-button"
              data-tip="goto compareview"
              size={6}
              scaleOnHover
              onClick={() => {
                window.location.assign(
                  window.location.href.replace('listview', 'compareview'),
                )
              }}
            >
              <MdCompare />
            </ButtonLike>
          </MenuIcon>,
          1,
        ],
      ]),
    [hasCompareView],
  )

  const extendedLeftMenuIcons = useMemo(
    () =>
      compact([
        controller.createDoc && [
          <MenuIcon key="create">
            <ReactTooltip place="right" />
            <ButtonLike
              data-cy="create-new-doc"
              data-tip="新增文件"
              disabled={!crud.create}
              size={6}
              scaleOnHover
              onClick={() => {
                if (!getActiveState(ticket).assignee_as_editor) {
                  showUploader({ onSelected: onCreate })
                } else onCreate(email)
              }}
            >
              <AiOutlinePlusCircle />
            </ButtonLike>
          </MenuIcon>,
          -1,
        ],
        controller.importDocs && [
          <MenuIcon key="import">
            <ImportButton
              disabled={!crud.create}
              onImport={onImport}
              accept=".json"
            />
          </MenuIcon>,
          -1,
        ],
        [<FieldSelectionMenuButton key="show-field" />, -1],
        canReturn && [
          <MenuIcon key="return">
            <ButtonLike
              data-tip="Return"
              size={6}
              scaleOnHover
              onClick={() =>
                showConfirmDialog({
                  title: 'Info',
                  message: 'Return this ticket?',
                  action: 'OK',
                })
              }
            >
              <img width="100%" alt="Not dispatched yet" src={ReturnIcon} />
            </ButtonLike>
          </MenuIcon>,
          10,
        ],
      ]),
    [
      canReturn,
      showConfirmDialog,
      controller,
      crud.create,
      email,
      onCreate,
      onImport,
      showUploader,
      ticket,
    ],
  )

  const useFilteredTranspile = useMemo(
    () => partialRight(useTicketTranspile, useTranspile, ticket),
    [useTranspile, ticket],
  )

  return (
    <>
      {renderConfirmDialog({
        onYes: action => {
          if (action === 'OK') onReturn()
        },
      })}
      {renderUploader({ uploaders: getDataEditors(ticket) })}
      <SchemaSummaryModel
        {...props}
        controller={controller}
        extendedLeftMenuIcons={extendedLeftMenuIcons}
        extendedRightMenuIcons={extendedRightMenuIcons}
        modelId={get(ticket, '_id')}
        useTranspile={useFilteredTranspile}
        useValidFields={useValidFields}
        crud={crud}
        HeaderLeftPanel={HeaderLeftPanelWithTicket}
        HeaderRightPanel={HeaderRightPanelWithTicket}
        requiredPrivilege={requiredPrivilege}
        sortByField={sortByField}
        selectedFields={selectedFields}
      />
    </>
  )
}

TicketSummaryModel.propTypes = {
  ticket: TicketPropType.isRequired,
  useController: PropTypes.func.isRequired,
  useTranspile: PropTypes.func.isRequired,
  useValidFields: PropTypes.func,
  useCrud: PropTypes.func,
  HeaderLeftPanel: PropTypes.func,
  HeaderRightPanel: PropTypes.func,
  requiredPrivilege: PropTypes.arrayOf(PropTypes.string),
  currentSchema: PropTypes.object.isRequired,
  defaultSummary: PropTypes.shape({
    selected: PropTypes.arrayOf(PropTypes.string).isRequired,
    filters: PropTypes.object.isRequired,
  }).isRequired,
}

export default TicketSummaryModel
