import Joi from 'joi'
import { namespaces, useGetConnectedNodes, KG_NODES } from '@common'
import {
  addSchema,
  schemaToPropTypes,
  RequiredString,
  shortString,
  UniqueNodeArray,
  matchStateSchema,
  ticketDocSchema,
  SchemaEmptyPositive,
} from '@schema'

const skillRoot = [
  {
    node_id: KG_NODES.myfinance_editor.NINJA_SKILLS,
    namespace: 'myfinance_editor',
  },
]
export const ninjaSchema = {
  ...ticketDocSchema,
  ...matchStateSchema,
  name: {
    schema: RequiredString.max(shortString),
    filterable: true,
    type: 'string',
    label: '員工姓名',
  },
  skill: {
    schema: UniqueNodeArray,
    type: 'arrayOf(node)',
    label: '技能',
    referToKG: 'myfinance_editor',
    useNodes: () => useGetConnectedNodes(skillRoot),
  },
  height: {
    schema: SchemaEmptyPositive,
    type: 'number',
    label: '身高',
  },
  weight: {
    schema: SchemaEmptyPositive,
    type: 'number',
    label: '體重',
  },
  birth: {
    schema: Joi.alternatives().try(Joi.string().valid(''), Joi.date()),
    type: 'date',
    label: '生日',
  },
  jinchuuriki: {
    schema: Joi.bool(),
    type: 'bool',
    label: '人柱力？',
  },
}

addSchema({ ninjaSchema }, '忍者', namespaces.test.value)
export const NinjaPropType = schemaToPropTypes(ninjaSchema)
