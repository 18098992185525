import { useState, useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import 'styled-components/macro'
import css from '@styled-system/css'
import {
  Box,
  Flex,
  StyledOverlay,
} from '@changingai/react-editor-common-component'
import { PickList } from 'primereact/picklist'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import {
  find,
  map,
  attempt,
  get,
  head,
  isError,
  compact,
  reject,
  filter,
} from 'lodash'
import { useQuery, useMutation } from '@apollo/client'

import { TicketPropType } from '@common'
import { StatusBarRoot } from '@widget'
import { cceClient } from '@gql'

import { _queryMemo, _updateMemo } from './DocSortingDialog.gql'

export function DocSortingDialog({ onHide, allItems, ticket, sortedNames }) {
  // Go on with memo service(_queryMemo and _updateMemo) before we implement
  // a dedicated service for sorting.
  // Prefix target_id key with 'sortedDocs' so that we can pull out sorting
  // data easier when a dedicated service provided.
  const cacheKey = `sortedDocs.${ticket.scope_type}.${ticket._id}`

  const { data: sortedData } = useQuery(_queryMemo, {
    client: cceClient,
    variables: {
      target_ids: [cacheKey],
    },
  })

  const sortedDocs = useMemo(() => {
    if (!sortedData) return null

    const ids = attempt(JSON.parse, get(head(sortedData.queryMemo), 'memo'))
    if (isError(ids)) return []
    return compact(ids.map(_id => find(allItems, { _id })))
  }, [allItems, sortedData])

  const [source, setSource] = useState(null)
  const [target, setTarget] = useState(null)

  useEffect(() => {
    if (!sortedDocs) return

    setSource(reject(allItems, ({ _id }) => find(sortedDocs, { _id })))
    setTarget(filter(sortedDocs, ({ _id }) => find(allItems, { _id })))
  }, [allItems, sortedDocs, setSource, setTarget])

  const [updateSortedDocs] = useMutation(_updateMemo)
  const itemTemplate = useCallback(
    item => <Flex>{sortedNames.map(key => item[key]).join(' - ')}</Flex>,
    [sortedNames],
  )
  const onChange = useCallback(
    ({ source, target }) => {
      setSource(source)
      setTarget(target)
    },
    [setSource, setTarget],
  )
  const onSorted = useCallback(
    sotredIds =>
      updateSortedDocs({
        variables: {
          target_id: cacheKey,
          memo: JSON.stringify(sotredIds),
        },
      }),
    [updateSortedDocs, cacheKey],
  )

  const isReady = !!target

  return (
    <Dialog
      header="Sort items"
      appendTo={document.body}
      visible={true}
      style={{ width: '1024px' }}
      contentStyle={{ position: 'relative' }}
      modal={true}
      onHide={onHide}
    >
      <StyledOverlay active={!isReady}>
        <Box
          css={css({
            '&& .p-picklist.p-component': {
              display: 'flex',
              width: '100%',
              alignItems: 'center',
            },
            '& .p-picklist-listwrapper.p-picklist-source-wrapper, & .p-picklist-listwrapper.p-picklist-target-wrapper': {
              flex: '1 1 50%',
            },
          })}
        >
          <PickList
            source={source || []}
            target={target || []}
            itemTemplate={itemTemplate}
            sourceHeader="Available"
            targetHeader="Sotred"
            sourceStyle={{ height: '500px', width: '100%' }}
            targetStyle={{ height: '500px', width: '100%' }}
            onChange={onChange}
          />
          <StatusBarRoot
            justifyContent="flex-end"
            css={css({
              '& .p-button': {
                mx: 1,
              },
            })}
          >
            <Button
              type="button"
              label="OK"
              className="p-button-primary"
              onClick={async () => {
                await onSorted(map(target, '_id'))
                onHide()
              }}
            />
            <Button
              type="button"
              label="Cancel"
              className="p-button-secondary"
              onClick={onHide}
            />
          </StatusBarRoot>
        </Box>
      </StyledOverlay>
    </Dialog>
  )
}

DocSortingDialog.propTypes = {
  onHide: PropTypes.func.isRequired,
  allItems: PropTypes.array.isRequired,
  ticket: TicketPropType.isRequired,
  sortedNames: PropTypes.arrayOf(PropTypes.string).isRequired,
}
