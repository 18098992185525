import { useMemo } from 'react'
import PropTypes from 'prop-types'
import isNil from 'lodash/isNil'

import { CardGroupScope } from '@common'
import { inputFactory } from '@widget'

import { cardGroupSchema } from './schema'

import { useFetchFullDoc } from '../DocHelper'
import { SchemaBaseEditor } from '../SchemaBaseEditor'
import { _queryCardGroup } from './editor.gql'

export function CardGroupEditor(props) {
  const { editContext, setEditContext } = useFetchFullDoc(
    props.doc,
    cardGroupSchema,
    _queryCardGroup,
  )
  const tabs = useMemo(
    () => [
      {
        caption: '',
        configs: [
          inputFactory(cardGroupSchema, 'card_name'),
          inputFactory(cardGroupSchema, 'scope'),
          inputFactory(cardGroupSchema, 'card_provider_id'),
        ],
      },
    ],
    [],
  )
  return (
    <SchemaBaseEditor
      {...props}
      currentSchema={cardGroupSchema}
      tabs={tabs}
      isReady={!isNil(editContext)}
      editContext={editContext}
      setEditContext={setEditContext}
    />
  )
}

CardGroupEditor.propTypes = {
  doc: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    card_name: PropTypes.string,
    scope: PropTypes.oneOf(Object.values(CardGroupScope)).isRequired,
    card_provider_id: PropTypes.string,
  }),
}
