import { useCallback, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import {
  find,
  get,
  intersection,
  includes,
  isEmpty,
  isEqual,
  isNil,
  map,
  size,
  keys,
  omit,
} from 'lodash'

import {
  KG_NODES,
  ProjectContext,
  TicketPropType,
  TicketState,
  useDialog,
} from '@common'
import { mergeInputFactory } from '@widget'
import { cceClient } from '@gql'
import { MergeDialog } from '@dialog'

import {
  checkFeeTable,
  isEqualFactors,
  useTranspile,
  useUploadFeeTableOfTicket,
} from './common'
import { FeeTableEditDialog } from './editor'
import { FactorDiffLayout, MatricesDiffLayout } from './diffLayout'
import { feeTableSchema } from './schema'

import { useCheckApi } from '../Validation'
import { useCompareController } from '../Compare'
import CompareView, {
  bindSchemaWithDiffLayout,
  TicketHeaderWithLink,
} from '../CompareView'
import { useFetchDocOfTicket } from '../TicketHelper'

import { _queryMatrices } from './compare.gql'

const fullAccessStates = [TicketState.MERGE, TicketState.FINISH]
const primaryKeyRoot = KG_NODES.myfinance_editor.FEETABLE_PRIMARY_KEY_ROOT_ID
const defaultPrimaryKeys = [
  {
    name: 'default-match-rule',
    keys: ['currency_id', 'fee_base'],
  },
]

function FeeTableMergerDialog(props) {
  const { fieldname } = props
  const config = useMemo(() => mergeInputFactory(feeTableSchema, fieldname), [
    fieldname,
  ])
  if (includes(['matrices', 'factors'], fieldname)) return null
  return <MergeDialog config={config} {...props} />
}

FeeTableMergerDialog.propTypes = {
  fieldname: PropTypes.string.isRequired,
}

const scorePlugins = [
  {
    currentSchema: feeTableSchema,
    scoringFields: ['factors', 'matrices'],
    scoringFunction: ({ first, second, primaryKeys }) => {
      const { factors: firstFactors, matrices: firstMatrices } = first
      const { factors: secondFactors, matrices: secondMatrices } = second
      if (!isEqualFactors(firstFactors, secondFactors)) {
        return [
          {
            score: [0, 0],
            diff: [
              {
                fieldname: 'factors',
                // When editor modify the factors of feetable doc, the remain factors
                // of matrices field needs to add or remove the corresponding axes to
                // be able to upload feetable to backend.
                // Currently, diffView can't handle relation between fields.
                disableSelect: true,
                diffPath: 'factors',
              },
              {
                fieldname: 'matrices',
                disableSelect: true,
                diffPath: 'matrices',
              },
            ],
          },
        ]
      }
      if (!isEqual(firstMatrices, secondMatrices)) {
        return [
          {
            score: [0, 0],
            diff: [
              {
                fieldname: 'matrices',
                diffPath: 'matrices',
              },
            ],
          },
        ]
      }
      const primaryScore = size(
        intersection(primaryKeys, ['factors', 'matrices']),
      )
      return [
        {
          score: [primaryScore, 2 - primaryScore],
          diff: [],
        },
      ]
    },
  },
]

const queryMatrices = variables =>
  cceClient.query({
    query: _queryMatrices,
    variables,
  })

function useDocs(ticket, feeTableSchema) {
  // Currently, backend couldn't query matrices without FeeTableMatricesAxes.
  // To query matrices, use first and second factors as queryMatrices input variables,
  // and map them back to fee-table doc.
  const remoteDocs = useFetchDocOfTicket(
    ticket,
    feeTableSchema,
    keys(omit(feeTableSchema, ['matrices'])),
  )
  const { data } = useQuery(_queryMatrices, {
    client: cceClient,
    variables: {
      doc_ids: map(remoteDocs, '_id'),
      axes: map(remoteDocs, ({ _id, factors: [f1, f2] }) => ({
        _id,
        x_axis: f1.id,
        y_axis: f2.id,
      })),
    },
    skip: isEmpty(remoteDocs),
  })

  const docs = useMemo(
    () =>
      data &&
      map(remoteDocs, (doc, index) => ({
        ...doc,
        matrices: get(data, `queryFeeTable.${index}.matrices`),
      })),
    [remoteDocs, data],
  )
  return docs
}

function useController(ticket) {
  const { email } = useContext(ProjectContext)
  const {
    docs,
    matched,
    uploaders,
    updateMatched,
    oneOneMapping,
    setOneOneMappting,
    computing,
    allPrimaryKeys,
    selectedPrimaryKeys,
    setSelectedPrimaryKeys,
  } = useCompareController(
    ticket,
    fullAccessStates,
    primaryKeyRoot,
    defaultPrimaryKeys,
    feeTableSchema,
    scorePlugins,
    useDocs,
  )

  const [showDialog, renderEditor] = useDialog(FeeTableEditDialog)
  const showEditor = useCallback(
    (doc, crud) => {
      showDialog({
        doc,
        ticket,
        crud,
      })
    },
    [ticket, showDialog],
  )

  const onUpdateMatched = useCallback(
    async docs => {
      // After upload Doc by editDialog, the doc return by backend doesn't contain matrices field.
      // Refetch the matrices field to show matrices field correctly in diff view.
      const {
        data: { queryFeeTable },
      } = await queryMatrices({
        doc_ids: map(docs, '1'),
        axes: map(docs, ([{ _id, factors: [f1, f2] }]) => ({
          _id,
          x_axis: f1.id,
          y_axis: f2.id,
        })),
      })

      updateMatched(
        map(docs, ([doc, _id]) => [
          {
            ...doc,
            matrices: find(queryFeeTable, { _id }).matrices,
          },
          _id,
        ]),
      )
    },
    [updateMatched],
  )

  const upload = useUploadFeeTableOfTicket()
  const uploadDoc = useCallback(
    context => upload(ticket, context, email, true),
    [upload, ticket, email],
  )
  const [showMergeDialog, renderMergeDialog] = useDialog(FeeTableMergerDialog)
  return {
    isReady: !isNil(docs),
    docs,
    matched,
    uploaders,
    updateMatched: onUpdateMatched,
    uploadDoc,
    oneOneMapping,
    setOneOneMappting,
    computing,
    showEditor,
    renderEditor,
    allPrimaryKeys,
    selectedPrimaryKeys,
    setSelectedPrimaryKeys,
    renderMergeDialog,
    showMergeDialog,
  }
}

bindSchemaWithDiffLayout(feeTableSchema, {
  factors: {
    renderer: FactorDiffLayout,
  },
  matrices: {
    renderer: MatricesDiffLayout,
  },
})

function FeeTableCompareView({ ticket }) {
  const checker = useCheckApi({ externalAPI: checkFeeTable })
  return (
    <CompareView
      ticket={ticket}
      useController={useController}
      useTranspile={useTranspile}
      currentSchema={feeTableSchema}
      Caption={({ ticket }) => (
        <TicketHeaderWithLink
          ticket={ticket}
          to={`/productSchema/${ticket.scope_id}`}
          linkText="（產品連結）"
          externalUrl={false}
        />
      )}
      checker={checker}
    />
  )
}

FeeTableCompareView.propTypes = {
  ticket: TicketPropType.isRequired,
}

export default FeeTableCompareView
