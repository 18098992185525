import { useMemo, useContext, useState, useEffect, useCallback } from 'react'
import {
  get,
  compact,
  sum,
  map,
  sortBy,
  mapValues,
  castArray,
  isNil,
  isObject,
  intersection,
  find,
  flatMap,
  isEmpty,
  reject,
  keys,
  filter,
  orderBy,
  flatten,
  head,
  split,
  uniq,
  has,
  size,
  includes,
  forEach,
  isNull,
  isEqual,
  partition,
  pick,
} from 'lodash'
import { useLocalStorage } from '@changing-cc/hooks'
import { useQuery } from '@apollo/client'
import { useImmer } from 'use-immer'

import {
  getActiveState,
  getDataEditors,
  ProjectContext,
  computeType,
} from '@common'
import { toEditableDoc } from '@schema'
import { kgClient } from '@gql'

import { useFetchDocOfTicket } from './TicketHelper'
import { useCacheDocs } from './Common'
import { _query_kg, _get_connected } from './Compare.gql'

export function usePOV(ticket, docs) {
  const { email: me, peepMode } = useContext(ProjectContext)
  const pov = useMemo(() => {
    if (size(docs) === 0) return [null]

    const activeState = getActiveState(ticket)
    if (activeState.assignee_as_editor && activeState.assignees.includes(me)) {
      return [
        me,
        [me, ...filter(activeState.assignees, assignee => assignee !== me)],
      ]
    }

    const assignees = getDataEditors(ticket)
    return activeState.assignees.includes(me) || peepMode
      ? [head(assignees), assignees]
      : [null]
  }, [ticket, docs, me, peepMode])

  return pov
}

function usePrimaryKeys(primaryKeyRoot, defaultPrimaryKeys) {
  const { data: dataRoot } = useQuery(_query_kg, {
    client: kgClient,
    variables: {
      query: {
        op: 'eq',
        field: 'node_id',
        value: primaryKeyRoot,
      },
      namespace: 'myfinance_editor',
    },
    skip: isNil(primaryKeyRoot),
  })

  const { data: dataConnected } = useQuery(_get_connected, {
    client: kgClient,
    variables: {
      node_id: primaryKeyRoot,
      namespace: 'myfinance_editor',
    },
    skip: isNil(primaryKeyRoot),
  })

  const primaryKeys = useMemo(() => {
    if (isNil(dataConnected) || isNil(dataRoot)) return null

    const order = find(dataRoot.query_kg[0].attributes, {
      name: '.children_order_',
    })

    return order
      ? order.values
          .map(node_id => find(dataConnected.get_connected, { node_id }))
          .map(({ name, attributes }) => ({
            name,
            keys: find(attributes, { name: 'keys' }).values,
          }))
      : defaultPrimaryKeys
  }, [dataConnected, dataRoot, defaultPrimaryKeys])

  return primaryKeys
}

function usePrimaryKeysInStorage(
  scope_type,
  primaryKeyRoot,
  defaultPrimaryKeys,
) {
  const primaryKeys = usePrimaryKeys(primaryKeyRoot, defaultPrimaryKeys)
  const [nameStorage, setNameStorage, , isLoading] = useLocalStorage(
    `usePrimaryKeysInStorage.${scope_type}`,
    -1,
  )

  const [selected, setSelected] = useState(null)
  useEffect(() => {
    // Initiate selected state.
    if (!isLoading && primaryKeys && !selected) {
      if (isEmpty(nameStorage)) setNameStorage(primaryKeys[0].name)
      setSelected(find(primaryKeys, { name: nameStorage }) || primaryKeys[0])
    }
  }, [isLoading, selected, primaryKeys, nameStorage, setNameStorage])

  const updateSelected = useCallback(
    selected => {
      setSelected(selected)
      setNameStorage(selected.name)
    },
    [setSelected, setNameStorage],
  )

  return [primaryKeys, selected, updateSelected]
}

function computeScore(
  firstGroup,
  secondGroup,
  selectedPrimaryKeys,
  oneOneMapping,
  currentSchema,
  scoringPlugins,
) {
  const result = scoringDocs(
    firstGroup,
    secondGroup,
    selectedPrimaryKeys.keys,
    currentSchema,
    scoringPlugins,
  )

  if (oneOneMapping) oneToOneMapping(result, secondGroup)
  return result.map(doc => {
    const orphan = isEmpty(doc.scores)
    const fullMatched =
      !orphan && doc.current.matched_target === get(doc.scores, '0.doc._id')
    return {
      ...doc,
      status: orphan ? 'Orphan' : fullMatched ? 'FullMatched' : 'MightMatched',
    }
  })
}

export function useCompareController(
  ticket,
  fullAccessStates,
  primaryKeyRoot,
  defaultPrimaryKeys,
  currentSchema,
  scoringPlugins,
  useDocs = useFetchDocOfTicket,
) {
  if (
    size(flatMap(scoringPlugins, 'scoringFields')) !==
    size(uniq(flatMap(scoringPlugins, 'scoringFields')))
  )
    throw new Error('Duplicate scoring fields is not allow.')

  const [
    allPrimaryKeys,
    selectedPrimaryKeys,
    setSelectedPrimaryKeys,
  ] = usePrimaryKeysInStorage(
    ticket.scope_type,
    primaryKeyRoot,
    defaultPrimaryKeys,
  )

  const { email, peepMode } = useContext(ProjectContext)
  const remoteDocs = useDocs(ticket, currentSchema)

  const sortedDocs = useMemo(
    () =>
      map(remoteDocs, doc =>
        sortField(doc, currentSchema, flatMap(scoringPlugins, 'scoringFields')),
      ),
    [remoteDocs, scoringPlugins, currentSchema],
  )

  const { updateCache, docs: localDocs } = useCacheDocs(
    email,
    peepMode,
    ticket,
    sortedDocs,
    fullAccessStates,
  )

  const [oneOneMapping, setOneOneMappting] = useLocalStorage(
    'oneOneMapping',
    true,
  )
  const [matched, setMatched] = useImmer(null)

  const [computing, setComputing] = useState(false)
  const [turnOnComputing, setTurnOnComputing] = useState(false)
  const [pov, uploaders] = usePOV(ticket, localDocs)

  useEffect(() => {
    const readyToCompute = !isEmpty(localDocs) && !isNull(pov)
    if (readyToCompute) {
      // Change computing state first, so that components depend on this state
      // have change to update UI before triggering re-computing, which may
      // take several seconds.
      setMatched(() => null)
      setComputing(true)

      // Trigger computing asynchronously.
      setTimeout(() => {
        setTurnOnComputing(true)
      }, 0)
    }
  }, [
    localDocs,
    ticket,
    email,
    peepMode,
    oneOneMapping,
    selectedPrimaryKeys,
    setMatched,
    setComputing,
    setTurnOnComputing,
    pov,
  ])

  useEffect(() => {
    if (!selectedPrimaryKeys || isEmpty(pov) || !turnOnComputing) return
    const [firstGroup, secondGroup] = partition(localDocs, {
      uploader: pov,
    })

    const result = computeScore(
      firstGroup,
      secondGroup,
      selectedPrimaryKeys,
      oneOneMapping,
      currentSchema,
      scoringPlugins,
    )
    const orphanInSecond = filter(
      secondGroup,
      ({ _id }) =>
        !includes(flatMap(result, ({ scores }) => map(scores, 'doc._id')), _id),
    )

    setMatched(draft => [
      ...(!draft
        ? result
        : draft.map(item => ({
            ...result.find(
              ({ current }) => current._id === get(item, 'current._id'),
            ),
            changed: !!item.changed,
          }))),
      ...map(orphanInSecond, doc => ({
        scores: [
          {
            doc,
            score: [],
          },
        ],
        status: 'Orphan',
      })),
    ])

    setComputing(false)
    setTurnOnComputing(false)
  }, [
    localDocs,
    ticket,
    email,
    setMatched,
    peepMode,
    oneOneMapping,
    selectedPrimaryKeys,
    turnOnComputing,
    setComputing,
    setTurnOnComputing,
    pov,
    scoringPlugins,
    currentSchema,
  ])

  const updateMatched = useCallback(
    docs => {
      docs.forEach(({ doc, originalId }) =>
        updateCache(toEditableDoc(doc, currentSchema), originalId),
      )
      setMatched(draft => {
        docs.forEach(({ doc }) => {
          const matched =
            find(draft, { current: { _id: doc._id } }) ||
            find(draft, ({ scores }) => get(scores, '0.doc._id') === doc._id)

          if (matched) matched.changed = true
        })
      })
    },
    [setMatched, updateCache, currentSchema],
  )

  return {
    docs: localDocs,
    matched,
    uploaders,
    updateMatched,
    oneOneMapping,
    setOneOneMappting,
    computing,
    allPrimaryKeys,
    selectedPrimaryKeys,
    setSelectedPrimaryKeys,
  }
}

export function sortField(doc, currentSchema, skipFields = []) {
  if (Array.isArray(doc)) {
    return sortBy(
      doc.map(value => sortField(value, currentSchema, skipFields)),
      Object.keys(currentSchema),
    )
  }

  return mapValues(doc, (value, key) => {
    // Some fields may be appended into doc for the need of the app,
    // such as 'local_doc' or '__typename'. No need to sort this kind
    // of fields, since they are not referred in comparing process.
    if (
      !has(currentSchema, key) ||
      has(currentSchema, `${key}.skipCompare`) ||
      includes(skipFields, key)
    )
      return value
    const { isTypedArray, extractedSchema, extractedType } = computeType(
      currentSchema[key].type,
    )
    if (!isNil(extractedSchema)) {
      return sortField(value, extractedSchema, skipFields)
    }

    if (castArray(value).some(isObject)) {
      // We should define nested type for this field.
      throw new Error(`nested type of ${key} is not defined.`)
    }
    if (!isTypedArray) return value
    return extractedType === 'node'
      ? // arrayOf(node) is an unordered list, by design.
        value.concat().sort()
      : value
  })
}

const defaultScore = () => ({ score: [0, 0], diff: [] })
export function scoringDoc(props) {
  const {
    first,
    second,
    primaryKeys,
    currentSchema,
    currentPath,
    scoringPlugins,
  } = props
  if (!first) return defaultScore()

  const skipFields = flatMap(scoringPlugins, 'scoringFields')
  if (size(uniq(skipFields)) !== size(skipFields)) {
    throw new Error('Fields listed in scoringFields must be uniq.')
  }

  const accumulate = scores => ({
    score: [sum(map(scores, 'score.0')), sum(map(scores, 'score.1'))],
    diff: flatMap(scores, 'diff'),
  })

  const generateScore = (fieldname, point) =>
    primaryKeys.includes(fieldname) ? [point, 0] : [0, point]
  return accumulate([
    ...map(
      filter(
        keys(first),
        fieldname =>
          !get(currentSchema, `${fieldname}.skipCompare`) &&
          !includes(skipFields, fieldname),
      ),
      fieldname => {
        if (!has(currentSchema, fieldname)) return defaultScore()

        const { extractedSchema } = computeType(currentSchema[fieldname].type)

        const firstField = sortBy(compact(castArray(first[fieldname])))
        const secondField = sortBy(compact(castArray(get(second, fieldname))))

        if (!isNil(extractedSchema)) {
          const [longerField, shorterField] = orderBy(
            [firstField, secondField],
            'length',
            'desc',
          )

          const childScores = accumulate(
            longerField.map((item, index) =>
              scoringDoc({
                first: item,
                second: get(shorterField, index),
                primaryKeys,
                currentSchema: extractedSchema,
                currentPath: flatten([
                  compact([currentPath, fieldname]),
                  index,
                ]).join('.'),
                scoringPlugins,
              }),
            ),
          )

          return {
            score: generateScore(fieldname, sum(childScores.score)),
            diff: childScores.diff,
          }
        }

        const point = size(intersection(firstField, secondField))
        const diffPath = compact([currentPath, fieldname]).join('.')

        const diff = isEqual(firstField, secondField)
          ? []
          : [{ diffPath: diffPath, fieldname: head(split(diffPath, '.')) }]
        return {
          score: generateScore(fieldname, point),
          diff,
        }
      },
    ),
    ...flatMap(
      filter(scoringPlugins, { currentSchema }),
      ({ scoringFunction, scoringFields }) => {
        const result = scoringFunction({
          ...props,
          // Pass selected fields, so that we can make sure that the scoring
          // points returning from each plugin are orthogonal.
          first: pick(first, scoringFields),
          second: pick(second, scoringFields),
        })
        forEach(result, ({ diff }) => {
          forEach(diff, ({ fieldname }) => {
            // scoringFunction should only take care of fields that was listed
            // in plugin.scoringFields.
            if (!includes(scoringFields, fieldname)) {
              throw new Error(`${fieldname} is not listed in scoringFields`)
            }
          })
        })
        return result
      },
    ),
  ])
}

const getScore = (primary, secondary) => 1000 * primary + secondary
function sortByPoints(left, right) {
  return (
    getScore(...get(right, 'scores.0.score', [0, 0])) -
    getScore(...get(left, 'scores.0.score', [0, 0]))
  )
}

export function scoringDocs(
  firstGroup,
  secondGroup,
  primaryKeys,
  currentSchema,
  scoringPlugins,
) {
  const matched_targets = compact(map(firstGroup, 'matched_target'))

  return firstGroup
    .map(doc => ({
      current: doc,
      scores: secondGroup
        .filter(({ _id }) =>
          doc.matched_target
            ? _id === doc.matched_target
            : !matched_targets.includes(_id),
        )
        .map(paired => ({
          doc: paired,
          ...scoringDoc({
            first: find(firstGroup, { _id: doc._id }),
            second: find(secondGroup, { _id: paired._id }),
            primaryKeys,
            currentSchema,
            currentPath: null,
            scoringPlugins,
          }),
        }))
        // Remove totally unmatched docs from score
        .filter(({ score: [primary, secondary] }) => primary || secondary)
        .sort(
          (
            { score: [primaryPointLeft, seconardyPointLeft] },
            { score: [primaryPointRight, seconardyPointRight] },
          ) =>
            getScore(primaryPointRight, seconardyPointRight) -
            getScore(primaryPointLeft, seconardyPointLeft),
        ),
    }))
    .sort(sortByPoints)
}

export function oneToOneMapping(sortingResult) {
  let flatten = flatMap(sortingResult, left =>
    map(left.scores, right => ({ left, right })),
  )

  // Reset scores since we are going to set it up again.
  sortingResult.forEach(left => {
    left.scores = []
  })

  // Sort by score.
  flatten.sort(
    ({ right: { score: scoreA } }, { right: { score: scoreB } }) =>
      getScore(...scoreA) - getScore(...scoreB),
  )

  while (!isEmpty(flatten)) {
    // Pick current maximum score.
    const { left, right } = flatten.pop()
    left.scores.push(right)

    // Filter non-valid items.
    const leftID = left.current._id
    const rightID = right.doc._id
    flatten = reject(
      flatten,
      ({ left, right }) =>
        left.current._id === leftID || right.doc._id === rightID,
    )
  }
  sortingResult.sort(sortByPoints)
}
