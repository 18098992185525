import PropTypes from 'prop-types'
import Joi from 'joi'
import { values } from 'lodash'

import {
  useGetConnectedNodes,
  KG_NODES,
  namespaces,
  CardGroupScope,
} from '@common'
import {
  addSchema,
  RequiredString,
  RequiredMongoIdString,
  shortString,
  RequiredKGNodeIdString,
} from '@schema'

export const CardGroupPropType = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  card_name: PropTypes.string.isRequired,
  scope: PropTypes.oneOf(Object.values(CardGroupScope)).isRequired,
  card_provider_id: PropTypes.string.isRequired,
  card_provider_name: PropTypes.string.isRequired,
})

const roots = [
  {
    node_id: KG_NODES.entity.ISSUER_ROOT_ID,
    namespace: 'entity',
  },
  {
    node_id: KG_NODES.entity.BANK_ROOT_ID,
    namespace: 'entity',
  },
]

export const cardGroupSchema = {
  _id: {
    schema: RequiredMongoIdString,
    type: 'string',
    label: '卡片代碼',
    skipCompare: true,
  },
  card_name: {
    schema: RequiredString.max(shortString),
    type: 'string',
    label: '卡片名稱',
  },
  scope: {
    schema: Joi.string()
      .valid(...values(CardGroupScope))
      .required(),
    enum: values(CardGroupScope),
    type: 'string',
    label: '範圍',
  },
  card_provider_id: {
    schema: RequiredKGNodeIdString,
    type: 'node',
    label: '發行者',
    referToKG: 'entity',
    useNodes: () => useGetConnectedNodes(roots),
  },
}

addSchema({ cardGroupSchema }, '卡片群組', namespaces.card.value)
