import { useState, useEffect, useContext, useCallback, useMemo } from 'react'
import Cookies from 'js-cookie'
import _isEqual from 'lodash/isEqual'
import { LocalStorageContext } from './Context'
import { useHasMounted } from './Misc'

export function useLocalStorage(key, defaultVal) {
  const hasMounted = useHasMounted()
  const [storage, setStorage] = useState(defaultVal)
  const [isLoading, setIsLoading] = useState(true)

  const clearStorage = useCallback(() => {
    if (hasMounted) {
      setStorage(defaultVal)
      window.localStorage.removeItem(key)
    }
  }, [hasMounted, setStorage, key, defaultVal])

  const result = useMemo(() => [storage, setStorage, clearStorage, isLoading], [
    storage,
    setStorage,
    clearStorage,
    isLoading,
  ])

  // set default value for storage & localStorage if haven't already
  useEffect(() => {
    if (!hasMounted) return
    if (localStorage.getItem(key) === null) {
      const value = JSON.stringify(defaultVal)
      localStorage.setItem(key, value)
    }
  }, [defaultVal, hasMounted, key])

  // load data from localStorage if user has added cards before
  useEffect(() => {
    if (hasMounted) {
      const value = JSON.parse(localStorage.getItem(key))
      setStorage(value)
    }
  }, [hasMounted, key])

  // update localStorage when user add/remove card
  useEffect(() => {
    if (!hasMounted) return
    const value = JSON.stringify(storage)
    localStorage.setItem(key, value)
  }, [hasMounted, key, storage])

  // update loading state
  useEffect(() => {
    if (!hasMounted) return
    const data = JSON.parse(localStorage.getItem(key))
    if (data) {
      const isSynced = _isEqual(storage, data)
      setIsLoading(!isSynced)
    }
  }, [hasMounted, key, storage])

  return result
}

export function useCookie(name, dafaultValue, path = '/') {
  function getDefault(name) {
    const cookie = Cookies.get(name)
    return typeof cookie !== 'undefined' ? JSON.parse(cookie) : dafaultValue
  }
  const [cookie, setCookie] = useState(getDefault(name))

  const setCookieAndStorage = useCallback(
    cookie => {
      Cookies.set(name, JSON.stringify(cookie), { path })
      setCookie(cookie)
    },
    [setCookie, name, path],
  )

  return [cookie, setCookieAndStorage, setCookie]
}

export function useCookieInContext(name, defaultValue) {
  const context = useContext(LocalStorageContext)
  const [cookie, setCookieStorage, setCookie] = useCookie(name, defaultValue)

  useEffect(() => {
    if (context && name in context) {
      setCookie(context[name])
    }
  }, [context, name, setCookie])

  return context ? [cookie] : [cookie, setCookieStorage]
}
