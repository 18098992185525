import Joi from 'joi'
import { isNil } from 'lodash'
import { Box } from '@changingai/react-editor-common-component'
import { URL_RE } from '@schema'

export function renderPlainTextChip(chip) {
  return <Box>{chip}</Box>
}

export function renderUrlChip(chip) {
  const { error } = Joi.string()
    .regex(URL_RE)
    .validate(chip)
  const hasError = !isNil(error)
  return (
    <div>
      <a
        href={chip}
        target="_blank"
        style={{ color: hasError ? 'OrangeRed' : 'yellow' }}
        rel="noopener noreferrer"
      >
        {chip}
      </a>
    </div>
  )
}
