import { useCallback, useMemo } from 'react'
import {
  castArray,
  flatten,
  map,
  some,
  groupBy,
  isEmpty,
  get,
  find,
} from 'lodash'

import { ValidationCategory, useDialog } from '@common'
import { ValidationDialog } from '@dialog'
import { useDocCheck } from '@remote'
import { purifySchemaDoc } from '@schema'

function useDefaultFilter() {
  const filter = useCallback(doc => doc, [])
  return filter
}

export function useCheckApi({
  rules = [],
  context,
  externalAPI = [],
  useDocFilter = useDefaultFilter,
}) {
  const filter = useDocFilter()

  const checkAPI = useCallback(
    doc => ({
      status: 200,
      json: () =>
        Promise.all(map(rules, validate => validate(filter(doc), context))),
    }),
    [filter, context, rules],
  )

  const composedChecker = useCallback(
    async doc => {
      const results = await Promise.all(
        map([checkAPI, ...castArray(externalAPI)], checker => checker(doc)),
      )
      if (some(map(results, 'status'), status => status !== 200)) {
        throw new Error(
          `response.status = ${
            find(results, ({ status }) => status !== 200).status
          }`,
        )
      }
      return flatten(
        await Promise.all(map(results, response => response.json())),
      )
    },
    [checkAPI, externalAPI],
  )

  return composedChecker
}

export function useValidateDialog({
  checkOp,
  onUpdate,
  onUpload,
  currentSchema,
}) {
  const onRuleCheck = useDocCheck(checkOp)
  const [showValidation, renderValidation] = useDialog(ValidationDialog)
  const onValidAndUpload = useCallback(
    async context => {
      if (!checkOp) return { docs: await onUpload(context), errors: null }

      const results = await onRuleCheck(
        map(castArray(context), doc => purifySchemaDoc(doc, currentSchema)),
      )
      const categories = groupBy(flatten(results), 'category')
      if (
        isEmpty(get(categories, ValidationCategory.Error)) &&
        isEmpty(get(categories, ValidationCategory.Warning))
      )
        return { docs: await onUpload(context), errors: null }
      showValidation({
        results: categories,
        docs: castArray(context),
        onUpload: async docs => {
          onUpdate({ docs: await onUpload(docs), errors: null })
        },
      })
      return {
        docs: null,
        errors: map(get(categories, ValidationCategory.Error), 'label'),
      }
    },
    [showValidation, onRuleCheck, onUpload, onUpdate, checkOp, currentSchema],
  )

  const result = useMemo(
    () => ({
      renderValidation,
      onValidAndUpload,
    }),
    [renderValidation, onValidAndUpload],
  )

  return result
}
