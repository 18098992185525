import { useCallback, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { isNil, get } from 'lodash'

import {
  KG_NODES,
  ProjectContext,
  TicketPropType,
  TicketState,
  useDialog,
} from '@common'
import { mergeInputFactory } from '@widget'
import { MergeDialog } from '@dialog'

import { BenefitContext, useBenefitContext } from './context'
import { checkBenefit, useTranspile, useUploadBenefitOfTicket } from './common'
import { BenefitEditDialog } from './editor'
import { benefitSchema } from './schema'
import { useCompareController } from '../Compare'
import CompareView, { TicketHeaderWithLink } from '../CompareView'
import { useCheckApi } from '../Validation'

function BenefitMergerDialog(props) {
  const { fieldname } = props
  const config = useMemo(() => mergeInputFactory(benefitSchema, fieldname), [
    fieldname,
  ])
  return <MergeDialog config={config} {...props} />
}

BenefitMergerDialog.propTypes = {
  fieldname: PropTypes.string.isRequired,
}

const fullAccessStates = [TicketState.MERGE, TicketState.FINISH]

const primaryKeyRoot = KG_NODES.myfinance_editor.BENEFIT_PRIMARY_KEY_ROOT_ID

const defaultPrimaryKeys = {
  name: 'default-match-rule',
  keys: ['benefit_name'],
}

function useController(ticket) {
  const { email } = useContext(ProjectContext)
  const {
    docs,
    matched,
    uploaders,
    updateMatched,
    oneOneMapping,
    setOneOneMappting,
    computing,
    allPrimaryKeys,
    selectedPrimaryKeys,
    setSelectedPrimaryKeys,
  } = useCompareController(
    ticket,
    fullAccessStates,
    primaryKeyRoot,
    defaultPrimaryKeys,
    benefitSchema,
  )

  const [showDialog, renderEditor] = useDialog(BenefitEditDialog)
  const showEditor = useCallback(
    (doc, crud) => {
      showDialog({
        doc,
        ticket,
        crud,
      })
    },
    [ticket, showDialog],
  )

  const upload = useUploadBenefitOfTicket()
  const uploadDoc = useCallback(context => upload(ticket, context, email), [
    upload,
    ticket,
    email,
  ])
  const [showMergeDialog, renderMergeDialog] = useDialog(BenefitMergerDialog)

  return {
    isReady: !isNil(docs),
    docs,
    matched,
    uploaders,
    updateMatched,
    uploadDoc,
    oneOneMapping,
    setOneOneMappting,
    computing,
    showEditor,
    renderEditor,
    allPrimaryKeys,
    selectedPrimaryKeys,
    setSelectedPrimaryKeys,
    showMergeDialog,
    renderMergeDialog,
  }
}

function BenefitCompareView({ ticket }) {
  const context = useBenefitContext(ticket)
  const checker = useCheckApi({ externalAPI: checkBenefit })

  return (
    <BenefitContext.Provider value={context}>
      <CompareView
        ticket={ticket}
        useController={useController}
        useTranspile={useTranspile}
        currentSchema={benefitSchema}
        Caption={({ ticket }) => {
          const url = get(context, 'contract.file_url')
          if (isNil(url)) return null
          return (
            <TicketHeaderWithLink
              ticket={ticket}
              to={url}
              linkText="（合約連結）"
              externalUrl={true}
            />
          )
        }}
        checker={checker}
      />
    </BenefitContext.Provider>
  )
}

BenefitCompareView.propTypes = {
  ticket: TicketPropType.isRequired,
}

export default BenefitCompareView
