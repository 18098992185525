import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import produce from 'immer'
import {
  forEach,
  get,
  map,
  isNil,
  castArray,
  findKey,
  indexOf,
  unset,
  groupBy,
  isEmpty,
  filter,
  includes,
  join,
} from 'lodash'
import { toast } from 'react-toastify'

import { isLocalDoc, getActiveState, getDataEditors, MatchState } from '@common'
import { purifySchemaDoc, SchemaStore } from '@schema'
import { DuplicateToast } from './Shell'
import { _pushHistory } from './Uploader.gql'

function getToBeUploadedPairDocs(docs, ticket) {
  const pairDocs = docs.filter(
    doc => doc.matched_state === MatchState.FULLY_MATCHED,
  )
  return docs.concat(
    map(pairDocs, doc =>
      produce(doc, draft => {
        const assignees = getDataEditors(ticket)
        const uploader = assignees.find(assignee => assignee !== doc.uploader)
        draft._id = draft.matched_target
        draft.uploader = uploader
      }),
    ),
  )
}
export function useUploadDocWithHistory(currentSchema, uploadMutation) {
  const [historyMutation] = useMutation(_pushHistory)

  const upload = useCallback(
    async (ticket, docs, email, detectDuplicate = false) => {
      const sourceDocs =
        isNil(ticket) || getActiveState(ticket).assignee_as_editor
          ? castArray(docs)
          : getToBeUploadedPairDocs(castArray(docs), ticket)
      const ids = map(sourceDocs, '_id')

      try {
        const purifiedDocs = sourceDocs.map(doc =>
          purifySchemaDoc(
            produce(doc, draft => {
              if (isLocalDoc(doc)) delete draft._id
              forEach(currentSchema, (value, field) => {
                if (get(value, 'downloadOnly')) unset(draft, field)
              })

              // Not every doc belongs to a ticket, such as contract and card doc.
              // Nil check before accessing it.
              if (!isNil(ticket) && getActiveState(ticket).assignee_as_editor)
                draft.uploader = email
            }),
            currentSchema,
          ),
        )

        const extractTicketHistory = ticket => {
          if (isNil(ticket))
            return {
              ticket_state: null,
              assignee_index: null,
            }

          return {
            ticket_state: getActiveState(ticket).main_state,
            assignee_index: get(
              getActiveState(ticket),
              'assignee_as_editor',
              false,
            )
              ? indexOf(getDataEditors(ticket), email)
              : -1,
          }
        }

        const uploadedDocs = await uploadMutation(purifiedDocs)
        const dupIds = []
        if (detectDuplicate) {
          const groupedDocs = groupBy(uploadedDocs, '__typename')
          const dups = get(groupedDocs, 'DuplicateDoc', [])
          dupIds.push(...map(dups, '_id'))
          if (!isEmpty(dups)) {
            toast.error(<DuplicateToast dups={dups} />, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
            })
          }
        }

        const correctUploadedDocs = filter(
          uploadedDocs,
          ({ _id }) => !includes(dupIds, _id),
        )
        if (!isEmpty(correctUploadedDocs)) {
          await historyMutation({
            variables: {
              hisotries: map(correctUploadedDocs, ({ _id }, index) => ({
                target_id: _id,
                docType: findKey(
                  SchemaStore,
                  schema => schema === currentSchema,
                ),
                actionType: isLocalDoc(sourceDocs[index]) ? 'CREATE' : 'UPDATE',
                actionContent: JSON.stringify({
                  ...uploadedDocs[index],
                  ...extractTicketHistory(ticket),
                }),
              })),
            },
          })

          toast.success(
            `Upload ${join(
              map(correctUploadedDocs, '_id'),
              ' /',
            )} successfully!`,
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
            },
          )
        }

        return map(correctUploadedDocs, (doc, index) => ({
          doc,
          originalId: ids[index],
        }))
      } catch (e) {
        toast.error(`Upload doc error: ${e}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        })
        return []
      }
    },
    [historyMutation, currentSchema, uploadMutation],
  )

  return upload
}

export function useDeleteDocWithHistory(currentSchema, deleteMutation) {
  const [historyMutation] = useMutation(_pushHistory)
  const deleteDoc = useCallback(
    doc => {
      try {
        deleteMutation(doc._id)
        historyMutation({
          variables: {
            hisotries: [
              {
                target_id: doc._id,
                docType: findKey(
                  SchemaStore,
                  schema => schema === currentSchema,
                ),
                actionType: 'DELETE',
                actionContent: JSON.stringify(doc),
              },
            ],
          },
        })

        toast.success('Delete Successfully!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        })
      } catch (e) {
        toast.error(`Delete a doc failed: ${e}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        })
      }
    },
    [currentSchema, deleteMutation, historyMutation],
  )

  return deleteDoc
}
