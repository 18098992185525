import { useMemo, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import 'styled-components/macro'
import css from '@styled-system/css'
import { useImmer } from 'use-immer'
import {
  map,
  size,
  get,
  find,
  filter,
  uniq,
  every,
  range as _range,
  isNil,
  isEmpty,
  split,
  toUpper,
  includes,
} from 'lodash'
import { Checkbox } from 'primereact/checkbox'
import { Dialog } from 'primereact/dialog'
import { ListBox } from 'primereact/listbox'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { PickList } from 'primereact/picklist'
import { Tag } from 'primereact/tag'
import { Flex, Box, Text } from '@changingai/react-editor-common-component'

import { isEqualFactors } from './common'
import { feeTableSchema } from './schema'

import { schemaToPropTypes } from '@schema'
import {
  ActionButtons,
  StatusBarRoot,
  SelectionList,
  StyledChips,
} from '@widget'

function computeValues(node) {
  const currentType = get(find(node.attributes, { name: 'type' }), 'values.0')

  if (currentType === 'list') {
    return get(find(node.attributes, { name: 'list' }), 'values')
  }

  if (currentType === 'free') {
    return []
  }

  const [min, max, advance] = map(
    get(find(node.attributes, { name: 'range' }), 'values'),
    parseFloat,
  )
  return map(_range(min, max + advance, advance), number => number.toString())
}

function VariableSelector({
  node_id,
  requestVariables,
  values,
  setValues,
  maxLength,
}) {
  const [variable, range, currentType] = useMemo(() => {
    const variable = find(requestVariables, { node_id })
    return [
      variable,
      map(
        get(find(variable.attributes, { name: 'range' }), 'values', []),
        parseFloat,
      ),
      get(find(variable.attributes, { name: 'type' }), 'values.0'),
    ]
  }, [node_id, requestVariables])

  const [customizedRange, setCustomizedRange] = useState([])
  useEffect(() => {
    if (currentType === 'range' && size(range) === 3) {
      setCustomizedRange([range[0].toString(), range[1].toString(), range[2]])
    }
  }, [range, setCustomizedRange, currentType])

  const canGenerateRange = useMemo(() => {
    const start = parseFloat(get(customizedRange, 0))
    const end = parseFloat(get(customizedRange, 1))
    const advance = get(customizedRange, 2)

    const notEmpty = !isNaN(start) && !isNaN(end)
    const validBoundary =
      advance > 0
        ? end > start && start >= range[0] && end <= range[1]
        : start > end && start <= range[0] && end >= range[1]

    return notEmpty && validBoundary
  }, [customizedRange, range])

  if (currentType === 'free') {
    return (
      <Box width="100%" height="350px" overflowY="auto" overflowX="hidden">
        <Flex
          width="100%"
          p="2"
          bg="blacks.0"
          justifyContent="center"
          fontSize="h1"
        >
          {get(variable, 'name')}
        </Flex>
        <Box p="2">
          <Box width="100%">請手動輸入維度值</Box>
          <StyledChips
            css={css({
              '&& .p-inputtext': {
                borderRadius: 0,
              },
            })}
            value={get(find(values, { node_id }), 'choice', [])}
            onChange={({ value }) => {
              if (size(uniq(split(value, /[;|,|\s]/))) <= maxLength) {
                setValues(draft => {
                  const variable = find(draft, { node_id })
                  variable.choice = uniq(split(value, /[;|,|\s]/))
                })
              }
            }}
          />
        </Box>
      </Box>
    )
  }
  return (
    <Flex
      width="100%"
      flexWrap="wrap"
      css={css({
        '&& .p-picklist.p-component': {
          display: 'flex',
          width: '100%',
          alignItems: 'center',
        },
        '& .p-picklist-listwrapper.p-picklist-source-wrapper, & .p-picklist-listwrapper.p-picklist-target-wrapper': {
          flex: '1 1 50%',
        },
      })}
    >
      <Flex
        width="100%"
        p="2"
        bg="blacks.0"
        justifyContent="center"
        fontSize="h1"
      >
        {get(variable, 'name')}
      </Flex>
      <PickList
        source={get(find(values, { node_id }), 'origin', [])}
        target={get(find(values, { node_id }), 'choice', [])}
        itemTemplate={item => <Flex>{item}</Flex>}
        sourceHeader="原始維度值"
        targetHeader="選取維度值"
        sourceStyle={{ height: '500px', width: '100%' }}
        targetStyle={{ height: '500px', width: '100%' }}
        onChange={({ source, target }) => {
          if (size(target) <= maxLength) {
            setValues(draft => {
              const variable = find(draft, { node_id })
              variable.origin = source
              variable.choice = target
            })
          }
        }}
      />
      {currentType === 'range' && size(customizedRange) === 3 && (
        <>
          <Flex
            width="100%"
            alignItems="center"
            m="2"
            justifyContent="space-between"
          >
            <Box mx="2">From</Box>
            <InputText
              keyfilter="num"
              value={customizedRange[0]}
              placeholder="起始值"
              onChange={({ target: { value } }) =>
                setCustomizedRange([
                  value,
                  customizedRange[1],
                  customizedRange[2],
                ])
              }
            />
            <Box mx="2">To</Box>
            <InputText
              keyfilter="num"
              value={customizedRange[1]}
              placeholder="結束值"
              onChange={({ target: { value } }) =>
                setCustomizedRange([
                  customizedRange[0],
                  value,
                  customizedRange[2],
                ])
              }
            />
            <Button
              type="button"
              label="Gen"
              disabled={!canGenerateRange}
              className="p-button-success"
              onClick={() => {
                setValues(draft => {
                  const variable = find(draft, { node_id })
                  const [min, max, advance] = customizedRange
                  variable.origin = map(
                    _range(parseFloat(min), parseFloat(max) + advance, advance),
                    number => number.toString(),
                  )
                  variable.choice = []
                })
              }}
            />
          </Flex>
          <Flex px="2" justifyContent="flex-end" width="100%" color="gray">
            {`原始設定起始值：${range[0]}。結束值：${range[1]}。間距：${
              range[2]
            }`}
          </Flex>
        </>
      )}
    </Flex>
  )
}

VariableSelector.propTypes = {
  node_id: PropTypes.string.isRequired,
  requestVariables: PropTypes.arrayOf(PropTypes.object).isRequired,
  values: PropTypes.arrayOf(PropTypes.object).isRequired,
  setValues: PropTypes.func.isRequired,
  maxLength: PropTypes.number.isRequired,
}

export function SelectAxisDialog({
  onHide,
  onChange,
  requestVariables,
  context: { factors },
  mainAxes,
}) {
  const [selectedAxes, setSelectedAxes] = useState(map(factors, 'id'))

  const options = useMemo(
    () =>
      map(requestVariables, ({ node_id, name, attributes }) => ({
        id: node_id,
        name,
        value: node_id,
        type: get(find(attributes, { name: 'type' }), 'values.0'),
      })),
    [requestVariables],
  )

  const [selected, setSelect] = useState(null)
  const [axisValues, setAxisValues] = useImmer(
    map(requestVariables, node => ({
      ...node,
      origin: filter(
        computeValues(node),
        value =>
          !includes(
            get(find(factors, { id: node.node_id }), 'values', []),
            value,
          ),
      ),
      choice: get(find(factors, { id: node.node_id }), 'values', []),
    })),
  )

  const isSelectable = useCallback(
    id =>
      !includes(mainAxes, id) && size(get(find(factors, { id }), 'values')) < 2,
    [factors, mainAxes],
  )

  const isDirty = useMemo(() => {
    const newFactors = map(selectedAxes, axis => ({
      id: axis,
      values: find(axisValues, { node_id: axis }).choice,
    }))
    return !isEqualFactors(factors, newFactors)
  }, [axisValues, selectedAxes, factors])

  const error = useMemo(() => {
    if (size(selectedAxes) < 2) return '至少選擇兩個維度'
    if (
      !every(
        map(selectedAxes, axis => find(axisValues, { node_id: axis }).choice),
        choice => !isEmpty(choice) && size(uniq(choice)) === size(choice),
      )
    )
      return '不允許空的維度/重複的維度值'
  }, [axisValues, selectedAxes])

  return (
    <Dialog
      header="Select axes of FeeTable"
      appendTo={document.body}
      visible={true}
      style={{ width: '900px' }}
      contentStyle={{ overflow: 'visible' }}
      modal={true}
      onHide={onHide}
    >
      <Flex width="100%" flexWrap="wrap">
        <Flex width="100%">
          <Box flex="0 0 auto">
            <ListBox
              value={selected}
              options={options}
              filter
              optionLabel="name"
              onChange={({ value }) => {
                if (!isNil(value) && isSelectable(value)) {
                  setSelect(value)
                  if (!selectedAxes.includes(value)) {
                    setSelectedAxes([...selectedAxes, value])
                  }
                }
              }}
              itemTemplate={({ name, value, type }) => (
                <Flex flex="0 0 100%">
                  <Checkbox
                    inputId={value}
                    onChange={({ checked }) => {
                      if (checked) {
                        setSelectedAxes([...selectedAxes, value])
                      } else {
                        setSelectedAxes(selectedAxes.filter(id => id !== value))
                      }
                    }}
                    checked={selectedAxes.includes(value)}
                    disabled={!isSelectable(value)}
                    style={{ margin: '3px 5px' }}
                  />
                  <label htmlFor={value} className="p-checkbox-label">
                    <Tag value={toUpper(type[0])} severity="warning" />
                    <Text ml="2">{name}</Text>
                  </label>
                </Flex>
              )}
              style={{ width: '15rem' }}
              listStyle={{ maxHeight: '500px' }}
            />
          </Box>
          <Flex
            flex="1 1 auto"
            border="1px solid"
            borderColor="blacks.3"
            flexWrap="wrap"
            alignItems="flex-start"
            alignContent="flex-start"
            ml="1"
          >
            {isNil(selected) && (
              <Flex
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="center"
                fontSize="h1"
                color="gray"
              >
                請選擇變數
              </Flex>
            )}
            {!isNil(selected) && (
              <VariableSelector
                key={selected}
                requestVariables={requestVariables}
                node_id={selected}
                values={axisValues}
                setValues={setAxisValues}
                maxLength={isEmpty(factors) ? 9999 : 1}
              />
            )}
          </Flex>
        </Flex>

        <SelectionList selected={selectedAxes} items={options} />
      </Flex>
      <StatusBarRoot py={2}>
        <Flex width="100%" justifyContent="flex-end">
          <Flex marginLeft="2" width="100%" alignItems="center" color="red">
            {error}
          </Flex>
          <ActionButtons
            buttons={{
              right: [
                {
                  label: !isEmpty(factors) ? 'Update' : 'Create',
                  action: !isEmpty(factors) ? 'Update' : 'Create',
                  className: 'p-button-primary',
                  enable: isNil(error) && isDirty,
                },
                {
                  label: 'Close',
                  action: 'Close',
                  className: 'p-button-secondary',
                },
              ],
            }}
            onClicked={action => {
              if (action === 'Create' || action === 'Update') {
                onChange(
                  'factors',
                  map(selectedAxes, axis => ({
                    id: axis,
                    values: find(axisValues, { node_id: axis }).choice,
                  })),
                )
              }
              onHide()
            }}
          />
        </Flex>
      </StatusBarRoot>
    </Dialog>
  )
}

SelectAxisDialog.propTypes = {
  onHide: PropTypes.func.isRequired,
  context: schemaToPropTypes(feeTableSchema).isRequired,
  onChange: PropTypes.func.isRequired,
  requestVariables: PropTypes.arrayOf(PropTypes.object).isRequired,
  mainAxes: PropTypes.arrayOf(PropTypes.string),
}
