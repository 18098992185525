import { useCallback, useEffect, useMemo } from 'react'
import { useImmer } from 'use-immer'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { AutoComplete } from 'primereact/autocomplete'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { attempt, isError, isEmpty, escapeRegExp, includes, map } from 'lodash'
import { useLocalStorage } from '@changing-cc/hooks'
import { VscRegex } from 'react-icons/vsc'

import { Flex, Box } from '@changingai/react-editor-common-component'
import theme from '@theme'

import { ButtonLike } from './button'

const DeleteButton = styled(Button)`
  &&& {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    height: 35px;
  }
`

const SearchInput = styled(AutoComplete)`
  && .p-autocomplete-input {
    width: 230px;
    height: 35px;
    border-radius: 0px;
    background: ${({ error }) =>
      error ? theme.colors.danger : theme.colors.whites[0]};
  }
`
const FieldDropdown = styled(Dropdown)`
  &&& {
    border-right: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 150px;
    height: 35px;
    background: #cfcfcf;
    align-items: center;
  }
`

const createSearchContext = (field = null, value = null) => ({ field, value })

export function SearchWidget({
  searchSuggestions,
  searchFields,
  cacheKey,
  onSearch,
  enableRegExpMode = false,
}) {
  const [searchContext, setSearchContext] = useLocalStorage(
    `SearchWidget.searchContext.${cacheKey}.v2`,
    [createSearchContext()],
  )

  const [regExpMode, setRegExpMode] = useLocalStorage(
    `SearchWidget.regExpMode.${cacheKey}`,
    false,
  )
  const [andGate, setAndGate] = useLocalStorage(
    `SearchWidget.andGate.${cacheKey}`,
    false,
  )

  useEffect(() => {
    const contexts = searchContext.filter(({ value }) => {
      return !isEmpty(value)
    })
    onSearch(
      isEmpty(contexts) ? null : contexts,
      regExpMode && enableRegExpMode,
      andGate,
    )
  }, [searchContext, regExpMode, enableRegExpMode, andGate, onSearch])

  return (
    <SearchToolBar
      searchSuggestions={searchSuggestions}
      searchFields={searchFields}
      searchContext={searchContext}
      setSearchContext={setSearchContext}
      enableRegExpMode={enableRegExpMode}
      regExpMode={regExpMode}
      setRegExpMode={setRegExpMode}
      andGate={andGate}
      setAndGate={setAndGate}
    />
  )
}

SearchWidget.propTypes = {
  searchSuggestions: PropTypes.arrayOf(PropTypes.string),
  searchFields: PropTypes.arrayOf(PropTypes.object),
  cacheKey: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  enableRegExpMode: PropTypes.bool.isRequired,
}

SearchWidget.defaultProps = {
  searchSuggestions: [],
}

function SearchToolBar({
  searchSuggestions,
  searchFields,
  searchContext,
  setSearchContext,
  regExpMode,
  enableRegExpMode,
  setRegExpMode,
  andGate,
  setAndGate,
}) {
  const [editContext, setEditContext] = useImmer(searchContext)

  const errors = useMemo(
    () => map(editContext, ({ value }) => isError(attempt(RegExp, value))),
    [editContext],
  )

  useEffect(() => {
    setEditContext(() => searchContext)
  }, [setEditContext, searchContext])

  const onFilter = useCallback(() => {
    setSearchContext(editContext)
  }, [editContext, setSearchContext])

  const onReset = useCallback(() => {
    setSearchContext([createSearchContext()])
  }, [setSearchContext])

  const onAddEditContext = useCallback(() => {
    setEditContext(() => [...editContext, createSearchContext()])
  }, [editContext, setEditContext])

  const [suggestions, setSuggestions] = useImmer(null)

  const completeMethod = useCallback(
    ({ query }) => {
      const trimQuery = query.trim()
      if (isEmpty(trimQuery)) {
        return
      }
      setSuggestions(() =>
        searchSuggestions.filter(name =>
          RegExp(escapeRegExp(trimQuery), 'ig').test(name),
        ),
      )
    },
    [searchSuggestions, setSuggestions],
  )

  return (
    <Flex data-cy="search-widget">
      <Box>
        <Flex className="p-inputgroup" height="35px" margin="5px" mx="0">
          <Button
            tooltip="過濾"
            onClick={onFilter}
            disabled={
              isEmpty(editContext.filter(({ value }) => !isEmpty(value))) ||
              (regExpMode && includes(errors, true))
            }
            icon="pi pi-filter"
            className="p-button-success"
          />
          <Button
            tooltip="重置搜尋"
            onClick={onReset}
            icon="pi pi-refresh"
            className="p-button-warning"
          />
          <Button
            tooltip="增加條件"
            icon="pi pi-plus"
            className="p-button-primary"
            onClick={onAddEditContext}
          />
          {enableRegExpMode && (
            <ButtonLike
              data-tip={`Regex 模式 (${regExpMode ? 'on' : 'off'})`}
              m={0}
              onClick={() => {
                setRegExpMode(!regExpMode)
              }}
              disabled={!regExpMode && includes(errors, true)}
              width="36px"
            >
              <VscRegex
                style={{
                  background: regExpMode
                    ? theme.colors.danger
                    : theme.colors.veryLightGrey,
                  height: '100%',
                  width: '100%',
                }}
              />
            </ButtonLike>
          )}

          <Button
            label={andGate ? '交集' : '聯集'}
            className="p-button-success"
            onClick={() => {
              setAndGate(!andGate)
            }}
          />
        </Flex>
      </Box>
      <Flex flexWrap="wrap">
        {editContext.map(({ field, value }, index) => (
          <Box data-cy={`search-input-${index}`} margin="5px" key={index}>
            <Flex alignItems="center">
              {!isEmpty(searchFields) && (
                <FieldDropdown
                  value={field}
                  onChange={({ value }) => {
                    setEditContext(draft => {
                      draft[index].field = value
                    })
                  }}
                  options={searchFields}
                  filter
                  showClear
                />
              )}
              <Box data-cy="search-input-target">
                <SearchInput
                  placeholder="搜尋..."
                  suggestions={suggestions}
                  completeMethod={completeMethod}
                  onChange={({ value }) =>
                    setEditContext(draft => {
                      draft[index].value = value
                    })
                  }
                  onKeyUp={({ key }) => {
                    if (regExpMode && includes(errors, true)) return
                    if (key === 'Enter') onFilter()
                  }}
                  value={value}
                  error={errors[index] && regExpMode}
                />
              </Box>
              <DeleteButton
                tooltip="刪除"
                icon="pi pi-minus"
                className="p-button-danger"
                disabled={editContext.length === 1}
                style={{
                  border: '1px solid rgba(0, 0, 0, 0.38)',
                  borderLeft: 'unset',
                }}
                onClick={() => {
                  setEditContext(draft => {
                    draft.splice(index, 1)
                  })
                }}
              />
            </Flex>
          </Box>
        ))}
      </Flex>
    </Flex>
  )
}

SearchToolBar.propTypes = {
  searchSuggestions: PropTypes.arrayOf(PropTypes.string),
  searchFields: PropTypes.arrayOf(PropTypes.object),
  searchContext: PropTypes.arrayOf(PropTypes.object),
  setSearchContext: PropTypes.func.isRequired,
  regExpMode: PropTypes.bool.isRequired,
  enableRegExpMode: PropTypes.bool.isRequired,
  setRegExpMode: PropTypes.func.isRequired,
  andGate: PropTypes.bool.isRequired,
  setAndGate: PropTypes.func.isRequired,
}
