import { useMemo, useState, useEffect } from 'react'
import {
  isNil,
  map,
  values,
  xor,
  find,
  filter,
  compact,
  get,
  toUpper,
} from 'lodash'
import { useQuery } from '@apollo/client'

import { TicketState } from '@common'
import { cceClient } from '@gql'
import { useCardGroup } from '@schema'

import { backendDocType, namespace } from './config'

import { CRUDO } from '../TicketHelper'

import { _queryTickets, _queryCardGroup } from './ticket.gql'

export function useScopeName(scope_id) {
  const { loading, data } = useQuery(_queryCardGroup, {
    client: cceClient,
    variables: {
      doc_ids: [scope_id],
    },
  })

  return useMemo(
    () =>
      loading || isNil(data)
        ? scope_id
        : get(data, 'queryCardGroup.0.card_name'),
    [loading, data, scope_id],
  )
}

export function useTicketScopeMap(currentType) {
  const skip = useMemo(() => currentType !== backendDocType, [currentType])
  const [cardGroups] = useCardGroup({
    skip,
  })

  const scopeMap = useMemo(() => {
    if (!cardGroups) return null
    return cardGroups.remap('_id', v => ({
      ...v,
      provider_id: v.card_provider_id,
      provider_name: v.card_provider_name,
      ticket_name: `${v.card_provider_name}.${v.card_name}`,
    }))
  }, [cardGroups])

  return scopeMap
}

export function useCandidator(currentType, tickets, scopeTargetMap) {
  const [manuallyCreate, setManuallyCreate] = useState(null)
  useEffect(() => {
    async function downLoadCardTicket(candidators) {
      const {
        data: { queryTickets },
      } = await cceClient.query({
        query: _queryTickets,
        variables: {
          filter: {
            scope_type: 'Card',
          },
          namespace: toUpper('card'),
        },
      })
      setManuallyCreate(
        // Allow create a reward ticket for a cardgroup which has card
        // been created with it.
        filter(candidators, ({ card_provider_id }) => {
          const ticket = find(queryTickets, { scope_id: card_provider_id })
          return (
            !isNil(ticket) &&
            get(ticket, 'meta.assignee_meta.0.own_docs', 0) > 0
          )
        }),
      )
    }

    if (
      isNil(tickets) ||
      isNil(scopeTargetMap) ||
      currentType !== backendDocType
    )
      return

    const candidators = map(values(scopeTargetMap), group => ({
      ...group,
      name: `${group.provider_name} - ${group.card_name}`,
      scope_id: group._id,
      // node_id for list it in SingleSelect.
      id: group._id,
    }))
    downLoadCardTicket(candidators)
  }, [currentType, tickets, scopeTargetMap])

  const autoCreate = useMemo(() => {
    if (isNil(tickets) || isNil(scopeTargetMap)) return []
    return compact(
      map(
        xor(map(tickets, 'scope_id'), map(values(scopeTargetMap), '_id')),
        scope_id => find(manuallyCreate, { scope_id }),
      ),
    )
  }, [tickets, manuallyCreate, scopeTargetMap])

  return useMemo(
    () => ({
      isLoading: isNil(manuallyCreate),
      manuallyCreate,
      autoCreate,
    }),
    [manuallyCreate, autoCreate],
  )
}

export const ticketProfile = {
  ticketType: backendDocType,
  namespace,
  states: [
    {
      main_state: TicketState.CREATE,
      assignee_quota: 2,
      assignee_as_editor: true,
      crud: CRUDO.RW_NO_OVERWRITE,
    },
    {
      main_state: TicketState.APPROXIMATE,
      assignee_quota: 2,
      assignee_as_editor: true,
      crud: CRUDO.RW_NO_DEL_OVERWRITE,
      removeable: true,
    },
    {
      main_state: TicketState.MERGE,
      assignee_quota: 1,
      assignee_as_editor: false,
      crud: CRUDO.FULLY_ACCESS,
    },
    {
      main_state: TicketState.FINISH,
      assignee_quota: 0,
      assignee_as_editor: false,
      crud: CRUDO.READ_ONLY,
    },
  ],
  autoCreate: false,
  enableVersion: true,
  filterByCurrentUser: true,
}
