import { useMemo, useContext } from 'react'
import { head, isEmpty, filter, partial, compact, get, find } from 'lodash'
import moment from 'moment-timezone'

import { getDataEditors } from '@common'
import { useFetchReferencedKG } from '@schema'
import { cceClient } from '@gql'
import { dataAnalysisService } from '@remote'

import { ProductContext } from './context'
import { productSchema } from './schema'

import { useConvertIdToName } from '../Common'
import { useMutateTicket } from '../TicketHelper'
import { useUploadDocWithHistory } from '../Uploader'

import { _uploadProduct } from './common.gql'

function composeProductChildTicketName(
  { title, sub_title, code, company_id, launch_date },
  companies,
) {
  return compact([
    title,
    sub_title,
    code,
    get(find(companies, { node_id: company_id }), 'name'),
    isEmpty(launch_date)
      ? null
      : `${moment(launch_date)
          .tz('Asia/Taipei')
          .format('YYYY-MM-DD')}`,
  ]).join('.')
}

export function useTranspile(docs, selectedFields) {
  const companies = productSchema.company_id.useNodes()
  const contracts = productSchema.contract_ids.useNodes(head(docs) || {})
  const nodes = useFetchReferencedKG(docs, productSchema, selectedFields, true)
  const referencedList = useMemo(() => [contracts, companies, nodes], [
    contracts,
    companies,
    nodes,
  ])
  return useConvertIdToName(docs, referencedList, selectedFields, productSchema)
}

export function useUploadProductOfTicket(ticket) {
  const { createOrRenameTicket } = useMutateTicket()
  const { companies } = useContext(ProductContext)
  const upload = useUploadDocWithHistory(productSchema, async products => {
    const {
      data: { uploadProduct },
    } = await cceClient.mutate({
      mutation: _uploadProduct,
      variables: {
        products,
      },
    })

    const mainEditor = head(getDataEditors(ticket))
    const productOfMainEditor = filter(uploadProduct, {
      uploader: mainEditor,
    })
    if (!isEmpty(productOfMainEditor)) {
      await createOrRenameTicket(productOfMainEditor, 'Plan', product =>
        composeProductChildTicketName(product, companies),
      )
      await createOrRenameTicket(productOfMainEditor, 'FeeTable', product =>
        composeProductChildTicketName(product, companies),
      )
    }

    return uploadProduct
  })

  return upload
}

export const checkProduct = partial(dataAnalysisService, 'check_product')
