import { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { InputTextarea } from 'primereact/inputtextarea'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import { Button } from 'primereact/button'

import { isEmpty } from 'lodash'
import { Box, Flex } from '@changingai/react-editor-common-component'
import { ProjectContext } from '@common'

export function MemoPanel({ doc, memoContext, updateMemo }) {
  const [editMode, setEditMode] = useState(false)
  const { enableMarkdown } = useContext(ProjectContext)

  return (
    <Box width="100%" position="relative" height="100%" py="2">
      {(editMode || !enableMarkdown) && (
        <>
          <InputTextarea
            value={memoContext}
            style={{
              width: '100%',
              height: '100%',
              minHeight: '150px',
              border: 'solid 3px BurlyWood',
              backgroundColor: 'AntiqueWhite',
            }}
            onChange={({ target: { value } }) => updateMemo(doc._id, value)}
            onBlur={() => setEditMode(false)}
            autoResize={true}
            maxLength="500"
            disabled={memoContext === null}
          />
          {enableMarkdown && (
            <Flex
              position="absolute"
              right="0"
              bottom="0"
              width="50px"
              m="3"
              justifyContent="flex-end"
            >
              <Button
                icon="pi pi-check"
                className="p-button-rounded"
                onClick={() => {
                  setEditMode(false)
                }}
              />
            </Flex>
          )}
        </>
      )}
      {!editMode && enableMarkdown && (
        <Box
          color={isEmpty(memoContext) ? 'blacks.2' : 'black'}
          width="100%"
          bg="AntiqueWhite"
          height="100%"
          p="1"
          border="solid 3px BurlyWood"
          onClick={() => setEditMode(true)}
        >
          <ReactMarkdown plugins={[gfm]}>
            {isEmpty(memoContext)
              ? `**Markdown** is supported.
* [ ] todo
* [x] done`
              : memoContext}
          </ReactMarkdown>
        </Box>
      )}
    </Box>
  )
}

MemoPanel.propTypes = {
  doc: PropTypes.object.isRequired,
  memoContext: PropTypes.string.isRequired,
  updateMemo: PropTypes.func.isRequired,
}
