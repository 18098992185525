import Joi from 'joi'

import { addSchema } from './SchemaStore'
import {
  RequiredString,
  shortString,
  emailString,
  RequiredMongoIdString,
} from './JoiSchema'

const crudSchema = {
  create: {
    schema: Joi.bool().required(),
    type: 'bool',
  },
  read: {
    schema: Joi.bool().required(),
    type: 'bool',
  },
  update: {
    schema: Joi.bool().required(),
    type: 'bool',
  },
  delete: {
    schema: Joi.bool().required(),
    type: 'bool',
  },
  overwrite: {
    schema: Joi.bool().required(),
    type: 'bool',
  },
}

export const ticketProfileStateSchema = {
  main_state: {
    schema: RequiredString.max(shortString),
    type: 'string',
  },
  assignee_quota: {
    schema: Joi.number().integer(),
    type: 'number',
  },
  assignee_as_editor: {
    schema: Joi.bool().required(),
    type: 'bool',
  },
  crud: {
    schema: Joi.object({
      create: Joi.bool().required(),
      read: Joi.bool().required(),
      update: Joi.bool().required(),
      delete: Joi.bool().required(),
      overwrite: Joi.bool().required(),
    }),
    type: 'objectOf(crudSchema)',
  },
}

export const ticketProfileSchema = {
  scope_id: {
    schema: RequiredString.max(shortString),
    type: 'string',
  },
  scope_type: {
    schema: RequiredString.max(shortString),
    type: 'string',
  },
  name: {
    schema: RequiredString.max(shortString),
    type: 'string',
  },
  version: {
    schema: RequiredString.max(shortString),
    type: 'string',
  },
  states: {
    schema: Joi.array().required(),
    type: 'arrayOf(ticketProfileStateSchema)',
  },
}

// Extend from this schema for the doc is grouped by ticket.
export const ticketDocSchema = {
  _id: {
    schema: Joi.string(),
    type: 'string',
    label: '文件代碼',
    skipCompare: true,
  },
  uploader: {
    schema: RequiredString.max(emailString),
    type: 'string',
    label: '上傳者',
    skipCompare: true,
  },
  ticket_id: {
    label: '票卷代碼',
    schema: RequiredMongoIdString,
    type: 'string',
  },
}

export const matchStateSchema = {
  matched_state: {
    schema: Joi.string(),
    type: 'string',
    label: '配對狀態',
    skipCompare: true,
    downloadOnly: true,
  },
  matched_target: {
    schema: Joi.string(),
    type: 'string',
    label: '配對文件',
    skipCompare: true,
    downloadOnly: true,
  },
}

addSchema({ matchStateSchema })
addSchema({ ticketProfileStateSchema })
addSchema({ ticketProfileSchema })
addSchema({ ticketDocSchema })
addSchema({ crudSchema })
