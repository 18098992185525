import { useCallback, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { map, get, isEmpty, find, uniq, toUpper } from 'lodash'
import { useQuery } from '@apollo/client'

import { TicketState, namespaces } from '@common'
import { cceClient } from '@gql'
import { Box, Text } from '@changingai/react-editor-common-component'

import './summary'
import { useTranspile } from './common'
import { docPrivilege } from './config'
import { usePlanContext, PlanContext } from './context'
import { PlanEditDialog } from './editor'
import { planSchema } from './schema'

import { useFetchDocsAndTickets } from '../ComposedView'
import OwnedDocSummaryModel from '../OwnedDocSummaryModel'

import { _queryPlanTicketIds, _queryPlan, _queryTickets } from './composed.gql'

const fullAccessStates = [TicketState.MERGE, TicketState.FINISH]
function useController(ids) {
  const result = useFetchDocsAndTickets(
    _queryPlan,
    fullAccessStates,
    namespaces.insurance.value,
    PlanEditDialog,
    ids,
  )

  const generateCaption = useCallback(
    (index, doc) => {
      const tickets = get(result, 'tickets')
      const ticket = find(tickets.queryTickets, { _id: doc.ticket_id })
      return (
        <Box fontSize="h1" my="1" mx="2">
          <Text>{`${index + 1}. 代碼：${doc._id}`}</Text>
          <Text fontSize="h2" color="yellow">{`（產品名稱：${get(
            ticket,
            'name',
          )}）`}</Text>
        </Box>
      )
    },
    [result],
  )

  const context = useContext(PlanContext)
  return {
    ...result,
    generateCaption,
    isReady: result.isReady && context.isReady,
  }
}

const defaultSummary = {
  selected: ['name', 'benefits'],
  filters: {},
}

const OwnedPlanListView = props => {
  const { data: ticketIds } = useQuery(_queryPlanTicketIds, {
    client: cceClient,
    variables: {
      doc_ids: props.ids,
    },
    skip: isEmpty(props.ids),
  })

  const { loading, data: tickets } = useQuery(_queryTickets, {
    client: cceClient,
    variables: {
      filter: {
        ticket_ids: uniq(map(get(ticketIds, 'queryPlan'), 'ticket_id')),
      },
      namespace: toUpper('insurance'),
    },
    skip: isEmpty(ticketIds),
  })

  const scopeIds = useMemo(
    () =>
      loading || !tickets
        ? null
        : map(get(tickets, 'queryTickets'), 'scope_id'),
    [loading, tickets],
  )
  const context = usePlanContext(scopeIds)
  return (
    <PlanContext.Provider value={context}>
      <OwnedDocSummaryModel
        {...props}
        useController={useController}
        useTranspile={useTranspile}
        defaultSummary={defaultSummary}
        requiredPrivilege={[docPrivilege]}
        currentSchema={planSchema}
        showIdInputIcon={true}
      />
    </PlanContext.Provider>
  )
}

OwnedPlanListView.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default OwnedPlanListView
