import { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import 'styled-components/macro'
import css from '@styled-system/css'
import { Dialog } from 'primereact/dialog'
import { ListBox } from 'primereact/listbox'
import { IoIosCloseCircle } from 'react-icons/io'
import { TiWarningOutline } from 'react-icons/ti'
import { get, isEmpty, compact } from 'lodash'
import { Box, Flex } from '@changingai/react-editor-common-component'

import { ValidationCategory } from '@common'
import { ActionButtons } from '@widget'
import theme from '@theme'

export function ValidationDialog({ results, onHide, onUpload, docs }) {
  const [uploading, setUploading] = useState(false)
  const canUpload = useMemo(
    () => isEmpty(get(results, ValidationCategory.Error)),
    [results],
  )
  return (
    <Dialog
      header="Document validation"
      appendTo={document.body}
      visible={true}
      style={{ width: '800px' }}
      contentStyle={{ position: 'relative' }}
      modal={true}
      closable={false}
      onHide={() => {}}
    >
      <Flex
        width="100%"
        flexWrap="wrap"
        css={css({
          '& h3': {
            display: 'inline',
          },
          '& p': {
            display: 'inline',
          },
        })}
      >
        <ListBox
          style={{ flex: '1 0', height: '505px', width: '100%' }}
          listStyle={{ height: '500px' }}
          options={[
            ...get(results, ValidationCategory.Warning, []),
            ...get(results, ValidationCategory.Error, []),
          ]}
          itemTemplate={({ category, label }) => (
            <Flex
              justifyContent="space-between"
              borderBottom="1px dashed"
              borderColor="gray"
              alignItems="center"
            >
              <Box flex="1 1">{label}</Box>
              {category === ValidationCategory.Error && (
                <IoIosCloseCircle
                  style={{
                    flex: '0 0 auto',
                    color: 'red',
                    width: `${get(theme, 'sizes.4')}px`,
                    height: `${get(theme, 'sizes.4')}px`,
                  }}
                />
              )}
              {category === ValidationCategory.Warning && (
                <TiWarningOutline
                  style={{
                    flex: '0 0 auto',
                    color: 'orange',
                    width: `${get(theme, 'sizes.4')}px`,
                    height: `${get(theme, 'sizes.4')}px`,
                  }}
                />
              )}
            </Flex>
          )}
        />
        <ActionButtons
          buttons={{
            right: compact([
              canUpload && {
                label: 'Upload',
                action: 'Upload',
                className: 'p-button-warning',
                icon: uploading ? 'pi pi-spin pi-spinner' : 'pi pi-check',
              },
              {
                label: 'Close',
                action: 'Close',
                className: 'p-button-secondary',
                icon: 'pi pi-times',
              },
            ]),
          }}
          onClicked={async action => {
            if (action === 'Upload') {
              setUploading(true)
              await onUpload(docs)
              setUploading(false)
            }
            onHide()
          }}
        />
      </Flex>
    </Dialog>
  )
}

ValidationDialog.propTypes = {
  results: PropTypes.shape({
    Pass: PropTypes.array,
    Error: PropTypes.array,
    Warning: PropTypes.array,
  }).isRequired,
  onHide: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  docs: PropTypes.array.isRequired,
}
