import { useCallback } from 'react'
import { map, flatMap, startsWith } from 'lodash'
import { getPrefix } from '@gql'

const servicePath = '/data-analysis-service/'
const getServiceUrl = endpoint => {
  if (window.location.protocol !== 'http:')
    return window.location.origin + getPrefix() + servicePath + endpoint

  return `${process.env.REACT_APP_DATA_ANALYSIS_URL}${
    startsWith(process.env.REACT_APP_DATA_ANALYSIS_URL, 'http:')
      ? '/'
      : servicePath
  }${endpoint}`
}

const options = content => ({
  method: 'POST',
  mode: 'cors',
  cache: 'no-cache',
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(content),
})

export function dataAnalysisService(endpoint, context) {
  return fetch(getServiceUrl(endpoint), options(context))
}

export function useDocCheck(checkAPI) {
  const onRuleCheck = useCallback(
    async docs => {
      try {
        const results = await Promise.all(map(docs, checkAPI))
        return map(results, result =>
          flatMap(result, ({ fieldname, result }) =>
            map(result, ([category, message]) => ({
              category,
              label: `${fieldname}: ${message}`,
              pass: false,
            })),
          ),
        )
      } catch (e) {
        return [
          [
            {
              category: 'Error',
              label: `data-analysis-service 錯誤: ${e}`,
              pass: false,
            },
          ],
        ]
      }
    },
    [checkAPI],
  )

  return onRuleCheck
}
