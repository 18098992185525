import { useMemo, useEffect, useState, useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  get,
  map,
  keys,
  range,
  partial,
  flatten,
  isEqual,
  isEmpty,
  includes,
  toString,
  toNumber,
  join,
} from 'lodash'
import uuid from 'react-uuid'
import { useImmer } from 'use-immer'
import { Dialog } from 'primereact/dialog'
import { Tag } from 'primereact/tag'
import { Button } from 'primereact/button'
import { MdDeleteForever } from 'react-icons/md'

import { Flex, Box } from '@changingai/react-editor-common-component'
import {
  Label,
  useInit,
  useError,
  ButtonLike,
  TableInput,
  inputFactory,
  create2dArray,
  ActionButtons,
  createSeparator,
  useRenderConfig,
} from '@widget'
import { useDialog, ProjectContext } from '@common'

import {
  calculateFactorSchema,
  calculateFactorsSchema,
  calculateRuleSchema,
  coefCellSchema,
  COEF_LENGTH,
  CalculateFactorPropType,
  CalculateRulePropType,
} from './schema'

export function CalculateRule({
  context,
  onDirty,
  onUpdateContext,
  onError,
  currentPath,
}) {
  const renderer = useRenderConfig({
    onError,
    onUpdateContext,
    onDirty,
    editContext: context,
    currentSchema: calculateRuleSchema,
    currentPath,
  })

  const config = useMemo(
    () => [
      inputFactory(calculateRuleSchema, 'rule_id', {
        inputAttributes: {
          maxHeight: '600px',
          itemPadding: 'small',
        },
      }),
      ...map(keys(calculateFactorsSchema), fieldname => ({
        fieldname,
        widget: 'CustomizedInput',
        RenderComp: CalculateFactor,
      })),
      createSeparator({}),
      inputFactory(calculateRuleSchema, 'detail', {
        inputAttributes: {
          multilines: true,
        },
      }),
    ],
    [],
  )

  return (
    <Flex width="100%" flexWrap="wrap">
      {map(config, renderer)}
    </Flex>
  )
}

CalculateRule.propTypes = {
  context: CalculateRulePropType.isRequired,
  onChange: PropTypes.func.isRequired,
  onDirty: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onUpdateContext: PropTypes.func.isRequired,
  onValid: PropTypes.func.isRequired,
  currentPath: PropTypes.arrayOf(PropTypes.string).isRequired,
}

function CoefEditDialog({ values, header, startIndex, onChange, onHide }) {
  const [data, setData] = useState(
    isEmpty(values)
      ? create2dArray(COEF_LENGTH, 1, null)
      : map(values, value => [toString(value)]),
  )
  const canSave = useMemo(() => !includes(flatten(data), null), [data])
  const onSave = useCallback(() => onChange(flatten(data)), [data, onChange])

  return (
    <Dialog
      header={header}
      appendTo={document.body}
      visible={true}
      style={{ width: '800px' }}
      modal={true}
      onHide={onHide}
    >
      <Box width="100%" height="50vh" my="2">
        <TableInput
          schema={coefCellSchema}
          format={value => {
            const formattedValue = value.replace(/[^0-9.]/g, '')
            return formattedValue === ''
              ? null
              : toString(toNumber(formattedValue))
          }}
          header={[header]}
          columnHeader={map(
            range(startIndex, startIndex + COEF_LENGTH),
            toString,
          )}
          values={data}
          headerEditable={false}
          fixedDimension
          onChange={({ values }) => setData(values)}
        />
      </Box>
      <ActionButtons
        buttons={{
          right: [
            {
              label: 'Save',
              action: 'Save',
              className: 'p-button-warning',
              icon: 'pi pi-check',
              enable: canSave,
            },
            {
              label: 'Close',
              action: 'Close',
              className: 'p-button-secondary',
              icon: 'pi pi-times',
            },
          ],
        }}
        onClicked={action => {
          if (action === 'Save') {
            onSave()
          }
          onHide()
        }}
      />
    </Dialog>
  )
}

CoefEditDialog.propTypes = {
  values: PropTypes.arrayOf(PropTypes.number),
  header: PropTypes.string.isRequired,
  startIndex: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
}

const CoefText = styled(ButtonLike)`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  background: white;
  padding: 5px;
  border-radius: 4px;
  border: 1px #ccc solid;
`

function CoefField({
  context,
  onChange,
  onDirty,
  onValid,
  fieldname,
  startIndex,
  currentPath,
}) {
  const { getSuggestions } = useContext(ProjectContext)
  const suggestions = getSuggestions(currentPath)
  const [, compare] = useInit(get(context, fieldname))
  const [id] = useState(uuid())
  const [showDialog, renderDialog] = useDialog(CoefEditDialog)
  const header = useMemo(
    () => get(calculateFactorSchema, `${fieldname}.label`),
    [fieldname],
  )
  const values = useMemo(() => get(context, fieldname), [context, fieldname])
  const error = useError(onValid, id, fieldname, values)
  useEffect(() => {
    onDirty(id, compare(values))
    onValid(fieldname, values, {
      key: id,
      currentSchema: calculateFactorSchema,
    })
    return () => {
      onValid(fieldname, null, {
        clean: true,
        key: id,
        currentSchema: calculateFactorSchema,
      })
    }
  }, [id, values, fieldname, onValid, onDirty, compare])

  const disabled = suggestedValue => isEqual(values, suggestedValue)

  return (
    <Box width="100%" my="2">
      <Flex width="100%" alignItems="center">
        <Label width="200px" changed={compare(values)} error={error}>
          {header}
        </Label>
        {renderDialog({
          onChange: values => onChange(fieldname, values),
        })}
        <CoefText
          onClick={() => showDialog({ values, header, onChange, startIndex })}
        >
          {isEmpty(get(context, fieldname)) ? (
            <Tag value="空" severity="info" />
          ) : (
            join(get(context, fieldname), ', ')
          )}
        </CoefText>
        <ButtonLike onClick={() => onChange(fieldname, [])}>
          <MdDeleteForever style={{ color: 'red' }} />
        </ButtonLike>
      </Flex>
      <Flex justifyContent="flex-end" width="calc(100% - 45px)">
        {map(suggestions, ({ label, value }) => {
          const isInvalidSuggestion = !!get(
            get(calculateFactorSchema, `${fieldname}.schema`).validate(value),
            'error',
          )
          return (
            <Button
              key={label}
              label={label}
              style={{
                marginRight: '5px',
                height: '20px',
                ...(isInvalidSuggestion
                  ? { color: 'red', backgroundColor: 'gray' }
                  : {}),
              }}
              className={
                disabled(value) ? 'p-button-secondary' : 'p-button-success'
              }
              onClick={() => {
                if (!isInvalidSuggestion) onChange(fieldname, value)
              }}
              disabled={disabled(value)}
            />
          )
        })}
      </Flex>
    </Box>
  )
}

CoefField.propTypes = {
  context: CalculateFactorPropType.isRequired,
  onChange: PropTypes.func.isRequired,
  onDirty: PropTypes.func.isRequired,
  onValid: PropTypes.func.isRequired,
  fieldname: PropTypes.string.isRequired,
  startIndex: PropTypes.number.isRequired,
  currentPath: PropTypes.array.isRequired,
}

function CalculateFactor({
  context,
  onDirty,
  onUpdateContext,
  onError,
  fieldname,
  currentPath,
}) {
  const [editContext, setEditContext] = useImmer(context?.[fieldname])

  const renderer = useRenderConfig({
    onError,
    onUpdateContext: setEditContext,
    onDirty,
    editContext,
    currentSchema: calculateFactorSchema,
    currentPath,
  })

  useEffect(() => {
    if (!isEqual(context?.[fieldname], editContext))
      onUpdateContext(draft => {
        draft[fieldname] = editContext
      })
  }, [fieldname, editContext, context, onUpdateContext])

  const PolicyYearWrap = useCallback(
    props => (
      <CoefField
        {...props}
        startIndex={1}
        currentPath={[...currentPath, props.fieldname]}
      />
    ),
    [currentPath],
  )
  const AttainedAgeWrap = useCallback(
    props => (
      <CoefField
        {...props}
        startIndex={0}
        currentPath={[...currentPath, props.fieldname]}
      />
    ),
    [currentPath],
  )

  const configs = useMemo(
    () => [
      ...map(
        ['base', 'installment', 'expectation'],
        partial(inputFactory, calculateFactorSchema),
      ),
      {
        fieldname: 'policy_year',
        widget: 'CustomizedInput',
        RenderComp: PolicyYearWrap,
      },
      {
        fieldname: 'attained_age',
        widget: 'CustomizedInput',
        RenderComp: AttainedAgeWrap,
      },
    ],
    [PolicyYearWrap, AttainedAgeWrap],
  )

  return (
    <Flex width="100%" flexWrap="wrap">
      <Box bg="blacks.2" textAlign="center" width="100%">
        {`${get(calculateRuleSchema, `${fieldname}.label`)}`}
      </Box>
      {map(configs, renderer)}
    </Flex>
  )
}

CalculateFactor.propTypes = {
  fieldname: PropTypes.string.isRequired,
  context: CalculateFactorPropType.isRequired,
  onDirty: PropTypes.func.isRequired,
  onUpdateContext: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  currentPath: PropTypes.arrayOf(PropTypes.string).isRequired,
}
