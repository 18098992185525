import { useCallback, useContext, useMemo } from 'react'
import {
  get,
  uniq,
  map,
  isEmpty,
  isNil,
  trimStart,
  find,
  toUpper,
} from 'lodash'
import { useQuery } from '@apollo/client'

import { getQueryTagName, ProjectContext, useDialog } from '@common'
import { cceClient } from '@gql'

import { useCacheDocs } from './Common'

import { _queryTickets } from './ComposedView.gql'

export function useFetchDocsAndTickets(
  queryDoc,
  fullAccessStates,
  namespace,
  Editor,
  ids,
) {
  const { loading: loadingDocs, data } = useQuery(queryDoc, {
    client: cceClient,
    variables: {
      doc_ids: ids,
    },
    skip: isEmpty(ids),
  })

  const sourceDocs = useMemo(() => {
    if (loadingDocs || !data) return null
    const operationName = trimStart(getQueryTagName(queryDoc), '_')
    return get(data, operationName)
  }, [queryDoc, loadingDocs, data])
  const ticketIds = useMemo(() => uniq(map(sourceDocs, 'ticket_id')), [
    sourceDocs,
  ])
  const { data: tickets } = useQuery(_queryTickets, {
    client: cceClient,
    variables: {
      filter: {
        ticket_ids: ticketIds,
      },
      namespace: toUpper(namespace),
    },
    skip: isEmpty(ticketIds),
  })

  const { email } = useContext(ProjectContext)
  const { docs } = useCacheDocs(email, true, null, sourceDocs, fullAccessStates)
  const [showDialog, renderDialog] = useDialog(Editor)
  const showDoc = useCallback(
    (doc, crud) => {
      showDialog({
        doc,
        ticket: find(tickets.queryTickets, { _id: doc.ticket_id }),
        crud,
      })
    },
    [showDialog, tickets],
  )

  return {
    isReady: isEmpty(ids) || (!isNil(sourceDocs) && !isNil(tickets)),
    tickets,
    docs,
    showDoc,
    updated: [],
    renderDoc: renderDialog,
    showMemo: false,
  }
}
