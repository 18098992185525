import PropTypes from 'prop-types'
import { partial } from 'lodash'

import { namespace, schema } from './config'
import { ProductEditDialog } from './editor'
import { productSchema } from './schema'
import { useProductContext, ProductContext } from './context'
import { getTicketDocPath } from '../Common'
import { useFetchDocAndTicket } from '../DocHelper'
import { referenceDoc } from '../Reference'
import { useRestoreDoc, RestoreDialog } from '../Restore'
import { EditorLoader } from '../SchemaBaseEditorLoader'

import { _queryProduct } from './loader.gql'

const getPath = partial(getTicketDocPath, namespace, schema)

export const queryManifest = {
  gql: _queryProduct,
  name: 'queryProduct',
  getPath,
}

export const generateReferenceDoc = partial(
  referenceDoc,
  'title',
  getPath,
  schema,
  namespace,
)

export function ProductRestorer({ doc }) {
  return (
    <RestoreDialog
      DialogComp={ProductEditDialog}
      useRestoreDoc={partial(useRestoreDoc, doc, namespace)}
    />
  )
}

ProductRestorer.propTypes = {
  doc: PropTypes.object.isRequired,
}

export function ProductEditorLoader({ docId }) {
  const context = useProductContext()

  return (
    <ProductContext.Provider value={context}>
      <EditorLoader
        DialogComp={ProductEditDialog}
        useFetchDocAndTicket={partial(
          useFetchDocAndTicket,
          _queryProduct,
          'queryProduct',
          namespace,
          docId,
        )}
        currentSchema={productSchema}
      />
    </ProductContext.Provider>
  )
}

ProductEditorLoader.propTypes = {
  docId: PropTypes.string.isRequired,
}

export default ProductEditorLoader
