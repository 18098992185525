import { useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  groupBy,
  isNil,
  get,
  map,
  size,
  mapValues,
  sum,
  isEqual,
  values,
  uniqWith,
  sortBy,
  slice,
  isEmpty,
  pickBy,
  negate,
  findIndex,
  flow,
  filter,
  compact,
} from 'lodash'
import { Chart } from 'primereact/chart'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { Flex, Box } from '@changingai/react-editor-common-component'

import { ButtonLike } from '@widget'
import { exportDoc, csvBlob, computeType } from '@common'

import { chartBGColors, stackedOptions } from './common'

function computeFieldValueChangeScore(
  editingHitoryOfDocs,
  fields,
  assigneeIndex,
  currentSchema,
) {
  const filterDocsCreatedInMergeState = docs => {
    const index = findIndex(
      docs,
      ({ actionContent: { ticket_state } }) => ticket_state === 'MERGE',
    )
    // index === 0 means this doc was created in MERGE state. skip it
    // If index is not zero, we keep only one record before MERGE state.
    return index === 0 ? [] : slice(docs, index - 1)
  }

  const filterDocsByAssigneeIndex = docs => {
    const assignee_index = get(docs, '0.actionContent.assignee_index')
    // Filter out old records without assignee_index
    if (isNil(assignee_index)) return []
    return assigneeIndex === -1 || assignee_index === assigneeIndex ? docs : []
  }

  const filterChain = [filterDocsCreatedInMergeState, filterDocsByAssigneeIndex]
  const filteredDocs = pickBy(
    mapValues(editingHitoryOfDocs, docs => flow(filterChain)(docs)),
    negate(isEmpty),
  )

  const changeScores = mapValues(filteredDocs, docs => {
    const fieldUniqValues = map(
      map(fields, field => [field, map(docs, doc => doc.actionContent[field])]),
      ([field, fieldValues]) =>
        computeType(currentSchema?.[field]?.type).isTypedArray
          ? uniqWith(map(fieldValues, sortBy), isEqual)
          : uniqWith(fieldValues, isEqual),
    )

    return {
      uploader: get(docs, '0.actor'),
      changeTimes: map(fieldUniqValues, values => size(values) - 1),
      id: get(docs, '0.target_id'),
      changed: map(fieldUniqValues, values => (size(values) > 1 ? 1 : 0)),
    }
  })

  return values(
    mapValues(groupBy(values(changeScores), 'uploader'), (scores, key) => ({
      uploader: key,
      fieldScore: map(fields, (_, index) =>
        sum(map(scores, ({ changeTimes }) => changeTimes[index])),
      ),
      fieldChanged: map(fields, (_, index) =>
        sum(map(scores, ({ changed }) => changed[index])),
      ),
      totalScore: sum(map(scores, ({ changeTimes }) => sum(changeTimes))),
      // docs that are changed in merge state only.
      changedDocCount: size(filter(scores, ({ changed }) => sum(changed) > 0)),
      // docs that are changed in all states. changedDocCount <= totalDocCount
      totalDocCount: size(scores),
      ids: map(fields, (_, index) =>
        compact(
          map(scores, ({ changed, id }) => (changed[index] > 0 ? id : null)),
        ),
      ),
    })),
  )
}

export function ChangeScoreChart({
  currentSchema,
  fields,
  editingHitoryOfDocs,
  assigneeIndex,
  title,
}) {
  const scoreOfUploader = useMemo(
    () =>
      isNil(fields)
        ? null
        : sortBy(
            map(
              computeFieldValueChangeScore(
                editingHitoryOfDocs,
                fields,
                assigneeIndex,
                currentSchema,
              ),
              ({
                uploader,
                fieldScore,
                fieldChanged,
                totalScore,
                totalDocCount,
                changedDocCount,
                ids,
              }) => ({
                uploader,
                fieldScore,
                fieldChanged,
                fieldErrorRate: map(fieldScore, score => score / totalDocCount),
                errorRate: totalScore / totalDocCount,
                totalDocCount,
                changedDocCount,
                ids,
              }),
            ),
            'errorRate',
          ),
    [editingHitoryOfDocs, fields, assigneeIndex, currentSchema],
  )

  const stackedData = useMemo(
    () =>
      isNil(scoreOfUploader)
        ? null
        : {
            labels: map(scoreOfUploader, ({ uploader }) =>
              uploader.substr(0, uploader.indexOf('@')),
            ),
            datasets: map(fields, (field, index) => ({
              type: 'bar',
              label: get(currentSchema, `${field}.label`),
              backgroundColor: chartBGColors[index],
              data: map(
                scoreOfUploader,
                ({ fieldErrorRate }) =>
                  Math.round(fieldErrorRate[index] * 10000) / 10000,
              ),
            })),
          },
    [scoreOfUploader, fields, currentSchema],
  )

  return (
    <Box width="80%">
      <Flex width="100%" justifyContent="center" fontSize="h1">
        {title}
      </Flex>
      <Flex width="100%" justifyContent="flex-end">
        <Button
          label="Export"
          className="p-button-primary"
          icon="pi pi-save"
          onClick={() => {
            exportDoc(
              'change_count_statistic.csv',
              csvBlob([
                [
                  '上傳者',
                  ...map(fields, field => get(currentSchema, `${field}.label`)),
                  '文件數量',
                ],
                ...map(scoreOfUploader, row => [
                  row.uploader,
                  ...row.fieldScore,
                  row.totalDocCount,
                ]),
              ]),
            )
          }}
        />
      </Flex>
      <Chart type="bar" data={stackedData} options={stackedOptions} />
      <DataTable
        value={scoreOfUploader}
        paginator={false}
        style={{ width: '100%', margin: '4px 0' }}
      >
        <Column
          header="上傳者"
          field="uploader"
          body={({ uploader }) => (
            <Box>{uploader.substr(0, uploader.indexOf('@'))}</Box>
          )}
        />
        {map(fields, (field, index) => (
          <Column
            key={field}
            sortable
            header={get(currentSchema, `${field}.label`)}
            body={({ fieldScore, fieldChanged, ids }) => (
              <Flex alignItems="flex-end">
                {fieldScore[index] > 0 && (
                  <ButtonLike
                    onClick={() => {
                      navigator.clipboard.writeText(ids[index])
                      toast.info('Copied', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 2000,
                      })
                    }}
                  >
                    {fieldScore[index]}
                  </ButtonLike>
                )}
                {fieldScore[index] === 0 && <Box>{fieldScore[index]}</Box>}
                {fieldScore[index] !== fieldChanged[index] && (
                  <Box
                    color="blue"
                    fontSize="small"
                  >{`(${fieldChanged[index]})`}</Box>
                )}
              </Flex>
            )}
          />
        ))}

        <Column
          sortable
          style={{ fontSize: 'large', fontWeight: 'bold' }}
          header="修改總量"
          field="changedDocCount"
        />
        <Column
          sortable
          style={{ fontSize: 'large', fontWeight: 'bold' }}
          header="文件總量"
          field="totalDocCount"
        />
      </DataTable>
      <Flex width="100%" justifyContent="flex-end">
        <Link
          to="/card/composedview/cardRewardSchema"
          target="_blank"
          rel="noopener noreferrer"
        >
          Launch Reward Viewer
        </Link>
      </Flex>
    </Box>
  )
}

ChangeScoreChart.propTypes = {
  editingHitoryOfDocs: PropTypes.object.isRequired,
  currentSchema: PropTypes.object.isRequired,
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  assigneeIndex: PropTypes.oneOf([0, 1, -1]).isRequired,
  title: PropTypes.string.isRequired,
}
