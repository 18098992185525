import { useMemo } from 'react'
import isNil from 'lodash/isNil'

import { inputFactory } from '@widget'
import { SchemaBaseEditor } from '../SchemaBaseEditor'
import { useFetchFullDoc } from '../DocHelper'

import { ninjaSchema, NinjaPropType } from './schema'
import { _queryNinja } from './editor.gql'

export function NinjaEditor(props) {
  const { editContext, setEditContext } = useFetchFullDoc(
    props.doc,
    ninjaSchema,
    _queryNinja,
  )
  const tabs = useMemo(
    () => [
      {
        caption: 'Primary',
        configs: [
          inputFactory(ninjaSchema, '_id', { disabled: true }),
          inputFactory(ninjaSchema, 'name'),
          inputFactory(ninjaSchema, 'skill'),
          inputFactory(ninjaSchema, 'height'),
          inputFactory(ninjaSchema, 'weight'),
          inputFactory(ninjaSchema, 'birth'),
          inputFactory(ninjaSchema, 'jinchuuriki'),
        ],
      },
    ],
    [],
  )

  return (
    <SchemaBaseEditor
      {...props}
      currentSchema={ninjaSchema}
      tabs={tabs}
      crud={{
        create: true,
        update: true,
        delete: true,
        read: true,
        overwrite: true,
      }}
      isReady={!isNil(editContext)}
      editContext={editContext}
      setEditContext={setEditContext}
    />
  )
}

NinjaEditor.propTypes = {
  doc: NinjaPropType.isRequired,
}
