import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import uuid from 'react-uuid'
import { isEmpty, size } from 'lodash'
import { toast } from 'react-toastify'
import { MdDeleteForever } from 'react-icons/md'
import { Flex, Box } from '@changingai/react-editor-common-component'

import { ToastifyLabel } from './ToastifyLabel'
import { ImportButton } from './ImportButton'
import { useInit, useError, useUploadFile } from './common'
import { StyledChips } from './StyledComps'
import { ButtonLike } from './button'
import { renderUrlChip } from './Chips'

export function FileUploader({
  fieldname,
  value,
  onChange,
  onDirty,
  label,
  onValid,
  mimetype,
  labelAttributes = { width: '150px' },
  width = '100%',
  directInput = false,
}) {
  const [multipleUrls] = useState(Array.isArray(value))
  const [id] = useState(uuid())
  const [, compare] = useInit(value)
  const [url, setUrl] = useState(value)
  const uploadBlob = useUploadFile()
  const error = useError(onValid, id, fieldname, value, true)

  useEffect(() => {
    setUrl(value)
    onDirty(id, compare(value))
  }, [value, onDirty, id, compare])

  return (
    <Flex width={width} alignItems="center">
      <ToastifyLabel
        label={label}
        fieldname={fieldname}
        changed={compare(url)}
        error={error}
        {...labelAttributes}
      />
      {directInput ? (
        <StyledChips
          value={url}
          itemTemplate={renderUrlChip}
          onChange={({ value }) => {
            if (size(value) > 1) return
            setUrl(value)
            onChange(fieldname, value)
            onDirty(id, compare(value))
          }}
        />
      ) : (
        <Box
          flex="1 1"
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          <a href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        </Box>
      )}
      <ImportButton
        accept={mimetype}
        onImport={async ({ target: { files } }) => {
          const file = files[0]
          try {
            const uploaded = await uploadBlob(id, file, file.type)
            // Currently, we only support keeping one url.
            // To keep more then one urls, this component need to be
            // able to support display and delete more then one url.
            const result = multipleUrls ? [uploaded] : uploaded
            setUrl(result)

            onChange(fieldname, result)
            onDirty(id, compare(result))
          } catch (e) {
            toast.error(`Import failed: ${e}`, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
            })
          }
        }}
      />
      <ButtonLike
        disabled={isEmpty(url)}
        size={6}
        scaleOnHover
        onClick={() => {
          const result = multipleUrls ? [] : null
          setUrl(result)
          onChange(fieldname, result)
          onDirty(id, compare(result))
        }}
      >
        <MdDeleteForever
          style={{
            color: 'red',
          }}
        />
      </ButtonLike>
    </Flex>
  )
}

FileUploader.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  fieldname: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDirty: PropTypes.func.isRequired,
  onValid: PropTypes.func.isRequired,
  mimetype: PropTypes.string.isRequired,
  labelAttributes: PropTypes.object,
  width: PropTypes.string,
  directInput: PropTypes.bool.isRequired,
}
