import PropTypes from 'prop-types'
import { partial } from 'lodash'

import { namespace, schema } from './config'
import { FeeTableEditDialog } from './editor'
import { feeTableSchema } from './schema'

import { getTicketDocPath } from '../Common'
import { useFetchDocAndTicket } from '../DocHelper'
import { referenceDoc } from '../Reference'
import { useRestoreDoc, RestoreDialog } from '../Restore'
import { EditorLoader } from '../SchemaBaseEditorLoader'

import { _queryFeeTable } from './loader.gql'

const getPath = partial(getTicketDocPath, namespace, schema)

export const queryManifest = {
  gql: _queryFeeTable,
  name: 'queryFeeTable',
  getPath,
}

export const generateReferenceDoc = partial(
  referenceDoc,
  'name',
  getPath,
  schema,
  namespace,
)

export function FeeTableRestorer({ doc }) {
  return (
    <RestoreDialog
      DialogComp={FeeTableEditDialog}
      useRestoreDoc={partial(useRestoreDoc, doc, namespace)}
    />
  )
}

FeeTableRestorer.propTypes = {
  doc: PropTypes.object.isRequired,
}

export function FeeTableEditorLoader({ docId }) {
  return (
    <EditorLoader
      DialogComp={FeeTableEditDialog}
      useFetchDocAndTicket={partial(
        useFetchDocAndTicket,
        _queryFeeTable,
        'queryFeeTable',
        namespace,
        docId,
      )}
      currentSchema={feeTableSchema}
    />
  )
}

FeeTableEditorLoader.propTypes = {
  docId: PropTypes.string.isRequired,
}

export default FeeTableEditorLoader
