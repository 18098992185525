import { useCallback, useContext, useMemo } from 'react'
import {
  isEmpty,
  get,
  has,
  mapValues,
  partial,
  map,
  size,
  difference,
  isNil,
} from 'lodash'
import { Box } from '@changingai/react-editor-common-component'

import { ProjectContext, computeType } from '@common'
import { getFieldLabel, toEditableValue, toStoreableValue } from '@schema'

import {
  SingleInputWithSuggestions,
  SingleInputWithUrl,
  SingleInputMergeWidget,
} from './SingleInput'
import { SingleCheck } from './SingleCheck'
import { SingleSelect, SingleSelectMergeWidget } from './SingleSelect'
import {
  MultipleNodeList,
  MultipleNodeListMergeWidget,
} from './MultipleNodeList'
import { FileUploader } from './FileUploader'
import {
  MultiCheckboxList,
  MultiCheckboxMergeWidget,
} from './MultiCheckboxList'
import { MultiInput, MultiInputMergeWidget } from './MultiInput'
import { ImageUploader } from './ImageUploader'
import { renderPlainTextChip } from './Chips'
import { TreeSelect, TreeSelectMergeWidget } from './TreeSelect'

function configToAttribute(config) {
  const defaultInputAttributes = {
    MultiCheckboxList: { width: '100%', height: '200px' },
    SingleInput: { width: '100%', multilines: false },
  }

  return {
    key: config.fieldname,
    // Remove width after all input widget use inputAttributes.width
    width: get(config, 'width', '100%'),
    editable: get(config, 'editable', true),
    disabled: get(config, 'disabled', false),
    filter: get(config, 'filter', false),
    labelAttributes: {
      width: '200px',
      ...get(config, 'labelAttributes', {}),
    },
    inputAttributes: {
      width: '100%',
      ...get(defaultInputAttributes, config.widget, {}),
      ...get(config, 'inputAttributes', {}),
    },
    hint: get(config, 'hint', null),
    suggestions: get(config, 'suggestions', []),
  }
}

function useOnChangeAndValid({ currentSchema, onError, onUpdate }) {
  const onValid = useCallback(
    (fieldname, value, options = {}) => {
      const {
        key = fieldname,
        clean = false,
        currentSchema: fieldSchema,
        context = {},
      } = options
      const schema = fieldSchema || currentSchema
      if (!(fieldname in schema)) {
        // eslint-disable-next-line no-console
        console.log(`missed ${fieldname}`)
        return true
      }

      if (has(options, 'force')) {
        onError(key, options.force)
        return !options.force
      }

      if (clean) {
        onError(key, false)
        return true
      }

      const { error } = schema[fieldname].schema.validate(value, { context })
      onError(key, !!error, get(error, 'message', ''))
      return !error
    },
    [onError, currentSchema],
  )
  const onChange = useCallback(
    (fieldname, value) => {
      function makeStoreable() {
        const { extractedSchema, isTypedArray } = computeType(
          currentSchema[fieldname].type,
        )
        if (isTypedArray && !isNil(extractedSchema)) {
          return value.map(item =>
            mapValues(item, (v, k) => toStoreableValue(k, v, extractedSchema)),
          )
        }

        return toStoreableValue(fieldname, value, currentSchema)
      }

      const storeable = makeStoreable()
      onUpdate(fieldname, storeable)
      return storeable
    },
    [onUpdate, currentSchema],
  )
  return { onValid, onChange }
}

export function useRenderMergeConfigBase({
  fieldname,
  currentSchema,
  onUpdateContext,
  leftValue,
  rightValue,
  onError,
  position,
  extenedRender = config => {
    throw new Error(`Unhandled widget type: ${config.widget}`)
  },
}) {
  const onUpdate = useCallback(
    (_, storeable) => {
      onUpdateContext(storeable)
    },
    [onUpdateContext],
  )

  const { onValid, onChange } = useOnChangeAndValid({
    currentSchema,
    onError,
    onUpdate,
  })

  const commonProps = useMemo(
    () => ({
      leftValue: toEditableValue(
        fieldname,
        leftValue[fieldname],
        currentSchema,
      ),
      rightValue: toEditableValue(
        fieldname,
        rightValue[fieldname],
        currentSchema,
      ),
      fieldname,
      currentSchema,
      onChange,
      onValid,
      position,
    }),
    [
      fieldname,
      currentSchema,
      onChange,
      onValid,
      leftValue,
      rightValue,
      position,
    ],
  )

  const mutliSelectionsProps = useCallback(
    ({ useQueryHook }) => ({
      useQueryHook: partial(useQueryHook, leftValue),
    }),
    [leftValue],
  )

  const multiInputProps = useCallback(
    config => ({
      renderChip: get(config, 'renderChip', renderPlainTextChip),
      allowDuplicate: get(config, 'allowDuplicate', false),
      useQueryHook: config.useQueryHook
        ? partial(config.useQueryHook, leftValue)
        : undefined,
    }),
    [leftValue],
  )

  const multipleNodeListProps = useCallback(
    ({ node_types }) => ({
      node_types: isEmpty(node_types)
        ? ['channel', 'channel_concept']
        : node_types,
    }),
    [],
  )

  const render = useCallback(
    config => {
      const widget = get(config, 'widget')
      switch (widget) {
        case 'SingleInputMergeWidget':
          return (
            <SingleInputMergeWidget
              {...commonProps}
              suggestions={get(config, 'suggestions')}
            />
          )
        case 'MultiCheckboxMergeWidget':
          return (
            <MultiCheckboxMergeWidget
              {...commonProps}
              {...mutliSelectionsProps(config)}
            />
          )
        case 'TreeSelectMergeWidget':
          return (
            <TreeSelectMergeWidget
              {...commonProps}
              {...mutliSelectionsProps(config)}
            />
          )
        case 'MultiInputMergeWidget':
          return (
            <MultiInputMergeWidget
              {...commonProps}
              {...multiInputProps(config)}
            />
          )
        case 'SingleSelectMergeWidget':
          return (
            <SingleSelectMergeWidget
              {...commonProps}
              {...mutliSelectionsProps(config)}
            />
          )
        case 'MultipleNodeListMergeWidget':
          return (
            <MultipleNodeListMergeWidget
              {...commonProps}
              {...multipleNodeListProps(config)}
            />
          )

        default:
          return extenedRender(commonProps, config, onChange, onValid, onError)
      }
    },
    [
      commonProps,
      mutliSelectionsProps,
      multiInputProps,
      multipleNodeListProps,
      extenedRender,
      onChange,
      onValid,
      onError,
    ],
  )
  return render
}

export function useRenderConfigBase({
  onError,
  onUpdateContext,
  onDirty,
  editContext,
  currentSchema,
  currentPath,
  extenedRender = config => {
    throw new Error(`Unhandled widget type: ${config.widget}`)
  },
}) {
  const onUpdate = useCallback(
    (fieldname, storeable) => {
      onUpdateContext(draft => {
        draft[fieldname] = storeable
      })
    },
    [onUpdateContext],
  )

  const { onValid, onChange } = useOnChangeAndValid({
    currentSchema,
    onError,
    onUpdate,
  })

  const { privilege, getSuggestions } = useContext(ProjectContext)
  const commonProps = useCallback(
    ({ fieldname, disabled }) => ({
      fieldname,
      currentSchema,
      label: getFieldLabel(currentSchema, editContext, fieldname),
      onDirty,
      onValid,
      onChange,
      disabled:
        disabled ||
        size(
          difference(get(currentSchema[fieldname], 'privilege', []), privilege),
        ) !== 0,
    }),
    [currentSchema, onDirty, onValid, onChange, privilege, editContext],
  )

  const singleInputProps = useCallback(
    ({ fieldname, useQuerySimiliar }) => ({
      value: toEditableValue(fieldname, editContext[fieldname], currentSchema),
      useQuerySimiliar,
    }),
    [currentSchema, editContext],
  )

  const singleSelectProps = useCallback(
    (fieldname, { useQueryHook, nodes, filterNodes }) => ({
      value: editContext[fieldname],
      useQueryHook: useQueryHook
        ? partial(useQueryHook, editContext)
        : undefined,
      nodes,
      filterNodes,
    }),
    [editContext],
  )

  const mutliCheckBoxListProps = useCallback(
    (fieldname, { useQueryHook, itemRenderer }) => ({
      value: editContext[fieldname],
      useQueryHook: partial(useQueryHook, editContext),
      itemRenderer,
    }),
    [editContext],
  )

  const multipleNodeListProps = useCallback(
    (fieldname, node_types) => ({
      node_ids: editContext[fieldname],
      node_types: isEmpty(node_types)
        ? ['channel', 'channel_concept']
        : node_types,
    }),
    [editContext],
  )
  const multiInputProps = useCallback(
    (fieldname, config) => ({
      value: map(editContext[fieldname], v => v.toString()),
      renderChip: get(config, 'renderChip', renderPlainTextChip),
      allowDuplicate: get(config, 'allowDuplicate', false),
      useQueryHook: config.useQueryHook
        ? partial(config.useQueryHook, editContext)
        : undefined,
    }),
    [editContext],
  )

  const render = useCallback(
    config => {
      const path = [...currentPath, config?.fieldname]
      if (isNil(config?.suggestions)) config.suggestions = getSuggestions(path)
      const commonAttributes = configToAttribute(config)
      switch (config.widget) {
        case 'Separator':
          return (
            <Box
              key={get(config, 'key')}
              width="100%"
              my="4"
              height={get(config, 'height')}
              bg={get(config, 'bg')}
              borderRadius="3px"
            />
          )
        case 'SingleInput':
          return (
            <SingleInputWithSuggestions
              {...commonAttributes}
              {...commonProps(config)}
              {...singleInputProps(config)}
            />
          )
        case 'SingleInputWithUrl':
          return (
            <SingleInputWithUrl
              {...commonAttributes}
              {...commonProps(config)}
              {...singleInputProps(config)}
            />
          )
        case 'SingleCheck':
          return (
            <SingleCheck
              {...commonAttributes}
              {...commonProps(config)}
              value={editContext[config.fieldname]}
              onCheck={onChange}
            />
          )
        case 'FileUploader':
          return (
            <FileUploader
              {...commonAttributes}
              {...commonProps(config)}
              onChange={onChange}
              value={editContext[config.fieldname]}
              mimetype={get(config, 'mimetype', 'application/pdf')}
              directInput={get(config, 'directInput', false)}
            />
          )
        case 'SingleSelect':
          return (
            <SingleSelect
              {...commonAttributes}
              {...commonProps(config)}
              {...singleSelectProps(config.fieldname, config)}
            />
          )
        case 'MultiCheckboxList':
          return (
            <MultiCheckboxList
              {...commonAttributes}
              {...commonProps(config)}
              {...mutliCheckBoxListProps(config.fieldname, config)}
              height={get(config, 'height', '200px')}
              labelPosition={get(config, 'labelPosition', 'left')}
            />
          )
        case 'MultipleNodeList':
          return (
            <MultipleNodeList
              {...commonAttributes}
              {...commonProps(config)}
              {...multipleNodeListProps(config.fieldname, config.node_types)}
              labelPosition={get(config, 'labelPosition', 'left')}
            />
          )
        case 'MultiInput':
          return (
            <MultiInput
              {...commonAttributes}
              {...commonProps(config)}
              {...multiInputProps(config.fieldname, config)}
              labelPosition={get(config, 'labelPosition', 'left')}
            />
          )
        case 'ImageUploader':
          return (
            <ImageUploader
              {...commonAttributes}
              {...commonProps(config)}
              editable={true}
              value={editContext[config.fieldname]}
              disableResize={config.disableResize}
            />
          )
        case 'CustomizedInput':
          return (
            <config.RenderComp
              key={config.fieldname}
              fieldname={config.fieldname}
              context={editContext}
              onDirty={onDirty}
              onUpdateContext={onUpdateContext}
              onError={onError}
              onValid={onValid}
              onChange={onChange}
              currentPath={path}
            />
          )
        case 'TreeSelect':
          return (
            <TreeSelect
              {...commonAttributes}
              {...commonProps(config)}
              scrollHeight={get(config, 'inputAttributes.height', '500px')}
              selected={editContext[config.fieldname]}
              useQueryHook={partial(config.useQueryHook, editContext)}
              expandAll={config.expandAll}
              enableSearch={config.enableSearch}
            />
          )

        default:
          return extenedRender(config, onChange, onValid, onError, currentPath)
      }
    },
    [
      onError,
      onUpdateContext,
      onDirty,
      editContext,
      commonProps,
      singleInputProps,
      singleSelectProps,
      mutliCheckBoxListProps,
      multipleNodeListProps,
      multiInputProps,
      onChange,
      onValid,
      extenedRender,
      currentPath,
      getSuggestions,
    ],
  )

  return render
}
