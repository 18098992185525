import PropTypes from 'prop-types'
import styled from 'styled-components'
import { noop } from 'lodash'
import { Flex } from '@changingai/react-editor-common-component'

import theme from '@theme'

const ButtonLikeStyle = styled(Flex)`
  transition: 300ms ease-in-out;
  :hover {
    background-color: ${({ disabled = false, hbc = theme.colors.hover }) =>
      disabled ? 'inherit' : hbc};
    transform: ${({ scaleOnHover = false, disabled = false }) =>
      scaleOnHover && !disabled ? 'scale(1.3)' : ''};
  }
  :active {
    background-color: ${({ disabled = false, abc = theme.colors.active }) =>
      disabled ? 'inherit' : abc};
  }
  cursor: ${({ disabled = false }) => (disabled ? 'inherit' : 'pointer')};
  opacity: ${({ disabled = false }) => (disabled ? 0.3 : 1)};
`

ButtonLikeStyle.defaultProps = {
  m: 1,
  justifyContent: 'center',
  alignItems: 'center',
}

export function ButtonLike({ disabled = false, onClick = noop, ...props }) {
  const trueClick = disabled ? noop : onClick
  return <ButtonLikeStyle {...props} onClick={trueClick} disabled={disabled} />
}

ButtonLike.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}
