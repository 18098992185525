import { createContext, useMemo } from 'react'

import { isNil } from 'lodash'
import { productSchema } from './schema'

export const ProductContext = createContext({
  cpmpanies: null,
})

export function useProductContext() {
  const companies = productSchema.company_id.useNodes()

  const isReady = useMemo(() => !isNil(companies), [companies])

  return {
    isReady,
    companies,
  }
}
