import { get, keys, values, isNil, has, set } from 'lodash'

export const SchemaStore = {}
export const SchemaLabels = {}
export const SchemaNamespace = {}
export function addSchema(schema, name, namespace) {
  const key = get(keys(schema), '0')
  const value = get(values(schema), '0')
  if (has(SchemaStore, key)) throw new Error(`Duplicated schema key: ${key}`)
  SchemaStore[key] = value

  SchemaLabels[key] = name || key

  if (!isNil(namespace)) {
    if (has(SchemaNamespace, namespace))
      SchemaNamespace[namespace].push([key, value])
    else set(SchemaNamespace, namespace, [[key, value]])
  }
}
